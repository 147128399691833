import { COLORS } from '../../shared/styles/Theme';

export const textButtonStyles = {
  padding: '5px 0',
  color: COLORS.blue[700],
  textTransform: 'capitalize',
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '20px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
};
