import * as dates from 'date-arithmetic';
import moment from 'moment';
import { bool, func, instanceOf, number, string } from 'prop-types';
import React from 'react';
import { Calendar, Navigate, Views, momentLocalizer } from 'react-big-calendar';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import {
  DEFAULT_MAX_WORKING_HOUR,
  DEFAULT_WORKING_HOURS,
} from '../../shared/constants';
import './index.css';

const localizer = momentLocalizer(moment);
const now = new Date();
function MyWeek({ date, ...props }) {
  const range = MyWeek.range(date);

  return <TimeGrid {...props} range={range} eventOffset={15} />;
}

MyWeek.propTypes = {
  date: instanceOf(Object),
};

MyWeek.range = (date) => {
  const start = dates.startOf(date, 'week');
  const end = dates.endOf(date, 'week');

  let current = start;
  const range = [];

  while (dates.lte(current, end, 'day')) {
    if (dates.weekday(current) && current.getDay() !== 6) {
      range.push(current);
    }
    current = dates.add(current, 1, 'day');
  }

  return range;
};

MyWeek.navigate = (date, action) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return dates.add(date, -0, 'day');

    case Navigate.NEXT:
      return dates.add(date, 0, 'day');

    default:
      return date;
  }
};

// it's not visible but necessary
MyWeek.title = (date) => `My awesome week: ${date?.toLocaleDateString()}`;

export default function DefaultFiveDaysCalendar({
  className = '',
  customToolbar = () => {},
  date = null,
  dateHeaderWrapper = () => {},
  dayPropGetter = () => {},
  eventComponent = () => {},
  eventStyleGetter = () => {},
  events = [],
  firstVisit = 0,
  lastVisit = 0,
  onNavigate = () => {},
  onSelectEvent = () => {},
  slotPropGetter = () => {},
  toolbar = false,
}) {
  const calculateLastVisit =
    lastVisit - firstVisit <= DEFAULT_WORKING_HOURS - 1
      ? firstVisit + DEFAULT_WORKING_HOURS
      : lastVisit + 1;
  const minTime = new Date();
  minTime.setHours(firstVisit, 0, 0);
  const maxTime = new Date();
  const calcMaxLastVisit =
    calculateLastVisit > DEFAULT_MAX_WORKING_HOUR
      ? DEFAULT_MAX_WORKING_HOUR
      : calculateLastVisit;
  maxTime.setHours(calcMaxLastVisit, 0, 0);
  const timeFormats = {
    timeGutterFormat: 'h A',
    eventTimeRangeFormat: ({ start, end }) =>
      `${moment(start).format('h A')} - ${moment(end).format('h A')}`,
  };
  return (
    <Calendar
      className={`five-days-calendar ${className}`}
      components={{
        event: eventComponent,
        toolbar: customToolbar,
        week: {
          header: dateHeaderWrapper,
        },
      }}
      defaultView={Views.WEEK}
      eventPropGetter={eventStyleGetter}
      events={events}
      dayLayoutAlgorithm="no-overlap"
      step={5}
      timeslots={12}
      dayPropGetter={dayPropGetter}
      slotPropGetter={slotPropGetter}
      date={date}
      formats={timeFormats}
      localizer={localizer}
      max={maxTime}
      min={minTime}
      now={now}
      onNavigate={onNavigate}
      onSelectEvent={onSelectEvent}
      toolbar={toolbar}
      tooltipAccessor={() => ''}
      views={{ week: MyWeek }}
    />
  );
}

DefaultFiveDaysCalendar.propTypes = {
  className: string,
  customToolbar: func,
  date: instanceOf(Object),
  dateHeaderWrapper: func,
  dayPropGetter: func,
  eventComponent: func,
  eventStyleGetter: func,
  events: instanceOf(Array),
  firstVisit: number,
  lastVisit: number,
  onNavigate: func,
  onSelectEvent: func,
  slotPropGetter: func,
  toolbar: bool,
};
