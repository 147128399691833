import { COLORS } from '../../../../shared/styles/Theme';

export const reschedulingVisitStyle = (status) => [
  {
    '& .MuiDataGrid-main': {
      background: status === 'error' ? COLORS.red[200] : COLORS.green[200],
    },
    '& .MuiDataGrid-main .MuiDataGrid-row:hover': {
      backgroundColor: status === 'error' ? COLORS.red[200] : COLORS.green[200],
    },
    '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
  },
];

export const CustomTableStyles = [
  {
    '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-virtualScroller': {
      maxHeight: '100%',
    },
  },
];
