import { Box, Typography } from '@mui/material';
import moment from 'moment';
import CustomCheckbox from '../../../../pages/ClientDetails/components/CreateTask/components/Checkbox/Checkbox';
import React from 'react';
import { H12_TIME_FORMAT, backgroundColorSchemeMap } from '../../../../shared/constants';
import { COLORS } from '../../../../shared/styles/Theme';
import { checkIsDayDisabled } from '../../../../shared/utils/common';
import { pluralize } from '../../../../shared/utils/pluralize';
import Badge from '../../../../components/Badge';
import CustomTimeSelect from '../../../../components/CustomDateAndTimePickers/CustomTimeSelect';
import HistoryBulletList from './components/CaregiverAvailabilityHistory/HistoryBulletList';
import { getBadgeLabel } from './utils';

export const availabilityScheduleModels = {
  current: '0',
  future: '1',
};

export const viewTypeButtons = [
  { name: 'Current', infoType: 'current' },
  { name: 'History', infoType: 'history' },
];
export const PAGE_SIZE = 10;

export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

export const historyColumns = [
  {
    field: 'dateTime',
    headerName: 'Date & Time',
    sortable: false,
    minWidth: 280,
    renderCell: (params) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{params.row.dateCreated}</Typography>
      </Box>
    ),
  },
  {
    field: 'changes',
    headerName: 'Changes',
    minWidth: 740,
    sortable: false,
    renderCell: (params) => <HistoryBulletList changes={params.value} />,
  },
  {
    field: 'user',
    headerName: 'User',
    sortable: false,
    minWidth: 330,
    renderCell: (params) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{params.row.author}</Typography>
      </Box>
    ),
  },
];

export const getAvailabilityColumns = ({
  caregiverWithoutCurrentAvailability,
  checkedRows,
  startDate,
}) => [
  {
    field: 'weekDays',
    headerName: 'Weekday',
    minWidth: 330,
    sortable: false,
    renderCell: (params) => (
      <CustomCheckbox
        isDisabled
        checked={checkedRows.includes(params.row.id)}
        label={
          <Box>
            <Typography sx={{ color: '#0D092B' }}>
              {params.row.workingDays}
            </Typography>
            {!caregiverWithoutCurrentAvailability && (
              <Badge
                colorScheme={
                  moment(startDate).isBefore(moment())
                    ? backgroundColorSchemeMap.info
                    : backgroundColorSchemeMap.active
                }
                label={getBadgeLabel(startDate)}
              />
            )}
          </Box>
        }
      />
    ),
  },
  {
    field: 'startTime',
    headerName: 'Start Time',
    sortable: false,
    minWidth: 330,
    renderCell: (params) => (
      <Box>
        {!checkedRows?.includes(params.row.id) ? (
          <Typography>-</Typography>
        ) : (
          <Typography> {params?.row?.startTime}</Typography>
        )}
      </Box>
    ),
  },
  {
    field: 'endTime',
    headerName: 'End Time',
    sortable: false,
    width: 330,
    renderCell: (params) => (
      <Box>
        {!checkedRows?.includes(params.row.id) ? (
          <Typography>-</Typography>
        ) : (
          <Typography>{params?.row?.endTime}</Typography>
        )}
      </Box>
    ),
  },
  {
    field: 'workingHours',
    headerName: 'Working Hours',
    sortable: false,
    width: 330,
    renderCell: (params) => {
      const startTime = moment(params.row.startTime, 'h:mmA');
      const endTime = moment(params.row.endTime, 'h:mmA');
      const duration = moment.duration(endTime?.diff(startTime));
      const hours = parseInt(duration?.asHours().toString(), 10);
      const minutes = parseInt(duration?.asMinutes().toString(), 10) % 60;
      const range = minutes
        ? `${pluralize(hours, 'hour')} ${pluralize(minutes, 'minute')}.`
        : pluralize(hours, 'hour');
      return (
        <Box>
          <Typography>
            {params.row.startTime !== null && params.row.endTime ? range : '-'}
          </Typography>
        </Box>
      );
    },
  },
];

export const getEditAvailabilityColumns = ({
  checkedRows,
  handleCheckboxChange,
  handleStartTimeChange,
  handleEndTimeChange,
  startDate,
  isPastAvailability,
  disabledDays,
}) => [
  {
    field: 'weekDays',
    headerName: 'Weekday',
    minWidth: 330,
    sortable: false,
    renderCell: ({ row }) => {
      const isDayDisabledSuAndSa = checkIsDayDisabled(
        row?.workingDays,
        disabledDays,
      );
      return (
        <CustomCheckbox
          isDisabled={isPastAvailability || isDayDisabledSuAndSa}
          checked={checkedRows?.includes(row.id)}
          label={
            <Box>
              <Typography sx={{ color: COLORS.blue[900] }}>
                {row.workingDays}
              </Typography>
              <Badge
                colorScheme={
                  moment(startDate).isBefore(moment())
                    ? backgroundColorSchemeMap.info
                    : backgroundColorSchemeMap.active
                }
                label={getBadgeLabel(startDate)}
              />
            </Box>
          }
          onValueChange={() => handleCheckboxChange(row.id)}
        />
      );
    },
  },
  {
    field: 'startTime',
    headerName: 'Start Time',
    sortable: false,
    minWidth: 330,
    renderCell: ({ row }) => (
      <Box sx={{ width: '70%' }}>
        {isPastAvailability && <Typography>{row?.startTime}</Typography>}
        {checkedRows?.includes(row.id) && !isPastAvailability && (
          <CustomTimeSelect
            isRequired
            onSelectTimeValue={(value) =>
              handleStartTimeChange(row.id, moment(value, H12_TIME_FORMAT))
            }
            placeHolder={`Start Time ${row?.workingDays}`}
            selectedTime={row.startTime || ''}
          />
        )}
      </Box>
    ),
  },
  {
    field: 'endTime',
    headerName: 'End Time',
    sortable: false,
    width: 330,
    renderCell: ({ row }) => (
      <Box sx={{ width: '70%' }}>
        {isPastAvailability && <Typography>{row?.endTime}</Typography>}
        {checkedRows?.includes(row.id) && !isPastAvailability && (
          <CustomTimeSelect
            isRequired
            onSelectTimeValue={(value) =>
              handleEndTimeChange(row.id, moment(value, H12_TIME_FORMAT))
            }
            placeHolder={`End Time ${row?.workingDays}`}
            selectedTime={row.endTime || ''}
          />
        )}
      </Box>
    ),
  },
  {
    field: 'workingHours',
    headerName: 'Working Hours',
    sortable: false,
    width: 330,
    renderCell: (params) => {
      const startTime = moment(params.row.startTime, 'h:mmA');
      const endTime = moment(params.row.endTime, 'h:mmA');
      const duration = moment.duration(endTime?.diff(startTime));
      const hours = parseInt(duration?.asHours().toString(), 10);
      const minutes = parseInt(duration?.asMinutes().toString(), 10) % 60;
      const range = minutes
        ? `${pluralize(hours, 'hour')} ${pluralize(minutes, 'minute')}.`
        : pluralize(hours, 'hour');
      return (
        <Box>
          {checkedRows?.includes(params.row.id) && (
            <Typography>
              {params.row.startTime !== null && params.row.endTime
                ? range
                : '-'}
            </Typography>
          )}
        </Box>
      );
    },
  },
];
