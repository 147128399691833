import { COLORS } from '../../../../../../../shared/styles/Theme';

export const checkPossibilityViewWrapper = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  pb: '42px',
};

export const calloutTimeRangeLabelStyles = {
  fontWeight: 400,
  color: COLORS.blue[900],
  '& span': { color: COLORS.blue[500], mr: '3px' },
};

export const infoAlertStyles = {
  '&.MuiAlert-root': {
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px 0',
    border: `1px solid ${COLORS.blue[20]}`,
    borderRadius: '8px',
    '& .MuiAlert-icon': {
      padding: 0,
      color: COLORS.blue[300],
      fontSize: '17px',
      mr: '10px',
    },
    '& .MuiAlert-message': {
      padding: 0,
      color: COLORS.blue[900],
      fontSize: '15px',
      fontWeight: 400,
      lineHeight: '20px',
    },
  },
};
