import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryRealDB } from '../../api/baseQueryBE';
import { transformBackendAvailabilityData } from '../../pages/CaregiverDetails/view/Availability/utils';

export const CAREGIVERS_AVAILABILITY_API_KEY = 'caregiversAvailabilityApi';
const caregiversAvailabilityApi = createApi({
  reducerPath: CAREGIVERS_AVAILABILITY_API_KEY,
  baseQuery: baseQueryRealDB,
  endpoints: (builder) => ({
    getCaregiverAvailability: builder.query({
      query: (caregiverId) => ({
        url: `/caregiver/${caregiverId}/availability`,
        method: 'GET',
      }),
      transformResponse: (response) =>
        transformBackendAvailabilityData(response),
      providesTags: () => [{ type: 'caregiverAvailability', id: 'ALL' }],
    }),
    updateCaregiverAvailability: builder.mutation({
      query: ({ caregiverId, payload }) => ({
        url: `/caregiver/${caregiverId}/availability/check`,
        method: 'POST',
        body: payload,
      }),
    }),
    confirmCaregiverAvailability: builder.mutation({
      query: ({ caregiverId, requestId }) => ({
        url: `caregiver/${caregiverId}/availability/confirm/${requestId}`,
        method: 'POST',
      }),
      invalidatesTags: () => [{ type: 'caregiverAvailability', id: 'ALL' }],
    }),
  }),
});
export const {
  useGetCaregiverAvailabilityQuery,
  useUpdateCaregiverAvailabilityMutation,
  useConfirmCaregiverAvailabilityMutation,
} = caregiversAvailabilityApi;
export default caregiversAvailabilityApi;
