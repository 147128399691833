export const checkAvailabilityCustomAlert = {
  display: 'flex',
  width: '100%',
  justifyContent: 'start',
  alignItems: 'center',
  borderRadius: '10px',
  mb: '16px',
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '16px',
};
