import { Box, IconButton, Typography } from '@mui/material';
import ArrowDown from '../../../../../../../../img/icons/arrow-down.svg?react';
import ArrowUp from '../../../../../../../../img/icons/arrow-up.svg?react';
import DeleteIcon from '../../../../../../../../img/icons/iconButtons/delete-icon.svg';
import { CreateOneTimeVisitContext } from '../../../../../../../../pages/ClientDetails/context';
import { instanceOf, string } from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { COLORS } from '../../../../../../../../shared/styles/Theme';
import CustomTooltip from '../../../../../../../../components/CustomTooltip';
import CustomDialog from '../../../../../../../../components/Dialog';
import { getTaskActionsStyles } from './styles';

export default function TaskActions({ date = '', row = {} }) {
  const { availableVisitTasks, setAvailableVisitTasks } = useContext(
    CreateOneTimeVisitContext,
  );
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const currentVisitIndex = availableVisitTasks.findIndex(
    (visit) => visit.date === date,
  );
  const currentVisit = availableVisitTasks[currentVisitIndex];

  const swapTasks = (target, currentItem) => {
    if (!currentVisit?.tasks?.length) {
      return true;
    }
    const array = [...currentVisit.tasks];
    const currentIndex = array.findIndex(
      ({ carePlanTaskId }) => carePlanTaskId === currentItem,
    );
    if (currentIndex === -1) {
      return true;
    }
    const targetIndex = currentIndex + target;
    if (targetIndex < 0 || targetIndex >= array.length) {
      return true;
    }
    const newArray = array.map((task, index) => {
      if (index === currentIndex) {
        return array[targetIndex];
      }
      if (index === targetIndex) {
        return array[currentIndex];
      }
      return task;
    });

    const newVisitsData = [...availableVisitTasks];
    newVisitsData[currentVisitIndex] = {
      ...newVisitsData[currentVisitIndex],
      tasks: newArray,
    };
    setAvailableVisitTasks(newVisitsData);
    return true;
  };
  const itemIndex = currentVisit?.tasks?.findIndex(
    ({ carePlanTaskId }) => carePlanTaskId === row?.carePlanTaskId,
  );
  const deleteTask = () => {
    const newTasks = availableVisitTasks[0]?.tasks.filter(
      (task) => task.id !== row.id,
    );
    const newAvailableVisitTasks = [
      {
        ...availableVisitTasks[0],
        tasks: newTasks,
      },
    ];
    setAvailableVisitTasks(newAvailableVisitTasks);
  };
  const actions = [
    {
      callback: () => swapTasks(1, row?.carePlanTaskId),
      disabled: itemIndex === currentVisit.tasks.length - 1,
      icon: <ArrowDown />,
      label: 'Move down',
    },
    {
      callback: () => swapTasks(-1, row?.carePlanTaskId),
      disabled: itemIndex < 1,
      icon: <ArrowUp />,
      label: 'Move up',
    },
    {
      callback: () => setOpenDeleteDialog(true),
      disabled: availableVisitTasks[0]?.tasks?.length < 2,
      icon: <img src={DeleteIcon} alt="Delete" />,
      label: 'Delete',
    },
  ];

  const taskActionStyles = useCallback(
    (action) => getTaskActionsStyles(action),
    [],
  );
  const taskName = useMemo(
    () =>
      availableVisitTasks[0]?.tasks?.find(
        (task) => task.id === row?.carePlanTaskId,
      )?.name || '',
    [availableVisitTasks, row?.carePlanTaskId],
  );
  return (
    <Box sx={{ display: 'flex', gap: '16px' }}>
      {actions?.map((action) => (
        <CustomTooltip title={action.label} key={action.label}>
          <IconButton
            aria-label={action.label}
            disableRipple
            onClick={() => (action.disabled ? {} : action.callback())}
            sx={taskActionStyles}
          >
            {action.icon}
          </IconButton>
        </CustomTooltip>
      ))}
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setOpenDeleteDialog(false)}
        submitButtonName="confirm"
        submitCallback={deleteTask}
        title="Confirmation"
        openDialog={openDeleteDialog}
      >
        <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            {`The task "${taskName}" will be removed. Do you want to proceed?`}
          </Typography>
        </Box>
      </CustomDialog>
    </Box>
  );
}

TaskActions.propTypes = {
  date: string,
  row: instanceOf(Object),
};
