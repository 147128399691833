import { useGridApiRef } from '@mui/x-data-grid';
import { bool, instanceOf, string } from 'prop-types';
import React, { useCallback } from 'react';
import { getNoData } from '../../../../../../../../shared/utils/common';
import CustomTable from '../../../../../../../../components/Table';
import { customUpdateProgramTableStyles } from './styles';

function VisitsByTypesChangesTable({
  columns = [],
  isLoading = false,
  noDataTitle = '',
  visitsList = [],
}) {
  const apiRef = useGridApiRef();
  const getRowId = useCallback((row) => `${row.visitId}`, []);

  return (
    <CustomTable
      apiRef={apiRef}
      columns={columns}
      customStyles={customUpdateProgramTableStyles}
      customRowClassName="super-app-theme"
      getRowClassName={(params) =>
        params.row.shiftedBy ? 'shifted-visit' : ''
      }
      getRowId={getRowId}
      headerHeight={44}
      isLoading={isLoading}
      noRowsOverlay={() => getNoData({ title: noDataTitle })}
      rowHeight={60}
      rows={visitsList}
      withoutPagination
    />
  );
}

VisitsByTypesChangesTable.propTypes = {
  columns: instanceOf(Array),
  isLoading: bool,
  noDataTitle: string,
  visitsList: instanceOf(Array),
};

export default React.memo(VisitsByTypesChangesTable);
