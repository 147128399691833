import { Typography } from '@mui/material';
import React from 'react';
import TerritoryTableActions from './components/TableActions';

export const DEFAULT_SORTING_MODEL = { field: 'order', sort: 'asc' };

export const territoryColumns = (locations) => [
  {
    field: 'order',
    headerName: 'Order',
    minWidth: 250,
    flex: 1,
    renderCell: ({ row }) => (
      <Typography
        sx={{
          display: 'flex',
        }}
      >
        {row.order}
      </Typography>
    ),
  },

  {
    field: 'territoryName',
    headerName: 'Territory Name',
    minWidth: 300,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => (
      <TerritoryTableActions row={row} locations={locations} />
    ),
  },
];
