import { Box, Stack, Typography } from '@mui/material';
import { bool, instanceOf, string } from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { WEBSOCKET_MESSAGES_TYPES } from '../../../../../shared/constants';
import { useWebSocket } from '../../../../../shared/hooks/useWebsocket';
import AlertContext from '../../../../../components/Alert';
import FullWidthDialog from '../../../../../components/FullWidthDialog';
import Loader from '../../../../../components/Loader';
import {
  CAREGIVER_STATUS_ACTION_TITLES,
  INTERRUPTIBLE_TASK_KEY,
} from '../constants';
import { reschedulerWaitingBlinkingText } from '../styles';
import DeactivationConfirmationDialog from './components/DeactivationConfirmationDialog';
import ScheduleChangesView from './components/ScheduleChangesView';

function ChangeStatusSteps({
  closeChangeStatusView = () => {},
  confirmNewStatus = () => {},
  showVisitsChanges = false,
  statusAction = '',
  date = {},
}) {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const { clearMessage, message, sendMessage } = useWebSocket();
  const [caregiverActionCheckData, setCaregiverActionCheckData] =
    useState(null);
  const [firstDataLoading, setFirstDataLoading] = useState(true);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [interruptibleId, setInterruptibleId] = useState(null);
  const notAllocatedVisits = caregiverActionCheckData?.events?.filter(
    (event) => event?.status?.status === 'error',
  );

  useEffect(() => {
    window.onpopstate = () => {
      setFirstDataLoading(true);
      setInterruptibleId(null);
      setCaregiverActionCheckData(false);
      setOpenConfirmationDialog(false);
      clearMessage();
      if (interruptibleId) {
        sendMessage({
          action: 'INTERRUPTION_TASK',
          payload: {
            type: 'interruptRequest',
            requestId: interruptibleId,
          },
        });
      }
    };
  }, [allDataLoaded, clearMessage, interruptibleId, sendMessage]);

  useEffect(() => {
    if (message?.type === INTERRUPTIBLE_TASK_KEY) {
      setInterruptibleId(message.payload.requestId);
    }
    if (
      message &&
      !message?.payload?.status &&
      (message.type === WEBSOCKET_MESSAGES_TYPES.caregiverDeactivationCheck ||
        message.type === WEBSOCKET_MESSAGES_TYPES.caregiverActivationCheck)
    ) {
      const response = {
        requestId: message?.payload?.requestId,
        events: message?.payload?.rescheduledEvents,
        nonShadowVisitsAnyMore: message?.payload?.nonShadowVisitsAnyMore,
      };
      setFirstDataLoading(false);
      setCaregiverActionCheckData((prevState) => {
        if (
          prevState?.requestId &&
          prevState?.requestId !== response?.requestId
        ) {
          return prevState;
        }
        return {
          requestId: prevState?.requestId || response?.requestId,
          events: prevState?.events
            ? [...prevState.events, ...response.events]
            : response.events,
          nonShadowVisitsAnyMore: prevState?.nonShadowVisitsAnyMore
            ? [
                ...prevState.nonShadowVisitsAnyMore,
                ...response.nonShadowVisitsAnyMore,
              ]
            : response.nonShadowVisitsAnyMore,
        };
      });
    }
    if (message?.payload?.status === 500) {
      setFirstDataLoading(false);
      setAllDataLoaded(true);
      setInterruptibleId(null);
      closeChangeStatusView();
      setAlert({
        errorData: message,
        type: 'error',
      });
    }
    if (message?.status === 500) {
      setFirstDataLoading(false);
      setAllDataLoaded(true);
      setInterruptibleId(null);
      closeChangeStatusView();
      setAlert({
        errorData: message,
        type: 'error',
      });
    }
    if (message?.payload?.status?.toLowerCase() === 'success') {
      setFirstDataLoading(false);
      setAllDataLoaded(true);
      setInterruptibleId(null);
      setCaregiverActionCheckData((prevState) => {
        if (!prevState?.requestId) {
          return {
            requestId: message?.payload?.requestId,
            events: [],
            nonShadowVisitsAnyMore: [],
          };
        }
        return prevState;
      });
    }
  }, [closeChangeStatusView, message, setAlert]);

  const confirmStatus = () => {
    confirmNewStatus({
      caregiverId: id,
      requestId: caregiverActionCheckData?.requestId,
    });
    setFirstDataLoading(true);
    closeChangeStatusView();
  };
  const titleDataLoader = useMemo(
    () => (
      <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <Typography variant="body1" sx={reschedulerWaitingBlinkingText}>
          Waiting for rescheduling finished
        </Typography>
        <Box sx={{ width: '20px', height: '20px', position: 'relative' }}>
          <Loader size={20} />
        </Box>
      </Box>
    ),
    [],
  );
  return (
    <FullWidthDialog
      cancelButtonName="cancel"
      cancelCallback={closeChangeStatusView}
      disableAllActions={!allDataLoaded}
      disabledSubmit={!allDataLoaded}
      customTitleComponent={allDataLoaded ? null : titleDataLoader}
      submitButtonName="Confirm"
      submitCallback={
        CAREGIVER_STATUS_ACTION_TITLES[statusAction] === 'Reactivate Caregiver'
          ? confirmStatus
          : () => setOpenConfirmationDialog(true)
      }
      title={CAREGIVER_STATUS_ACTION_TITLES[statusAction]}
      openDialog={showVisitsChanges}
    >
      <Stack>
        <ScheduleChangesView
          actionType={statusAction}
          allDataLoaded={allDataLoaded}
          closeDialog={closeChangeStatusView}
          isLoading={firstDataLoading}
          shadowVisits={caregiverActionCheckData?.nonShadowVisitsAnyMore || []}
          visits={notAllocatedVisits || []}
        />
        <DeactivationConfirmationDialog
          openDeactivationModal={openConfirmationDialog}
          date={date}
          closeDeactivationModal={() => setOpenConfirmationDialog(false)}
          confirmDeactivation={confirmStatus}
        />
      </Stack>
    </FullWidthDialog>
  );
}

ChangeStatusSteps.propTypes = {
  closeChangeStatusView: () => {},
  confirmNewStatus: () => {},
  showVisitsChanges: bool,
  statusAction: string,
  date: instanceOf(Object),
};

export default React.memo(ChangeStatusSteps);
