import { Box } from '@mui/material';
import { instanceOf, string } from 'prop-types';
import React from 'react';
import PtoHistoryCommon from './PtoHistoryCommon';

const initializeCalloutChanges = () => ({
  type: '',
  typeBefore: '',
  details: '',
  detailsBefore: '',
  fromDate: '',
  fromDateBefore: '',
  fromTime: '',
  fromTimeBefore: '',
  toDate: '',
  toDateBefore: '',
  status: '',
  statusBefore: '',
  toTime: '',
  toTimeBefore: '',
  caregiverFirstName: '',
  caregiverLastName: '',
  createdDate: '',
  modifiedDate: '',
  author: '',
});

const getHistoryList = (arr, author) => {
  const calloutChanges = initializeCalloutChanges();
  const groupPropertiesArray = arr;

  groupPropertiesArray.forEach((item) => {
    const { propertyName, to, from } = item;
    calloutChanges.author = author;
    switch (propertyName) {
      case 'type':
        calloutChanges.type = to;
        break;
      case 'details':
        calloutChanges.details = to;
        calloutChanges.detailsBefore = from;
        break;
      case 'fromDate':
        calloutChanges.fromDate = to;
        calloutChanges.fromDateBefore = from;
        break;
      case 'fromTime':
        calloutChanges.fromTime = to;
        calloutChanges.fromTimeBefore = from;
        break;
      case 'toDate':
        calloutChanges.toDate = to;
        calloutChanges.toDateBefore = from;
        break;
      case 'toTime':
        calloutChanges.toTime = to;
        calloutChanges.toTimeBefore = from;
        break;
      case 'createdDate':
        calloutChanges.createdDate = to;
        break;
      case 'modifiedDate':
        calloutChanges.modifiedDate = to;
        break;
      case 'status':
        calloutChanges.status = to;
        calloutChanges.statusBefore = from;
        break;
      default:
        break;
    }
  });
  const renderPTOHistory = () => {
    const updatePtoHistory = arr.some(
      (change) => change.operation === 'update',
    );
    return (
      <PtoHistoryCommon
        calloutChanges={calloutChanges}
        updatePtoHistory={updatePtoHistory}
      />
    );
  };
  return <Box>{renderPTOHistory()}</Box>;
};

export default function HistoryBulletList({ changes = [], author = '' }) {
  return (
    <Box sx={{ paddingLeft: '20px' }}>{getHistoryList(changes, author)}</Box>
  );
}

HistoryBulletList.propTypes = {
  changes: instanceOf(Array),
  author: string,
};
