import { COLORS } from '../../shared/styles/Theme';

export const commonAlertWrapper = {
  position: 'absolute',
  zIndex: 10000,
  right: '50%',
  left: '50%',
  top: 30,
  minWidth: '400px',
  fontSize: '12px',
  boxShadow: '0px 0px 12px rgba(44, 45, 46, 0.16)',
};

export const errorAlertBodyWrapper = {
  width: '100%',
  display: 'flex',
  maxWidth: '520px',
  padding: '0 28px 28px 28px',
  gap: '9px',
  alignItems: 'start',
};

export const informationAlertBodyWrapper = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '520px',
  padding: '0 28px 28px 28px',
  gap: '12px',
  alignItems: 'start',
};

export const errorMessageWrapper = {
  height: '100%',
  maxHeight: '300px',
  overflowY: 'scroll',
  paddingRight: '10px',
  '& .MuiTypography-root': {
    wordWrap: 'break-word',
  },
  '&::-webkit-scrollbar': {
    width: '0.4em',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
  "& .MuiAutocomplete-option[aria-selected='true']": {
    backgroundColor: COLORS.blue[50],
  },
};

export const textButtonStyles = {
  color: COLORS.blue[700],
  textTransform: 'capitalize',
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '20px',
  padding: 0,
  width: 'max-content',
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

export const infoBodyHints = {
  fontSize: '15px',
  color: COLORS.blue[900],
  lineHeight: '24px',
};

export const infoItemTitle = {
  display: 'flex',
  alignItems: 'baseline',
  maxWidth: '120px',
  width: '100%',
  marginRight: '12px',
  color: COLORS.blue[500],
  fontSize: '15px',
};

export const infoItemValue = {
  fontSize: '15px',
  color: COLORS.blue[900],
};
