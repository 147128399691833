import { Box, Typography } from '@mui/material';
import { string } from 'prop-types';
import React from 'react';
import { visitNotesStyles } from '../styles';

export default function AdditionalVisitDetails({ notes = '' }) {
  return (
    <Box sx={{ mt: '24px' }}>
      <Typography sx={{ ...visitNotesStyles, fontWeight: 600 }}>
        Additional Visit Details
      </Typography>
      <Typography sx={visitNotesStyles}>{notes}</Typography>
    </Box>
  );
}

AdditionalVisitDetails.propTypes = {
  notes: string,
};
