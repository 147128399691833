import { COLORS } from '../../shared/styles/Theme';

export const customSelectFormControlStyles = {
  width: 160,
  margin: '2px',
  background: '#FFF6D4',
  borderRadius: '10px',
  mr: 0,
};
export const customSelectPaperProps = {
  mt: '5px',
  padding: '8px 0',
  boxShadow: '0px 4px 12px 2px rgba(180, 180, 180, 0.2)',
  borderRadius: '10px',
  maxHeight: '400px',
};
export const customSelectStyles = {
  minWidth: '100px',
  flex: '1 0 0',
  fontSize: '15px',
  height: '42px',
  color: `${COLORS.blue[500]}`,
  borderRadius: '8px',
  '&.MuiSvgIcon-root': {
    fill: `${COLORS.blue[300]}`,
    width: '20px',
    height: '20px',
    top: 11,
  },
  '&.MuiOutlinedInput-root': {
    border: 'none',
    backgroundColor: COLORS.blue[50],
    '.MuiOutlinedInput-input': {
      padding: '10px 12px',
      paddingRight: '28px',
      '&.Mui-disabled': {
        '-webkit-text-fill-color': 'inherit',
      },
    },
    fieldset: {
      border: 'none',
    },
  },
  '&.MuiOutlinedInput-root.has-items': {
    backgroundColor: COLORS.yellow[200],
  },
};
export const customSelectItem = {
  display: 'flex',
  fontSize: '15px',
  color: `${COLORS.blue[900]}`,
  backgroundColor: '#ffffff',
  '&.select-all-item': {
    borderBottom: `1px solid ${COLORS.blue[200]}`,
    paddingBottom: '8px',
  },
  '&:hover, &:active, &:focus, ': {
    backgroundColor: '#ffffff',
  },
  '&:hover': {
    backgroundColor: `${COLORS.blue[50]}`,
  },
  '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:active,  &.Mui-selected:focus':
    {
      backgroundColor: '#ffffff',
    },
};
