import moment from 'moment';
import {
  CALIFORNIA_TIME_ZONE,
  CONVERT_TO_UTC_FORMAT,
  H12_TIME_FORMAT,
  ISO_DATE_ONLY_FORMAT,
  visitStatusesMap,
} from '../../shared/constants';
import { COLORS } from '../../shared/styles/Theme';
import {
  combineISODateString,
  convertDateToUTC,
  convertUtcToTeamTimeZoneMomentObject,
} from '../../shared/utils/common';

export const getBgColor = (status) => {
  if (status === visitStatusesMap.verified) {
    return COLORS.green[700];
  }
  if (status === visitStatusesMap.enRoute) {
    return '#BA8BFF';
  }
  if (status === visitStatusesMap.onSite) {
    return '#9450FF';
  }
  if (status === visitStatusesMap.canceled) {
    return '#FF402E';
  }
  if (status === visitStatusesMap.completed) {
    return '#47BA40';
  }
  if (status === visitStatusesMap.scheduled) {
    return '#2B77FF';
  }
  if (status === visitStatusesMap.inProgress) {
    return '#83DD12';
  }
  if (status === visitStatusesMap.allocated) {
    return '#E3E8F4';
  }
  return '#2B77FF';
};

const parseVisitStatus = (status) => {
  if (!status) {
    return null;
  }
  if (
    [
      visitStatusesMap.allocated,
      visitStatusesMap.cannotBeAllocated,
      visitStatusesMap.scheduled,
    ].includes(status)
  ) {
    return 'Scheduled';
  }
  return status;
};

const getScheduledData = (data) => {
  if (!data) {
    return '';
  }
  const { arrivalTimeStart, arrivalTimeEnd, date, isExactTime } = data;
  const arrivalTime = isExactTime
    ? arrivalTimeStart
    : `${arrivalTimeStart} - ${arrivalTimeEnd}`;
  return `${moment(date, ISO_DATE_ONLY_FORMAT).format(
    'MMM, D',
  )}, ${arrivalTime}`;
};

export const parseVisitData = (data) => {
  const careProgramTasks = data?.careProgramTasks
    ? data?.careProgramTasks?.map((taskData) => ({
        ...taskData,
        status: taskData.status || 'To Do',
      }))
    : [];
  const visitTasks = data?.visitTasks
    ? data?.visitTasks?.map((taskData) => ({
        ...taskData,
        status: taskData.status || 'To Do',
      }))
    : [];
  const isStartEndTimeSame = moment(data?.arrivalStart).isSame(
    moment(data?.arrivalEnd),
    'minute',
  );

  return {
    ...data,
    arrivalTime: data?.arrivalTime
      ? convertUtcToTeamTimeZoneMomentObject(data?.arrivalTime).format(
          H12_TIME_FORMAT,
        )
      : null,
    arrivalTimeStart: data?.arrivalStart
      ? convertUtcToTeamTimeZoneMomentObject(data?.arrivalStart).format(
          H12_TIME_FORMAT,
        )
      : null,
    arrivalTimeEnd: data?.arrivalEnd
      ? convertUtcToTeamTimeZoneMomentObject(data?.arrivalEnd).format(
          H12_TIME_FORMAT,
        )
      : null,
    actualTimeStart:
      !data?.actualTimeStart || data?.actualTimeStart === 'NOT STARTED'
        ? null
        : convertUtcToTeamTimeZoneMomentObject(data?.actualTimeStart).format(
            H12_TIME_FORMAT,
          ),
    actualTimeEnd:
      !data?.actualTimeEnd || data?.actualTimeEnd === 'NOT ENDED'
        ? null
        : convertUtcToTeamTimeZoneMomentObject(data?.actualTimeEnd).format(
            H12_TIME_FORMAT,
          ),
    actualDuration: data?.actualDuration !== null ? data?.actualDuration : null,
    careProgramTasks,
    date: data?.arrivalStart
      ? convertUtcToTeamTimeZoneMomentObject(data?.arrivalStart).format(
          ISO_DATE_ONLY_FORMAT,
        )
      : null,
    isExactTime: isStartEndTimeSame,
    location: data?.address,
    repeated: data?.repeat === 'Weekly' || data?.recurrence === 'Weekly',
    shadowStatus: parseVisitStatus(data?.shadowStatus),
    status: parseVisitStatus(data?.status),
    team: data?.team || null,
    title: `${data?.visitType} Visit`,
    violatedHardConstraints: data?.violatedHardConstraints?.length
      ? {
          title: 'Hard constraints violations',
          type: 'error',
          reasons: data?.violatedHardConstraints,
        }
      : null,
    violatedSoftConstraints: data?.violatedSoftConstraints?.length
      ? {
          title: 'Soft constraints violations',
          type: 'warning',
          reasons: data?.violatedSoftConstraints,
        }
      : null,
    visitDetails: data?.visitDetails || '',
    visitNotes: data?.visitNotes || '',
    visitTasks,
  };
};

export const parseShortVisitInfoData = (visitData) => ({
  ...visitData,
  actualDuration:
    visitData?.actualDuration !== null
      ? `${visitData?.actualDuration} min`
      : null,
  caregiver: visitData?.caregiver
    ? {
        id: visitData?.caregiver?.id,
        link: `/caregivers/${visitData?.caregiver?.id}`,
        name: `${visitData?.caregiver?.firstName} ${visitData?.caregiver?.lastName}`,
        preferredCaregiver: visitData.isPreferredCaregiver,
      }
    : null,
  client: {
    link: `/clients/${visitData?.client?.id}`,
    name: `${visitData?.client?.firstName} ${visitData?.client?.lastName}`,
  },
  date: visitData?.date || '',
  duration: `${visitData?.duration} min`,
  scheduledArrival: getScheduledData(visitData),
  preferredCaregiver: visitData?.preferredCaregiver,
  shadowCaregiver: visitData?.shadowCaregiver
    ? {
        link: `/caregivers/${visitData?.shadowCaregiver?.id}`,
        name: `${visitData?.shadowCaregiver?.firstName} ${visitData?.shadowCaregiver?.lastName}`,
      }
    : null,
});

export const parseVisitReschedulingData = ({
  initialVisitData,
  temporaryVisitData,
}) => ({
  eventId: temporaryVisitData?.id,
  fromDate: initialVisitData?.date,
  team: temporaryVisitData?.team,
  toDate: temporaryVisitData?.date,
  toDuration: temporaryVisitData?.duration,
  toCaregiver: temporaryVisitData?.caregiver?.id,
  toTime: temporaryVisitData?.arrivalTimeEnd,
  fromTime: temporaryVisitData?.arrivalTimeStart,
});

export const parseUpdateVisitData = ({
  temporaryVisitData,
  cancellationReason,
}) => {
  const actualEndDateWithTimeString = temporaryVisitData?.actualTimeEnd
    ? combineISODateString({
        date: temporaryVisitData?.date,
        time: temporaryVisitData?.actualTimeEnd,
      })
    : null;
  const actualStartDateWithTimeString = temporaryVisitData?.actualTimeStart
    ? combineISODateString({
        date: temporaryVisitData?.date,
        time: temporaryVisitData?.actualTimeStart,
      })
    : null;
  return {
    actualDuration: temporaryVisitData?.actualDuration || 0,
    actualTimeEnd: actualEndDateWithTimeString
      ? convertDateToUTC({
          date: actualEndDateWithTimeString,
          dateFormat: CONVERT_TO_UTC_FORMAT,
          dateTimeZone: CALIFORNIA_TIME_ZONE,
        })
      : null,
    actualTimeStart: actualStartDateWithTimeString
      ? convertDateToUTC({
          date: actualStartDateWithTimeString,
          dateFormat: CONVERT_TO_UTC_FORMAT,
          dateTimeZone: CALIFORNIA_TIME_ZONE,
        })
      : null,
    address: temporaryVisitData?.location,
    arrivalEnd: temporaryVisitData?.arrivalTimeEnd,
    arrivalStart: temporaryVisitData?.arrivalTimeStart,
    arrivalTime: temporaryVisitData?.arrivalTime,
    cancellationReason:
      cancellationReason || temporaryVisitData?.cancellationReason,
    careProgramTasks: temporaryVisitData?.careProgramTasks,
    caregiver: temporaryVisitData?.caregiver,
    client: temporaryVisitData?.client,
    date: temporaryVisitData?.date,
    duration: temporaryVisitData?.duration,
    externalId: temporaryVisitData?.externalId,
    id: temporaryVisitData?.id,
    preferredCaregiver: temporaryVisitData?.preferredCaregiver,
    previousDate: temporaryVisitData?.previousDate,
    recurrence: temporaryVisitData?.recurrence,
    roadTimeInMinutes: temporaryVisitData?.roadTimeInMinutes,
    shadowCaregiver: temporaryVisitData?.shadowCaregiver,
    shadowStatus: temporaryVisitData?.shadowCaregiver
      ? temporaryVisitData.status
      : temporaryVisitData.shadowStatus,
    status: temporaryVisitData.status,
    team: temporaryVisitData?.team,
    violatedHardConstraints:
      temporaryVisitData?.violatedHardConstraints?.reasons || [],
    violatedSoftConstraints:
      temporaryVisitData?.violatedSoftConstraints?.reasons || [],
    visitTasks: temporaryVisitData?.visitTasks,
    visitType: temporaryVisitData?.visitType,
    visitNotes: temporaryVisitData?.visitNotes,
    visitDetails: temporaryVisitData?.visitDetails,
  };
};

export const createNewVisitData = ({
  reason,
  statusChanging,
  temporaryVisitData,
}) => {
  let newVisitData = parseUpdateVisitData({
    temporaryVisitData,
    ...(reason ? { cancellationReason: reason } : {}),
  });
  if (
    statusChanging &&
    temporaryVisitData.status === visitStatusesMap.inProgress
  ) {
    newVisitData = {
      ...newVisitData,
      actualTimeStart: convertDateToUTC({
        date: moment(),
        dateTimeZone: CALIFORNIA_TIME_ZONE,
      }),
    };
  }
  if (
    statusChanging &&
    temporaryVisitData.status === visitStatusesMap.completed
  ) {
    const start = moment().tz(CALIFORNIA_TIME_ZONE).format(H12_TIME_FORMAT);
    const end = moment(temporaryVisitData?.actualTimeStart, H12_TIME_FORMAT);
    const newDuration = moment(start, H12_TIME_FORMAT).diff(
      moment(end, H12_TIME_FORMAT).tz(CALIFORNIA_TIME_ZONE),
      'minutes',
    );
    newVisitData = {
      ...newVisitData,
      actualTimeEnd: convertDateToUTC({
        date: moment(),
        dateTimeZone: CALIFORNIA_TIME_ZONE,
      }),
      actualDuration: newDuration < 1 ? 1 : newDuration,
    };
  }
  return {
    ...newVisitData,
    arrivalEnd: convertDateToUTC({
      date: `${newVisitData?.date} ${newVisitData?.arrivalEnd}`,
      dateFormat: CONVERT_TO_UTC_FORMAT,
      dateTimeZone: CALIFORNIA_TIME_ZONE,
    }),
    arrivalStart: convertDateToUTC({
      date: `${newVisitData?.date} ${newVisitData?.arrivalStart}`,
      dateFormat: CONVERT_TO_UTC_FORMAT,
      dateTimeZone: CALIFORNIA_TIME_ZONE,
    }),
    arrivalTime: convertDateToUTC({
      date: `${newVisitData?.date} ${newVisitData?.arrivalTime}`,
      dateFormat: CONVERT_TO_UTC_FORMAT,
      dateTimeZone: CALIFORNIA_TIME_ZONE,
    }),
  };
};

export const checkFieldsToRecheckAvailability = (prevData, newData) => {
  if (!prevData && !newData) {
    return false;
  }
  const prevDate = prevData?.date;
  const prevArrivalStartTime = prevData?.arrivalTimeStart || '';
  const prevArrivalEndTime = prevData?.arrivalTimeEnd || '';
  const prevDuration = prevData?.duration || '';
  const prevIsExactTime = prevData?.isExactTime || '';
  const prevActualTimeStart = prevData?.actualTimeStart || '';
  const prevActualTimeEnd = prevData?.actualTimeEnd || '';

  const newDate = newData?.date;
  const newArrivalStartTime = newData?.arrivalTimeStart || '';
  const newArrivalEndTime = newData?.arrivalTimeEnd || '';
  const newDuration = newData?.duration || '';
  const newIsExactTime = newData?.isExactTime || '';
  const newActualTimeStart = newData?.actualTimeStart || '';
  const newActualTimeEnd = newData?.actualTimeEnd || '';

  return (
    prevIsExactTime !== newIsExactTime ||
    prevArrivalStartTime !== newArrivalStartTime ||
    prevDate !== newDate ||
    prevArrivalEndTime !== newArrivalEndTime ||
    prevDuration !== newDuration ||
    prevActualTimeStart !== newActualTimeStart ||
    prevActualTimeEnd !== newActualTimeEnd
  );
};

export const visitDateHasBeenChanged = (prevData, newData) => {
  if (!prevData && !newData) {
    return false;
  }
  const prevVisitNotes = prevData?.visitNotes || '';
  const prevVisitDetails = prevData?.visitDetails || '';
  const prevStatus = prevData?.status || '';
  const prevTasks =
    prevData.careProgramTasks && prevData.visitTasks
      ? [...prevData.careProgramTasks, ...prevData.visitTasks].reduce(
          (string, currentValue) =>
            string + currentValue.status + currentValue.details,
          '',
        )
      : '';
  const newVisitNotes = newData?.visitNotes || '';
  const newVisitDetails = newData?.visitDetails || '';
  const newStatus = newData?.status || '';
  const newTasks =
    newData.careProgramTasks && newData.visitTasks
      ? [...newData.careProgramTasks, ...newData.visitTasks].reduce(
          (string, currentValue) =>
            string + currentValue.status + currentValue.details,
          '',
        )
      : '';

  return (
    prevVisitNotes !== newVisitNotes ||
    prevVisitDetails !== newVisitDetails ||
    prevStatus !== newStatus ||
    prevTasks !== newTasks
  );
};

export const checkEnableToEditFields = ({
  editActualTime,
  editArrivalTime,
  editingPrimaryCaregiver,
  editingShadowCaregiver,
  isNotAllocatedVisit,
  needToConfirmStatus,
  unMarkingPreferredCaregiver,
  unMarkingShadowCaregiver,
  visitStatus,
}) => {
  const isPrimaryCaregiverAvailableToEdit =
    !isNotAllocatedVisit &&
    !editArrivalTime &&
    !editingShadowCaregiver &&
    !needToConfirmStatus &&
    !unMarkingShadowCaregiver &&
    [visitStatusesMap.scheduled].includes(visitStatus);

  const isShadowCaregiverAvailableToEdit =
    !isNotAllocatedVisit &&
    !editArrivalTime &&
    !editingPrimaryCaregiver &&
    !needToConfirmStatus &&
    !unMarkingPreferredCaregiver &&
    [visitStatusesMap.scheduled].includes(visitStatus);

  const isScheduledTimeAvailableToEdit =
    !isNotAllocatedVisit &&
    !editingPrimaryCaregiver &&
    !editingShadowCaregiver &&
    !needToConfirmStatus &&
    !unMarkingPreferredCaregiver &&
    !unMarkingShadowCaregiver &&
    [
      visitStatusesMap.scheduled,
      visitStatusesMap.enRoute,
      visitStatusesMap.onSite,
    ].includes(visitStatus);

  const isActualTimeAvailableToEdit =
    !unMarkingPreferredCaregiver &&
    !unMarkingShadowCaregiver &&
    !isNotAllocatedVisit &&
    !needToConfirmStatus &&
    [visitStatusesMap.inProgress, visitStatusesMap.completed].includes(
      visitStatus,
    );

  const isStatusDisabledToEdit =
    editActualTime ||
    editArrivalTime ||
    editingPrimaryCaregiver ||
    unMarkingPreferredCaregiver ||
    unMarkingShadowCaregiver ||
    needToConfirmStatus;

  return {
    isActualTimeAvailableToEdit,
    isPrimaryCaregiverAvailableToEdit,
    isScheduledTimeAvailableToEdit,
    isShadowCaregiverAvailableToEdit,
    isStatusDisabledToEdit,
  };
};
