import { Box } from '@mui/material';
import moment from 'moment';
import { bool, func, instanceOf, string } from 'prop-types';
import React from 'react';
import { FULL_DATE_H24_STANDARD_FORMAT } from '../../../../../../../shared/constants';
import { CustomDatePicker } from '../../../../../../../components/CustomDateAndTimePickers';
import ErrorAlert from '../../../../../../../components/ErrorAlert';
import CustomCheckbox from '../../../../../../../components/VisitInfoTypeProvider/components/CreateTask/components/Checkbox/Checkbox';
import ContentWrapper from './Wrapper';

export default function SettingsView({
  error = '',
  setError = () => {},
  setStatusPeriodDates = () => {},
  checkedEndTime = false,
  setCheckedEndTime = () => {},
  statusPeriodDates = instanceOf(Object),
}) {
  const checkDatesValidation = ({ startDate, endDate }) => {
    if (error) {
      setError(null);
    }
    if (endDate.isBefore(startDate, 'minutes')) {
      setError('You have been inputted wrong dates');
      return '';
    }
    return '';
  };
  const changeRequestDate = ({ startDate, endDate }) => {
    setStatusPeriodDates({ startDate, endDate });
    if (startDate && endDate) {
      checkDatesValidation({
        startDate,
        endDate,
      });
    }
  };
  const onCheckboxChange = (e) => {
    setCheckedEndTime(e.target.checked);
    changeRequestDate({
      startDate: statusPeriodDates?.startDate,
      endDate: null,
    });
  };
  return (
    <ContentWrapper>
      {error && <ErrorAlert error={error} />}
      <Box>
        <Box sx={{ display: 'flex', gap: '20px', mb: '10px' }}>
          <CustomDatePicker
            changeDate={(newValue) =>
              changeRequestDate({
                startDate: newValue,
                endDate: statusPeriodDates?.endDate,
              })
            }
            date={statusPeriodDates?.startDate}
            isDisablePast
            isDisabledToday
            label="Suspension Start Date"
          />
          <CustomDatePicker
            disabled={checkedEndTime}
            changeDate={(newValue) =>
              changeRequestDate({
                startDate: statusPeriodDates?.startDate,
                endDate: newValue,
              })
            }
            date={
              statusPeriodDates?.endDate
                ? moment(
                    statusPeriodDates?.endDate,
                    FULL_DATE_H24_STANDARD_FORMAT,
                  )
                : null
            }
            isDisablePast
            isDisabledToday
            label="Suspension End Date"
          />
        </Box>
        <CustomCheckbox
          checked={checkedEndTime}
          label="Continue without end date"
          onValueChange={onCheckboxChange}
        />
      </Box>
    </ContentWrapper>
  );
}

SettingsView.propTypes = {
  error: string,
  checkedEndTime: bool,
  setCheckedEndTime: func,
  setError: func,
  setStatusPeriodDates: func,
  statusPeriodDates: instanceOf(Object),
};
