import { COLORS } from '../../../../../shared/styles/Theme';

export const editPreferredCaregiverWrapperStyles = {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  padding: '16px 20px',
  marginBottom: '16px',
  backgroundColor: COLORS.yellow[200],
  zIndex: 1,
  '& .MuiAutocomplete-root .MuiFilledInput-root,& .MuiOutlinedInput-root': {
    backgroundColor: '#ffffff',
    width: '100%',
  },
};

export const setupActionsWrapperStyles = {
  display: 'flex',
  height: '40px',
  gap: '10px',
};

export const outlinedButtonStyles = {
  backgroundColor: '#ffffff',
  '&:hover': {
    backgroundColor: '#ffffff',
  },
  '&.Mui-disabled': {
    backgroundColor: '#ffffff',
    color: COLORS.blue[500],
    opacity: 0.7,
  },
};
