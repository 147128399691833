import { Box, Stack } from '@mui/material';
import { useGetWeeklyReportQuery } from '../../../../api/Reporting/api';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ISO_DATE_ONLY_FORMAT } from '../../../../shared/constants';
import AlertContext from '../../../../components/Alert';
import ButtonsGroup from '../../../../components/ButtonsGroup';
import Loader from '../../../../components/Loader';
import SelectTeamFilter from '../../../../components/SelectTeamFilter';
import VisitManagementProvider from '../../../../components/VisitInfoTypeProvider/VisitManagementProvider';
import ReportStatistics from './components/ReportStatistics';
import ReportingWeekDates from './components/ReportingWeek';
import VisitsList from './components/VisitsList';
import { viewTypeButtons } from './constants';
import { tablesWrapper } from './styles';

export default function WeeklySchedulingReport() {
  const navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);
  const { tabSubView } = useParams();
  const currentWeekStartDate = moment().clone().startOf('isoweek');
  const [reportingWeekStartDate, setReportingWeekStartDate] =
    useState(currentWeekStartDate);
  const {
    data: weeklyReportData,
    error,
    isLoading,
    isFetching,
    refetch: refetchWeeklyReport,
  } = useGetWeeklyReportQuery(
    { date: reportingWeekStartDate.format(ISO_DATE_ONLY_FORMAT) },
    { refetchOnMountOrArgChange: true },
  );
  const [teamsList, setTeamsList] = useState([]);
  const [teamFilter, setTeamFilter] = useState([]);
  const [visitsList, setVisitsList] = useState([]);

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);

  useEffect(() => {
    if (weeklyReportData) {
      setTeamsList(weeklyReportData[tabSubView].teams);
      setTeamFilter(weeklyReportData[tabSubView].teams);
      setVisitsList(weeklyReportData[tabSubView].visitsData);
    }
  }, [tabSubView, weeklyReportData]);

  useEffect(() => {
    if (teamFilter.length) {
      setVisitsList(
        weeklyReportData[tabSubView].visitsData.filter(({ team }) =>
          teamFilter.includes(team?.name),
        ),
      );
    } else {
      setVisitsList([]);
    }
  }, [tabSubView, teamFilter, weeklyReportData]);

  const changeSubView = (value) =>
    navigate(`/reporting/weekly-scheduling-report/${value}`);

  return (
    <VisitManagementProvider reFetchCalendarEvents={refetchWeeklyReport}>
      <Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: '20px',
          }}
        >
          {(isLoading || isFetching) && <Loader />}
          <ButtonsGroup
            buttons={viewTypeButtons}
            changeSelected={changeSubView}
            selected={tabSubView}
          />
          <Box sx={{ display: 'flex', gap: '20px' }}>
            <ReportingWeekDates
              date={reportingWeekStartDate}
              setNewDate={setReportingWeekStartDate}
            />
            <SelectTeamFilter
              hasSelectAllOption
              id="teams"
              isMultiselect
              options={teamsList}
              placeHolder="Teams"
              selectedValue={teamFilter}
              setValue={({ value }) => setTeamFilter(value)}
            />
          </Box>
        </Box>
        {/* {TODO statistics will be implemented later} */}
        {!tabSubView && <ReportStatistics />}
        <Box sx={tablesWrapper}>
          <VisitsList visitsList={visitsList} />
        </Box>
      </Stack>
    </VisitManagementProvider>
  );
}
