import { Box, Stack } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { viewTypeButtons } from '../../../../shared/constants';
import ButtonsGroup from '../../../../components/ButtonsGroup';
import HolidayHeader from './components/HolidayHeader';
import NationalHolidays from './components/NationalHolidays';
import NationalHolidaysHistory from './components/NationalHolidaysHistory';

export default function AdministrationHolidays() {
  const { tabSubView } = useParams();
  const navigate = useNavigate();

  const changeSubView = (value) =>
    navigate(`/administration/holidays/${value}`);

  return (
    <Stack>
      {tabSubView === 'current' ? (
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <ButtonsGroup
              buttons={viewTypeButtons}
              changeSelected={changeSubView}
              selected={tabSubView}
            />

            <HolidayHeader />
          </Box>
          <Box sx={{ mt: '24px' }}>
            <NationalHolidays />
          </Box>
        </Box>
      ) : (
        <Box>
          <ButtonsGroup
            buttons={viewTypeButtons}
            changeSelected={changeSubView}
            selected={tabSubView}
          />
          <NationalHolidaysHistory />
        </Box>
      )}
    </Stack>
  );
}
