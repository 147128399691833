import { Checkbox } from '@mui/material';
import { VerificationModeVisitNotesContext } from '../../../../../../pages/Scheduling/context';
import { string } from 'prop-types';
import React, { useContext } from 'react';

function VisitSelectCheckbox({ locationId = '' }) {
  const { completedVisits, onSelectAllTeamVisits, selectionModel } = useContext(
    VerificationModeVisitNotesContext,
  );
  return (
    <Checkbox
      checked={selectionModel.some(
        (visitId) =>
          completedVisits.find((visit) => visit.id === visitId)?.region.id ===
          locationId,
      )}
      onChange={() => onSelectAllTeamVisits(locationId)}
    />
  );
}

VisitSelectCheckbox.propTypes = {
  locationId: string,
};

export default React.memo(VisitSelectCheckbox);
