import { Box } from '@mui/material';
import { bool, func, instanceOf } from 'prop-types';
import React from 'react';
import CustomDialog from '../../../../../../components/Dialog';
import SelectEffectiveDate from './SelectEffectiveDate';

function EffectiveDateDialog({
  openEffectiveDateDialog = false,
  closeEffectiveDateDialog = () => {},
  submitEffectiveDateDialog = () => {},
  date = {},
  selectDate = () => {},
}) {
  return (
    <CustomDialog
      cancelButtonName="Cancel"
      cancelCallback={closeEffectiveDateDialog}
      submitButtonName="Proceed"
      submitCallback={submitEffectiveDateDialog}
      title="Set Deactivation Effective Date"
      openDialog={openEffectiveDateDialog}
    >
      <Box sx={{ padding: '0 28px 20px 13px' }}>
        <SelectEffectiveDate date={date} selectDate={selectDate} />
      </Box>
    </CustomDialog>
  );
}

EffectiveDateDialog.propTypes = {
  openEffectiveDateDialog: bool,
  closeEffectiveDateDialog: func,
  submitEffectiveDateDialog: func,
  date: instanceOf(Object),
  selectDate: func,
};

export default EffectiveDateDialog;
