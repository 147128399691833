import { Box, Button, Grow, InputAdornment, TextField } from '@mui/material';
import { bool, func, number, string } from 'prop-types';
import React, { useState } from 'react';
import { VISIT_INFO_DEBOUNCE_DELAY } from '../../../../../../shared/constants';
import { useDebouncedCallback } from '../../../../../../shared/hooks/useDebounce';
import { customInputStyles, customMultilinesInputStyles } from './style';

export default function VisitViolations({
  cancelCallback = () => {},
  changeDetails = () => {},
  disabled = false,
  initialVisitNotes = '',
  label = '',
  maxLength = 0,
  submitCallback = () => {},
  textFieldId = '',
}) {
  const [showHelper, setShowHelper] = useState(false);
  const [propValue, setPropValue] = useState(initialVisitNotes || '');
  const [showNotesActions, setShowNotesActions] = useState(false);

  const debouncedChangeData = useDebouncedCallback((e) => {
    changeDetails(e);
  }, VISIT_INFO_DEBOUNCE_DELAY);
  const changeNotes = (e) => {
    const isValueChanged = e.target.value !== initialVisitNotes;
    debouncedChangeData(e);
    setShowNotesActions(() => isValueChanged);
    setPropValue(e.target.value);
  };
  const cancelNotesChanges = () => {
    setPropValue(initialVisitNotes !== null ? initialVisitNotes : '');
    cancelCallback();
    setShowNotesActions(false);
  };
  const saveNotesChanges = () => {
    submitCallback();
    setShowNotesActions(false);
  };
  return (
    <TextField
      className={showNotesActions ? 'has-actions' : ''}
      disabled={disabled}
      helperText={
        !disabled && (!propValue?.length || showHelper)
          ? `${propValue?.length || 0} / ${maxLength}`
          : ''
      }
      id={textFieldId}
      InputProps={{
        endAdornment: (
          <InputAdornment
            sx={{ position: 'absolute', bottom: '24px' }}
            position="start"
          >
            <Grow in={showNotesActions}>
              <Box sx={{ display: 'flex', gap: '12px' }}>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={cancelNotesChanges}
                  disableElevation
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={saveNotesChanges}
                  disableElevation
                  disabled={disabled}
                >
                  Save
                </Button>
              </Box>
            </Grow>
          </InputAdornment>
        ),
      }}
      label={label}
      maxRows={8}
      minRows={2}
      multiline
      onKeyDown={(e) => e.stopPropagation()}
      onBlur={() => setShowHelper(false)}
      onChange={(e) => changeNotes(e)}
      onFocus={() => setShowHelper(true)}
      sx={{ ...customInputStyles, ...customMultilinesInputStyles }}
      variant="filled"
      value={propValue}
    />
  );
}

VisitViolations.propTypes = {
  cancelCallback: func,
  changeDetails: func,
  disabled: bool,
  initialVisitNotes: string,
  label: string,
  maxLength: number,
  submitCallback: func,
  textFieldId: string,
};
