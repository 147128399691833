import { Box } from '@mui/material';
import { useUpdateCaregiverTeamMutation } from '../../../../../api/Caregivers/api';
import moment from 'moment';
import {
  CaregiverDetailsContext,
  CaregiverProfileContext,
} from '../../../../../pages/CaregiverDetails/context';
import { bool, func, instanceOf, string } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  ISO_DATE_ONLY_FORMAT,
  OPTIMIZATION_PERIOD_LENGTH,
} from '../../../../../shared/constants';
import AlertContext from '../../../../../components/Alert';
import CustomDialog from '../../../../../components/Dialog';
import SchedulingUpdatesTable from '../TeamChangeTable';
import { getChangedTeams } from '../constants';
import SelectEffectiveDate from './SelectEffectiveDate';

function TeamEffectiveDateModal({
  openEffectiveDateDialog = false,
  closeEffectiveDateDialog = () => {},
  date = {},
  selectDate = () => {},
  caregiver = {},
  isPrimary = false,
  caregiverId = '',
}) {
  const [open, setOpen] = useState(false);
  const [
    checkTeamChange,
    {
      isLoading: isCheckTeamLoading,
      error: checkCaregiverTeamChangeError,
      data,
    },
  ] = useUpdateCaregiverTeamMutation();
  const { setAlert } = useContext(AlertContext);

  useEffect(() => {
    if (checkCaregiverTeamChangeError) {
      const errorData = checkCaregiverTeamChangeError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [checkCaregiverTeamChangeError, setAlert]);
  const {
    caregiverProfile,
    setTeamEffectiveDateOpen,
    caregiverProfileBeforeEdit,
    initializeCaregiverProfile,
    updateTeam,
  } = useContext(CaregiverProfileContext);
  const { onCloseProfileEditing } = useContext(CaregiverDetailsContext);
  const secondaryTeamsChanged = getChangedTeams(
    caregiverProfile.secondaryTeams,
    caregiverProfileBeforeEdit.secondaryTeams,
  );
  const cancelCallback = () => {
    setOpen(false);
    setTeamEffectiveDateOpen({
      isPrimary: !!isPrimary,
      isOpen: false,
    });
    initializeCaregiverProfile();
  };
  const submitEffectiveDateDialog = () => {
    setOpen(true);
    setTeamEffectiveDateOpen({
      isPrimary: !!isPrimary,
      isOpen: false,
    });
    checkTeamChange({
      caregiverId,
      payload: {
        primaryTeams: caregiverProfile.primaryTeams?.map((team) => team),
        secondaryTeams: caregiverProfile.secondaryTeams?.map((team) => team),
        effectiveDateStart: moment(date).format(ISO_DATE_ONLY_FORMAT),
      },
    });
  };
  const submitCallback = useCallback(() => {
    updateTeam({
      requestId: data?.requestId,
      caregiverId,
    });
    setOpen(false);
    onCloseProfileEditing();
  }, [caregiverId, data?.requestId, onCloseProfileEditing, updateTeam]);

  const isOutsideOptimizationPeriod = moment(date).diff(
    moment().startOf('isoWeek'),
    'days',
  );
  useEffect(() => {
    if (isOutsideOptimizationPeriod > OPTIMIZATION_PERIOD_LENGTH && data) {
      submitCallback();
    }
  }, [data, isOutsideOptimizationPeriod, submitCallback]);
  return (
    <Box>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={closeEffectiveDateDialog}
        submitButtonName="view changed visits"
        submitCallback={submitEffectiveDateDialog}
        title="Set Effective Date"
        openDialog={openEffectiveDateDialog}
      >
        <Box sx={{ padding: '0 28px 20px 13px' }}>
          <SelectEffectiveDate
            date={date}
            selectDate={selectDate}
            caregiver={caregiver}
            isPrimary={isPrimary}
            secondaryTeamsChanged={secondaryTeamsChanged.addedTeams}
          />
        </Box>
      </CustomDialog>
      {isOutsideOptimizationPeriod <= OPTIMIZATION_PERIOD_LENGTH && (
        <SchedulingUpdatesTable
          open={open}
          actualVisit={data}
          isPrimary={isPrimary}
          isLoading={isCheckTeamLoading}
          cancelCallback={cancelCallback}
          submitCallback={submitCallback}
          teamDate={date}
          secondaryTeamsChanged={secondaryTeamsChanged?.addedTeams}
        />
      )}
    </Box>
  );
}

TeamEffectiveDateModal.propTypes = {
  openEffectiveDateDialog: bool,
  closeEffectiveDateDialog: func,
  date: instanceOf(Object),
  selectDate: func,
  isPrimary: bool,
  caregiver: instanceOf(Object),
  caregiverId: string,
};

export default TeamEffectiveDateModal;
