import { Box, Typography } from '@mui/material';
import moment from 'moment/moment';
import { transformAvailabilityRows } from '../../../../../pages/CaregiverDetails/view/Availability/utils';
import CustomCheckbox from '../../../../../pages/ClientDetails/components/CreateTask/components/Checkbox/Checkbox';
import { func, instanceOf, string } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { H12_TIME_FORMAT } from '../../../../../shared/constants';
import { COLORS } from '../../../../../shared/styles/Theme';
import { checkIsDayDisabled } from '../../../../../shared/utils/common';
import CustomTimeSelect from '../../../../../components/CustomDateAndTimePickers/CustomTimeSelect';
import ErrorAlert from '../../../../../components/ErrorAlert';

export default function MultiplyModeContent({
  disabledDays = {},
  endTime = '',
  error = '',
  selectedDays = [],
  setEndTime = () => {},
  setError = () => {},
  setSelectedDays = () => {},
  setStartTime = () => {},
  startTime = '',
}) {
  const handleCheckboxChange = (id) => {
    if (selectedDays.includes(id)) {
      setSelectedDays((prevValue) => prevValue.filter((value) => value !== id));
    } else setSelectedDays((prevValue) => [...prevValue, id]);
  };

  const handleStartTimeChange = useCallback(
    (newValue) => {
      const selectedTime = moment(newValue, H12_TIME_FORMAT);
      const end = moment(endTime, H12_TIME_FORMAT);
      setStartTime(newValue);
      if (!!endTime.length && selectedTime.isSameOrAfter(end)) {
        setError('You have been inputted wrong start time');
        return false;
      }
      if (error) {
        setError('');
      }
      return false;
    },
    [error, endTime, setError, setStartTime],
  );

  const handleEndTimeChange = useCallback(
    (newValue) => {
      const selectedTime = moment(newValue, H12_TIME_FORMAT);
      const start = moment(startTime, H12_TIME_FORMAT);
      setEndTime(newValue);
      if (!!startTime.length && selectedTime.isSameOrBefore(start)) {
        setError('You have been inputted wrong end time');
        return false;
      }
      if (error) {
        setError('');
      }
      return false;
    },
    [error, setEndTime, setError, startTime],
  );
  const weekdays = useMemo(() => transformAvailabilityRows(), []);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0 28px 28px 28px',
      }}
    >
      <Box sx={{ display: 'flex', gap: '20px', mb: '20px' }}>
        <CustomTimeSelect
          isRequired
          onSelectTimeValue={handleStartTimeChange}
          placeHolder="Start Time"
          selectedTime={startTime}
        />
        <CustomTimeSelect
          isRequired
          onSelectTimeValue={handleEndTimeChange}
          placeHolder="End time"
          selectedTime={endTime}
        />
      </Box>
      {error && <ErrorAlert error={error} />}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        {weekdays.map(({ id, workingDays }) => {
          const isDayDisabledSuAndSa = checkIsDayDisabled(
            workingDays,
            disabledDays,
          );

          return (
            <CustomCheckbox
              key={workingDays}
              checked={selectedDays?.includes(id)}
              isDisabled={isDayDisabledSuAndSa}
              label={
                <Box>
                  <Typography sx={{ color: COLORS.blue[900] }}>
                    {workingDays}
                  </Typography>
                </Box>
              }
              onValueChange={() => handleCheckboxChange(id)}
            />
          );
        })}
      </Box>
    </Box>
  );
}

MultiplyModeContent.propTypes = {
  disabledDays: instanceOf(Object),
  endTime: string,
  error: string,
  selectedDays: instanceOf(Array),
  setEndTime: func,
  setError: func,
  setSelectedDays: func,
  setStartTime: func,
  startTime: string,
};
