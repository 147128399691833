import { Box, Chip, Typography } from '@mui/material';
import moment from 'moment/moment';
import { bool, instanceOf, number } from 'prop-types';
import React from 'react';
import {
  arrivalWindowBorder,
  chipArrivalStartStyle,
  chipArrivalTimeEndStyle,
  chipArrivalWindowStyle,
  chipEndStyle,
  chipStartStyle,
  durationStyle,
} from '../style';

export default function EventHoveredInfo({
  arrivalEndPosition = 0,
  arrivalStartPosition = 0,
  arrivalWindowHeight = 0,
  checkIfShowArrival = false,
  event = {},
  visitWithArrivalWindow = false,
}) {
  return (
    <Box>
      <Chip label={moment(event.start).format('h:mm A')} sx={chipStartStyle} />
      <Chip label={moment(event.end).format('h:mm A')} sx={chipEndStyle} />
      <Box sx={durationStyle}>
        <Typography
          sx={{ color: '#2C2D2E', fontSize: '12px', lineHeight: '16px' }}
        >
          Duration {event.duration} min
        </Typography>
      </Box>
      {!visitWithArrivalWindow && checkIfShowArrival && (
        <Box>
          <Chip
            label={moment(event.arrivalTimeStart).format('h:mm A')}
            sx={{
              ...chipArrivalStartStyle,
              top: `${-arrivalStartPosition + 8}px`,
            }}
          />
          <Chip
            label={moment(event.arrivalTimeEnds).format('h:mm A')}
            sx={{
              ...chipArrivalTimeEndStyle,
              top: `${arrivalEndPosition - 10}px`,
            }}
          />
          <Box
            sx={{
              ...chipArrivalWindowStyle,
              top: `${arrivalEndPosition - 10 - arrivalWindowHeight / 2}px`,
            }}
          >
            <Typography
              sx={{
                color: '#2C2D2E',
                fontSize: '12px',
                lineHeight: '16px',
              }}
            >
              Arrival window
            </Typography>
          </Box>
          <Box
            sx={{
              ...arrivalWindowBorder,
              height: `${arrivalWindowHeight}px`,
              top: `-${arrivalStartPosition}px`,
            }}
          />
        </Box>
      )}
      {visitWithArrivalWindow && (
        <Box>
          <Chip
            label={moment(event.arrivalTimeStart).format('h:mm A')}
            sx={{
              ...chipArrivalStartStyle,
              top: moment(event.start).isAfter(event.arrivalTimeStart)
                ? `-${arrivalStartPosition + 10}px`
                : `${-arrivalStartPosition - 10}px`,
            }}
          />
          <Box
            sx={{
              ...chipArrivalWindowStyle,
              top: `${-arrivalStartPosition + 15}px`,
              width: '75px',
              right: '-80px',
            }}
          >
            <Typography
              sx={{
                color: '#2C2D2E',
                fontSize: '12px',
                lineHeight: '16px',
              }}
            >
              Exact Time
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

EventHoveredInfo.propTypes = {
  arrivalEndPosition: number,
  arrivalStartPosition: number,
  arrivalWindowHeight: number,
  checkIfShowArrival: bool,
  event: instanceOf(Object),
  visitWithArrivalWindow: bool,
};
