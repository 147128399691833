import { Box, IconButton, Typography } from '@mui/material';
import CollapsedIcon from '../../../img/icons/iconButtons/collapsedIcon.svg';
import ExpandedIcon from '../../../img/icons/iconButtons/expandenIcon.svg';
import { bool, func } from 'prop-types';
import React from 'react';
import {
  SHOW_DETAILS_BUTTON_HEIGHT_COLLAPSE,
  SHOW_DETAILS_BUTTON_HEIGHT_EXPAND,
} from '../constants';
import { showDetailsStyles } from '../styles';
import { expandButtonStyles } from './styles';

export default function ExpandButton({
  setShowDetails = false,
  showDetails = () => {},
}) {
  return (
    <IconButton
      className={showDetails ? 'showMoreButtExpand' : 'showMoreButtCollapse'}
      aria-label={showDetails ? 'Collapse' : 'Expand'}
      onClick={() => setShowDetails(!showDetails)}
      sx={{
        ...showDetailsStyles,
        top: showDetails
          ? SHOW_DETAILS_BUTTON_HEIGHT_COLLAPSE
          : SHOW_DETAILS_BUTTON_HEIGHT_EXPAND,
      }}
    >
      {showDetails ? (
        <Box sx={expandButtonStyles}>
          <Typography sx={{ cursor: 'pointer' }}>Collapse</Typography>
          <img src={ExpandedIcon} alt="" />
        </Box>
      ) : (
        <Box sx={expandButtonStyles}>
          <Typography sx={{ cursor: 'pointer' }}>Expand</Typography>
          <img src={CollapsedIcon} alt="" />
        </Box>
      )}
    </IconButton>
  );
}

ExpandButton.propTypes = {
  setShowDetails: func,
  showDetails: bool,
};
