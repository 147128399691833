import { Box, Button, FormControlLabel, Switch } from '@mui/material';
import AddTaskAlert from '../../../../../../pages/ClientDetails/components/CreateTask/components/AddTaskAlert';
import { bool, func, instanceOf } from 'prop-types';
import React, { useMemo, useState } from 'react';
import {
  MAX_TASK_DETAILS_FIELD_LENGTH,
  WORKING_WEEK_LENGTH,
} from '../../../../../../shared/constants';
import CustomInput from '../../../../../../components/Inputs';
import MultilineInput from '../../../../../../components/Inputs/MultilineInput';
import { TaskItemMultiselect } from '../../../../../../components/MultiselectWithBadge';
import CustomSelect from '../../../../../../components/Select';
import {
  carePlanTasks,
  optionalEmptyFields,
  standardTasksCategory,
  taskInitialState,
} from '../../constants';
import CustomAutocomplete from '../Autocomplete';
import CustomCheckbox from '../Checkbox/Checkbox';
import ContentWrapper from './Wrapper';
import { customSwitcher } from './styles';

export default function Content({
  disabledDays = {},
  filtersData = {},
  hasPredefinedData = false,
  hasTheSameTask = false,
  setTaskData = () => {},
  taskData = {},
}) {
  const [customVisitSetup, setCustomVisitSetup] = useState(hasPredefinedData);
  const [oldTaskData, setOldTaskData] = useState(taskInitialState);
  const handleChangeTask = (_, value) =>
    setTaskData({ ...taskData, name: value ? value.name : '' });

  const onSelectChange = (params) => {
    setTaskData({
      ...taskData,
      name:
        params.field === 'category' && !taskData.isCustom ? '' : taskData.name,
      [params.field]: params.value,
    });
  };
  const changeCustomTaskName = (e) =>
    setTaskData({ ...taskData, [e.target.id]: e.target.value });

  const changeDetails = (e) => {
    if (e.target.value.length <= MAX_TASK_DETAILS_FIELD_LENGTH) {
      setTaskData({ ...taskData, [e.target.id]: e.target.value });
    }
  };

  const isStepAvailable = (step) => {
    if (step === 1) {
      return (
        !taskData.isCustom ||
        !!(taskData?.name.length && taskData?.category.length) ||
        hasPredefinedData
      );
    }
    if (step === 2) {
      return (
        !taskData.isCustom ||
        !!(
          taskData?.name.length &&
          taskData?.category.length &&
          taskData?.levelOfAssistance.length
        ) ||
        hasPredefinedData
      );
    }
    return true;
  };

  const selectCustomTask = (e) => {
    setTaskData({
      ...(hasPredefinedData ? taskData : taskInitialState),
      isCustom: e.target.checked,
    });
    setCustomVisitSetup(hasPredefinedData);
    if (!taskData.isCustom) {
      setOldTaskData(taskData);
    }
    if (taskData.isCustom) {
      setTaskData(oldTaskData);
    }
  };

  const isResetRecurrencePatternAvailable = useMemo(
    () =>
      optionalEmptyFields.some(
        (fieldName) =>
          taskData[fieldName].length !== 0 &&
          taskData[fieldName].length !== filtersData[fieldName]?.length,
      ),
    [filtersData, taskData],
  );
  const resetRecurrencePattern = () => {
    setTaskData({ ...taskData, days: [], timesOfDay: [], visitTypes: [] });
  };
  return (
    <ContentWrapper>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        {hasTheSameTask && <AddTaskAlert />}
        <FormControlLabel
          control={
            <Switch
              sx={customSwitcher}
              checked={taskData.isCustom}
              color="secondary"
              onChange={(e) => selectCustomTask(e)}
            />
          }
          label={taskData.isCustom ? 'Custom Task' : 'Standard Task'}
        />
        {!taskData.isCustom && (
          <CustomSelect
            id="category"
            isLoading={false}
            isMultiselect={false}
            options={standardTasksCategory}
            placeHolder="Category"
            selectedValue={taskData?.category}
            setValue={onSelectChange}
          />
        )}
        {taskData.isCustom ? (
          <CustomInput
            changeTaskName={changeCustomTaskName}
            id="name"
            label="Task"
            value={taskData?.name}
          />
        ) : (
          <CustomAutocomplete
            id="name"
            changeValue={handleChangeTask}
            options={carePlanTasks.filter(
              (t) => t.category === taskData.category,
            )}
            selectedValue={taskData?.name || ''}
          />
        )}
        {taskData.isCustom && (
          <CustomSelect
            id="category"
            isLoading={false}
            isMultiselect={false}
            options={standardTasksCategory}
            placeHolder="Category"
            selectedValue={taskData?.category}
            setValue={onSelectChange}
          />
        )}
        {isStepAvailable(1) && (
          <CustomSelect
            id="levelOfAssistance"
            isLoading={false}
            isMultiselect={false}
            options={filtersData?.levelsOfAssistance}
            placeHolder="Level Of Assistance"
            selectedValue={taskData?.levelOfAssistance}
            setValue={onSelectChange}
          />
        )}
        {isStepAvailable(2) && (
          <>
            <MultilineInput
              changeDetails={changeDetails}
              id="details"
              label="Details"
              maxLength={MAX_TASK_DETAILS_FIELD_LENGTH}
              value={taskData.details}
              showHelperText
            />
            <CustomCheckbox
              checked={customVisitSetup}
              isDisabled={hasPredefinedData}
              label="Define Recurrence Pattern"
              onValueChange={() =>
                setCustomVisitSetup((prevState) => !prevState)
              }
            />
            {customVisitSetup && (
              <TaskItemMultiselect
                additionalStyles={{ width: '100%' }}
                disabled={hasPredefinedData}
                hasSelectAllOption
                id="visitTypes"
                isMultiselect
                options={filtersData?.shortVisitTypes}
                placeHolder={
                  taskData?.visitTypes?.length
                    ? 'Visit type'
                    : 'All Visit Types by default'
                }
                selectedValue={taskData?.visitTypes || []}
                setValue={onSelectChange}
              />
            )}
            {customVisitSetup && (
              <TaskItemMultiselect
                additionalStyles={{ width: '100%' }}
                disabled={hasPredefinedData}
                disabledDays={disabledDays}
                hasSelectAllOption
                id="days"
                isMultiselect
                options={filtersData?.weekdays
                  .slice(0, WORKING_WEEK_LENGTH)
                  .map(({ name }) => name)}
                placeHolder={
                  taskData?.days?.length ? 'Days' : 'All Days by default'
                }
                selectedValue={taskData?.days || []}
                setValue={onSelectChange}
              />
            )}
            {customVisitSetup && (
              <TaskItemMultiselect
                additionalStyles={{ width: '100%' }}
                disabled={hasPredefinedData}
                hasSelectAllOption
                id="timesOfDay"
                isMultiselect
                options={filtersData?.timesOfDay.map((time) => time.name)}
                placeHolder={
                  taskData?.timesOfDay?.length
                    ? 'Time Of Day'
                    : 'All Times Of Day by default'
                }
                selectedValue={taskData?.timesOfDay || []}
                setValue={onSelectChange}
              />
            )}
            {customVisitSetup && (
              <Button
                color="secondary"
                disableElevation
                disabled={!isResetRecurrencePatternAvailable}
                onClick={resetRecurrencePattern}
                sx={{ width: '220px', fontSize: '11px', padding: '8px 12px' }}
                variant="outlined"
              >
                reset recurrence pattern
              </Button>
            )}
          </>
        )}
      </Box>
    </ContentWrapper>
  );
}

Content.propTypes = {
  disabledDays: instanceOf(Object),
  filtersData: instanceOf(Object),
  hasPredefinedData: bool,
  hasTheSameTask: bool,
  setTaskData: func,
  taskData: instanceOf(Object),
};
