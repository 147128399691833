import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { bool, func, instanceOf, number } from 'prop-types';
import React from 'react';
import { SHORT_DATE_FORMAT } from '../../../../../shared/constants';
import LocationsFilter from '../../../../../components/FiltersComponents/LocationFilter';
import SearchFilter from '../../../../../components/FiltersComponents/Search';
import { MultiselectWithBadge } from '../../../../../components/MultiselectWithBadge';
import { customFilterStyles, filterStyles } from './styles';

export default function Filters({
  blockedFilters = false,
  completedCounter = 0,
  date = {},
  debouncedSearch = () => {},
  filterData = {},
  isVerification = false,
  locationsList = [],
  setFilterData = () => {},
  setTeamFilter = () => {},
  statusOptions = [],
  teamsFilter = {},
  violationOptions = [],
}) {
  const onSelectChange = (params) => {
    setFilterData({
      ...filterData,
      name: filterData.name,
      [params.field]: params.value,
    });
  };
  return (
    <Box sx={{ ...filterStyles }}>
      {isVerification && (
        <Typography sx={{ fontWeight: 600, mr: '10px' }} variant="body1">
          {moment(date).format(SHORT_DATE_FORMAT)}
        </Typography>
      )}
      <SearchFilter
        blocked={blockedFilters}
        changeValue={debouncedSearch}
        name="caregiver"
        placeholder="Caregiver"
        customFilterStyles={customFilterStyles}
      />
      <SearchFilter
        blocked={blockedFilters}
        changeValue={debouncedSearch}
        name="client"
        placeholder="Client"
        customFilterStyles={customFilterStyles}
      />
      <SearchFilter
        blocked={blockedFilters}
        changeValue={debouncedSearch}
        name="jobId"
        placeholder="Job ID"
        customFilterStyles={customFilterStyles}
      />
      {!isVerification && (
        <MultiselectWithBadge
          options={violationOptions}
          placeHolder="Show"
          id="show"
          hasSelectAllOption
          isMultiselect
          selectedValue={filterData.show || []}
          setValue={onSelectChange}
        />
      )}
      <LocationsFilter
        locationsList={locationsList}
        setFilters={setTeamFilter}
        filters={teamsFilter}
      />
      {!isVerification && (
        <MultiselectWithBadge
          options={statusOptions}
          placeHolder="Status"
          id="status"
          hasSelectAllOption
          isMultiselect
          selectedValue={filterData.status || []}
          setValue={onSelectChange}
        />
      )}
      {isVerification && (
        <Typography sx={{ fontSize: '13px' }} variant="body2">
          COMPLETED VISITS: {completedCounter}
        </Typography>
      )}
    </Box>
  );
}

Filters.propTypes = {
  blockedFilters: bool,
  completedCounter: number,
  date: instanceOf(Object),
  debouncedSearch: func,
  filterData: instanceOf(Object),
  isVerification: bool,
  locationsList: instanceOf(Array),
  setFilterData: func,
  setTeamFilter: func,
  statusOptions: instanceOf(Array),
  teamsFilter: instanceOf(Object),
  violationOptions: instanceOf(Array),
};
