import { COLORS } from '../../../../../../shared/styles/Theme';

export const addModalBoxStyles = {
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  mr: '20px',
  maxHeight: '40px',
};
export const addTeamButtonStyles = {
  '&.MuiButton-root.Mui-disabled': {
    backgroundColor: 'transparent',
    color: COLORS.blue[500],
    opacity: 0.7,
  },
};
