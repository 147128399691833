import { MsalProvider } from '@azure/msal-react';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import theme from './shared/styles/Theme';
import App from './App';
import { pcaInstance } from './config';
import store from './store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MsalProvider instance={pcaInstance}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Provider store={store}>
              <App />
            </Provider>
          </LocalizationProvider>
        </ThemeProvider>
      </MsalProvider>å
    </BrowserRouter>
  </React.StrictMode>,
);
