import { Box, Dialog, DialogTitle, Typography } from '@mui/material';
import Arrow from '../../../../img/icons/arrow_forward.svg';
import { bool, func, instanceOf } from 'prop-types';
import React, { useState } from 'react';
import {
  CALIFORNIA_TIME_ZONE,
  CONVERT_TO_UTC_FORMAT,
  TransitionSlide,
} from '../../../../shared/constants';
import { COLORS } from '../../../../shared/styles/Theme';
import { convertDateToUTC } from '../../../../shared/utils/common';
import VisitTaskTable from './VisitTaskTable';
import { taskSelectionModels } from './constants';
import { dialogPaperStyles } from './styles';

export default function TaskChangesModal({
  actualVisit = {},
  carePlanChanges = {},
  openDialog = false,
  setOpenDialog = () => {},
  updateVisitData = () => {},
}) {
  const [openTasks, setOpenTasks] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    taskSelectionModels.current,
  );
  const updateTasks = () => {
    updateVisitData([
      {
        ...actualVisit?.event,
        arrivalEnd: convertDateToUTC({
          date: `${actualVisit?.event?.date} ${actualVisit?.event?.arrivalTimeEnds}`,
          dateFormat: CONVERT_TO_UTC_FORMAT,
          dateTimeZone: CALIFORNIA_TIME_ZONE,
        }),
        arrivalStart: convertDateToUTC({
          date: `${actualVisit?.event?.date} ${actualVisit?.event?.arrivalTimeStart}`,
          dateFormat: CONVERT_TO_UTC_FORMAT,
          dateTimeZone: CALIFORNIA_TIME_ZONE,
        }),
        arrivalTime: convertDateToUTC({
          date: `${actualVisit?.event?.date} ${actualVisit?.event?.start}`,
          dateFormat: CONVERT_TO_UTC_FORMAT,
          dateTimeZone: CALIFORNIA_TIME_ZONE,
        }),
        team: actualVisit.event.region,
        careProgramTasks:
          selectedOption === taskSelectionModels.current
            ? carePlanChanges.previousCarePlanTasks
            : carePlanChanges.newCarePlanTasks,
      },
    ]);
    setOpenTasks(false);
    setOpenDialog(false);
  };
  return (
    <div>
      <Dialog
        disableEscapeKeyDown
        open={openDialog}
        keepMounted={false}
        TransitionComponent={TransitionSlide}
        PaperProps={{
          sx: { ...dialogPaperStyles },
        }}
      >
        <DialogTitle>
          <Typography variant="h4">The Visit was Successfully Moved</Typography>
        </DialogTitle>
        <Typography sx={{ p: '24px' }}>
          What would you like to do with the visit tasks?
        </Typography>
        <Box
          sx={{
            padding: '0 10px',
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Box
            onClick={() => {
              setOpenTasks(true);
              setSelectedOption(taskSelectionModels.current);
            }}
            sx={{
              display: 'flex',
              gap: 2,
              cursor: 'pointer',
              p: '10px',
            }}
          >
            <img src={Arrow} alt="arrow" />
            <Typography
              variant="body1"
              sx={{ color: COLORS.blue[700], cursor: 'pointer' }}
            >
              To keep the current tasks
            </Typography>
          </Box>
          <Box
            onClick={() => {
              setOpenTasks(true);
              setSelectedOption(taskSelectionModels.future);
            }}
            sx={{ display: 'flex', gap: 2, cursor: 'pointer', p: '10px' }}
          >
            <img src={Arrow} alt="arrow" />
            <Typography
              variant="body1"
              sx={{ color: COLORS.blue[700], cursor: 'pointer' }}
            >
              Tasks from the Care Plan according to the new weekday & time
            </Typography>
          </Box>
        </Box>
      </Dialog>
      <VisitTaskTable
        tasks={
          selectedOption === '0'
            ? carePlanChanges?.previousCarePlanTasks
            : carePlanChanges?.newCarePlanTasks
        }
        openTaskDialog={openTasks}
        setOpenTaskDialog={setOpenTasks}
        updateTasks={updateTasks}
      />
    </div>
  );
}

TaskChangesModal.propTypes = {
  openDialog: bool,
  actualVisit: instanceOf(Object),
  carePlanChanges: instanceOf(Object),
  setOpenDialog: func,
  updateVisitData: func,
};
