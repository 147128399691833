import { Box, Typography } from '@mui/material';
import React from 'react';
import HistoryBulletList from '../../components/HistoryBulletList';
import ClientActivityBullet from './components/ClientActivityBullet';

export const INITIAL_ACTIVITY_FILTERS = {
  search: '',
  user: '',
  activity: [],
};
export const activityColumns = (clients) => [
  {
    field: 'activity',
    headerName: 'Activity',
    minWidth: 240,
    sortable: false,
    renderCell: ({ row }) => (
      <Typography>
        {clients ? row.activity : row?.changes[0]?.activity}
      </Typography>
    ),
  },
  {
    field: 'changes',
    headerName: 'Changes',
    minWidth: 540,
    sortable: false,
    renderCell: ({ row }) =>
      clients ? (
        <ClientActivityBullet changes={row} />
      ) : (
        <HistoryBulletList changes={row.changes} />
      ),
  },
  {
    field: 'dateTime',
    headerName: 'Date & Time',
    sortable: false,
    minWidth: 340,
    renderCell: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{row.dateCreated}</Typography>
      </Box>
    ),
  },
  {
    field: 'user',
    headerName: 'User',
    sortable: false,
    minWidth: 330,
    renderCell: (params) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{params.row.author}</Typography>
      </Box>
    ),
  },
];

export const DummyData = [
  {
    activity: 'Visit Updates',
    dateCreated: 'Wed 8 May 2024, 4:05:13 PM',
    changes: [
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'status',
        from: 'Pending',
        to: 'Approved',
      },
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'modifiedDate',
        from: 'May 8, 2024',
        to: 'May 8, 2024',
      },
    ],
    author: 'polina@tandem.care',
    objectDescription: '{}',
    id: 13,
  },
  {
    activity: 'Visit Updates',
    dateCreated: 'Wed 8 May 2024, 1:36:04 PM',
    changes: [
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'status',
        from: 'Approved',
        to: 'Cancelled',
      },
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'modifiedDate',
        from: 'May 2, 2024',
        to: 'May 8, 2024',
      },
    ],
    author: 'harutyun.harutyunyan@tandem.care',
    objectDescription: '{}',
    id: 16,
  },
  {
    activity: 'Profile Updates',
    dateCreated: 'Wed 8 May 2024, 1:34:46 PM',
    changes: [
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'status',
        from: 'Approved',
        to: 'Cancelled',
      },
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'modifiedDate',
        from: 'Apr 8, 2024',
        to: 'May 8, 2024',
      },
    ],
    author: 'harutyun.harutyunyan@tandem.care',
    objectDescription: '{}',
    id: 9,
  },
  {
    dateCreated: 'Fri 3 May 2024, 1:41:27 PM',
    changes: [
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'status',
        from: 'Pending',
        to: 'Approved',
      },
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'modifiedDate',
        from: 'May 3, 2024',
        to: 'May 3, 2024',
      },
    ],
    author: 'polina@tandem.care',
    objectDescription: '{}',
    id: 1,
  },
  {
    dateCreated: 'Thu 2 May 2024, 1:54:50 PM',
    changes: [
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'status',
        from: 'Pending',
        to: 'Approved',
      },
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'modifiedDate',
        from: 'May 2, 2024',
        to: 'May 2, 2024',
      },
    ],
    author: 'polina@tandem.care',
    objectDescription: '{}',
    id: 10,
  },
  {
    dateCreated: 'Thu 2 May 2024, 1:50:22 PM',
    changes: [
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'status',
        from: 'Pending',
        to: 'Approved',
      },
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'modifiedDate',
        from: 'May 2, 2024',
        to: 'May 2, 2024',
      },
    ],
    author: 'polina@tandem.care',
    objectDescription: '{}',
    id: 4,
  },
  {
    dateCreated: 'Thu 2 May 2024, 1:49:42 PM',
    changes: [
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'status',
        from: 'Pending',
        to: 'Approved',
      },
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'modifiedDate',
        from: 'May 2, 2024',
        to: 'May 2, 2024',
      },
    ],
    author: 'polina@tandem.care',
    objectDescription: '{}',
    id: 11,
  },
  {
    dateCreated: 'Thu 2 May 2024, 1:46:36 PM',
    changes: [
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'status',
        from: 'Pending',
        to: 'Approved',
      },
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'modifiedDate',
        from: 'May 2, 2024',
        to: 'May 2, 2024',
      },
    ],
    author: 'polina@tandem.care',
    objectDescription: '{}',
    id: 14,
  },
  {
    dateCreated: 'Fri 26 Apr 2024, 7:10:51 PM',
    changes: [
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'status',
        from: 'Pending',
        to: 'Approved',
      },
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'modifiedDate',
        from: 'Apr 26, 2024',
        to: 'Apr 26, 2024',
      },
    ],
    author: 'Denis.Rumiantsev@tandem.care',
    objectDescription: '{}',
    id: 6,
  },
  {
    dateCreated: 'Fri 26 Apr 2024, 7:09:18 PM',
    changes: [
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'status',
        from: 'Pending',
        to: 'Approved',
      },
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'modifiedDate',
        from: 'Apr 26, 2024',
        to: 'Apr 26, 2024',
      },
    ],
    author: 'Denis.Rumiantsev@tandem.care',
    objectDescription: '{}',
    id: 3,
  },
  {
    dateCreated: 'Tue 9 Apr 2024, 12:34:00 PM',
    changes: [
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'status',
        from: 'Pending',
        to: 'Approved',
      },
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'modifiedDate',
        from: 'Apr 9, 2024',
        to: 'Apr 9, 2024',
      },
    ],
    author: 'harutyun.harutyunyan@tandem.care',
    objectDescription: '{}',
    id: 2,
  },
  {
    dateCreated: 'Tue 9 Apr 2024, 12:29:00 PM',
    changes: [
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'status',
        from: 'Pending',
        to: 'Approved',
      },
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'modifiedDate',
        from: 'Apr 9, 2024',
        to: 'Apr 9, 2024',
      },
    ],
    author: 'harutyun.harutyunyan@tandem.care',
    objectDescription: '{}',
    id: 5,
  },
  {
    dateCreated: 'Mon 8 Apr 2024, 5:28:42 PM',
    changes: [
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'status',
        from: 'Pending',
        to: 'Approved',
      },
      {
        type: 'primitive',
        operation: 'update',
        propertyName: 'modifiedDate',
        from: 'Apr 8, 2024',
        to: 'Apr 8, 2024',
      },
    ],
    author: 'polina@tandem.care',
    objectDescription: '{}',
    id: 7,
  },
];
