// import { Typography } from '@mui/material';
import React from 'react';
import ClientsCalendar from './views/Calendar';
import CarePlan from './views/CarePlan';
import CareProgram from './views/CareProgram';
import ClientActivity from './views/ClientActivity';
import ClientsProfileInfo from './views/Profile/Details';

export const CLIENTS_TABS_MAP = {
  profile: 'profile',
  careProgram: 'care-program',
  carePlan: 'care-plan',
  calendar: 'calendar',
  activity: 'activity',
};
export const CLIENTS_TABS_SUBVIEW_NAMES_MAP = {
  profile: 'current',
  'care-program': 'table',
  'care-plan': 'current',
  calendar: null,
  activity: null,
};

export const CLIENT_DETAILS_TABS = [
  {
    title: 'Profile',
    key: CLIENTS_TABS_MAP.profile,
    child: <ClientsProfileInfo />,
  },
  { title: 'Care Plan', key: CLIENTS_TABS_MAP.carePlan, child: <CarePlan /> },
  {
    title: 'Care Program',
    key: CLIENTS_TABS_MAP.careProgram,
    child: <CareProgram />,
  },
  {
    title: 'Calendar',
    key: CLIENTS_TABS_MAP.calendar,
    child: <ClientsCalendar />,
  },
  {
    title: 'Activity',
    key: CLIENTS_TABS_MAP.activity,
    child: <ClientActivity />,
  },
];
