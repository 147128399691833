import { Box, Typography } from '@mui/material';
import CarePlanBulletList from '../../../pages/ClientDetails/views/CarePlan/components/CarePlanHistory/CarePlanBulletList';
import CareProgramHistoryBulletList from '../../../pages/ClientDetails/views/CareProgram/views/CareProgramHistory/HistoryBulletList';
import { instanceOf } from 'prop-types';
import React from 'react';
import styles from './style.module.css';

const convertTitles = (str) =>
  str
    ?.split(/(?=[A-Z])/)
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(' ');
const getHistoryList = (arr) => {
  const objectDescription = JSON?.parse(arr?.objectDescription);
  return (
    <Box>
      {objectDescription?.externalId && (
        <Box>
          <Typography sx={{ pt: '6px' }}>
            Visit: {objectDescription?.externalId}
          </Typography>
          <Typography sx={{ pt: '6px' }}>
            Visit Type : {objectDescription?.visitType}
          </Typography>
        </Box>
      )}
      <ul className={styles.bulletList}>
        {arr?.changes?.map((item, index) => {
          const key = `${item?.operation}-${item?.propertyName}-${item?.id}${index}`;
          if (item?.operation === 'update') {
            return (
              <li
                key={key}
                className={
                  item.to === ''
                    ? `${styles.bulletListItem} ${styles.removedType}`
                    : styles.bulletListItem
                }
              >
                <span className={styles.boldWeight}>
                  {convertTitles(item?.propertyName)}
                </span>
                {item.to !== '' ? (
                  <span>
                    is
                    <span className={styles.boldWeight}>{item.visitType}</span>
                    changed from
                    <span className={styles.oldTime}>{item.from}</span> to
                    <span className={styles.newTime}> {item.to}</span>
                  </span>
                ) : (
                  <span> was removed</span>
                )}
              </li>
            );
          }
          if (item?.operation === 'remove') {
            return (
              <li
                key={key}
                className={`${styles.bulletListItem} ${styles.removedType}`}
              >
                <span className={styles.boldWeight}>
                  {item.secondName && item.secondName}
                  {item.secondName && "'s"} {convertTitles(item?.propertyName)}
                </span>
                ({item.from}) was removed
              </li>
            );
          }
          if (item?.operation === 'insert') {
            return (
              <li
                key={key}
                className={`${styles.bulletListItem} ${styles.addedType}`}
              >
                <span className={styles.boldWeight}>
                  {item.secondName && item.secondName}
                  {item.secondName && "'s"} {convertTitles(item?.propertyName)}
                </span>
                ({item.to}) was added
              </li>
            );
          }
          return false;
        })}
      </ul>
    </Box>
  );
};

const renderHistoryBullet = (changes) => {
  if (changes.activity === 'Care Program') {
    return <CareProgramHistoryBulletList changes={changes.changes} />;
  }
  if (changes.activity === 'Care Plan') {
    return <CarePlanBulletList changes={changes.changes} />;
  }
  return <Box sx={{ pl: '20px' }}>{getHistoryList(changes)}</Box>;
};
export default function ClientActivityBullet({ changes = [] }) {
  return <Box>{renderHistoryBullet(changes)}</Box>;
}

ClientActivityBullet.propTypes = {
  changes: instanceOf(Object),
};
