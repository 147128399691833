import { Typography } from '@mui/material';
import GenderSelector from '../../../../pages/CaregiverDetails/view/Profile/GenderSelector';
import LanguagesSelector from '../../../../pages/CaregiverDetails/view/Profile/LanguagesSelector';
import React from 'react';
import SkillBadge from '../../components/SkillBadge';
// import CaregiverTypeSelector from './CaregiverTypeSelector';
import PrimaryAndSecondaryRegionsInfo from './PrimaryAndSecondaryRegionsInfo';
import SecondaryRegionsInfo from './PrimaryAndSecondaryRegionsInfo/SecondaryRegionsInfo';
import PrimaryRegionsSelector from './PrimaryRegionsSelector';
import SecondaryRegionsSelector from './SecondaryRegionsSelector';
import SkillsSelector from './SkillsSelector';
import StatusSelector from './StatusSelector';

export const getInfoComponent = ({ editMode, type, value }) => {
  if (type === 'badge') {
    return <StatusSelector />;
  }
  if (type === 'text') {
    return <Typography variant="body1">{value || ''}</Typography>;
  }
  if (type === 'primaryTeams') {
    return editMode ? (
      <PrimaryRegionsSelector />
    ) : (
      <PrimaryAndSecondaryRegionsInfo value={value} isPrimary />
    );
  }
  if (type === 'secondaryTeams') {
    return editMode ? (
      <SecondaryRegionsSelector />
    ) : (
      <SecondaryRegionsInfo value={value} />
    );
  }
  if (type === 'phone') {
    const numericPhoneNumber = value?.replace(/\D/g, '');

    const formattedPhoneNumber = numericPhoneNumber
      ? `(${numericPhoneNumber?.slice(0, 3)}) ${numericPhoneNumber?.slice(
          3,
          6,
        )}-${numericPhoneNumber?.slice(6)}`
      : '';
    return (
      <Typography variant="body1">{formattedPhoneNumber || ''}</Typography>
    );
  }
  // if (type === 'caregiverType') {
  //   return editMode ? (
  //     <CaregiverTypeSelector />
  //   ) : (
  //     <Typography variant="body1">{value || ''}</Typography>
  //   );
  // }
  if (type === 'skills') {
    return editMode ? <SkillsSelector /> : <SkillBadge skills={value} />;
  }
  if (type === 'gender') {
    return editMode ? (
      <GenderSelector />
    ) : (
      <Typography variant="body1">{value || ''}</Typography>
    );
  }
  if (type === 'languages') {
    return editMode ? <LanguagesSelector /> : <SkillBadge skills={value} />;
  }
  return false;
};
