import { COLORS } from '../../../../../../../shared/styles/Theme';

export const careProgramVisitActionsWrapper = {
  display: 'flex',
  width: '100%',
  gap: '16px',
  justifyContent: 'flex-end',
};

export const textButtonStyles = {
  color: COLORS.blue[700],
  textTransform: 'capitalize',
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '20px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
};
