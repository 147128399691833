import React from 'react';
import CareProgramCalendarView from './views/CareProgramCalendarView';
import CareProgramHistory from './views/CareProgramHistory';
import CareProgramTable from './views/CareProgramTableView/CareProgramTableView';
import CareProgramTableHeader from './views/CareProgramTableView/CareProgramTableViewHeader';

export const getCareProgramComponent = (type, careProgramVersion) => {
  if (type === 'table') {
    return <CareProgramTable />;
  }
  if (type === 'history') {
    return <CareProgramHistory />;
  }
  if (type === 'calendar') {
    return <CareProgramCalendarView careProgramVersion={careProgramVersion} />;
  }
  return false;
};

export const getCareProgramRightHeader = (
  type,
  careProgramVersion,
  setCareProgramVersion,
) => {
  if (type === 'table') {
    return <CareProgramTableHeader view={type} />;
  }
  if (type === 'history') {
    return false;
  }
  if (type === 'calendar') {
    return (
      <CareProgramTableHeader
        view={type}
        careProgramVersion={careProgramVersion}
        setCareProgramVersion={setCareProgramVersion}
      />
    );
  }
  return false;
};
