/* eslint-disable no-param-reassign */
import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetCaregiverHistoryQuery } from '../../../../../api/Caregivers/api';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import {
  FULL_DATE_H12_STANDARD_FORMAT,
  HISTORY_UPDATE_FORMAT,
  ISO_DATE_ONLY_FORMAT,
  SHORT_DATE_FORMAT,
  TIME_OFF_REQUEST_DATE_FORMAT,
} from '../../../../../shared/constants';
import AlertContext from '../../../../../components/Alert';
import CustomTable from '../../../../../components/Table';
import { PAGE_SIZE, PAGE_SIZE_OPTIONS, historyColumns } from '../constants';

export default function CaregiverHistoryTable() {
  const apiRef = useGridApiRef();
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const { data, isLoading, error } = useGetCaregiverHistoryQuery(id);

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);
  const updatedEvents = data
    ?.map((event, index) => {
      const changes = JSON.parse(event?.changes);
      const changesArray = [];
      const onlyPrimitives = changes
        ?.filter((type) => type.type === 'primitive')
        ?.map((change) => change);
      if (onlyPrimitives) {
        changesArray.push(...onlyPrimitives);
      }
      const primaryRegionsObject = changes
        ?.filter((change) => change.type === 'collection')
        ?.filter((change) => change.propertyName === 'primaryTeams')
        ?.map((change) => change.changes)
        .flat();

      const changePropertyNamePrimary = primaryRegionsObject?.map((change) => ({
        ...change,
        propertyName: 'primaryRegion',
      }));
      if (changePropertyNamePrimary) {
        changesArray.push(...changePropertyNamePrimary);
      }
      const secondaryRegionsObject = changes
        ?.filter((change) => change.type === 'collection')
        ?.filter((change) => change.propertyName === 'secondaryTeams')
        ?.map((change) => change.changes)
        ?.flat();

      const changePropertyNameSecondary = secondaryRegionsObject?.map(
        (change) => ({
          ...change,
          propertyName: 'secondaryRegion',
        }),
      );
      if (changePropertyNameSecondary) {
        changesArray.push(...changePropertyNameSecondary);
      }

      const onlySkills = changes
        ?.filter((type) => type.type === 'collection')
        ?.filter((change) => change.propertyName === 'skills')
        ?.map((change) => change)
        ?.map((changeArray) => changeArray.changes)
        ?.flatMap((change) => change);

      const changeSkillsName = onlySkills?.map((change) => ({
        ...change,
        propertyName: 'skills',
      }));
      if (changeSkillsName) {
        changesArray.push(...changeSkillsName);
      }
      const onlyLanguages = changes
        ?.filter((type) => type.type === 'collection')
        ?.filter((change) => change.propertyName === 'languages')
        ?.map((change) => change)
        ?.map((changeArray) => changeArray.changes)
        ?.flatMap((change) => change);

      const changeLanguagesName = onlyLanguages?.map((change) => ({
        ...change,
        propertyName: 'languages',
      }));
      if (changeLanguagesName) {
        changesArray.push(...changeLanguagesName);
      }

      changesArray.forEach((change) => {
        if (change.propertyName === 'tandemAnniversary' && change.to) {
          change.to = moment(change.to, FULL_DATE_H12_STANDARD_FORMAT).format(
            TIME_OFF_REQUEST_DATE_FORMAT,
          );
        }
        if (
          change.propertyName === 'activeFrom' ||
          change.propertyName === 'inactiveFrom'
        ) {
          change.to = moment(change?.to, ISO_DATE_ONLY_FORMAT).format(
            SHORT_DATE_FORMAT,
          );
          change.from = moment(change?.from, ISO_DATE_ONLY_FORMAT).format(
            SHORT_DATE_FORMAT,
          );
        }
      });

      if (changesArray.length === 0) {
        return false;
      }
      const hasNonNoChange = changesArray.some(
        (change) => change.operation !== 'noChange',
      );
      if (!hasNonNoChange) {
        return false;
      }
      return {
        ...event,
        changes: changesArray,
        dateCreated: moment(event.dateCreated).format(HISTORY_UPDATE_FORMAT),
        id: index + 1,
      };
    })
    .filter(Boolean);
  const sortedUpdatedEvents = updatedEvents?.sort((a, b) => {
    const dateA = new Date(a.dateCreated);
    const dateB = new Date(b.dateCreated);
    return dateB - dateA;
  });
  return (
    <Box>
      <CustomTable
        apiRef={apiRef}
        columns={historyColumns}
        headerHeight={44}
        isLoading={isLoading}
        rows={sortedUpdatedEvents}
        pageSize={PAGE_SIZE}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
      />
    </Box>
  );
}
