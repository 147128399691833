import { Box, Typography } from '@mui/material';
import { bool, func, string } from 'prop-types';
import React from 'react';
import { MAX_TASK_DETAILS_FIELD_LENGTH } from '../../../../shared/constants';
import MultilineInput from '../../../../components/Inputs/MultilineInput';
import { columnTitle, visitInfoManagementColumnStyles } from './styles';

function VisitInfoManagement({
  disabled = false,
  updateTemporaryVisitData = () => {},
  visitNotes = '',
  visitDetails = '',
}) {
  const changeVisitNotes = (e) =>
    updateTemporaryVisitData({
      visitNotes: e.target.value,
    });
  const changeVisitDetails = (e) =>
    updateTemporaryVisitData({
      visitDetails: e.target.value,
    });
  return (
    <Box
      sx={{
        ...visitInfoManagementColumnStyles,
        width: '100%',
        '& .MuiFilledInput-root.MuiInputBase-root': {
          alignItems: 'start',
        },
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Typography sx={columnTitle}>Additional Visit Details</Typography>
        <MultilineInput
          customStyles={{ height: '90%' }}
          changeDetails={(e) => changeVisitDetails(e)}
          disabled={disabled}
          id="details"
          maxLength={MAX_TASK_DETAILS_FIELD_LENGTH}
          value={visitDetails || ''}
          showHelperText
        />
      </Box>
      <Box sx={{ width: '100%' }}>
        <Typography sx={columnTitle}>Caregiver Visit Notes</Typography>
        <MultilineInput
          customStyles={{ height: '90%' }}
          changeDetails={(e) => changeVisitNotes(e)}
          disabled={disabled}
          id="details"
          maxLength={MAX_TASK_DETAILS_FIELD_LENGTH}
          value={visitNotes || ''}
          showHelperText
        />
      </Box>
    </Box>
    // </Box>
  );
}

VisitInfoManagement.propTypes = {
  disabled: bool,
  updateTemporaryVisitData: func,
  visitNotes: string,
  visitDetails: string,
};

export default React.memo(VisitInfoManagement);
