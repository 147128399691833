import { Typography } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { instanceOf } from 'prop-types';
import React from 'react';
import { getNoData } from '../../../../../shared/utils/common';
import CustomTable from '../../../../../components/Table';
import { reschedulingVisitColumns } from '../constants';
import { CustomTableStyles } from '../styles';
import AffectedVisitsAccordion from './index';

export default function AffectedVisitsAccordionWrapper({
  affectedVisits = {},
  listOfDates = [],
}) {
  const apiRef = useGridApiRef();
  return (
    <>
      <Typography sx={{ mb: '20px', mt: '20px' }} variant="h4">
        Affected Visits
      </Typography>
      {affectedVisits &&
        listOfDates?.map((date) => {
          const dateVisits = affectedVisits[date];
          return (
            <AffectedVisitsAccordion
              key={date}
              hasError={dateVisits.some(
                (visit) => visit?.status?.status === 'error',
              )}
              hasWarning={dateVisits.some(
                (visit) => visit?.status?.status === 'warning',
              )}
              title={date}
              visitsCount={dateVisits.length}
            >
              <CustomTable
                apiRef={apiRef}
                columns={reschedulingVisitColumns({ isMainVisit: false })}
                customStyles={CustomTableStyles}
                headerHeight={44}
                noRowsOverlay={() =>
                  getNoData({ title: 'Have no affected visits on this day' })
                }
                rowHeight={107}
                rows={dateVisits}
                withoutPagination
              />
            </AffectedVisitsAccordion>
          );
        })}
    </>
  );
}

AffectedVisitsAccordionWrapper.propTypes = {
  affectedVisits: instanceOf(Object),
  listOfDates: instanceOf(Array),
};
