import { Box, Typography } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  H12_TIME_FORMAT,
  ISO_DATE_ONLY_FORMAT,
  SHORT_DATE_FORMAT,
  TASKS_TYPES,
  visitStatusesMap,
} from '../../../../shared/constants';
import { VisitInfoAddTaskContext, VisitInfoTypeContext } from '../../../../shared/context';
import { COLORS } from '../../../../shared/styles/Theme';
import Loader from '../../../../components/Loader';
import { constraintsIconsTypes, visitDetailsViewsMap } from '../../constants';
import { emptyPageWrapper } from '../../styles';
import ConstraintsAlert from '../ConstraintsAlert';
import CustomBreadcrumbs from './Breadcrumbs';
import Title from './Title';
import VisitDetailsInfo from './VisitDetailsInfo';
import VisitHistory from './VisitHistory';
import { visitDetailsWrapper } from './styles';

export default function VisitDetails() {
  const [showedTasksType, setShowedTasksType] = useState(
    TASKS_TYPES.visitTasks,
  );
  const [detailsView, setDetailsView] = useState(visitDetailsViewsMap.details);
  const {
    closeDialog,
    initialVisitData,
    temporaryVisitData,
    updateTemporaryVisitData,
    updatingVisitDetails,
    visitInfoLoading,
  } = useContext(VisitInfoTypeContext);
  const { addNewTask } = useContext(VisitInfoAddTaskContext);
  const breadcrumbsTitle = useMemo(() => {
    const timeRange = temporaryVisitData?.isExactTime
      ? temporaryVisitData?.arrivalTimeStart
      : `${temporaryVisitData?.arrivalTimeStart} - ${temporaryVisitData?.arrivalTimeEnd}`;

    return `${temporaryVisitData?.title}, ${moment(
      temporaryVisitData?.date,
      ISO_DATE_ONLY_FORMAT,
    ).format(SHORT_DATE_FORMAT)} ${timeRange}`;
  }, [
    temporaryVisitData?.arrivalTimeStart,
    temporaryVisitData?.arrivalTimeEnd,
    temporaryVisitData?.date,
    temporaryVisitData?.isExactTime,
    temporaryVisitData?.title,
  ]);

  const setNewStatus = useCallback(
    ({ newStatus, reason }) => {
      let newData = { status: newStatus };
      if (newStatus === visitStatusesMap.inProgress) {
        newData = {
          status: newStatus,
          actualTimeStart: moment().format(H12_TIME_FORMAT),
          actualTimeEnd: moment()
            .add(temporaryVisitData?.duration, 'minutes')
            .format(H12_TIME_FORMAT),
        };
      }
      if (newStatus === visitStatusesMap.completed) {
        newData = {
          status: newStatus,
          duration: moment
            .duration(
              moment().diff(
                moment(temporaryVisitData?.actualTimeStart, H12_TIME_FORMAT),
              ),
            )
            .asMinutes(),
          actualTimeEnd: moment().format(H12_TIME_FORMAT),
        };
      }
      if (newStatus === visitStatusesMap.canceled) {
        newData = {
          status: newStatus,
          cancellationReason: reason,
        };
      }
      updateTemporaryVisitData(newData);
    },
    [
      temporaryVisitData?.duration,
      temporaryVisitData?.actualTimeStart,
      updateTemporaryVisitData,
    ],
  );

  const addVisitTask = useCallback(
    () =>
      addNewTask({
        ...temporaryVisitData,
      }),
    [addNewTask, temporaryVisitData],
  );

  if (visitInfoLoading || updatingVisitDetails) {
    return (
      <Box sx={emptyPageWrapper}>
        <Loader />
      </Box>
    );
  }
  if (!temporaryVisitData?.id) {
    return (
      <Box sx={emptyPageWrapper}>
        <Typography variant="h4" style={{ color: COLORS.blue[900] }}>
          The visit you’re looking for doesn’t exist.
        </Typography>
      </Box>
    );
  }

  const constraintsViolations =
    initialVisitData?.violatedHardConstraints ||
    initialVisitData?.violatedSoftConstraints;
  return (
    <Box sx={visitDetailsWrapper}>
      <CustomBreadcrumbs
        clientId={temporaryVisitData?.client?.id}
        closeDialog={closeDialog}
        title={breadcrumbsTitle}
      />
      <Title
        cost={temporaryVisitData?.visitCost}
        detailsView={detailsView}
        disabledAll
        jobId={temporaryVisitData?.externalId}
        initialStatus={initialVisitData?.status}
        repeated={temporaryVisitData?.repeated}
        setDetailsView={setDetailsView}
        setNewStatus={setNewStatus}
        status={temporaryVisitData?.status}
        title={temporaryVisitData?.title}
        type={temporaryVisitData?.visitType?.toLowerCase()}
      />
      {!!constraintsViolations && (
        <ConstraintsAlert
          alertData={constraintsViolations}
          iconType={constraintsIconsTypes.triangle}
        />
      )}
      {detailsView === visitDetailsViewsMap.details ? (
        <VisitDetailsInfo
          addVisitTask={addVisitTask}
          initialVisitData={initialVisitData}
          setShowedTasksType={setShowedTasksType}
          showedTasks={temporaryVisitData[showedTasksType]}
          showedTasksType={showedTasksType}
          temporaryVisitData={temporaryVisitData}
          updateTemporaryVisitData={updateTemporaryVisitData}
        />
      ) : (
        <VisitHistory
          date={initialVisitData?.date}
          visitId={initialVisitData.id}
        />
      )}
    </Box>
  );
}
