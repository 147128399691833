import { Box, Chip, Tooltip, Typography } from '@mui/material';
import {
  chipComponentStyle,
  tooltipStyles,
} from '../../../../pages/CaregiverDetails/components/SkillBadge/styles';
import { instanceOf } from 'prop-types';
import React from 'react';

export default function SkillBadge({ skills: initialSkills = [] }) {
  const skills = initialSkills || [];
  const maxVisibleSkills = 6;
  const visibleSkills = skills?.slice(0, maxVisibleSkills);
  const hiddenSkillsCount = skills.length - maxVisibleSkills;
  const hiddenSkills = skills?.slice(maxVisibleSkills);

  const tooltipContent = (
    <div>
      {hiddenSkills?.map((t, index) => (
        <div
          key={t}
          style={{
            marginBottom: index === hiddenSkills.length - 1 ? '0' : '12px',
          }}
        >
          <Typography variant="body1" style={{ marginLeft: '10px' }}>
            {t}
          </Typography>
        </div>
      ))}
    </div>
  );

  return (
    <Box
      sx={{
        maxWidth: '350px',
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'space-between',
        gap: '10px',
      }}
    >
      {visibleSkills?.map((t) => (
        <Chip key={t} label={t} style={chipComponentStyle} />
      ))}
      {hiddenSkillsCount > 0 && (
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: tooltipStyles,
            },
          }}
          title={tooltipContent}
          placement="right-start"
        >
          <Chip
            label={`+${hiddenSkillsCount} Skills`}
            style={chipComponentStyle}
          />
        </Tooltip>
      )}
    </Box>
  );
}

SkillBadge.propTypes = {
  skills: instanceOf(Array),
};
