import { COLORS } from '../../../../../../shared/styles/Theme';

const getCustomSelectStyles = (colorScheme) => ({
  minHeight: '24px',
  fontSize: '14px',
  fontWeight: '400',
  color: colorScheme.color || '#ffffff',
  borderRadius: '30px',
  '&.MuiOutlinedInput-root': {
    border: 'none',
    backgroundColor: colorScheme.backgroundColor || COLORS.blue[100],
    '.MuiOutlinedInput-input, .MuiOutlinedInput-input.Mui-disabled': {
      alignItems: 'center',
      height: '100%',
      WebkitTextFillColor: colorScheme.color || '#ffffff',
      padding: '2px 32px 2px 10px',
    },
    fieldset: {
      border: 'none',
    },
  },
  '& .MuiSvgIcon-root.MuiSelect-icon': {
    fill: colorScheme.color || '#ffffff',
    width: '20px',
    height: '20px',
    top: 2,
  },
});

const customSelectItem = {
  display: 'flex',
  fontSize: '15px',
  margin: '0 8px',
  color: `${COLORS.blue[900]}`,
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  '&.select-all-item': {
    paddingBottom: 0,
    '&.MuiMenuItem-root:hover': {
      backgroundColor: '#ffffff',
    },
  },
  '&:focus ': {
    backgroundColor: '#ffffff',
  },
  '&:hover': {
    backgroundColor: `${COLORS.blue[50]}`,
  },
  '&.Mui-selected, &.Mui-selected:active,  &.Mui-selected:focus': {
    backgroundColor: '#ffffff',
  },
  '&.Mui-selected:hover': {
    backgroundColor: `${COLORS.blue[50]}`,
    cursor: 'default',
  },
};

const customPaperStyles = {
  padding: '8px 0',
  boxShadow: '0px 4px 12px 2px rgba(180, 180, 180, 0.2)',
  borderRadius: '10px',
  maxHeight: '300px',
  marginTop: '4px',
  marginLeft: '24px',
};

export { customPaperStyles, getCustomSelectStyles, customSelectItem };
