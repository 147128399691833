import { Box, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { SHORT_DATE_FORMAT } from '../../../../shared/constants';
import NationalHolidaysHistoryBullet from './components/NationalHolidaysHistory/NationalHolidaysHistoryBullet';
import TableActions from './components/TableActions';

export const DEFAULT_SORT_MODAL = { field: 'holidayDate', sort: 'asc' };

const comparingHolidayDays = (first, second) => {
  const firstTime = moment(first);
  const secondTime = moment(second);
  if (firstTime.isBefore(secondTime)) {
    return -1;
  }
  if (firstTime.isAfter(secondTime)) {
    return 1;
  }
  return 0;
};
export const columns = [
  {
    field: 'holidayDate',
    headerName: 'Date',
    minWidth: 640,
    renderCell: ({ row }) => {
      const holiday = moment(row.holidayDate);
      return <Typography>{holiday.format(SHORT_DATE_FORMAT)}</Typography>;
    },
    sortComparator: (v1, v2) => comparingHolidayDays(v1, v2),
  },
  {
    field: 'name',
    headerName: 'Holiday Name',
    flex: 1,
    renderCell: ({ row }) => <TableActions row={row} />,
  },
];

export const historyColumns = [
  {
    field: 'dateTime',
    headerName: 'Date & Time',
    sortable: false,
    minWidth: 280,
    renderCell: (params) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{params.row.dateCreated}</Typography>
      </Box>
    ),
  },
  {
    field: 'changes',
    headerName: 'Changes',
    minWidth: 740,
    sortable: false,
    renderCell: (params) => (
      <NationalHolidaysHistoryBullet changes={params.row} />
    ),
  },
  {
    field: 'user',
    headerName: 'User',
    sortable: false,
    minWidth: 330,
    renderCell: (params) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{params.row.author}</Typography>
      </Box>
    ),
  },
];
