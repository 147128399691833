import { Box, Divider } from '@mui/material';
import { node } from 'prop-types';
import React from 'react';
import { crossedOutCellWrapperStyles, crossedOutLineStyles } from './styles';

export default function CrossedOutCell({ children = <div /> }) {
  return (
    <Box sx={crossedOutCellWrapperStyles}>
      <Divider sx={crossedOutLineStyles} />
      {children}
    </Box>
  );
}

CrossedOutCell.propTypes = {
  children: node,
};
