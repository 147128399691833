import { Box, Typography } from '@mui/material';
import {
  useApprovePendingTimeOffRequestMutation,
  useCancelPendingTimeOffRequestMutation,
  useConfirmCancelingApprovedTimeOffRequestMutation,
} from '../../../../api/TimeOffRequest/api';
import moment from 'moment';
import { instanceOf } from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { COLORS } from '../../../../shared/styles/Theme';
import { convertUtcToTeamTimeZoneMomentObject } from '../../../../shared/utils/common';
import AlertContext from '../../../../components/Alert';
import CustomDialog from '../../../../components/Dialog';
import ButtonsGroup from './ButtonsGroup';
import ChangeRequestStatusPossibility from './ChangeRequestStatusPossibility';
import { SchedulerTimeOffRequestsContext } from './context';

export default function RequestsActions({ row = {} }) {
  const { setAlert } = useContext(AlertContext);
  const { setStatusChangingLoading } = useContext(
    SchedulerTimeOffRequestsContext,
  );
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [
    changeRequestStatusPossibilityData,
    setChangeRequestStatusPossibilityData,
  ] = useState(null);

  const [
    cancelPendingTimeOffRequest,
    {
      error: cancelPendingTimeOffRequestError,
      isLoading: cancelPendingTimeOffRequestWaiting,
    },
  ] = useCancelPendingTimeOffRequestMutation();

  const [
    confirmCancelingApprovedTimeOffRequest,
    {
      error: confirmCancelingApprovedTimeOffRequestError,
      isLoading: confirmCancelingApprovedTimeOffRequestWaiting,
    },
  ] = useConfirmCancelingApprovedTimeOffRequestMutation();

  const [
    approvePendingTimeOffRequest,
    {
      error: approvePendingTimeOffRequestError,
      isLoading: approvePendingTimeOffRequestWaiting,
    },
  ] = useApprovePendingTimeOffRequestMutation();

  useEffect(() => {
    if (
      approvePendingTimeOffRequestError ||
      cancelPendingTimeOffRequestError ||
      confirmCancelingApprovedTimeOffRequestError
    ) {
      const errorData =
        cancelPendingTimeOffRequestError ||
        approvePendingTimeOffRequestError ||
        confirmCancelingApprovedTimeOffRequestError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    approvePendingTimeOffRequestError,
    cancelPendingTimeOffRequestError,
    confirmCancelingApprovedTimeOffRequestError,
    setAlert,
  ]);

  const isDataLoading = useMemo(
    () =>
      cancelPendingTimeOffRequestWaiting ||
      confirmCancelingApprovedTimeOffRequestWaiting ||
      approvePendingTimeOffRequestWaiting,
    [
      approvePendingTimeOffRequestWaiting,
      cancelPendingTimeOffRequestWaiting,
      confirmCancelingApprovedTimeOffRequestWaiting,
    ],
  );

  useEffect(() => {
    setStatusChangingLoading(isDataLoading);
  }, [isDataLoading, setStatusChangingLoading]);

  const cancelPendingRequest = useCallback(
    (requestId) => {
      cancelPendingTimeOffRequest(requestId);
      setOpenRejectDialog(false);
    },
    [cancelPendingTimeOffRequest],
  );

  const checkCancelingApprovedRequest = useCallback(
    (requestId) =>
      setChangeRequestStatusPossibilityData({
        actionType: 'cancelRequest',
        requestId,
      }),
    [],
  );

  const checkApprovingPendingRequest = useCallback(
    () =>
      setChangeRequestStatusPossibilityData({
        actionType: 'approveRequest',
        requestId: row.id,
      }),
    [row, setChangeRequestStatusPossibilityData],
  );

  const confirmRequestStatusChanges = useCallback(
    (requestId) => {
      if (changeRequestStatusPossibilityData.actionType === 'approveRequest') {
        approvePendingTimeOffRequest({ requestId });
      }
      if (changeRequestStatusPossibilityData.actionType === 'cancelRequest') {
        confirmCancelingApprovedTimeOffRequest({ requestId });
      }
      setChangeRequestStatusPossibilityData(null);
    },
    [
      approvePendingTimeOffRequest,
      changeRequestStatusPossibilityData,
      confirmCancelingApprovedTimeOffRequest,
      setChangeRequestStatusPossibilityData,
    ],
  );
  const cancelRequest = () => {
    setOpenRejectDialog(false);
    if (row.status?.toLowerCase() === 'approved') {
      checkCancelingApprovedRequest(row.id);
    }
    if (row.status?.toLowerCase() === 'pending') {
      cancelPendingRequest(row.id);
    }
  };
  const showContinueButton = useMemo(
    () =>
      row.status?.toLowerCase() === 'pending' &&
      moment().isBefore(
        convertUtcToTeamTimeZoneMomentObject(row.requestStart),
        'day',
      ),
    [row],
  );
  const showRejectButton = useMemo(
    () =>
      (row.status?.toLowerCase() === 'approved' &&
        moment().isBefore(
          convertUtcToTeamTimeZoneMomentObject(row.requestStart),
          'minutes',
        )) ||
      (row.status?.toLowerCase() === 'pending' &&
        moment().isAfter(
          convertUtcToTeamTimeZoneMomentObject(row.requestStart),
          'minutes',
        )),
    [row],
  );

  return (
    <Box>
      <ChangeRequestStatusPossibility
        cancelChanges={() => setChangeRequestStatusPossibilityData(null)}
        changingStatusData={changeRequestStatusPossibilityData}
        confirmChanges={confirmRequestStatusChanges}
        caregiverData={row.caregiver}
      />
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setOpenRejectDialog(false)}
        submitButtonName="Confirm"
        submitCallback={cancelRequest}
        title="Confirmation"
        openDialog={openRejectDialog}
      >
        <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            Time-Off Request will be cancelled. Do you want to proceed?
          </Typography>
        </Box>
      </CustomDialog>
      <ButtonsGroup
        checkApprovingPendingRequest={checkApprovingPendingRequest}
        openRejectingDialog={() => setOpenRejectDialog(true)}
        showContinueButton={showContinueButton}
        showRejectButton={showRejectButton}
      />
    </Box>
  );
}

RequestsActions.propTypes = {
  row: instanceOf(Object),
};
