import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetHolidaysHistoryQuery } from '../../../../../../api/Administration/api';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import {
  HISTORY_UPDATE_FORMAT,
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  SHORT_DATE_FORMAT,
} from '../../../../../../shared/constants';
import { getNoData } from '../../../../../../shared/utils/common';
import AlertContext from '../../../../../../components/Alert';
import CustomTable from '../../../../../../components/Table';
import { historyColumns } from '../../constants';

export default function NationalHolidaysHistory() {
  const apiRef = useGridApiRef();
  const { setAlert } = useContext(AlertContext);
  const {
    data,
    isLoading,
    error: getTeamsHistoryError,
  } = useGetHolidaysHistoryQuery();

  useEffect(() => {
    if (getTeamsHistoryError) {
      setAlert({
        errorData: getTeamsHistoryError,
        type: 'error',
      });
    }
  }, [getTeamsHistoryError, setAlert]);

  const updatedEvents = data
    ?.map((event, index) => {
      const changesArray = event?.changes ? JSON.parse(event?.changes) : null;
      if (!changesArray) return null;
      const changes = changesArray.map((item) => {
        if (item.propertyName === 'holidayDate') {
          return {
            ...item,
            from: moment(item.from).format(SHORT_DATE_FORMAT),
            to: moment(item.to).format(SHORT_DATE_FORMAT),
          };
        }
        return item;
      });

      let holidayDateObj = null;
      let nameObj = null;
      changes?.forEach((item) => {
        if (item.propertyName === 'holidayDate') {
          holidayDateObj = item;
        } else if (item.propertyName === 'name') {
          nameObj = item;
        }
      });
      const finalChanges = holidayDateObj
        ? [
            {
              ...holidayDateObj,
              nameFrom: nameObj?.from,
              nameTo: nameObj?.to,
            },
          ]
        : [
            {
              ...nameObj,
              nameFrom: holidayDateObj?.from,
              nameTo: holidayDateObj?.to,
            },
          ];

      return {
        ...event,
        changes: finalChanges,
        dateCreated: moment(event?.dateCreated).format(HISTORY_UPDATE_FORMAT),
        id: index + 1,
      };
    })
    .filter(Boolean);
  const sortedUpdatedEvents = updatedEvents?.sort((a, b) => {
    const dateA = new Date(a.dateCreated);
    const dateB = new Date(b.dateCreated);
    return dateB - dateA;
  });
  return (
    <Box sx={{ mt: '30px' }}>
      <CustomTable
        apiRef={apiRef}
        columns={historyColumns}
        headerHeight={44}
        rows={sortedUpdatedEvents}
        isLoading={isLoading}
        noRowsOverlay={() => getNoData({ title: 'No Holidays History yet' })}
        pageSize={PAGE_SIZE}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
      />
    </Box>
  );
}
