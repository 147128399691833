import { Box, Typography } from '@mui/material';
import shadowCaregiverIcon from '../../../../../../../../img/icons/shadow-visit-black-icon.svg';
import { ScheduleCaregiverManagementContext } from '../../../../../../../../pages/Scheduling/context';
import { instanceOf } from 'prop-types';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import CustomTooltip from '../../../../../../../../components/CustomTooltip';
import CustomDialog from '../../../../../../../../components/Dialog';
import UnsavedChangesContent from '../../../../../../../../components/Dialog/UnsavedChangesContent';
import {
  caregiverNameStyles,
  customCellWrapper,
  visitCaregiverInfo,
} from './styles';

export default function ShadowCaregiverCell({ row = {} }) {
  const navigate = useNavigate();
  const { closeCallOutCreation } = useContext(
    ScheduleCaregiverManagementContext,
  );
  const [showConformation, setShowConformation] = useState(false);
  const [navigationUrl, setNavigationUrl] = useState('');

  const onLinkClick = (url) => {
    setNavigationUrl(url);
    setShowConformation(true);
  };

  const closeCreation = () => {
    closeCallOutCreation();
    navigate(navigationUrl);
    setShowConformation(null);
    setNavigationUrl('');
  };
  return (
    <div>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setShowConformation(false)}
        submitButtonName="confirm"
        submitCallback={closeCreation}
        title="Confirmation"
        openDialog={showConformation}
      >
        <UnsavedChangesContent text="Callout creation will be lost. Do you want to proceed?" />
      </CustomDialog>
      <Box sx={customCellWrapper}>
        <Box sx={visitCaregiverInfo}>
          {row?.shadowCaregiver && (
            <CustomTooltip title="Shadow visit">
              <img src={shadowCaregiverIcon} alt="arrivalIcon" />
            </CustomTooltip>
          )}
          {row?.shadowCaregiver ? (
            <Typography
              variant="body1"
              sx={caregiverNameStyles}
              component="span"
              onClick={() =>
                onLinkClick(`/caregivers/${row?.oldShadowCaregiver?.id}`)
              }
            >
              {`${row?.oldShadowCaregiver?.firstName} ${row?.oldShadowCaregiver?.lastName}`}
            </Typography>
          ) : (
            <Typography>-</Typography>
          )}
        </Box>
      </Box>
    </div>
  );
}

ShadowCaregiverCell.propTypes = {
  row: instanceOf(Object),
};
