import { Dialog, DialogContent } from '@mui/material';
import { bool, element, func, instanceOf, string } from 'prop-types';
import React, { createElement } from 'react';
import { TransitionSlide } from '../../shared/constants';
import Title from './Title';
import { dialogContentStyles } from './styles';

function FullWidthDialog({
  backButtonCallback = () => {},
  cancelButtonName = '',
  cancelCallback = () => {},
  children = createElement('div'),
  customTitleButton = null,
  customTitleComponent = null,
  disableAllActions = false,
  disableOnCloseByClickOutside = false,
  disabledSubmit = false,
  hideBackButton = false,
  hideSubmitButtons = false,
  openDialog = false,
  scrollable = {},
  slotProps = {},
  submitButtonName = '',
  submitCallback = () => {},
  title,
}) {
  return (
    <Dialog
      disableEscapeKeyDown={disableOnCloseByClickOutside}
      fullScreen
      keepMounted={false}
      onClose={() => (disableOnCloseByClickOutside ? {} : cancelCallback())}
      open={!!openDialog}
      PaperProps={{
        sx: {
          width: '100%',
          overflow: scrollable ? 'auto' : 'hidden',
        },
      }}
      slotProps={slotProps}
      TransitionComponent={TransitionSlide}
    >
      <Title
        backButtonCallback={backButtonCallback}
        cancelButtonName={cancelButtonName}
        cancelCallback={cancelCallback}
        customTitleButton={customTitleButton}
        customTitleComponent={customTitleComponent}
        disableAllActions={disableAllActions}
        disabled={disabledSubmit || disableAllActions}
        hideBackButton={hideBackButton}
        hideSubmitButtons={hideSubmitButtons}
        submitButtonName={submitButtonName}
        submitCallback={submitCallback}
        title={title}
      />
      <DialogContent
        sx={{
          ...dialogContentStyles,
          overflow: scrollable ? 'auto' : 'hidden',
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
}

FullWidthDialog.propTypes = {
  backButtonCallback: func,
  cancelButtonName: string,
  cancelCallback: func,
  children: element,
  customTitleButton: element,
  customTitleComponent: element,
  disableAllActions: bool,
  disableOnCloseByClickOutside: bool,
  disabledSubmit: bool,
  hideBackButton: bool,
  hideSubmitButtons: bool,
  openDialog: bool,
  scrollable: bool,
  slotProps: instanceOf(Object),
  submitButtonName: string,
  submitCallback: func,
  title: string,
};

export default React.memo(FullWidthDialog);
