import {
  useCheckClientBlockedCaregiversChangesQuery,
  useConfirmBlockedCaregiversMutation,
} from '../../../../../api/Clients/api';
import { bool, func, instanceOf } from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import AlertContext from '../../../../../components/Alert';
import FullWidthDialog from '../../../../../components/FullWidthDialog';
import ScheduleChangesView from './components/ScheduleChangesView';

export default function ChangeBlockedCaregiversSteps({
  blockedCaregivers = [],
  cancelInfoEditing = () => {},
  closeBlockedCaregiversResult = () => {},
  showCheckBlockedCaregiversResult = false,
}) {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const {
    data: checkBlockedCaregiversResponse,
    isLoading: checkBlockedCaregiversResponseLoading,
    isFetching,
    error: checkBlockedCaregiversResponseError,
  } = useCheckClientBlockedCaregiversChangesQuery(
    {
      clientId: id,
      payload: blockedCaregivers,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !showCheckBlockedCaregiversResult,
    },
  );
  const [
    confirmBlockedCaregivers,
    {
      error: confirmBlockedCaregiversError,
      status,
      isLoading: isApproveListLoading,
    },
  ] = useConfirmBlockedCaregiversMutation();

  useEffect(() => {
    if (checkBlockedCaregiversResponseError || confirmBlockedCaregiversError) {
      const errorData =
        checkBlockedCaregiversResponseError || confirmBlockedCaregiversError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    checkBlockedCaregiversResponseError,
    confirmBlockedCaregiversError,
    setAlert,
  ]);

  useEffect(() => {
    if (['fulfilled', 'rejected'].includes(status)) {
      cancelInfoEditing();
    }
  }, [cancelInfoEditing, status]);

  const cancelBlockedCaregiversChanges = () => closeBlockedCaregiversResult();

  const confirmBlockedCaregiversChanges = () => {
    confirmBlockedCaregivers({
      clientId: id,
      requestId: checkBlockedCaregiversResponse?.requestId,
    });
  };

  const isDataLoading =
    isFetching || checkBlockedCaregiversResponseLoading || isApproveListLoading;

  return (
    <FullWidthDialog
      cancelButtonName="cancel"
      cancelCallback={cancelBlockedCaregiversChanges}
      submitButtonName="Confirm"
      submitCallback={confirmBlockedCaregiversChanges}
      disabledSubmit={isDataLoading}
      title="Check Block Caregiver Possibility"
      openDialog={showCheckBlockedCaregiversResult}
    >
      <ScheduleChangesView
        closeDialog={cancelBlockedCaregiversChanges}
        dates={checkBlockedCaregiversResponse?.dates}
        isLoading={isDataLoading}
        notAllocatedDates={
          checkBlockedCaregiversResponse?.datesWithNotAllocatedVisits
        }
        visits={checkBlockedCaregiversResponse?.visitsList}
      />
    </FullWidthDialog>
  );
}

ChangeBlockedCaregiversSteps.propTypes = {
  blockedCaregivers: instanceOf(Array),
  cancelInfoEditing: func,
  closeBlockedCaregiversResult: func,
  showCheckBlockedCaregiversResult: bool,
};
