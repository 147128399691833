import { TabContext, TabList } from '@mui/lab';
import { Box, Divider, Tab, Typography } from '@mui/material';
import repeatedVisit from '../../../../img/icons/visitTypes/repeatedVisit.svg';
import { bool, func, number, string } from 'prop-types';
import React, { useState } from 'react';
import {
  MAX_TASK_DETAILS_FIELD_LENGTH,
  visitStatusesMap,
  visitTypeIcons,
} from '../../../../shared/constants';
import { COLORS } from '../../../../shared/styles/Theme';
import CustomDialog from '../../../../components/Dialog';
import MultilineInput from '../../../../components/Inputs/MultilineInput';
import { visitDetailsViewsMap, visitStatuses } from '../../constants';
import { getBgColor } from '../../utils';
import JobIdInfo from '../JobIdInfo';
import VisitStatusSelect from '../StatusSelect';
import VisitCost from '../VisitCost';
import { visitDetailsTitleStyles, visitDetailsTitleWrapper } from './styles';

function Title({
  cost = 0,
  detailsView = '',
  disabledAll = false,
  initialStatus = '',
  jobId = '',
  repeated = false,
  setDetailsView = () => {},
  setNewStatus = () => {},
  status = '',
  title = '',
  type = '',
}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [temporaryStatus, setTemporaryStatus] = useState(null);
  const [reason, setReason] = useState('');
  const changeTab = (_, newValue) => setDetailsView(newValue);
  const changeStatus = (value) => {
    if (value === visitStatusesMap.canceled) {
      setOpenDialog(true);
      setTemporaryStatus(value);
    } else setNewStatus({ newStatus: value });
  };
  const confirmationCanceling = () => {
    setNewStatus({ newStatus: temporaryStatus, reason });
    setTemporaryStatus(null);
    setReason('');
    setOpenDialog(false);
  };
  const rejectCanceling = () => {
    setTemporaryStatus(null);
    setOpenDialog(false);
    setReason('');
  };
  return (
    <Box sx={visitDetailsTitleWrapper}>
      <Box sx={visitDetailsTitleStyles}>
        <CustomDialog
          cancelButtonName="Cancel"
          cancelCallback={rejectCanceling}
          disabledSubmit={!reason.length}
          submitButtonName="confirm"
          submitCallback={confirmationCanceling}
          title="Cancellation Reason"
          openDialog={openDialog}
        >
          <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
            <MultilineInput
              changeDetails={(e) => setReason(e.target.value)}
              id="details"
              label="Cancellation Reason"
              maxLength={MAX_TASK_DETAILS_FIELD_LENGTH}
              value={reason}
              showHelperText
            />
          </Box>
        </CustomDialog>
        <img src={visitTypeIcons[type?.toLowerCase()]} alt="visit type icon" />
        {repeated && <img src={repeatedVisit} alt="icon" />}
        <Typography variant="h1" noWrap>
          {title}
        </Typography>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ color: COLORS.blue[200] }}
        />
        <VisitCost cost={cost} />
        <Divider
          orientation="vertical"
          flexItem
          sx={{ color: COLORS.blue[200] }}
        />
        <JobIdInfo jobId={jobId} />
        <Box sx={{ maxWidth: '250px', minWidth: '250px' }}>
          <VisitStatusSelect
            bgColor={getBgColor(status)}
            disabled={disabledAll}
            id="status"
            initialVisitStatus={initialStatus}
            options={visitStatuses}
            selectedValue={status || ''}
            setValue={changeStatus}
          />
        </Box>
      </Box>
      <TabContext value={detailsView}>
        <TabList
          onChange={changeTab}
          sx={{
            display: 'flex',
            '& .MuiTabs-flexContainer': { gap: '24px' },
          }}
        >
          {Object.values(visitDetailsViewsMap)?.map((value) => (
            <Tab disableRipple key={value} label={value} value={value} />
          ))}
        </TabList>
      </TabContext>
    </Box>
  );
}

Title.propTypes = {
  cost: number,
  detailsView: string,
  disabledAll: bool,
  jobId: string,
  initialStatus: string,
  repeated: bool,
  setDetailsView: func,
  setNewStatus: func,
  status: string,
  title: string,
  type: string,
};

export default React.memo(Title);
