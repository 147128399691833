export const checkBoxesWrapper = {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  '& .MuiFormControlLabel-root': {
    flex: 1,
    mr: 0,
    paddingRight: '5px',
    width: 'min-content',
    maxWidth: 'max-content',
    '& .MuiTypography-root': {
      cursor: 'pointer',
      width: 'max-content',
    },
  },
};
