import { Stack, Typography } from '@mui/material';
import carIcon from '../../../../../img/icons/car-grey-icon.svg';
import React from 'react';
import { COLORS } from '../../../../../shared/styles/Theme';

export default function ReportStatistics() {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap="6px"
      mb="20px"
      sx={{ textTransform: 'uppercase' }}
    >
      <Typography sx={{ fontSize: '13px' }}>Avg Utilization Rate</Typography>
      <Stack direction="row" alignItems="center" gap="4px">
        <Typography sx={{ fontSize: '13px' }}>With</Typography>
        <img src={carIcon} alt="carIcon" />
        <Typography sx={{ fontSize: '13px' }}>:</Typography>
        <Typography
          sx={{
            fontSize: '12px',
            color: COLORS.yellow[800],
            fontWeight: 600,
          }}
        >
          58%
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap="4px" ml="22px">
        <Typography sx={{ fontSize: '13px' }}>Without</Typography>
        <img src={carIcon} alt="carIcon" />
        <Typography sx={{ fontSize: '13px' }}>:</Typography>
        <Typography
          sx={{
            fontSize: '12px',
            color: COLORS.red[500],
            fontWeight: 600,
            ml: '4px',
          }}
        >
          45%
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap="4px" ml="22px">
        <Typography sx={{ fontSize: '13px' }}>Avg</Typography>
        <img src={carIcon} alt="carIcon" />
        <Typography sx={{ fontSize: '13px' }}>:</Typography>
        <Typography
          sx={{
            fontSize: '12px',
            color: COLORS.blue[900],
            fontWeight: 600,
            ml: '4px',
          }}
        >
          13 min
        </Typography>
      </Stack>
    </Stack>
  );
}
