import { Box, Button, IconButton, Typography } from '@mui/material';
import DeleteIcon from '../../../../../../../../../../img/icons/iconButtons/delete-icon.svg';
import { bool, func } from 'prop-types';
import React from 'react';
import { COLORS } from '../../../../../../../../../../shared/styles/Theme';
import { visitAccordionIconButtonStyles } from '../styles';

export default function VisitActions({
  confirmArrivalTime = () => {},
  deleteVisitFromSchedule = () => {},
  disableDelete = false,
  disabledConfirmation = false,
  isUnscheduledVisit = false,
  hasNoAvailableWindows = false,
  showActions = false,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        visibility: showActions ? 'visible' : 'hidden',
        alignItems: 'center',
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <IconButton
        aria-label="delete"
        disabled={disableDelete}
        disableRipple
        onClick={deleteVisitFromSchedule}
        sx={visitAccordionIconButtonStyles}
      >
        <img src={DeleteIcon} alt="Delete" />
      </IconButton>
      {isUnscheduledVisit && !hasNoAvailableWindows && (
        <Button
          color="primary"
          variant="contained"
          onClick={confirmArrivalTime}
          disableElevation
          disabled={disabledConfirmation}
          sx={{ ml: '20px' }}
        >
          Confirm
        </Button>
      )}
      {hasNoAvailableWindows && (
        <Typography
          variant="body2"
          sx={{
            height: '20px',
            ml: '20px',
            fontWeight: 600,
            color: COLORS.red[600],
            borderRadius: '4px',
            background: COLORS.red[200],
            padding: '0 4px',
            lineHeight: '18px',
            textWrap: 'nowrap',
          }}
        >
          No Resources
        </Typography>
      )}
    </Box>
  );
}

VisitActions.propTypes = {
  confirmArrivalTime: func,
  deleteVisitFromSchedule: func,
  disableDelete: bool,
  disabledConfirmation: bool,
  isUnscheduledVisit: bool,
  hasNoAvailableWindows: bool,
  showActions: bool,
};
