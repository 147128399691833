import { Box, Typography } from '@mui/material';
import { ScheduleCaregiverManagementContext } from '../../../../../../../../pages/Scheduling/context';
import { bool, instanceOf } from 'prop-types';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { COLORS } from '../../../../../../../../shared/styles/Theme';
import CustomDialog from '../../../../../../../../components/Dialog';
import UnsavedChangesContent from '../../../../../../../../components/Dialog/UnsavedChangesContent';
import { customCellWrapper } from './styles';

export default function ClientsCell({ row = {}, notAllocated = false }) {
  const navigate = useNavigate();
  const { closeCallOutCreation } = useContext(
    ScheduleCaregiverManagementContext,
  );
  const [showConformation, setShowConformation] = useState(false);
  const [navigationUrl, setNavigationUrl] = useState('');
  const newClient = row?.newClient
    ? `${row?.newClient?.firstName} ${row?.newClient?.lastName}`
    : null;
  const oldClient = row?.oldClient
    ? `${row?.oldClient?.firstName} ${row?.oldClient?.lastName}`
    : null;

  const initialClient = notAllocated
    ? `${row?.client?.firstName} ${row?.client?.lastName}`
    : oldClient;
  const onLinkClick = (url) => {
    setNavigationUrl(url);
    setShowConformation(true);
  };
  const closeCreation = () => {
    closeCallOutCreation();
    navigate(navigationUrl);
    setShowConformation(null);
    setNavigationUrl('');
  };
  return (
    <div>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setShowConformation(false)}
        submitButtonName="confirm"
        submitCallback={closeCreation}
        title="Confirmation"
        openDialog={showConformation}
      >
        <UnsavedChangesContent text="Callout creation will be lost. Do you want to proceed?" />
      </CustomDialog>
      <Box sx={customCellWrapper}>
        <Typography
          component="span"
          onClick={() =>
            onLinkClick(
              notAllocated
                ? `/clients/${row?.client?.id}`
                : `/clients/${row?.oldClient?.id}`,
            )
          }
          sx={{
            textDecoration: newClient ? 'line-through' : 'none',
            color: COLORS.blue[700],
            cursor: 'pointer',
          }}
        >
          {initialClient || ''}
        </Typography>
        {newClient && (
          <Typography
            component="span"
            onClick={() => onLinkClick(`/clients/${row?.newClient?.id}`)}
            sx={{
              fontWeight: 600,
              color: COLORS.blue[700],
              cursor: 'pointer',
            }}
          >
            {newClient || ''}
          </Typography>
        )}
      </Box>
    </div>
  );
}

ClientsCell.propTypes = {
  row: instanceOf(Object),
  notAllocated: bool,
};
