import { Alert, AlertTitle, Box, Grow, Stack } from '@mui/material';
import { useGetWeekendsSchedulingDaysQuery } from '../../../../api/Administration/api';
import {
  useConfirmCaregiverAvailabilityMutation,
  useGetCaregiverAvailabilityQuery,
  useUpdateCaregiverAvailabilityMutation,
} from '../../../../api/Availability/api';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ISO_DATE_ONLY_FORMAT, SHORT_DATE_FORMAT } from '../../../../shared/constants';
import AlertContext from '../../../../components/Alert';
import ButtonsGroup from '../../../../components/ButtonsGroup';
import AvailabilityControls from './components/AvailabilityControls';
import AvailabilityTable from './components/AvailabilityTable';
import CaregiverAvailabilityHistory from './components/CaregiverAvailabilityHistory';
import EditAvailability from './components/EditAvailability';
import EffectiveDateDialog from './components/EffectiveDateDialog';
import AvailabilityNoRowsComponent from './components/NoRowsComponent';
import SchedulingUpdatesTable from './components/SchedulingUpdatesTable';
import { availabilityScheduleModels, viewTypeButtons } from './constants';
import { successAlertStyles } from './styles';
import { transformAvailabilityData } from './utils';

export default function Availability() {
  const { setAlert } = useContext(AlertContext);
  const { id, tabSubView } = useParams();
  const navigate = useNavigate();
  const [availabilityScheduleView, setAvailabilityScheduleView] = useState(
    availabilityScheduleModels.current,
  );
  const [showMultiplyMode, setShowMultiplyMode] = useState(false);
  const initialEffectiveDate = moment().add(1, 'day');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openReschedule, setOpenReschedule] = useState(false);
  const [date, setDate] = useState(initialEffectiveDate);
  const [editMode, setEditMode] = useState(false);
  const [availabilityData, setAvailabilityData] = useState([]);
  const [availabilityResponseData, setAvailabilityResponseData] = useState();
  const showWarningTitle =
    availabilityData.length === 2 &&
    moment(availabilityData[0].effectiveDate).isBefore(moment()) &&
    moment(availabilityData[1].effectiveDate).isAfter(moment());

  const {
    data: caregiverAvailabilitySchedule,
    isLoading,
    refetch,
    error: careGiverAvailabilityError,
  } = useGetCaregiverAvailabilityQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const [
    checkAvailability,
    {
      isLoading: iChecksAvailabilityUpdating,
      error: checkCaregiverAvailabilityError,
      data: rescheduleData,
    },
  ] = useUpdateCaregiverAvailabilityMutation();
  const [
    updateAvailability,
    {
      isLoading: isAvailabilityUpdating,
      error: updateCaregiverAvailabilityError,
    },
  ] = useConfirmCaregiverAvailabilityMutation();
  const {
    data: getWeekdays,
    isLoading: getWeekdaysLoading,
    error: getWeekdaysError,
  } = useGetWeekendsSchedulingDaysQuery();
  useEffect(() => {
    if (
      careGiverAvailabilityError ||
      updateCaregiverAvailabilityError ||
      checkCaregiverAvailabilityError ||
      getWeekdaysError
    ) {
      const errorData =
        careGiverAvailabilityError ||
        updateCaregiverAvailabilityError ||
        checkCaregiverAvailabilityError ||
        getWeekdaysError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    careGiverAvailabilityError,
    checkCaregiverAvailabilityError,
    getWeekdaysError,
    setAlert,
    updateCaregiverAvailabilityError,
  ]);

  useEffect(() => {
    if (caregiverAvailabilitySchedule) {
      setAvailabilityData(
        caregiverAvailabilitySchedule?.length < 2 &&
          !caregiverAvailabilitySchedule[0]?.effectiveDate
          ? []
          : caregiverAvailabilitySchedule,
      );
      setAvailabilityResponseData(caregiverAvailabilitySchedule);
    }
  }, [caregiverAvailabilitySchedule]);
  useEffect(() => {
    if (tabSubView === 'current') {
      refetch();
    }
  }, [refetch, availabilityScheduleView, tabSubView]);

  useEffect(() => {
    if (
      availabilityResponseData?.length === 2 &&
      moment(availabilityData[0]?.effectiveDate).isBefore(moment(), 'day') &&
      moment(availabilityData[1]?.effectiveDate) >
        moment(availabilityData[0]?.effectiveDate) &&
      moment(availabilityData[1]?.effectiveDate).isBefore(moment(), 'day')
    ) {
      setAvailabilityResponseData(availabilityResponseData.slice(1));
    }
  }, [availabilityData, availabilityScheduleView, availabilityResponseData]);
  const getRequestData = () =>
    availabilityData.length
      ? {
          availabilities: availabilityData?.map((item) => ({
            effectiveDateStart:
              availabilityData.length !== 1
                ? moment(item?.effectiveDate).format(ISO_DATE_ONLY_FORMAT) ||
                  date.format(ISO_DATE_ONLY_FORMAT)
                : date.format(ISO_DATE_ONLY_FORMAT),
            effectiveDateEnd: date.format(ISO_DATE_ONLY_FORMAT),
            workingDays: transformAvailabilityData(item.days),
          })),
        }
      : [];

  const checkAvailabilityData = () => {
    setOpenReschedule(true);
    const requestData = getRequestData();
    let greaterEffectiveDateStart;
    if (requestData.availabilities) {
      requestData?.availabilities?.forEach((availability) => {
        if (!greaterEffectiveDateStart) {
          greaterEffectiveDateStart = availability;
        } else if (
          moment(availability.effectiveDateStart).isAfter(
            greaterEffectiveDateStart.effectiveDateStart,
          )
        ) {
          greaterEffectiveDateStart = availability;
        }
      });
    }
    if (greaterEffectiveDateStart) {
      greaterEffectiveDateStart.effectiveDateEnd = null;
    }
    const sortedRequestData = {
      ...requestData,
      availabilities: requestData.availabilities.sort(
        (a, b) => moment(a.effectiveDateStart) - moment(b.effectiveDateStart),
      ),
    };
    checkAvailability({
      caregiverId: id,
      payload: { ...sortedRequestData },
    });
    setOpenReschedule(true);
  };
  const updateAvailabilityData = () => {
    updateAvailability({
      caregiverId: id,
      requestId: rescheduleData?.requestId,
    })
      .unwrap()
      .then(() => {
        setShowSuccessAlert(true);
        setTimeout(() => {
          setShowSuccessAlert(false);
        }, 3000);
      });
    setOpenReschedule(false);
    setEditMode(false);
    setDate(initialEffectiveDate);
  };
  const submitAvailabilityDialog = () => {
    setOpenDialog(false);
    setEditMode(true);
    setShowMultiplyMode(true);
    if (
      !caregiverAvailabilitySchedule[0].effectiveDate ||
      moment(caregiverAvailabilitySchedule[0]?.effectiveDate).isAfter(
        moment(),
        'day',
      )
    ) {
      setAvailabilityScheduleView(availabilityScheduleModels.current);
      setAvailabilityData(caregiverAvailabilitySchedule);
      return '';
    }
    if (
      caregiverAvailabilitySchedule.length < 2 &&
      caregiverAvailabilitySchedule[availabilityScheduleView].effectiveDate
    ) {
      setAvailabilityScheduleView(availabilityScheduleModels.future);
      setAvailabilityData((prevState) => [
        ...prevState,
        {
          days: caregiverAvailabilitySchedule[availabilityScheduleView].days,
          effectiveDate: date.format(ISO_DATE_ONLY_FORMAT),
        },
      ]);
      return '';
    }
    if (
      caregiverAvailabilitySchedule.length > 1 &&
      moment(availabilityData[0].effectiveDate).isBefore(moment()) &&
      moment(availabilityData[1].effectiveDate).isBefore(moment())
    ) {
      setAvailabilityScheduleView(availabilityScheduleModels.future);
      setAvailabilityData((prevState) => {
        const newData = [...prevState];
        newData[availabilityScheduleModels.current] = {
          days: prevState[availabilityScheduleModels.current].days,
          effectiveDate: caregiverAvailabilitySchedule[1].effectiveDate,
          effectiveDateEnd: date.format(ISO_DATE_ONLY_FORMAT),
        };
        newData[availabilityScheduleModels.future] = {
          days: prevState[availabilityScheduleModels.future].days,
          effectiveDate: date.format(ISO_DATE_ONLY_FORMAT),
        };
        return newData;
      });
    }
    if (
      caregiverAvailabilitySchedule.length > 1 &&
      moment(availabilityData[1].effectiveDate).isAfter(moment())
    ) {
      setAvailabilityScheduleView(availabilityScheduleModels.future);
      setAvailabilityData((prevState) => {
        const newData = [...prevState];
        newData[availabilityScheduleModels.future] = {
          days: prevState[availabilityScheduleModels.future].days,
          effectiveDate: date.format(ISO_DATE_ONLY_FORMAT),
        };
        return newData;
      });
      return '';
    }
    return '';
  };
  const cancelEditMode = () => {
    setEditMode(false);
    setAvailabilityData(
      caregiverAvailabilitySchedule.length < 2 &&
        !caregiverAvailabilitySchedule[0].effectiveDate
        ? []
        : caregiverAvailabilitySchedule,
    );
    setAvailabilityScheduleView(availabilityScheduleModels.current);
    setDate(initialEffectiveDate);
  };
  const cancelCallback = () => {
    setOpenReschedule(false);
    setEditMode(false);
    setAvailabilityData(
      caregiverAvailabilitySchedule.length < 2 &&
        !caregiverAvailabilitySchedule[0].effectiveDate
        ? []
        : caregiverAvailabilitySchedule,
    );
    setAvailabilityScheduleView(availabilityScheduleModels.current);
    setDate(initialEffectiveDate);
  };
  // TODO can be removed after testing
  // useEffect(() => {
  //   if (!caregiverAvailabilitySchedule || !caregiverAvailabilitySchedule?.[0].effectiveDate) {
  //     setAvailabilityScheduleView(availabilityScheduleModels.current);
  //     return '';
  //   }
  //   if (
  //     availabilityResponseData?.length === 1 &&
  //     caregiverAvailabilitySchedule?.[0].effectiveDateEnd
  //   ) {
  //     setAvailabilityScheduleView(availabilityScheduleModels.current);
  //     return '';
  //   }
  //   if (
  //     availabilityResponseData?.length === 1 &&
  //     editMode &&
  //     caregiverAvailabilitySchedule
  //   ) {
  //     setAvailabilityScheduleView(availabilityScheduleModels.future);
  //     return '';
  //   }
  // }, [
  //   availabilityResponseData?.length,
  //   caregiverAvailabilitySchedule,
  //   editMode,
  // ]);
  const handleAvailabilityDataChange = (changedAvailabilityData) => {
    setAvailabilityData(changedAvailabilityData);
  };
  const viewEffectiveDate = [
    {
      name: moment(availabilityData[0]?.effectiveDate).format(
        SHORT_DATE_FORMAT,
      ),
      infoType: availabilityScheduleModels.current,
    },
    {
      name: moment(availabilityData[1]?.effectiveDate).format(
        SHORT_DATE_FORMAT,
      ),
      infoType: availabilityScheduleModels.future,
    },
  ];
  const sortedViewEffectiveDate = viewEffectiveDate.sort((a, b) =>
    moment(a.name, SHORT_DATE_FORMAT).diff(moment(b.name, SHORT_DATE_FORMAT)),
  );
  const changeSubView = (value) =>
    navigate(`/caregivers/${id}/availability/${value}`);
  return (
    <Stack sx={{ maxWidth: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <ButtonsGroup
          buttons={viewTypeButtons}
          changeSelected={changeSubView}
          selected={tabSubView}
        />
        {tabSubView === 'current' && (
          <AvailabilityControls
            availabilityData={availabilityData}
            availabilityScheduleView={availabilityScheduleView}
            initialData={availabilityResponseData}
            setAvailabilityScheduleView={setAvailabilityScheduleView}
            setOpenDialog={setOpenDialog}
            viewEffectiveDate={sortedViewEffectiveDate}
          />
        )}
        <EffectiveDateDialog
          openAvailabilityDialog={openDialog}
          date={date}
          selectDate={(newValue) => setDate(newValue)}
          closeAvailabilityDialog={() => {
            setOpenDialog(false);
            setDate(initialEffectiveDate);
          }}
          submitAvailabilityDialog={submitAvailabilityDialog}
          showWarningTitle={showWarningTitle}
        />
        <Grow in={showSuccessAlert}>
          <Alert sx={successAlertStyles} severity="success">
            <AlertTitle>Success</AlertTitle>
            Successfully update Availability
          </Alert>
        </Grow>
      </Box>
      <Box sx={{ marginTop: '30px' }}>
        {tabSubView === 'current' ? (
          <Box>
            <AvailabilityTable
              availabilityData={availabilityResponseData}
              isLoading={isLoading || isAvailabilityUpdating}
              noRowsOverlay={AvailabilityNoRowsComponent}
              availabilityScheduleView={availabilityScheduleView}
            />
            <EditAvailability
              availabilityData={availabilityData}
              availabilityScheduleView={availabilityScheduleView}
              cancelEditMode={cancelEditMode}
              closeDialog={cancelEditMode}
              dbData={availabilityResponseData}
              disabledDays={getWeekdays?.schema}
              isLoading={
                isLoading ||
                isAvailabilityUpdating ||
                iChecksAvailabilityUpdating ||
                getWeekdaysLoading
              }
              openEditMode={editMode}
              setAvailabilityData={handleAvailabilityDataChange}
              setShowMultiplyMode={setShowMultiplyMode}
              showMultiplyMode={showMultiplyMode}
              startDate={
                availabilityData[availabilityScheduleView]?.effectiveDate ||
                moment(date).format(ISO_DATE_ONLY_FORMAT)
              }
              submitCallback={checkAvailabilityData}
              setAvailabilityScheduleView={setAvailabilityScheduleView}
              viewEffectiveDate={sortedViewEffectiveDate}
            />
            {rescheduleData && (
              <SchedulingUpdatesTable
                actualVisit={rescheduleData}
                open={openReschedule}
                cancelCallback={cancelCallback}
                submitAvailability={updateAvailabilityData}
              />
            )}
          </Box>
        ) : (
          <CaregiverAvailabilityHistory />
        )}
      </Box>
    </Stack>
  );
}
