import {
  useCheckApprovingPendingTimeOffRequestMutation,
  useCheckCancelingApprovedTimeOffRequestMutation,
} from '../../../../../api/TimeOffRequest/api';
import { func, instanceOf } from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import AlertContext from '../../../../../components/Alert';
import TimeOffRequestPossibility from '../../../../../components/TimeOffRequestPossibility';

export default function ChangeRequestStatusPossibility({
  cancelChanges = () => {},
  confirmChanges = () => {},
  changingStatusData = null,
}) {
  const { setAlert } = useContext(AlertContext);
  const [
    checkCancelingApprovedTimeOffRequest,
    {
      data: checkCancelingApprovedTimeOffRequestData,
      error: checkCancelingApprovedTimeOffRequestError,
      isLoading: checkCancelingApprovedTimeOffRequestWaiting,
    },
  ] = useCheckCancelingApprovedTimeOffRequestMutation();
  const [
    checkApprovingPendingTimeOffRequest,
    {
      data: checkApprovingPendingTimeOffRequestData,
      error: checkApprovingPendingTimeOffRequestError,
      isLoading: checkApprovingPendingTimeOffRequestWaiting,
    },
  ] = useCheckApprovingPendingTimeOffRequestMutation();

  useEffect(() => {
    if (
      checkCancelingApprovedTimeOffRequestError ||
      checkApprovingPendingTimeOffRequestError
    ) {
      const errorData =
        checkCancelingApprovedTimeOffRequestError ||
        checkApprovingPendingTimeOffRequestError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    checkApprovingPendingTimeOffRequestError,
    checkCancelingApprovedTimeOffRequestError,
    setAlert,
  ]);

  useEffect(() => {
    if (
      changingStatusData &&
      changingStatusData.actionType === 'approveRequest'
    ) {
      checkApprovingPendingTimeOffRequest(changingStatusData.requestId);
    }
    if (
      changingStatusData &&
      changingStatusData.actionType === 'cancelRequest'
    ) {
      checkCancelingApprovedTimeOffRequest(changingStatusData.requestId);
    }
  }, [
    changingStatusData,
    checkApprovingPendingTimeOffRequest,
    checkCancelingApprovedTimeOffRequest,
  ]);

  const isDataLoading = useMemo(
    () =>
      checkCancelingApprovedTimeOffRequestWaiting ||
      checkApprovingPendingTimeOffRequestWaiting,
    [
      checkApprovingPendingTimeOffRequestWaiting,
      checkCancelingApprovedTimeOffRequestWaiting,
    ],
  );

  const possibilityResponseData = useMemo(
    () =>
      checkCancelingApprovedTimeOffRequestData ||
      checkApprovingPendingTimeOffRequestData,
    [
      checkCancelingApprovedTimeOffRequestData,
      checkApprovingPendingTimeOffRequestData,
    ],
  );

  return (
    <TimeOffRequestPossibility
      closeDialog={cancelChanges}
      confirmChanges={() => confirmChanges(possibilityResponseData?.requestId)}
      disabledSubmit={
        !!checkCancelingApprovedTimeOffRequestError?.data?.error ||
        !!checkApprovingPendingTimeOffRequestError?.data?.error ||
        isDataLoading
      }
      dataError={
        checkCancelingApprovedTimeOffRequestError?.data?.error ||
        checkApprovingPendingTimeOffRequestError?.data?.error
      }
      isLoading={isDataLoading}
      possibilityData={possibilityResponseData?.rescheduledEvents}
      showDialog={!!changingStatusData}
    />
  );
}

ChangeRequestStatusPossibility.propTypes = {
  cancelChanges: func,
  confirmChanges: func,
  changingStatusData: instanceOf(Object),
};
