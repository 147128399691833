import { Box, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { SHORT_DATE_FORMAT, backgroundColorSchemeMap } from '../../../../../../../../shared/constants';
import { COLORS } from '../../../../../../../../shared/styles/Theme';
import { getIconComponent } from '../../../../../../../../shared/utils/common';
import Badge from '../../../../../../../../components/Badge';
import CaregiversCell from './CaregiversCell';
import ClientsCell from './ClientsCell';
import ShadowCaregiverCell from './ShadowCaregiverCell';
import ShadowVisitClientsCell from './ShadowVisitClientsCell';
import { customCellWrapper } from './styles';

const getScheduledTimeRange = (data, notAllocated) => (
  <Box sx={customCellWrapper}>
    <Typography
      sx={{ textDecoration: data.newScheduledTime ? 'line-through' : 'none' }}
    >
      {notAllocated ? data.oldScheduledTime?.start : data.oldScheduledTime}
    </Typography>
    {data.newScheduledTime && (
      <Typography sx={{ fontWeight: 600 }}>{data.newScheduledTime}</Typography>
    )}
  </Box>
);

const getVisitTypeData = (row, notAllocated) => (
  <Box sx={customCellWrapper}>
    <Box
      sx={{
        display: 'flex',
        textDecoration: row?.newVisitType ? 'line-through' : 'none',
      }}
    >
      {notAllocated ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {getIconComponent(row?.visitType)}
          <Typography
            variant="body1"
            sx={{ ml: '8px', color: COLORS.blue[700] }}
          >
            {row?.visitType}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {getIconComponent(row?.oldVisitType)}
          <Typography
            variant="body1"
            sx={{ ml: '8px', color: COLORS.blue[700] }}
          >
            {row?.oldVisitType}
          </Typography>
        </Box>
      )}
    </Box>
    {row?.newVisitType && (
      <Box sx={{ display: 'flex' }}>
        {getIconComponent(row?.newVisitType)}
        <Typography
          variant="body1"
          sx={{ ml: '8px', color: COLORS.blue[700], fontWeight: 600 }}
        >
          {row?.newVisitType}
        </Typography>
      </Box>
    )}
  </Box>
);

export const calloutPossibilityColumns = (notAllocated) => [
  {
    field: 'caregiver',
    headerName: 'Caregiver',
    minWidth: 300,
    maxWidth: 400,
    width: 400,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => <CaregiversCell row={row} />,
  },
  {
    field: 'client',
    headerName: 'Client',
    minWidth: 300,
    width: 400,
    flex: 1,
    maxWidth: 400,
    sortable: false,
    renderCell: ({ row }) => (
      <ClientsCell row={row} notAllocated={notAllocated} />
    ),
  },
  {
    field: 'visitType',
    headerName: 'Visit Type',
    maxWidth: 200,
    width: 200,
    sortable: false,
    renderCell: ({ row }) => getVisitTypeData(row, notAllocated),
  },
  {
    field: 'scheduledTime',
    headerName: 'Scheduled Time',
    sortable: false,
    minWidth: 150,
    renderCell: ({ row }) => getScheduledTimeRange(row, notAllocated),
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: false,
    minWidth: 250,
    renderCell: ({ row }) => {
      const colorKey = notAllocated ? 'error' : 'active';
      return (
        <Badge
          colorScheme={backgroundColorSchemeMap[colorKey]}
          label={notAllocated ? row?.status?.message : 'Reassignment Possible'}
        />
      );
    },
  },
];

export const deleteShadowVisitsColumns = [
  {
    field: 'caregiver',
    headerName: 'Caregiver',
    minWidth: 300,
    maxWidth: 400,
    width: 400,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => <ShadowCaregiverCell row={row} />,
  },
  {
    field: 'client',
    headerName: 'Client',
    minWidth: 300,
    width: 400,
    flex: 1,
    maxWidth: 400,
    sortable: false,
    renderCell: ({ row }) => <ShadowVisitClientsCell row={row} />,
  },
  {
    field: 'visitType',
    headerName: 'Visit Type',
    maxWidth: 200,
    width: 200,
    sortable: false,
    renderCell: ({ row }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {getIconComponent(row?.visitType)}
        <Typography variant="body1" sx={{ ml: '8px', color: COLORS.blue[700] }}>
          {row?.visitType}
        </Typography>
      </Box>
    ),
  },
  {
    field: 'scheduledTime',
    headerName: 'Scheduled Time',
    sortable: false,
    minWidth: 150,
    renderCell: ({ row }) =>
      row.oldScheduledTime?.start ? (
        <Typography>{`${row.oldScheduledTime?.start} - ${row.oldScheduledTime?.end}`}</Typography>
      ) : (
        <Typography>{row.oldScheduledTime}</Typography>
      ),
  },
  {
    field: 'date',
    headerName: 'Date',
    sortable: false,
    minWidth: 150,
    renderCell: ({ value }) => (
      <Typography>{moment(value).format(SHORT_DATE_FORMAT)}</Typography>
    ),
  },
];
