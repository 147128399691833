import { Box, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import ButtonsGroup from '../../../../components/ButtonsGroup';
import CareProgramManagement from './components/CareProgramManagementProvider';
import { detailsButtons } from './constants';
import { getCareProgramComponent, getCareProgramRightHeader } from './utils';

export default function CareProgram() {
  const navigate = useNavigate();
  const { id, tabSubView } = useParams();
  const [careProgramVersion, setCareProgramVersion] = useState('current');
  return (
    <CareProgramManagement>
      <Stack>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <ButtonsGroup
            buttons={detailsButtons}
            changeSelected={(value) =>
              navigate(`/clients/${id}/care-program/${value}`)
            }
            selected={tabSubView}
          />
          {getCareProgramRightHeader(
            tabSubView,
            careProgramVersion,
            setCareProgramVersion,
          )}
        </Box>
        <Box sx={{ mt: '24px' }}>
          {getCareProgramComponent(tabSubView, careProgramVersion)}
        </Box>
      </Stack>
    </CareProgramManagement>
  );
}
