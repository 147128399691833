import { useVerifyVisitMutation } from '../../../../../../api/Scheduler/api';
import { VerificationModeVisitNotesContext } from '../../../../../../pages/Scheduling/context';
import { bool, func, instanceOf } from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { combineTeamsAndTerritory } from '../../../../../../shared/utils/common';
import AlertContext from '../../../../../../components/Alert';
import FullWidthDialog from '../../../../../../components/FullWidthDialog';
import {
  parseUpdatedChosenVisitsData,
  parseUpdatedVisitsNotesData,
} from '../../utils';
import VisitVerificationCommon from './VisitVerificationCommon';

function VisitVerification({
  actualVisit = [],
  allLocations = [],
  date = {},
  debouncedSearch = () => {},
  filterData = {},
  teamsFilter = {},
  open = false,
  setFilterData = () => {},
  setOpen = () => {},
  setVerificationMode = () => {},
  statusOptions = [],
  verificationMode = false,
  violationOptions = [],
  setTeamFilter = () => {},
}) {
  const { setAlert } = useContext(AlertContext);
  const completedVisits = useMemo(
    () =>
      actualVisit.filter((visit) => visit.status.toLowerCase() === 'completed'),
    [actualVisit],
  );
  const visitNotesArray = completedVisits?.map((event) => ({
    careProgramTasks: event?.careProgramTasks,
    caregiver: event.caregiver,
    client: event.client,
    externalId: event.externalId,
    fullVisitData: event,
    id: event.id + 1,
    region: event.region,
    type: 'visitNote',
    visitDetails: event?.visitDetails,
    visitNotes: event?.visitNotes,
  }));
  const initialPreVisitNotes = useMemo(() => {
    const notes = {};
    visitNotesArray.forEach((event) => {
      notes[event.id] = {
        visitNotes: event?.visitNotes || '',
        visitDetails: event?.visitDetails || '',
      };
    });
    return notes;
  }, [visitNotesArray]);
  const [preVisitNotes, setPreVisitNotes] = useState(initialPreVisitNotes);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [chosenVisits, setChosenVisits] = useState([]);
  const [verifyVisits, { error: verifyVisitError }] = useVerifyVisitMutation();
  useEffect(() => {
    if (verifyVisitError) {
      setAlert({
        errorData: verifyVisitError,
        type: 'error',
      });
    }
  }, [verifyVisitError, setAlert]);
  const allData = completedVisits?.concat(visitNotesArray);
  const sortedAllData = allData.sort((a, b) => a.id.localeCompare(b.id));
  const allTeamsAndTerritory = combineTeamsAndTerritory(allLocations);
  const onlyTeamsArray = allTeamsAndTerritory?.filter((team) => team.territory);
  const filteredTeams = useMemo(
    () =>
      onlyTeamsArray?.filter((location) =>
        filterData.teams?.some((team) => location?.name === team),
      ),
    [onlyTeamsArray, filterData],
  );
  // const filteredVisits = {};
  const filteredVisits = useMemo(() => ({}), []);
  filteredTeams?.forEach((location) => {
    filteredVisits[location.id] = sortedAllData.filter(
      (visit) =>
        (!visit?.externalId ||
          visit?.externalId?.includes(filterData?.jobId)) &&
        visit?.region?.name === location?.name &&
        `${visit?.caregiver?.firstName} + ${visit?.caregiver?.lastName}`
          ?.toLowerCase()
          .includes(filterData?.caregiver?.toLowerCase()?.trim()) &&
        `${visit?.client?.firstName} ${visit?.client?.lastName}`
          .toLowerCase()
          .includes(filterData?.client?.toLowerCase()?.trim()),
    );
  });
  const sortedTeams = filteredTeams?.sort((a, b) => {
    const lengthA = filteredVisits[a.id]?.length || 0;
    const lengthB = filteredVisits[b.id]?.length || 0;
    return lengthB - lengthA;
  });
  useEffect(() => {
    setChosenVisits(
      completedVisits.filter((visit) => selectionModel.includes(visit.id)),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionModel]);
  const onSelectVisit = useCallback(
    (rowId) => {
      if (selectionModel.includes(rowId)) {
        setSelectionModel((prev) => prev.filter((id) => id !== rowId));
      } else {
        setSelectionModel([...selectionModel, rowId]);
      }
    },
    [selectionModel, setSelectionModel],
  );
  const changePreVisitNotes = useCallback(
    (newValue, rowId) => {
      setPreVisitNotes((prevNotes) => ({
        ...prevNotes,
        [rowId]: newValue,
      }));
    },
    [setPreVisitNotes],
  );

  const handleVerificationModeChange = useCallback(
    (event) => {
      setVerificationMode(event.target.checked);
    },
    [setVerificationMode],
  );

  const cancelVisitNotes = useCallback((rowId) => {
    setPreVisitNotes((prevNotes) => ({
      ...prevNotes,
      [rowId]: '',
    }));
    const parsedRowId = rowId.slice(0, -1);
    setChosenVisits((prevChosenVisits) =>
      prevChosenVisits?.map((visit) => ({
        ...visit,
        visitNotes: visit.id === parsedRowId ? '' : visit.visitNotes,
      })),
    );
    setPreVisitNotes((current) => {
      const copy = { ...current };

      delete copy[rowId];

      return copy;
    });
  }, []);

  const submitVisitNote = useCallback(
    (rowId, visitType) => {
      setChosenVisits((prevChosenVisits) =>
        prevChosenVisits?.map((visit) => {
          const parsedId = `${visit?.id}1`;
          const matchingPreVisitNote = preVisitNotes?.[parsedId];
          return {
            ...visit,
            [visitType]: matchingPreVisitNote[visitType] || '',
          };
        }),
      );

      verifyVisits(
        parseUpdatedVisitsNotesData({
          completedVisits,
          preVisitNotes,
          rowId,
          visitType,
        }),
      );
    },
    [completedVisits, preVisitNotes, verifyVisits],
  );

  const handleSelectAllTable = useCallback(() => {
    if (selectAll) {
      setSelectionModel([]);
    } else {
      setSelectionModel(completedVisits?.map((t) => t.id));
    }

    setSelectAll(!selectAll);
  }, [selectAll, setSelectionModel, completedVisits]);

  const onSelectAllTeamVisits = useCallback(
    (locationId) => {
      const isSelected = selectionModel.some(
        (visitId) =>
          completedVisits.find((visit) => visit.id === visitId)?.region.id ===
          locationId,
      );

      if (!isSelected) {
        const selectedIdsForLocation = completedVisits
          ?.filter((visit) => visit.region.id === locationId)
          ?.map((visit) => visit.id);

        setSelectionModel((prev) => [...prev, ...selectedIdsForLocation]);
      } else {
        const deselectedIdsForLocation = completedVisits
          ?.filter((visit) => visit.region.id === locationId)
          ?.map((visit) => visit.id);

        setSelectionModel((prev) =>
          prev.filter((id) => !deselectedIdsForLocation.includes(id)),
        );
      }
    },
    [selectionModel, setSelectionModel, completedVisits],
  );

  const submitCallback = () => {
    setOpenConfirmationDialog(true);
    setChosenVisits((prevChosenVisits) =>
      prevChosenVisits.map((visit) => {
        const parsedId = `${visit?.id}1`;
        const matchingPreVisitNote = preVisitNotes?.[parsedId];

        return {
          ...visit,
          visitNotes: matchingPreVisitNote?.visitNotes || '',
          visitDetails: matchingPreVisitNote?.visitDetails || '',
        };
      }),
    );
  };
  const submitDialog = useCallback(() => {
    verifyVisits(parseUpdatedChosenVisitsData({ chosenVisits, date }));
    setOpenConfirmationDialog(false);
    setOpen(false);
    setChosenVisits([]);
  }, [chosenVisits, date, verifyVisits, setOpenConfirmationDialog, setOpen]);

  const cancelCallback = () => {
    setOpen(false);
    setSelectionModel([]);
    setChosenVisits([]);
    setPreVisitNotes({});
  };
  let additionalVisitDetails = 0;
  let caregiverVisitNotes = 0;

  Object.keys(preVisitNotes).forEach((key) => {
    if (Object.hasOwn(initialPreVisitNotes, key)) {
      additionalVisitDetails +=
        preVisitNotes[key]?.visitNotes !== initialPreVisitNotes[key]?.visitNotes
          ? 1
          : 0;
      caregiverVisitNotes +=
        preVisitNotes[key]?.visitDetails !==
        initialPreVisitNotes[key]?.visitDetails
          ? 1
          : 0;
    }
  });
  const memoizedProviderValue = useMemo(
    () => ({
      additionalVisitDetails,
      allLocations,
      cancelVisitNotes,
      caregiverVisitNotes,
      changePreVisitNotes,
      chosenVisits,
      completedVisits,
      date,
      debouncedSearch,
      filterData,
      filteredVisits,
      handleSelectAllTable,
      handleVerificationModeChange,
      onSelectAllTeamVisits,
      onSelectVisit,
      openConfirmationDialog,
      preVisitNotes,
      selectionModel,
      setFilterData,
      setOpenConfirmationDialog,
      setTeamFilter,
      sortedTeams,
      statusOptions,
      submitDialog,
      submitVisitNote,
      teamsFilter,
      verificationMode,
      violationOptions,
    }),
    [
      additionalVisitDetails,
      allLocations,
      cancelVisitNotes,
      caregiverVisitNotes,
      changePreVisitNotes,
      chosenVisits,
      completedVisits,
      date,
      debouncedSearch,
      filterData,
      filteredVisits,
      handleSelectAllTable,
      handleVerificationModeChange,
      onSelectAllTeamVisits,
      onSelectVisit,
      openConfirmationDialog,
      preVisitNotes,
      selectionModel,
      setFilterData,
      setTeamFilter,
      sortedTeams,
      statusOptions,
      submitDialog,
      submitVisitNote,
      teamsFilter,
      verificationMode,
      violationOptions,
    ],
  );
  return (
    <VerificationModeVisitNotesContext.Provider value={memoizedProviderValue}>
      <FullWidthDialog
        cancelButtonName="cancel"
        backButtonCallback={cancelCallback}
        cancelCallback={cancelCallback}
        submitButtonName={`Verify ${
          chosenVisits.length === 0 ? '' : chosenVisits?.length
        } visit & save notes`}
        disabledSubmit={chosenVisits.length === 0}
        submitCallback={submitCallback}
        title="Verification Mode"
        openDialog={open}
      >
        <VisitVerificationCommon />
      </FullWidthDialog>
    </VerificationModeVisitNotesContext.Provider>
  );
}

VisitVerification.propTypes = {
  actualVisit: instanceOf(Array),
  allLocations: instanceOf(Array),
  date: instanceOf(Object),
  debouncedSearch: func,
  filterData: instanceOf(Object),
  teamsFilter: instanceOf(Object),
  open: bool,
  setFilterData: func,
  setOpen: func,
  setVerificationMode: func,
  setTeamFilter: func,
  statusOptions: instanceOf(Array),
  verificationMode: bool,
  violationOptions: instanceOf(Array),
};

export default React.memo(VisitVerification);
