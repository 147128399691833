import { Box, Typography } from '@mui/material';
import { instanceOf, oneOfType, string } from 'prop-types';
import React from 'react';
import { customLabelStyles, customSelectedValueStyles } from './style';

export default function SelectRenderValue({
  options = [],
  placeHolder = '',
  selectedValue = '' || {},
}) {
  const getValue = () =>
    selectedValue
      ?.map((selectedItem) => options.find((item) => item === selectedItem))
      ?.join(', ');
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        height: '100%',
        pb: '3px',
        cursor: 'pointer',
      }}
    >
      <Box
        className={`animated-placeholder ${
          selectedValue?.length ? 'has-selected-items' : ''
        }`}
        sx={{ ...customLabelStyles }}
      >
        {placeHolder}
      </Box>
      <Box sx={{ ...customSelectedValueStyles }}>
        <Typography noWrap>{getValue()}</Typography>
      </Box>
    </Box>
  );
}

SelectRenderValue.propTypes = {
  options: instanceOf(Array),
  placeHolder: string,
  selectedValue: oneOfType([instanceOf(Array), string, instanceOf(Object)]),
};
