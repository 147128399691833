import { Box, Typography } from '@mui/material';
import VisitActions from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/AlternativeArrivalWindows/VisitActions';
import { visitAccordionSummaryTitle } from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/AlternativeArrivalWindows/styles';
import { bool, func, string } from 'prop-types';
import React from 'react';

export default function VisitSlotTitle({
  confirmNewArrivalTime,
  deleteVisitFromSchedule,
  disabledNewAWConfirmation,
  disableDeleteVisit,
  hasNoAvailableWindows,
  isExpanded,
  isUnscheduled,
  onExpand,
  title,
}) {
  return (
    <Box sx={visitAccordionSummaryTitle} onClick={onExpand}>
      <Typography
        sx={{
          lineHeight: '32px',
          mr: '12px',
          cursor: onExpand ? 'pointer' : 'default',
        }}
        variant="h5"
      >
        {title}
      </Typography>
      <VisitActions
        confirmArrivalTime={confirmNewArrivalTime}
        deleteVisitFromSchedule={deleteVisitFromSchedule}
        disableDelete={disableDeleteVisit}
        disabledConfirmation={disabledNewAWConfirmation}
        isUnscheduledVisit={isUnscheduled}
        hasNoAvailableWindows={hasNoAvailableWindows}
        showActions={isExpanded}
      />
    </Box>
  );
}

VisitSlotTitle.propTypes = {
  confirmNewArrivalTime: func,
  deleteVisitFromSchedule: func,
  disableDeleteVisit: bool,
  disabledNewAWConfirmation: bool,
  hasNoAvailableWindows: bool,
  isExpanded: bool,
  isUnscheduled: bool,
  onExpand: func,
  title: string,
};
