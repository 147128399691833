import moment from 'moment';
import { ISO_DATE_ONLY_FORMAT, SHORT_DATE_FORMAT } from '../../../../../../../../../../shared/constants';

export const getSummaryData = (data) => {
  const {
    careProgramVisits,
    effectiveDate,
    selectedTimeSlots,
    temporaryVisitData,
    userData,
    visitData,
  } = data;
  const editedVisit = careProgramVisits.find(
    ({ visitId }) => visitId === temporaryVisitData?.parentId,
  );
  const arrivalWindows = visitData?.days?.map((day) => {
    if (editedVisit?.days?.find((dayData) => dayData.day === day)) {
      return {
        ...(selectedTimeSlots && selectedTimeSlots[day]
          ? selectedTimeSlots[day]
          : { day }),
        initialDayData: editedVisit.days.find((dayData) => dayData.day === day),
      };
    }
    if (selectedTimeSlots && selectedTimeSlots[day])
      return selectedTimeSlots[day];
    return { day };
  });
  return {
    client: `${userData?.clientDetails?.firstName} ${userData?.clientDetails?.lastName}`,
    address: userData?.clientDetails?.address,
    visitType: visitData?.visitType.name,
    duration: visitData?.duration,
    ...(selectedTimeSlots?.arrivalStart
      ? {
          arrivalWindow: `${selectedTimeSlots?.arrivalStart} - ${selectedTimeSlots?.arrivalEnd}`,
        }
      : {}),
    repeat: visitData?.repeat,
    effectiveDate: moment(effectiveDate, ISO_DATE_ONLY_FORMAT).format(
      SHORT_DATE_FORMAT,
    ),
    arrivalWindows,
  };
};
