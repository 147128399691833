import { getStatusClassName } from '../../../../shared/utils/common';

export const minHour = (events) =>
  events?.reduce((min, item) => {
    const arrivalStart = item.arrivalTimeStart?.toString();
    const timeParts = arrivalStart?.split(' ');
    const hours = parseInt(timeParts?.[4], 10);
    const isPM = timeParts?.[1]?.includes('PM');
    const hours24 = isPM ? hours + 12 : hours;

    return Math.min(min, hours24);
  }, 24);

export const maxHour = (events) =>
  events?.reduce((max, item) => {
    const arrivalEnd = item?.start?.toString();
    const timeParts = arrivalEnd?.split(' ');
    const hours = parseInt(timeParts?.[4], 10);
    const isPM = timeParts?.[1]?.includes('PM');
    const hours24 = isPM ? hours + 12 : hours;
    const duration = parseInt(item.duration, 10);
    const maxWithDuration = hours24 + Math.floor(duration / 60);
    return Math.max(max, maxWithDuration);
  }, 0);

export const checkIsCreateVisitStepConfirmButtonDisabled = (data) => {
  const { availabilityScheduling, step, visitData, visitTasksDetails } = data;
  if (!visitData) {
    return null;
  }
  if (step === 0) {
    const {
      arrivalEnd,
      arrivalStart,
      dates,
      duration,
      exactTimeSlot,
      visitType,
    } = visitData || {};
    return (
      !(
        !!(arrivalStart?.length && arrivalEnd?.length) ||
        !!exactTimeSlot?.length
      ) ||
      !dates.length ||
      !duration ||
      !visitType
    );
  }
  if (step === 1) {
    return !!availabilityScheduling?.unAvailableSchedule?.length;
  }
  if (step === 2) {
    return !visitTasksDetails?.length;
  }
  return false;
};
export const getEventBoxClass = (event) => {
  const classNameData = { className: 'default' };

  if (event.visitType === 'Lunch break' || event?.title === 'Lunch break') {
    classNameData.className = classNameData.className.concat(' lunchBreak');
  }

  classNameData.className = getStatusClassName(classNameData.className, event);
  return classNameData;
};
