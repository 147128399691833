import { Box, Chip, Typography } from '@mui/material';
import moment from 'moment/moment';
import { instanceOf, number } from 'prop-types';
import React from 'react';
import {
  chipArrivalStartStyle,
  chipArrivalTimeEndStyle,
  chipArrivalWindowStyle,
} from '../../../../components/CalendarsEventComponent/styles';

export default function ArrivalWindow({
  arrivalEnd = {},
  arrivalStart = {},
  arrivalWindowStartPosition = 0,
  arrivalEndPosition = 0,
}) {
  return (
    <Box className="timeContent">
      <Box>
        <Chip
          label={moment(arrivalStart).format('h:mm A')}
          sx={{
            ...chipArrivalStartStyle,
            top: `${-arrivalWindowStartPosition}px`,
          }}
        />
        <Chip
          label={moment(arrivalEnd).format('h:mm A')}
          sx={{
            ...chipArrivalTimeEndStyle,
            top: `${arrivalEndPosition}px`,
          }}
        />
        <Box
          sx={{
            ...chipArrivalWindowStyle,
            top: `${-arrivalWindowStartPosition + 25}px`,
          }}
        >
          <Typography
            sx={{
              color: '#2C2D2E',
              fontSize: '12px',
              lineHeight: '16px',
            }}
          >
            Arrival window
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

ArrivalWindow.propTypes = {
  arrivalEnd: instanceOf(Object),
  arrivalStart: instanceOf(Object),
  arrivalWindowStartPosition: number,
  arrivalEndPosition: number,
};
