import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { bool, func, instanceOf, number } from 'prop-types';
import React, { useMemo, useState } from 'react';
import CustomPagination from '../../components/Pagination';
import { customTableStyles } from './styles';

function CustomTable({
  apiRef = { current: {} },
  checkboxSelection = false,
  columns = [],
  customPaginationModel = {},
  customStyles = [],
  getRowClassName = () => {},
  getRowId = () => {},
  headerHeight = 0,
  isLoading = false,
  isRowSelectable = () => {},
  noRowsOverlay = () => {},
  onRowClick = () => {},
  onRowSelectionModelChange = () => {},
  page = 0,
  pageSize = 0,
  pageSizeOptions = [],
  rowHeight = 0,
  rows = [],
  rowSelectionModel = [],
  setCustomPaginationModel = () => {},
  setSortModelState = () => {},
  sortModel = [],
  withoutPagination = false,
  withSavedPagination = false,
}) {
  const [paginationModel, setPaginationModel] = useState({
    pageSize,
    page: 0,
  });
  const getColumns = useMemo(() => columns, [columns]);
  const getRows = useMemo(() => rows, [rows]);
  const paginationModelProp = useMemo(() => {
    if (withoutPagination) {
      return null;
    }
    if (!withoutPagination && withSavedPagination) {
      return customPaginationModel;
    }
    return paginationModel;
  }, [
    customPaginationModel,
    paginationModel,
    withSavedPagination,
    withoutPagination,
  ]);
  return (
    <Box sx={{ width: '100%' }}>
      <DataGrid
        rowSelectionModel={rowSelectionModel}
        apiRef={apiRef}
        autoHeight
        columnHeaderHeight={headerHeight}
        columns={getColumns}
        columnBuffer={getColumns.length}
        density="comfortable"
        disableColumnMenu
        checkboxSelection={checkboxSelection}
        isRowSelectable={isRowSelectable}
        onRowSelectionModelChange={onRowSelectionModelChange}
        disableColumnSelector
        disableDensitySelector
        disableRowSelectionOnClick
        disableVirtualization
        getRowClassName={(params) =>
          getRowClassName ? `${getRowClassName(params)}` : {}
        }
        getRowHeight={() => rowHeight || 'auto'}
        getRowId={(row) => (row.id ? row.id : getRowId(row))}
        hideFooterPagination={withoutPagination}
        initialState={{
          ...(withoutPagination
            ? {}
            : { pagination: { paginationModel: { pageSize, page } } }),
        }}
        loading={isLoading}
        onPaginationModelChange={
          withSavedPagination ? setCustomPaginationModel : setPaginationModel
        }
        onRowClick={onRowClick}
        onSortModelChange={setSortModelState}
        pageSizeOptions={withoutPagination ? null : pageSizeOptions}
        paginationModel={paginationModelProp}
        rows={getRows}
        slots={{
          pagination: withoutPagination ? null : CustomPagination,
          noRowsOverlay,
        }}
        sortingOrder={['asc', 'desc']}
        sortModel={sortModel}
        sx={[...customTableStyles, ...(customStyles || [])]}
      />
    </Box>
  );
}

CustomTable.propTypes = {
  apiRef: instanceOf(Object),
  checkboxSelection: bool,
  columns: instanceOf(Array),
  customPaginationModel: instanceOf(Object),
  customStyles: instanceOf(Array),
  getRowClassName: func,
  getRowId: func,
  headerHeight: number,
  isLoading: bool,
  isRowSelectable: func,
  noRowsOverlay: func,
  onRowClick: func,
  onRowSelectionModelChange: func,
  page: number,
  pageSize: number,
  pageSizeOptions: instanceOf(Array),
  rowHeight: number,
  rows: instanceOf(Array),
  rowSelectionModel: instanceOf(Array),
  setCustomPaginationModel: func,
  setSortModelState: func,
  sortModel: instanceOf(Array),
  withoutPagination: bool,
  withSavedPagination: bool,
};

export default React.memo(CustomTable);
