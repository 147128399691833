import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { bool, func, instanceOf, string } from 'prop-types';
import * as React from 'react';
import { checkIsDayDisabled } from '../../shared/utils/common';
import CounterBadge from '../../components/CounterBadge';
import CustomBadges from '../../components/CustomBadges';
import {
  customSelectFormControlStyles,
  customSelectItem,
  customSelectPaperProps,
  customSelectStyles,
} from './styles';

export default function TaskItemMultiselect({
  disabled = false,
  disabledDays = {},
  id = '',
  options = [],
  placeHolder = '',
  selectedValue = [],
  setValue = () => {},
}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === 'all') {
      const filteredWeekendDays = options?.filter(
        (name) =>
          !(
            (name === 'Saturday' && !disabledDays?.saturdaySwitchedOn) ||
            (name === 'Sunday' && !disabledDays?.sundaySwitchedOn)
          ),
      );
      const chosenOptions = id === 'days' ? filteredWeekendDays : options;

      setValue({
        field: id,
        value:
          selectedValue?.length === chosenOptions?.length ? [] : chosenOptions,
      });
      return '';
    }
    let duplicateRemoved = [];
    value.forEach((item) => {
      if (duplicateRemoved.findIndex((option1) => option1 === item) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x === item);
      } else {
        duplicateRemoved.push(item);
      }
    });
    setValue({ field: id, value: duplicateRemoved });
    return '';
  };

  const onDeleteItem = (value) =>
    setValue({
      field: id,
      value: selectedValue.filter((item) => item !== value),
    });

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <FormControl sx={{ ...customSelectFormControlStyles, width: '100%' }}>
        <Select
          className={
            options?.length === selectedValue?.length ? 'has-items' : ''
          }
          disabled={disabled}
          IconComponent={ExpandMoreIcon}
          displayEmpty
          multiple
          onChange={handleChange}
          renderValue={() => (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {placeHolder}
              {selectedValue?.length > 0 && (
                <CounterBadge
                  label={
                    selectedValue?.length === options?.length
                      ? 'ALL'
                      : selectedValue.length.toString()
                  }
                />
              )}
            </Box>
          )}
          MenuProps={{
            disableAutoFocusItem: true,
            PaperProps: {
              sx: customSelectPaperProps,
            },
          }}
          sx={customSelectStyles}
          value={selectedValue}
        >
          <MenuItem
            name="all"
            value="all"
            disableRipple
            className="select-all-item"
            sx={{
              ...customSelectItem,
            }}
          >
            <Checkbox
              checked={
                options?.length > 0 && options?.length === selectedValue?.length
              }
            />
            All
          </MenuItem>
          {options?.map((name) => (
            <MenuItem
              key={name}
              value={name}
              disableRipple
              disabled={id === 'days' && checkIsDayDisabled(name, disabledDays)}
              sx={{
                ...customSelectItem,
              }}
            >
              <Checkbox
                checked={
                  !selectedValue?.length
                    ? false
                    : selectedValue?.findIndex((item) => item === name) >= 0
                }
                sx={{ padding: '5px 9px' }}
              />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Collapse
        in={
          !!selectedValue?.length && options?.length !== selectedValue?.length
        }
      >
        <CustomBadges
          disabled={disabled}
          items={
            selectedValue?.map((skill) => ({
              name: skill,
              value: skill,
            })) || []
          }
          onDelete={onDeleteItem}
        />
      </Collapse>
    </Box>
  );
}

TaskItemMultiselect.propTypes = {
  disabled: bool,
  disabledDays: instanceOf(Object),
  id: string,
  options: instanceOf(Array),
  placeHolder: string,
  selectedValue: instanceOf(Array),
  setValue: func,
};
