/* eslint-disable react/prop-types */
import moment from 'moment';
import { bool, func, instanceOf, number } from 'prop-types';
import React from 'react';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import {
  DEFAULT_MAX_WORKING_HOUR,
  DEFAULT_WORKING_HOURS,
} from '../../shared/constants';
import './index.css';

moment.locale('es-es', {
  week: {
    dow: 1,
  },
});
const localizer = momentLocalizer(moment);
const now = new Date();

export default function SevenDaysCalendar({
  events = [],
  firstVisit = 0,
  lastVisit = 0,
  onSelectEvent = () => {},
  toolbar = false,
  customToolbar,
  dateHeaderWrapper,
  eventComponent,
  eventStyleGetter,
  onNavigate,
  className,
  date,
  dayPropGetter,
  slotPropGetter,
}) {
  const calculateLastVisit =
    lastVisit - firstVisit <= DEFAULT_WORKING_HOURS - 1
      ? firstVisit + DEFAULT_WORKING_HOURS
      : lastVisit;

  const minTime = new Date();
  minTime.setHours(firstVisit, 0, 0);
  const calcMaxLastVisit =
    calculateLastVisit > DEFAULT_MAX_WORKING_HOUR
      ? DEFAULT_MAX_WORKING_HOUR
      : calculateLastVisit;
  const maxTime = new Date();
  maxTime.setHours(calcMaxLastVisit, 0, 0);

  const timeFormats = {
    timeGutterFormat: 'h A',
    eventTimeRangeFormat: ({ start, end }) =>
      `${moment(start).format('h A')} - ${moment(end).format('h A')}`,
  };

  return (
    <Calendar
      className={`seven-days-calendar ${className}`}
      components={{
        event: eventComponent,
        toolbar: customToolbar,
        week: {
          header: dateHeaderWrapper,
        },
      }}
      defaultView={Views.WEEK}
      eventPropGetter={eventStyleGetter}
      events={events}
      dayPropGetter={dayPropGetter}
      date={date}
      dayLayoutAlgorithm="no-overlap"
      step={5}
      timeslots={12}
      slotPropGetter={slotPropGetter}
      formats={timeFormats}
      localizer={localizer}
      max={maxTime}
      min={minTime}
      now={now}
      onNavigate={onNavigate}
      onSelectEvent={onSelectEvent}
      toolbar={toolbar}
      tooltipAccessor={() => ''}
      views={{ week: true }}
    />
  );
}

SevenDaysCalendar.propTypes = {
  events: instanceOf(Array),
  firstVisit: number,
  lastVisit: number,
  onSelectEvent: func,
  toolbar: bool,
};
