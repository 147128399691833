import { Box } from '@mui/material';
import moment from 'moment';
import { func } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import {
  H12_TIME_FORMAT,
  ISO_DATE_ONLY_FORMAT,
  visitArrivalTimeTypes,
  visitArrivalTimeTypesNames,
} from '../../../../shared/constants';
import { VisitInfoTypeContext } from '../../../../shared/context';
import ArrivalTypeSelector from '../../../../components/ArrivalTypeSelector';
import { CustomDatePicker } from '../../../../components/CustomDateAndTimePickers';
import CustomTimeSelect from '../../../../components/CustomDateAndTimePickers/CustomTimeSelect';
import ErrorAlert from '../../../../components/ErrorAlert';
import { customDatePickerStyles, timeInputStyles } from '../../styles';
import ArrivalWindow from './ArrivalWindow';
import SetupActions from './SetupActions';
import { visitDateAndTimeWrapperStyles } from './styles';

export default function VisitDateAndArrivalTimeSetup({
  cancelEditing = () => {},
}) {
  const {
    initialVisitData,
    isRecheckAvailable,
    recheckAvailability,
    temporaryVisitData,
    updateTemporaryVisitData,
  } = useContext(VisitInfoTypeContext);
  const [visitArrivalTimeType, setVisitArrivalTimeType] =
    useState('arrivalWindow');
  const [error, setError] = useState(null);

  useEffect(() => {
    setVisitArrivalTimeType(
      temporaryVisitData?.isExactTime ? 'exactTime' : 'arrivalWindow',
    );
  }, [temporaryVisitData?.isExactTime]);

  const changeExactTime = (timeValue) => {
    updateTemporaryVisitData({
      arrivalTimeStart: timeValue,
      arrivalTimeEnd: timeValue,
    });
  };
  const changeArrivalTimeRange = (data) => {
    updateTemporaryVisitData({
      arrivalTimeStart: data.arrivalStart,
      arrivalTimeEnd: data.arrivalEnd,
    });
  };
  const selectDate = (date) =>
    updateTemporaryVisitData({
      date: moment(date).startOf('day').format(ISO_DATE_ONLY_FORMAT),
    });

  const closeDateEditing = () => {
    cancelEditing();
    updateTemporaryVisitData({
      arrivalTimeStart: initialVisitData.arrivalTimeStart,
      arrivalTimeEnd: initialVisitData.arrivalTimeEnd,
      date: initialVisitData.date,
    });
  };

  const onChangeArrivalTimeType = (e) => {
    const isExactTimeSelected = e.target.value === 'exactTime';
    updateTemporaryVisitData({
      isExactTime: isExactTimeSelected,
      arrivalTimeStart: initialVisitData.arrivalTimeStart,
      arrivalTimeEnd: isExactTimeSelected
        ? initialVisitData.arrivalTimeStart
        : moment(initialVisitData.arrivalTimeStart, H12_TIME_FORMAT)
            .add(1, 'hour')
            .format(H12_TIME_FORMAT),
    });
  };

  const maxDatePickerDate = moment().startOf('week').add(13, 'days');

  return (
    <Box sx={visitDateAndTimeWrapperStyles}>
      <ArrivalTypeSelector
        arrivalTimeOptions={visitArrivalTimeTypes}
        arrivalTimeType={visitArrivalTimeType}
        changeArrivalTimeType={(e) => onChangeArrivalTimeType(e)}
      />
      <Box sx={{ display: 'flex', width: '100%', mt: '12px', gap: '16px' }}>
        <CustomDatePicker
          customStyles={customDatePickerStyles}
          changeDate={(newValue) => selectDate(newValue)}
          date={moment(temporaryVisitData?.date, ISO_DATE_ONLY_FORMAT)}
          isDisablePast
          label="Visit Scheduled Date"
          maxDate={maxDatePickerDate}
        />
        {visitArrivalTimeType === visitArrivalTimeTypesNames.exactTime ? (
          <CustomTimeSelect
            customStyles={timeInputStyles}
            isRequired
            onSelectTimeValue={changeExactTime}
            placeHolder="AW Scheduled Start Time"
            selectedTime={temporaryVisitData?.arrivalTimeStart}
          />
        ) : (
          <ArrivalWindow
            arrivalEnd={temporaryVisitData?.arrivalTimeEnd}
            arrivalStart={temporaryVisitData?.arrivalTimeStart}
            changeArrivalTimeRange={changeArrivalTimeRange}
            customStyles={timeInputStyles}
            gap={16}
            isRequired
            setError={setError}
          />
        )}
      </Box>
      {error && <ErrorAlert error={error} />}
      <SetupActions
        disableCheckAvailability={!isRecheckAvailable || !!error}
        onCancel={closeDateEditing}
        recheckAvailability={recheckAvailability}
      />
    </Box>
  );
}

VisitDateAndArrivalTimeSetup.propTypes = {
  cancelEditing: func,
};
