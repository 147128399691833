import { Step, StepButton, StepIcon, Stepper, Typography } from '@mui/material';
import completedStep from '../../../../img/icons/complete-step.svg';
import { bool, func, instanceOf, number } from 'prop-types';
import React from 'react';
import { customStepperStyles } from './styles';

export default function CustomStepper({
  changeStep = () => {},
  completedSteps = [],
  currentStep = 0,
  disabled = false,
  steps = [],
}) {
  return (
    <Stepper
      activeStep={currentStep}
      sx={{
        ...customStepperStyles,
      }}
    >
      {steps?.map((label, index) => (
        <Step
          key={label}
          disabled={disabled || !completedSteps.includes(index)}
          completed={completedSteps.indexOf(index) >= 0}
        >
          <StepButton
            icon={
              completedSteps.indexOf(index) >= 0 ? (
                <StepIcon
                  icon={<img src={completedStep} alt="completed step icon" />}
                />
              ) : null
            }
            color="inherit"
            onClick={() => changeStep(index)}
          >
            <Typography variant="body1" sx={{ cursor: 'pointer' }}>
              {label}
            </Typography>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
}

CustomStepper.propTypes = {
  changeStep: func,
  completedSteps: instanceOf(Array),
  currentStep: number,
  disabled: bool,
  steps: instanceOf(Array),
};
