import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { COLORS } from '../../../../../../../../../../shared/styles/Theme';

export default function StepTitle() {
  return (
    <Box sx={{ display: 'flex', mb: '20px', alignItems: 'center' }}>
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 600,
          color: COLORS.blue[900],
        }}
      >
        Personal Information
      </Typography>
      <Divider sx={{ marginLeft: '6px', color: COLORS.blue[200], flex: 1 }} />
    </Box>
  );
}
