import { Box, Typography } from '@mui/material';
import carIcon from '../../../../img/icons/car-icon.svg';
import { number } from 'prop-types';
import React from 'react';
import { travelTimeStyles } from '../../../../components/CalendarsEventComponent/styles';

export default function TravelTime({ travelTime = 0 }) {
  const travelTimeLessThanMinimal = travelTime < 15;
  return (
    <Box
      className="travelTime"
      sx={travelTimeStyles({
        travelTime,
        travelTimeLessThanMinimal,
      })}
    >
      <img style={{ width: '20px', height: '23px' }} src={carIcon} alt="car" />
      <Typography sx={{ ml: '8px' }}>
        {travelTimeLessThanMinimal ? 'Less than 15 min' : ` ${travelTime} min`}
      </Typography>
    </Box>
  );
}

TravelTime.propTypes = {
  travelTime: number,
};
