import { Box, Button, DialogActions } from '@mui/material';
import { bool, func, string } from 'prop-types';
import React from 'react';
import { COLORS } from '../../shared/styles/Theme';

export default function Actions({
  cancelButtonName = '',
  cancelCallback = () => {},
  disabled = false,
  submitButtonName = '',
  submitCallback = () => {},
}) {
  return (
    <DialogActions
      sx={{
        justifyContent: 'flex-end',
        width: '100%',
        padding: '12px 20px',
        borderTop: `1px solid ${COLORS.blue[200]}`,
      }}
    >
      <Box sx={{ display: 'flex', gap: '12px' }}>
        {cancelButtonName !== '' && (
          <Button
            color="secondary"
            variant="outlined"
            onClick={cancelCallback}
            disableElevation
          >
            {cancelButtonName}
          </Button>
        )}
        <Button
          color="primary"
          variant="contained"
          onClick={submitCallback}
          disableElevation
          disabled={disabled}
        >
          {submitButtonName}
        </Button>
      </Box>
    </DialogActions>
  );
}

Actions.propTypes = {
  cancelButtonName: string,
  cancelCallback: func,
  disabled: bool,
  submitButtonName: string,
  submitCallback: func,
};
