import { Box, Typography } from '@mui/material';
import errorIconTriangle from '../../../../../../../../img/icons/error-icon.svg';
import moment from 'moment';
import React from 'react';
import {
  H12_TIME_FORMAT,
  MONTH_DAY_YEAR_FORMAT,
  SHORT_DATE_FORMAT,
  backgroundColorSchemeMap,
} from '../../../../../../../../shared/constants';
import { COLORS } from '../../../../../../../../shared/styles/Theme';
import {
  getArrivalRange,
  getIconComponent,
  getOneTimeOrRepeatedIcon,
} from '../../../../../../../../shared/utils/common';
import Badge from '../../../../../../../../components/Badge';
import CustomTooltip from '../../../../../../../../components/CustomTooltip';
import ShadowCaregiverCell from '../../../../../../../../components/DeletedSVShadowCaregiverCell';

const getScheduledTimeRange = (data, status) => (
  <div>
    {data?.oldScheduledTime && (
      <Typography
        sx={{
          textDecoration: data?.newScheduledTime ? 'line-through' : 'none',
        }}
      >{`${moment(data?.oldScheduledTime?.start, H12_TIME_FORMAT).format(
        H12_TIME_FORMAT,
      )} - ${moment(data?.oldScheduledTime?.end, H12_TIME_FORMAT).format(
        H12_TIME_FORMAT,
      )}`}</Typography>
    )}
    {data?.newScheduledTime && (
      <CustomTooltip
        title={
          status === 'active' ? 'Time can be changed within arrival window' : ''
        }
      >
        <div>
          <Badge
            colorScheme={backgroundColorSchemeMap[status || 'active']}
            label={`${moment(
              data?.newScheduledTime?.start,
              H12_TIME_FORMAT,
            ).format(H12_TIME_FORMAT)} - ${moment(
              data?.newScheduledTime?.end,
              H12_TIME_FORMAT,
            ).format(H12_TIME_FORMAT)}`}
          />
        </div>
      </CustomTooltip>
    )}
  </div>
);

const getCaregiverData = (newCaregiver, initialCaregiver) =>
  newCaregiver ? (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {initialCaregiver && (
        <Typography
          noWrap
          sx={{
            textDecoration: 'line-through',
            color: COLORS.blue[900],
            fontSize: '14px',
            mr: '8px',
          }}
        >
          {initialCaregiver}
        </Typography>
      )}
      {newCaregiver && (
        <Typography
          noWrap
          sx={{
            textDecoration: 'none',
            color: COLORS.blue[700],
            fontSize: '14px',
          }}
        >
          {newCaregiver}
        </Typography>
      )}
    </Box>
  ) : (
    <Typography
      noWrap
      sx={{
        textDecoration: 'none',
        color: COLORS.blue[700],
        fontSize: '14px',
      }}
    >
      {initialCaregiver}
    </Typography>
  );

export const caregiverStatusChangingColumns = [
  {
    field: 'oldDate',
    headerName: 'Date',
    minWidth: 170,
    maxWidth: 200,
    flex: 1,
    sortable: false,
    renderCell: ({ row, value }) => (
      <Box sx={{ display: 'flex', gap: '4px' }}>
        {row?.status?.status === 'error' && (
          <img src={errorIconTriangle} alt="error icon" />
        )}
        <Typography sx={{ fontSize: '14px' }}>
          {value
            ? moment(value).format(MONTH_DAY_YEAR_FORMAT)
            : moment(row?.newDate).format(MONTH_DAY_YEAR_FORMAT)}
        </Typography>
      </Box>
    ),
  },
  {
    field: 'visitType',
    headerName: 'Visit Type',
    flex: 1,
    minWidth: 220,
    maxWidth: 260,
    sortable: false,
    renderCell: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '6px', maxWidth: '240px' }}>
        {getOneTimeOrRepeatedIcon(row?.repeat)}
        {getIconComponent(row.type || row.visitType)}
        <Typography
          noWrap
          variant="body1"
          sx={{ color: COLORS.blue[700], fontSize: '14px' }}
        >
          {row.type || row.visitType}
        </Typography>
      </Box>
    ),
  },
  {
    field: 'duration',
    headerName: 'Duration',
    sortable: false,
    minWidth: 100,
    maxWidth: 120,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography sx={{ fontSize: '14px' }}>
        {value !== null ? `${value} min` : '0 min'}
      </Typography>
    ),
  },
  {
    field: 'arrivalWindow',
    headerName: 'Arrival Window',
    sortable: false,
    minWidth: 180,
    maxWidth: 200,
    flex: 1,
    renderCell: ({ row }) => getArrivalRange(row),
  },
  {
    field: 'scheduledTime',
    headerName: 'Scheduled Time',
    sortable: false,
    minWidth: 180,
    maxWidth: 200,
    flex: 1,
    renderCell: ({ row }) =>
      row?.oldScheduledTime || row?.newScheduledTime
        ? getScheduledTimeRange(row, 'active')
        : '',
  },
  {
    field: 'team',
    headerName: 'Team',
    sortable: false,
    minWidth: 130,
    maxWidth: 160,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography sx={{ fontSize: '14px' }}>{value?.name || ''}</Typography>
    ),
  },
  {
    field: 'caregiver',
    headerName: 'Caregiver Replacement',
    minWidth: 260,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => {
      const initialCaregiver = row?.oldCaregiver
        ? `${row?.oldCaregiver?.firstName} ${row?.oldCaregiver?.lastName}`
        : null;
      const newCaregiver = row?.newCaregiver
        ? `${row?.newCaregiver?.firstName} ${row?.newCaregiver?.lastName}`
        : null;
      return row?.status?.status === 'success' ? (
        getCaregiverData(newCaregiver, initialCaregiver)
      ) : (
        <Badge
          colorScheme={backgroundColorSchemeMap.error}
          label="Reassignment impossible"
        />
      );
    },
  },
  {
    field: 'status',
    headerName: 'Visit Status',
    minWidth: 180,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => (
      <Badge
        colorScheme={
          backgroundColorSchemeMap[
            row?.repeat?.toLowerCase() === 'weekly' ? 'active' : 'info'
          ]
        }
        label={
          row?.repeat?.toLowerCase() === 'weekly' ? 'Scheduled' : 'Allocated'
        }
      />
    ),
  },
];
export const getDeletedShadowVisitsColumns = (closeDialog) => [
  {
    field: 'oldDate',
    headerName: 'Date',
    minWidth: 170,
    maxWidth: 200,
    flex: 1,
    sortable: false,
    renderCell: ({ row, value }) => (
      <Box sx={{ display: 'flex', gap: '4px' }}>
        {row?.status?.status === 'error' && (
          <img src={errorIconTriangle} alt="error icon" />
        )}
        <Typography sx={{ fontSize: '14px' }}>
          {value
            ? moment(value).format(MONTH_DAY_YEAR_FORMAT)
            : moment(row?.newDate).format(MONTH_DAY_YEAR_FORMAT)}
        </Typography>
      </Box>
    ),
  },
  {
    field: 'visitType',
    headerName: 'Visit Type',
    flex: 1,
    minWidth: 220,
    maxWidth: 260,
    sortable: false,
    renderCell: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '6px', maxWidth: '240px' }}>
        {getOneTimeOrRepeatedIcon(row?.repeat)}
        {getIconComponent(row.type || row.visitType)}
        <Typography
          noWrap
          variant="body1"
          sx={{ color: COLORS.blue[700], fontSize: '14px' }}
        >
          {row.type || row.visitType}
        </Typography>
      </Box>
    ),
  },
  {
    field: 'duration',
    headerName: 'Duration',
    sortable: false,
    minWidth: 100,
    maxWidth: 120,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography sx={{ fontSize: '14px' }}>
        {value !== null ? `${value} min` : '0 min'}
      </Typography>
    ),
  },
  {
    field: 'arrivalWindow',
    headerName: 'Arrival Window',
    sortable: false,
    minWidth: 180,
    maxWidth: 200,
    flex: 1,
    renderCell: ({ row }) => getArrivalRange(row),
  },
  {
    field: 'scheduledTime',
    headerName: 'Scheduled Time',
    sortable: false,
    minWidth: 180,
    maxWidth: 200,
    flex: 1,
    renderCell: ({ row }) =>
      row?.oldScheduledTime || row?.newScheduledTime
        ? getScheduledTimeRange(row, 'active')
        : '',
  },
  {
    field: 'team',
    headerName: 'Team',
    sortable: false,
    minWidth: 130,
    maxWidth: 160,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography sx={{ fontSize: '14px' }}>{value?.name || ''}</Typography>
    ),
  },
  {
    field: 'oldShadowCaregiver',
    headerName: 'Shadow caregiver',
    minWidth: 260,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => (
      <ShadowCaregiverCell closeDialog={closeDialog} row={row} />
    ),
  },
  {
    field: 'date',
    headerName: 'Date',
    sortable: false,
    width: 150,
    renderCell: ({ value }) => (
      <Typography>{moment(value).format(SHORT_DATE_FORMAT)}</Typography>
    ),
  },
];
