import { COLORS } from '../../../../shared/styles/Theme';

const listItemsColors = {
  error: COLORS.red[500],
  warning: COLORS.yellow[700],
};

export const getListMarkerStyles = (type) => ({
  display: 'flex',
  width: '6px',
  height: '6px',
  backgroundColor: listItemsColors[type],
  borderRadius: '50%',
});

export const listItemsStyles = {
  display: 'flex',
  mt: '12px',
  alignItems: 'center',
  '& img': { mt: '2px', height: '14px', alignItems: 'start' },
};
