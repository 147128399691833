import { Box, Typography } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetVisitDetailsHistoryTasksQuery } from '../../../../../api/Visits/api';
import moment from 'moment';
import { string } from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { HISTORY_UPDATE_FORMAT } from '../../../../../shared/constants';
import AlertContext from '../../../../../components/Alert';
import Loader from '../../../../../components/Loader';
import CustomTable from '../../../../../components/Table';
import {
  parseObjectTypeEvents,
  parsePrimitiveTypeEvents,
} from '../../../../../components/VisitInfoTypeProvider/components/VisitDetails/VisitHistory/utils';
import {
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  historyColumns,
  skippedInsertOperationEvents,
  trackedEvents,
} from './constants';

export default function VisitHistory({ date = '', visitId = '' }) {
  const apiRef = useGridApiRef();
  const { setAlert } = useContext(AlertContext);

  const {
    data: visitDetailsHistory,
    isLoading,
    error: visitDetailsHistoryError,
  } = useGetVisitDetailsHistoryTasksQuery(
    { date, visitId },
    { refetchOnMountOrArgChange: true },
  );

  if (visitDetailsHistoryError) {
    setAlert({
      errorData: visitDetailsHistoryError,
      type: 'error',
    });
  }
  const updatedEventsCheck = useMemo(
    () =>
      visitDetailsHistory
        ?.map((event, index) => {
          if (event?.changes === null) return false;
          const transformedEventsList =
            event?.objectDescription &&
            JSON.parse(event?.objectDescription)?.type?.includes('Task')
              ? [
                  {
                    propertyName: 'task',
                    type: 'object',
                    changes: JSON.parse(event.changes),
                    description: JSON.parse(event?.objectDescription),
                    taskType: JSON.parse(event?.objectDescription)?.type,
                  },
                ]
              : JSON.parse(event.changes);
          const filteredEvents = transformedEventsList.filter(
            ({ operation, propertyName }) =>
              trackedEvents.includes(propertyName) &&
              !(
                skippedInsertOperationEvents.includes(propertyName) &&
                operation === 'insert'
              ),
          );
          const onlyPrimitives = parsePrimitiveTypeEvents(filteredEvents);
          const filterObjectTypeArray = parseObjectTypeEvents(filteredEvents);

          const allChanges = onlyPrimitives?.concat(filterObjectTypeArray);

          if (!allChanges.length) {
            return false;
          }
          return {
            ...event,
            changes: allChanges,
            dateCreated: moment(event.dateCreated).format(
              HISTORY_UPDATE_FORMAT,
            ),
            id: index + 1,
          };
        })
        .filter(Boolean),
    [visitDetailsHistory],
  );
  const sortedUpdatedEvents = updatedEventsCheck
    ? [...updatedEventsCheck]?.sort((a, b) => {
        const dateA = new Date(a.dateCreated);
        const dateB = new Date(b.dateCreated);
        return dateB - dateA;
      })
    : [];
  if (isLoading) return <Loader />;

  return (
    <Box>
      {sortedUpdatedEvents?.length ? (
        <CustomTable
          apiRef={apiRef}
          columns={historyColumns}
          headerHeight={44}
          isLoading={isLoading}
          rows={sortedUpdatedEvents}
          pageSize={PAGE_SIZE}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
        />
      ) : (
        <Typography variant="body1">
          History is empty or has a wrong data
        </Typography>
      )}
    </Box>
  );
}

VisitHistory.propTypes = {
  date: string,
  visitId: string,
};
