import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetUsersQuery } from '../../../../api/Administration/api';
import { useGetCaregiverActivityQuery } from '../../../../api/Caregivers/api';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  HISTORY_UPDATE_FORMAT,
  SEARCH_INPUT_DEBOUNCE_DELAY,
} from '../../../../shared/constants';
import { useDebouncedCallback } from '../../../../shared/hooks/useDebounce';
import Activity from '../../../../components/Activity';
import { INITIAL_ACTIVITY_FILTERS } from '../../../../components/Activity/constants';
import AlertContext from '../../../../components/Alert';
import { activityOptions } from './constants';

function CaregiverActivity() {
  const { id } = useParams();
  const {
    data,
    isLoading,
    error: activityError,
  } = useGetCaregiverActivityQuery(
    { caregiverId: id },
    { refetchOnMountOrArgChange: true },
  );
  const apiRef = useGridApiRef();
  const [visitsFilters, setVisitsFilters] = useState(INITIAL_ACTIVITY_FILTERS);
  const { setAlert } = useContext(AlertContext);
  const { data: users, error } = useGetUsersQuery();
  const [filteredEvents, setFilteredEvents] = useState([]);

  const allChanges = data?.activitiesByEntity?.Caregiver?.concat(
    data?.activitiesByEntity?.Client,
    data?.activitiesByEntity?.TimeOffRequest,
  );
  const filteredData = allChanges?.filter(Boolean);
  const updatedEvents = filteredData
    ?.map((event, index) => {
      const changes = JSON.parse(event?.changes);
      const changesArray = [];
      const onlyPrimitives = changes?.filter(
        (change) =>
          change.type === 'primitive' &&
          change.propertyName !== 'modifiedDate' &&
          change.propertyName !== 'status',
      );
      const changeName = onlyPrimitives?.map((change) => ({
        ...change,
        activity: 'Profile Updates',
      }));
      changesArray.push(...changeName);
      const onlyPrimitivesPTO = changes?.filter(
        (change) =>
          change.type === 'primitive' &&
          (change.propertyName === 'modifiedDate' ||
            change.propertyName === 'status'),
      );
      const changePTOName = onlyPrimitivesPTO?.map((change) => ({
        ...change,
        activity: 'PTO&CallOuts',
      }));
      changesArray.push(...changePTOName);

      const onlyPrimaryRegions = changes
        ?.filter((change) => change.type === 'collection')
        ?.filter((change) => change.propertyName === 'primaryTeams')
        ?.map((change) => change.changes)
        .flat()
        .filter((change) => change.type === 'primitive');
      const changePropertyNamePrimary = onlyPrimaryRegions?.map((change) => ({
        ...change,
        propertyName: 'primary Region',
        activity: 'Profile Updates',
      }));
      changesArray.push(...changePropertyNamePrimary);
      const onlySecondaryRegions = changes
        ?.filter((change) => change.type === 'collection')
        ?.filter((change) => change.propertyName === 'secondaryTeams')
        ?.map((change) => change.changes)
        .flat()
        .filter((change) => change.type === 'primitive');
      const changePropertyNameSecondary = onlySecondaryRegions?.map(
        (change) => ({
          ...change,
          propertyName: 'secondary Region',
          activity: 'Profile Updates',
        }),
      );

      changesArray.push(...changePropertyNameSecondary);
      const onlyAvailabilities = changes
        ?.filter((change) => change.type === 'collection')
        ?.filter((change) => change.propertyName === 'availabilities')
        ?.map((change) => change.changes)
        ?.flat()
        ?.filter((change) => change.type === 'object')
        ?.map((change) => change.changes)
        ?.flat();
      const changePropertyNameAvailability = onlyAvailabilities?.map(
        (change) => ({
          ...change,
          propertyName: `Availabilities ${change.propertyName}`,
          activity: 'Availability',
        }),
      );
      changesArray.push(...changePropertyNameAvailability);
      return {
        ...event,
        changes: changesArray,
        dateCreated: moment(event?.dateCreated).format(HISTORY_UPDATE_FORMAT),
        id: index + 1,
      };
    })
    .filter(Boolean);
  const sortedUpdatedEvents = updatedEvents?.sort((a, b) => {
    const dateA = new Date(a.dateCreated);
    const dateB = new Date(b.dateCreated);
    return dateB - dateA;
  });
  useEffect(() => {
    setFilteredEvents(sortedUpdatedEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    setFilteredEvents(sortedUpdatedEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const errorData = error || activityError;
    if (errorData) {
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [activityError, error, setAlert]);

  useEffect(() => {
    setVisitsFilters((prevValue) => ({
      ...prevValue,
      user: users?.userDtoList?.map((user) => user?.externalId),
      activity: activityOptions.map((name) => name),
    }));
  }, [setVisitsFilters, users?.userDtoList]);

  const debouncedSearch = useDebouncedCallback((e) => {
    setVisitsFilters({ ...visitsFilters, [e.target.name]: e.target.value });
  }, SEARCH_INPUT_DEBOUNCE_DELAY);

  const filterEvents = useCallback(() => {
    if (!updatedEvents) return [];
    return updatedEvents.filter((event) => {
      const { author, changes } = event;
      if (visitsFilters.user && !visitsFilters.user?.includes(author)) {
        return false;
      }
      if (
        visitsFilters.activity &&
        !visitsFilters.activity?.includes(changes[0]?.activity)
      ) {
        return false;
      }
      const matchesSearch = changes.some((change) =>
        Object.values(change).some(
          (value) =>
            typeof value === 'string' &&
            value.toLowerCase().includes(visitsFilters.search),
        ),
      );

      if (matchesSearch) {
        return true;
      }
      return true;
    });
  }, [updatedEvents, visitsFilters]);

  useEffect(() => {
    const filtered = filterEvents();
    setFilteredEvents(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitsFilters]);

  return (
    <Box sx={{ mt: '10px' }}>
      <Activity
        filterData={visitsFilters}
        setFilterData={setVisitsFilters}
        userOptions={users?.userDtoList?.map((user) => user?.externalId)}
        debouncedSearch={debouncedSearch}
        data={filteredEvents}
        isLoading={isLoading}
        activityOptions={activityOptions.map((name) => name)}
        apiRef={apiRef}
      />
    </Box>
  );
}

export default CaregiverActivity;
