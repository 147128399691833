import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryRealDB } from '../../api/baseQueryBE';

export const USER_DATA_API_KEY = 'userDataApi';

const userDataApi = createApi({
  reducerPath: USER_DATA_API_KEY,
  baseQuery: baseQueryRealDB,
  endpoints: (builder) => ({
    getUserProfileData: builder.query({
      query: () => ({
        url: '/profile',
        method: 'GET',
      }),
    }),
  }),
});
export const { useGetUserProfileDataQuery } = userDataApi;

export default userDataApi;
