import { COLORS } from '../../../../../../shared/styles/Theme';

export const customSwitcher = {
  '& .MuiSwitch-thumb': {
    background: '#fff',
  },
  '& .MuiSwitch-track': {
    background: '#D4DCED',
  },
  '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    background: COLORS.green[500],
    opacity: 1,
  },
};
