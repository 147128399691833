import { Box } from '@mui/material';
import { CaregiverProfileContext } from '../../../../../pages/CaregiverDetails/context';
import React, { useContext, useMemo } from 'react';
import CustomSelect from '../../../../../components/Select';

export default function GenderSelector() {
  const { caregiverProfile, editingProfileDataLibrary, setCaregiverProfile } =
    useContext(CaregiverProfileContext);

  const genders = useMemo(
    () =>
      editingProfileDataLibrary?.skills?.genders?.map(
        (option) => option.description,
      ),
    [editingProfileDataLibrary],
  );

  const onSelectChange = (params) => {
    setCaregiverProfile({
      ...caregiverProfile,
      [params.field]: params.value,
    });
  };

  return (
    <Box sx={{ maxWidth: '250px', minWidth: '250px' }}>
      <CustomSelect
        id="gender"
        skillLang
        options={genders}
        isLoading={false}
        selectedValue={caregiverProfile.gender || ''}
        setValue={onSelectChange}
      />
    </Box>
  );
}
