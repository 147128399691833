import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem, Select } from '@mui/material';
import { bool, func, instanceOf, oneOfType, string } from 'prop-types';
import React from 'react';
import { visitStatusesMap } from '../../../../shared/constants';
import {
  customPaperStyles,
  customSelectItem,
  getCustomSelectStyles,
} from './style';

export default function VisitStatusSelect({
  bgColor = '',
  disabled = false,
  initialVisitStatus = '',
  isLoading = false,
  options = [],
  selectedValue = '' || {},
  setValue = () => {},
}) {
  const selectInitialValue = '';
  return (
    <Select
      IconComponent={ExpandMoreIcon}
      disabled={disabled || isLoading}
      displayEmpty
      onChange={(e) => setValue(e.target.value)}
      sx={{ ...getCustomSelectStyles(bgColor) }}
      MenuProps={{
        PaperProps: {
          sx: customPaperStyles,
        },
      }}
      value={selectedValue || selectInitialValue}
    >
      {options?.map(({ enabledIfStatus, key, name }) => (
        <MenuItem
          key={key}
          className={visitStatusesMap.canceled === name ? 'canceled-item' : ''}
          disabled={isLoading || !enabledIfStatus.includes(initialVisitStatus)}
          disableRipple
          sx={customSelectItem}
          value={name}
        >
          {name}
        </MenuItem>
      ))}
    </Select>
  );
}

VisitStatusSelect.propTypes = {
  bgColor: string,
  disabled: bool,
  initialVisitStatus: string,
  isLoading: bool,
  options: instanceOf(Array),
  selectedValue: oneOfType([instanceOf(Array), string, instanceOf(Object)]),
  setValue: func,
};
