import { COLORS } from '../../shared/styles/Theme';

export const dialogContentStyles = {
  '&::-webkit-scrollbar': {
    width: '0.4em',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
};

export const dialogTitleStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '10px 32px',
  borderBottom: `1px solid ${COLORS.blue[200]}`,
};

export const dialogTitleNameBlock = {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
};

export const backButtonStyles = {
  display: 'flex',
  width: '40px',
  height: '40px',
  padding: 0,
  backgroundColor: COLORS.blue[50],
  '&.hidden': {
    display: 'none',
  },
  '& .MuiSvgIcon-root': { color: COLORS.blue[900], fontSize: '20px' },
  '&.Mui-disabled': {
    backgroundColor: COLORS.blue[50],
    cursor: 'not-allowed',
    '& .MuiSvgIcon-root': { color: COLORS.blue[300] },
  },
};
