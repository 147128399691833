import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
// import Chevron from '../../img/icons/chevron-right.svg?react';
import shadowCaregiverIcon from '../../img/icons/deleted-shadow-visit-black-icon.svg';
import ErrorIcon from '../../img/icons/error-icon.svg';
import WarningIcon from '../../img/icons/warning-icon.svg';
import { bool, node, number, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import CounterBadge from '../../components/CounterBadge';
import {
  customAccordionStyles,
  customAccordionSummaryStyles,
  visitAccordionSummaryTitle,
} from './styles';

function AffectedVisitsAccordion({
  children = <div />,
  hasError = false,
  hasWarning = false,
  initialExpanded = false,
  shadowVisit = false,
  tasksCount = 0,
  title = '',
}) {
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    setExpanded(initialExpanded);
  }, [initialExpanded]);

  return (
    <Accordion elevation={0} expanded={expanded} sx={customAccordionStyles}>
      <AccordionSummary
        // expandIcon={<Chevron onClick={() => setExpanded(!expanded)} />}
        aria-controls="panel1bh-content"
        sx={customAccordionSummaryStyles}
      >
        <Box
          sx={visitAccordionSummaryTitle}
          onClick={() => setExpanded(!expanded)}
        >
          {hasWarning && <img src={WarningIcon} alt="Warning" />}
          {hasError && <img src={ErrorIcon} alt="Error" />}
          <Typography sx={{ lineHeight: '32px' }} variant="h5">
            {title}
          </Typography>
          {shadowVisit && (
            <img src={shadowCaregiverIcon} alt="shadowCaregiverIcon" />
          )}
          <CounterBadge label={tasksCount.toString()} hideIfNothing />
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>{children}</AccordionDetails>
    </Accordion>
  );
}

AffectedVisitsAccordion.propTypes = {
  children: node,
  hasError: bool,
  hasWarning: bool,
  initialExpanded: bool,
  shadowVisit: bool,
  tasksCount: number,
  title: string,
};

export default React.memo(AffectedVisitsAccordion);
