import { COLORS } from '../../../../shared/styles/Theme';

export const visitStatusChangingConfirmationWrapperStyles = {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  padding: '20px',
  marginBottom: '16px',
  backgroundColor: COLORS.yellow[200],
  zIndex: 1,
};

export const visitStatusChangingConfirmationActionsWrapper = {
  display: 'flex',
  width: '100%',
  justifyContent: 'end',
  marginTop: '20px',
  gap: '12px',
};

export const visitStatusChangingTextAreaWrapper = {
  width: '100%',
  backgroundColor: '#ffffff !important',
};

export const styledCancelButton = {
  backgroundColor: '#ffffff',
  '&:hover, &:active, &:focus ': {
    backgroundColor: '#ffffff',
  },
};
