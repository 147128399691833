import { Box } from '@mui/material';
import { ClientProfilesContext } from '../../../../../pages/ClientDetails/context';
import React, { useContext } from 'react';
import CustomSelect from '../../../../../components/Select';
import { additionalSelectStyles } from '../styles';

export default function TeamSelector() {
  const {
    clientProfile,
    editingProfileDataLibrary,
    // isBlockedCaregiverListChanged,
    onSelectChange,
  } = useContext(ClientProfilesContext);
  return (
    <Box sx={{ maxWidth: '250px', minWidth: '250px' }}>
      <CustomSelect
        addStyles={additionalSelectStyles}
        // disabled={isBlockedCaregiverListChanged}
        id="team"
        isLoading={false}
        options={editingProfileDataLibrary?.team?.map((option) => option?.name)}
        placeHolder="Team"
        selectedValue={clientProfile?.team?.name || ''}
        setValue={onSelectChange}
      />
    </Box>
  );
}
