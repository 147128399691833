import { Box, Chip, Typography } from '@mui/material';
import moment from 'moment/moment';
import { instanceOf } from 'prop-types';
import React from 'react';
import {
  chipEndStyle,
  chipStartStyle,
  durationStyle,
} from '../../../../components/CalendarsEventComponent/styles';

export default function Duration({ event = {} }) {
  return (
    <Box className="timeContent">
      <Chip label={moment(event.start).format('h:mm A')} sx={chipStartStyle} />
      <Chip label={moment(event.end).format('h:mm A')} sx={chipEndStyle} />
      <Box sx={durationStyle}>
        <Typography
          sx={{
            color: '#2C2D2E',
            fontSize: '12px',
            lineHeight: '16px',
          }}
        >
          Duration {event.duration} min
        </Typography>
      </Box>
    </Box>
  );
}

Duration.propTypes = {
  event: instanceOf(Object),
};
