import { DialogContent } from '@mui/material';
import { node } from 'prop-types';
import React from 'react';

export default function ContentWrapper({ children = <div /> }) {
  return (
    <DialogContent
      sx={{
        width: '520px',
        padding: '0 28px 28px 28px',
      }}
    >
      {children}
    </DialogContent>
  );
}

ContentWrapper.propTypes = {
  children: node,
};
