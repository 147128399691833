import { Box } from '@mui/material';
import { useGetCareProgramTableQuery } from '../../../../../../api/CareProgram/api';
import moment from 'moment';
import CareProgramCalendar from '../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramCalendar';
import { string } from 'prop-types';
import React, { useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import AlertContext from '../../../../../../components/Alert';
import './index.css';

export default function CareProgramCalendarView({ careProgramVersion = '' }) {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const { data, error } = useGetCareProgramTableQuery(id);

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);
  const eventWrapperRef = useRef();
  const currentVisits = data?.careProgram?.filter((date) =>
    moment(date.effectiveDateStart).isBefore(moment()),
  );

  return (
    <Box
      sx={{ height: 'calc(100vh - 300px)', overflow: 'auto' }}
      ref={eventWrapperRef}
    >
      <CareProgramCalendar
        careProgramVisits={
          careProgramVersion === 'current' ? currentVisits : data?.careProgram
        }
      />
    </Box>
  );
}

CareProgramCalendarView.propTypes = {
  careProgramVersion: string,
};
