import { Box, Typography } from '@mui/material';
import RecurrencePattern from '../../../../pages/ClientDetails/views/CarePlan/components/RecurrencePattern';
import TableTaskCell from '../../../../pages/ClientDetails/views/CarePlan/components/TableTaskCell';
import TaskActions from '../../../../pages/ClientDetails/views/CarePlan/components/TaskActions';
import { visitTypeCellWrapper } from '../../../../pages/ClientDetails/views/CarePlan/components/styles';
import React from 'react';
import CarePlanBulletList from './components/CarePlanHistory/CarePlanBulletList';

export const INITIAL_CARE_PLAN_FILTERS = {
  task: '',
  levelOfAssistance: [],
  category: [],
  days: [],
  timeOfDay: [],
  visitType: [],
};
export const PAGE_SIZE = 10;

export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

export const viewTypeButtons = [
  { name: 'Current', infoType: 'current' },
  { name: 'History', infoType: 'history' },
];

export const sortingOptions = [
  { id: 'name', name: 'Task' },
  { id: 'category', name: 'Category' },
  { id: 'levelsOfAssistance', name: 'Level of Assistance' },
  { id: 'timesOfDay', name: 'Time of Day' },
  { id: 'visitTypes', name: 'Visit Type' },
];

export const columns = [
  {
    field: 'name',
    headerName: 'Task',
    minWidth: 300,
    maxWidth: 360,
    flex: 1,
    renderCell: ({ row }) => <TableTaskCell row={row} />,
  },
  {
    field: 'details',
    headerName: 'Details',
    minWidth: 400,
    flex: 1,
    sortable: false,
  },
  {
    field: '',
    headerName: 'Recurrence Pattern',
    width: 370,
    minWidth: 320,
    sortable: false,
    renderCell: ({ id, row }) => (
      <Box sx={visitTypeCellWrapper}>
        <RecurrencePattern row={row} />
        <TaskActions rowId={id} />
      </Box>
    ),
  },
];

export const historyColumns = [
  {
    field: 'dateTime',
    headerName: 'Date & Time',
    sortable: false,
    minWidth: 280,
    renderCell: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{row.dateCreated}</Typography>
      </Box>
    ),
  },
  {
    field: 'changes',
    headerName: 'Changes',
    minWidth: 740,
    sortable: false,
    renderCell: ({ value }) => <CarePlanBulletList changes={value} />,
  },
  {
    field: 'user',
    headerName: 'User',
    sortable: false,
    width: 330,
    renderCell: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{row.author}</Typography>
      </Box>
    ),
  },
];
