import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { func, instanceOf, number, string } from 'prop-types';
import React from 'react';
import { customLabel, customSelectItem, customSelectStyles } from './styles';

export default function PaginationItemsPerPageSelect({
  itemsPerPage = 0,
  itemsPerPageLabel = 'Records per page',
  options = [0],
  setItemsPerPage = () => null,
}) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography sx={{ ...customLabel }} component="span">
        {itemsPerPageLabel}:
      </Typography>
      <Select
        IconComponent={ExpandMoreIcon}
        onChange={setItemsPerPage}
        sx={{ ...customSelectStyles }}
        value={itemsPerPage}
      >
        {options?.map((option) => (
          <MenuItem key={option} value={option} sx={{ ...customSelectItem }}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}

PaginationItemsPerPageSelect.propTypes = {
  itemsPerPage: number,
  itemsPerPageLabel: string,
  options: instanceOf(Array),
  setItemsPerPage: func,
};
