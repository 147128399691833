import { Box, Typography } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetTerritoryQuery } from '../../api/Administration/api';
import { useGetClientsListQuery } from '../../api/Clients/api';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { SEARCH_INPUT_DEBOUNCE_DELAY } from '../../shared/constants';
import { useDebouncedCallback } from '../../shared/hooks/useDebounce';
import { getNoData } from '../../shared/utils/common';
import AlertContext from '../../components/Alert';
import CustomTable from '../../components/Table';
import Filters from './components/Filters';
import {
  CLIENT_LOCAL_STORAGE_KEYS,
  ClientsFilters,
  DEFAULT_SORT_MODAL,
  INITIAL_CLIENTS_FILTERS,
  INITIAL_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  columns,
  getLocalStorageValues,
} from './constants';
import { customTableRowStyles } from './styles';
import { filterClient } from './utils';

export default function ClientsView() {
  const apiRef = useGridApiRef();
  const { setAlert } = useContext(AlertContext);
  const [clientsFilters, setClientsFilters] = useState(INITIAL_CLIENTS_FILTERS);
  const [paginationModel, setPaginationModel] = useState(() => {
    const storedPageSize = localStorage.getItem(
      CLIENT_LOCAL_STORAGE_KEYS.clientPageSize,
    );
    return storedPageSize ? JSON.parse(storedPageSize) : INITIAL_PAGE_SIZE;
  });
  const [sortModel, setSortModel] = useState(DEFAULT_SORT_MODAL);
  const {
    data: locations,
    error: locationsError,
    isLoading: isLocationsLoading,
  } = useGetTerritoryQuery();
  const [location, setLocation] = useState([]);

  useEffect(() => {
    if (Array.isArray(locations)) {
      setLocation(locations);
    }
  }, [locations]);
  const locationsList = useMemo(() => [...location], [location]);

  const {
    data: clientData,
    error: getClientsListError,
    isLoading,
    status,
  } = useGetClientsListQuery({ refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (locationsError || getClientsListError) {
      const errorData = locationsError || getClientsListError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [locationsError, getClientsListError, setAlert]);

  const navigate = useNavigate();

  useEffect(() => {
    if (Array.isArray(locations)) {
      setLocation(locations);
    }
  }, [locations]);

  useEffect(() => {
    setSortModel(DEFAULT_SORT_MODAL);
  }, [clientsFilters]);

  const onRowClick = (data) => {
    navigate(`${data.id}`);
  };

  const debouncedSearch = useDebouncedCallback((e) => {
    if (!e.target.value.length || e.target.value.length > 2) {
      setClientsFilters({ ...clientsFilters, [e.target.name]: e.target.value });
    }
    // if (e.target.name === 'phoneNumber') {
    //   localStorage.setItem(
    //     CLIENT_LOCAL_STORAGE_KEYS.clientPhoneNumber,
    //     JSON.stringify(e.target.value),
    //   );
    // }

    if (e.target.name === 'clientName') {
      localStorage.setItem(
        CLIENT_LOCAL_STORAGE_KEYS.clientSearch,
        JSON.stringify(e.target.value),
      );
    }
  }, SEARCH_INPUT_DEBOUNCE_DELAY);

  const changeTeamsFilters = useCallback((value) => {
    localStorage.setItem(
      CLIENT_LOCAL_STORAGE_KEYS.clientTeamsFilter,
      JSON.stringify(value),
    );
  }, []);

  const changeStatusFilters = useCallback(({ field, value }) => {
    setClientsFilters((prevState) => ({ ...prevState, [field]: value }));
    localStorage.setItem(
      CLIENT_LOCAL_STORAGE_KEYS.clientStatus,
      JSON.stringify(value),
    );
  }, []);

  const changePageSize = useCallback((model) => {
    localStorage.setItem(
      CLIENT_LOCAL_STORAGE_KEYS.clientPageSize,
      JSON.stringify(model),
    );
    setPaginationModel(model);
  }, []);

  useEffect(() => {
    getLocalStorageValues({
      locationsList,
      setClientsFilters,
      setPaginationModel,
    });
  }, [locationsList]);

  const updateSorting = useCallback((data) => setSortModel(data[0]), []);
  const isFetching = status === 'pending';
  const filteredClients = useMemo(
    () => filterClient(clientsFilters, clientData?.clientList || []),
    [clientsFilters, clientData],
  );

  const memoizedProviderValue = useMemo(
    () => ({
      changeStatusFilters,
      changeTeamsFilters,
      clientsFilters,
      debouncedSearch,
      isLoading,
      isFetching,
      locationsList,
      setClientsFilters,
      isLocationsLoading,
    }),
    [
      changeStatusFilters,
      changeTeamsFilters,
      clientsFilters,
      debouncedSearch,
      isLoading,
      isFetching,
      locationsList,
      setClientsFilters,
      isLocationsLoading,
    ],
  );

  return (
    <ClientsFilters.Provider value={memoizedProviderValue}>
      <Box sx={{ width: '100%', maxHeight: 'calc(100vh - 180px)' }}>
        <Typography variant="h1" sx={{ mb: '24px' }}>
          Clients
        </Typography>
        <Filters />
        <CustomTable
          apiRef={apiRef}
          columns={columns}
          customPaginationModel={paginationModel}
          customStyles={customTableRowStyles}
          getRowClassName={(params) => (!params.row.team ? 'has-no-team' : '')}
          headerHeight={44}
          isLoading={isLoading || isFetching || isLocationsLoading}
          noRowsOverlay={() =>
            getNoData({ title: 'Have no clients by this request data' })
          }
          onRowClick={onRowClick}
          page={paginationModel.page}
          pageSize={paginationModel.pageSize}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          rowHeight={40}
          rows={filteredClients}
          setSortModelState={updateSorting}
          setCustomPaginationModel={changePageSize}
          sortModel={[sortModel]}
          withSavedPagination
        />
      </Box>
    </ClientsFilters.Provider>
  );
}
