import { Box, Typography } from '@mui/material';
import { EditCareProgramContext } from '../../../../../../../../../../pages/ClientDetails/context';
import CrossedOutCell from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/CrossedOutCell';
import { getEffectiveDateBadge } from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/constants';
import { instanceOf } from 'prop-types';
import React, { useContext } from 'react';
import { COLORS } from '../../../../../../../../../../shared/styles/Theme';
import {
  durationAndEffectiveDates,
  previewEffectiveDatesStatusStyles,
} from './styles';

export default function DurationAndEffectiveDate({ row = {} }) {
  const { updatedCareProgram } = useContext(EditCareProgramContext);
  const parentVisit = updatedCareProgram.find(
    ({ visitId }) => row?.parentId && visitId === row?.parentId,
  );
  const durationHasBeenChanged =
    parentVisit && parentVisit?.duration !== row?.duration;
  const effectiveDateView = () => {
    if (row.changesType === 'changedStartingDate') {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <CrossedOutCell>
            <Typography variant="h5" sx={{ width: '100%' }}>
              {getEffectiveDateBadge({
                dateStart: row?.prevEffectiveDateStart,
              })}
            </Typography>
          </CrossedOutCell>
          <Typography variant="h5" sx={{ width: '100%' }}>
            {getEffectiveDateBadge({
              dateEnd: row?.effectiveDateEnd,
              dateStart: row?.effectiveDateStart,
            })}
          </Typography>
        </Box>
      );
    }
    if (row.parentId) {
      return (
        <Box sx={durationAndEffectiveDates}>
          <CrossedOutCell>
            <Typography variant="h5" sx={{ width: '100%' }}>
              {getEffectiveDateBadge({
                dateStart: parentVisit?.effectiveDateStart,
              })}
            </Typography>
          </CrossedOutCell>
          <Typography variant="h5" sx={{ width: '100%', padding: '0 4px' }}>
            {getEffectiveDateBadge({
              dateStart: row?.effectiveDateStart,
            })}
          </Typography>
        </Box>
      );
    }
    return (
      <Typography variant="h5" sx={{ width: '100%' }}>
        {getEffectiveDateBadge({
          dateEnd: row?.effectiveDateEnd,
          dateStart: row?.effectiveDateStart,
        })}
      </Typography>
    );
  };
  return (
    <Box sx={{ display: 'flex', alignItems: 'start' }}>
      <Box sx={previewEffectiveDatesStatusStyles}>{effectiveDateView()}</Box>
      <Box
        sx={{
          width: '2px',
          height: '2px',
          borderRadius: '50%',
          margin: '10px 10px 0 10px',
          backgroundColor: COLORS.blue[500],
        }}
      />
      <Box sx={durationAndEffectiveDates}>
        {durationHasBeenChanged && (
          <CrossedOutCell>
            <Typography component="span" sx={{ width: 'max-content' }}>
              {parentVisit?.duration !== null
                ? `${parentVisit?.duration} min`
                : '0 min'}
            </Typography>
          </CrossedOutCell>
        )}
        <Typography
          sx={{
            width: 'max-content',
            fontWeight: durationHasBeenChanged ? 600 : 400,
          }}
        >
          {row?.duration !== null ? `${row?.duration} min` : '0 min'}
        </Typography>
      </Box>
    </Box>
  );
}

DurationAndEffectiveDate.propTypes = {
  row: instanceOf(Object),
};
