import { Box, Typography } from '@mui/material';
import { useCheckOneTimeVisitAvailabilityQuery } from '../../../../../../../../api/Visits/api';
import moment from 'moment';
import AvailabilityLoader from '../../../../../../../../pages/ClientDetails/components/AvailabilityLoader';
import { func, instanceOf } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ISO_DATE_ONLY_FORMAT } from '../../../../../../../../shared/constants';
import { COLORS } from '../../../../../../../../shared/styles/Theme';
import AlertContext from '../../../../../../../../components/Alert';
import CustomDialog from '../../../../../../../../components/Dialog';
import AvailabilityColumns from './AvailabilityColumns';

function CheckAvailability({
  availabilityScheduling = {},
  completedSteps = [],
  setAvailabilityScheduling = () => {},
  setStepDataLoading = () => {},
  setVisitParams = () => {},
  visitParams = {},
}) {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [deletedVisitData, setDeletedVisitData] = useState(null);
  const [expanded, setExpanded] = useState([]);
  const {
    data: oneTimeVisitAvailabilities,
    isLoading,
    isFetching,
    error,
  } = useCheckOneTimeVisitAvailabilityQuery(
    {
      clientId: id,
      payload: {
        ...visitParams,
        dates: visitParams?.dates?.map((date) =>
          moment(date).format(ISO_DATE_ONLY_FORMAT),
        ),
        genderPreference: visitParams.genderPreference || 'None',
      },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: completedSteps.includes(1),
    },
  );

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);
  useEffect(() => {
    if (oneTimeVisitAvailabilities) {
      setAvailabilityScheduling(oneTimeVisitAvailabilities);
    }
  }, [oneTimeVisitAvailabilities, setAvailabilityScheduling]);
  useEffect(() => {
    const isDataLoading = isFetching || isLoading;
    setStepDataLoading(isDataLoading);
  }, [isFetching, isLoading, setStepDataLoading]);

  const confirmDeleting = () => {
    const { dataKey, date } = deletedVisitData;
    const newSchedule = availabilityScheduling[dataKey].filter(
      (item) => item.date !== date,
    );
    setAvailabilityScheduling({
      ...availabilityScheduling,
      [dataKey]: newSchedule,
    });
    const updateVisitDates = [...visitParams.dates].filter(
      (item) => item !== date,
    );
    setVisitParams({ ...visitParams, dates: updateVisitDates });
    setExpanded(expanded.filter((item) => item !== date));
    setDeletedVisitData(null);
    setOpenConfirmationModal(false);
  };

  const deleteVisitFromSchedule = (date) => {
    setOpenConfirmationModal(true);
    setDeletedVisitData(date);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => {
          setDeletedVisitData(null);
          setOpenConfirmationModal(false);
        }}
        submitButtonName="confirm"
        submitCallback={confirmDeleting}
        title="Confirmation"
        openDialog={openConfirmationModal}
      >
        <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            The day of visit will be removed. Do you want to proceed?
          </Typography>
        </Box>
      </CustomDialog>
      {isLoading || isFetching ? (
        <AvailabilityLoader />
      ) : (
        <AvailabilityColumns
          availabilityData={availabilityScheduling}
          deleteVisitFromSchedule={deleteVisitFromSchedule}
          expanded={expanded}
          setAvailabilityScheduling={setAvailabilityScheduling}
          setExpanded={setExpanded}
          setVisitParams={setVisitParams}
        />
      )}
    </Box>
  );
}

CheckAvailability.propTypes = {
  availabilityScheduling: instanceOf(Object),
  completedSteps: instanceOf(Array),
  setAvailabilityScheduling: func,
  setStepDataLoading: func,
  setVisitParams: func,
  visitParams: instanceOf(Object),
};

export default React.memo(CheckAvailability);
