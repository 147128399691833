import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import {
  SHORT_DATE_FORMAT,
  TIME_OFF_REQUEST_DATE_FORMAT,
  TIME_OFF_REQUEST_LAST_UPDATED_DATE_FORMAT,
} from '../../../../shared/constants';
import { convertUtcToTeamTimeZoneMomentObject } from '../../../../shared/utils/common';
import Badge from '../../../../components/Badge';
import RequestsActions from './components/RequestsActions';
import { badgeAndActionsCellStyles, lastUpdateCellWrapper } from './styles';
import { getBadgeColorScheme } from './utils';

export const timeOffRequestTypes = {
  settingParams: 'settingParams',
  checkPossibility: 'checkPossibility',
};

export const viewTypeButtons = [
  { name: 'Current', infoType: 'current' },
  { name: 'History', infoType: 'history' },
];

export const columns = [
  {
    field: 'requestType',
    headerName: 'Request Type',
    minWidth: 150,
    flex: 1,
    sortable: false,
  },
  {
    field: 'details',
    headerName: 'Details',
    minWidth: 240,
    sortable: false,
  },
  {
    field: 'createdDate',
    headerName: 'Date Submitted',
    minWidth: 170,
    flex: 1,
    renderCell: ({ value }) =>
      value
        ? convertUtcToTeamTimeZoneMomentObject(value).format(SHORT_DATE_FORMAT)
        : '-',
    sortable: false,
  },
  {
    field: 'requestDateStart',
    headerName: 'Start Date & Time',
    minWidth: 130,
    flex: 1,
    renderCell: ({ value }) => value.format(TIME_OFF_REQUEST_DATE_FORMAT),
    sortable: false,
  },
  {
    field: 'requestDateEnd',
    headerName: 'End Date & Time',
    minWidth: 130,
    flex: 1,
    renderCell: ({ value }) => value.format(TIME_OFF_REQUEST_DATE_FORMAT),
    sortable: false,
  },
  {
    field: 'lastUpdated',
    headerName: 'Last Updated',
    minWidth: 490,
    flex: 1,
    renderCell: ({ row }) => (
      <Box sx={lastUpdateCellWrapper}>
        {row?.lastUpdatedBy ? (
          <Stack sx={{ width: '185px' }}>
            <Typography>{`${row?.lastUpdatedBy.firstName} ${row?.lastUpdatedBy.lastName}`}</Typography>
            <Typography>
              {row?.modifiedDate
                ? convertUtcToTeamTimeZoneMomentObject(row.modifiedDate).format(
                    TIME_OFF_REQUEST_LAST_UPDATED_DATE_FORMAT,
                  )
                : ''}
            </Typography>
          </Stack>
        ) : (
          '-'
        )}
        <Box sx={badgeAndActionsCellStyles}>
          <Box
            sx={{ display: 'flex', justifyContent: 'center', width: '86px' }}
          >
            <Badge
              colorScheme={getBadgeColorScheme(row?.status)}
              label={row?.status}
            />
          </Box>
          <Box
            sx={{ display: 'flex', justifyContent: 'center', width: '110px' }}
          >
            <RequestsActions row={row} />
          </Box>
        </Box>
      </Box>
    ),
    sortable: false,
  },
];
