import { Box, Checkbox, Typography } from '@mui/material';
import { useVerifyVisitMutation } from '../../../../../../api/Scheduler/api';
import LinkVerificationIcon from '../../../../../../img/icons/link-verification.svg';
import {
  VerificationModeVisitNotesContext,
  VerificationTasksContext,
} from '../../../../../../pages/Scheduling/context';
import { instanceOf } from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TASKS_TYPES } from '../../../../../../shared/constants';
import { COLORS } from '../../../../../../shared/styles/Theme';
import AlertContext from '../../../../../../components/Alert';
import Loader from '../../../../../../components/Loader';
import { parseDataToUpdatesTasksForVerification } from '../../utils';
import VisitNotes from './VisitNotes/VisitNotes';
import VisitTasksManagement from './VisitTasksManagement';
import { showTasksLinkStyles } from './styles';

const visitNotesFields = [
  {
    key: 'visitDetails',
    title: 'Additional Visit Details',
    updatedFieldKey: 'visitDetails',
    fieldKey: 'visitNotes',
    oldValueKey: 'visitNotes',
  },
  {
    key: 'visitNotes',
    title: 'Caregiver Visit Notes',
    updatedFieldKey: 'visitNotes',
    fieldKey: 'visitDetails',
    oldValueKey: 'visitDetails',
  },
];

function VisitRow({ row = {} }) {
  const { setAlert } = useContext(AlertContext);
  const [temporaryVisitData, setTemporaryVisitData] = useState(false);
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const [showedTasksType, setShowedTasksType] = useState(
    TASKS_TYPES.visitTasks,
  );
  const {
    cancelVisitNotes,
    changePreVisitNotes,
    onSelectVisit,
    preVisitNotes,
    selectionModel,
    submitVisitNote,
  } = useContext(VerificationModeVisitNotesContext);

  const [verifyVisits, { error: verifyVisitError, isLoading }] =
    useVerifyVisitMutation();

  useEffect(() => {
    if (verifyVisitError) {
      setAlert({
        errorData: verifyVisitError,
        type: 'error',
      });
    }
  }, [verifyVisitError, setAlert]);

  const parsedRowId = `${row.id}1`;

  const changeNotes = ({ value, updatedField, field, oldValue }) => {
    const updatedNotes = {
      [field]: oldValue,
      [updatedField]: value,
    };
    changePreVisitNotes(updatedNotes, row.id);
  };

  const showVisitTasks = useCallback(() => {
    setTemporaryVisitData(row.fullVisitData);
    setOpenTaskDialog(true);
  }, [row]);

  const updateTemporaryVisitData = useCallback(
    (value) => {
      setTemporaryVisitData((prevState) => ({ ...prevState, ...value }));
    },
    [setTemporaryVisitData],
  );

  const onCloseTasksDialog = useCallback(() => {
    setOpenTaskDialog(false);
    setShowedTasksType(TASKS_TYPES.visitTasks);
  }, []);

  const updateTasks = () => {
    verifyVisits(parseDataToUpdatesTasksForVerification(temporaryVisitData));
    setOpenTaskDialog(false);
    setShowedTasksType(TASKS_TYPES.visitTasks);
  };

  const memoizedProviderValue = useMemo(
    () => ({
      temporaryVisitData,
      updateTemporaryVisitData,
    }),
    [temporaryVisitData, updateTemporaryVisitData],
  );

  const showedTasks = useMemo(
    () => (temporaryVisitData ? temporaryVisitData[showedTasksType] : []),
    [temporaryVisitData, showedTasksType],
  );

  return (
    <VerificationTasksContext.Provider value={memoizedProviderValue}>
      <Box sx={{ display: 'flex', flex: '1 0 0', pb: '16px' }}>
        {isLoading && <Loader />}
        {row.type !== 'visitNote' ? (
          <Checkbox
            checked={selectionModel?.includes(row?.id)}
            onChange={() => onSelectVisit(row.id)}
          />
        ) : (
          <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', width: '100%' }}>
              {visitNotesFields.map(
                ({ key, title, updatedFieldKey, fieldKey, oldValueKey }) => (
                  <VisitNotes
                    key={key}
                    cancelCallback={() => cancelVisitNotes(row?.id)}
                    changeDetails={(e) =>
                      changeNotes({
                        value: e.target?.value,
                        updatedField: updatedFieldKey,
                        field: fieldKey,
                        oldValue: preVisitNotes?.[row.id]?.[oldValueKey],
                      })
                    }
                    initialVisitNotes={
                      preVisitNotes?.[parsedRowId]?.[key] || row?.[key]
                    }
                    label={title}
                    maxLength={4000}
                    submitCallback={() => submitVisitNote(row?.id, key)}
                    textFieldId={row?.id}
                    textFieldValue={
                      preVisitNotes?.[parsedRowId]?.[key] || row?.[key] || ''
                    }
                  />
                ),
              )}
            </Box>
            {row?.careProgramTasks?.length > 0 ? (
              <Box sx={showTasksLinkStyles} onClick={showVisitTasks}>
                <img src={LinkVerificationIcon} alt="" />
                <Typography variant="body1" sx={{ color: COLORS.blue[700] }}>
                  View {row?.careProgramTasks?.length} Tasks
                </Typography>
              </Box>
            ) : (
              <Typography sx={{ marginLeft: '20px' }}>No Tasks </Typography>
            )}
            <VisitTasksManagement
              closeTasksDialog={onCloseTasksDialog}
              openTaskDialog={openTaskDialog}
              setShowedTasksType={setShowedTasksType}
              tasks={showedTasks}
              showedTasksType={showedTasksType}
              updateTasks={updateTasks}
            />
          </Box>
        )}
      </Box>
    </VerificationTasksContext.Provider>
  );
}

VisitRow.propTypes = {
  row: instanceOf(Object),
};

export default React.memo(VisitRow);
