import { Button } from '@mui/material';
import { func, instanceOf, string } from 'prop-types';
import React from 'react';
import { textButtonStyles } from './styles';

export default function BreadcrumbsButton({
  additionalStyles = {},
  name = '',
  onLinkClick = () => {},
}) {
  return (
    <Button
      variant="text"
      sx={{
        ...textButtonStyles,
        ...additionalStyles,
      }}
      disableFocusRipple
      disableRipple
      onClick={onLinkClick}
    >
      {name}
    </Button>
  );
}

BreadcrumbsButton.propTypes = {
  additionalStyles: instanceOf(Object),
  name: string,
  onLinkClick: func,
};
