import { Alert, AlertTitle } from '@mui/material';
import warningIcon from '../../../img/icons/warning-icon-shcedule.svg';
import { string } from 'prop-types';
import React from 'react';
import { backgroundColorSchemeMap } from '../../../shared/constants';

export default function ActualTimeWarning({ title = '' }) {
  return (
    <Alert
      icon={<img src={warningIcon} alt="warning icon" />}
      severity="warning"
      sx={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'start',
        backgroundColor: backgroundColorSchemeMap.warning.backgroundColor,
        borderRadius: '10px',
        mb: '24px',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '16px',
        '& .MuiAlert-icon': {
          display: 'flex',
          alignItems: 'center',
          height: '42px',
        },
      }}
    >
      <AlertTitle sx={{ mt: '4px', mb: 0, fontWeight: 700 }}>
        {title}
      </AlertTitle>
    </Alert>
  );
}

ActualTimeWarning.propTypes = {
  title: string,
};
