import { Box } from '@mui/material';
import { instanceOf, number } from 'prop-types';
import React from 'react';
import CustomTooltip from '../../components/CustomTooltip';

function IconsCell({ iconsMap = {}, items = [], maxWidth = 0 }) {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        maxWidth: `${maxWidth}px`,
        height: '100%',
        gap: '16px',
        flexWrap: 'wrap',
      }}
    >
      {items?.map((item) => (
        <CustomTooltip key={item} title={item}>
          <Box
            component="img"
            src={iconsMap[item?.toLowerCase()]}
            sx={{ height: '20px' }}
            alt="img"
          />
        </CustomTooltip>
      ))}
    </Box>
  );
}

IconsCell.propTypes = {
  iconsMap: instanceOf(Object),
  items: instanceOf(Array),
  maxWidth: number,
};

export default React.memo(IconsCell);
