import { Box, Chip, Typography } from '@mui/material';
import moment from 'moment/moment';
import { instanceOf, number } from 'prop-types';
import React from 'react';
import {
  arrivalWindowLabelStyles,
  chipArrivalStartStyle,
  chipArrivalTimeEndStyle,
  chipArrivalWindowStyle,
} from '../../styles';
import { arrivalWindowBoxStyles } from './styles';

export default function CaregiverCalendarEventArrivalTime({
  arrivalEnd = {},
  arrivalStart = {},
  arrivalEndPosition = 0,
  arrivalWindowHeight = 0,
  arrivalWindowStartPosition = 0,
  event = {},
}) {
  return (
    <Box>
      <Chip
        label={moment(arrivalStart).format('h:mm A')}
        sx={{
          ...chipArrivalStartStyle,
          top: moment(event.start).isAfter(arrivalStart)
            ? `-${arrivalWindowStartPosition}px`
            : `${-arrivalWindowStartPosition}px`,
        }}
      />
      <Chip
        label={moment(arrivalEnd).format('h:mm A')}
        sx={{
          ...chipArrivalTimeEndStyle,
          top: `${arrivalEndPosition}px`,
        }}
      />
      <Box
        sx={{
          ...chipArrivalWindowStyle,
          top: `${-arrivalWindowStartPosition + 25}px`,
        }}
      >
        <Typography sx={arrivalWindowLabelStyles}>Arrival window</Typography>
      </Box>
      <Box
        sx={{
          ...arrivalWindowBoxStyles({
            arrivalStart,
            arrivalWindowHeight,
            arrivalWindowStartPosition,
            eventStart: event.start,
          }),
        }}
      />
    </Box>
  );
}

CaregiverCalendarEventArrivalTime.propTypes = {
  arrivalEnd: instanceOf(Object),
  arrivalStart: instanceOf(Object),
  arrivalEndPosition: number,
  arrivalWindowHeight: number,
  arrivalWindowStartPosition: number,
  event: instanceOf(Object),
};
