import moment from 'moment';
import { DATE_ONLY_STANDARD_FORMAT } from '../../../../../../shared/constants';
import { filterVisitsByEffectiveDate } from '../../../../../../shared/utils/common';
import { calendarDaysMap } from './constants';

export const parseVisits = (visits) =>
  filterVisitsByEffectiveDate(visits)
    ?.map((event) => {
      const { days, duration, visitType } = event;
      const currentDate = moment();
      const weekStart = currentDate
        .clone()
        .startOf('week')
        .format(DATE_ONLY_STANDARD_FORMAT);
      const schedule = [];
      days.forEach((visit) => {
        const arrivalDateStart = moment(`${weekStart} ${visit.arrivalStart}`)
          .add(calendarDaysMap[visit.day], 'days')
          .toDate();
        const arrivalDateEnd = moment(`${weekStart} ${visit.arrivalEnd}`)
          .add(calendarDaysMap[visit.day], 'days')
          .toDate();

        const eventEnd = moment(`${weekStart} ${visit.arrivalEnd}`)
          .add(duration, 'minutes')
          .add(calendarDaysMap[visit.day], 'days')
          .toDate();

        schedule.push({
          ...event,
          title: `${visitType.name} Visit`,
          type: visitType.name?.toLowerCase(),
          arrivalTimeStart: arrivalDateStart,
          start: arrivalDateEnd,
          end: eventEnd,
        });
      });
      return schedule;
    })
    .flat();

export const getMinHour = (parsedEventsData) => {
  const minHours = parsedEventsData
    ?.map((item) => item.arrivalTimeStart.getHours())
    ?.filter((hour) => hour);
  return Math.min(...minHours);
};
export const getMaxHour = (parsedEventsData) => {
  const maxHours = parsedEventsData
    ?.map((item) => item.end.getHours())
    ?.filter((hour) => hour);
  return Math.max(...maxHours);
};
