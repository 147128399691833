import moment from 'moment';
import { H12_TIME_FORMAT, ISO_DATE_ONLY_FORMAT } from '../../../../../../shared/constants';
import { v4 as uuidv4 } from 'uuid';
import { VISITS_CHANGES_TYPES, createVisitInitialData } from '../../constants';

/* Don't delete because logic can be useful */

export const checkIsPeriodsAreCollapsed = ({
  currentProgram,
  updatedCareProgram,
  startTime,
  endTime,
}) => {
  const comparedPrograms = updatedCareProgram.filter(
    (item) =>
      item.visitId !== currentProgram.visitId &&
      currentProgram.days.some((day) => item.days.indexOf(day) >= 0),
  );
  return comparedPrograms.some(({ arrivalEnd, arrivalStart, duration }) => {
    const convertedArrivalEnd = moment(arrivalEnd, H12_TIME_FORMAT).add(
      duration,
      'minutes',
    );
    const convertedArrivalStart = moment(arrivalStart, H12_TIME_FORMAT);
    return (
      startTime.isBetween(
        convertedArrivalStart,
        convertedArrivalEnd,
        'minutes',
        [],
      ) ||
      endTime.isBetween(
        convertedArrivalStart,
        convertedArrivalEnd,
        'minutes',
        [],
      ) ||
      convertedArrivalStart.isBetween(startTime, endTime, 'minutes', []) ||
      convertedArrivalEnd.isBetween(startTime, endTime, 'minutes', [])
    );
  });
};

export const checkIsDataHasBeenChanged = ({ initialData, updatedData }) => {
  const initialParams = initialData?.reduce(
    (
      string,
      {
        arrivalEnd,
        arrivalStart,
        duration,
        effectiveDateEnd,
        effectiveDateStart,
        visitId,
      },
    ) =>
      string +
      arrivalEnd +
      arrivalStart +
      duration +
      effectiveDateEnd +
      effectiveDateStart +
      visitId,
    '',
  );
  const updatedParams = updatedData?.reduce(
    (
      string,
      {
        arrivalEnd,
        arrivalStart,
        duration,
        effectiveDateEnd,
        effectiveDateStart,
        visitId,
      },
    ) =>
      string +
      arrivalEnd +
      arrivalStart +
      duration +
      effectiveDateEnd +
      effectiveDateStart +
      visitId,
    '',
  );
  return initialParams !== updatedParams;
};

export const checkIsCreateVisitStepConfirmButtonDisabled = (data) => {
  const { alternativeArrivalWindows, selectedTimeSlots, step, visitData } =
    data;

  if (step === 0) {
    const {
      arrivalWindowDuration,
      days,
      duration,
      repeat,
      timesOfDay,
      visitType,
    } = visitData || {};

    return (
      !repeat?.length ||
      !arrivalWindowDuration ||
      !timesOfDay?.length ||
      !days?.length ||
      !duration ||
      !visitType
    );
  }
  if (step === 1) {
    return (
      selectedTimeSlots === null ||
      Object.values(selectedTimeSlots)?.length !== visitData?.days?.length
    );
  }
  if (step === 2) {
    return (
      !alternativeArrivalWindows ||
      !!alternativeArrivalWindows?.unAvailableSchedule?.filter(
        (visit) => !visit.cancelled,
      ).length
    );
  }
  return false;
};

export const getInitialVisitData = (visit, effectiveDate) => {
  if (!visit) {
    return {
      ...createVisitInitialData,
      effectiveDateStart: effectiveDate
        ? moment(effectiveDate).format(ISO_DATE_ONLY_FORMAT)
        : null,
    };
  }

  const getMinutesRange = ({ arrivalStart, arrivalEnd }) => {
    const start = moment(arrivalStart, H12_TIME_FORMAT);
    const end = moment(arrivalEnd, H12_TIME_FORMAT);
    const duration = moment.duration(end.diff(start));
    return duration.as('minutes').toString();
  };
  return {
    arrivalWindowDuration: getMinutesRange(visit.days[0]),
    days: visit?.days?.map(({ day }) => day),
    duration: visit.duration,
    gender: visit.genderPreference,
    repeat: visit.repeat,
    skills: visit.skills,
    timesOfDay: visit.timesOfDay || [],
    ...(visit.temporaryVisitId
      ? { temporaryVisitId: visit.temporaryVisitId }
      : {}),
    visitType: visit.visitType,
    ...(visit.visitId ? { parentId: visit.visitId } : {}),
    ...(visit.parentId ? { parentId: visit.parentId } : {}),
    effectiveDateStart: effectiveDate
      ? moment(effectiveDate).format(ISO_DATE_ONLY_FORMAT)
      : null,
  };
};

export const getNewVisitsList = ({
  alternativeArrivalWindows,
  availableVisitTasks,
  updatedCareProgram,
  visitData,
}) => {
  const temporaryVisitId = visitData.temporaryVisitId || uuidv4();
  const newVisitData = {
    alternativeVisitDetails:
      alternativeArrivalWindows?.availableSchedule &&
      alternativeArrivalWindows?.unAvailableSchedule
        ? [
            ...alternativeArrivalWindows.availableSchedule.filter(
              ({ reselectedUnavailable }) => reselectedUnavailable,
            ),
            ...alternativeArrivalWindows.unAvailableSchedule,
          ]
        : [],
    days: availableVisitTasks?.map((task) => ({
      ...task,
    })),
    duration: visitData.duration,
    effectiveDateStart: visitData.effectiveDateStart,
    genderPreference: visitData.genderPreference,
    preVisitNotes: visitData.preVisitNotes,
    repeat: visitData.repeat,
    preferredSkills: visitData.preferredSkills,
    preferredLanguages: visitData.preferredLanguages,
    temporaryVisitId,
    timesOfDay: visitData.timesOfDay,
    visitType: visitData.visitType,
    ...(visitData.parentId ? { parentId: visitData.parentId } : {}),
  };
  const careProgramVisits = [...updatedCareProgram];
  if (visitData.temporaryVisitId) {
    const editedVisitIndex = updatedCareProgram.findIndex(
      (careProgramVisit) =>
        careProgramVisit.temporaryVisitId === visitData.temporaryVisitId,
    );
    careProgramVisits[editedVisitIndex] = newVisitData;
    return careProgramVisits;
  }
  if (visitData.parentId) {
    const editedVisitIndex = updatedCareProgram.findIndex(
      ({ visitId }) => visitId === visitData.parentId,
    );
    const newEditedVisitData = {
      ...updatedCareProgram[editedVisitIndex],
      changesType: VISITS_CHANGES_TYPES.edit,
      childId: temporaryVisitId,
      effectiveDateEnd: visitData.effectiveDateStart,
    };
    careProgramVisits[editedVisitIndex] = [newEditedVisitData, newVisitData];
    return careProgramVisits.flat();
  }
  careProgramVisits.push(newVisitData);
  return careProgramVisits;
};
