import { COLORS } from '../../shared/styles/Theme';

export const holidayDateWrapper = {
  display: 'flex',
  alignItems: 'center',
  mt: '2px',
};

export const showDetailsStyles = {
  width: '24px',
  height: '12px',
  p: 0,
  borderRadius: 0,
  position: 'absolute',
  left: '94%',
  zIndex: 101,
  transition: 'all 0.5s ease-out',
};

export const hideHeader = {
  position: 'absolute',
  top: '63px',
  background: '#fff',
  width: '70px',
  zIndex: 101,
};
export const hideContent = {
  position: 'absolute',
  top: '157px',
  background: '#fff',
  height: '-webkit-fill-available',
  width: '34px',
  zIndex: 101,
};
export const hideEditedHeader = {
  position: 'absolute',
  top: '75px',
  background: 'white',
  width: '70px',
  zIndex: 101,
};
export const hideEditedContent = {
  position: 'absolute',
  top: '173px',
  background: 'white',
  height: '-webkit-fill-available',
  width: '34px',
  zIndex: 101,
};
export const editSchedulingShowDetailsStyles = {
  width: '24px',
  height: '12px',
  p: 0,
  borderRadius: 0,
  position: 'absolute',
  right: '42px',
  zIndex: 101,
};
export const showAlertOnDrag = {
  position: 'absolute',
  top: '90px',
  left: '36%',
  zIndex: 10000,
};
export const teamsTooltipStyles = {
  '&.MuiTooltip-tooltipPlacementRight': {
    mt: '5px !important',
    mr: '25px !important',
  },
  padding: '10px',
  paddingLeft: '6px',
  backgroundColor: '#ffffff',
  boxShadow: '0px 0px 12px rgba(44, 45, 46, 0.16)',
  color: COLORS.blue[900],
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '20px',
  borderRadius: '10px',
};
