import { Box } from '@mui/material';
import { getEventBoxClass } from '../../../../pages/CaregiverDetails/view/Calendar/utils';
import { func, instanceOf } from 'prop-types';
import React, { useCallback, useContext, useRef } from 'react';
import { useParams } from 'react-router';
import { eventsByCalendarTypes } from '../../../../shared/constants';
import { VisitInfoTypeContext } from '../../../../shared/context';
import { isWeekend, updateRealEvents } from '../../../../shared/utils/common';
import DefaultFiveDaysCalendar from '../../../../components/Calendar';
import CustomToolbar from '../../../../components/Calendar/CustomToolbar';
import SchedulingEventComponent from '../../../../components/CalendarsEventComponent';
import SevenDaysCalendar from '../../../../components/SevenDaysCalendar';
import SevenDaysCalendarCustomToolbar from '../../../../components/SevenDaysCalendar/SevenDaysCustomToolbar';
import {
  findMaximumEndAvailability,
  findMinimumStartAvailability,
} from './constants';

export default function CaregiverCalendarView({
  availabilities = [],
  calendarDate = null,
  calendarHeader = () => {},
  calendarEvents = [],
  dayPropGetter = {},
  setCalendarDate = () => {},
  slotPropGetter = {},
}) {
  const { id } = useParams();
  const eventWrapperRef = useRef();
  const { onOpenVisitInfoTypeDialog } = useContext(VisitInfoTypeContext);
  const parsedEvents = updateRealEvents(calendarEvents, id);
  const weekendEvents = parsedEvents?.some((event) => isWeekend(event.start));

  const onSelectEvent = (eventData) => {
    if (id !== eventData?.shadowCaregiver?.id) {
      onOpenVisitInfoTypeDialog({
        visitId: eventData?.id,
        clientId: eventData?.client?.id,
        date: eventData?.start,
      });
    }
  };
  const minStartAvailability = findMinimumStartAvailability(
    availabilities?.map((t) => t.availabilities),
    parsedEvents,
  );
  const maxEndAvailability = findMaximumEndAvailability(
    availabilities?.map((t) => t.availabilities),
    parsedEvents,
  );
  const customEvent = useCallback(
    ({ event }) => (
      <SchedulingEventComponent
        event={event}
        eventType={eventsByCalendarTypes.caregiverCalendar}
      />
    ),
    [],
  );
  return (
    <Box sx={{ height: 'calc(100vh - 200px)' }} ref={eventWrapperRef}>
      {!weekendEvents ? (
        <DefaultFiveDaysCalendar
          customToolbar={CustomToolbar}
          date={calendarDate}
          dateHeaderWrapper={calendarHeader}
          dayPropGetter={dayPropGetter}
          eventComponent={customEvent}
          eventStyleGetter={getEventBoxClass}
          events={parsedEvents}
          firstVisit={minStartAvailability - 1}
          lastVisit={maxEndAvailability}
          onNavigate={(date) => setCalendarDate(date)}
          onSelectEvent={onSelectEvent}
          slotPropGetter={slotPropGetter}
          toolbar
        />
      ) : (
        <SevenDaysCalendar
          className="client-seven-days-calendar"
          customToolbar={SevenDaysCalendarCustomToolbar}
          date={calendarDate}
          dateHeaderWrapper={calendarHeader}
          dayPropGetter={dayPropGetter}
          eventComponent={customEvent}
          eventStyleGetter={getEventBoxClass}
          events={parsedEvents}
          firstVisit={minStartAvailability - 1}
          lastVisit={maxEndAvailability}
          onNavigate={(date) => setCalendarDate(date)}
          onSelectEvent={onSelectEvent}
          slotPropGetter={slotPropGetter}
          toolbar
        />
      )}
    </Box>
  );
}

CaregiverCalendarView.propTypes = {
  availabilities: instanceOf(Array),
  calendarDate: instanceOf(Date),
  calendarHeader: func,
  calendarEvents: instanceOf(Array),
  dayPropGetter: instanceOf(Object),
  setCalendarDate: func,
  slotPropGetter: instanceOf(Object),
};
