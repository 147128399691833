import { Box } from '@mui/material';
import { CaregiverProfileContext } from '../../../../../pages/CaregiverDetails/context';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { checkEqualArrays } from '../../../../../shared/utils/common';
import TeamEffectiveDateModal from '../TeamEffectiveDateModal';
import TeamsSelect from '../TeamsSelect';

export default function SecondaryRegionsSelector() {
  const {
    caregiverProfile,
    caregiverProfileBeforeEdit,
    editingProfileDataLibrary,
    id,
    initializeCaregiverProfile,
    profileData,
    setCaregiverProfile,
    setTeamDate,
    setTeamEffectiveDateOpen,
    teamDate,
    teamEffectiveDateOpen,
  } = useContext(CaregiverProfileContext);
  const [optionIsOpen, setOptionIsOpen] = useState(false);
  const onSelectChange = (params) => {
    const selectedTeams = editingProfileDataLibrary?.teams?.filter((region) =>
      params.value.includes(region.name),
    );

    setCaregiverProfile({
      ...caregiverProfile,
      [params.field]: selectedTeams,
    });
  };
  const isSecondaryTeamsSame = checkEqualArrays(
    caregiverProfile.secondaryTeams,
    caregiverProfileBeforeEdit.secondaryTeams,
  );
  useEffect(() => {
    if (
      !isSecondaryTeamsSame &&
      !optionIsOpen &&
      caregiverProfile.secondaryTeams
    ) {
      setTeamEffectiveDateOpen({ isPrimary: false, isOpen: true });
    }
  }, [
    caregiverProfile.secondaryTeams,
    isSecondaryTeamsSame,
    optionIsOpen,
    setTeamEffectiveDateOpen,
  ]);

  const options = useMemo(() => {
    if (Array.isArray(caregiverProfile?.primaryTeams)) {
      return editingProfileDataLibrary?.teams?.map((team) =>
        caregiverProfile?.primaryTeams
          ?.map((name) => name)
          ?.includes(team?.name)
          ? { ...team, disabledTeam: true }
          : team,
      );
    }
    return editingProfileDataLibrary?.teams;
  }, [editingProfileDataLibrary, caregiverProfile?.primaryTeams]);
  const closeEffectiveDateDialog = () => {
    if (isSecondaryTeamsSame) {
      setTeamEffectiveDateOpen({
        isPrimary: true,
        isOpen: false,
      });
    } else {
      setTeamEffectiveDateOpen({
        isPrimary: false,
        isOpen: false,
      });
    }

    initializeCaregiverProfile();
  };
  return (
    <Box sx={{ maxWidth: '250px', minWidth: '250px' }}>
      <TeamsSelect
        id="secondaryTeams"
        options={options}
        placeHolder="Secondary teams"
        selectedValue={
          Array.isArray(caregiverProfile?.secondaryTeams)
            ? caregiverProfile?.secondaryTeams.map((region) => region?.name)
            : []
        }
        setValue={onSelectChange}
        isOpen={optionIsOpen}
        setIsOpen={setOptionIsOpen}
      />
      <TeamEffectiveDateModal
        closeEffectiveDateDialog={closeEffectiveDateDialog}
        openEffectiveDateDialog={
          teamEffectiveDateOpen.isOpen && profileData?.hasAvailability
        }
        submitEffectiveDateDialog={() => {}}
        isPrimary={teamEffectiveDateOpen.isPrimary}
        date={teamDate}
        selectDate={setTeamDate}
        secondaryTeams={caregiverProfile.secondaryTeams}
        caregiverId={id}
      />
    </Box>
  );
}
