const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

const getShortedEmail = (string) => {
  if (string?.split('@')[0].includes('.')) {
    return `${string?.split('.')[0][0]}${string.split('.')[1][0]}`;
  }
  return string?.slice(0, 2);
};
const getShortedFullName = (string) => {
  const nameParts = string?.split(' ');

  if (nameParts?.length === 2) {
    const [firstName, lastName] = nameParts;
    return `${firstName[0]}${lastName[0]}`;
  }

  return string?.slice(0, 2);
};

const stringAvatar = (name, email) => ({
  sx: {
    bgcolor: stringToColor(name),
  },
  // if email and if just firsst and last name
  children: email ? getShortedEmail(name) : getShortedFullName(name),
});

export default stringAvatar;
