import { COLORS } from '../../../../../../../../../shared/styles/Theme';

export const timeSlotItemStyles = {
  display: 'flex',
  height: '40px',
  minWidth: '160px',
  flex: '1 1 0',
  padding: '2px 0',
  borderRadius: '10px',
  backgroundColor: COLORS.green[200],
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '13px',
  fontWeight: 600,
  lineHeight: '16px',
  letterSpacing: '0.03em',
  color: COLORS.green[600],
  cursor: 'pointer',
  border: 'none',
  '&.selected': {
    backgroundColor: COLORS.blue[700],
    color: '#ffffff',
  },
};
