export const visitInfoManagementWrapper = {
  display: 'flex',
  gap: '24px',
  mt: '24px',
};

export const visitInfoManagementColumnStyles = {
  display: 'flex',
  gap: '24px',
  width: '100%',
};

export const columnTitle = {
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '16px',
  letterSpacing: '0em',
  mb: '16px',
};
