import { Box, Stack } from '@mui/material';
import { useGetTeamsQuery } from '../../../../api/Administration/api';
import { useGetCaregiversListQuery } from '../../../../api/Caregivers/api';
import {
  useConfirmClientActivationMutation,
  useConfirmClientDeactivationMutation,
  useConfirmClientResumeMutation,
  useConfirmClientSuspendMutation,
  useGetUserDataByIdQuery,
  useUpdateClientsInfoMutation,
} from '../../../../api/Clients/api';
import { useGetSkillsLibraryQuery } from '../../../../api/commonApi/api';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router';
import { checkEqualArrays } from '../../../../shared/utils/common';
import AlertContext from '../../../../components/Alert';
import ButtonsGroup from '../../../../components/ButtonsGroup';
import Loader from '../../../../components/Loader';
import { ClientDetailsContext, ClientProfilesContext } from '../../context';
import ChangeBlockedCaregiversSteps from './ChangeBlockedCaregiversSteps';
import ClientProfileHistoryTable from './ClientProfileHistory';
import EditProfileActions from './EditProfileActions';
import InfoView from './InfoView';
import { detailsButtons, initialState } from './constants';

export default function ClientsProfileInfo() {
  const { id, tabSubView } = useParams();
  const { setAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const [clientProfile, setClientProfile] = useState(initialState);
  const [hasChanges, setHasChanges] = useState(false);
  const [
    showCheckBlockedCaregiversResult,
    setShowCheckBlockedCaregiversResult,
  ] = useState(false);
  const [date, setDate] = useState(moment().add(1, 'day'));

  const { isEdit, onCloseProfileEditing, onEditProfile } =
    useContext(ClientDetailsContext);

  const { data: skills, error: getSkillsLibraryError } =
    useGetSkillsLibraryQuery();
  const { data: team, error: getLocationsListError } = useGetTeamsQuery();
  const {
    data: userData,
    isLoading: isDataLoading,
    isFetching: isDataReFetching,
    error: userDataError,
  } = useGetUserDataByIdQuery(id);
  const [updateClientsInfo, { error: updateClientsInfoError }] =
    useUpdateClientsInfoMutation();
  const { data: caregiverList, error: getCaregiversListError } =
    useGetCaregiversListQuery({ refetchOnMountOrArgChange: true });
  const [
    confirmClientSuspend,
    { isLoading: confirmationSuspend, error: confirmationSuspendError },
  ] = useConfirmClientSuspendMutation();
  const [
    confirmClientResume,
    { isLoading: confirmationResume, error: confirmationResumeError },
  ] = useConfirmClientResumeMutation();
  const [
    confirmClientDeactivation,
    {
      isLoading: confirmationDeactivation,
      error: confirmationDeactivationError,
    },
  ] = useConfirmClientDeactivationMutation();
  const [
    confirmClientActivation,
    { isLoading: confirmationActivation, error: confirmationActivationError },
  ] = useConfirmClientActivationMutation();

  useEffect(() => {
    if (
      userDataError ||
      updateClientsInfoError ||
      getCaregiversListError ||
      confirmationSuspendError ||
      confirmationResumeError ||
      confirmationDeactivationError ||
      getSkillsLibraryError ||
      getLocationsListError ||
      confirmationActivationError
    ) {
      const errorData =
        userDataError ||
        updateClientsInfoError ||
        getCaregiversListError ||
        confirmationSuspendError ||
        confirmationResumeError ||
        confirmationDeactivationError ||
        getSkillsLibraryError ||
        getLocationsListError ||
        confirmationActivationError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    confirmationActivationError,
    confirmationDeactivationError,
    confirmationResumeError,
    confirmationSuspendError,
    getCaregiversListError,
    getLocationsListError,
    getSkillsLibraryError,
    setAlert,
    updateClientsInfoError,
    userDataError,
  ]);

  const modifiedCaregiverList = caregiverList?.map((item) => ({
    firstName: item.firstName,
    hasAnyAvailability: item.hasAnyAvailability,
    id: item.id,
    lastName: item.lastName,
    primaryTeams: item.primaryTeams,
    secondaryTeams: item.secondaryTeams,
    status: item.status,
  }));

  const editingProfileDataLibrary = useMemo(() => {
    const filteredCaregiversByClientTeam = modifiedCaregiverList
      ? modifiedCaregiverList
          ?.filter(
            (caregiver) =>
              caregiver.status.toLowerCase() === 'active' &&
              ([...caregiver.primaryTeams, ...caregiver.secondaryTeams]
                .map((teamData) => teamData?.id)
                .includes(clientProfile?.team?.id) ||
                clientProfile?.blockedCaregivers.find(
                  (savedCaregiver) => savedCaregiver.id === caregiver.id,
                )),
          )
          .sort((a, b) => a.firstName.localeCompare(b.firstName))
      : [];
    return {
      skills,
      team,
      caregiversAvailableForBlackList: filteredCaregiversByClientTeam,
    };
  }, [modifiedCaregiverList, clientProfile, skills, team]);

  useEffect(() => {
    const isEqual =
      clientProfile?.team?.id === userData?.clientDetails?.team?.id;
    setHasChanges(!isEqual);
    if (
      // clientProfile?.additionalInfo === null &&
      clientProfile?.team === null
    ) {
      setHasChanges(false);
    }
  }, [clientProfile, userData?.clientDetails]);

  const initializeClientProfile = () => {
    setClientProfile({
      team: userData?.clientDetails?.team,
      blockedCaregivers: userData?.clientDetails.blockedCaregivers || [],
    });
  };

  const editProfile = () => {
    initializeClientProfile();
    onEditProfile();
    setHasChanges(false);
  };

  const updateClientData = () => {
    updateClientsInfo({
      clientId: id,
      payload: {
        ...clientProfile,
        version: userData?.clientDetails.version,
      },
    });
    onCloseProfileEditing();
    setHasChanges(false);
  };

  const onSelectChange = useCallback(
    (params) => {
      if (params.field === 'team') {
        const selectedTeam = editingProfileDataLibrary[params.field]?.find(
          (teamParams) => teamParams.name === params.value,
        );

        setClientProfile((prevProfile) => ({
          ...prevProfile,
          [params.field]: selectedTeam,
        }));
        return true;
      }
      if (params.field === 'blockedCaregivers') {
        const selectedCaregivers =
          editingProfileDataLibrary?.caregiversAvailableForBlackList?.filter(
            (caregiver) =>
              params.value.includes(
                `${caregiver.firstName} ${caregiver.lastName}`,
              ),
          );
        setClientProfile((prevProfile) => ({
          ...prevProfile,
          [params.field]: selectedCaregivers,
        }));
        return true;
      }
      setClientProfile((prevProfile) => ({
        ...prevProfile,
        [params.field]: params.value,
      }));
      return true;
    },
    [editingProfileDataLibrary],
  );

  const isBlockedCaregiverListChanged = useMemo(
    () =>
      !checkEqualArrays(
        clientProfile?.blockedCaregivers,
        userData?.clientDetails?.blockedCaregivers,
      ),
    [clientProfile, userData?.clientDetails],
  );
  const isNeedToCallOptimisation = useMemo(() => {
    if (
      !userData ||
      !editingProfileDataLibrary?.caregiversAvailableForBlackList.length
    )
      return false;

    const uniqueUpdatedArray = clientProfile?.blockedCaregivers.filter(
      (initialListCaregiverData) =>
        !userData?.clientDetails?.blockedCaregivers.find(
          (newListCaregiverData) =>
            newListCaregiverData?.id === initialListCaregiverData?.id,
        ),
    );
    if (!uniqueUpdatedArray.length) return false;
    return uniqueUpdatedArray.some((caregiver) => caregiver.hasAnyAvailability);
  }, [clientProfile, editingProfileDataLibrary, userData]);

  const memoizedProviderValue = useMemo(
    () => ({
      clientProfile,
      confirmClientActivation,
      confirmClientDeactivation,
      confirmClientResume,
      confirmClientSuspend,
      editingProfileDataLibrary,
      isBlockedCaregiverListChanged,
      onSelectChange,
      userData,
      date,
      setDate,
    }),
    [
      clientProfile,
      confirmClientActivation,
      confirmClientDeactivation,
      confirmClientResume,
      confirmClientSuspend,
      editingProfileDataLibrary,
      isBlockedCaregiverListChanged,
      onSelectChange,
      userData,
      date,
      setDate,
    ],
  );
  const isDataFetching =
    confirmationSuspend ||
    confirmationResume ||
    confirmationDeactivation ||
    confirmationActivation ||
    isDataReFetching ||
    isDataLoading;

  const cancelInfoEditing = () => {
    setShowCheckBlockedCaregiversResult(false);
    onCloseProfileEditing();
    setHasChanges(false);
  };
  const changeSubView = (value) => navigate(`/clients/${id}/profile/${value}`);
  return (
    <ClientProfilesContext.Provider value={memoizedProviderValue}>
      <Stack sx={{ maxWidth: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <ButtonsGroup
            buttons={detailsButtons}
            changeSelected={changeSubView}
            selected={tabSubView}
            disabled={isEdit || !userData}
          />
          {tabSubView === 'current' && (
            <EditProfileActions
              cancelEditing={onCloseProfileEditing}
              checkBlockedCareGivers={() =>
                setShowCheckBlockedCaregiversResult(true)
              }
              editProfile={editProfile}
              hasChanges={hasChanges}
              isBlockedCaregiverListChanged={isBlockedCaregiverListChanged}
              isEdit={isEdit}
              isNeedToCallOptimisation={isNeedToCallOptimisation}
              updateClientData={updateClientData}
            />
          )}
        </Box>
        {showCheckBlockedCaregiversResult && (
          <ChangeBlockedCaregiversSteps
            blockedCaregivers={clientProfile?.blockedCaregivers}
            cancelInfoEditing={cancelInfoEditing}
            closeBlockedCaregiversResult={() =>
              setShowCheckBlockedCaregiversResult(false)
            }
            showCheckBlockedCaregiversResult={showCheckBlockedCaregiversResult}
          />
        )}
        {isDataFetching && <Loader />}
        {userData && (
          <Box sx={{ maxWidth: '100%', mt: '32px' }}>
            {tabSubView === 'current' ? (
              <InfoView isEdit={isEdit} userData={userData} />
            ) : (
              <ClientProfileHistoryTable />
            )}
          </Box>
        )}
      </Stack>
    </ClientProfilesContext.Provider>
  );
}
