import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { string } from 'prop-types';
import React from 'react';
import { SHORT_DATE_FORMAT } from '../../../../../../../shared/constants';
import { COLORS } from '../../../../../../../shared/styles/Theme';

export default function CaregiverInfo({ caregiverName = '', date = '' }) {
  return (
    <Box sx={{ display: 'flex', gap: '16px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <Typography sx={{ color: COLORS.blue[500] }}>Caregiver:</Typography>
        <Typography sx={{ color: COLORS.blue[500] }}>Date:</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Typography sx={{ color: COLORS.blue[900] }}>
          {caregiverName}
        </Typography>
        <Typography sx={{ color: COLORS.blue[900] }}>
          {moment(date).format(SHORT_DATE_FORMAT)}
        </Typography>
      </Box>
    </Box>
  );
}

CaregiverInfo.propTypes = {
  caregiverName: string,
  date: string,
};
