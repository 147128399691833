import { Box, Stack, Typography } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import noDataIcon from '../../../../../img/icons/no-data.svg';
import { CaregiverProfileContext } from '../../../../../pages/CaregiverDetails/context';
import { bool, func, instanceOf } from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { MONTH_DAY_YEAR_FORMAT } from '../../../../../shared/constants';
import { v4 as uuidv4 } from 'uuid';
import {
  AffectedVisitsAccordion,
  DeletedShadowVisitsAccordion,
} from '../../../../../components/AffectedVisitsAccordion';
import FullWidthDialog from '../../../../../components/FullWidthDialog';
import Loader from '../../../../../components/Loader';
import CustomTable from '../../../../../components/Table';
import AvailabilityBreadcrumbs from './Breadcrum';
import { SecondaryTeamTooltip } from './TeamChangedTooltip/TeamChangedTooltip';
import { reschedulingVisitColumns, shadowVisitColumns } from './constants';

function SchedulingUpdatesTable({
  actualVisit = {},
  open = false,
  isLoading = false,
  cancelCallback = () => {},
  submitCallback = () => {},
  teamDate = {},
  secondaryTeamsChanged = [],
}) {
  const apiRef = useGridApiRef();
  const getRowId = useCallback((row) => `${row.visitType} - ${uuidv4()}`, []);
  const {
    caregiverProfileBeforeEdit,
    caregiverProfile,
    teamEffectiveDateOpen,
  } = useContext(CaregiverProfileContext);
  if (!actualVisit) {
    return false;
  }
  const getNoData = (isShadow) => (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <img src={noDataIcon} alt="" />
      {isShadow ? (
        <Typography>Have no deleted shadow visits</Typography>
      ) : (
        <Typography>Have no rescheduled visits on this day</Typography>
      )}
    </Stack>
  );
  const dates =
    actualVisit && actualVisit.rescheduledEvents
      ? Object.keys(actualVisit.rescheduledEvents)
      : [];
  if (isLoading) {
    return (
      <Box sx={{ height: '300px' }}>
        <Loader />
      </Box>
    );
  }
  return (
    <Box>
      <FullWidthDialog
        cancelButtonName="cancel"
        cancelCallback={cancelCallback}
        backButtonCallback={cancelCallback}
        submitButtonName="confirm Updates"
        submitCallback={submitCallback}
        title="Scheduling Updates"
        openDialog={open}
      >
        <Box sx={{ padding: '30px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <AvailabilityBreadcrumbs />
            <Box sx={{ display: 'flex', gap: 2 }}>
              {teamEffectiveDateOpen?.isPrimary && (
                <Box sx={{ display: 'flex', gap: 1, mb: '20px' }}>
                  <Typography>PrimaryTeam:</Typography>
                  <Typography sx={{ textDecoration: 'line-through' }}>
                    {caregiverProfileBeforeEdit?.primaryTeams &&
                      caregiverProfileBeforeEdit?.primaryTeams[0]?.name}
                  </Typography>
                  <Typography>
                    {caregiverProfile?.primaryTeams &&
                      caregiverProfile?.primaryTeams[0]?.name}
                  </Typography>
                </Box>
              )}
              {!teamEffectiveDateOpen?.isPrimary && (
                <Box sx={{ display: 'flex', gap: 1, mb: '20px' }}>
                  <Typography> New Secondary Teams:</Typography>
                  <SecondaryTeamTooltip teams={secondaryTeamsChanged} />
                </Box>
              )}
              <Box sx={{ display: 'flex', gap: 1, mb: '20px' }}>
                <Typography>Effective Start Date:</Typography>
                <Typography>
                  {teamDate.format(MONTH_DAY_YEAR_FORMAT)}
                </Typography>
              </Box>
            </Box>
          </Box>
          {actualVisit?.rescheduledEvents &&
          Object?.keys(actualVisit?.rescheduledEvents)?.length === 0 ? (
            <CustomTable
              apiRef={apiRef}
              columns={reschedulingVisitColumns}
              getRowId={getRowId}
              headerHeight={44}
              noRowsOverlay={getNoData}
              rowHeight={107}
              withoutPagination
              isLoading={isLoading}
            />
          ) : (
            <Box>
              {dates?.map((date) => (
                <AffectedVisitsAccordion
                  key={uuidv4()}
                  hasWarning={actualVisit.rescheduledEvents[date].some(
                    (visit) => visit?.status?.status === 'warning',
                  )}
                  hasError={actualVisit.rescheduledEvents[date].some(
                    (visit) => visit?.status?.status === 'error',
                  )}
                  tasksCount={actualVisit.rescheduledEvents[date].length}
                  title={date}
                >
                  <CustomTable
                    apiRef={apiRef}
                    columns={reschedulingVisitColumns}
                    getRowId={getRowId}
                    headerHeight={44}
                    noRowsOverlay={getNoData}
                    rowHeight={107}
                    rows={actualVisit?.rescheduledEvents[date]}
                    withoutPagination
                    isLoading={isLoading}
                  />
                </AffectedVisitsAccordion>
              ))}
            </Box>
          )}
          <DeletedShadowVisitsAccordion
            itemsCount={actualVisit?.nonShadowVisitsAnyMore?.length}
            title="Shadow Visits to Be Deleted"
          >
            <CustomTable
              apiRef={apiRef}
              columns={shadowVisitColumns}
              headerHeight={44}
              noRowsOverlay={() => getNoData(true)}
              rowHeight={107}
              rows={actualVisit?.nonShadowVisitsAnyMore}
              withoutPagination
              isLoading={isLoading}
            />
          </DeletedShadowVisitsAccordion>
        </Box>
      </FullWidthDialog>
    </Box>
  );
}

SchedulingUpdatesTable.propTypes = {
  actualVisit: instanceOf(Object),
  open: bool,
  isLoading: bool,
  cancelCallback: func,
  submitCallback: func,
  teamDate: instanceOf(Object),
  secondaryTeamsChanged: instanceOf(Array),
};

export default React.memo(SchedulingUpdatesTable);
