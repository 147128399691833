import moment from 'moment';
import { H12_TIME_FORMAT } from '../../../../shared/constants';
import { addCallOutRequiredFields } from './constants';

export const checkRequiredFieldsFilled = (requestData) =>
  !addCallOutRequiredFields.some(
    (item) =>
      (item !== 'caregiver' && !requestData[item]?.isValid()) ||
      !requestData[item] ||
      requestData[item].length < 1,
  );

export const isCrossedPeriods = (data) => {
  const { endTime, id, periods, startTime } = data;
  const filteredPeriods = periods.filter(
    ({ temporaryId }) => temporaryId !== id,
  );
  const isCrossedPeriod = filteredPeriods.some((period) => {
    const rangeStart = moment(period.startTime, H12_TIME_FORMAT);
    const rangeEnd = moment(period.endTime, H12_TIME_FORMAT);
    return (
      (startTime &&
        startTime.isBetween(rangeStart, rangeEnd, 'minutes', '()')) ||
      (endTime && endTime.isBetween(rangeStart, rangeEnd, 'minutes', '()'))
    );
  });
  if (isCrossedPeriod) {
    return 'You have been inputted time which crossed with some periods';
  }
  const isPeriodsAbsorb =
    startTime &&
    endTime &&
    filteredPeriods.some((period) => {
      const rangeStart = moment(period.startTime, H12_TIME_FORMAT);
      const rangeEnd = moment(period.endTime, H12_TIME_FORMAT);
      return (
        rangeStart.isBetween(startTime, endTime, 'minutes', '[)') &&
        rangeEnd.isBetween(startTime, endTime, 'minutes', '(]')
      );
    });
  if (isPeriodsAbsorb) {
    return 'You have been inputted time range which absorbed another periods';
  }
  return null;
};
