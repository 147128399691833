import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import { Alert, IconButton } from '@mui/material';
import { func, instanceOf, string } from 'prop-types';
import React from 'react';
import { COLORS } from '../../shared/styles/Theme';

export default function ErrorAlert({
  closeError = null,
  customStyles = {},
  error = '',
}) {
  return (
    <Alert
      action={
        closeError ? (
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={closeError}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        ) : null
      }
      onClose={closeError}
      severity="error"
      sx={{
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14px',
        color: COLORS.blue[900],
        mb: '14px',
        '& .MuiAlert-message': {
          whiteSpace: 'break-spaces',
          padding: 0,
          display: 'flex',
          alignItems: 'center',
        },
        ...customStyles,
      }}
      icon={
        <WarningIcon
          sx={{
            color: COLORS.red[400],
            fontSize: '14px',
            mr: '4px',
            cursor: 'pointer',
          }}
        />
      }
    >
      {error}
    </Alert>
  );
}

ErrorAlert.propTypes = {
  closeError: func,
  customStyles: instanceOf(Object),
  error: string,
};
