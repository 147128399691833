import { Box, Typography } from '@mui/material';
import { CaregiverProfileContext } from '../../../../../pages/CaregiverDetails/context';
import { bool, instanceOf } from 'prop-types';
import React, { useContext } from 'react';
import { backgroundColorSchemeMap } from '../../../../../shared/constants';
import { checkEqualArrays } from '../../../../../shared/utils/common';
import Badge from '../../../../../components/Badge';

export default function SecondaryRegionsInfo({
  isPrimary = false,
  value = [],
}) {
  const emptyValuePlaceholder = isPrimary ? 'No primary team' : '';
  const { profileData } = useContext(CaregiverProfileContext);
  const isEqualTeams = checkEqualArrays(
    value,
    profileData?.futureSecondaryTeams,
  );
  return (
    <Box sx={{ display: 'flex', maxWidth: '800px', flexWrap: 'wrap' }}>
      {value?.length ? (
        value?.map((regions, index) => {
          const key = `${regions?.name}${regions?.id}-${index}`;
          return (
            <Box key={key}>
              {!profileData.secondaryChanged ? (
                <Typography
                  key={key}
                  sx={{ marginRight: '2px' }}
                  variant="body1"
                >{`${regions?.name}${
                  index !== value.length - 1 ? ',' : ''
                }`}</Typography>
              ) : (
                <Box key={regions?.id} sx={{ mr: '6px' }}>
                  <Badge
                    colorScheme={backgroundColorSchemeMap.error}
                    label={regions?.name}
                  />
                </Box>
              )}
            </Box>
          );
        })
      ) : (
        <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
          {emptyValuePlaceholder}
        </Typography>
      )}
      {!isEqualTeams && profileData?.futureSecondaryTeams?.length && (
        <Box sx={{ display: 'flex', gap: 1 }}>
          {profileData?.futureSecondaryTeams?.length &&
            profileData?.futureSecondaryTeams?.map((newRegion, counter) => (
              <Typography
                key={newRegion.id}
                sx={{ marginRight: '2px' }}
                variant="body1"
              >{`${newRegion.name}${
                counter !== value.length - 1 ? ',' : ''
              }`}</Typography>
            ))}
          <Typography>from {profileData?.effectiveDateStart}</Typography>
        </Box>
      )}
    </Box>
  );
}

SecondaryRegionsInfo.propTypes = {
  isPrimary: bool,
  value: instanceOf(Array),
};
