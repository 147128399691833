import { COLORS } from '../../../../../../../../../../../shared/styles/Theme';

export const timeSlotItemWrapperStyles = {
  display: 'flex',
  flex: 1,
  width: '100%',
  height: '54px',
  padding: '8px',
  backgroundColor: '#ffffff',
};

export const timeSlotItemStyles = {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  color: COLORS.blue[900],
  borderRadius: '10px',
  cursor: 'pointer',
  '& .MuiTypography-root': {
    cursor: 'pointer',
  },
};

export const getConstantTableItemStyles = (isArrivalWindowItem) => ({
  display: 'flex',
  boxSizing: 'border-box',
  width: isArrivalWindowItem ? '300px' : '100%',
  height: '44px',
  flex: isArrivalWindowItem ? 'none' : 1,
  alignItems: 'center',
  justifyContent: 'space-between',
  color: COLORS.blue[500],
  backgroundColor: '#ffffff',
  mr: isArrivalWindowItem ? '5px' : 0,
  borderRight: isArrivalWindowItem ? `1px solid ${COLORS.blue[200]}` : 'none',
  borderBottom: `1px solid ${COLORS.blue[200]}`,
});
