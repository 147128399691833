import { COLORS } from '../../../../shared/styles/Theme';

const getCustomSelectStyles = (backgroundColor) => ({
  maxWidth: '150px',
  minWidth: '100px',
  width: '100%',
  minHeight: '40px',
  flex: '1 0 0',
  fontSize: '13px',
  fontWeight: '600',
  color: '#ffffff',
  textTransform: 'uppercase',
  borderRadius: '8px',
  '&.MuiOutlinedInput-root': {
    border: 'none',
    backgroundColor,
    '.MuiOutlinedInput-input, .MuiOutlinedInput-input.Mui-disabled': {
      height: '100%',
      padding: '11px 16px',
      WebkitTextFillColor: '#ffffff',
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      cursor: 'not-allowed',
    },
    fieldset: {
      border: 'none',
    },
  },
  '& .MuiSvgIcon-root.MuiSelect-icon': {
    fill: '#ffffff',
    width: '20px',
    height: '20px',
    right: 10,
    top: 9,
  },
});

const customSelectItem = {
  display: 'flex',
  fontSize: '15px',
  margin: '0 8px',
  color: `${COLORS.blue[900]}`,
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  '&.canceled-item': {
    color: `${COLORS.red[500]}`,
  },
  '&.select-all-item': {
    paddingBottom: 0,
    '&.MuiMenuItem-root:hover': {
      backgroundColor: '#ffffff',
    },
  },
  '&:focus ': {
    backgroundColor: '#ffffff',
  },
  '&:hover': {
    backgroundColor: `${COLORS.blue[50]}`,
  },
  '&.Mui-selected, &.Mui-selected:active,  &.Mui-selected:focus': {
    backgroundColor: '#ffffff',
  },
  '&.Mui-selected:hover': {
    backgroundColor: `${COLORS.blue[50]}`,
    cursor: 'default',
  },
};

const customPaperStyles = {
  padding: '8px 0',
  boxShadow: '0px 4px 12px 2px rgba(180, 180, 180, 0.2)',
  borderRadius: '10px',
  maxHeight: '300px',
};

export { customPaperStyles, getCustomSelectStyles, customSelectItem };
