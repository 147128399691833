import { Box } from '@mui/material';
import { func, instanceOf } from 'prop-types';
import React from 'react';
import { WORKING_WEEK_LENGTH } from '../../../../../../../../../../shared/constants';
import HorizontalItemsSelector from '../../../../../../../../../../components/HorizontalItemsSelector';
import CustomSelect from '../../../../../../../../../../components/Select';
import { visitRepeatItems } from '../constants';

export default function DaysSchedule({
  disabledDays = {},
  filtersData = {},
  handleChangeDays = () => {},
  onSelectChange = () => {},
  visitData = {},
}) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ width: '100%', height: '8px' }} />
      <HorizontalItemsSelector
        handleChange={handleChangeDays}
        disabledDays={disabledDays}
        options={filtersData?.weekdays.slice(0, WORKING_WEEK_LENGTH)}
        isRequired
        selectedDays={visitData?.days}
        title="Recurrence Pattern"
      />
      <Box sx={{ width: '100%', height: '24px' }} />
      <CustomSelect
        disabled
        id="repeat"
        isLoading={false}
        options={visitRepeatItems}
        placeHolder="Repeat"
        selectedValue={visitData?.repeat || ''}
        setValue={onSelectChange}
      />
    </Box>
  );
}

DaysSchedule.propTypes = {
  filtersData: instanceOf(Object),
  disabledDays: instanceOf(Object),
  handleChangeDays: func,
  onSelectChange: func,
  visitData: instanceOf(Object),
};
