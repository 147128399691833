import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useGetWeekendsSchedulingDaysQuery } from '../../../../../../../../../../api/Administration/api';
import {
  useGetFiltersDataQuery,
  useGetSkillsLibraryQuery,
} from '../../../../../../../../../../api/commonApi/api';
import { func, instanceOf } from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { sortingDayNamesArray } from '../../../../../../../../../../shared/utils/common';
import AlertContext from '../../../../../../../../../../components/Alert';
import CustomBadges from '../../../../../../../../../../components/CustomBadges';
import SelectWithCounter from '../../../../../../../../../../components/FiltersComponents/Select';
import MultilineInput from '../../../../../../../../../../components/Inputs/MultilineInput';
import Loader from '../../../../../../../../../../components/Loader';
import CustomSelect from '../../../../../../../../../../components/Select';
import { MAX_LONG_TEXT_DESCRIPTION_FIELD } from '../constants';
import ArrivalTimeSelect from './ArrivalTimeSelect';
import ClientData from './ClientData';
import DaysSchedule from './DaysSchedule';
import StepTitle from './StepTitle';
import VisitTypeBlock from './VisitTypeBlock';

export default function VisitParameters({
  setVisitData = () => {},
  visitData = {},
}) {
  const { setAlert } = useContext(AlertContext);
  const [showVisitNotes, setShowVisitNotes] = useState(false);
  const {
    data: filtersData,
    isLoading,
    error: onMountOrArgChangeError,
  } = useGetFiltersDataQuery({
    refetchOnMountOrArgChange: true,
  });
  const {
    data: skillsData,
    isLoading: isLoadingSkills,
    error: getSkillsLibraryError,
  } = useGetSkillsLibraryQuery({
    refetchOnMountOrArgChange: true,
  });
  const { data: disabledWeekDays, error: getWeekdaysError } =
    useGetWeekendsSchedulingDaysQuery();

  useEffect(() => {
    if (onMountOrArgChangeError || getSkillsLibraryError || getWeekdaysError) {
      const errorData =
        onMountOrArgChangeError || getSkillsLibraryError || getWeekdaysError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    getSkillsLibraryError,
    getWeekdaysError,
    onMountOrArgChangeError,
    setAlert,
  ]);
  const changeDays = (e) => {
    let selectedDays = visitData.days ? [...visitData.days] : [];
    if (selectedDays.indexOf(e.target.name) > -1) {
      selectedDays = selectedDays.filter((day) => day !== e.target.name);
    } else {
      selectedDays.push(e.target.name);
    }
    setVisitData({
      ...visitData,
      days: selectedDays.sort(sortingDayNamesArray),
    });
  };

  const gendersMapKeys = useMemo(
    () =>
      skillsData?.genders.reduce(
        (obj, { description, name }) => ({
          ...obj,
          [description]: name,
          [name]: description,
        }),
        {},
      ),
    [skillsData],
  );

  const onSelectChange = (params) => {
    const isVisitTypeSelect = params.field === 'visitType';
    const isTypesOfDaySelect = params.field === 'timesOfDay';
    if (isVisitTypeSelect) {
      const selectedValue = filtersData?.visitTypes.find(
        (type) => type.name === params.value,
      );
      setVisitData({
        ...visitData,
        [params.field]: selectedValue,
        duration: selectedValue.duration,
      });
      return '';
    }
    if (isTypesOfDaySelect) {
      const selectedValue = filtersData?.timesOfDay.filter((type) =>
        params.value.includes(type.name),
      );
      setVisitData({
        ...visitData,
        [params.field]: selectedValue,
      });
      return '';
    }
    if (params.field === 'genderPreference') {
      setVisitData({
        ...visitData,
        [params.field]: gendersMapKeys[params.value],
      });
      return '';
    }
    setVisitData({
      ...visitData,
      [params.field]: params.value,
    });
    return '';
  };

  const changeDuration = (value) => {
    setVisitData({ ...visitData, duration: value });
  };

  const onDelete = (skillName) => {
    setVisitData({
      ...visitData,
      skills: visitData?.skills.filter((skill) => skill !== skillName),
    });
  };

  const changeDetails = (e) => {
    if (e.target.value.length <= MAX_LONG_TEXT_DESCRIPTION_FIELD) {
      setVisitData({
        ...visitData,
        [e.target.id]: e.target.value,
      });
    }
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '520px',
        width: '100%',
        margin: '36px auto 0 auto',
      }}
    >
      <StepTitle />
      <ClientData />
      <Box sx={{ width: '100%', height: '8px' }} />
      <DaysSchedule
        disabledDays={disabledWeekDays?.schema}
        filtersData={filtersData}
        handleChangeDays={changeDays}
        onSelectChange={onSelectChange}
        visitData={visitData}
      />
      <Box sx={{ width: '100%', height: '24px' }} />
      <VisitTypeBlock
        changeDuration={changeDuration}
        changeVisitType={onSelectChange}
        filtersData={filtersData}
        visitData={visitData}
      />
      <Box sx={{ width: '100%', height: '24px' }} />
      <ArrivalTimeSelect
        changeArrivalWindowDuration={onSelectChange}
        changeTypeOfDay={onSelectChange}
        filtersData={filtersData}
        visitData={visitData}
      />
      <Box sx={{ width: '100%', height: '24px' }} />
      <CustomSelect
        id="genderPreference"
        isLoading={isLoadingSkills}
        options={skillsData?.genders?.map((option) => option.description)}
        placeHolder="Gender Preference"
        selectedValue={
          visitData?.genderPreference
            ? gendersMapKeys[visitData?.genderPreference]
            : ''
        }
        setValue={onSelectChange}
      />
      <Box sx={{ width: '100%', height: '24px' }} />
      <SelectWithCounter
        disableColored
        filterItems={visitData?.preferredSkills || []}
        id="preferredSkills"
        isLoading={isLoading}
        isMultiselect
        options={skillsData?.skills?.map((option) => option.name)}
        placeHolder="Skills Preference"
        setFilters={onSelectChange}
        showFiltersCount
      />
      <CustomBadges
        items={
          visitData?.preferredSkills?.map((skill) => ({
            name: skill,
            value: skill,
          })) || []
        }
        onDelete={onDelete}
      />
      <Box sx={{ width: '100%', height: '24px' }} />
      <SelectWithCounter
        disableColored
        filterItems={visitData?.preferredLanguages || []}
        id="preferredLanguages"
        isLoading={isLoading}
        isMultiselect
        options={skillsData?.language?.map((option) => option.name)}
        placeHolder="Language Preference"
        setFilters={onSelectChange}
        showFiltersCount
      />
      <CustomBadges
        items={
          visitData?.preferredLanguages?.map((language) => ({
            name: language,
            value: language,
          })) || []
        }
        onDelete={onDelete}
      />
      <Box sx={{ width: '100%', height: '24px' }} />
      <FormControlLabel
        sx={{ marginBottom: '12px', maxWidth: '200px' }}
        control={<Checkbox checked={showVisitNotes} />}
        onChange={() => setShowVisitNotes(!showVisitNotes)}
        label="Additional Visit Details"
      />
      {showVisitNotes && (
        <MultilineInput
          changeDetails={changeDetails}
          id="visitNotes"
          label="Additional Visit Details"
          maxLength={MAX_LONG_TEXT_DESCRIPTION_FIELD}
          value={visitData?.visitNotes}
          showHelperText
        />
      )}
      <Box sx={{ width: '100%', height: '24px' }} />
    </Box>
  );
}

VisitParameters.propTypes = {
  setVisitData: func,
  visitData: instanceOf(Object),
};
