/* eslint-disable no-param-reassign */
import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetClientsProfileHistoryQuery } from '../../../../../api/Clients/api';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import {
  HISTORY_UPDATE_FORMAT,
  ISO_DATE_ONLY_FORMAT,
  SHORT_DATE_FORMAT,
} from '../../../../../shared/constants';
import AlertContext from '../../../../../components/Alert';
import CustomTable from '../../../../../components/Table';
import { PAGE_SIZE, PAGE_SIZE_OPTIONS, historyColumns } from '../constants';

export default function ClientProfileHistoryTable() {
  const apiRef = useGridApiRef();
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const { data, isLoading, error } = useGetClientsProfileHistoryQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);

  const updatedEvents = data
    ?.map((event, index) => {
      const changes = JSON.parse(event?.changes);
      const changesArray = [];
      const onlyPrimitives = changes
        ?.filter((type) => type.type === 'primitive')
        ?.map((change) => change);
      if (onlyPrimitives) {
        changesArray.push(...onlyPrimitives);
      }
      const team = changes
        ?.filter((change) => change.type === 'object')
        ?.map((change) => change.changes)
        ?.flat()
        ?.filter((change) => change.propertyName === 'name');
      const modifiedTeam = team?.map((change) => ({
        ...change,
        propertyName: 'Team',
      }));
      if (modifiedTeam) {
        changesArray.push(...modifiedTeam);
      }

      const caregivers = changes
        ?.filter((change) => change.type === 'collection')
        ?.map((change) => change.changes)
        .flat()
        ?.map((t) => t.changes);

      const fullNameChanges = caregivers
        ?.map((change) => {
          const firstNameChange = change?.find(
            (name) => name.propertyName === 'firstName',
          );
          const lastNameChange = change?.find(
            (name) => name.propertyName === 'lastName',
          );
          if (firstNameChange && lastNameChange) {
            const { operation } = firstNameChange;
            const propertyName = 'BlockedCaregiver';
            const from =
              operation === 'remove'
                ? `${firstNameChange.from} ${lastNameChange.from}`
                : null;
            const to =
              operation === 'insert'
                ? `${firstNameChange.to} ${lastNameChange.to}`
                : null;
            return {
              type: 'primitive',
              operation,
              propertyName,
              from,
              to,
            };
          }
          return false;
        })
        ?.filter((change) => change !== null);
      if (fullNameChanges) {
        changesArray.push(...fullNameChanges);
      }

      if (changesArray.length === 0) {
        return false;
      }

      changesArray.forEach((change) => {
        if (
          change.propertyName === 'activeFrom' ||
          change.propertyName === 'inactiveFrom'
        ) {
          change.to = moment(change?.to, ISO_DATE_ONLY_FORMAT).format(
            SHORT_DATE_FORMAT,
          );
          change.from = moment(change?.from, ISO_DATE_ONLY_FORMAT).format(
            SHORT_DATE_FORMAT,
          );
        }
      });
      return {
        ...event,
        changes: changesArray,
        dateCreated: moment(event?.dateCreated).format(HISTORY_UPDATE_FORMAT),
        id: index + 1,
      };
    })
    .filter(Boolean);
  const sortedUpdatedEvents = updatedEvents?.sort((a, b) => {
    const dateA = new Date(a.dateCreated);
    const dateB = new Date(b.dateCreated);
    return dateB - dateA;
  });
  return (
    <Box>
      <CustomTable
        apiRef={apiRef}
        columns={historyColumns}
        headerHeight={44}
        isLoading={isLoading}
        rows={sortedUpdatedEvents}
        pageSize={PAGE_SIZE}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
      />
    </Box>
  );
}
