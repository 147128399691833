import { Dialog } from '@mui/material';
import {
  useGetCareProgramTableQuery,
  useUpdateCareProgramMutation,
} from '../../../../../../api/CareProgram/api';
import moment from 'moment';
import { EditCareProgramContext } from '../../../../../../pages/ClientDetails/context';
import { instanceOf } from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router';
import { ISO_DATE_ONLY_FORMAT, TransitionGrow } from '../../../../../../shared/constants';
import { filterVisitsByEffectiveDate } from '../../../../../../shared/utils/common';
import AlertContext from '../../../../../../components/Alert';
import Loader from '../../../../../../components/Loader';
import { UPDATE_PROGRAM_POPUPS, VISITS_CHANGES_TYPES } from '../../constants';
import CreateCareProgramVisitDialog from './components/CreateCareProgramVisit';
import SmallDialogs from './components/SmallDialogs';
import UpdateCareProgramDialog from './components/UpdateCareProgram';

const INITIAL_INNER_MODAL = UPDATE_PROGRAM_POPUPS.effectiveDate;
export const ERROR_INITIAL_STATE = {};

export default function CareProgramManagement({ children = {} }) {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const [openDialog, setOpenDialog] = useState(false);

  const [
    updateCareProgram,
    { isLoading: isUpdating, isSuccess, status, error: updateCareProgramError },
  ] = useUpdateCareProgramMutation();
  const {
    data: careProgramData,
    isLoading: isCareProgramLoading,
    isFetching,
    error: getCareProgramTableError,
  } = useGetCareProgramTableQuery(id);

  const initialNextDayDate = moment().add(1, 'day').startOf('day');
  const [error, setError] = useState(ERROR_INITIAL_STATE);
  const [innerDialogType, setInnerDialogType] = useState(null);
  const [updatedCareProgram, setUpdatedCareProgram] = useState([]);
  const [initialCareProgramData, setInitialCareProgramData] = useState([]);
  const [effectiveDate, setEffectiveDate] = useState(initialNextDayDate);
  const [removedVisit, setRemovedVisit] = useState(null);
  const [editedVisit, setEditedVisit] = useState(null);

  useEffect(() => {
    if (updateCareProgramError || getCareProgramTableError) {
      const errorData = updateCareProgramError || getCareProgramTableError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [updateCareProgramError, getCareProgramTableError, setAlert]);

  useEffect(() => {
    if (status === 'fulfilled' && isSuccess) {
      setAlert({
        errorData: {
          message: 'Care program changes have been saved successfully.',
        },
        type: 'success',
      });
    }
  }, [isSuccess, setAlert, status]);

  useEffect(() => {
    if (careProgramData?.careProgram) {
      const filteredCareProgramVisits = filterVisitsByEffectiveDate(
        careProgramData?.careProgram,
      );
      const parsedCareProgram = { ...careProgramData };
      parsedCareProgram.careProgram = filteredCareProgramVisits;
      setUpdatedCareProgram(filteredCareProgramVisits);
      setInitialCareProgramData(parsedCareProgram);
    }
  }, [careProgramData, setInitialCareProgramData, setUpdatedCareProgram]);

  const closeDialog = useCallback(() => {
    if (error) {
      setError(ERROR_INITIAL_STATE);
    }
    if (removedVisit) {
      setRemovedVisit(null);
    }
    if (
      [
        UPDATE_PROGRAM_POPUPS.effectiveDate,
        UPDATE_PROGRAM_POPUPS.programView,
        UPDATE_PROGRAM_POPUPS.careProgramSummary,
      ].includes(innerDialogType) ||
      !updatedCareProgram.length
    ) {
      setOpenDialog(false);
    } else {
      setInnerDialogType(UPDATE_PROGRAM_POPUPS.programView);
    }
  }, [error, innerDialogType, removedVisit, updatedCareProgram?.length]);
  const saveCareProgramData = useCallback(() => {
    const parsedCareProgramVisits = updatedCareProgram?.map(
      ({ changesType, childId, parentId, temporaryVisitId, ...keepAttrs }) =>
        keepAttrs,
    );
    updateCareProgram({
      clientId: id,
      payload: {
        careProgram: parsedCareProgramVisits,
        clientId: id,
        version: initialCareProgramData?.version,
        // do not send plan.id if its new visit
        plan: initialCareProgramData?.plan,
      },
    });
    closeDialog();
  }, [
    closeDialog,
    id,
    initialCareProgramData?.plan,
    initialCareProgramData?.version,
    updatedCareProgram,
    updateCareProgram,
  ]);

  const openUpdateProgram = useCallback(() => {
    setUpdatedCareProgram(initialCareProgramData?.careProgram);
    setEffectiveDate(initialNextDayDate);
    setInnerDialogType(INITIAL_INNER_MODAL);
    setOpenDialog(true);
  }, [initialCareProgramData?.careProgram, initialNextDayDate]);

  const showProgramVisitsView = () => {
    setInnerDialogType(UPDATE_PROGRAM_POPUPS.programView);
  };

  const showProgramVisitsSummary = useCallback(() => {
    const careProgramWithUpdatedEffectiveDate = updatedCareProgram?.map(
      (visitData) =>
        moment(
          visitData.effectiveDateStart,
          ISO_DATE_ONLY_FORMAT,
        ).isSameOrBefore(
          moment(visitData.effectiveDateEnd, ISO_DATE_ONLY_FORMAT),
        ) &&
        moment(visitData.effectiveDateStart, ISO_DATE_ONLY_FORMAT).isAfter(
          moment(),
        ) &&
        visitData.visitId
          ? {
              ...visitData,
              effectiveDateStart:
                moment(effectiveDate).format(ISO_DATE_ONLY_FORMAT),
              prevEffectiveDateStart: visitData.effectiveDateStart,
              changesType: VISITS_CHANGES_TYPES.changedStartingDate,
            }
          : visitData,
    );
    setUpdatedCareProgram(careProgramWithUpdatedEffectiveDate);
    setInnerDialogType(UPDATE_PROGRAM_POPUPS.careProgramSummary);
  }, [effectiveDate, updatedCareProgram]);

  const showAddNewProgramVisit = () => {
    setInnerDialogType(UPDATE_PROGRAM_POPUPS.addNewVisit);
  };

  const showRemoveProgramVisit = (visit) => {
    setRemovedVisit(visit);
    setInnerDialogType(UPDATE_PROGRAM_POPUPS.removeProgram);
  };

  const setEditedVisitData = (visit) => {
    if (!visit) {
      setEditedVisit(null);
      return '';
    }
    setEditedVisit(visit);
    setInnerDialogType(UPDATE_PROGRAM_POPUPS.addNewVisit);
    return '';
  };

  const memoizedProviderValue = useMemo(
    () => ({
      closeDialog,
      editedVisit,
      effectiveDate,
      error,
      initialCareProgramData,
      innerDialogType,
      openUpdateProgram,
      plan: initialCareProgramData?.plan,
      removedVisit,
      saveCareProgramData,
      setEditedVisitData,
      setEffectiveDate,
      setError,
      setInitialCareProgramData,
      setRemovedVisit,
      setUpdatedCareProgram,
      showAddNewProgramVisit,
      showProgramVisitsSummary,
      showProgramVisitsView,
      showRemoveProgramVisit,
      updatedCareProgram,
    }),
    [
      closeDialog,
      editedVisit,
      effectiveDate,
      error,
      initialCareProgramData,
      innerDialogType,
      openUpdateProgram,
      removedVisit,
      saveCareProgramData,
      showProgramVisitsSummary,
      updatedCareProgram,
    ],
  );

  return (
    <EditCareProgramContext.Provider value={memoizedProviderValue}>
      {(isCareProgramLoading || isFetching || isUpdating) && <Loader />}
      <Dialog open={openDialog} fullScreen TransitionComponent={TransitionGrow}>
        <SmallDialogs />
        <CreateCareProgramVisitDialog />
        <UpdateCareProgramDialog />
      </Dialog>
      {children}
    </EditCareProgramContext.Provider>
  );
}

CareProgramManagement.propTypes = {
  children: instanceOf(Object),
};
