import { isItemsContainSubstring } from '../../shared/utils/common';
import { CAREGIVERS_STATUSES_MAP } from './constants';

export const filterCaregivers = (
  { caregiverName, phoneNumber, status, teams },
  caregivers,
) => {
  let data = [...caregivers];
  if (data?.length && caregiverName?.length) {
    data = data.filter((item) => {
      const fullName = `${item.firstName} ${item.lastName}`;
      const reverseFullName = `${item.lastName} ${item.firstName}`;
      return (
        isItemsContainSubstring(fullName, caregiverName) ||
        isItemsContainSubstring(reverseFullName, caregiverName)
      );
    });
  }
  if (data.length && phoneNumber?.length) {
    data = data.filter((item) => {
      const numericPhoneNumber = item?.phoneNumber?.replace(/\D/g, '');

      const formattedPhoneNumber = `(${numericPhoneNumber?.slice(
        0,
        3,
      )}) ${numericPhoneNumber?.slice(3, 6)}-${numericPhoneNumber?.slice(6)}`;
      return item.phoneNumber
        ? isItemsContainSubstring(formattedPhoneNumber, phoneNumber)
        : '';
    });
  }
  if (data.length && !status?.length) {
    data = [];
  }
  if (data.length && status?.length) {
    data = data.filter((item) =>
      status.includes(CAREGIVERS_STATUSES_MAP[item.status?.toLowerCase()]),
    );
  }

  if (!teams.length) {
    data = [];
  }

  if (data?.length && teams.length) {
    let emptyTeamCaregivers = [];
    let selectedRegionCaregivers = [];

    const locationNames = teams?.map((location) => location.name);
    const teamNames = teams?.flatMap((location) =>
      location?.teams?.map((team) => team?.name),
    );
    const allNames = [...locationNames, ...teamNames];

    if (teams?.includes('Empty Team')) {
      emptyTeamCaregivers = data?.filter(
        (item) => item?.primaryTeams?.length === 0,
      );
    }
    if (teams?.some((team) => team !== 'Empty Team')) {
      selectedRegionCaregivers = data.filter((item) =>
        [...item.primaryTeams, ...item.secondaryTeams].some((team) =>
          allNames.includes(team?.name),
        ),
      );
    }

    data = Array.from(
      new Set([...emptyTeamCaregivers, ...selectedRegionCaregivers]),
    );
  }
  return data;
};

export const getSelectedLocations = (selectedLocations, locationsList) => {
  if (!selectedLocations.length) return false;
  const regions = selectedLocations.filter(
    (item) => locationsList?.map(({ id }) => id).indexOf(item) >= 0,
  ).length;
  const communities = selectedLocations.length - regions;
  return { regions, communities };
};
