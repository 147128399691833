import { Box, Typography } from '@mui/material';
import arrivalIcon from '../../../../../../img/icons/arrival-window-visit.svg';
import carIcon from '../../../../../../img/icons/car-icon.svg';
import repeatedVisit from '../../../../../../img/icons/visitTypes/repeatedVisit.svg';
import moment from 'moment';
import { instanceOf } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { COLORS } from '../../../../../../shared/styles/Theme';
import { getIconComponent } from '../../../../../../shared/utils/common';
import { calendarCellHeightMultiplier } from '../../../../../../components/CalendarsEventComponent/constant';
import CustomTooltip from '../../../../../../components/CustomTooltip';
import { getTravelTimeStyles } from '../styles';
import ArrivalWindow from './ArrivalWindow';

export default function CareProgramEvent({ event = {} }) {
  const maxTitleLength = 20;
  const [hovered, setHovered] = useState(false);
  let truncatedTitle = event?.title || '';
  if (truncatedTitle.length > maxTitleLength) {
    truncatedTitle = `${truncatedTitle.slice(0, maxTitleLength)}...`;
  }
  const start = moment(event.start);
  const arrivalTimeStart = moment(event.arrivalTimeStart);
  const arrivalWindowHeight =
    moment(start).diff(arrivalTimeStart, 'minutes') *
    calendarCellHeightMultiplier;

  const travelTimeStyles = useMemo(
    () => getTravelTimeStyles(event.travelTime),
    [event.travelTime],
  );

  return (
    <Box
      sx={{ height: '100%' }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {event?.travelTime > 0 && moment(event?.end).isAfter(moment()) && (
        <Box sx={travelTimeStyles}>
          <img
            style={{ width: '20px', height: '23px' }}
            src={carIcon}
            alt="car"
          />
          <Typography sx={{ ml: '8px' }}>{event.travelTime} min</Typography>
        </Box>
      )}
      <Box className="custom-event" sx={{ padding: '10px 16px' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
          className="custom-event-box"
        >
          <CustomTooltip title={event?.title || ''}>
            <Typography
              variant="body1"
              sx={{
                marginLeft: '8px',
                color: COLORS.blue[900],
                fontWeight: '600',
              }}
            >
              {truncatedTitle}
            </Typography>
          </CustomTooltip>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {event.repeated && <img src={repeatedVisit} alt="icon" />}
            <Typography sx={{ marginLeft: '8px', display: 'flex' }}>
              {getIconComponent(event?.type)}
            </Typography>
          </Box>
        </Box>
        {event.duration > 20 && (
          <Box sx={{ display: 'flex', padding: '2px 10px' }}>
            <img src={arrivalIcon} alt="arrivalIcon" />
            <Typography sx={{ ml: '6px' }} variant="body2">
              {moment(arrivalTimeStart).format('h:mm A')} -
              {moment(start).format('h:mm A')}
            </Typography>
          </Box>
        )}
      </Box>
      {hovered && (
        <ArrivalWindow
          arrivalTimeStart={arrivalTimeStart}
          arrivalWindowHeight={arrivalWindowHeight}
          event={event}
          start={start}
        />
      )}
    </Box>
  );
}

CareProgramEvent.propTypes = {
  event: instanceOf(Object),
};
