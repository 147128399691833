import { Box, Typography } from '@mui/material';
import { func, instanceOf } from 'prop-types';
import React from 'react';
import { COLORS } from '../../../../../../shared/styles/Theme';
import { CustomDatePicker } from '../../../../../../components/CustomDateAndTimePickers';

export default function SelectAvailabilityDate({
  date = {},
  selectDate = () => {},
}) {
  return (
    <Box sx={{ width: '520px', padding: '0 28px 28px 28px' }}>
      <Typography
        variant="body1"
        sx={[{ color: COLORS.blue[900], mb: '20px' }]}
      >
        Select the effective date for the changes
      </Typography>
      <CustomDatePicker
        customStyles={[{ maxWidth: '160px', height: '40px' }]}
        changeDate={selectDate}
        date={date}
        isDisablePast
        isDisabledToday
        label="Effective Date"
      />
    </Box>
  );
}

SelectAvailabilityDate.propTypes = {
  date: instanceOf(Object),
  selectDate: func,
};
