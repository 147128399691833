import { Checkbox, FormControlLabel } from '@mui/material';
import { bool, func, string } from 'prop-types';
import React from 'react';

export default function CustomCheckbox({
  checked = false,
  isDisabled = false,
  label = '',
  onValueChange = () => {},
}) {
  return (
    <FormControlLabel
      sx={{ width: 'fit-content' }}
      control={
        <Checkbox
          checked={checked}
          disabled={isDisabled}
          onChange={onValueChange}
          sx={{
            '&.MuiCheckbox-root': {
              padding: '0 9px',
            },
            '&.Mui-disabled.MuiCheckbox-root.Mui-checked': {
              color: 'rgba(0, 0, 0, 0.26)',
            },
          }}
        />
      }
      label={label}
    />
  );
}

CustomCheckbox.propTypes = {
  checked: bool,
  isDisabled: bool,
  label: string,
  onValueChange: func,
};
