import { COLORS } from '../../../../shared/styles/Theme';

export const chipComponentStyle = {
  cursor: 'inherit',
  padding: '2px 10px',
  fontSize: '14px',
  fontWeight: 400,
  borderRadius: '30px',
  color: COLORS.blue[900],
  background: COLORS.blue[50],
};

export const tooltipStyles = {
  '&.MuiTooltip-tooltipPlacementRight': {
    mt: '5px !important',
    mr: '25px !important',
  },
  padding: '10px',
  paddingLeft: '6px',
  backgroundColor: '#ffffff',
  boxShadow: '0px 0px 12px rgba(44, 45, 46, 0.16)',
  color: COLORS.blue[900],
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '20px',
  borderRadius: '10px',
};
