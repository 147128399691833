import { COLORS } from '../../shared/styles/Theme';

const tableRowActionsWrapper = {
  display: 'flex',
  height: '100%',
  gap: '16px',
  alignItems: 'center',
  position: 'relative',
};

const actionsButtonStyles = {
  width: '40px',
  height: '40px',
  border: `1px solid ${COLORS.blue[200]}`,
  borderRadius: '10px',
  '&.Mui-disabled': {
    opacity: 0.6,
  },
  '&:hover': {
    backgroundColor: COLORS.blue[50],
  },
  opacity: 1,
  cursor: 'pointer',
};

export { actionsButtonStyles, tableRowActionsWrapper };
