import { LOCAL_STORAGE_KEYS } from '../../../pages/Scheduling/constants';
import { bool, func, instanceOf, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import TimeSelect from './Select';

export default function CustomTimeSelect({
  customStyles = {},
  disabled = false,
  isRequired = false,
  onSelectTimeValue = () => {},
  placeHolder = '',
  selectedTime = '',
}) {
  const [selectedValue, setSelectedValue] = useState('');
  useEffect(() => setSelectedValue(selectedTime), [selectedTime]);
  const timeValues = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS.timeValuesList),
  );
  return (
    <TimeSelect
      addStyles={customStyles}
      disabled={disabled}
      id="time"
      isLoading={false}
      isRequired={isRequired}
      options={timeValues}
      placeHolder={placeHolder}
      selectedValue={selectedValue}
      setValue={({ value }) => onSelectTimeValue(value)}
    />
  );
}

CustomTimeSelect.propTypes = {
  customStyles: instanceOf(Object),
  disabled: bool,
  isRequired: bool,
  onSelectTimeValue: func,
  placeHolder: string,
  selectedTime: string,
};
