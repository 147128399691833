import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetVisitAvailableTasksQuery } from '../../../../../../../../../../api/Visits/api';
import NoRowsComponent from '../../../../../../../../../../pages/ClientDetails/components/NoRowsComponent';
import VisitDateAccordion from '../../../../../../../../../../pages/ClientDetails/components/VisitDateAccordion';
import { func, instanceOf } from 'prop-types';
import React, { useCallback, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import AlertContext from '../../../../../../../../../../components/Alert';
import CustomTable from '../../../../../../../../../../components/Table';
import { getEditColumns } from './constants';
import { stepContentWrapperStyles, tasksManagementTableStyles } from './styles';

export default function TasksManagement({
  availableVisitTasks = [],
  closeDialog = () => {},
  selectedTimeSlots = {},
  setAvailableVisitTasks = () => {},
  visitData = {},
}) {
  const apiRef = useGridApiRef();
  const { setAlert } = useContext(AlertContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: carePlanAvailableTasks,
    isLoading: isLoadingTasks,
    error: getVisitAvailableTasksError,
  } = useGetVisitAvailableTasksQuery(
    {
      clientId: id,
      payload: {
        visitType: visitData.visitType.name,
        days: Object.values(selectedTimeSlots).map((timeSlot) => ({
          ...timeSlot,
          weekday: timeSlot.day,
        })),
      },
    },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (getVisitAvailableTasksError) {
      setAlert({
        errorData: getVisitAvailableTasksError,
        type: 'error',
      });
    }
  }, [getVisitAvailableTasksError, setAlert]);

  useEffect(() => {
    if (carePlanAvailableTasks) {
      setAvailableVisitTasks(carePlanAvailableTasks.tasksByDay);
    }
  }, [carePlanAvailableTasks, setAvailableVisitTasks]);

  const goToEditTasks = useCallback(() => {
    navigate(`/clients/${id}/care-plan/current`);
    closeDialog();
  }, [closeDialog, id, navigate]);

  const getRowId = (row) => (row ? `${row.name} ${row.carePlanTaskId}` : '');

  const getNoRowsComponent = useCallback(
    () => <NoRowsComponent goToEditTasks={goToEditTasks} />,
    [goToEditTasks],
  );

  return (
    <Box sx={stepContentWrapperStyles}>
      {!isLoadingTasks &&
        availableVisitTasks?.map((visit) => (
          <VisitDateAccordion
            key={visit.day}
            tasksCount={visit.tasks.length}
            title={visit.day}
            visit={{
              ...visit,
              visitType: visitData.visitType.name,
            }}
          >
            <CustomTable
              apiRef={apiRef}
              columns={getEditColumns(visit.day)}
              customStyles={tasksManagementTableStyles(!visit.tasks.length)}
              headerHeight={34}
              getRowId={getRowId}
              isLoading={isLoadingTasks}
              noRowsOverlay={() => getNoRowsComponent(visit)}
              onRowClick={() => {}}
              rows={visit.tasks}
              withoutPagination
            />
          </VisitDateAccordion>
        ))}
    </Box>
  );
}

TasksManagement.propTypes = {
  availableVisitTasks: instanceOf(Array),
  closeDialog: func,
  selectedTimeSlots: instanceOf(Object),
  setAvailableVisitTasks: func,
  visitData: instanceOf(Object),
};
