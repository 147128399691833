import { createContext } from 'react';

export const EditCareProgramContext = createContext(null);
export const ClientDetailsContext = createContext(null);
export const ClientProfilesContext = createContext(null);
export const CreateVisitContext = createContext(null);
export const CreateVisitActionsContext = createContext(null);
export const CreateOneTimeVisitContext = createContext(null);
export const AddTaskContext = createContext(null);
export const CarePlanContext = createContext({});
