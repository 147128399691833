import { COLORS } from '../../../../../shared/styles/Theme';

export const customUpdateProgramTableStyles = [
  {
    '& .MuiDataGrid-columnHeader:nth-last-of-type(2)': {
      '& svg': { display: 'none' },
    },
    '& .MuiDataGrid-row.visit-children': {
      borderTop: 'none',
    },
    '& .MuiDataGrid-row.visit-children .MuiDataGrid-cell:not(:first-of-type)': {
      borderTop: `1px solid ${COLORS.blue[200]}`,
    },
    '& .MuiDataGrid-cell:first-of-type': {
      padding: '0 16px 0 20px',
    },
    '& .MuiDataGrid-cell:last-of-type': {
      padding: '0 28px 0 16px',
    },
    '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-columnHeader--moving': {
      backgroundColor: 'transparent',
    },
  },
];
