import moment from 'moment';

export const groupByDay = (arr) => {
  const grouped = arr.reduce((acc, item) => {
    if (item?.secondName) {
      if (!acc[item?.secondName]) {
        acc[item?.secondName] = {
          newStartTime: null,
          newEndTime: null,
          oldStartTime: null,
          oldEndTime: null,
        };
      }

      if (item?.operation === 'insert') {
        if (item?.propertyName === 'startTime') {
          if (
            !acc[item.secondName].newStartTime ||
            item.effectiveDateStart >
              acc[item.secondName]?.newStartTime?.effectiveDateStart
          ) {
            acc[item?.secondName].newStartTime = item;
          }
        } else if (item.propertyName === 'endTime') {
          if (
            !acc[item.secondName].newEndTime ||
            item.effectiveDateStart >
              acc[item.secondName].newEndTime.effectiveDateStart
          ) {
            acc[item.secondName].newEndTime = item;
          }
        }
      } else if (item.operation === 'remove') {
        if (item.propertyName === 'startTime') {
          if (
            !acc[item.secondName].oldStartTime ||
            item.effectiveDateStart >
              acc[item.secondName].oldStartTime.effectiveDateStart
          ) {
            acc[item.secondName].oldStartTime = item;
          }
        } else if (item.propertyName === 'endTime') {
          if (
            !acc[item.secondName].oldEndTime ||
            item.effectiveDateStart >
              acc[item.secondName].oldEndTime.effectiveDateStart
          ) {
            acc[item.secondName].oldEndTime = item;
          }
        }
      }
    }
    return acc;
  }, {});
  return grouped;
};

export const findNewestEffectiveDate = (arr) => {
  const inserts = arr.filter(
    (item) =>
      item.propertyName === 'effectiveDateStart' && item.operation === 'insert',
  );

  const newestInsert = inserts.reduce((acc, item) => {
    if (!acc || item.to > acc.to) {
      return item;
    }
    return acc;
  }, null);

  return newestInsert ? newestInsert.to : null;
};
export const identifyUIRenderType = (arr) => {
  const inserts = arr.filter(
    (item) =>
      item.propertyName === 'effectiveDateStart' && item.operation === 'insert',
  );

  const uniqueDates = new Set();
  inserts.forEach((item) => uniqueDates.add(item.to));

  if (uniqueDates.size === 1) {
    return false;
  }
  return true;
};

export const sortDaysByWeekOrder = (groupedDays) => {
  const weekOrder = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  const sortedKeys = Object.keys(groupedDays).sort(
    (a, b) => weekOrder.indexOf(a) - weekOrder.indexOf(b),
  );

  const sortedGroupedDays = {};
  sortedKeys.forEach((key) => {
    sortedGroupedDays[key] = groupedDays[key];
  });

  return sortedGroupedDays;
};

const formatTimeToAmPm = (time24) => {
  if (!time24) return null;
  return moment(time24, 'HH:mm:ss').format('hh:mm A');
};

export const formattedSortedGroupedDays = (sortedGroupedDays) =>
  Object.fromEntries(
    Object.entries(sortedGroupedDays).map(([day, times]) => [
      day,
      {
        newStartTime: times.newStartTime
          ? {
              ...times.newStartTime,
              to: formatTimeToAmPm(times.newStartTime.to),
            }
          : null,
        newEndTime: times.newEndTime
          ? {
              ...times.newEndTime,
              to: formatTimeToAmPm(times.newEndTime.to),
            }
          : null,
        oldStartTime: times.oldStartTime
          ? {
              ...times.oldStartTime,
              from: formatTimeToAmPm(times.oldStartTime.from),
            }
          : null,
        oldEndTime: times.oldEndTime
          ? {
              ...times.oldEndTime,
              from: formatTimeToAmPm(times.oldEndTime.from),
            }
          : null,
      },
    ]),
  );
