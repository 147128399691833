import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
// import Chevron from '../../../../../../img/icons/chevron-right.svg?react';
import { bool, node, number, string } from 'prop-types';
import React, { useState } from 'react';
import CounterBadge from '../../../../../../components/CounterBadge';
import {
  customAccordionStyles,
  customAccordionSummaryStyles,
  visitAccordionSummaryTitle,
} from './styles';

function VisitsAccordion({
  children = <div />,
  predefinedExpanded = false,
  tasksCount = 0,
  title = '',
}) {
  const [expanded, setExpanded] = useState(predefinedExpanded);
  return (
    <Accordion elevation={0} expanded={expanded} sx={customAccordionStyles}>
      <AccordionSummary
        // expandIcon={<Chevron onClick={() => setExpanded(!expanded)} />}
        aria-controls="panel1bh-content"
        sx={customAccordionSummaryStyles}
      >
        <Box
          sx={visitAccordionSummaryTitle}
          onClick={() => setExpanded(!expanded)}
        >
          <Typography
            sx={{ lineHeight: '32px', mr: '12px', ml: '8px' }}
            variant="h5"
          >
            {title}
          </Typography>
          <CounterBadge label={tasksCount.toString()} hideIfNothing />
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>{children}</AccordionDetails>
    </Accordion>
  );
}

VisitsAccordion.propTypes = {
  children: node,
  predefinedExpanded: bool,
  tasksCount: number,
  title: string,
};

export default React.memo(VisitsAccordion);
