import { COLORS } from '../../shared/styles/Theme';

export const customInputStyles = {
  width: '100%',
  flex: '1 0 0',
  maxWidth: '140px',
  color: COLORS.blue[900],
  height: '40px',
  '& .MuiInputLabel-root': {
    top: '-6px',
    left: '3px',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '19px',
    letterSpacing: '0em',
    color: COLORS.blue[500],
    '&.Mui-focused': {
      color: COLORS.blue[500],
    },
    '&.Mui-disabled': {
      color: COLORS.black[200],
    },
    '&[data-shrink=true]': {
      top: '-3px',
    },
    '& .MuiFormLabel-asterisk': {
      color: COLORS.red[600],
    },
  },
  '& .MuiFilledInput-root': {
    border: 'none',
    minWidth: '100px',
    height: '100%',
    borderRadius: '10px',
    fontSize: '15px',
    color: COLORS.blue[900],
    backgroundColor: COLORS.blue[50],
    alignItems: 'end',
    padding: '0 16px',
    '&:before, &:after': {
      display: 'none',
    },
    '&:hover, &.Mui-focused': {
      backgroundColor: COLORS.blue[50],
    },
    '& fieldset': {
      border: 'none',
    },
    '& svg': {
      fill: COLORS.blue[300],
      fontSize: '17px',
    },
    '&.Mui-disabled .svg, & .disabled-arrow': {
      fill: COLORS.black[200],
    },
    '& .MuiFilledInput-input': {
      padding: 0,
      mb: '3px',
      '&.Mui-disabled': {
        color: COLORS.black[200],
      },
    },
  },
};
