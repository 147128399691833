import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../baseQuery';

export const CARE_PROGRAM_LOCAL_API_KEY = 'careProgramLocalApi';

const careProgramLocalApi = createApi({
  reducerPath: CARE_PROGRAM_LOCAL_API_KEY,
  baseQuery,
  endpoints: (builder) => ({
    // change name
    getCareProgramData: builder.query({
      query: (clientId) => ({
        url: `/careProgram/${clientId}`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'careProgram', id: 'ALL' }],
    }),
    updateCareProgram: builder.mutation({
      query: ({ clientId, payload }) => ({
        url: `/careProgram/${clientId}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'careProgram', id: 'ALL' }],
    }),
  }),
});

export const { useGetCareProgramDataQuery, useUpdateCareProgramMutation } =
  careProgramLocalApi;

export default careProgramLocalApi;
