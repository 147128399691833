import CircleIcon from '@mui/icons-material/Circle';
import { Box, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import WarningIcon from '../../../img/icons/warning-icon.svg';
import moment from 'moment';
import React from 'react';
import {
  H12_TIME_FORMAT,
  SHORT_DATE_FORMAT,
  backgroundColorSchemeMap,
} from '../../../shared/constants';
import { COLORS } from '../../../shared/styles/Theme';
import { getArrivalRange, getIconComponent } from '../../../shared/utils/common';
import Badge from '../../../components/Badge';
import CustomTooltip from '../../../components/CustomTooltip';
import ShadowCaregiverCell from '../../../components/DeletedSVShadowCaregiverCell';

const visitReassignmentStatusesColorsMap = {
  error: 'error',
  warning: 'partiallySuccessful',
  success: 'active',
};

const getScheduledTimeRange = (data) => {
  const colorKey = visitReassignmentStatusesColorsMap[data?.status?.status];
  return (
    <div>
      <Typography
        sx={{
          textDecoration: data?.newScheduledTime ? 'line-through' : 'none',
        }}
      >{`${moment(data?.oldScheduledTime?.start, H12_TIME_FORMAT).format(
        H12_TIME_FORMAT,
      )} - ${moment(data?.oldScheduledTime?.end, H12_TIME_FORMAT).format(
        H12_TIME_FORMAT,
      )}`}</Typography>
      {data?.newScheduledTime && (
        <CustomTooltip
          title={
            colorKey === 'active'
              ? 'Time can be changed within arrival window'
              : ''
          }
        >
          <div>
            <Badge
              colorScheme={backgroundColorSchemeMap[colorKey || 'active']}
              label={`${moment(
                data?.newScheduledTime?.start,
                H12_TIME_FORMAT,
              ).format(H12_TIME_FORMAT)} - ${moment(
                data?.newScheduledTime?.end,
                H12_TIME_FORMAT,
              ).format(H12_TIME_FORMAT)}`}
            />
          </div>
        </CustomTooltip>
      )}
    </div>
  );
};

const getCaregiverData = (row) => {
  const colorKey = visitReassignmentStatusesColorsMap[row?.status?.status];
  const initialCaregiver = row?.oldCaregiver
    ? `${row?.oldCaregiver?.firstName} ${row?.oldCaregiver?.lastName}`
    : null;
  const newCaregiver = row?.newCaregiver
    ? `${row?.newCaregiver?.firstName} ${row?.newCaregiver?.lastName}`
    : null;
  return (
    <div>
      <Typography
        sx={{ textDecoration: newCaregiver ? 'line-through' : 'none' }}
      >
        {initialCaregiver || 'Not Allocated'}
      </Typography>
      {newCaregiver && (
        <CustomTooltip
          title={colorKey === 'active' ? 'Caregiver can be changed' : ''}
        >
          <div>
            <Badge
              colorScheme={backgroundColorSchemeMap[colorKey || 'active']}
              label={newCaregiver || ''}
            />
          </div>
        </CustomTooltip>
      )}
    </div>
  );
};

export const commonColumns = [
  {
    field: 'visitType',
    headerName: 'Visit Type',
    flex: 1,
    maxWidth: 250,
    sortable: false,
    renderCell: ({ value, row }) => (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', ml: row?.shiftedBy ? '40px' : 0 }}>
          {getIconComponent(value)}
          <Typography
            variant={row?.shiftedBy ? 'body1' : 'h5'}
            sx={{ ml: '8px', color: COLORS.blue[700] }}
          >
            {value}
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ fontSize: '13px' }}>
          {row?.team?.name}
        </Typography>
      </Box>
    ),
  },
  {
    field: 'client',
    headerName: 'Client',
    minWidth: 160,
    sortable: false,
    renderCell: ({ value }) => (
      <Typography
        sx={{ color: COLORS.blue[700] }}
      >{`${value.firstName} ${value.lastName}`}</Typography>
    ),
  },
  {
    field: 'duration',
    headerName: 'Duration',
    sortable: false,
    width: 100,
    renderCell: ({ value }) => (
      <Typography>{value !== null ? `${value} min` : '0 min'}</Typography>
    ),
  },
  {
    field: 'arrivalWindow',
    headerName: 'Arrival Window',
    sortable: false,
    width: 180,
    renderCell: ({ row }) => getArrivalRange(row),
  },
  {
    field: 'scheduledTime',
    headerName: 'Scheduled Time',
    sortable: false,
    width: 180,
    renderCell: ({ row }) =>
      row?.oldScheduledTime ? getScheduledTimeRange(row) : '',
  },
  {
    field: 'caregiver',
    headerName: 'Caregiver',
    minWidth: 150,
    maxWidth: 300,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => getCaregiverData(row),
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: false,
    minWidth: 240,
    renderCell: ({ value }) => {
      const colorKey = visitReassignmentStatusesColorsMap[value?.status];
      return value?.status ? (
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <Badge
            colorScheme={backgroundColorSchemeMap[colorKey]}
            label={value.message}
          />
          {value?.status === 'warning' && (
            <CustomTooltip
              title={
                value?.reasons?.length ? (
                  <Box sx={{ padding: '12px' }}>
                    <List>
                      {value?.reasons?.map((reason) => (
                        <ListItem key={reason} sx={{ padding: 0 }}>
                          <ListItemIcon sx={{ minWidth: '16px' }}>
                            <CircleIcon
                              sx={{ fontSize: '6px', color: COLORS.blue[700] }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={reason} />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                ) : (
                  ''
                )
              }
            >
              <img src={WarningIcon} alt="Warning" />
            </CustomTooltip>
          )}
        </Box>
      ) : (
        ''
      );
    },
  },
];

export const shadowVisitsColumns = [
  {
    field: 'visitType',
    headerName: 'Visit Type',
    flex: 1,
    maxWidth: 250,
    sortable: false,
    renderCell: ({ value, row }) => (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex' }}>
          {getIconComponent(value)}
          <Typography variant="h5" sx={{ ml: '8px', color: COLORS.blue[700] }}>
            {value}
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ fontSize: '13px' }}>
          {row?.team?.name}
        </Typography>
      </Box>
    ),
  },
  {
    field: 'client',
    headerName: 'Client',
    minWidth: 160,
    sortable: false,
    renderCell: ({ value }) => (
      <Typography
        sx={{ color: COLORS.blue[700] }}
      >{`${value.firstName} ${value.lastName}`}</Typography>
    ),
  },
  {
    field: 'duration',
    headerName: 'Duration',
    sortable: false,
    width: 100,
    renderCell: ({ value }) => (
      <Typography>{value !== null ? `${value} min` : '0 min'}</Typography>
    ),
  },
  {
    field: 'arrivalWindow',
    headerName: 'Arrival Window',
    sortable: false,
    width: 180,
    renderCell: ({ row }) => getArrivalRange(row),
  },
  {
    field: 'scheduledTime',
    headerName: 'Scheduled Time',
    sortable: false,
    width: 180,
    renderCell: ({ row }) =>
      row.oldScheduledTime?.start ? (
        <Typography>{`${row.oldScheduledTime?.start} - ${row.oldScheduledTime?.end}`}</Typography>
      ) : (
        <Typography>{row.oldScheduledTime}</Typography>
      ),
  },
  {
    field: 'caregiver',
    headerName: 'Caregiver',
    minWidth: 150,
    maxWidth: 300,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => <ShadowCaregiverCell row={row} />,
  },
  {
    field: 'date',
    headerName: 'Date',
    sortable: false,
    width: 150,
    renderCell: ({ value }) => (
      <Typography>{moment(value).format(SHORT_DATE_FORMAT)}</Typography>
    ),
  },
];
