import { Box } from '@mui/material';
import { useGetFiltersDataQuery } from '../../../../../../api/commonApi/api';
import { bool, func, instanceOf } from 'prop-types';
import React, { useContext, useEffect } from 'react';
import {
  SEARCH_INPUT_DEBOUNCE_DELAY,
  WORKING_WEEK_LENGTH,
} from '../../../../../../shared/constants';
import { useDebouncedCallback } from '../../../../../../shared/hooks/useDebounce';
import AlertContext from '../../../../../../components/Alert';
import SearchFilter from '../../../../../../components/FiltersComponents/Search';
import SelectWithCounter from '../../../../../../components/FiltersComponents/Select';
import { standardTasksCategory } from '../../../../../../components/VisitInfoTypeProvider/components/CreateTask/constants';

export default function FiltersWrapper({
  carePlanFilters = {},
  isLoading = false,
  setCarePlanFilters = () => {},
}) {
  const { setAlert } = useContext(AlertContext);
  const { data: filtersData, error } = useGetFiltersDataQuery({
    refetchOnMountOrArgChange: true,
  });
  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);
  const changeSelectFilters = ({ field, value }) =>
    setCarePlanFilters({ ...carePlanFilters, [field]: value });

  const debouncedSearch = useDebouncedCallback((e) => {
    if (!e.target.value.length || e.target.value.length > 2) {
      setCarePlanFilters({
        ...carePlanFilters,
        [e.target.name]: e.target.value,
      });
    }
  }, SEARCH_INPUT_DEBOUNCE_DELAY);

  if (!filtersData) {
    return false;
  }
  return (
    <Box sx={{ display: 'flex', mb: '25px', gap: '20px' }}>
      <SearchFilter
        changeValue={debouncedSearch}
        name="task"
        placeholder="Task"
      />
      <SelectWithCounter
        filterItems={carePlanFilters.category}
        id="category"
        isLoading={isLoading}
        isMultiselect
        options={standardTasksCategory}
        placeHolder="Category"
        setFilters={changeSelectFilters}
        showFiltersCount
      />
      <SelectWithCounter
        filterItems={carePlanFilters.levelOfAssistance}
        id="levelOfAssistance"
        isLoading={isLoading}
        isMultiselect
        options={filtersData.levelsOfAssistance}
        placeHolder="Level Of Assistance"
        setFilters={changeSelectFilters}
        showFiltersCount
      />
      <SelectWithCounter
        filterItems={carePlanFilters.days}
        id="days"
        isLoading={isLoading}
        isMultiselect
        options={filtersData.weekdays
          .map(({ name }) => name)
          .slice(0, WORKING_WEEK_LENGTH)}
        placeHolder="Day"
        setFilters={changeSelectFilters}
        showFiltersCount
      />
      <SelectWithCounter
        filterItems={carePlanFilters.timeOfDay}
        id="timeOfDay"
        isLoading={isLoading}
        isMultiselect
        options={filtersData?.timesOfDay?.map((time) => time.name)}
        placeHolder="Time of Day"
        setFilters={changeSelectFilters}
        showFiltersCount
      />
      <SelectWithCounter
        filterItems={carePlanFilters.visitType}
        id="visitType"
        isLoading={isLoading}
        isMultiselect
        hasSelectAllOption
        options={filtersData.shortVisitTypes}
        placeHolder="Visit Type"
        setFilters={changeSelectFilters}
        showFiltersCount
      />
    </Box>
  );
}

FiltersWrapper.propTypes = {
  carePlanFilters: instanceOf(Object),
  isLoading: bool,
  setCarePlanFilters: func,
};
