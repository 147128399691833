import CloseIcon from '@mui/icons-material/Close';
import { Box, DialogTitle, IconButton, Typography } from '@mui/material';
import { bool, element, func, string } from 'prop-types';
import React, { createElement } from 'react';
import { COLORS } from '../../shared/styles/Theme';
import { customTitleStyles, titleContentWrapper } from './styles';

export default function Title({
  closeDialog = () => {},
  title = '',
  titleBadge = createElement('div'),
  titleIcon = createElement('div'),
  withBorder = false,
}) {
  return (
    <DialogTitle
      sx={{
        ...customTitleStyles,
        borderBottom: withBorder && `1px solid ${COLORS.blue[20]}`,
      }}
    >
      <Box sx={titleContentWrapper}>
        <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
          {titleIcon}
          <Typography variant="h4">{title}</Typography>
          {titleBadge}
        </Box>
        <IconButton
          onClick={() => closeDialog(null)}
          sx={{ marginRight: '-8px', marginTop: '-8px' }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </DialogTitle>
  );
}

Title.propTypes = {
  closeDialog: func,
  title: string,
  titleBadge: element,
  titleIcon: element,
  withBorder: bool,
};
