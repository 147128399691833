import { Breadcrumbs, Typography } from '@mui/material';
import { func, instanceOf } from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router';
import { COLORS } from '../../../../../../../shared/styles/Theme';
import BreadcrumbsButton from '../../../../../../../components/BreadcrumbsButton';

export default function CustomBreadcrumbs({
  caregiver = {},
  closeDialog = () => {},
}) {
  const navigate = useNavigate();
  const onLinkClick = ({ e, url }) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    } else {
      navigate(url);
      closeDialog();
    }
  };
  return (
    <Breadcrumbs
      aria-label="breadcrumbs"
      sx={{ '& .MuiBreadcrumbs-li .MuiTypography-root': { cursor: 'pointer' } }}
    >
      <BreadcrumbsButton
        name="Caregivers"
        onLinkClick={(e) => onLinkClick({ e, url: '/caregivers' })}
      />
      <BreadcrumbsButton
        name="Scheduling"
        onLinkClick={(e) => onLinkClick({ e, url: '/scheduling' })}
      />
      <BreadcrumbsButton
        name={caregiver.name}
        onLinkClick={(e) =>
          onLinkClick({ e, url: `/caregivers/${caregiver?.id}` })
        }
      />
      <Typography sx={{ color: COLORS.blue[900] }} variant="body1">
        Callout
      </Typography>
    </Breadcrumbs>
  );
}

CustomBreadcrumbs.propTypes = {
  caregiver: instanceOf(Object),
  closeDialog: func,
};
