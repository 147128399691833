import { Box, IconButton } from '@mui/material';
import { instanceOf } from 'prop-types';
import React from 'react';
import CustomTooltip from '../../components/CustomTooltip';
import { actionsButtonStyles, tableRowActionsWrapper } from './styles';

function TableRowActionsWithIcons({ menuItems = [] }) {
  return (
    <Box sx={tableRowActionsWrapper}>
      {menuItems.map(({ callback, icon, key, name }) => (
        <CustomTooltip key={key} title={name}>
          <IconButton
            aria-label="Edit visit"
            disableRipple
            onClick={callback}
            sx={actionsButtonStyles}
          >
            <img src={icon} alt="Edit" />
          </IconButton>
        </CustomTooltip>
      ))}
    </Box>
  );
}

TableRowActionsWithIcons.propTypes = {
  menuItems: instanceOf(Array),
};

export default React.memo(TableRowActionsWithIcons);
