import { bool, func, instanceOf, string } from 'prop-types';
import React from 'react';
import FullWidthDialog from '../../components/FullWidthDialog';
import CheckPossibilityView from './CheckPossibilityView';

export default function TimeOffRequestPossibility({
  caregiverData = {},
  closeDialog = () => {},
  confirmChanges = () => {},
  dataError = '',
  disabledSubmit = false,
  isLoading = false,
  possibilityData = {},
  responseData = null,
  shadowVisits = [],
  showDialog = false,
}) {
  return (
    <FullWidthDialog
      cancelButtonName="cancel"
      cancelCallback={closeDialog}
      submitButtonName="Confirm time-off request"
      submitCallback={confirmChanges}
      disabledSubmit={disabledSubmit}
      title="Check Time-Off Possibility"
      openDialog={showDialog}
    >
      <CheckPossibilityView
        caregiverData={caregiverData}
        closeDialog={closeDialog}
        error={dataError}
        isLoading={isLoading}
        requestData={responseData}
        possibilityData={possibilityData}
        shadowVisits={shadowVisits}
      />
    </FullWidthDialog>
  );
}

TimeOffRequestPossibility.propTypes = {
  caregiverData: instanceOf(Object),
  closeDialog: func,
  confirmChanges: func,
  dataError: string,
  disabledSubmit: bool,
  isLoading: bool,
  possibilityData: instanceOf(Object),
  responseData: instanceOf(Object),
  shadowVisits: instanceOf(Array),
  showDialog: bool,
};
