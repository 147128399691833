/* eslint-disable react/prop-types */
import Button from '@mui/material/Button';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as React from 'react';
import { datePickerStyles } from '../styles';

function ButtonField(props) {
  const {
    setOpen,
    label,
    id,
    disabled,
    InputProps: { ref } = {},
    inputProps: { 'aria-label': ariaLabel } = {},
  } = props;

  return (
    <Button
      variant="text"
      id={id}
      disabled={disabled}
      disableElevation
      disableFocusRipple
      disableRipple
      ref={ref}
      aria-label={ariaLabel}
      sx={datePickerStyles}
      onClick={() => setOpen?.((prev) => !prev)}
    >
      {label ?? 'Pick a date'}
    </Button>
  );
}

function ButtonDatePicker(props) {
  const [open, setOpen] = React.useState(false);
  return (
    <DatePicker
      // eslint-disable-next-line react/destructuring-assignment
      slots={{ field: ButtonField, ...props.slots }}
      slotProps={{ field: { setOpen } }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
}

export default function CustomDatePicker({
  value,
  label,
  minDate,
  maxDate,
  onDateChange,
}) {
  const handleChange = (newValue) => onDateChange(newValue);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ButtonDatePicker
        // eslint-disable-next-line react/destructuring-assignment
        label={label}
        minDate={minDate}
        maxDate={maxDate}
        value={value}
        onChange={handleChange}
      />
    </LocalizationProvider>
  );
}
