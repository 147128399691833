export const callOutSteps = {
  addCallOut: 'addCallOut',
  checkPossibility: 'checkPossibility',
};

export const callOutInitialState = {
  callOutEndTime: null,
  callOutStartTime: null,
  callOutEndDate: null,
  callOutStartDate: null,
  caregiver: null,
  details: '',
  forTheDay: false,
  startingNow: false,
};

export const addCallOutRequiredFields = [
  'callOutEndDate',
  'callOutEndTime',
  'callOutStartDate',
  'callOutStartTime',
  'caregiver',
];

export const caregiverAvailabilities = {
  id: 'string',
  date: '11/30/6082 05:30:42',
  holiday: 'National Homemaker Day (All Day)',
  oneTimeAvailability: [
    {
      startTime: '2:00 PM',
      endTime: '3:00 PM',
    },
  ],
  callout: {
    startTime: '3:00 PM',
    endTime: '7:00 PM',
  },
  availabilities: {
    startTime: '10:00 AM',
    endTime: '4:25 PM',
  },
};
