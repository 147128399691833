import { H12_TIME_FORMAT, visitStatusesMap } from '../../shared/constants';
import { convertUtcToTeamTimeZoneMomentObject } from '../../shared/utils/common';

const regroupVisitsData = ({ constraintsType, visits }) => {
  const actualTeamsList = visits.map((visit) => visit?.team?.name);
  const parseVisitStatus = (status) => {
    if (!status) {
      return null;
    }
    if (
      [
        visitStatusesMap.allocated,
        visitStatusesMap.cannotBeAllocated,
        visitStatusesMap.scheduled,
      ].includes(status)
    ) {
      return 'Scheduled';
    }
    return status;
  };
  return {
    visitsData: visits.reduce(
      (result, item) => [
        ...result,
        {
          ...item,
          status: parseVisitStatus(item?.status),
          arrivalStart: convertUtcToTeamTimeZoneMomentObject(
            item?.arrivalStart,
          ).format(H12_TIME_FORMAT),
          arrivalEnd: convertUtcToTeamTimeZoneMomentObject(
            item?.arrivalEnd,
          ).format(H12_TIME_FORMAT),
        },
        {
          client: item.client,
          date: item.date,
          id: `${item.id}-${constraintsType}`,
          rowType: 'violationsData',
          status: parseVisitStatus(item?.status),
          team: item.team,
          violations: item[constraintsType],
          visitId: item.id,
        },
      ],
      [],
    ),
    teams: [...new Set(actualTeamsList)],
  };
};

export const parseWeeklyReportData = ({
  allocatedVisits,
  notAllocatedVisits,
}) => {
  const filteredData = allocatedVisits.filter(({ events }) => events.length);
  const parsedData = filteredData.reduce(
    (obj, item) => ({
      hardConstraintsVisits: [
        ...obj.hardConstraintsVisits,
        ...item.events.filter((event) => event.violatedHardConstraints?.length),
      ],
      softConstraintsVisits: [
        ...obj.softConstraintsVisits,
        ...item.events.filter((event) => event.violatedSoftConstraints?.length),
      ],
      utilizationRate: obj.utilizationRate + item.caregiver.utilizationRate,
      utilizationRateWithTravelTime:
        obj.utilizationRateWithTravelTime +
        item.caregiver.utilizationRateWithTravelTime,
      utilizedMinutes: obj.utilizedMinutes + item.caregiver.utilizedMinutes,
      utilizedMinutesWithTravelTime:
        obj.utilizedMinutesWithTravelTime +
        item.caregiver.utilizedMinutesWithTravelTime,
    }),
    {
      hardConstraintsVisits: [],
      softConstraintsVisits: [],
      utilizationRate: 0,
      utilizationRateWithTravelTime: 0,
      utilizedMinutes: 0,
      utilizedMinutesWithTravelTime: 0,
    },
  );

  return {
    statistics: {
      utilizationRate: parsedData.utilizationRate,
      utilizationRateWithTravelTime: parsedData.utilizationRateWithTravelTime,
      utilizedMinutes: parsedData.utilizedMinutes,
      utilizedMinutesWithTravelTime: parsedData.utilizedMinutesWithTravelTime,
    },
    'hard-constraints': regroupVisitsData({
      constraintsType: 'violatedHardConstraints',
      visits: parsedData?.hardConstraintsVisits,
    }),
    'soft-constraints': regroupVisitsData({
      constraintsType: 'violatedSoftConstraints',
      visits: parsedData?.softConstraintsVisits,
    }),
    'not-allocated': regroupVisitsData({
      constraintsType: 'violatedHardConstraints',
      visits: notAllocatedVisits,
    }),
  };
};
