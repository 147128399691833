import { Box, Collapse } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  useGetFiltersDataQuery,
  useGetSkillsLibraryQuery,
} from '../../../../../../../../api/commonApi/api';
import moment from 'moment';
import { bool, func, instanceOf, string } from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CALIFORNIA_TIME_ZONE,
  H12_TIME_FORMAT,
  ISO_DATE_ONLY_FORMAT,
  visitArrivalTimeTypesNames,
} from '../../../../../../../../shared/constants';
import { sortArrayOfDates } from '../../../../../../../../shared/utils/common';
import AlertContext from '../../../../../../../../components/Alert';
import CustomBadges from '../../../../../../../../components/CustomBadges';
import SelectWithCounter from '../../../../../../../../components/FiltersComponents/Select';
import MultilineInput from '../../../../../../../../components/Inputs/MultilineInput';
import Loader from '../../../../../../../../components/Loader';
import CustomSelect from '../../../../../../../../components/Select';
import DatesCalendar from '../DatesCalendar';
import { MAX_LONG_TEXT_DESCRIPTION_FIELD } from '../constants';
import { visitParametersWrapper } from '../styles';
import ArrivalSlots from './ArrivalSlots';
import ClientData from './ClientData';
import StepTitle from './StepTitle';
import VisitTypeBlock from './VisitTypeBlock';

function VisitParameters({
  isSelectedTimeOutOfRange = false,
  pastTimeError = null,
  setIsSelectedTimeOutOfRange = () => {},
  setPastTimeError = () => {},
  setVisitParams = () => {},
  visitParams = {},
}) {
  const { setAlert } = useContext(AlertContext);
  const [showVisitNotes, setShowVisitNotes] = useState(false);
  const [visitArrivalTimeType, setVisitArrivalTimeType] = useState(
    visitParams?.arrivalStart
      ? visitArrivalTimeTypesNames.arrivalWindow
      : visitArrivalTimeTypesNames.exactTime,
  );
  const {
    data: filtersData,
    isLoading,
    error: getFiltersDataError,
  } = useGetFiltersDataQuery({
    refetchOnMountOrArgChange: true,
  });
  const {
    data: skillsData,
    isLoading: isLoadingSkills,
    error: getSkillsLibraryError,
  } = useGetSkillsLibraryQuery({
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (getFiltersDataError || getSkillsLibraryError) {
      const errorData = getFiltersDataError || getSkillsLibraryError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [getFiltersDataError, getSkillsLibraryError, setAlert]);

  const checkPastTimeError = useCallback(
    ({ teamCurrentTime, visitStartTime }) => {
      if (
        moment(visitStartTime, H12_TIME_FORMAT).isSameOrBefore(
          moment(teamCurrentTime, H12_TIME_FORMAT).add(10, 'minutes'),
          'minutes',
        )
      ) {
        setPastTimeError(
          'You cannot create a visit starting less than 10 min of the current time (CA timezone)',
        );
        return null;
      }
      if (pastTimeError) {
        setPastTimeError(null);
      }
      return null;
    },
    [pastTimeError, setPastTimeError],
  );

  const changeDays = useCallback(
    (dates) => {
      setVisitParams((prevValue) => ({
        ...prevValue,
        dates: sortArrayOfDates(dates),
      }));
      const californiaCurrentTime = moment()
        .tz(CALIFORNIA_TIME_ZONE)
        .format(H12_TIME_FORMAT);
      const californiaCurrentDateSelected = dates?.some(
        (date) =>
          date.format(ISO_DATE_ONLY_FORMAT) ===
          moment().tz(CALIFORNIA_TIME_ZONE).format(ISO_DATE_ONLY_FORMAT),
      );
      if (
        californiaCurrentDateSelected &&
        (visitParams?.arrivalStart || visitParams?.exactTimeSlot)
      ) {
        checkPastTimeError({
          teamCurrentTime: californiaCurrentTime,
          visitStartTime: visitParams?.exactTimeSlot
            ? moment(visitParams?.exactTimeSlot, H12_TIME_FORMAT)
            : moment(visitParams?.arrivalStart, H12_TIME_FORMAT),
        });
      }
      if (!californiaCurrentDateSelected && pastTimeError) {
        setPastTimeError(null);
      }
      return '';
    },
    [
      checkPastTimeError,
      pastTimeError,
      setPastTimeError,
      setVisitParams,
      visitParams,
    ],
  );

  const gendersMapKeys = useMemo(
    () =>
      skillsData?.genders.reduce(
        (obj, { description, name }) => ({
          ...obj,
          [description]: name,
          [name]: description,
        }),
        {},
      ),
    [skillsData],
  );

  const onSelectChange = (params) => {
    const isVisitTypeSelect = params.field === 'visitType';
    if (isVisitTypeSelect) {
      const selectedValue = filtersData?.visitTypes.find(
        (type) => type.name === params.value,
      );
      setVisitParams((prevValue) => ({
        ...prevValue,
        [params.field]: selectedValue,
        duration: selectedValue.duration,
      }));
      return '';
    }
    if (params.field === 'genderPreference') {
      setVisitParams((prevValue) => ({
        ...prevValue,
        [params.field]: gendersMapKeys[params.value],
      }));
      return '';
    }
    setVisitParams((prevValue) => ({
      ...prevValue,
      [params.field]: params.value,
    }));
    return '';
  };

  const changeDuration = (value) => {
    setVisitParams({ ...visitParams, duration: +value });
  };

  const minTime = useMemo(
    () =>
      filtersData?.timesOfDay.find(
        (time) => time?.name?.toLowerCase() === 'morning',
      )?.from,
    [filtersData],
  );
  const maxTime = useMemo(
    () =>
      filtersData?.timesOfDay.find(
        (time) => time?.name?.toLowerCase() === 'afternoon',
      )?.to,
    [filtersData],
  );

  const checkIsOutOfRange = useCallback(
    ({ exactTime, arrivalStart, arrivalEnd }) => {
      if (exactTime) {
        return (
          moment(exactTime, H12_TIME_FORMAT).isBefore(
            moment(minTime, H12_TIME_FORMAT),
          ) ||
          moment(exactTime, H12_TIME_FORMAT).isAfter(
            moment(maxTime, H12_TIME_FORMAT),
          )
        );
      }
      if (arrivalStart && arrivalEnd) {
        return (
          moment(arrivalStart, H12_TIME_FORMAT).isBefore(
            moment(minTime, H12_TIME_FORMAT),
          ) ||
          moment(arrivalEnd, H12_TIME_FORMAT).isAfter(
            moment(maxTime, H12_TIME_FORMAT),
          )
        );
      }
      return false;
    },
    [maxTime, minTime],
  );

  const changeExactTime = useCallback(
    (time) => {
      const isOutOfRange = checkIsOutOfRange({ exactTime: time });
      if (isOutOfRange) {
        setIsSelectedTimeOutOfRange(true);
      }
      if (!isOutOfRange) {
        setIsSelectedTimeOutOfRange(false);
      }
      setVisitParams((prevValue) => ({
        ...prevValue,
        exactTimeSlot: time,
      }));
      const californiaCurrentTime = moment()
        .tz(CALIFORNIA_TIME_ZONE)
        .format(H12_TIME_FORMAT);

      const californiaCurrentDateSelected = visitParams?.dates?.some(
        (date) =>
          date.format(ISO_DATE_ONLY_FORMAT) ===
          moment().tz(CALIFORNIA_TIME_ZONE).format(ISO_DATE_ONLY_FORMAT),
      );

      if (californiaCurrentDateSelected) {
        checkPastTimeError({
          teamCurrentTime: californiaCurrentTime,
          visitStartTime: time,
        });
      }
    },
    [
      checkIsOutOfRange,
      checkPastTimeError,
      setIsSelectedTimeOutOfRange,
      setVisitParams,
      visitParams?.dates,
    ],
  );

  const changeArrivalTimeRange = useCallback(
    (data) => {
      const isOutOfRange = checkIsOutOfRange({
        arrivalStart: data.arrivalStart,
        arrivalEnd: data.arrivalEnd,
      });
      if (isOutOfRange) {
        setIsSelectedTimeOutOfRange(true);
      }
      if (!isOutOfRange) {
        setIsSelectedTimeOutOfRange(false);
      }
      setVisitParams((prevValue) => ({ ...prevValue, ...data }));

      const californiaCurrentTime = moment()
        .tz(CALIFORNIA_TIME_ZONE)
        .format(H12_TIME_FORMAT);
      const californiaCurrentDateSelected = visitParams?.dates?.some(
        (date) =>
          date.format(ISO_DATE_ONLY_FORMAT) ===
          moment().tz(CALIFORNIA_TIME_ZONE).format(ISO_DATE_ONLY_FORMAT),
      );
      if (data?.arrivalStart && californiaCurrentDateSelected) {
        checkPastTimeError({
          teamCurrentTime: californiaCurrentTime,
          visitStartTime: moment(data?.arrivalStart, H12_TIME_FORMAT),
        });
      }
    },
    [
      checkIsOutOfRange,
      checkPastTimeError,
      setIsSelectedTimeOutOfRange,
      setVisitParams,
      visitParams?.dates,
    ],
  );

  const changeArrivalTimeType = useCallback(
    (e) => {
      setVisitArrivalTimeType(e.target.value);
      if (isSelectedTimeOutOfRange) {
        setIsSelectedTimeOutOfRange(false);
      }
      if (pastTimeError) {
        setPastTimeError(null);
      }
      if (visitArrivalTimeType === visitArrivalTimeTypesNames.exactTime) {
        setVisitParams((prevValue) => {
          const newData = { ...prevValue };
          delete newData.exactTimeSlot;
          return newData;
        });
      } else {
        setVisitParams((prevValue) => {
          const newData = { ...prevValue };
          delete newData.arrivalStart;
          delete newData.arrivalEnd;
          return newData;
        });
      }
    },
    [
      isSelectedTimeOutOfRange,
      pastTimeError,
      setIsSelectedTimeOutOfRange,
      setPastTimeError,
      setVisitArrivalTimeType,
      setVisitParams,
      visitArrivalTimeType,
    ],
  );

  const changePreVisitNotes = (e) => {
    if (e.target.value.length <= MAX_LONG_TEXT_DESCRIPTION_FIELD) {
      setVisitParams({ ...visitParams, preVisitNotes: e.target.value });
    }
  };

  const onDeleteSkill = (skillName) => {
    const newSkillsList = visitParams.skills.filter(
      (skill) => skill !== skillName,
    );
    setVisitParams({ ...visitParams, skills: newSkillsList });
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Box sx={visitParametersWrapper}>
      <StepTitle />
      <ClientData />
      <Box sx={{ width: '100%', height: '8px' }} />
      <DatesCalendar
        handleSelectDate={changeDays}
        selectedDates={visitParams?.dates}
      />
      <Box sx={{ width: '100%', height: '24px' }} />
      <VisitTypeBlock
        changeDuration={changeDuration}
        changeVisitType={onSelectChange}
        filtersData={filtersData}
        visitParams={visitParams}
      />
      <Box sx={{ width: '100%', height: '24px' }} />
      <ArrivalSlots
        arrivalEnd={visitParams.arrivalEnd}
        arrivalStart={visitParams.arrivalStart}
        changeArrivalTimeRange={changeArrivalTimeRange}
        changeArrivalTimeType={changeArrivalTimeType}
        changeExactTime={changeExactTime}
        exactTimeSlot={visitParams.exactTimeSlot}
        isSelectedTimeOutOfRange={isSelectedTimeOutOfRange}
        pastTimeError={pastTimeError}
        visitArrivalTimeType={visitArrivalTimeType}
      />
      <CustomSelect
        id="genderPreference"
        isLoading={isLoadingSkills}
        options={skillsData?.genders?.map((option) => option.name)}
        placeHolder="Gender Preference"
        selectedValue={visitParams?.genderPreference || ''}
        setValue={onSelectChange}
      />
      <Box sx={{ width: '100%', height: '24px' }} />
      <SelectWithCounter
        disableColored
        filterItems={visitParams?.preferredSkills || []}
        id="preferredSkills"
        isLoading={isLoading}
        isMultiselect
        options={skillsData?.skills?.map((option) => option.name)}
        placeHolder="Skills Preference"
        setFilters={onSelectChange}
        showFiltersCount
      />
      <Collapse in={!!visitParams?.preferredSkills.length}>
        <CustomBadges
          items={
            visitParams?.preferredSkills?.map((skill) => ({
              name: skill,
              value: skill,
            })) || []
          }
          onDelete={onDeleteSkill}
        />
      </Collapse>
      <Box sx={{ width: '100%', height: '24px' }} />
      <SelectWithCounter
        disableColored
        filterItems={visitParams?.preferredLanguages || []}
        id="preferredLanguages"
        isLoading={isLoading}
        isMultiselect
        options={skillsData?.language?.map((option) => option.name)}
        placeHolder="Language Preference"
        setFilters={onSelectChange}
        showFiltersCount
      />
      <Collapse in={!!visitParams?.preferredLanguages.length}>
        <CustomBadges
          items={
            visitParams?.preferredLanguages?.map((skill) => ({
              name: skill,
              value: skill,
            })) || []
          }
          onDelete={onDeleteSkill}
        />
      </Collapse>
      <Box sx={{ width: '100%', height: '12px' }} />
      <FormControlLabel
        sx={{ marginBottom: '12px', maxWidth: '200px' }}
        control={<Checkbox checked={showVisitNotes} />}
        onChange={() => setShowVisitNotes(!showVisitNotes)}
        label="Additional Visit Details"
      />
      {showVisitNotes && (
        <MultilineInput
          changeDetails={changePreVisitNotes}
          id="preVisitNotes"
          label="Additional Visit Details"
          maxLength={MAX_LONG_TEXT_DESCRIPTION_FIELD}
          value={visitParams.preVisitNotes}
          showHelperText
        />
      )}
      <Box sx={{ width: '100%', height: '15px' }} />
    </Box>
  );
}

VisitParameters.propTypes = {
  isSelectedTimeOutOfRange: bool,
  pastTimeError: string,
  setIsSelectedTimeOutOfRange: func,
  setPastTimeError: func,
  setVisitParams: func,
  visitParams: instanceOf(Object),
};

export default React.memo(VisitParameters);
