import React, { lazy } from 'react';
import { Navigate } from 'react-router';
import DASHBOARD_NAVIGATION from './constants';

const Dashboard = lazy(() => import('../pages/Dashboard'));
const GeneralView = lazy(() => import('../pages/General'));
const NotFound = lazy(() => import('../pages/NotFound'));

const routes = (isLoggedIn) => [
  {
    path: '/',
    element: isLoggedIn ? <Dashboard /> : <GeneralView />,
    children: isLoggedIn
      ? DASHBOARD_NAVIGATION
      : [
          { path: 'auth', element: <GeneralView /> },
          { path: '/', element: <Navigate to="/auth" /> },
        ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export default routes;
