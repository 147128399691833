import { Box, Button } from '@mui/material';
import { bool, func, instanceOf } from 'prop-types';
import React from 'react';
import { WORKING_WEEK_LENGTH } from '../../../../../../../../../../shared/constants';
import HorizontalItemsSelector from '../../../../../../../../../../components/HorizontalItemsSelector';
import { checkAvailabilitySchedulePatternWrapperStyles } from '../styles';

export default function CheckAvailabilitySchedulePattern({
  disabledRecheckAvailability = false,
  disabledDays = {},
  filtersData = {},
  handleChangeDays = () => {},
  handleChangeTimeOfDay = () => {},
  isFiltersLoading = false,
  isLoading = false,
  recheckAvailability = () => {},
  visitData = {},
}) {
  return (
    !isFiltersLoading && (
      <Box sx={checkAvailabilitySchedulePatternWrapperStyles}>
        <HorizontalItemsSelector
          disabled={isLoading}
          disabledDays={disabledDays}
          extendingProperties={{ minWidth: '60px' }}
          gap="8px"
          handleChange={handleChangeDays}
          options={filtersData?.weekdays.slice(0, WORKING_WEEK_LENGTH)}
          selectedDays={visitData?.days}
          title="Recurrence Pattern"
        />
        <HorizontalItemsSelector
          disabled={isLoading}
          extendingProperties={{ minWidth: '112px' }}
          gap="8px"
          handleChange={handleChangeTimeOfDay}
          options={filtersData?.timesOfDay?.map(({ name }) => ({
            name,
            value: name,
          }))}
          selectedDays={visitData?.timesOfDay?.map(({ name }) => name)}
          title="Time of Day"
        />
        <Button
          color="primary"
          variant="contained"
          onClick={recheckAvailability}
          className="re-check-availability-button"
          disabled={disabledRecheckAvailability}
          disableElevation
        >
          Recheck availability
        </Button>
      </Box>
    )
  );
}

CheckAvailabilitySchedulePattern.propTypes = {
  disabledRecheckAvailability: bool,
  disabledDays: instanceOf(Object),
  filtersData: instanceOf(Object),
  handleChangeDays: func,
  handleChangeTimeOfDay: func,
  isFiltersLoading: bool,
  isLoading: bool,
  recheckAvailability: func,
  visitData: instanceOf(Object),
};
