import { Box, Typography } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { bool, func, instanceOf } from 'prop-types';
import React, { useMemo } from 'react';
import { getNoData } from '../../../../shared/utils/common';
import FullWidthDialog from '../../../../components/FullWidthDialog';
import Loader from '../../../../components/Loader';
import CustomTable from '../../../../components/Table';
import ShadowVisitsAccordionWrapper from '../../../../components/VisitInfoTypeProvider/components/CheckReschedulingTable/AffectedVisitsAccordion/ShadowVisitsAccordionWrapper';
import { constraintsIconsTypes } from '../../constants';
import ConstraintsAlert from '../ConstraintsAlert';
import AffectedVisitsAccordionWrapper from './AffectedVisitsAccordion/AffectedVisitsAccordionWrapper';
import {
  checkingSchedulingColumns,
  reschedulingVisitColumns,
} from './constants';
import { reschedulingVisitStyle } from './styles';

export default function CheckReschedulingTable({
  closeRescheduling = () => {},
  confirmReschedulingVisit = () => {},
  isReschedulingDataLoading = false,
  openCheckReschedulingDialog = false,
  reschedulingData = {},
}) {
  const apiRef = useGridApiRef();
  const rescheduledVisit = reschedulingData?.rescheduledEvent || {};
  const listOfDates = useMemo(
    () =>
      reschedulingData?.affectedVisits
        ? Object.keys(reschedulingData?.affectedVisits)
        : [],
    [reschedulingData?.affectedVisits],
  );

  const alertKey = useMemo(() => {
    if (reschedulingData?.reschedulingStatus?.status === 'error') {
      return 'error';
    }
    if (reschedulingData?.reschedulingStatus?.status === 'warning') {
      return 'warning';
    }
    const affectedVisitsHasSoftViolations = listOfDates.some((date) => {
      const dateVisits = reschedulingData?.affectedVisits[date];
      return dateVisits.some((visit) => visit?.status?.status === 'warning');
    });
    const affectedVisitsHasHardViolations = listOfDates.some((date) => {
      const dateVisits = reschedulingData?.affectedVisits[date];
      return dateVisits.some((visit) => visit?.status?.status === 'error');
    });
    if (
      reschedulingData?.reschedulingStatus?.status === 'success' &&
      affectedVisitsHasSoftViolations
    ) {
      return 'partiallySuccessfulWarning';
    }
    if (
      reschedulingData?.reschedulingStatus?.status === 'success' &&
      affectedVisitsHasHardViolations
    ) {
      return 'partiallySuccessfulError';
    }
    return 'success';
  }, [
    listOfDates,
    reschedulingData?.affectedVisits,
    reschedulingData?.reschedulingStatus?.status,
  ]);

  return (
    <Box>
      <FullWidthDialog
        cancelButtonName="cancel"
        cancelCallback={closeRescheduling}
        disabledSubmit={
          !reschedulingData ||
          !reschedulingData?.rescheduledEvent?.id ||
          checkingSchedulingColumns[alertKey].type === 'error'
        }
        openDialog={openCheckReschedulingDialog}
        submitButtonName="confirm Rescheduling"
        submitCallback={confirmReschedulingVisit}
        title="Reschedule Visit"
      >
        {isReschedulingDataLoading ? (
          <Loader />
        ) : (
          <Box>
            {!reschedulingData?.rescheduledEvent ||
            !reschedulingData?.rescheduledEvent?.id ? (
              <Typography
                sx={{ margin: '100px auto', textAlign: 'center' }}
                variant="h4"
              >
                {`Data can't be loaded`}
              </Typography>
            ) : (
              <Box sx={{ padding: '30px' }}>
                <ConstraintsAlert
                  alertData={{
                    ...checkingSchedulingColumns[alertKey],
                    reasons:
                      reschedulingData?.reschedulingStatus?.status !== 'error'
                        ? reschedulingData?.reschedulingStatus?.reasons
                        : null,
                  }}
                  iconType={constraintsIconsTypes.circle}
                />
                <Typography sx={{ mb: '20px' }} variant="h4">
                  Rescheduled Visit
                </Typography>
                <CustomTable
                  apiRef={apiRef}
                  columns={reschedulingVisitColumns({ isMainVisit: true })}
                  customStyles={reschedulingVisitStyle(
                    rescheduledVisit?.status?.status,
                  )}
                  customRowClassName="super-app-theme"
                  getRowClassName={(params) =>
                    params.row.shiftedBy ? 'shifted-visit' : ''
                  }
                  headerHeight={44}
                  noRowsOverlay={() =>
                    getNoData({
                      title: 'Have no re-scheduled visits on this day',
                    })
                  }
                  rowHeight={107}
                  rows={[rescheduledVisit]}
                  withoutPagination
                />
                {rescheduledVisit?.status?.status !== 'error' && (
                  <AffectedVisitsAccordionWrapper
                    affectedVisits={reschedulingData?.affectedVisits}
                    listOfDates={listOfDates}
                  />
                )}
                <ShadowVisitsAccordionWrapper
                  shadowVisits={reschedulingData?.nonShadowVisitsAnyMore}
                />
              </Box>
            )}
          </Box>
        )}
      </FullWidthDialog>
    </Box>
  );
}

CheckReschedulingTable.propTypes = {
  closeRescheduling: func,
  confirmReschedulingVisit: func,
  isReschedulingDataLoading: bool,
  openCheckReschedulingDialog: bool,
  reschedulingData: instanceOf(Object),
};
