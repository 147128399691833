import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { Box, Button } from '@mui/material';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { SchedulingEngineContext } from '../../context';
import { customEditButton } from './styles';

export default function SchedulingEngineActions() {
  const { tabSubView } = useParams();

  const {
    isEdit,
    onEdiSchedulingEngine,
    onCloseSchedulingEngine,
    onSaveWeekendDays,
  } = useContext(SchedulingEngineContext);
  return (
    tabSubView === 'current' && (
      <Box>
        {!isEdit ? (
          <Button
            color="secondary"
            onClick={onEdiSchedulingEngine}
            startIcon={<EditTwoToneIcon />}
            sx={customEditButton}
            variant="outlined"
          >
            EDIT
          </Button>
        ) : (
          <Box>
            <Button
              color="secondary"
              variant="outlined"
              onClick={onCloseSchedulingEngine}
              disableElevation
            >
              CANCEL
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={onSaveWeekendDays}
              sx={{ ml: '28px' }}
              disableElevation
            >
              SAVE
            </Button>
          </Box>
        )}
      </Box>
    )
  );
}
