import { Box } from '@mui/material';
import { instanceOf } from 'prop-types';
import React from 'react';
import { DAYS_MAP } from '../../shared/constants';
import { COLORS } from '../../shared/styles/Theme';

function DaysSchedule({ additionalStyles = {}, selectedDays = [] }) {
  return (
    <Box sx={{ display: 'flex', gap: '8px' }}>
      {DAYS_MAP?.map(({ name }) => (
        <Box
          key={name}
          sx={{
            display: 'flex',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor:
              selectedDays?.indexOf(name) !== -1
                ? COLORS.brown[600]
                : '#eff2f9',
            color:
              selectedDays?.indexOf(name) !== -1 ? '#ffffff' : COLORS.blue[500],
            fontWeight: 600,
            fontSize: '13px',
            lineHeight: '16px',
            textAlign: 'center',
            letterSpacing: '0.03em',
            ...additionalStyles,
          }}
        >
          {name.slice(0, 2).toUpperCase()}
        </Box>
      ))}
    </Box>
  );
}

DaysSchedule.propTypes = {
  additionalStyles: instanceOf(Object),
  selectedDays: instanceOf(Array),
};

export default React.memo(DaysSchedule);
