import { Box } from '@mui/material';
import { instanceOf } from 'prop-types';
import React from 'react';
import styles from './style.module.css';

const convertTitles = (str) =>
  str
    ?.split(/(?=[A-Z])/)
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(' ');

const getHistoryList = (arr) => (
  <ul className={styles.bulletList}>
    {arr?.map((item, index) => {
      const key = `${item?.operation}-${item?.propertyName}-${item?.id}${index}`;
      if (item?.operation === 'update') {
        return (
          <li
            key={key}
            className={
              item.to === ''
                ? `${styles.bulletListItem} ${styles.removedType}`
                : styles.bulletListItem
            }
          >
            <span className={styles.boldWeight}>
              {convertTitles(item?.propertyName)}
            </span>
            {item.to !== '' ? (
              <span>
                is
                <span className={styles.boldWeight}>{item.visitType}</span>
                changed from
                <span className={styles.oldTime}>
                  {item?.from?.toString()}
                </span>{' '}
                to
                <span className={styles.newTime}> {item?.to?.toString()}</span>
              </span>
            ) : (
              <span> was removed</span>
            )}
          </li>
        );
      }
      if (item?.operation === 'remove') {
        return (
          <li
            key={key}
            className={`${styles.bulletListItem} ${styles.removedType}`}
          >
            <span className={styles.boldWeight}>
              {item.secondName && item.secondName}
              {item.secondName && "'s"} {convertTitles(item?.propertyName)}
            </span>
            ({item.from}) was removed
          </li>
        );
      }
      if (item?.operation === 'insert') {
        return (
          <li
            key={key}
            className={`${styles.bulletListItem} ${styles.addedType}`}
          >
            <span className={styles.boldWeight}>
              {item.secondName && item.secondName}
              {item.secondName && "'s"} {convertTitles(item?.propertyName)}
            </span>
            ({item.to}) was added
          </li>
        );
      }
      return false;
    })}
  </ul>
);

export default function HistoryBulletList({ changes = [] }) {
  return <Box sx={{ paddingLeft: '20px' }}>{getHistoryList(changes)}</Box>;
}

HistoryBulletList.propTypes = {
  changes: instanceOf(Array),
};
