import { Box, Typography } from '@mui/material';
import LunchBreakIcon from '../../img/icons/lunch-icon.svg';
import React from 'react';
import { COLORS } from '../../shared/styles/Theme';

export const calendarCellHeightMultiplier = 2.65;

export const eventComponent = (event) => {
  if (event.title === 'arrival') {
    return (
      <Box
        sx={{
          height: '100%',
          fontSize: '13px',
          fontWeight: 400,
          border: `2px dashed ${COLORS.blue[700]}`,
          background:
            'linear-gradient(0deg, rgba(43, 119, 255, 0.2), rgba(43, 119, 255, 0.2));',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        Arrival Time Window
      </Box>
    );
  }
  if (event.title === 'Lunch break') {
    return (
      <Box
        sx={{
          border: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          height: '100%',
          padding: '20px',
          cursor: 'default',
        }}
      >
        <img src={LunchBreakIcon} alt="lunchbreak" />
        <Typography
          sx={{
            ml: '10px',
            fontSize: '15px',
            fontWeight: 400,
            color: COLORS.blue[900],
          }}
        >
          {event.duration} min
        </Typography>
      </Box>
    );
  }
  if (event.title === 'arrivalTime') {
    return (
      <Box
        sx={{
          height: '100%',
          fontSize: '13px',
          fontWeight: 400,
          border: `2px dashed ${COLORS.blue[700]}`,
          borderBottom: 'none',
          background: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    );
  }
  return '';
};
