import moment from 'moment/moment';
import {
  CALIFORNIA_TIME_ZONE,
  CONVERT_TO_UTC_FORMAT,
  ISO_DATE_ONLY_FORMAT,
} from '../../../../shared/constants';
import { convertDateToUTC } from '../../../../shared/utils/common';

export const parseDataToUpdatesTasksForVerification = (visitsData) => [
  {
    ...visitsData,
    team: visitsData.region,
    actualTimeEnd: convertDateToUTC({
      date: `${visitsData?.date} ${visitsData?.actualTimeEnd}`,
      dateFormat: CONVERT_TO_UTC_FORMAT,
      dateTimeZone: CALIFORNIA_TIME_ZONE,
    }),
    actualTimeStart: convertDateToUTC({
      date: `${visitsData?.date} ${visitsData?.actualTimeStart}`,
      dateFormat: CONVERT_TO_UTC_FORMAT,
      dateTimeZone: CALIFORNIA_TIME_ZONE,
    }),
    arrivalEnd: convertDateToUTC({
      date: `${visitsData?.date} ${visitsData?.arrivalEnd}`,
      dateFormat: CONVERT_TO_UTC_FORMAT,
      dateTimeZone: CALIFORNIA_TIME_ZONE,
    }),
    arrivalStart: convertDateToUTC({
      date: `${visitsData?.date} ${visitsData?.arrivalStart}`,
      dateFormat: CONVERT_TO_UTC_FORMAT,
      dateTimeZone: CALIFORNIA_TIME_ZONE,
    }),
    arrivalTime: convertDateToUTC({
      date: `${visitsData?.date} ${visitsData?.arrivalTime}`,
      dateFormat: CONVERT_TO_UTC_FORMAT,
      dateTimeZone: CALIFORNIA_TIME_ZONE,
    }),
  },
];

export const parseUpdatedChosenVisitsData = ({ chosenVisits, date }) =>
  chosenVisits?.map((visit) => ({
    ...visit,
    date: moment(date).format(ISO_DATE_ONLY_FORMAT),
    team: visit?.region,
    actualTimeEnd: convertDateToUTC({
      date: `${visit?.date} ${visit?.actualTimeEnd}`,
      dateFormat: CONVERT_TO_UTC_FORMAT,
      dateTimeZone: CALIFORNIA_TIME_ZONE,
    }),
    actualTimeStart: convertDateToUTC({
      date: `${visit?.date} ${visit?.actualTimeStart}`,
      dateFormat: CONVERT_TO_UTC_FORMAT,
      dateTimeZone: CALIFORNIA_TIME_ZONE,
    }),
    arrivalEnd: convertDateToUTC({
      date: `${visit?.date} ${visit?.arrivalEnd}`,
      dateFormat: CONVERT_TO_UTC_FORMAT,
      dateTimeZone: CALIFORNIA_TIME_ZONE,
    }),
    arrivalStart: convertDateToUTC({
      date: `${visit?.date} ${visit?.arrivalStart}`,
      dateFormat: CONVERT_TO_UTC_FORMAT,
      dateTimeZone: CALIFORNIA_TIME_ZONE,
    }),
    arrivalTime: convertDateToUTC({
      date: `${visit?.date} ${visit?.arrivalTime}`,
      dateFormat: CONVERT_TO_UTC_FORMAT,
      dateTimeZone: CALIFORNIA_TIME_ZONE,
    }),
    status: 'Verified',
  }));

export const parseUpdatedVisitsNotesData = ({
  completedVisits,
  preVisitNotes,
  rowId,
  visitType,
}) => {
  const saveVisitNotes = completedVisits.filter(
    (ev) => ev.id === rowId.slice(0, -1),
  );
  return saveVisitNotes?.map((event) => ({
    ...event,
    team: event.region,
    [visitType]: preVisitNotes[rowId][visitType],
    actualTimeEnd: convertDateToUTC({
      date: `${event?.date} ${event?.actualTimeEnd}`,
      dateFormat: CONVERT_TO_UTC_FORMAT,
      dateTimeZone: CALIFORNIA_TIME_ZONE,
    }),
    actualTimeStart: convertDateToUTC({
      date: `${event?.date} ${event?.actualTimeStart}`,
      dateFormat: CONVERT_TO_UTC_FORMAT,
      dateTimeZone: CALIFORNIA_TIME_ZONE,
    }),
    arrivalEnd: convertDateToUTC({
      date: `${event?.date} ${event?.arrivalEnd}`,
      dateFormat: CONVERT_TO_UTC_FORMAT,
      dateTimeZone: CALIFORNIA_TIME_ZONE,
    }),
    arrivalStart: convertDateToUTC({
      date: `${event?.date} ${event?.arrivalStart}`,
      dateFormat: CONVERT_TO_UTC_FORMAT,
      dateTimeZone: CALIFORNIA_TIME_ZONE,
    }),
    arrivalTime: convertDateToUTC({
      date: `${event?.date} ${event?.arrivalTime}`,
      dateFormat: CONVERT_TO_UTC_FORMAT,
      dateTimeZone: CALIFORNIA_TIME_ZONE,
    }),
  }));
};
