import { Box, Typography } from '@mui/material';
import { string } from 'prop-types';
import React from 'react';

export default function JobIdInfo({ jobId = '' }) {
  return (
    <Box>
      <Typography variant="body2" noWrap>
        Job ID
      </Typography>
      {jobId ? (
        <Box sx={{ display: 'flex', alignItems: 'start' }}>
          <Typography variant="body1" noWrap>
            {jobId}
          </Typography>
        </Box>
      ) : (
        <Typography variant="body1">N /A</Typography>
      )}
    </Box>
  );
}

JobIdInfo.propTypes = {
  jobId: string,
};
