import { COLORS } from '../../../../../../../../shared/styles/Theme';

export const effectiveDateSelectCustomAlert = {
  display: 'flex',
  alignItems: 'center',
  borderRadius: '10px',
  padding: '12px',
  mb: '20px',
  '& .MuiAlert-icon': {
    padding: 0,
  },
  '& .MuiAlert-message': {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    color: COLORS.yellow[700],
    padding: 0,
  },
};
