import { COLORS } from '../../../../../shared/styles/Theme';

export const customFilterStyles = {
  width: '120px',
};
export const filterStyles = {
  display: 'flex',
  gap: '20px',
  alignItems: 'center',
  '& .MuiOutlinedInput-root, & .MuiTextField-root': {
    minWidth: '240px',
  },
};

export const datePickerStyles = {
  color: COLORS.blue[700],
  fontSize: '14px',
  fontWeight: 600,
  alignSelf: 'center',
  textTransform: 'capitalize',
  border: 'none',
  padding: '5px 0',
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

export const datesDividerStyles = {
  color: COLORS.blue[700],
  padding: '0 5px',
  fontSize: '14px',
  lineHeight: '20px',
};
