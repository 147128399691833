import { COLORS } from '../../../../../../../../../../shared/styles/Theme';

export const recurrentAvailabilityWrapper = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#ecebf5',
  border: `1px solid ${COLORS.blue[200]}`,
  borderRadius: '8px',
  overflow: 'hidden',
};

export const getRecurrentAvailabilityContentWrapperStyles = (
  visitSummaryHeight,
) => ({
  overflow: 'auto',
  maxHeight: `calc(100vh - 350px - ${visitSummaryHeight}px)`,
  '&::-webkit-scrollbar': {
    width: '0.4em',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
});
