import { Box, Typography } from '@mui/material';
import React from 'react';
import { tasksTypeIcons } from '../../../../../../../shared/constants';
import CustomTooltip from '../../../../../../../components/CustomTooltip';
import StatusAction from './StatusAction';
import TaskDetails from './TaskDetails';

export const getTasksColumns = (tasksType) => [
  {
    field: 'name',
    headerName: 'Task',
    minWidth: 250,
    flex: 1,
    renderCell: ({ row }) => (
      <CustomTooltip title={row?.name}>
        <Typography
          sx={{
            fontSize: '20px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
            wordBreak: 'break-word',
            lineHeight: '24px',
          }}
          variant="h4"
        >
          {row.name}
        </Typography>
      </CustomTooltip>
    ),
    sortable: false,
  },
  {
    field: 'category',
    headerName: 'Category',
    minWidth: 150,
    sortable: false,
  },
  {
    field: 'details',
    headerName: 'Details',
    minWidth: 300,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => <TaskDetails row={row} />,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: ({ row, value }) => (
      <StatusAction id={row.id} status={value} tasksType={tasksType} />
    ),
  },
  {
    field: 'isCustom',
    headerName: 'Task Type',
    minWidth: 150,
    renderCell: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <CustomTooltip
          title={row?.isCustom ? 'Custom task' : 'Care Program task'}
        >
          <Box
            component="img"
            src={tasksTypeIcons[row?.isCustom ? 'custom' : 'common']}
            sx={{ height: '20px', mt: '4px', mr: '20px' }}
            alt="img"
          />
        </CustomTooltip>
      </Box>
    ),
    sortable: false,
  },
];
