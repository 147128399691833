import { filterTasks } from '../../pages/ClientDetails/views/CarePlan/utils';
import { sortArray } from '../../shared/utils/common';

export const filterCarePlanTasksResponse = (options) => {
  const { response, carePlanFilters, sortOption } = options;
  if (!response?.tasks.length) {
    return { tasks: response?.tasks, version: response.version };
  }
  const filteredTasks = carePlanFilters
    ? filterTasks(carePlanFilters, response?.tasks)
    : response?.tasks;
  if (sortOption.field === 'levelsOfAssistance') {
    return {
      tasks: sortArray(
        filteredTasks,
        'levelOfAssistance',
        sortOption.sort === 'desc',
      ),
      version: response.version,
    };
  }
  return { tasks: filteredTasks, version: response.version };
};
