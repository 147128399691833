export const visitTasksManagementWrapper = {
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  mt: '40px',
};

export const tasksManagementTableStyles = (isEmpty) => [
  {
    '& .MuiDataGrid-virtualScroller': {
      maxHeight: '100%',
      padding: isEmpty ? '32px 0' : '1px',
    },
    '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-cell': {
      padding: '26px 21px 24px 21px',
    },
    '& .MuiDataGrid-footerContainer': {
      margin: 0,
    },
  },
];

export const dialogPaperStyles = {
  width: '100%',
  maxWidth: '540px',
  borderRadius: '10px',
  minHeight: '200px',
  padding: '20px',
  paddingBottom: '30px',
};
