import {
  useCancelCreatingTimeOffRequestMutation,
  useCheckPossibilityAndCreateTimeOffRequestDraftMutation,
  useCreateTimeOffRequestMutation,
} from '../../../../../../api/TimeOffRequest/api';
import moment from 'moment';
import { TimeOffRequestsContext } from '../../../../../../pages/CaregiverDetails/context';
import { instanceOf } from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router';
import {
  CALIFORNIA_TIME_ZONE,
  FULL_DATE_H24_STANDARD_FORMAT,
} from '../../../../../../shared/constants';
import { convertDateToUTC } from '../../../../../../shared/utils/common';
import AlertContext from '../../../../../../components/Alert';
import Badge from '../../../../../../components/Badge';
import CustomDialog from '../../../../../../components/Dialog';
import TimeOffRequestPossibility from '../../../../../../components/TimeOffRequestPossibility';
import { timeOffRequestTypes } from '../../constants';
import {
  checkIsTimeOffRequestChanged,
  checkRequiredFieldsFilled,
  formingDataForTimeOffRequestCreation,
  getBadgeColorScheme,
} from '../../utils';
import SettingsView from './components/SettingsView';
import { timeOffRequestInitialState } from './constants';

export default function CreatePtoAndCallouts({ caregiverData = {} }) {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const [
    checkPossibilityAndCreateTimeOffRequestDraft,
    {
      data: possibilityData,
      isLoading: isUpdating,
      error: checkPossibilityAndCreateTimeOffRequestError,
    },
  ] = useCheckPossibilityAndCreateTimeOffRequestDraftMutation();
  const [createTimeOffRequest, { createTimeOffRequestError }] =
    useCreateTimeOffRequestMutation();
  const [cancelCreatingTimeOffRequest, { cancelCreatingTimeOffRequestError }] =
    useCancelCreatingTimeOffRequestMutation();

  useEffect(() => {
    if (
      checkPossibilityAndCreateTimeOffRequestError ||
      createTimeOffRequestError ||
      cancelCreatingTimeOffRequestError
    ) {
      const errorData =
        checkPossibilityAndCreateTimeOffRequestError ||
        createTimeOffRequestError ||
        cancelCreatingTimeOffRequestError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    cancelCreatingTimeOffRequestError,
    checkPossibilityAndCreateTimeOffRequestError,
    createTimeOffRequestError,
    setAlert,
  ]);

  const {
    closeAddRequestDialog,
    editedRequestData,
    creatingRequestInitialData,
    openAddRequestDialogType,
    showCheckPossibilityView,
  } = useContext(TimeOffRequestsContext);
  const [requestData, setRequestData] = useState(
    editedRequestData || timeOffRequestInitialState,
  );
  const [error, setError] = useState(null);

  const isDataChanged = useMemo(
    () => checkIsTimeOffRequestChanged(creatingRequestInitialData, requestData),
    [creatingRequestInitialData, requestData],
  );

  const isRequiredFieldsFilled = useMemo(
    () => checkRequiredFieldsFilled(requestData),
    [requestData],
  );

  const closeDialog = useCallback(() => {
    closeAddRequestDialog();
    setRequestData(timeOffRequestInitialState);
    setError(null);
  }, [closeAddRequestDialog]);

  const confirmRequest = () => {
    createTimeOffRequest({
      requestId: possibilityData?.requestId,
    });
    closeDialog();
  };
  const checkTimeOffRequestPossibility = () => {
    const parsedData = formingDataForTimeOffRequestCreation(requestData);
    const utcStartDate = convertDateToUTC({
      date: parsedData.requestStart,
      dateFormat: FULL_DATE_H24_STANDARD_FORMAT,
      dateTimeZone: CALIFORNIA_TIME_ZONE,
    });
    const utcEndDate = convertDateToUTC({
      date: parsedData.requestEnd,
      dateFormat: FULL_DATE_H24_STANDARD_FORMAT,
      dateTimeZone: CALIFORNIA_TIME_ZONE,
    });
    checkPossibilityAndCreateTimeOffRequestDraft({
      caregiverId: id,
      payload: {
        ...parsedData,
        requestStart: utcStartDate,
        requestEnd: utcEndDate,
      },
    });
    showCheckPossibilityView();
  };

  const isStartDateExpired = useMemo(
    () => moment(requestData?.requestDateStart).isSameOrBefore(moment(), 'day'),
    [requestData?.requestDateStart],
  );

  const cancelCreatingTimeOffRequestCreating = () => {
    cancelCreatingTimeOffRequest(possibilityData?.requestId);
    closeAddRequestDialog();
  };
  return (
    <>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={closeDialog}
        disableOnCloseByClickOutside
        disabledSubmit={
          !isDataChanged ||
          !isRequiredFieldsFilled ||
          !!error ||
          isStartDateExpired
        }
        isLoading={isUpdating}
        openDialog={
          openAddRequestDialogType === timeOffRequestTypes.settingParams
        }
        submitButtonName="Check time-off possibility"
        submitCallback={checkTimeOffRequestPossibility}
        title={
          requestData?.id ? 'Edit Time-Off Request' : 'Add Time-Off Request'
        }
        titleBadge={
          requestData?.status ? (
            <Badge
              colorScheme={getBadgeColorScheme(requestData?.status)}
              label={requestData?.status}
            />
          ) : null
        }
      >
        <SettingsView
          error={error}
          isStartDateExpired={isStartDateExpired}
          requestData={requestData}
          setError={setError}
          setRequestData={setRequestData}
        />
      </CustomDialog>
      <TimeOffRequestPossibility
        caregiverData={caregiverData}
        closeDialog={cancelCreatingTimeOffRequestCreating}
        confirmChanges={confirmRequest}
        disabledSubmit={
          !!checkPossibilityAndCreateTimeOffRequestError?.data?.error ||
          isUpdating
        }
        dataError={checkPossibilityAndCreateTimeOffRequestError?.data?.error}
        isLoading={isUpdating}
        possibilityData={possibilityData?.rescheduledEvents}
        responseData={requestData}
        shadowVisits={possibilityData?.nonShadowVisitsAnyMore}
        showDialog={
          openAddRequestDialogType === timeOffRequestTypes.checkPossibility
        }
      />
    </>
  );
}

CreatePtoAndCallouts.propTypes = {
  caregiverData: instanceOf(Object),
};
