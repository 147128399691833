import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetAllTimeOffRequestsQuery } from '../../../../api/Scheduler/api';
import moment from 'moment';
import { func, instanceOf } from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SEARCH_INPUT_DEBOUNCE_DELAY } from '../../../../shared/constants';
import { useDebouncedCallback } from '../../../../shared/hooks/useDebounce';
import { getNoData } from '../../../../shared/utils/common';
import AlertContext from '../../../../components/Alert';
import CustomTable from '../../../../components/Table';
import FiltersPanel from './components/FiltersPanel';
import {
  DEFAULT_SORTING_MODEL,
  INITIAL_FILTERS,
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  TIME_OFF_REQUESTS_STATUSES,
  columns,
} from './constants';
import { SchedulerTimeOffRequestsContext } from './context';
import { customTableStyles } from './styles';

export default function PtoAndCallouts({
  teamsArray = [],
  teamsFilter = [],
  setTeamFilter = () => {},
}) {
  const apiRef = useGridApiRef();
  const { setAlert } = useContext(AlertContext);

  const [sortOption, setSortOption] = useState(DEFAULT_SORTING_MODEL);
  const [timeOffRequestsFilters, setTimeOffRequestsFilters] =
    useState(INITIAL_FILTERS);
  const [statusChangingLoading, setStatusChangingLoading] = useState(false);

  useEffect(() => {
    setTimeOffRequestsFilters((prevValue) => ({
      ...prevValue,
      status: TIME_OFF_REQUESTS_STATUSES?.map((status) => status),
      teams: teamsFilter?.teams?.map((team) => team?.name),
      datesRange: {
        startDate: moment(),
        endDate: moment().add(1, 'months'),
      },
    }));
  }, [setTimeOffRequestsFilters, teamsFilter]);
  const {
    data: timeOffRequestsList,
    isLoading,
    error,
  } = useGetAllTimeOffRequestsQuery(
    {
      timeOffRequestsFilters,
      isAllTeams: timeOffRequestsFilters?.teams?.length === teamsFilter?.length,
    },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);

  const debouncedSearch = useDebouncedCallback((e) => {
    setTimeOffRequestsFilters({
      ...timeOffRequestsFilters,
      [e.target.name]: e.target.value,
    });
  }, SEARCH_INPUT_DEBOUNCE_DELAY);

  const memoizedProviderValue = useMemo(
    () => ({
      setStatusChangingLoading,
    }),
    [setStatusChangingLoading],
  );
  const updateSorting = useCallback((item) => setSortOption(item[0]), []);
  return (
    <SchedulerTimeOffRequestsContext.Provider value={memoizedProviderValue}>
      <Box>
        <FiltersPanel
          debouncedSearch={debouncedSearch}
          filterData={timeOffRequestsFilters}
          setFilterData={setTimeOffRequestsFilters}
          teamsArray={teamsArray}
          teamsFilter={teamsFilter}
          setTeamFilter={setTeamFilter}
        />
        <Box sx={{ padding: '0 40px' }}>
          <CustomTable
            apiRef={apiRef}
            columns={columns}
            customStyles={customTableStyles}
            isLoading={isLoading || statusChangingLoading}
            headerHeight={44}
            noRowsOverlay={() =>
              getNoData({ title: 'No PTO or Callouts found' })
            }
            pageSize={PAGE_SIZE}
            setSortModelState={updateSorting}
            sortModel={[sortOption]}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            rows={timeOffRequestsList}
          />
        </Box>
      </Box>
    </SchedulerTimeOffRequestsContext.Provider>
  );
}

PtoAndCallouts.propTypes = {
  teamsArray: instanceOf(Array),
  teamsFilter: instanceOf(Array),
  setTeamFilter: func,
};
