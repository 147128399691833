import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import moment from 'moment';
import { bool, func, instanceOf, string } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { H12_TIME_FORMAT } from '../../../../../shared/constants';
import ButtonsGroup from '../../../../../components/ButtonsGroup';
import FullWidthDialog from '../../../../../components/FullWidthDialog';
import CustomTable from '../../../../../components/Table';
import { getEditAvailabilityColumns } from '../constants';
import EditAvailabilityHeader from './EditAvailabilityHeader';

function EditAvailability({
  availabilityData = [],
  availabilityScheduleView = '',
  cancelEditMode = () => {},
  closeDialog = () => {},
  dbData = [],
  disabledDays = {},
  isLoading = true,
  openEditMode = false,
  resetToInitialView = () => {},
  setAvailabilityData = () => {},
  setAvailabilityScheduleView = () => {},
  setShowMultiplyMode = () => {},
  showMultiplyMode = false,
  startDate = '',
  submitCallback = () => {},
  viewEffectiveDate = [],
}) {
  const apiRef = useGridApiRef();
  const [checkedRows, setCheckedRows] = useState([]);
  const sortedAvailabilityData = [...availabilityData].sort(
    (a, b) => moment(a.effectiveDate) - moment(b.effectiveDate),
  );
  useEffect(() => {
    if (availabilityData[availabilityScheduleView]?.days) {
      setCheckedRows(
        availabilityData[availabilityScheduleView]?.days
          .filter((row) => row.startTime !== null)
          .map((row) => row.id),
      );
    }
  }, [availabilityData, availabilityScheduleView]);

  const updateScheduleTime = useCallback(
    ({ formatTimeToString, rowId, timeKey }) => {
      setAvailabilityData((prevData) => {
        const availabilityPrevData = [...prevData];
        availabilityPrevData[availabilityScheduleView] = {
          ...availabilityPrevData[availabilityScheduleView],
          days: availabilityPrevData[availabilityScheduleView]?.days?.map(
            (row) =>
              row.id === rowId
                ? { ...row, [timeKey]: formatTimeToString }
                : row,
          ),
        };
        return availabilityPrevData;
      });
    },
    [availabilityScheduleView, setAvailabilityData],
  );

  const updateAllSchedule = useCallback(
    (schedule) => {
      setAvailabilityData((prevData) => {
        const availabilityPrevData = [...prevData];
        availabilityPrevData[availabilityScheduleView] = {
          ...availabilityPrevData[availabilityScheduleView],
          days: schedule,
        };
        return availabilityPrevData;
      });
    },
    [availabilityScheduleView, setAvailabilityData],
  );

  const handleStartTimeChange = useCallback(
    (rowId, newValue) => {
      const formatTimeToString = moment(newValue).format(H12_TIME_FORMAT);

      updateScheduleTime({ formatTimeToString, rowId, timeKey: 'startTime' });
    },
    [updateScheduleTime],
  );

  const handleEndTimeChange = useCallback(
    (rowId, newValue) => {
      const formatTimeToString = moment(newValue).format(H12_TIME_FORMAT);
      const startTime = availabilityData[availabilityScheduleView]?.days?.find(
        (row) => row.id === rowId,
      )?.startTime;

      const selectedTime = moment(newValue, H12_TIME_FORMAT);
      const start = moment(startTime, H12_TIME_FORMAT);

      if (startTime && selectedTime.isSameOrAfter(start)) {
        updateScheduleTime({ formatTimeToString, rowId, timeKey: 'endTime' });
      }
    },
    [availabilityData, availabilityScheduleView, updateScheduleTime],
  );

  const handleCheckboxChange = useCallback(
    (rowId) => {
      setAvailabilityData((prevData) => {
        const availabilityPrevData = [...prevData];
        availabilityPrevData[availabilityScheduleView] = {
          ...availabilityPrevData[availabilityScheduleView],
          days: availabilityPrevData[availabilityScheduleView]?.days?.map(
            (row) => {
              if (row.id !== rowId) {
                return row;
              }
              if (row.startTime === null && row.endTime === null) {
                const previousData = dbData[
                  availabilityScheduleView
                ]?.days.find((prevRow) => prevRow.id === rowId);

                return previousData && previousData.startTime !== null
                  ? {
                      ...row,
                      startTime: previousData.startTime,
                      endTime: previousData.endTime,
                    }
                  : {
                      ...row,
                      startTime: '8:00 AM',
                      endTime: '6:00 PM',
                    };
              }
              return {
                ...row,
                startTime: null,
                endTime: null,
              };
            },
          ),
        };
        return availabilityPrevData;
      });
    },
    [availabilityScheduleView, dbData, setAvailabilityData],
  );
  const isPastAvailability =
    availabilityData.length > 1 && Number(availabilityScheduleView) === 0;
  const columns = useMemo(
    () =>
      getEditAvailabilityColumns({
        checkedRows,
        handleCheckboxChange,
        handleStartTimeChange,
        handleEndTimeChange,
        startDate,
        isPastAvailability,
        disabledDays,
      }),
    [
      checkedRows,
      handleCheckboxChange,
      handleStartTimeChange,
      handleEndTimeChange,
      startDate,
      isPastAvailability,
      disabledDays,
    ],
  );
  const isScheduleChanged = useMemo(() => {
    const initialScheduling = dbData[1]?.days ? dbData[1] : dbData[0];
    const prevAvailability = initialScheduling?.days?.reduce(
      (prevString, item) => `${prevString}${item.startTime}-${item.endTime}`,
      '',
    );
    const newAvailability = availabilityData[1]?.days?.reduce(
      (nextString, item) => `${nextString}${item.startTime}-${item.endTime}`,
      '',
    );
    return (
      prevAvailability !== newAvailability ||
      !availabilityData[0]?.effectiveDate
    );
  }, [availabilityData, dbData]);
  return (
    <FullWidthDialog
      cancelButtonName="cancel"
      cancelCallback={cancelEditMode}
      backButtonCallback={cancelEditMode}
      disabledSubmit={!isScheduleChanged}
      submitButtonName="Check Scheduling Updates"
      submitCallback={submitCallback}
      title="Edit Mode"
      openDialog={openEditMode}
      disableAllActions={isLoading}
    >
      <Box sx={{ padding: '30px 40px' }}>
        <EditAvailabilityHeader
          closeDialog={closeDialog}
          disabledDays={disabledDays}
          disabledMultipleMode={isPastAvailability}
          isLoading={isLoading}
          resetToInitialView={resetToInitialView}
          setShowMultiplyMode={setShowMultiplyMode}
          showMultiplyMode={showMultiplyMode}
          startDate={startDate}
          submitAvailability={updateAllSchedule}
          weekdays={sortedAvailabilityData[availabilityScheduleView]?.days}
        />
        <Box sx={{ mb: '30px' }}>
          {sortedAvailabilityData?.length === 2 && (
            <ButtonsGroup
              buttons={viewEffectiveDate}
              changeSelected={({ target }) =>
                target.name ? setAvailabilityScheduleView(target.name) : {}
              }
              selected={availabilityScheduleView}
              isChangedView
              disabled={isLoading}
            />
          )}
        </Box>
        <CustomTable
          apiRef={apiRef}
          columns={columns}
          headerHeight={44}
          isLoading={isLoading}
          rowHeight={80}
          rows={sortedAvailabilityData[availabilityScheduleView]?.days}
          withoutPagination
        />
      </Box>
    </FullWidthDialog>
  );
}

EditAvailability.propTypes = {
  availabilityData: instanceOf(Array),
  availabilityScheduleView: string,
  cancelEditMode: func,
  closeDialog: func,
  dbData: instanceOf(Array),
  disabledDays: instanceOf(Object),
  isLoading: bool,
  openEditMode: bool,
  resetToInitialView: func,
  setAvailabilityData: func,
  setAvailabilityScheduleView: func,
  setShowMultiplyMode: func,
  showMultiplyMode: bool,
  startDate: string,
  submitCallback: func,
  viewEffectiveDate: instanceOf(Array),
};

export default React.memo(EditAvailability);
