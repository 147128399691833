import { useGridApiRef } from '@mui/x-data-grid';
import { instanceOf } from 'prop-types';
import React from 'react';
import { getNoData } from '../../../../../../../../shared/utils/common';
import CustomTable from '../../../../../../../../components/Table';
import { deleteShadowVisitsColumns } from './constants';
import { customVisitsTableStyles } from './styles';

export default function ShadowVisitsTable({ rows = [] }) {
  const apiRef = useGridApiRef();
  return (
    <CustomTable
      apiRef={apiRef}
      columns={deleteShadowVisitsColumns}
      customStyles={customVisitsTableStyles}
      customRowClassName="super-app-theme"
      getRowClassName={(params) =>
        params.row.shiftedBy ? 'shifted-visit' : ''
      }
      noRowsOverlay={() =>
        getNoData({
          title: 'There are no deleted shadow visits for this day ',
        })
      }
      headerHeight={44}
      rowHeight={107}
      rows={rows}
      withoutPagination
    />
  );
}

ShadowVisitsTable.propTypes = {
  rows: instanceOf(Array),
};
