import { Box, Button } from '@mui/material';
import { bool, func } from 'prop-types';
import React from 'react';
import { outlinedButtonStyles, setupActionsWrapperStyles } from './styles';

export default function SetupActions({
  disableCheckAvailability = false,
  hasBeenRechecked = false,
  onCancel = () => {},
  recheckAvailability = () => {},
}) {
  return (
    <Box sx={setupActionsWrapperStyles}>
      <Button
        color="secondary"
        variant="outlined"
        onClick={onCancel}
        disableElevation
        sx={outlinedButtonStyles}
      >
        Cancel
      </Button>
      {!hasBeenRechecked && (
        <Button
          color="primary"
          disabled={disableCheckAvailability}
          variant="contained"
          onClick={recheckAvailability}
          disableElevation
        >
          Check availability
        </Button>
      )}
    </Box>
  );
}

SetupActions.propTypes = {
  disableCheckAvailability: bool,
  hasBeenRechecked: bool,
  onCancel: func,
  recheckAvailability: func,
};
