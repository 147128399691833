import { COLORS } from '../../../../../../shared/styles/Theme';

export const teamColorStyles = (row) => ({
  background: row?.color?.color,
  width: '90px',
  height: '30px',
  borderRadius: '6px',
  mr: '6px',
});
export const teamFontColorStyles = (row) => ({
  position: 'absolute',
  top: '6px',
  left: '8px',
  color: row?.color?.fontColor,
  textTransform: 'uppercase',
});
export const teamColorBoxStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  paddingRight: '20px',
};
export const caregiverNameStyles = {
  color: COLORS.blue[700],
  fontWeight: '600',
  fontSize: '14px',
  cursor: 'pointer',
};
export const caregiverListStyles = {
  color: COLORS.blue[700],
  width: '8px',
  height: '8px',
};
export const clientListStyles = {
  color: COLORS.blue[700],
  width: '8px',
  height: '8px',
};
export const clientNameStyles = {
  color: COLORS.blue[700],
  fontWeight: '600',
  fontSize: '14px',
  cursor: 'pointer',
};
export const errorModalBoxStyles = {
  display: 'flex',
  gap: 1,
  alignItems: 'center',
  m: '20px 0',
};
export const listBoxStyles = {
  display: 'flex',
  width: '100%',
  gap: 12,
};
