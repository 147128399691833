import { COLORS } from '../../../../../../shared/styles/Theme';

export const dayBubbleWrapper = {
  display: 'flex',
  width: '28px',
  height: '28px',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: COLORS.green[500],
  color: '#fff',
  fontWeight: 600,
  fontSize: '11px',
  lineHeight: '16px',
  textAlign: 'center',
  letterSpacing: '0.03em',
};

export const taskNameStyles = {
  fontSize: '20px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  wordBreak: 'break-word',
};

export const taskSubInfoWrapper = {
  display: 'flex',
  mt: '8px',
  gap: '6px',
  alignItems: 'center',
};

export const taskSubInfoStyles = {
  fontSize: '13px',
  lineHeight: '16px',
  color: COLORS.blue[500],
};
