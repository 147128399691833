import { Box, Stack } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetTerritoryQuery } from '../../../../api/Administration/api';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  SEARCH_INPUT_DEBOUNCE_DELAY,
  viewTypeButtons,
} from '../../../../shared/constants';
import { useDebouncedCallback } from '../../../../shared/hooks/useDebounce';
import AlertContext from '../../../../components/Alert';
import ButtonsGroup from '../../../../components/ButtonsGroup';
import CustomTable from '../../../../components/Table';
import AddTerritoryModalHeader from './components/AddTerritoryModalHeader';
import TerritoryHistory from './components/TerritoryHistory';
import { DEFAULT_SORTING_MODEL, territoryColumns } from './constants';

export default function AdministrationTerritory() {
  const { tabSubView } = useParams();
  const navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);
  const apiRef = useGridApiRef();
  const [sortOption, setSortOption] = useState(DEFAULT_SORTING_MODEL);
  const [filters, setFilters] = useState({ territory: '' });
  const [territoryName, setTerritoryName] = useState();
  const [order, setOrder] = useState(1);
  const updateSorting = useCallback((item) => setSortOption(item[0]), []);

  const {
    data: locations,
    error: getLocationsListError,
    isLoading,
  } = useGetTerritoryQuery({ refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (getLocationsListError) {
      const errorData = getLocationsListError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [getLocationsListError, setAlert]);

  const debouncedSearch = useDebouncedCallback((e) => {
    setFilters({ [e.target.name]: e.target.value });
  }, SEARCH_INPUT_DEBOUNCE_DELAY);

  const filteredList = locations?.filter((terr) => {
    const userName = terr?.name?.toLowerCase();
    const filterValue = filters?.territory?.toLowerCase();
    return userName.includes(filterValue);
  });
  const changeSubView = (value) =>
    navigate(`/administration/territories/${value}`);
  return (
    <Stack>
      {tabSubView === 'current' ? (
        <Box>
          <Box sx={{ width: '100%', display: 'flex', gap: 2 }}>
            <ButtonsGroup
              buttons={viewTypeButtons}
              changeSelected={(value) => {
                changeSubView(value);
                setFilters({ territory: '' });
              }}
              selected={tabSubView}
            />
            <AddTerritoryModalHeader
              territoryName={territoryName}
              setTerritoryName={setTerritoryName}
              data={locations}
              order={order}
              setOrder={setOrder}
              debouncedSearch={debouncedSearch}
            />
          </Box>
          <Box sx={{ mt: '24px' }}>
            <CustomTable
              columns={territoryColumns(locations)}
              rows={filteredList}
              apiRef={apiRef}
              headerHeight={40}
              isLoading={isLoading}
              rowHeight={50}
              pageSize={PAGE_SIZE}
              setSortModelState={updateSorting}
              sortModel={[sortOption]}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
            />
          </Box>
        </Box>
      ) : (
        <Box>
          <ButtonsGroup
            buttons={viewTypeButtons}
            changeSelected={changeSubView}
            selected={tabSubView}
          />
          <TerritoryHistory />
        </Box>
      )}
    </Stack>
  );
}
