import { Box, Typography } from '@mui/material';
import moment from 'moment/moment';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import {
  ISO_DATE_ONLY_FORMAT,
  backgroundColorSchemeMap,
} from '../../../../../shared/constants';
import { useWebSocket } from '../../../../../shared/hooks/useWebsocket';
import { getStatusChangingInfo } from '../../../../../shared/utils/common';
import Badge from '../../../../../components/Badge';
import {
  CaregiverDetailsContext,
  CaregiverProfileContext,
} from '../../../context';
import ChangeStatusSteps from '../ChangeStatusSteps';
import { CAREGIVER_STATUSES } from '../constants';
import EffectiveDateDialog from './EffectiveDateModal';
import StatusesCustomSelect from './Select';

export default function StatusSelector() {
  const { id } = useParams();
  const {
    confirmCaregiverDeactivation,
    profileData,
    setCaregiverProfile,
    caregiverProfile,
  } = useContext(CaregiverProfileContext);
  const { isEdit, onCloseProfileEditing } = useContext(CaregiverDetailsContext);
  const { clearMessage, sendMessage } = useWebSocket();
  const [showVisitsChanges, setShowVisitsChanges] = useState(false);
  const [statusAction, setStatusAction] = useState(null);
  const [date, setDate] = useState(moment().add(1, 'day'));
  const [openEffectiveDateDialog, setOpenEffectiveDateDialog] = useState(false);

  const changeStatus = (value) => {
    if (value === 'Inactive') {
      setOpenEffectiveDateDialog(true);
      setStatusAction('deactivate');
    }
    setCaregiverProfile({ ...caregiverProfile, status: 'Active' });
  };
  const submitConfirmationDateDialog = () => {
    setShowVisitsChanges(true);
    sendMessage({
      action: 'CAREGIVER_DEACTIVATION_CHECK',
      payload: {
        type: 'caregiverDeactivationCheck',
        caregiverId: id,
        effectiveDate: date?.format(ISO_DATE_ONLY_FORMAT),
      },
    });
    setOpenEffectiveDateDialog(false);
  };
  const confirmNewStatus = (confirmationData) => {
    if (profileData?.status === 'Active' && statusAction === 'deactivate') {
      confirmCaregiverDeactivation(confirmationData);
    }
    setShowVisitsChanges(false);
    setStatusAction(null);
    onCloseProfileEditing();
    clearMessage();
  };
  const closeChangeStatusView = useCallback(() => {
    setShowVisitsChanges(false);
    setStatusAction(null);
    setDate(moment());
    clearMessage();
  }, [clearMessage]);

  const closeEffectiveDateDialog = () => {
    setOpenEffectiveDateDialog(false);
    setDate(moment());
  };

  const accountChangesInfo = useMemo(
    () => getStatusChangingInfo(profileData, true),
    [profileData],
  );
  return (
    <Box sx={{ minWidth: '250px' }}>
      {showVisitsChanges && statusAction && (
        <ChangeStatusSteps
          confirmNewStatus={confirmNewStatus}
          closeChangeStatusView={closeChangeStatusView}
          date={date}
          statusAction={statusAction}
          showVisitsChanges={showVisitsChanges}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          minWidth: '250px',
        }}
      >
        {isEdit ? (
          <StatusesCustomSelect
            colorScheme={
              backgroundColorSchemeMap[caregiverProfile?.status?.toLowerCase()]
            }
            disabled={!caregiverProfile?.primaryTeams?.length}
            id="status"
            options={CAREGIVER_STATUSES?.map((option) => option.name)}
            isLoading={false}
            selectedValue={caregiverProfile?.status}
            setValue={changeStatus}
          />
        ) : (
          <Badge
            colorScheme={
              backgroundColorSchemeMap[profileData?.status?.toLowerCase()]
            }
            label={profileData?.status}
          />
        )}
        <Typography sx={{ fontStyle: 'italic', ml: '10px' }}>
          {accountChangesInfo}
        </Typography>
        <EffectiveDateDialog
          openEffectiveDateDialog={openEffectiveDateDialog}
          closeEffectiveDateDialog={closeEffectiveDateDialog}
          submitEffectiveDateDialog={submitConfirmationDateDialog}
          date={date}
          selectDate={(newValue) => setDate(newValue)}
        />
      </Box>
    </Box>
  );
}
