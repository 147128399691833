import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Checkbox, MenuItem, Select } from '@mui/material';
import { bool, func, instanceOf, oneOfType, string } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import CustomTooltip from '../../../../../components/CustomTooltip';
import SelectRenderValue from './SelectRenderValue';
import {
  customPaperStyles,
  customSelectItem,
  customSelectStyles,
} from './style';

export default function TeamsSelect({
  id = '',
  isRegionsSelect = false,
  disabled = false,
  options = [],
  placeHolder = '',
  selectedValue = '' || {},
  setValue = () => {},
  isOpen = false,
  setIsOpen = () => {},
}) {
  const handleChangeTeams = useCallback(
    (value) => {
      const newValues = selectedValue.includes(value)
        ? selectedValue.filter((team) => team !== value)
        : [...selectedValue, value];
      let withoutDuplicates = [];

      newValues.forEach((item) => {
        if (withoutDuplicates.findIndex((option) => option === item) >= 0) {
          withoutDuplicates = withoutDuplicates.filter((x) => x === item);
        } else {
          withoutDuplicates.push(item);
        }
      });
      setValue({ field: id, value: withoutDuplicates });
      return '';
    },
    [id, selectedValue, setValue],
  );

  const tooltipText = useMemo(
    () =>
      isRegionsSelect
        ? 'Team has been selected as secondary'
        : 'Team has been selected as primary',
    [isRegionsSelect],
  );
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <Select
      IconComponent={ExpandMoreIcon}
      className={selectedValue?.length ? 'has-selected-items' : ''}
      displayEmpty
      multiple
      disabled={disabled}
      sx={{ ...customSelectStyles }}
      onOpen={handleOpen}
      onClose={handleClose}
      open={isOpen}
      MenuProps={{
        PaperProps: {
          sx: customPaperStyles,
        },
      }}
      renderValue={() => (
        <SelectRenderValue
          options={options?.map((team) => team?.name)}
          placeHolder={placeHolder}
          selectedValue={selectedValue}
        />
      )}
      value={selectedValue || []}
    >
      {options?.map(({ disabledTeam, name }) => (
        <CustomTooltip
          key={name}
          title={disabledTeam ? tooltipText : ''}
          placement="top-start"
        >
          <span>
            <MenuItem
              disabled={disabledTeam}
              disableRipple
              sx={{ ...customSelectItem }}
              value={name}
              onClick={() => handleChangeTeams(name)}
            >
              <Checkbox
                checked={
                  !selectedValue?.length
                    ? false
                    : selectedValue?.findIndex((item) => item === name) >= 0
                }
                sx={{
                  padding: '5px 9px',
                }}
              />
              {name}
            </MenuItem>
          </span>
        </CustomTooltip>
      ))}
    </Select>
  );
}

TeamsSelect.propTypes = {
  id: string,
  isRegionsSelect: bool,
  disabled: bool,
  options: instanceOf(Array),
  placeHolder: string,
  selectedValue: oneOfType([instanceOf(Array), string, instanceOf(Object)]),
  setValue: func,
  isOpen: bool,
  setIsOpen: func,
};
