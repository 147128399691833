import { COLORS } from '../../../../../../../../shared/styles/Theme';

export const updateCareProgramViewWrapper = {
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 40px',
  minWidth: '1420px',
};

export const updateCareProgramTitleWrapper = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
};

export const updateCareProgramInfoWrapper = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  mt: '60px',
  mb: '25px',
};

export const customDialogButtonStyles = {
  ml: '4px',
  '&.MuiButton-root.Mui-disabled': {
    backgroundColor: 'transparent',
    color: COLORS.blue[500],
    opacity: 0.7,
  },
};
