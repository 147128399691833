import { COLORS } from '../../../../../../../shared/styles/Theme';

export const dialogContentWrapper = {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  justifyContent: 'flex-start',
  '& .info-icon': {
    height: '18px',
  },
};

export const textButtonStyles = {
  color: COLORS.blue[700],
  padding: '0 0 0 4px',
  justifyContent: 'flex-start',
  width: 'fit-content',
  height: '20px',
  textTransform: 'capitalize',
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '20px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
};
