import { Box, Typography } from '@mui/material';
import { useGetUserDataByIdQuery } from '../../../../../../../../../../api/Clients/api';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { COLORS } from '../../../../../../../../../../shared/styles/Theme';
import AlertContext from '../../../../../../../../../../components/Alert';
import Loader from '../../../../../../../../../../components/Loader';
import { personalInformationFieldsMap } from '../constants';

export default function ClientData() {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const { data: userData, isLoading, error } = useGetUserDataByIdQuery(id);
  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);
  const userInfo = {
    client: `${userData?.clientDetails.firstName} ${userData?.clientDetails.lastName}`,
    address: userData?.clientDetails.address,
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      {personalInformationFieldsMap?.map(({ name, key }) => (
        <Box key={name} sx={{ display: 'flex', mb: '16px' }}>
          <Typography
            variant="body1"
            sx={{ color: COLORS.blue[500], mr: '60px', minWidth: '70px' }}
          >
            {name}:
          </Typography>
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            {userInfo[key] || ''}
          </Typography>
        </Box>
      ))}
    </div>
  );
}
