export const createOneTimeVisitInitialData = {
  arrivalStart: null,
  arrivalEnd: null,
  exactTimeSlot: null,
  dates: [],
  duration: null,
  genderPreference: null,
  preferredSkills: [],
  preferredLanguages: [],
  visitType: {},
  preVisitNotes: '',
};

export const createOneTimeVisitSteps = [
  'Visit Parameters',
  'Availability',
  'Tasks & Summary',
];
