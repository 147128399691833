import { Box, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { H12_TIME_FORMAT, backgroundColorSchemeMap } from '../../../../../../../../shared/constants';
import { COLORS } from '../../../../../../../../shared/styles/Theme';
import { getArrivalRange } from '../../../../../../../../shared/utils/common';
import Badge from '../../../../../../../../components/Badge';
import CustomTooltip from '../../../../../../../../components/CustomTooltip';
import VisitType from '../VisitType';

// import Badge from '../../../../../../../../components/Badge';
// import VisitType from '../VisitType';

const getScheduledTime = (data) => {
  if (!data?.oldScheduledTime) {
    return '-';
  }
  const isStartEndTimeSame =
    !data?.oldScheduledTime?.end ||
    data?.oldScheduledTime?.end === 'NOT ENDED' ||
    moment(data?.oldScheduledTime?.start, H12_TIME_FORMAT).isSame(
      moment(data?.oldScheduledTime?.end, H12_TIME_FORMAT),
      'minute',
    );
  return isStartEndTimeSame ? (
    <Typography>{data?.oldScheduledTime?.start}</Typography>
  ) : (
    <Typography>{`${data?.oldScheduledTime?.start} - ${data?.oldScheduledTime?.end}`}</Typography>
  );
};

const getCaregiverData = (row) => {
  const initialCaregiver = row?.oldCaregiver
    ? `${row?.oldCaregiver?.firstName} ${row?.oldCaregiver?.lastName}`
    : null;
  const newCaregiver = row?.newCaregiver
    ? `${row?.newCaregiver?.firstName} ${row?.newCaregiver?.lastName}`
    : null;
  return (
    <div>
      <Typography
        component="span"
        sx={{ textDecoration: newCaregiver ? 'line-through' : 'none' }}
      >
        {initialCaregiver || ''}
      </Typography>
      {newCaregiver && (
        <CustomTooltip title="Caregiver can be changed">
          <div>
            <Badge
              colorScheme={backgroundColorSchemeMap.active}
              label={newCaregiver || ''}
            />
          </div>
        </CustomTooltip>
      )}
    </div>
  );
};
const getClientName = (data) => (
  <Typography
    noWrap
    sx={{
      color: COLORS.blue[700],
    }}
  >
    {`${data?.firstName} ${data?.lastName}`}
  </Typography>
);

export const columns = [
  {
    field: 'visitType',
    headerName: 'Visit Type',
    flex: 1,
    maxWidth: 250,
    sortable: false,
    renderCell: ({ row, value }) => <VisitType row={row} value={value} />,
  },
  {
    field: 'client',
    headerName: 'Client',
    minWidth: 190,
    sortable: false,
    renderCell: ({ value }) => getClientName(value),
  },
  {
    field: 'duration',
    headerName: 'Duration',
    sortable: false,
    width: 100,
    renderCell: ({ value }) => (
      <Typography>{value !== null ? `${value} min` : '0 min'}</Typography>
    ),
  },
  {
    field: 'arrivalWindow',
    headerName: 'Arrival Window',
    sortable: false,
    width: 180,
    renderCell: ({ row }) => getArrivalRange(row),
  },
  {
    field: 'scheduledTime',
    headerName: 'Scheduled Time',
    sortable: false,
    width: 180,
    renderCell: ({ row }) => getScheduledTime(row),
  },
  {
    field: 'caregiver',
    headerName: 'Caregiver',
    minWidth: 190,
    sortable: false,
    renderCell: ({ row }) => getCaregiverData(row, false),
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    maxWidth: 240,
    sortable: false,
    renderCell: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Badge
          colorScheme={
            row.status.message
              ? backgroundColorSchemeMap.active
              : backgroundColorSchemeMap.deactivated
          }
          label={
            row.status.message
              ? 'Reassignment Possible'
              : 'Reassignment Impossible'
          }
        />
      </Box>
    ),
  },
];
