import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem, Select } from '@mui/material';
import { bool, func, instanceOf, oneOfType, string } from 'prop-types';
import React from 'react';
import {
  customPaperStyles,
  customSelectItem,
  getCustomSelectStyles,
} from './style';

export default function StatusesCustomSelect({
  colorScheme = {},
  disabled = false,
  isLoading = false,
  options = [],
  selectedValue = '' || {},
  setValue = () => {},
}) {
  const selectInitialValue = '';
  return (
    <Select
      IconComponent={ExpandMoreIcon}
      disabled={disabled || isLoading}
      displayEmpty
      onChange={(e) => setValue(e.target.value)}
      sx={{ ...getCustomSelectStyles(colorScheme) }}
      MenuProps={{
        PaperProps: {
          sx: customPaperStyles,
        },
      }}
      value={selectedValue || selectInitialValue}
    >
      {options?.map((option) => (
        <MenuItem
          key={option}
          disabled={isLoading}
          disableRipple
          sx={customSelectItem}
          value={option}
        >
          {option}
        </MenuItem>
      ))}
    </Select>
  );
}

StatusesCustomSelect.propTypes = {
  colorScheme: instanceOf(Object),
  disabled: bool,
  isLoading: bool,
  options: instanceOf(Array),
  selectedValue: oneOfType([instanceOf(Array), string, instanceOf(Object)]),
  setValue: func,
};
