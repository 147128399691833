import { COLORS } from '../../../../../../shared/styles/Theme';

export const getScheduleStyles = ({ transparent, selected }) => ({
  display: 'flex',
  width: '108px',
  height: '40px',
  borderRadius: '10px',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: selected ? COLORS.brown[600] : '#eff2f9',
  fontWeight: 600,
  fontSize: '13px',
  lineHeight: '16px',
  textAlign: 'center',
  letterSpacing: '0.03em',
  opacity: transparent ? 0.8 : 1,
  '& .MuiTypography-root': {
    color: selected ? '#ffffff' : COLORS.blue[500],
  },
});
