import { Alert, Box } from '@mui/material';
import WarningIcon from '../../../../../img/icons/warning-icon.svg';
import { bool, func, instanceOf } from 'prop-types';
import React from 'react';
import CustomDialog from '../../../../../components/Dialog';
import { effectiveDateSelectCustomAlert } from '../styles';
import SelectAvailabilityDate from './SelecetAvailabilityDate';

function EffectiveDateDialog({
  openAvailabilityDialog = false,
  closeAvailabilityDialog = () => {},
  submitAvailabilityDialog = () => {},
  date = {},
  selectDate = () => {},
  showWarningTitle = false,
}) {
  return (
    <CustomDialog
      cancelButtonName="Cancel"
      cancelCallback={closeAvailabilityDialog}
      customStyles={{ maxWidth: '540px' }}
      submitButtonName="Proceed"
      submitCallback={submitAvailabilityDialog}
      title="Set Effective Date"
      openDialog={openAvailabilityDialog}
    >
      <Box sx={{ padding: '0 28px 20px 13px' }}>
        {showWarningTitle && (
          <Alert
            icon={<img src={WarningIcon} alt="" />}
            severity="warning"
            sx={effectiveDateSelectCustomAlert}
          >
            The future availability will be overwritten with the new effective
            date
          </Alert>
        )}
        <SelectAvailabilityDate date={date} selectDate={selectDate} />
      </Box>
    </CustomDialog>
  );
}

EffectiveDateDialog.propTypes = {
  openAvailabilityDialog: bool,
  closeAvailabilityDialog: func,
  submitAvailabilityDialog: func,
  date: instanceOf(Object),
  selectDate: func,
  showWarningTitle: bool,
};

export default EffectiveDateDialog;
