import CircleIcon from '@mui/icons-material/Circle';
import {
  Alert,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import errorIcon from '../../../../../../img/icons/error-outlined.svg';
import successIcon from '../../../../../../img/icons/success-outlined.svg';
import WarningIcon from '../../../../../../img/icons/warning-icon.svg';
import moment from 'moment';
import React from 'react';
import {
  H12_TIME_FORMAT,
  SHORT_DATE_FORMAT,
  backgroundColorSchemeMap,
} from '../../../../../../shared/constants';
import { COLORS } from '../../../../../../shared/styles/Theme';
import { getArrivalRange, getIconComponent } from '../../../../../../shared/utils/common';
import Badge from '../../../../../../components/Badge';
import CustomTooltip from '../../../../../../components/CustomTooltip';
import ShadowCaregiverCell from '../../../../../../components/DeletedSVShadowCaregiverCell';

export const checkingSchedulingColumns = [
  {
    bgColor: COLORS.green[200],
    color: COLORS.green[600],
    icon: <img src={successIcon} alt="success icon" />,
    getTitle() {
      return 'Scheduling updates are possible without constraints violation.';
    },
    type: 'success',
    dataKey: 'availableReschedule',
  },
  {
    bgColor: COLORS.green[200],
    color: COLORS.green[600],
    icon: <img src={successIcon} alt="success icon" />,
    getTitle() {
      return (
        <Box sx={{ display: 'flex' }}>
          <img
            style={{ marginRight: '6px' }}
            src={WarningIcon}
            alt="warning icon"
          />
          <Typography
            variant="body1"
            sx={{
              fontWeight: 600,
            }}
          >
            Scheduling updates are possible only with soft constraints violation
          </Typography>
        </Box>
      );
    },
    type: 'warning',
    dataKey: 'availableRescheduleResWarning',
  },
  {
    bgColor: COLORS.red[200],
    color: COLORS.red[600],
    icon: <img src={errorIcon} alt="error icon" />,
    getTitle() {
      return 'Scheduling update are not possible without hard constraints violation ';
    },
    type: 'error',
    dataKey: 'unAvailableReschedule',
  },
];
export const renderRescheduleAlert = (actualVisit) => {
  const hasError = actualVisit?.some(
    (visit) => visit?.status?.status === 'error',
  );
  const hasWarning = actualVisit?.some(
    (visit) => visit?.status?.status === 'warning',
  );

  const config = checkingSchedulingColumns.find((configs) => {
    if (hasError) {
      return configs.type === 'error';
    }
    if (hasWarning) {
      return configs.type === 'warning';
    }
    return configs.type === 'success';
  });

  if (config) {
    return (
      <Box
        key={config.dataKey}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          mb: '30px',
        }}
      >
        <Alert
          icon={config.icon}
          severity={config.type}
          sx={{
            backgroundColor: config.bgColor,
            color: config.color,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: 600,
            }}
          >
            {config.getTitle()}
          </Typography>
        </Alert>
      </Box>
    );
  }

  return null;
};
const visitReassignmentStatusesColorsMap = {
  error: 'error',
  warning: 'partiallySuccessful',
  success: 'active',
};
const getScheduledTimeRange = (data, status, actualVisit) => (
  <div>
    <Typography
      sx={{ textDecoration: data?.newScheduledTime ? 'line-through' : 'none' }}
    >{`${moment(data?.oldScheduledTime?.start, H12_TIME_FORMAT).format(
      H12_TIME_FORMAT,
    )} - ${moment(data?.oldScheduledTime?.end, H12_TIME_FORMAT).format(
      H12_TIME_FORMAT,
    )}`}</Typography>
    {data?.newScheduledTime && actualVisit ? (
      <CustomTooltip
        title={
          status === 'active' ? 'Time can be changed within arrival window' : ''
        }
      >
        <div>
          <Badge
            colorScheme={backgroundColorSchemeMap[status || 'active']}
            label={`${moment(
              data?.newScheduledTime?.start,
              H12_TIME_FORMAT,
            ).format(H12_TIME_FORMAT)} - ${moment(
              data?.newScheduledTime?.end,
              H12_TIME_FORMAT,
            ).format(H12_TIME_FORMAT)}`}
          />
        </div>
      </CustomTooltip>
    ) : (
      <Box>
        {data?.newScheduledTime && (
          <Typography fontWeight={600}>{`${moment(
            data?.newScheduledTime?.start,
            H12_TIME_FORMAT,
          ).format(H12_TIME_FORMAT)} - ${moment(
            data?.newScheduledTime?.end,
            H12_TIME_FORMAT,
          ).format(H12_TIME_FORMAT)}`}</Typography>
        )}
      </Box>
    )}
  </div>
);
const getCaregiverData = (
  newCaregiver,
  initialCaregiver,
  colorKeyStatus,
  actualVisit,
) => (
  <div>
    <Typography sx={{ textDecoration: newCaregiver ? 'line-through' : 'none' }}>
      {initialCaregiver || ''}
    </Typography>
    {newCaregiver && actualVisit ? (
      <CustomTooltip
        title={colorKeyStatus === 'active' ? 'Caregiver can be changed' : ''}
      >
        <div>
          <Badge
            colorScheme={backgroundColorSchemeMap[colorKeyStatus || 'active']}
            label={newCaregiver || ''}
          />
        </div>
      </CustomTooltip>
    ) : (
      <Typography>{newCaregiver || ''}</Typography>
    )}
  </div>
);
export const reschedulingVisitColumns = [
  {
    field: 'visitType',
    headerName: 'Visit Type',
    flex: 1,
    maxWidth: 250,
    sortable: false,
    renderCell: ({ value, row }) => (
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              ml: row?.shiftedBy ? '40px' : 0,
              mb: '6px',
            }}
          >
            {getIconComponent(value)}
            <Typography
              variant={row?.shiftedBy ? 'body1' : 'h5'}
              sx={{ ml: '8px', color: COLORS.blue[700] }}
            >
              {value}
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ fontSize: '13px' }}>
            {row?.team?.name}
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    field: 'client',
    headerName: 'Client',
    minWidth: 160,
    sortable: false,
    renderCell: ({ value }) => (
      <Typography
        sx={{ color: COLORS.blue[700] }}
      >{`${value.firstName} ${value.lastName}`}</Typography>
    ),
  },
  {
    field: 'duration',
    headerName: 'Duration',
    sortable: false,
    width: 100,
    renderCell: ({ value }) => (
      <Typography>{value !== null ? `${value} min` : '0 min'}</Typography>
    ),
  },
  {
    field: 'arrivalWindow',
    headerName: 'Arrival Window',
    sortable: false,
    width: 180,
    renderCell: ({ row }) => getArrivalRange(row),
  },
  {
    field: 'scheduledTime',
    headerName: 'Scheduled Time',
    sortable: false,
    width: 180,
    renderCell: ({ row }) => {
      const colorKey = visitReassignmentStatusesColorsMap[row?.status?.status];
      return row?.oldScheduledTime ? getScheduledTimeRange(row, colorKey) : '';
    },
  },
  {
    field: 'caregiver',
    headerName: 'Caregiver',
    minWidth: 150,
    maxWidth: 300,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => {
      const colorKey = visitReassignmentStatusesColorsMap[row?.status?.status];
      const initialCaregiver = row?.oldCaregiver
        ? `${row?.oldCaregiver?.firstName} ${row?.oldCaregiver?.lastName}`
        : null;
      const newCaregiver = row?.newCaregiver
        ? `${row?.newCaregiver?.firstName} ${row?.newCaregiver?.lastName}`
        : null;
      return getCaregiverData(newCaregiver, initialCaregiver, colorKey);
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: false,
    minWidth: 240,
    flex: 1,
    renderCell: ({ value }) => {
      const colorKey = visitReassignmentStatusesColorsMap[value?.status];
      return value?.status ? (
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <Badge
            colorScheme={backgroundColorSchemeMap[colorKey]}
            label={value.message}
          />
          {value?.status === 'warning' && (
            <CustomTooltip
              title={
                value?.reasons?.length ? (
                  <Box sx={{ padding: '12px' }}>
                    <List>
                      {value?.reasons?.map((reason) => (
                        <ListItem key={reason} sx={{ padding: 0 }}>
                          <ListItemIcon sx={{ minWidth: '16px' }}>
                            <CircleIcon
                              sx={{
                                fontSize: '6px',
                                color: COLORS.blue[700],
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={reason} />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                ) : (
                  ''
                )
              }
            >
              <img src={WarningIcon} alt="Warning" />
            </CustomTooltip>
          )}
        </Box>
      ) : (
        ''
      );
    },
  },
];
export const shadowVisitColumns = [
  {
    field: 'visitType',
    headerName: 'Visit Type',
    flex: 1,
    maxWidth: 250,
    sortable: false,
    renderCell: ({ value, row }) => (
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              ml: row?.shiftedBy ? '40px' : 0,
              mb: '6px',
            }}
          >
            {getIconComponent(value)}
            <Typography
              variant={row?.shiftedBy ? 'body1' : 'h5'}
              sx={{ ml: '8px', color: COLORS.blue[700] }}
            >
              {value}
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ fontSize: '13px' }}>
            {row?.team?.name}
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    field: 'client',
    headerName: 'Client',
    minWidth: 160,
    sortable: false,
    renderCell: ({ value }) => (
      <Typography
        sx={{ color: COLORS.blue[700] }}
      >{`${value.firstName} ${value.lastName}`}</Typography>
    ),
  },
  {
    field: 'duration',
    headerName: 'Duration',
    sortable: false,
    width: 100,
    renderCell: ({ value }) => (
      <Typography>{value !== null ? `${value} min` : '0 min'}</Typography>
    ),
  },
  {
    field: 'arrivalWindow',
    headerName: 'Arrival Window',
    sortable: false,
    width: 180,
    renderCell: ({ row }) => getArrivalRange(row),
  },
  {
    field: 'scheduledTime',
    headerName: 'Scheduled Time',
    sortable: false,
    width: 180,
    renderCell: ({ row }) =>
      row.oldScheduledTime?.start ? (
        <Typography>{`${row.oldScheduledTime?.start} - ${row.oldScheduledTime?.end}`}</Typography>
      ) : (
        <Typography>{row.oldScheduledTime}</Typography>
      ),
  },
  {
    field: 'caregiver',
    headerName: 'Caregiver',
    minWidth: 150,
    maxWidth: 300,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => <ShadowCaregiverCell row={row} />,
  },
  {
    field: 'date',
    headerName: 'Date',
    sortable: false,
    minWidth: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography>{moment(value).format(SHORT_DATE_FORMAT)}</Typography>
    ),
  },
];
