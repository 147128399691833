import { instanceOf } from 'prop-types';
import React from 'react';
import MultilineInput from '../../../../../../../components/Inputs/MultilineInput';

export default function TaskDetails({ row = {} }) {
  return (
    <MultilineInput
      disabled
      id={row.id}
      onKeyDown={(e) => e.stopPropagation()}
      value={row.details || ''}
      changeDetails={() => {}}
    />
  );
}

TaskDetails.propTypes = {
  row: instanceOf(Object),
};
