import { Box, Typography } from '@mui/material';
import ArrowUpward from '../../../../../../../../img/icons/arrow_upward.svg';
import RepeatedVisit from '../../../../../../../../img/icons/visitTypes/repeatedVisit.svg';
import moment from 'moment';
import React from 'react';
import {
  H12_TIME_FORMAT,
  MONTH_DAY_YEAR_FORMAT,
  backgroundColorSchemeMap,
} from '../../../../../../../../shared/constants';
import { COLORS } from '../../../../../../../../shared/styles/Theme';
import {
  getArrivalRange,
  getIconComponent,
  getOneTimeOrRepeatedIcon,
} from '../../../../../../../../shared/utils/common';
import Badge from '../../../../../../../../components/Badge';
import DaysSchedule from '../../../../../../../../components/DaysSchedule';

const getScheduledTime = (data) => {
  if (!data?.oldScheduledTime) {
    return '-';
  }
  const isStartEndTimeSame = moment(data?.arrivalStart, H12_TIME_FORMAT).isSame(
    moment(data?.arrivalEnd, H12_TIME_FORMAT),
    'minute',
  );
  return isStartEndTimeSame ? (
    <Typography>{data?.oldScheduledTime?.start}</Typography>
  ) : (
    <Typography>{`${data?.oldScheduledTime?.start} - ${data?.oldScheduledTime?.end}`}</Typography>
  );
};

const getCaregiverName = (data, reactivation) => {
  if (!data?.oldCaregiver && !data?.newCaregiver) {
    return '-';
  }
  const caregiverName = `${
    data[reactivation ? 'newCaregiver' : 'oldCaregiver']?.firstName
  } ${data[reactivation ? 'newCaregiver' : 'oldCaregiver']?.lastName}`;
  return (
    <Typography
      noWrap
      sx={{
        color: COLORS.blue[700],
      }}
    >
      {caregiverName}
    </Typography>
  );
};

export const getColumns = (isReactivationAccount) => ({
  allocatedVisits: [
    {
      field: 'oldDate',
      headerName: 'Date',
      minWidth: 190,
      sortable: false,
      renderCell: ({ value }) => (
        <Typography>{moment(value).format(MONTH_DAY_YEAR_FORMAT)}</Typography>
      ),
    },
    {
      field: 'visitType',
      headerName: 'Visit Type',
      flex: 1,
      maxWidth: 250,
      sortable: false,
      renderCell: ({ row, value }) => (
        <Box sx={{ display: 'flex', gap: '6px', maxWidth: '240px' }}>
          {getOneTimeOrRepeatedIcon(row?.repeat)}
          {getIconComponent(row.type || value)}
          <Typography noWrap variant="body1" sx={{ color: COLORS.blue[700] }}>
            {row.type || value}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'duration',
      headerName: 'Duration',
      sortable: false,
      width: 100,
      renderCell: ({ value }) => (
        <Typography>{value !== null ? `${value} min` : '0 min'}</Typography>
      ),
    },
    {
      field: 'arrivalWindow',
      headerName: 'Arrival Window',
      sortable: false,
      width: 180,
      renderCell: ({ row }) => getArrivalRange(row),
    },
    {
      field: 'scheduledTime',
      headerName: 'Scheduled Time',
      sortable: false,
      width: 180,
      renderCell: ({ row }) => getScheduledTime(row),
    },
    {
      field: 'team',
      headerName: 'Team',
      sortable: false,
      width: 160,
      renderCell: ({ value }) => <Typography>{value.name}</Typography>,
    },
    {
      field: 'caregiver',
      headerName: isReactivationAccount
        ? 'Assigned Caregiver'
        : 'Unassigned Caregiver',
      minWidth: 190,
      sortable: false,
      renderCell: ({ row }) => getCaregiverName(row, isReactivationAccount),
    },
    {
      field: 'status',
      headerName: 'Visit Status',
      flex: 1,
      maxWidth: 240,
      sortable: false,
      renderCell: ({ row }) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: isReactivationAccount ? 'row-reverse' : 'row',
            gap: '8px',
          }}
        >
          <Badge
            colorScheme={
              backgroundColorSchemeMap[
                row?.repeat?.toLowerCase() === 'weekly' ? 'active' : 'info'
              ]
            }
            label={
              row?.repeat.toLowerCase() === 'weekly' ? 'Scheduled' : 'Allocated'
            }
          />
          <img src={ArrowUpward} alt="icon" />
          <Badge
            colorScheme={backgroundColorSchemeMap.deactivated}
            label="Canceled"
          />
        </Box>
      ),
    },
  ],
  scheduledVisits: [
    {
      field: 'weekdays',
      headerName: 'Days of the Week',
      minWidth: 310,
      sortable: false,
      renderCell: ({ value }) => (
        <DaysSchedule
          additionalStyles={{ width: '28px', height: '28px', fontSize: '11px' }}
          selectedDays={value}
        />
      ),
    },
    {
      field: 'visitType',
      headerName: 'Visit Type',
      flex: 1,
      maxWidth: 280,
      sortable: false,
      renderCell: ({ row, value }) => (
        <Box sx={{ display: 'flex', gap: '6px', maxWidth: '240px' }}>
          <img src={RepeatedVisit} alt="icon" />
          {getIconComponent(row.type || value)}
          <Typography noWrap variant="body1" sx={{ color: COLORS.blue[700] }}>
            {row.type || value}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'duration',
      headerName: 'Duration',
      sortable: false,
      width: 100,
      renderCell: ({ value }) => (
        <Typography>{value !== null ? `${value} min` : '0 min'}</Typography>
      ),
    },
    {
      field: 'arrivalWindow',
      headerName: 'Arrival Window',
      sortable: false,
      width: 200,
      renderCell: ({ row }) => (
        <Typography>
          {row?.arrivalStart} - {row?.arrivalEnd}
        </Typography>
      ),
    },
    {
      field: 'team',
      headerName: 'Team',
      sortable: false,
      width: 170,
      renderCell: ({ value }) => <Typography>{value.name}</Typography>,
    },
    {
      field: 'status',
      headerName: 'Visit Status',
      flex: 1,
      maxWidth: 240,
      sortable: false,
      renderCell: () => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: isReactivationAccount ? 'row-reverse' : 'row',
            gap: '8px',
          }}
        >
          <Badge
            colorScheme={backgroundColorSchemeMap.active}
            label="Scheduled"
          />
          <img src={ArrowUpward} alt="icon" />
          <Badge
            colorScheme={backgroundColorSchemeMap.deactivated}
            label="Canceled"
          />
        </Box>
      ),
    },
  ],
});
