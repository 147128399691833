import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetTerritoryHistoryQuery } from '../../../../../../api/Administration/api';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import {
  HISTORY_UPDATE_FORMAT,
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
} from '../../../../../../shared/constants';
import { getNoData } from '../../../../../../shared/utils/common';
import AlertContext from '../../../../../../components/Alert';
import CustomTable from '../../../../../../components/Table';
import { teamsHistoryColumns } from './constants';

export default function TerritoryHistory() {
  const apiRef = useGridApiRef();
  const { setAlert } = useContext(AlertContext);
  const {
    data,
    isLoading,
    error: getTeamsHistoryError,
  } = useGetTerritoryHistoryQuery();
  useEffect(() => {
    if (getTeamsHistoryError) {
      setAlert({
        errorData: getTeamsHistoryError,
        type: 'error',
      });
    }
  }, [getTeamsHistoryError, setAlert]);
  const updatedEvents = data
    ?.map((event, index) => {
      const changes = event?.changes ? JSON.parse(event.changes) : null;
      if (!changes) return null;

      let orderObj = null;
      let nameObj = null;
      changes?.forEach((item) => {
        if (item.propertyName === 'order') {
          orderObj = item;
        } else if (item.propertyName === 'name') {
          nameObj = item;
        }
      });
      const finalChanges = orderObj
        ? [
            {
              ...orderObj,
              nameFrom: nameObj?.from,
              nameTo: nameObj?.to,
            },
          ]
        : [
            {
              ...nameObj,
              nameFrom: orderObj?.from,
              nameTo: orderObj?.to,
            },
          ];
      return {
        ...event,
        changes: finalChanges,
        dateCreated: moment(event.dateCreated).format(HISTORY_UPDATE_FORMAT),
        id: index + 1,
      };
    })
    .filter(Boolean);
  const sortedUpdatedEvents = updatedEvents?.sort((a, b) => {
    const dateA = new Date(a.dateCreated);
    const dateB = new Date(b.dateCreated);
    return dateB - dateA;
  });
  return (
    <Box sx={{ mt: '30px' }}>
      <CustomTable
        apiRef={apiRef}
        columns={teamsHistoryColumns}
        headerHeight={44}
        isLoading={isLoading}
        rows={sortedUpdatedEvents}
        noRowsOverlay={() => getNoData({ title: 'No Teams History yet' })}
        pageSize={PAGE_SIZE}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
      />
    </Box>
  );
}
