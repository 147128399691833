import {
  useCheckClientActivationQuery,
  useCheckClientDeactivationQuery,
  useCheckClientResumeQuery,
  useCheckClientSuspendQuery,
} from '../../../../../api/Clients/api';
import moment from 'moment';
import {
  CLIENTS_STATUS_CHANGING_ACTIONS,
  CLIENTS_STATUS_CHANGING_ACTIONS_TITLES,
} from '../../../../../pages/Clients/constants';
import { func, instanceOf, string } from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router';
import {
  FULL_DATE_H24_STANDARD_FORMAT,
  ISO_DATE_ONLY_FORMAT,
} from '../../../../../shared/constants';
import AlertContext from '../../../../../components/Alert';
import CustomDialog from '../../../../../components/Dialog';
import FullWidthDialog from '../../../../../components/FullWidthDialog';
import Loader from '../../../../../components/Loader';
import { changeClientSteps } from '../constants';
import ScheduleChangesView from './components/ScheduleChangesView';
import SettingsView from './components/SettingsView';

export default function ChangeStatusSteps({
  confirmNewStatus = () => {},
  setStatusChangingStep = () => {},
  statusAction = '',
  statusChangingStep = '',
  suspendedPeriod = {},
  onCloseProfileEdit = () => {},
  deactivationDate = {},
}) {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const [checkedEndTime, setCheckedEndTime] = useState(false);
  const convertToMoment = useCallback(
    () => ({
      startDate: moment().add(1, 'day'),
      endDate: suspendedPeriod
        ? moment(suspendedPeriod.endDate, FULL_DATE_H24_STANDARD_FORMAT)
        : null,
    }),
    [suspendedPeriod],
  );
  const [error, setError] = useState(null);
  const [statusPeriodDates, setStatusPeriodDates] = useState(
    suspendedPeriod ? convertToMoment() : {},
  );
  const {
    data: checkSuspendResponse,
    isLoading: suspendLoading,
    error: checkClientSuspendError,
  } = useCheckClientSuspendQuery(
    {
      clientId: id,
      payload: {
        fromDate: statusPeriodDates?.startDate?.format(ISO_DATE_ONLY_FORMAT),
        toDate: statusPeriodDates?.endDate?.format(ISO_DATE_ONLY_FORMAT),
      },
    },
    {
      refetchOnMountOrArgChange: true,
      skip:
        !statusAction ||
        statusAction !== CLIENTS_STATUS_CHANGING_ACTIONS.suspend ||
        statusChangingStep !== changeClientSteps.checkChangedVisits,
    },
  );
  const {
    data: checkResumeResponse,
    isLoading: resumeLoading,
    error: checkClientResumeError,
  } = useCheckClientResumeQuery(id, {
    refetchOnMountOrArgChange: true,
    skip:
      !statusAction || statusAction !== CLIENTS_STATUS_CHANGING_ACTIONS.resume,
  });
  const {
    data: checkDeactivationResponse,
    isLoading: deactivationLoading,
    error: checkClientDeactivationError,
    status: checkDeactivationStatus,
  } = useCheckClientDeactivationQuery(
    {
      clientId: id,
      date: moment(deactivationDate).format(ISO_DATE_ONLY_FORMAT),
    },
    {
      refetchOnMountOrArgChange: true,
      skip:
        !statusAction ||
        statusAction !== CLIENTS_STATUS_CHANGING_ACTIONS.deactivate,
    },
  );
  const {
    data: checkActivationResponse,
    isLoading: activationLoading,
    error: checkClientActivationError,
  } = useCheckClientActivationQuery(id, {
    refetchOnMountOrArgChange: true,
    skip:
      !statusAction ||
      statusAction !== CLIENTS_STATUS_CHANGING_ACTIONS.activation,
  });

  if (
    checkClientSuspendError ||
    checkClientResumeError ||
    checkClientDeactivationError ||
    checkClientActivationError
  ) {
    const errorData =
      checkClientSuspendError ||
      checkClientResumeError ||
      checkClientDeactivationError ||
      checkClientActivationError;
    setAlert({
      errorData,
      type: 'error',
    });
  }
  const isDataChanged = Object.values(statusPeriodDates).some(
    (value) => !!value,
  );
  const isRequiredFieldsFilled = useMemo(
    () =>
      statusPeriodDates?.startDate &&
      (checkedEndTime || statusPeriodDates?.endDate) &&
      !error,
    [
      checkedEndTime,
      error,
      statusPeriodDates?.endDate,
      statusPeriodDates?.startDate,
    ],
  );

  const viewCanceledVisits = () =>
    setStatusChangingStep(changeClientSteps.checkChangedVisits);

  const cancelStatusChanging = useCallback(() => {
    setStatusChangingStep(null);
    setStatusPeriodDates(suspendedPeriod ? convertToMoment() : {});
  }, [convertToMoment, setStatusChangingStep, suspendedPeriod]);
  const confirmStatus = useCallback(() => {
    const requestId =
      checkSuspendResponse?.requestId ||
      checkResumeResponse?.requestId ||
      checkDeactivationResponse?.requestId ||
      checkActivationResponse?.requestId;

    setStatusPeriodDates(suspendedPeriod ? convertToMoment() : {});

    confirmNewStatus({
      clientId: id,
      requestId,
    });
    cancelStatusChanging();
    onCloseProfileEdit();
  }, [
    cancelStatusChanging,
    checkActivationResponse?.requestId,
    checkDeactivationResponse?.requestId,
    checkResumeResponse?.requestId,
    checkSuspendResponse?.requestId,
    confirmNewStatus,
    convertToMoment,
    id,
    onCloseProfileEdit,
    suspendedPeriod,
  ]);

  const visitsList = useMemo(() => {
    const responseData =
      checkSuspendResponse || checkResumeResponse || checkActivationResponse;
    if (!responseData?.rescheduledEvents && !responseData?.scheduledVisits) {
      return [];
    }
    const dates = Object.keys(responseData.rescheduledEvents);
    const allocatedVisits = dates
      ?.filter((date) => responseData.rescheduledEvents[date].length)
      ?.map((date) => Object.values(responseData.rescheduledEvents[date]))
      ?.flat();
    return { allocatedVisits, scheduledVisits: responseData.scheduledVisits };
  }, [checkSuspendResponse, checkResumeResponse, checkActivationResponse]);

  const isDataLoading =
    suspendLoading || resumeLoading || deactivationLoading || activationLoading;

  useEffect(() => {
    if (checkDeactivationStatus === 'fulfilled') {
      confirmStatus();
      onCloseProfileEdit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkDeactivationResponse?.requestId, checkDeactivationStatus]);
  return (
    <>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={cancelStatusChanging}
        disableOnCloseByClickOutside
        disabledSubmit={!isDataChanged || !isRequiredFieldsFilled}
        openDialog={statusChangingStep === changeClientSteps.setPeriod}
        submitButtonName="View canceled visits"
        submitCallback={viewCanceledVisits}
        title={CLIENTS_STATUS_CHANGING_ACTIONS_TITLES[statusAction]}
      >
        <SettingsView
          error={error}
          setError={setError}
          checkedEndTime={checkedEndTime}
          setCheckedEndTime={setCheckedEndTime}
          setStatusPeriodDates={setStatusPeriodDates}
          statusPeriodDates={statusPeriodDates}
        />
      </CustomDialog>
      {checkDeactivationStatus === 'pending' && <Loader />}
      <FullWidthDialog
        cancelButtonName="cancel"
        cancelCallback={cancelStatusChanging}
        submitButtonName="Confirm"
        submitCallback={confirmStatus}
        disabledSubmit={isDataLoading}
        title={CLIENTS_STATUS_CHANGING_ACTIONS_TITLES[statusAction]}
        openDialog={statusChangingStep === changeClientSteps.checkChangedVisits}
      >
        <ScheduleChangesView
          actionType={statusAction}
          closeDialog={cancelStatusChanging}
          isLoading={isDataLoading}
          statusPeriodDates={statusPeriodDates}
          visits={visitsList}
        />
      </FullWidthDialog>
    </>
  );
}

ChangeStatusSteps.propTypes = {
  confirmNewStatus: func,
  setStatusChangingStep: func,
  statusAction: string,
  statusChangingStep: string,
  onCloseProfileEdit: func,
  suspendedPeriod: instanceOf(Object),
  deactivationDate: instanceOf(Object),
};
