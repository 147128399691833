import { Box, Collapse, Typography } from '@mui/material';
import { bool, func, string } from 'prop-types';
import React from 'react';
import {
  infoKeyField,
  infoRowEditableInfoWrapper,
  infoRowWrapperStyles,
} from '../styles';
import EditIconButton from './EditIconButton';
import VisitDateAndArrivalTimeSetup from './VisitDateAndArrivalTimeSetup';

export default function DateAndArrivalTimeFields({
  editArrivalTime = false,
  enableToEditScheduledTime = false,
  isExactTime = false,
  setEditArrivalTime = () => {},
  scheduledValue = '',
}) {
  return (
    <Box>
      {!editArrivalTime && (
        <Box sx={infoRowWrapperStyles}>
          <Typography sx={infoKeyField}>
            {isExactTime ? 'Scheduled Time:' : 'Scheduled Arrival:'}
          </Typography>
          <Box sx={infoRowEditableInfoWrapper}>
            <Typography variant="body1" component="span">
              {scheduledValue}
            </Typography>
            {enableToEditScheduledTime && (
              <EditIconButton
                onEdit={() => setEditArrivalTime(true)}
                tooltip="Edit arrival time"
              />
            )}
          </Box>
        </Box>
      )}
      <Collapse in={editArrivalTime} timeout={300}>
        <VisitDateAndArrivalTimeSetup
          cancelEditing={() => setEditArrivalTime(false)}
        />
      </Collapse>
    </Box>
  );
}

DateAndArrivalTimeFields.propTypes = {
  editArrivalTime: bool,
  enableToEditScheduledTime: bool,
  isExactTime: bool,
  setEditArrivalTime: func,
  scheduledValue: string,
};
