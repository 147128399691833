import { COLORS } from '../../../../../../../shared/styles/Theme';

const customInputStyles = {
  width: '100%',
  flex: '1 0 0',
  '&.disabled-multiselect': {
    '& .MuiInputLabel-root': {
      color: COLORS.black[200],
    },
  },
  '& .MuiInputLabel-root': {
    top: '-6px',
    left: '3px',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '19px',
    letterSpacing: '0em',
    color: COLORS.blue[500],
    '&.Mui-focused': {
      color: COLORS.blue[500],
    },
    '&[data-shrink=true]': {
      top: '-3px',
    },
  },
  '& .MuiFilledInput-root': {
    border: 'none',
    minWidth: '100px',
    borderRadius: '10px',
    fontSize: '15px',
    color: COLORS.blue[900],
    backgroundColor: COLORS.blue[50],
    alignItems: 'start',
    padding: '10px 16px',
    '&:hover, &.Mui-focused, &.Mui-disabled': {
      backgroundColor: COLORS.blue[50],
    },
    '&:before, &:after': {
      display: 'none',
    },
    '&:hover, &.Mui-focused': {
      backgroundColor: COLORS.blue[50],
    },
    '& fieldset': {
      border: 'none',
    },
    '& svg': {
      fill: COLORS.blue[300],
      fontSize: '17px',
    },
    '& .MuiFilledInput-input': {
      padding: 0,
      mb: '3px',
    },
  },
};

const customMultilinesInputStyles = {
  width: '100%',
  flex: '1 0 0',
  margin: '0 20px 16px 14px',
  '& .MuiInputLabel-root': {
    top: '-3px',
    left: '3px',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '19px',
    letterSpacing: '0em',
    color: COLORS.blue[500],
    '&.Mui-focused': {
      color: COLORS.blue[500],
    },
  },
  '&.MuiTextField-root': {
    display: 'flex',
    justifyContent: ' center',
    minHeight: '80px',
    height: '100%',
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: 0,
      right: 0,
      fontSize: '11px',
      fontWeight: 400,
      lineHeight: '19px',
      letterSpacing: '0em',
      color: COLORS.blue[500],
    },
  },
  '&.has-actions .MuiFilledInput-root': {
    padding: '16px 14px 40px 20px',
  },
  '& .MuiFilledInput-root': {
    border: 'none',
    minWidth: '100px',
    height: '100%',
    borderRadius: '10px',
    fontSize: '15px',
    color: COLORS.blue[900],
    backgroundColor: COLORS.blue[50],
    alignItems: 'start',
    padding: '16px 14px 0 20px',
    '&:before, &:after': {
      display: 'none',
    },
    '&:hover, &.Mui-focused, &.Mui-disabled': {
      backgroundColor: COLORS.blue[50],
    },
    '& .MuiFilledInput-input': {
      padding: 0,
    },
    '& ::-webkit-scrollbar': {
      width: '0.4em',
    },
    '& ::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '& ::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
    '& ::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
};

export { customInputStyles, customMultilinesInputStyles };
