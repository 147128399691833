import { COLORS } from '../../shared/styles/Theme';

export const chipComponentStyle = {
  cursor: 'inherit',
  padding: '2px 10px',
  fontSize: '14px',
  fontWeight: 400,
  borderRadius: '30px',
  color: COLORS.blue[900],
  background: '#EFF2F9',
};
export const chipClearIconStyle = {
  '&.MuiChip-deleteIcon': {
    fontSize: '18px',
    margin: '0 0 0 4px',
    color: COLORS.blue[300],
  },
};
