import { Box, Typography } from '@mui/material';
import { bool, func, number, string } from 'prop-types';
import React from 'react';
import CustomTimeSelect from '../../../../components/CustomDateAndTimePickers/CustomTimeSelect';
import NumericInput from '../../../../components/NumericInput';
import {
  columnTitle,
  customInputsStyles,
  visitDetailsCustomSelectStyles,
  visitInfoManagementColumnStyles,
} from './styles';

export default function ScheduledTimeFields({
  arrivalTime = '',
  arrivalTimeEnd = '',
  arrivalTimeStart = '',
  changeScheduledTimeValues = () => {},
  disableAllFields = false,
  duration = 0,
  exactTimeVisitEnd = '',
  exactTimeVisitStart = '',
  isExactTime = false,
}) {
  return (
    <Box sx={visitInfoManagementColumnStyles}>
      <Typography sx={columnTitle}>Scheduled Time</Typography>
      {isExactTime && (
        <CustomTimeSelect
          customStyles={visitDetailsCustomSelectStyles}
          disabled={disableAllFields}
          isRequired
          onSelectTimeValue={(value) =>
            changeScheduledTimeValues({ key: 'arrivalTimeStart', value })
          }
          placeHolder="Scheduled Visit Start Time"
          selectedTime={exactTimeVisitStart}
        />
      )}
      {isExactTime && (
        <CustomTimeSelect
          customStyles={visitDetailsCustomSelectStyles}
          disabled={disableAllFields}
          isRequired
          onSelectTimeValue={(value) =>
            changeScheduledTimeValues({ key: 'arrivalTimeStart', value })
          }
          placeHolder="Scheduled Visit End Time"
          selectedTime={exactTimeVisitEnd}
        />
      )}
      {!isExactTime && (
        <CustomTimeSelect
          customStyles={visitDetailsCustomSelectStyles}
          disabled={disableAllFields}
          isRequired
          onSelectTimeValue={(value) =>
            changeScheduledTimeValues({ key: 'arrivalTimeStart', value })
          }
          placeHolder="Scheduled Arrival Window Start"
          selectedTime={arrivalTimeStart}
        />
      )}
      {!isExactTime && (
        <CustomTimeSelect
          customStyles={visitDetailsCustomSelectStyles}
          disabled={disableAllFields}
          isRequired
          onSelectTimeValue={() => {}}
          placeHolder="Scheduled Arrival Window End"
          selectedTime={arrivalTimeEnd}
        />
      )}
      {!isExactTime && (
        <CustomTimeSelect
          customStyles={visitDetailsCustomSelectStyles}
          disabled={disableAllFields}
          isRequired
          onSelectTimeValue={() => {}}
          placeHolder="Scheduled Visit Start Time"
          selectedTime={arrivalTime}
        />
      )}
      <NumericInput
        additionalStyles={customInputsStyles}
        changeValue={() => {}}
        disabled={disableAllFields}
        label="Scheduled Visit Duration, min"
        step={5}
        value={duration || 0}
      />
    </Box>
  );
}

ScheduledTimeFields.propTypes = {
  arrivalTime: string,
  arrivalTimeEnd: string,
  arrivalTimeStart: string,
  changeScheduledTimeValues: func,
  disableAllFields: bool,
  duration: number,
  exactTimeVisitEnd: string,
  exactTimeVisitStart: string,
  isExactTime: bool,
};
