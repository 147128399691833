import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
// import Chevron from '../../../../../../../../img/icons/chevron-right.svg?react';
import moment from 'moment';
import { bool, func, instanceOf, number } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { ISO_DATE_ONLY_FORMAT, SHORT_DATE_FORMAT } from '../../../../../../../../shared/constants';
import {
  customAccordionStyles,
  customAccordionSummaryStyles,
  visitAccordionSummaryTitle,
} from '../styles';
import AvailableCaregiver from './AvailableCaregivers';
import SelectAlternativeVisitSlots from './SelectAlternativeVisitSlots';
import VisitActions from './VisitActions';

export default function VisitAccordion({
  deleteVisitFromSchedule = () => {},
  expanded = [],
  isUnscheduled = false,
  setExpanded = () => {},
  updateAvailabilityData = () => {},
  updateVisitData = () => {},
  visit = {},
  visitsCount = 0,
}) {
  const [newArrivalRange, setNewArrivalRange] = useState(null);
  const [availableCaregivers, setAvailableCaregivers] = useState(
    visit?.availableCaregivers || [],
  );
  const [preferredCaregiver, setPreferredCaregiver] = useState(
    visit?.preferredCaregiver || null,
  );

  const onExpand = () => {
    if (expanded.includes(visit.date)) {
      setExpanded(expanded.filter((item) => item !== visit.date));
    } else {
      setExpanded([...expanded, visit.date]);
    }
  };

  const visitTimeSlot = visit.exactTimeSlot
    ? visit.exactTimeSlot
    : `${visit.arrivalStart} - ${visit.arrivalEnd}`;

  const changeAvailableCareGivers = (caregivers) => {
    setPreferredCaregiver(null);
    setAvailableCaregivers(caregivers);
  };

  const confirmNewArrivalTime = () => {
    updateVisitData({ visit, newArrivalRange, preferredCaregiver });
  };

  const changePreferredCaregiver = (caregiver) => {
    updateAvailabilityData({ visit, preferredCaregiver: caregiver });
    setPreferredCaregiver(caregiver);
  };

  const preSetPreferredCaregiver = (caregiver) => {
    setPreferredCaregiver(caregiver);
  };

  const availableOtherDates = useMemo(
    () => visit?.otherDates.filter(({ available }) => available),
    [visit],
  );

  return (
    <Accordion
      elevation={0}
      key={visit.date}
      expanded={expanded.includes(visit.date)}
      sx={customAccordionStyles}
    >
      <AccordionSummary
        // expandIcon={<Chevron onClick={onExpand} />}
        aria-controls="panel1bh-content"
        sx={customAccordionSummaryStyles}
      >
        <Box sx={visitAccordionSummaryTitle} onClick={onExpand}>
          <Typography
            sx={{ lineHeight: '32px', mr: '12px', cursor: 'pointer' }}
            variant="h5"
          >
            {`${moment(visit.date, ISO_DATE_ONLY_FORMAT).format(
              SHORT_DATE_FORMAT,
            )}, ${visitTimeSlot}`}
          </Typography>
          <VisitActions
            confirmArrivalTime={confirmNewArrivalTime}
            deleteVisitFromSchedule={deleteVisitFromSchedule}
            disableDelete={visitsCount < 2}
            disabledConfirmation={!newArrivalRange}
            isUnscheduledVisit={isUnscheduled}
            hasNoAvailableWindows={
              !visit?.available &&
              !visit?.availableWindows?.length &&
              !availableOtherDates?.length
            }
            showActions={expanded.includes(visit.date)}
          />
        </Box>
      </AccordionSummary>
      {!isUnscheduled && (
        <AvailableCaregiver
          availableCaregivers={availableCaregivers}
          preferredCaregiver={preferredCaregiver}
          setPreferredCaregiver={changePreferredCaregiver}
        />
      )}
      {!isUnscheduled && <Box sx={{ height: '20px' }} />}
      {isUnscheduled && (
        <AccordionDetails sx={{ padding: '0 16px 16px 0' }}>
          {!!visit?.availableWindows?.length && (
            <SelectAlternativeVisitSlots
              availableSlots={visit?.availableWindows}
              isExactTime={!!visit?.exactTimeSlot}
              setNewVisitSlot={setNewArrivalRange}
              selectedNewSlot={newArrivalRange}
              setAvailableCaregivers={changeAvailableCareGivers}
              title="Available arrival windows:"
            />
          )}
          {!!availableOtherDates?.length && (
            <SelectAlternativeVisitSlots
              availableSlots={availableOtherDates}
              isExactTime={!!visit?.exactTimeSlot}
              setNewVisitSlot={setNewArrivalRange}
              selectedNewSlot={newArrivalRange}
              setAvailableCaregivers={changeAvailableCareGivers}
              title="Other dates:"
            />
          )}
          {!!availableCaregivers?.length && (
            <AvailableCaregiver
              availableCaregivers={availableCaregivers}
              preferredCaregiver={preferredCaregiver}
              setPreferredCaregiver={preSetPreferredCaregiver}
            />
          )}
          {!visit?.availableWindows?.length && !availableOtherDates?.length && (
            <Typography variant="body1" sx={{ mb: '12px', ml: '26px' }}>
              {`Unfortunately, we couldn't find alternative timeslots within 4h
                  range and on previous/next working day. Please try to choose a
                  different timeslot.`}
            </Typography>
          )}
        </AccordionDetails>
      )}
    </Accordion>
  );
}

VisitAccordion.propTypes = {
  deleteVisitFromSchedule: func,
  expanded: instanceOf(Array),
  isUnscheduled: bool,
  setExpanded: func,
  updateAvailabilityData: func,
  updateVisitData: func,
  visit: instanceOf(Object),
  visitsCount: number,
};
