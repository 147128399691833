import { Box } from '@mui/material';
import CustomBreadcrumbs from '../../../../../../../pages/ClientDetails/components/Breadcrumbs';
import CustomStepper from '../../../../../../../pages/ClientDetails/components/CustomStepper';
import {
  ClientDetailsContext,
  CreateOneTimeVisitContext,
} from '../../../../../../../pages/ClientDetails/context';
import { createOneTimeVisitSteps } from '../../../../../../../pages/ClientDetails/views/Calendar/constants';
import React, { useContext, useState } from 'react';
import ErrorAlert from '../../../../../../../components/ErrorAlert';
import CheckAvailability from './CheckAvailability';
import TasksManagement from './TasksManagement';
import VisitParameters from './VisitParameters';
import {
  createOneTimeVisitMultistepHeader,
  createOneTimeVisitMultistepWrapper,
} from './styles';

export default function CreateOneTimeVisitMultistep() {
  const [errorData, setErrorData] = useState(null);
  const {
    availabilityScheduling,
    availableVisitTasks,
    closeDialog,
    completedSteps,
    goToEditTasks,
    isSelectedTimeOutOfRange,
    pastTimeError,
    setAvailabilityScheduling,
    setAvailableVisitTasks,
    setIsSelectedTimeOutOfRange,
    setPastTimeError,
    setStep,
    setStepDataLoading,
    setVisitParams,
    step,
    stepDataLoading,
    visitParams,
  } = useContext(CreateOneTimeVisitContext);
  const { resetToInitialView } = useContext(ClientDetailsContext);
  const getStepView = () => {
    if (step === 0) {
      return (
        <VisitParameters
          isSelectedTimeOutOfRange={isSelectedTimeOutOfRange}
          pastTimeError={pastTimeError}
          setIsSelectedTimeOutOfRange={setIsSelectedTimeOutOfRange}
          setPastTimeError={setPastTimeError}
          setVisitParams={setVisitParams}
          visitParams={visitParams}
        />
      );
    }
    if (step === 1) {
      return (
        <CheckAvailability
          availabilityScheduling={availabilityScheduling}
          completedSteps={completedSteps}
          setAvailabilityScheduling={setAvailabilityScheduling}
          setStepDataLoading={setStepDataLoading}
          setVisitParams={setVisitParams}
          visitParams={visitParams}
        />
      );
    }
    if ([2, 3].includes(step)) {
      return (
        <TasksManagement
          availabilityScheduling={availabilityScheduling}
          goToEditTasks={goToEditTasks}
          setAvailableVisitTasks={setAvailableVisitTasks}
          visitParams={visitParams}
          availableVisitTasks={availableVisitTasks}
        />
      );
    }
    return false;
  };

  return (
    <Box sx={createOneTimeVisitMultistepWrapper}>
      <Box sx={createOneTimeVisitMultistepHeader}>
        <CustomBreadcrumbs
          closeDialog={closeDialog}
          resetToInitialView={resetToInitialView}
          title="Add Visit"
        />
        <CustomStepper
          changeStep={setStep}
          completedSteps={completedSteps}
          currentStep={step}
          disabled={stepDataLoading}
          steps={createOneTimeVisitSteps}
        />
      </Box>
      {!!errorData && (
        <Box sx={{ maxWidth: '500px', margin: 'auto' }}>
          <ErrorAlert error={errorData} closeError={() => setErrorData(null)} />
        </Box>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        {getStepView()}
      </Box>
    </Box>
  );
}
