import { Box } from '@mui/material';
import preferredCaregiverIcon from '../../../../img/icons/preferred-icon.svg';
import primaryCaregiverIcon from '../../../../img/icons/primary-visit-icon.svg';
import shadowCaregiverIcon from '../../../../img/icons/shadow-visit-black-icon.svg';
import { instanceOf } from 'prop-types';
import React from 'react';
import {
  getIconComponent,
  getOneTimeOrRepeatedIcon,
} from '../../../../shared/utils/common';
import CustomTooltip from '../../../../components/CustomTooltip';

export default function EventCardInfoIcons({ eventData = {} }) {
  const showPrimaryVisitIcon =
    !eventData?.isShadowVisit &&
    eventData?.shadowCaregiver &&
    eventData?.caregiver;
  const showPreferredCaregiverIcon =
    eventData?.preferredCaregiver &&
    eventData?.caregiver?.id === eventData?.preferredCaregiver?.id &&
    !eventData?.isShadowVisit;
  const isShadowVisitIcon =
    eventData?.shadowCaregiver && eventData?.isShadowVisit;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: eventData.duration < 45 ? 'row' : 'column',
        marginTop: '8px',
        alignItems: eventData.duration < 45 ? 'center' : 'end',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '6px',
          justifyContent: 'flex-end',
        }}
      >
        <CustomTooltip title="Care Program Visit">
          <div>{getOneTimeOrRepeatedIcon(eventData?.repeat)}</div>
        </CustomTooltip>
        <CustomTooltip title={eventData?.type}>
          <div>{getIconComponent(eventData?.type)}</div>
        </CustomTooltip>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '6px',
          justifyContent: 'end',
        }}
      >
        {showPreferredCaregiverIcon && (
          <CustomTooltip title="Preferred Caregiver">
            <img src={preferredCaregiverIcon} alt="preferredCaregiverIcon" />
          </CustomTooltip>
        )}
        {showPrimaryVisitIcon && (
          <CustomTooltip title="Primary Caregiver">
            <img src={primaryCaregiverIcon} alt="primaryCaregiverIcon" />
          </CustomTooltip>
        )}
        {isShadowVisitIcon && (
          <CustomTooltip title="Shadow visit">
            <img src={shadowCaregiverIcon} alt="shadowCaregiverIcon" />
          </CustomTooltip>
        )}
      </Box>
    </Box>
  );
}

EventCardInfoIcons.propTypes = {
  eventData: instanceOf(Object),
};
