export const getClassNames = ({ disabled, isToday, selected }) => {
  const names = [''];
  if (disabled) names.push('disabled-date');
  if (isToday) names.push('today-date');
  if (selected) names.push('selected-date');

  return names.join(' ');
};

export const getTooltipTitle = ({ isToday }) => {
  if (isToday) return 'Today';
  return 'Available';
};
