import { Box } from '@mui/material';
import preferredCaregiverIcon from '../../../../img/icons/preferred-icon.svg';
import primaryCaregiverIcon from '../../../../img/icons/primary-visit-icon.svg';
import shadowCaregiverIcon from '../../../../img/icons/shadow-visit-black-icon.svg';
import { instanceOf } from 'prop-types';
import React from 'react';
import { useParams } from 'react-router';
import {
  getIconComponent,
  getOneTimeOrRepeatedIcon,
} from '../../../../shared/utils/common';
import CustomTooltip from '../../../../components/CustomTooltip';

export default function EventCardInfoIcons({ eventData = {} }) {
  const { id } = useParams();
  const isPrimaryCaregiver =
    eventData?.caregiver.id === id && eventData?.shadowCaregiver;
  const isPreferredCaregiver =
    eventData?.caregiver.id === id && eventData?.preferredCaregiver?.id;
  const isShadowCaregiver =
    eventData?.shadowCaregiver && eventData?.shadowCaregiver.id === id;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: eventData.duration < 45 ? 'row' : 'column',
        marginTop: '8px',
        alignItems: eventData.duration < 45 ? 'center' : 'start',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '6px',
          justifyContent: 'flex-end',
        }}
      >
        <CustomTooltip title="Care Program Visit">
          {getOneTimeOrRepeatedIcon(eventData?.repeat)}
        </CustomTooltip>
        <CustomTooltip title={eventData?.type}>
          {getIconComponent(eventData?.type)}
        </CustomTooltip>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '6px',
        }}
      >
        {isPreferredCaregiver && (
          <CustomTooltip title="Preferred Caregiver">
            <img src={preferredCaregiverIcon} alt="arrivalIcon" />
          </CustomTooltip>
        )}
        {isPrimaryCaregiver && (
          <CustomTooltip title="Primary Caregiver">
            <img src={primaryCaregiverIcon} alt="arrivalIcon" />
          </CustomTooltip>
        )}
        {isShadowCaregiver && (
          <CustomTooltip title="Shadow visit">
            <img src={shadowCaregiverIcon} alt="arrivalIcon" />
          </CustomTooltip>
        )}
      </Box>
    </Box>
  );
}

EventCardInfoIcons.propTypes = {
  eventData: instanceOf(Object),
};
