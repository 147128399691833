import { COLORS } from '../../shared/styles/Theme';

export const customTableStyles = [
  {
    '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeader--moving': {
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-virtualScroller': {
      boxSizing: 'border-box',
      maxHeight: 'calc(100vh - 400px)',
      overflowX: 'hidden',
      overflowY: 'auto !important',
      padding: '1px',
      scrollbarColor: '#888 #f1f1f1',
      scrollbarWidth: 'thin',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
      width: '0.4em',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
];

export const noDataTableHintStyles = {
  mt: '12px',
  color: COLORS.blue[500],
  '& span': {
    color: COLORS.blue[700],
    cursor: 'pointer',
  },
};
