import { Box, Stack } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetCaregiverInfoQuery } from '../../../../api/Caregivers/api';
import {
  useApprovePendingTimeOffRequestMutation,
  useCancelPendingTimeOffRequestMutation,
  useConfirmCancelingApprovedTimeOffRequestMutation,
  useGetCaregiverTimeOffRequestsQuery,
  useGetTimeOffRequestsTypesQuery,
} from '../../../../api/TimeOffRequest/api';
import { TimeOffRequestsContext } from '../../../../pages/CaregiverDetails/context';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router';
import { getNoData } from '../../../../shared/utils/common';
import AlertContext from '../../../../components/Alert';
import CustomTable from '../../../../components/Table';
import ChangeRequestStatusPossibility from './components/ChangeRequestStatusPossibility';
import CreatePtoAndCallouts from './components/CreatePtoAndCallouts';
import PtoHistory from './components/PtoHistory';
import ViewActions from './components/ViewActions';
import { columns, timeOffRequestTypes } from './constants';
import { getTimeOffRequestsTableStyles } from './styles';

// import { parseNewRequestData, parseTimeOffRequestsData } from './utils';

function PtoAndCallouts() {
  const { setAlert } = useContext(AlertContext);
  const apiRef = useGridApiRef();
  const { id } = useParams();
  const { tabSubView } = useParams();
  const { data: userData, error: getUserDataError } =
    useGetCaregiverInfoQuery(id);
  const [openAddRequestDialogType, setOpenAddRequestDialogType] =
    useState(null);
  const [
    changeRequestStatusPossibilityData,
    setChangeRequestStatusPossibilityData,
  ] = useState(null);
  // TODO: Should be changed with logic to continue time off requests
  const creatingRequestInitialData = null;

  const { data: timeOffRequestsTypes, error: getTimeOffRequestsTypesError } =
    useGetTimeOffRequestsTypesQuery();
  const {
    data: timeOffRequests,
    isFetching,
    error: getCaregiverTimeOffRequestsError,
  } = useGetCaregiverTimeOffRequestsQuery(id);
  const [
    cancelPendingTimeOffRequest,
    {
      error: cancelPendingTimeOffRequestError,
      isLoading: cancelPendingTimeOffRequestWaiting,
    },
  ] = useCancelPendingTimeOffRequestMutation();

  const [
    confirmCancelingApprovedTimeOffRequest,
    {
      error: confirmCancelingApprovedTimeOffRequestError,
      isLoading: confirmCancelingApprovedTimeOffRequestWaiting,
    },
  ] = useConfirmCancelingApprovedTimeOffRequestMutation();

  const [
    approvePendingTimeOffRequest,
    {
      error: approvePendingTimeOffRequestError,
      isLoading: approvePendingTimeOffRequestWaiting,
    },
  ] = useApprovePendingTimeOffRequestMutation();

  useEffect(() => {
    if (
      approvePendingTimeOffRequestError ||
      cancelPendingTimeOffRequestError ||
      confirmCancelingApprovedTimeOffRequestError ||
      getCaregiverTimeOffRequestsError ||
      getTimeOffRequestsTypesError ||
      getUserDataError
    ) {
      const errorData =
        getTimeOffRequestsTypesError ||
        cancelPendingTimeOffRequestError ||
        getCaregiverTimeOffRequestsError ||
        approvePendingTimeOffRequestError ||
        confirmCancelingApprovedTimeOffRequestError ||
        getUserDataError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    approvePendingTimeOffRequestError,
    cancelPendingTimeOffRequestError,
    confirmCancelingApprovedTimeOffRequestError,
    getCaregiverTimeOffRequestsError,
    getTimeOffRequestsTypesError,
    getUserDataError,
    setAlert,
  ]);
  const closeAddRequestDialog = useCallback(() => {
    setOpenAddRequestDialogType(null);
  }, [setOpenAddRequestDialogType]);

  const showCheckPossibilityView = useCallback(
    () => setOpenAddRequestDialogType(timeOffRequestTypes.checkPossibility),
    [setOpenAddRequestDialogType],
  );

  const cancelPendingRequest = useCallback(
    (requestId) => cancelPendingTimeOffRequest(requestId),
    [cancelPendingTimeOffRequest],
  );

  const checkCancelingApprovedRequest = useCallback(
    (requestId) =>
      setChangeRequestStatusPossibilityData({
        actionType: 'cancelRequest',
        requestId,
      }),
    [],
  );

  const checkApprovingPendingRequest = useCallback(
    (requestId) =>
      setChangeRequestStatusPossibilityData({
        actionType: 'approveRequest',
        requestId,
      }),
    [setChangeRequestStatusPossibilityData],
  );

  const confirmRequestStatusChanges = useCallback(
    (requestId) => {
      if (changeRequestStatusPossibilityData.actionType === 'approveRequest') {
        approvePendingTimeOffRequest({ requestId });
      }
      if (changeRequestStatusPossibilityData.actionType === 'cancelRequest') {
        confirmCancelingApprovedTimeOffRequest({ requestId });
      }
      setChangeRequestStatusPossibilityData(null);
    },
    [
      approvePendingTimeOffRequest,
      changeRequestStatusPossibilityData,
      confirmCancelingApprovedTimeOffRequest,
      setChangeRequestStatusPossibilityData,
    ],
  );

  const memoizedProviderValue = useMemo(
    () => ({
      cancelPendingRequest,
      checkApprovingPendingRequest,
      checkCancelingApprovedRequest,
      closeAddRequestDialog,
      creatingRequestInitialData,
      openAddRequestDialogType,
      showCheckPossibilityView,
      timeOffRequests,
      timeOffRequestsTypes,
    }),
    [
      cancelPendingRequest,
      checkApprovingPendingRequest,
      checkCancelingApprovedRequest,
      closeAddRequestDialog,
      creatingRequestInitialData,
      openAddRequestDialogType,
      showCheckPossibilityView,
      timeOffRequests,
      timeOffRequestsTypes,
    ],
  );

  const isDataLoading = useMemo(
    () =>
      isFetching ||
      cancelPendingTimeOffRequestWaiting ||
      confirmCancelingApprovedTimeOffRequestWaiting ||
      approvePendingTimeOffRequestWaiting,
    [
      approvePendingTimeOffRequestWaiting,
      cancelPendingTimeOffRequestWaiting,
      confirmCancelingApprovedTimeOffRequestWaiting,
      isFetching,
    ],
  );

  return (
    <TimeOffRequestsContext.Provider value={memoizedProviderValue}>
      <Stack sx={{ maxWidth: '100%' }}>
        {!!openAddRequestDialogType && (
          <CreatePtoAndCallouts caregiverData={{ ...userData, id }} />
        )}
        <ChangeRequestStatusPossibility
          cancelChanges={() => setChangeRequestStatusPossibilityData(null)}
          changingStatusData={changeRequestStatusPossibilityData}
          confirmChanges={confirmRequestStatusChanges}
        />
        <ViewActions
          addRequest={() =>
            setOpenAddRequestDialogType(timeOffRequestTypes.settingParams)
          }
        />
        <Box>
          {tabSubView === 'current' ? (
            <CustomTable
              apiRef={apiRef}
              columns={columns}
              customStyles={getTimeOffRequestsTableStyles(
                !timeOffRequests?.length,
              )}
              headerHeight={34}
              isLoading={isDataLoading || approvePendingTimeOffRequestWaiting}
              noRowsOverlay={() =>
                getNoData({ title: 'No PTO or Callouts found' })
              }
              onRowClick={() => {}}
              rows={timeOffRequests || []}
              withoutPagination
            />
          ) : (
            <PtoHistory />
          )}
        </Box>
      </Stack>
    </TimeOffRequestsContext.Provider>
  );
}

export default PtoAndCallouts;
