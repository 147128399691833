import { Alert, Box, Divider } from '@mui/material';
import { func, instanceOf } from 'prop-types';
import React from 'react';
import { COLORS } from '../../../../../../../../shared/styles/Theme';
import { pluralize } from '../../../../../../../../shared/utils/pluralize';
import { checkingResultsColumns } from '../constants';
import {
  checkAvailabilityColumnsWrapper,
  checkAvailabilityCustomAlert,
} from '../styles';
import VisitAccordion from './VisitAccordion';

export default function AvailabilityColumns({
  availabilityData = {},
  deleteVisitFromSchedule = () => {},
  expanded = [],
  setAvailabilityScheduling = () => {},
  setExpanded = () => {},
}) {
  const updateVisitData = ({ visit, newArrivalRange, preferredCaregiver }) => {
    const unAvailableScheduleList = availabilityData.unAvailableSchedule.filter(
      (item) => item.date !== visit.date,
    );
    const newAvailableVisitData = {
      ...visit,
      ...newArrivalRange,
      preferredCaregiver,
    };
    setExpanded(expanded.filter((item) => item !== visit.date));
    setAvailabilityScheduling({
      availableSchedule: [
        ...availabilityData.availableSchedule,
        newAvailableVisitData,
      ],
      unAvailableSchedule: unAvailableScheduleList,
    });
  };

  const updateAvailabilityData = ({ visit, preferredCaregiver }) => {
    const visitIndex = availabilityData?.availableSchedule.findIndex(
      (item) => item.date === visit.date,
    );
    const newData = [...availabilityData.availableSchedule];
    newData[visitIndex] = {
      ...visit,
      preferredCaregiver,
    };
    setAvailabilityScheduling({
      ...availabilityData,
      availableSchedule: newData,
    });
  };

  const visitsCounter =
    (availabilityData?.availableSchedule?.length || 0) +
    (availabilityData?.unAvailableSchedule?.length || 0);
  return (
    <Box sx={checkAvailabilityColumnsWrapper}>
      {checkingResultsColumns.map(
        ({ bgColor, color, dataKey, icon, getTitle, type }) =>
          availabilityData &&
          !!availabilityData[dataKey]?.length && (
            <Box
              key={dataKey}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <Alert
                icon={icon}
                severity={type}
                sx={{
                  ...checkAvailabilityCustomAlert,
                  backgroundColor: bgColor,
                  color,
                }}
              >
                {getTitle(
                  pluralize(availabilityData[dataKey]?.length, 'visit'),
                )}
              </Alert>
              <Divider sx={{ color: COLORS.blue[200] }} />
              {availabilityData[dataKey]?.map((item) => (
                <VisitAccordion
                  key={item.date}
                  scheduleList={availabilityData[dataKey]}
                  deleteVisitFromSchedule={() =>
                    deleteVisitFromSchedule({ dataKey, date: item.date })
                  }
                  expanded={expanded}
                  isUnscheduled={dataKey === 'unAvailableSchedule'}
                  setExpanded={setExpanded}
                  updateAvailabilityData={updateAvailabilityData}
                  updateVisitData={updateVisitData}
                  visit={item}
                  visitsCount={visitsCounter}
                />
              ))}
            </Box>
          ),
      )}
    </Box>
  );
}

AvailabilityColumns.propTypes = {
  availabilityData: instanceOf(Object),
  deleteVisitFromSchedule: func,
  expanded: instanceOf(Array),
  setAvailabilityScheduling: func,
  setExpanded: func,
};
