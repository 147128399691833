import { Box, Typography } from '@mui/material';
import React from 'react';
import HistoryBulletList from '../../../../../components/VisitInfoTypeProvider/components/VisitDetails/VisitHistory/HistoryBulletList';

export const PAGE_SIZE = 10;

export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

export const trackedEvents = [
  'actualTimeEnd',
  'actualTimeStart',
  'arrivalTime',
  'caregiver',
  'exactTime',
  'shadowCaregiver',
  'preferredCaregiver',
  'status',
  'task',
  'visitNotes',
  'violatedHardConstraints',
  'violatedSoftConstraints',
  'visitDetails',
];

export const skippedInsertOperationEvents = [
  'exactTime',
  'visitDetails',
  'visitNotes',
];

export const historyColumns = [
  {
    field: 'dateTime',
    headerName: 'Date & Time',
    sortable: false,
    minWidth: 280,
    renderCell: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{row.dateCreated}</Typography>
      </Box>
    ),
  },
  {
    field: 'changes',
    headerName: 'Changes',
    minWidth: 740,
    sortable: false,
    renderCell: ({ value }) => <HistoryBulletList changes={value} />,
  },
  {
    field: 'user',
    headerName: 'User',
    sortable: false,
    width: 330,
    renderCell: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{row.author}</Typography>
      </Box>
    ),
  },
];
