import { useGridApiRef } from '@mui/x-data-grid';
import { useGetHolidaysQuery } from '../../../../../../api/Administration/api';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  ISO_DATE_ONLY_FORMAT,
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
} from '../../../../../../shared/constants';
import { getNoData } from '../../../../../../shared/utils/common';
import AlertContext from '../../../../../../components/Alert';
import CustomTable from '../../../../../../components/Table';
import { DEFAULT_SORT_MODAL, columns } from '../../constants';

export default function NationalHolidays() {
  const { setAlert } = useContext(AlertContext);
  const [sortOption, setSortOption] = useState(DEFAULT_SORT_MODAL);
  const updateSorting = useCallback((data) => setSortOption(data[0]), []);
  const { data, isLoading, error } = useGetHolidaysQuery();
  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);
  const sortedData = data
    // because in DB we have noValid dates
    ?.filter((item) =>
      moment(item?.holidayDate, ISO_DATE_ONLY_FORMAT, true).isValid(),
    )
    ?.slice()
    .sort((a, b) => moment(a?.holidayDate) - moment(b?.holidayDate));
  const apiRef = useGridApiRef();
  return (
    <CustomTable
      apiRef={apiRef}
      columns={columns}
      headerHeight={44}
      noRowsOverlay={() => getNoData({ title: 'No holidays yet' })}
      isLoading={isLoading}
      rowHeight={80}
      rows={sortedData}
      pageSize={PAGE_SIZE}
      setSortModelState={updateSorting}
      sortModel={[sortOption]}
      pageSizeOptions={PAGE_SIZE_OPTIONS}
    />
  );
}
