import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryRealDB } from '../../api/baseQueryBE';
import { filterCarePlanTasksResponse } from './utils';

export const CARE_PLAN_API_KEY = 'carePlanApi';

const carePlanApi = createApi({
  reducerPath: CARE_PLAN_API_KEY,
  baseQuery: baseQueryRealDB,
  endpoints: (builder) => ({
    getTasksList: builder.query({
      query: () => ({
        url: 'careplan/task-types',
        method: 'GET',
      }),
      transformResponse: (response) => response?.schema,
    }),
    getClientTasksList: builder.query({
      query: ({ clientId }) => ({
        url: `careplan/${clientId}`,
        method: 'GET',
      }),
      transformResponse: (response, meta, { carePlanFilters, sortOption }) =>
        carePlanFilters || sortOption
          ? filterCarePlanTasksResponse({
              response,
              carePlanFilters,
              sortOption,
            })
          : response,
      providesTags: () => [{ type: 'clientsTasks', id: 'ALL' }],
    }),
    updateCarePlanTasks: builder.mutation({
      query: ({ clientId, payload }) => ({
        url: `careplan/${clientId}`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'clientsTasks', id: 'ALL' }],
    }),
    getClientHistory: builder.query({
      query: (clientId) => ({
        url: `careplan/${clientId}/history`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'clientsTasks', id: 'ALL' }],
    }),
  }),
});
export const {
  useGetClientTasksListQuery,
  useGetTasksListQuery,
  useUpdateCarePlanTasksMutation,
  useGetClientHistoryQuery,
} = carePlanApi;

export default carePlanApi;
