import { isItemsContainSubstring } from '../../../../shared/utils/common';

export const filterTasks = (
  { task, levelOfAssistance, category, days, timeOfDay, visitType },
  tasksList,
) => {
  let data = [...tasksList];
  if (data.length && task.length) {
    data = data.filter((item) => isItemsContainSubstring(item.name, task));
  }
  if (data.length && levelOfAssistance.length) {
    data = data.filter((item) =>
      levelOfAssistance.includes(item.levelOfAssistance),
    );
  }
  if (data.length && category.length) {
    data = data.filter((item) => category.includes(item.category));
  }
  if (data.length && days.length) {
    data = data.filter((item) =>
      days.some((day) => item.days.indexOf(day) >= 0),
    );
  }
  if (data.length && timeOfDay.length) {
    data = data.filter((item) =>
      timeOfDay.some((time) => item.timesOfDay.indexOf(time) >= 0),
    );
  }
  if (data.length && visitType.length) {
    data = data.filter((item) =>
      visitType.some((type) => item.visitTypes.indexOf(type) >= 0),
    );
  }
  return data;
};
