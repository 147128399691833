import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { bool, func, instanceOf, number } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { combineTeamsAndTerritory } from '../../../shared/utils/common';

const bfsSearch = (graph, target) => {
  const queue = [...graph];
  while (queue.length > 0) {
    const currNode = queue.shift();
    if (currNode.id === target.id) {
      return currNode;
    }
    if (currNode.teams) {
      queue.push(...currNode.teams);
    }
  }
  return null; // Target node not found
};
export default function LocationsTree({
  actualSelected = [],
  allTeamCount = 0,
  isCaregiverOrClient = false,
  locationsList = [],
  selectedLocations = [],
  setSelectedLocations = () => {},
}) {
  useEffect(() => {
    setSelectedLocations(actualSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const combineTerritoryAndTeams = combineTeamsAndTerritory(locationsList);
  // Retrieve all ids from node to his children's
  function getAllIds(node, idList = []) {
    idList.push(node);
    if (node.teams) {
      node.teams.forEach((child) => getAllIds(child, idList));
    }
    return idList;
  }

  // Get IDs of all children from specific node
  const getAllChild = (item) => getAllIds(bfsSearch(locationsList, item));
  // Get all father IDs from specific node
  const getAllFathers = (item, list = []) => {
    const node = bfsSearch(locationsList, item);
    if (node && node.territory) {
      const parentNode = locationsList.find(
        (location) => location.id === node.territory.id,
      );
      if (parentNode) {
        list.push(parentNode);
        return getAllFathers(parentNode, list);
      }
    }
    return list;
  };

  function isAllChildrenChecked(node, list) {
    const allChild = getAllChild(node);
    const nodeIdIndex = allChild?.map(({ id }) => id).indexOf(node.id);
    allChild.splice(nodeIdIndex, 1);
    return allChild.every((nodeId) =>
      selectedLocations
        .map(({ id }) => id)
        .concat(list.map(({ id }) => id))
        .includes(nodeId.id),
    );
  }

  const handleNodeSelect = (event, node) => {
    event.stopPropagation();
    const allChild = getAllChild(node);
    const fathers = getAllFathers(node);
    if (selectedLocations.some(({ id }) => id === node.id)) {
      // Need to de-check
      setSelectedLocations((prevSelectedLocations) =>
        prevSelectedLocations.filter(
          ({ id }) =>
            !allChild.concat(fathers).some(({ id: childId }) => childId === id),
        ),
      );
    } else {
      // Need to check
      const ToBeChecked = allChild;
      fathers.forEach((item) => {
        if (isAllChildrenChecked(bfsSearch(locationsList, item), ToBeChecked)) {
          ToBeChecked.push(item);
        }
      });
      setSelectedLocations((prevSelectedLocations) => [
        ...new Set([...prevSelectedLocations].concat(ToBeChecked)),
      ]);
    }
  };

  const handleExpandClick = (e, node) => {
    // prevent the click event from propagating to the checkbox
    if (!node) {
      return true;
    }
    if (!node.territory && node.teams.length > 0) {
      e.stopPropagation();
    } else handleNodeSelect(e, node);
    return true;
  };
  const getNodeCheckState = (node) => {
    if (!node.teams || node.teams.length === 0) {
      return selectedLocations.some(({ id }) => id === node.id)
        ? 'checked'
        : 'unchecked';
    }

    const childrenCheckStates = node.teams.map((child) =>
      getNodeCheckState(child),
    );
    const allChecked = childrenCheckStates.every(
      (state) => state === 'checked',
    );
    const noneChecked = childrenCheckStates.every(
      (state) => state === 'unchecked',
    );

    if (allChecked) return 'checked';
    if (noneChecked) return 'unchecked';
    return 'indeterminate';
  };
  const renderTree = (node) => {
    const checkState = getNodeCheckState(node);
    return (
      <TreeItem
        key={node.id}
        itemId={node.id}
        onClick={(event) => handleExpandClick(event, node)}
        label={
          <>
            <Checkbox
              checked={selectedLocations.some(({ id }) => id === node.id)}
              tabIndex={-1}
              indeterminate={checkState === 'indeterminate'}
              disableRipple
              onClick={(event) => handleNodeSelect(event, node)}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography>{node.name}</Typography>
              {node.teams && (
                <Typography sx={{ fontSize: '3px' }}>{'\u2B24'}</Typography>
              )}
              <Typography>{node.teams && node.teams.length}</Typography>
            </Box>
          </>
        }
      >
        {Array.isArray(node.teams) ? (
          <Box sx={{ ml: '20px' }}>
            {node.teams.map((child) => renderTree(child))}
          </Box>
        ) : null}
      </TreeItem>
    );
  };
  const selectedTeamsLength = selectedLocations.filter(
    (loc) => loc.territory,
  ).length;
  const [allTeamsChecked, setAllTeamsChecked] = useState(
    allTeamCount === selectedTeamsLength,
  );
  const [isEmptyTeamChecked, setIsEmptyTeamChecked] = useState(
    selectedLocations.includes('Empty Team'),
  );
  useEffect(() => {
    setAllTeamsChecked(allTeamCount === selectedTeamsLength);
    setIsEmptyTeamChecked(selectedLocations.includes('Empty Team'));
  }, [allTeamCount, selectedLocations, selectedTeamsLength]);

  const handleAllTeamsChange = (event) => {
    if (event.target.checked) {
      setSelectedLocations([...combineTerritoryAndTeams, 'Empty Team']);
      setIsEmptyTeamChecked(true);
    } else {
      setSelectedLocations([]);
      setIsEmptyTeamChecked(false);
    }
    setAllTeamsChecked(event.target.checked);
  };
  const handleEmptyTeamsChange = (event) => {
    if (event.target.checked) {
      setSelectedLocations([...selectedLocations, 'Empty Team']);
    } else {
      setSelectedLocations(
        selectedLocations.filter((team) => team !== 'Empty Team'),
      );
    }
    setIsEmptyTeamChecked(event.target.checked);
  };
  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          value="All Teams"
          control={
            <Checkbox
              checked={allTeamsChecked}
              onClick={(event) => handleAllTeamsChange(event)}
            />
          }
          label="All Teams"
        />

        {isCaregiverOrClient && (
          <FormControlLabel
            value="Empty Teams"
            control={
              <Checkbox
                checked={isEmptyTeamChecked}
                onClick={(event) => handleEmptyTeamsChange(event)}
              />
            }
            label="Empty Teams"
          />
        )}
      </Box>
      <SimpleTreeView
        sx={{ maxWidth: '600px' }}
        multiSelect
        defaultcollapseicon={<ExpandMoreIcon />}
        defaultexpandicon={<ChevronRightIcon />}
        selected={selectedLocations.map(({ id }) => id)}
      >
        {locationsList.map((node) => renderTree(node))}
      </SimpleTreeView>
    </Box>
  );
}

LocationsTree.propTypes = {
  actualSelected: instanceOf(Array),
  allTeamCount: number,
  isCaregiverOrClient: bool,
  locationsList: instanceOf(Array),
  selectedLocations: instanceOf(Array),
  setSelectedLocations: func,
};
