import { COLORS } from '../../../../../../../../shared/styles/Theme';

export const customUpdateProgramTableStyles = [
  {
    '& .MuiDataGrid-virtualScroller': {
      maxHeight: '100%',
    },
    '& .MuiDataGrid-row.shifted-visit': {
      borderTop: 'none',
    },
    '& .MuiDataGrid-row.shifted-visit .MuiDataGrid-cell:not(:first-of-type)': {
      borderTop: `1px solid ${COLORS.blue[200]}`,
    },
    '& .MuiDataGrid-cell:first-of-type': {
      padding: '0 16px 0 40px',
    },
    '& .MuiDataGrid-cell:last-of-type': {
      padding: '0 28px 0 16px',
    },
    '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-columnHeader--moving': {
      backgroundColor: 'transparent',
    },
  },
];
