import { COLORS } from '../../shared/styles/Theme';

const customSelectStyles = {
  minWidth: '80px',
  fontSize: '15px',
  height: '40px',
  color: `${COLORS.blue[900]}`,
  borderRadius: '8px',
  '.MuiSvgIcon-root': {
    fill: `${COLORS.blue[300]}`,
    width: '20px',
    height: '20px',
    top: 9,
  },
  '&.MuiOutlinedInput-root': {
    border: `1px solid ${COLORS.blue[200]}`,
    '.MuiOutlinedInput-input': {
      padding: '10px 12px',
    },
    fieldset: {
      border: 'none',
    },
  },
};

const customSelectItem = {
  fontSize: '15px',
  color: `${COLORS.blue[900]}`,
  '&:hover, &:active, &:focus': {
    backgroundColor: `${COLORS.blue[100]}`,
  },
  '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:active,  &.Mui-selected:focus':
    {
      backgroundColor: `${COLORS.blue[100]}`,
    },
};

const customLabel = {
  fontSize: '15px',
  color: `${COLORS.blue[500]}`,
  mr: '12px',
};

export { customLabel, customSelectStyles, customSelectItem };
