import React from 'react';
// import ReportHistory from './views/ReportHistory';
import WeeklySchedulingReport from './views/WeeklySchedulingReport';

export const REPORTING_TABS = [
  {
    title: 'Weekly Scheduling Report',
    key: 'weekly-scheduling-report',
    child: <WeeklySchedulingReport />,
  },
  // {
  //   title: 'Visit History Report',
  //   key: 'visit-history-report',
  //   child: <ReportHistory />,
  // },
];
