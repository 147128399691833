import { COLORS } from '../../shared/styles/Theme';

export const titleStyles = {
  display: 'inline-flex',
  position: 'relative',
  mb: '12px',
  ml: '2px',
  color: COLORS.blue[900],
};

export const requiredAsterisk = {
  '&:after': {
    content: '"*"',
    color: COLORS.red[600],
    fontSize: '11px',
    position: 'absolute',
    top: '-3px',
    right: '-6px',
  },
};

export const customItem = {
  display: 'flex',
  height: '40px',
  flex: '1 1 0',
  padding: '2px 16px 2px 16px',
  borderRadius: '10px',
  backgroundColor: COLORS.blue[50],
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '13px',
  fontWeight: 600,
  lineHeight: '16px',
  letterSpacing: '0.03em',
  color: COLORS.blue[500],
  cursor: 'pointer',
  border: 'none',
  '&.selected': {
    backgroundColor: COLORS.brown[600],
    color: '#ffffff',
  },
};

export const disabledItem = {
  cursor: 'default',
  opacity: 0.4,
};
