import { Box } from '@mui/material';
import { func, instanceOf } from 'prop-types';
import React from 'react';
import { visitTypeIcons } from '../../../../../../../../shared/constants';
import NumericInput from '../../../../../../../../components/NumericInput';
import CustomSelect from '../../../../../../../../components/Select';
import { selectVisitTypeStyles } from '../styles';

function VisitTypeBlock({
  changeDuration = () => {},
  changeVisitType = () => {},
  filtersData = {},
  visitParams = {},
}) {
  return (
    <Box sx={{ display: 'flex' }}>
      <CustomSelect
        addStyles={selectVisitTypeStyles}
        endIcon={
          <img
            style={{ marginRight: '32px' }}
            src={visitTypeIcons[visitParams?.visitType?.name?.toLowerCase()]}
            alt={visitParams?.visitType?.name}
          />
        }
        icons={visitTypeIcons}
        id="visitType"
        isLoading={false}
        isRequired
        options={filtersData?.visitTypes?.map((option) => option.name)}
        placeHolder="Visit type"
        selectedValue={visitParams?.visitType?.name || ''}
        setValue={changeVisitType}
        withIcon
      />
      <NumericInput
        changeValue={changeDuration}
        disabled={visitParams?.visitType?.name !== 'Custom visit'}
        isRequired
        label="Duration"
        oneTimeVisit
        step={5}
        value={visitParams?.duration || ''}
      />
    </Box>
  );
}

VisitTypeBlock.propTypes = {
  changeDuration: func,
  changeVisitType: func,
  filtersData: instanceOf(Object),
  visitParams: instanceOf(Object),
};

export default React.memo(VisitTypeBlock);
