import { Box, Button, Typography } from '@mui/material';
import moment from 'moment';
import { TimeOffRequestsContext } from '../../../../../pages/CaregiverDetails/context';
import { instanceOf } from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';
import { COLORS } from '../../../../../shared/styles/Theme';
import { convertUtcToTeamTimeZoneMomentObject } from '../../../../../shared/utils/common';
import CustomDialog from '../../../../../components/Dialog';
import { continueButtonStyles, rejectButtonStyles } from '../styles';

export default function RequestsActions({ row = {} }) {
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const {
    checkApprovingPendingRequest,
    checkCancelingApprovedRequest,
    cancelPendingRequest,
  } = useContext(TimeOffRequestsContext);

  const rejectTimeOffRequest = () => {
    setOpenRejectDialog(false);
    if (row.status?.toLowerCase() === 'approved') {
      checkCancelingApprovedRequest(row.id);
    }
    if (row.status?.toLowerCase() === 'pending') {
      cancelPendingRequest(row.id);
    }
  };

  const showContinueButton = useMemo(
    () =>
      row.status?.toLowerCase() === 'pending' &&
      moment().isBefore(
        convertUtcToTeamTimeZoneMomentObject(row.requestStart),
        'day',
      ),
    [row],
  );
  const showRejectButton = useMemo(
    () =>
      (row.status?.toLowerCase() === 'approved' &&
        moment().isBefore(
          convertUtcToTeamTimeZoneMomentObject(row.requestStart),
          'minutes',
        )) ||
      (row.status?.toLowerCase() === 'pending' &&
        moment().isAfter(
          convertUtcToTeamTimeZoneMomentObject(row.requestStart),
          'minutes',
        )),
    [row],
  );

  return (
    <Box sx={{ display: 'flex', gap: '16px' }}>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setOpenRejectDialog(false)}
        openDialog={openRejectDialog}
        submitButtonName="Confirm"
        submitCallback={rejectTimeOffRequest}
        title="Confirmation"
      >
        <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            Time-Off Request will be cancelled. Do you want to proceed?
          </Typography>
        </Box>
      </CustomDialog>
      {showRejectButton && (
        <Button
          color="secondary"
          disableElevation
          onClick={() => setOpenRejectDialog(true)}
          sx={rejectButtonStyles}
          variant="outlined"
        >
          Cancel
        </Button>
      )}
      {showContinueButton && (
        <Button
          color="secondary"
          disableElevation
          onClick={() => checkApprovingPendingRequest(row.id)}
          sx={continueButtonStyles}
          variant="outlined"
        >
          Continue
        </Button>
      )}
    </Box>
  );
}

RequestsActions.propTypes = {
  row: instanceOf(Object),
};
