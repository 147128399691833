import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { string } from 'prop-types';
import React from 'react';
import { ISO_DATE_ONLY_FORMAT } from '../../shared/constants';
import { infoFieldsMap } from '../../components/Alert/constants';
import {
  infoBodyHints,
  infoItemTitle,
  infoItemValue,
  informationAlertBodyWrapper,
} from './styles';

export default function Information({ informationData = '' }) {
  const parsedInformation = JSON.parse(informationData);
  const dates = Object.keys(parsedInformation?.affectedTeamsByDate);
  const affectedDates = dates.map(
    (date) =>
      `${date}(${moment(date, ISO_DATE_ONLY_FORMAT).format('dddd').substring(0, 3).toUpperCase()})`,
  );
  const affectedTeams = dates.map((date) =>
    parsedInformation?.affectedTeamsByDate[date]?.map(({ name }) => name),
  );
  const completedData = {
    user: parsedInformation?.user,
    action: parsedInformation.action,
    affectedTeams: affectedTeams.flat().join(', '),
    dates: affectedDates.join(', '),
  };
  return (
    <Box sx={informationAlertBodyWrapper}>
      <Typography variant="body1" sx={infoBodyHints}>
        Currently, the Scheduling Engine is calculating another request:
      </Typography>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
      >
        {infoFieldsMap.map(({ name, key }) => (
          <Box key={name} sx={{ display: 'flex', marginBottom: '12px' }}>
            <Typography sx={infoItemTitle}>{name}:</Typography>
            <Typography variant="body1" sx={infoItemValue}>
              {completedData[key] || ''}
            </Typography>
          </Box>
        ))}
      </Box>
      <Typography variant="body1" sx={infoBodyHints}>
        Please, try again a bit later.
      </Typography>
    </Box>
  );
}

Information.propTypes = {
  informationData: string,
};
