import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  Box,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@mui/material';
import hardConstraintsWarning from '../../../../../../img/icons/error-icon.svg';
import { bool, func, instanceOf } from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { COLORS } from '../../../../../../shared/styles/Theme';
import CustomDialog from '../../../../../../components/Dialog';
import {
  caregiverListStyles,
  caregiverNameStyles,
  clientListStyles,
  clientNameStyles,
  errorModalBoxStyles,
  listBoxStyles,
} from './styles';

function ErrorModalTeam({
  openDeleteDialog = false,
  setOpenDeleteDialog = () => {},
  errorData = {},
}) {
  return (
    <CustomDialog
      cancelCallback={() => setOpenDeleteDialog(false)}
      submitButtonName="OK"
      submitCallback={() => setOpenDeleteDialog(false)}
      title="Error"
      openDialog={openDeleteDialog}
      withBorder
    >
      <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
        <Box sx={errorModalBoxStyles}>
          <img src={hardConstraintsWarning} alt="arrivalIcon" />
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            Please, reassign below clients and caregivers:
          </Typography>
        </Box>
        <Box sx={listBoxStyles}>
          <Box>
            <Typography sx={{ mb: '16px' }} variant="h5">
              Clients: {errorData?.clients?.length}
            </Typography>
            {errorData?.clients?.map((client) => (
              <List>
                <ListItem sx={{ padding: '6px', fontSize: '14px' }}>
                  <ListItemIcon sx={{ minWidth: '16px' }}>
                    <FiberManualRecordIcon sx={clientListStyles} />
                  </ListItemIcon>
                  <Link
                    component={RouterLink}
                    to={`/clients/${client?.id}`}
                    underline="none"
                    target="_blank"
                  >
                    <Typography variant="body1" sx={clientNameStyles}>
                      {client?.firstName} {client?.lastName}
                    </Typography>
                  </Link>
                </ListItem>
              </List>
            ))}
          </Box>
          <Box sx={{ borderLeft: `1px solid ${COLORS.blue[20]}`, pl: '20px' }}>
            <Typography sx={{ mb: '16px' }} variant="h5">
              Caregivers: {errorData?.caregivers?.length}
            </Typography>
            {errorData?.caregivers?.map((caregiver) => (
              <List>
                <ListItem sx={{ padding: '6px', fontSize: '14px' }}>
                  <ListItemIcon sx={{ minWidth: '16px' }}>
                    <FiberManualRecordIcon sx={caregiverListStyles} />
                  </ListItemIcon>
                  <Link
                    component={RouterLink}
                    to={`/caregivers/${caregiver?.id}`}
                    underline="none"
                    target="_blank"
                  >
                    <Typography variant="body1" sx={caregiverNameStyles}>
                      {caregiver?.firstName} {caregiver?.lastName}
                    </Typography>
                  </Link>
                </ListItem>
              </List>
            ))}
          </Box>
        </Box>
      </Box>
    </CustomDialog>
  );
}

ErrorModalTeam.propTypes = {
  openDeleteDialog: bool,
  setOpenDeleteDialog: func,
  errorData: instanceOf(Object),
};
export default ErrorModalTeam;
