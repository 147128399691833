import { Box } from '@mui/material';
import { func, instanceOf } from 'prop-types';
import React from 'react';
import { CustomDatePicker } from '../../../../../components/CustomDateAndTimePickers';

export default function SelectEffectiveDate({
  date = {},
  selectDate = () => {},
}) {
  return (
    <Box sx={{ width: '100%', padding: '0 20px 28px 28px' }}>
      <CustomDatePicker
        customStyles={[{ maxWidth: '200px', height: '40px' }]}
        changeDate={selectDate}
        date={date}
        isDisablePast
        isDisabledToday
        label="Change Caregiver’s Team"
      />
    </Box>
  );
}

SelectEffectiveDate.propTypes = {
  date: instanceOf(Object),
  selectDate: func,
};
