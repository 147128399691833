import { Stack, Typography } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import noDataIcon from '../../../../../img/icons/no-data.svg';
import { bool, instanceOf } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import CustomTable from '../../../../../components/Table';
import { noDataTableHintStyles } from '../../../../../components/Table/styles';
import { PAGE_SIZE, PAGE_SIZE_OPTIONS, getTableColumns } from '../constants';
import { customUpdateProgramTableStyles } from './styles';

function CareProgramTable({ careProgramVisits = [], isEditView = false }) {
  const apiRef = useGridApiRef();

  const getIndex = ({ item, index }) => {
    if (item.parentId) {
      return `${index}.2`;
    }
    if (item.childId) {
      return `${index}.1`;
    }
    return index;
  };

  const programDataWithIds = useMemo(() => {
    let counter = 0;
    return careProgramVisits?.map((item, index) => {
      counter += 1;
      const isEditedVisit =
        !!index &&
        (careProgramVisits[index - 1].childId ||
          careProgramVisits[index - 1].parentId);

      return {
        ...item,
        id: getIndex({ item, index: isEditedVisit ? counter - 1 : counter }),
      };
    });
  }, [careProgramVisits]);
  const getNoRowsComponent = useCallback(
    () => (
      <Stack height="100%" alignItems="center" justifyContent="center">
        <img src={noDataIcon} alt="" />
        <Typography sx={noDataTableHintStyles}>No visits yet. </Typography>
      </Stack>
    ),
    [],
  );
  const columns = useMemo(() => getTableColumns(isEditView), [isEditView]);
  return (
    <CustomTable
      apiRef={apiRef}
      columns={columns}
      customRowClassName="super-app-theme"
      customStyles={customUpdateProgramTableStyles}
      getRowClassName={(params) =>
        params.row.parentId ? 'visit-children' : ''
      }
      headerHeight={44}
      noRowsOverlay={() => getNoRowsComponent()}
      pageSize={PAGE_SIZE}
      pageSizeOptions={PAGE_SIZE_OPTIONS}
      rowHeight={107}
      rows={programDataWithIds}
    />
  );
}

CareProgramTable.propTypes = {
  careProgramVisits: instanceOf(Array),
  isEditView: bool,
};

export default React.memo(CareProgramTable);
