import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import OutlinedPreferredIcon from '../../../../img/icons/shadow-visit-gray-icon.svg';
import { bool, func } from 'prop-types';
import React from 'react';
import { customOutlinedButton } from '../../styles';

export default function AddShadowCaregiverButton({
  enableToEditShadowCaregiver = false,
  hasShadowCaregiver = false,
  onEditShadowCaregiver = () => {},
}) {
  return (
    enableToEditShadowCaregiver &&
    !hasShadowCaregiver && (
      <Button
        color="secondary"
        disableElevation
        onClick={onEditShadowCaregiver}
        startIcon={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
            <AddIcon sx={{ fontSize: '14px' }} />
            <img src={OutlinedPreferredIcon} alt="preferred-icon" />
          </Box>
        }
        sx={customOutlinedButton}
        variant="outlined"
      >
        Shadow caregiver
      </Button>
    )
  );
}

AddShadowCaregiverButton.propTypes = {
  enableToEditShadowCaregiver: bool,
  hasShadowCaregiver: bool,
  onEditShadowCaregiver: func,
};
