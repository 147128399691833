export const customDialogStyles = {
  maxWidth: '1000px',
  padding: '0 20px',
  '& .MuiDialogTitle-root': {
    padding: '36px 4px 24px 4px',
  },
  '& .MuiDialogActions-root': {
    borderTop: 'none',
    padding: '12px 0',
  },
};

export const visitTasksManagementWrapper = {
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  mt: '40px',
};

export const tasksManagementTableStyles = (isEmpty) => [
  {
    '& .MuiDataGrid-virtualScroller': {
      maxHeight: '100%',
      padding: isEmpty ? '32px 0' : '1px',
    },
    '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-cell': {
      padding: '26px 21px 24px 21px',
    },
    '& .MuiDataGrid-footerContainer': {
      margin: 0,
    },
  },
];
