import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { instanceOf } from 'prop-types';
import React from 'react';
import {
  H12_TIME_FORMAT,
  H24_TIME_FORMAT,
  ISO_DATE_ONLY_FORMAT,
  SHORT_DATE_FORMAT,
} from '../../../../../../../shared/constants';
import styles from './style.module.css';

const classifyChanges = (changes) => {
  const operationCounts = {
    insert: 0,
    remove: 0,
    update: 0,
  };
  changes.forEach((change) => {
    if (change?.type === 'object') {
      const operations = change.changes.map((item) => item.operation);
      const uniqueOperations = new Set(operations);

      if (uniqueOperations.has('insert') && uniqueOperations.size === 1) {
        operationCounts.insert += 1;
      } else if (
        uniqueOperations.has('remove') &&
        uniqueOperations.size === 1
      ) {
        operationCounts.remove += 1;
      } else {
        operationCounts.update += 1;
      }
    }
  });

  if (
    operationCounts.insert > 0 &&
    operationCounts.remove === 0 &&
    operationCounts.update === 0
  ) {
    return 'insert';
  }
  if (
    operationCounts.remove > 0 &&
    operationCounts.insert === 0 &&
    operationCounts.update === 0
  ) {
    return 'remove';
  }
  if (
    operationCounts.update > 0 ||
    (operationCounts.insert > 0 && operationCounts.remove > 0)
  ) {
    return 'update';
  }

  return '';
};

const formatDayAndArrivalWindow = (days) => {
  const dayMap = {};
  days.forEach((dayArray) => {
    let dayName = '';
    let arrivalStart = '';
    let arrivalEnd = '';
    let operation = '';
    let changeFrom = '';
    let changeTo = '';

    dayArray.forEach((change) => {
      if (change.propertyName === 'day') {
        dayName = change.to || change.from;
        if (!dayMap[dayName]) {
          dayMap[dayName] = {
            arrivalStart: '',
            arrivalEnd: '',
            operation: '',
            changeFrom: '',
            changeTo: '',
            dayName,
          };
        }
      }

      if (
        change.propertyName === 'arrivalStart' ||
        change.propertyName === 'arrivalEnd'
      ) {
        const time = change.to
          ? moment(change.to, H24_TIME_FORMAT).format(H12_TIME_FORMAT)
          : '';
        const fromTime = change.from
          ? moment(change.from, H24_TIME_FORMAT).format(H12_TIME_FORMAT)
          : '';

        if (change.propertyName === 'arrivalStart') {
          arrivalStart = time;
        } else if (change.propertyName === 'arrivalEnd') {
          arrivalEnd = time;
        }

        if (change.operation === 'remove') {
          operation = 'remove';
          changeFrom = fromTime;
          changeTo = '';
        } else if (change.operation === 'insert') {
          operation = 'insert';
          changeFrom = fromTime || '';
          changeTo = time;
        } else if (change.operation === 'noChange') {
          operation = 'noChange';
          changeFrom = fromTime;
          changeTo = time;
        }
      }
    });

    if (dayName) {
      if (arrivalStart || operation === 'remove')
        dayMap[dayName].arrivalStart = arrivalStart;
      if (arrivalEnd || operation === 'remove')
        dayMap[dayName].arrivalEnd = arrivalEnd;
      dayMap[dayName].operation = operation;
      dayMap[dayName].changeFrom = changeFrom;
      dayMap[dayName].changeTo = changeTo;
      dayMap[dayName].dayName = dayName;
    }
  });

  return Object.keys(dayMap)
    .map((day) => dayMap[day])
    .filter((entry) => entry.dayName);
};

const formatVisitInfo = (changes) => {
  const visitType = changes.filter(
    (visit) => visit?.propertyName === 'description',
  );
  const duration = changes.find((visit) => visit?.propertyName === 'duration');
  const recurrence = changes.find(
    (visit) => visit?.propertyName === 'recurrence',
  );
  const effectiveDate = changes.find(
    (visit) => visit?.propertyName === 'effectiveDateStart',
  );
  const effectiveDateEnd = changes.find(
    (visit) => visit?.propertyName === 'effectiveDayEnd',
  );
  const days = changes
    .filter((t) => t?.type === 'object')
    .map((change) => change.changes);
  return {
    visitType: visitType || 'N/A',
    duration: duration ? duration.to || duration.from : 'N/A',
    recurrence: recurrence ? recurrence.to || recurrence.from : 'N/A',
    effectiveDate: effectiveDate
      ? moment(effectiveDate.to).format(SHORT_DATE_FORMAT)
      : 'N/A',
    removedVisitEffectiveDate: effectiveDate
      ? moment(effectiveDate.from).format(SHORT_DATE_FORMAT)
      : 'N/A',
    effectiveDateEnd: effectiveDateEnd
      ? moment(effectiveDateEnd.to, ISO_DATE_ONLY_FORMAT).format(
          SHORT_DATE_FORMAT,
        )
      : 'N/A',
    dayAndArrivalWindows: formatDayAndArrivalWindow(days),
  };
};

const renderChanges = (changes) => {
  const changeType = classifyChanges(changes);
  const visitInfo = formatVisitInfo(changes);
  let header;
  let styleType;
  if (changeType === 'insert') {
    header = 'Care Program Visit Added';
    styleType = 'addedType';
  } else if (changeType === 'remove') {
    header = 'Care Program Visit Removed';
    styleType = 'removedType';
  } else {
    header = 'Care Program Visit Updated';
    styleType = '';
  }
  const getHeaderParams = () => {
    if (changeType === 'remove') {
      return `[${visitInfo.visitType?.[0]?.from} | ${visitInfo.duration}]`;
    }
    if (changeType === 'insert') {
      return `[${visitInfo.visitType?.[0]?.to} | ${visitInfo.duration}]`;
    }
    if (changeType === 'update') {
      return visitInfo.effectiveDateEnd === 'N/A'
        ? visitInfo.effectiveDate
        : visitInfo.effectiveDateEnd;
    }
    return '';
  };
  return (
    <Box sx={{ p: '10px 0' }}>
      <Typography className={styles[styleType]}>
        <span className={styles.boldWeight}>{header}: </span>{' '}
        {getHeaderParams()}
      </Typography>
      <Typography className={styles[`${changeType}Type`]}>
        Days of the Week & Arrival Windows:
      </Typography>
      {visitInfo.dayAndArrivalWindows.map((window, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Typography className={styles[`${changeType}Type`]} key={index}>
          {window.dayName}: {window.arrivalStart} - {window.arrivalEnd}
        </Typography>
      ))}
      <Typography className={styles[`${changeType}Type`]}>
        Recurrence: {visitInfo.recurrence}
      </Typography>
      <Typography className={styles[`${changeType}Type`]}>
        Effective Date:{' '}
        {changeType === 'remove'
          ? visitInfo.removedVisitEffectiveDate
          : visitInfo.effectiveDate}
      </Typography>
    </Box>
  );
};

export default function CareProgramHistoryBulletList({ changes = [] }) {
  return <Box sx={{ paddingLeft: '20px' }}>{renderChanges(changes)}</Box>;
}

CareProgramHistoryBulletList.propTypes = {
  changes: instanceOf(Array),
};
