import { Box } from '@mui/material';
import { instanceOf } from 'prop-types';
import React from 'react';
import {
  getInsertOperationComponent,
  getRemoveOperationComponent,
  getUpdateOperationComponent,
} from '../../../../../../components/VisitInfoTypeProvider/components/VisitDetails/VisitHistory/HistoryBulletList/utils';
import styles from './style.module.css';

export default function HistoryBulletList({ changes = [] }) {
  return (
    <Box sx={{ paddingLeft: '20px' }}>
      <ul className={styles.bulletList}>
        {changes?.map((item, index) => {
          const key = `${item?.operation}-${item?.propertyName}-${item?.id}${index}`;
          if (item?.operation === 'update') {
            return getUpdateOperationComponent(item, key, item?.propertyName);
          }
          if (item?.operation === 'remove') {
            return getRemoveOperationComponent(item, key, item?.propertyName);
          }
          if (item?.operation === 'insert') {
            return getInsertOperationComponent(item, key, item?.propertyName);
          }
          return false;
        })}
      </ul>
    </Box>
  );
}

HistoryBulletList.propTypes = {
  changes: instanceOf(Array),
};
