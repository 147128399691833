import { Box } from '@mui/material';
import { func, instanceOf, string } from 'prop-types';
import React, { useState } from 'react';
import { isValidText } from '../../../../../../shared/utils/common';
import { CustomDatePicker } from '../../../../../../components/CustomDateAndTimePickers';
import ErrorAlert from '../../../../../../components/ErrorAlert';
import CustomInput from '../../../../../../components/Inputs';

export default function AddHolidayModalContent({
  date = {},
  selectDate = () => {},
  setHolidayName = () => {},
  holidayName = '',
  shouldDisableDate = () => {},
}) {
  const [error, setError] = useState(null);

  const handleChangeHolidayName = (event) => {
    setHolidayName(event.target.value);
    setError(null);
  };
  const handleBlurEmail = () => {
    if (!isValidText(holidayName)) {
      setError('Holiday Name is invalid');
    }
    if (holidayName?.length === 0 && error) {
      setError(null);
    }
  };
  return (
    <Box
      sx={{
        width: '100%',
        padding: '0 28px 28px 28px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CustomDatePicker
        customStyles={[{ width: '100%', height: '50px', marginBottom: '24px' }]}
        changeDate={selectDate}
        date={date}
        isDisablePast
        label="Date"
        shouldDisableDate={shouldDisableDate}
      />
      <CustomInput
        changeTaskName={handleChangeHolidayName}
        id="name"
        label="Holiday Name"
        value={holidayName}
        onBlur={handleBlurEmail}
      />
      {error && <ErrorAlert error={error} />}
    </Box>
  );
}

AddHolidayModalContent.propTypes = {
  date: instanceOf(Object),
  selectDate: func,
  holidayName: string,
  setHolidayName: func,
  shouldDisableDate: func,
};
