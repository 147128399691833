import { Box, Typography } from '@mui/material';
import errorIcon from '../../../../../../../img/icons/error-outlined.svg';
import successIcon from '../../../../../../../img/icons/success-outlined.svg';
import { bool, func, instanceOf, number } from 'prop-types';
import React from 'react';
import { COLORS } from '../../../../../../../shared/styles/Theme';
import Loader from '../../../../../../../components/Loader';
import CustomBreadcrumbs from './Breadcrumbs';
import ColumnAlert from './ColumnAlert';
import VisitsByTypesAccordion from './VisitsByTypesAccordion';
import VisitsByTypesChangesTable from './VisitsByTypesChangesTable';
import { columns } from './VisitsByTypesChangesTable/constants';
import { checkPossibilityViewWrapper } from './styles';

function ScheduleChangesView({
  closeDialog = () => {},
  dates = [],
  isLoading = false,
  notAllocatedDates = 0,
  visits = {},
}) {
  const alertsMap = {
    success: {
      bgColor: COLORS.green[200],
      color: COLORS.green[600],
      icon: <img src={successIcon} alt="success icon" />,
      getTitle() {
        return 'Reassignment is possible for all days';
      },
      type: 'success',
    },
    error: {
      bgColor: COLORS.red[200],
      color: COLORS.red[600],
      icon: <img src={errorIcon} alt="error icon" />,
      getTitle() {
        return `Reassignment impossible for ${notAllocatedDates}`;
      },
      type: 'error',
    },
  };
  const calculateDateVisits = (visitsList, date) =>
    visits[date]
      ? visits[date].notAllocatedVisits.length +
        visits[date].changedVisits.length
      : 0;
  return (
    <Box sx={{ minWidth: '1440px', padding: '20px 32px' }}>
      <Box sx={checkPossibilityViewWrapper}>
        <CustomBreadcrumbs
          closeDialog={closeDialog}
          title="Check Block Caregiver Possibility"
        />
      </Box>
      {isLoading && (
        <Box sx={{ height: '300px' }}>
          <Loader />
        </Box>
      )}
      <Box sx={{ padding: '0 28px' }}>
        {!isLoading && (
          <ColumnAlert
            alertData={alertsMap[notAllocatedDates ? 'error' : 'success']}
            errorDays={notAllocatedDates}
          />
        )}
        {dates?.map((date) => (
          <VisitsByTypesAccordion
            date={date}
            key={date}
            tasksCount={calculateDateVisits(visits, date)}
            title={date}
          >
            {!!visits[date]?.notAllocatedVisits?.length && (
              <div>
                <Typography sx={{ fontWeight: '700', mb: '12px' }}>
                  Visits Unable to be Reassigned
                </Typography>
                <VisitsByTypesChangesTable
                  columns={columns}
                  visitsList={visits[date]?.notAllocatedVisits}
                  visitsType="not allocated visits"
                />
              </div>
            )}
            {!!visits[date]?.changedVisits?.length && (
              <div>
                <Typography sx={{ fontWeight: '700', mb: '12px' }}>
                  Reassigned Visits
                </Typography>
                <VisitsByTypesChangesTable
                  columns={columns}
                  visitsList={visits[date]?.changedVisits}
                  visitsType="reassigned visits"
                />
              </div>
            )}
          </VisitsByTypesAccordion>
        ))}
      </Box>
    </Box>
  );
}

ScheduleChangesView.propTypes = {
  closeDialog: func,
  dates: instanceOf(Array),
  isLoading: bool,
  notAllocatedDates: number,
  visits: instanceOf(Object),
};

export default React.memo(ScheduleChangesView);
