import { Box, Stack } from '@mui/material';
import moment from 'moment';
import { func, instanceOf } from 'prop-types';
import React from 'react';
import { DATE_ONLY_STANDARD_FORMAT, H12_TIME_FORMAT } from '../../../../../../../shared/constants';
import { CustomDatePicker } from '../../../../../../../components/CustomDateAndTimePickers';
import CustomTimeSelect from '../../../../../../../components/CustomDateAndTimePickers/CustomTimeSelect';

export default function ChooseDatesRows({
  changeRequestDate = () => {},
  changeRequestTime = () => {},
  callOutData = {},
}) {
  return (
    <Stack>
      <Box sx={{ display: 'flex', gap: '20px', mt: '-8px', mb: '24px' }}>
        <CustomTimeSelect
          disabled={callOutData?.startingNow}
          onSelectTimeValue={(value) =>
            changeRequestTime({
              callOutStartTime: moment(value, H12_TIME_FORMAT),
              callOutEndTime: callOutData.callOutEndTime,
            })
          }
          placeHolder="Callout Time Start"
          selectedTime={
            callOutData?.callOutStartTime?.isValid()
              ? callOutData?.callOutStartTime.format(H12_TIME_FORMAT)
              : ''
          }
        />
        <CustomTimeSelect
          disabled={callOutData?.forTheDay}
          onSelectTimeValue={(value) =>
            changeRequestTime({
              callOutStartTime: callOutData.callOutStartTime,
              callOutEndTime: moment(value, H12_TIME_FORMAT),
            })
          }
          placeHolder="Callout Time End"
          selectedTime={
            callOutData.callOutEndTime?.isValid()
              ? callOutData.callOutEndTime.format(H12_TIME_FORMAT)
              : ''
          }
        />
      </Box>
      <Box sx={{ display: 'flex', gap: '20px' }}>
        <CustomDatePicker
          changeDate={(newValue) =>
            changeRequestDate({
              callOutStartDate: newValue,
              callOutEndDate: callOutData?.callOutEndDate,
            })
          }
          date={
            callOutData?.callOutStartDate
              ? moment(callOutData?.callOutStartDate, DATE_ONLY_STANDARD_FORMAT)
              : null
          }
          disabled={callOutData?.startingNow}
          isDisablePast
          label="Callout Date Start"
        />
        <CustomDatePicker
          changeDate={(newValue) =>
            changeRequestDate({
              callOutStartDate: callOutData?.callOutStartDate,
              callOutEndDate: newValue,
            })
          }
          date={
            callOutData?.callOutEndDate
              ? moment(callOutData?.callOutEndDate, DATE_ONLY_STANDARD_FORMAT)
              : null
          }
          disabled={callOutData?.forTheDay}
          isDisablePast
          label="Callout Date End"
        />
      </Box>
    </Stack>
  );
}

ChooseDatesRows.propTypes = {
  changeRequestDate: func,
  changeRequestTime: func,
  callOutData: instanceOf(Object),
};
