import { Box, Button, Typography } from '@mui/material';
import moment from 'moment';
import {
  CLIENTS_STATUSES,
  CLIENTS_STATUSES_MAP,
  CLIENTS_STATUS_CHANGING_ACTIONS,
} from '../../../../../pages/Clients/constants';
import React, { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { SHORT_DATE_FORMAT, backgroundColorSchemeMap } from '../../../../../shared/constants';
import { getStatusChangingInfo } from '../../../../../shared/utils/common';
import Badge from '../../../../../components/Badge';
import StatusesCustomSelect from '../../../../../components/StatusesSelect';
import { ClientDetailsContext, ClientProfilesContext } from '../../../context';
import ChangeStatusSteps from '../ChangeStatusSteps';
import { changeClientSteps, suspendedPeriod } from '../constants';
import {
  inactivePeriodStyles,
  profileReactivationButtonStyles,
} from '../styles';
import ClientDeactivationDialog from './ClientDeactivationDialog';
import EffectiveDateModal from './EffectiveDateModal';

export default function StatusSelector() {
  const { id } = useParams();
  const [statusChangingStep, setStatusChangingStep] = useState(null);
  const [statusAction, setStatusAction] = useState(null);
  const {
    confirmClientActivation,
    confirmClientDeactivation,
    confirmClientResume,
    confirmClientSuspend,
    isBlockedCaregiverListChanged,
    userData,
    date,
    setDate,
  } = useContext(ClientProfilesContext);
  const { isEdit, onCloseProfileEditing } = useContext(ClientDetailsContext);
  const clientStatus = userData?.clientDetails?.status;
  const [openEffectiveDateDialog, setOpenEffectiveDateDialog] = useState(false);
  const [openDeactivationModal, setOpenDeactivationModal] = useState(false);
  const changeStatus = (value) => {
    if (value === CLIENTS_STATUSES_MAP.suspended) {
      setStatusChangingStep(changeClientSteps.setPeriod);
      setStatusAction(CLIENTS_STATUS_CHANGING_ACTIONS.suspend);
      return '';
    }
    if (value === CLIENTS_STATUSES_MAP.inactive) {
      setOpenEffectiveDateDialog(true);
      setStatusAction(CLIENTS_STATUS_CHANGING_ACTIONS.deactivate);
      return '';
    }
    return '';
  };

  const confirmNewStatus = (confirmationData) => {
    if (clientStatus === 'Active' && statusAction === 'suspend') {
      confirmClientSuspend(confirmationData);
    }
    if (clientStatus === 'Active' && statusAction === 'deactivate') {
      confirmClientDeactivation(confirmationData);
    }
    if (clientStatus === 'Suspended' && statusAction === 'resume') {
      confirmClientResume(confirmationData);
    }
  };
  const reActivateAccount = () => {
    if (clientStatus === 'Suspended') {
      setStatusAction(CLIENTS_STATUS_CHANGING_ACTIONS.resume);
    }
    if (clientStatus === 'Inactive') {
      confirmClientActivation({ clientId: id });
      setStatusChangingStep(null);
      setStatusAction(null);
      onCloseProfileEditing();
      return '';
    }
    setStatusChangingStep(changeClientSteps.checkChangedVisits);
    return '';
  };
  const accountChangesInfo = useMemo(
    () => getStatusChangingInfo(userData?.clientDetails),
    [userData],
  );
  const confirmDeactivation = () => {
    setStatusChangingStep(changeClientSteps.checkVisit);
    setOpenDeactivationModal(false);
  };
  const submitEffectiveDateDialog = () => {
    setOpenEffectiveDateDialog(false);
    setOpenDeactivationModal(true);
  };
  const isStatusChangedDisabled =
    userData?.clientDetails?.inactiveFrom &&
    moment().isBefore(moment(userData?.clientDetails?.inactiveFrom));

  return (
    <Box sx={{ minWidth: '250px' }}>
      {!!statusChangingStep && (
        <ChangeStatusSteps
          confirmNewStatus={confirmNewStatus}
          setStatusChangingStep={setStatusChangingStep}
          statusAction={statusAction}
          statusChangingStep={statusChangingStep}
          suspendedPeriod={
            clientStatus === 'Suspended' ? suspendedPeriod : null
          }
          deactivationDate={date}
          onCloseProfileEdit={onCloseProfileEditing}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          minWidth: '250px',
        }}
      >
        {isEdit && clientStatus === 'Active' ? (
          <StatusesCustomSelect
            colorScheme={backgroundColorSchemeMap[clientStatus?.toLowerCase()]}
            disabled={isBlockedCaregiverListChanged || isStatusChangedDisabled}
            id="status"
            options={CLIENTS_STATUSES.map((option) => option.name)}
            isLoading={false}
            selectedValue={clientStatus}
            setValue={changeStatus}
          />
        ) : (
          <Badge
            colorScheme={backgroundColorSchemeMap[clientStatus?.toLowerCase()]}
            label={clientStatus}
          />
        )}
        <Typography sx={{ fontStyle: 'italic', ml: '10px' }}>
          {accountChangesInfo}
        </Typography>
        {clientStatus === 'Suspended' &&
          userData?.clientDetails?.suspendedFrom && (
            <Typography sx={inactivePeriodStyles} variant="body1">
              {`${moment(userData?.clientDetails?.suspendedFrom).format(
                SHORT_DATE_FORMAT,
              )} - ${moment(userData?.clientDetails?.resumedFrom).format(
                SHORT_DATE_FORMAT,
              )}`}
            </Typography>
          )}
      </Box>
      <EffectiveDateModal
        openEffectiveDateDialog={openEffectiveDateDialog}
        closeEffectiveDateDialog={() => setOpenEffectiveDateDialog(false)}
        submitEffectiveDateDialog={submitEffectiveDateDialog}
        date={date}
        selectDate={(newValue) => setDate(newValue)}
      />
      <ClientDeactivationDialog
        openDeactivationModal={openDeactivationModal}
        closeDeactivationModal={() => setOpenDeactivationModal(false)}
        confirmDeactivation={confirmDeactivation}
        date={date}
      />
      {isEdit && ['Suspended', 'Inactive'].includes(clientStatus) && (
        <Button
          sx={profileReactivationButtonStyles}
          color="secondary"
          variant="outlined"
          onClick={reActivateAccount}
          disableElevation
          disabled={userData?.clientDetails?.activeFrom}
        >
          {clientStatus === 'Suspended' ? 'resume services' : 'reactivate'}
        </Button>
      )}
    </Box>
  );
}
