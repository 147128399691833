import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { VisitInfoTypeContext } from '../../../../shared/context';
import Loader from '../../../../components/Loader';
import { visitInfoTypeDialogs } from '../../constants';
import { emptyPageWrapper } from '../../styles';
import VisitInfoTypeContent from './VisitInfoTypeContent';
import VisitInfoTypeDialog from './VisitInfoTypeDialog';

export default function VisitInfoTypeDialogWrapper() {
  const {
    closeDialog,
    openDialogType,
    openVisitDetailsDialog,
    visitInfoLoading,
  } = useContext(VisitInfoTypeContext);
  return (
    <VisitInfoTypeDialog
      cancelCallback={closeDialog}
      isLoading={visitInfoLoading}
      openDialog={openDialogType === visitInfoTypeDialogs.visitInfoType}
      openVisitDetailsDialog={openVisitDetailsDialog}
    >
      <Box sx={{ position: 'relative' }}>
        {visitInfoLoading && (
          <Box sx={emptyPageWrapper}>
            <Loader />
          </Box>
        )}
        <VisitInfoTypeContent />
      </Box>
    </VisitInfoTypeDialog>
  );
}
