import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { bool, func, number, string } from 'prop-types';
import React, { useCallback } from 'react';
import { H12_TIME_FORMAT } from '../../../../shared/constants';
import CustomTimePicker from '../../../../components/CustomDateAndTimePickers/CustomTimePicker';
import NumericInput from '../../../../components/NumericInput';
import ScheduledTimeFields from './ScheduledTimeFields';
import {
  columnTitle,
  customInputsStyles,
  visitInfoManagementColumnStyles,
} from './styles';

function VisitTimeManagement({
  actualDuration = 0,
  actualTimeEnd = '',
  actualTimeStart = '',
  arrivalTime = '',
  arrivalTimeEnd = '',
  arrivalTimeStart = '',
  duration = 0,
  isExactTime = false,
  updateTemporaryVisitData = () => {},
}) {
  const changeScheduledTimeValues = ({ key, value }) =>
    updateTemporaryVisitData({ [key]: value.format(H12_TIME_FORMAT) });

  const changeActualDuration = useCallback(
    (value) =>
      updateTemporaryVisitData({
        actualTimeEnd: moment(actualTimeEnd, H12_TIME_FORMAT)
          .add(value, 'minutes')
          .format(H12_TIME_FORMAT),
        duration: value,
      }),
    [actualTimeEnd, updateTemporaryVisitData],
  );

  const changeActualStartTime = useCallback(
    (date) =>
      updateTemporaryVisitData({
        actualTimeStart: moment(date).format(H12_TIME_FORMAT),
        actualTimeEnd: moment(date)
          .add(duration, 'minutes')
          .format(H12_TIME_FORMAT),
      }),
    [duration, updateTemporaryVisitData],
  );

  const changeActualEndTime = useCallback(
    (date) => {
      const end = moment(date);
      const start = moment(actualTimeStart, H12_TIME_FORMAT);
      const durationDiff = end.diff(start, 'minutes');
      updateTemporaryVisitData({
        actualTimeEnd: moment(date).format(H12_TIME_FORMAT),
        duration: durationDiff,
      });
    },
    [actualTimeStart, updateTemporaryVisitData],
  );

  return (
    <Box sx={{ display: 'flex', gap: '24px' }}>
      <ScheduledTimeFields
        arrivalTime={arrivalTime}
        arrivalTimeEnd={arrivalTimeEnd}
        arrivalTimeStart={arrivalTimeStart}
        changeScheduledTimeValues={changeScheduledTimeValues}
        disableAllFields
        duration={duration}
        exactTimeVisitEnd={moment(arrivalTime, H12_TIME_FORMAT)
          .add(duration, 'minutes')
          .format(H12_TIME_FORMAT)}
        exactTimeVisitStart={arrivalTimeStart}
        isExactTime={isExactTime}
      />
      <Box sx={visitInfoManagementColumnStyles}>
        <Typography sx={columnTitle}>Actual Time</Typography>
        <CustomTimePicker
          customStyles={[customInputsStyles]}
          disabled
          label="Actual Start Time"
          onChangeValue={changeActualStartTime}
          value={
            actualTimeStart
              ? moment(actualTimeStart, H12_TIME_FORMAT)
              : actualTimeStart
          }
          views={['hours', 'minutes']}
          withIcon
        />
        <CustomTimePicker
          customStyles={[customInputsStyles]}
          disabled
          label="Actual End Time"
          isPMPlaceholder
          onChangeValue={changeActualEndTime}
          value={
            actualTimeEnd
              ? moment(actualTimeEnd || arrivalTimeEnd, H12_TIME_FORMAT)
              : actualTimeEnd
          }
          views={['hours', 'minutes']}
          withIcon
        />
        <NumericInput
          additionalStyles={customInputsStyles}
          changeValue={changeActualDuration}
          disabled
          label="Actual Visit Duration, min"
          step={5}
          value={actualDuration !== null ? actualDuration : ''}
        />
      </Box>
    </Box>
  );
}

VisitTimeManagement.propTypes = {
  actualDuration: number,
  actualTimeEnd: string,
  actualTimeStart: string,
  arrivalTime: string,
  arrivalTimeEnd: string,
  arrivalTimeStart: string,
  duration: number,
  isExactTime: bool,
  updateTemporaryVisitData: func,
};

export default React.memo(VisitTimeManagement);
