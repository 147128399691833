import React from 'react';
import AdministrationHolidays from './views/AdministrationHolidays';
import AdministrationSchedulingEngine from './views/AdministrationSchedulingEngine';
import AdministrationTeams from './views/AdministrationTeams';
import AdministrationTerritory from './views/AdministrationTerritory';
import AdministrationUsers from './views/AdministrationUsers';

export const ADMINISTRATION_DETAILS_TABS = [
  {
    title: 'Scheduling Engine',
    key: 'scheduling-engine',
    child: <AdministrationSchedulingEngine />,
  },
  {
    title: 'Territories',
    key: 'territories',
    child: <AdministrationTerritory />,
  },
  {
    title: 'Teams',
    key: 'teams',
    child: <AdministrationTeams />,
  },
  {
    title: 'Holidays',
    key: 'holidays',
    child: <AdministrationHolidays />,
  },
  {
    title: 'Users',
    key: 'users',
    child: <AdministrationUsers />,
  },
];
