import { COLORS } from '../../../../shared/styles/Theme';

export const availabilityLoaderWrapper = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 'auto',
};

export const availabilityLoader = {
  position: 'relative',
  width: '80px',
  height: '80px',
  mb: '12px',
  '&:before': {
    content: '""',
    borderRadius: '50%',
    position: 'absolute',
    inset: 0,
    boxShadow: `0 1px 0 ${COLORS.blue[700]} inset`,
    animation: 'rotate 1s linear infinite',
  },
  '@keyframes rotate': {
    '0%': { transform: 'rotate(0)' },
    '100%': { transform: 'rotate(360deg)' },
  },
};
