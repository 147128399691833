import moment from 'moment';
import { LOCAL_STORAGE_KEYS } from '../../../pages/Scheduling/constants';
import { func, instanceOf, number } from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import {
  SEARCH_INPUT_DEBOUNCE_DELAY,
  eventsByCalendarTypes,
} from '../../../shared/constants';
// import { VisitInfoTypeContext } from '../../../shared/context';
import { useDebouncedCallback } from '../../../shared/hooks/useDebounce';
import SchedulingEventComponent from '../../../components/CalendarsEventComponent';
import DnDCalendars from '../../../components/DndCalendar';
import '../index.css';
import {
  calculateCalendarVerticalScrollPosition,
  getDndEventBoxClass,
} from '../utils';
import CustomNavigation from './CustomNavigation';

function CommonScheduling({
  currentDate = {},
  dayPropGetter = () => {},
  eventsList = [],
  firstVisit = 0,
  isDateHoliday = {},
  lastVisit = 0,
  onNavigate = () => {},
  onSelectEvent = () => {},
  reloadSchedulingForDate = () => {},
  resourceMap = [],
  slotPropGetter = () => {},
}) {
  const debouncedScrollPositionStoring = useDebouncedCallback((timeContent) => {
    const scrollPosition = {
      top: timeContent.scrollTop.toFixed(2),
      left: timeContent.scrollLeft.toFixed(2),
    };
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.scrollPosition,
      JSON.stringify(scrollPosition),
    );
  }, SEARCH_INPUT_DEBOUNCE_DELAY);

  const savedScrollPosition = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS.scrollPosition),
  );

  useEffect(() => {
    const timeContent = document?.querySelector(
      '.mainScheduling .rbc-time-content',
    );
    if (timeContent && savedScrollPosition) {
      timeContent.scrollLeft = savedScrollPosition.left;
    }
    timeContent?.addEventListener('scroll', () =>
      debouncedScrollPositionStoring(timeContent),
    );

    return () => {
      timeContent?.removeEventListener('scroll', () =>
        debouncedScrollPositionStoring(timeContent),
      );
    };
  });
  const minStartTime = moment().subtract(30, 'minute').toDate();
  const minUTCStartTime = moment(minStartTime).utc().toDate();

  const customEvent = useCallback(
    ({ event }) => (
      <SchedulingEventComponent
        event={event}
        eventType={eventsByCalendarTypes.scheduling}
      />
    ),
    [],
  );
  const customToolbarComponent = (props) => (
    <CustomNavigation
      isDateHoliday={isDateHoliday}
      reloadSchedulingForDate={reloadSchedulingForDate}
      {...props}
    />
  );
  return (
    <DnDCalendars
      className="mainScheduling"
      customToolbar={customToolbarComponent}
      date={currentDate}
      dayPropGetter={dayPropGetter}
      eventComponent={customEvent}
      events={eventsList}
      eventStyleGetter={getDndEventBoxClass}
      firstVisit={firstVisit}
      lastVisit={lastVisit}
      isDateHoliday={isDateHoliday}
      onNavigate={onNavigate}
      onSelectEvent={onSelectEvent}
      resources={resourceMap}
      scrollTime={
        currentDate === null || !savedScrollPosition
          ? minUTCStartTime
          : calculateCalendarVerticalScrollPosition({
              currentDate,
              firstVisit,
              top: savedScrollPosition.top,
            })
      }
      slotPropGetter={slotPropGetter}
      toolbar
    />
  );
}

CommonScheduling.propTypes = {
  currentDate: instanceOf(Object),
  dayPropGetter: func,
  eventsList: instanceOf(Array),
  firstVisit: number,
  isDateHoliday: instanceOf(Object),
  lastVisit: number,
  onNavigate: func,
  onSelectEvent: func,
  reloadSchedulingForDate: func,
  resourceMap: instanceOf(Array),
  slotPropGetter: func,
};

export default CommonScheduling;
