import { useGetFiltersDataQuery } from '../../../../api/commonApi/api';
import moment from 'moment';
import { bool, func, instanceOf, oneOfType, string } from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  H12_TIME_FORMAT,
  ISO_DATE_ONLY_FORMAT,
  WORKING_WEEK_LENGTH,
  timesOfDayInclusivity,
} from '../../../../shared/constants';
import { VisitInfoAddTaskContext } from '../../../../shared/context';
import { v4 as uuidv4 } from 'uuid';
import AlertContext from '../../../../components/Alert';
import CustomDialog from '../../../../components/Dialog';
import Content from './components/Content';
import { taskInitialState } from './constants';

function AddTaskProvider({
  children = [],
  hasPredefinedData = false,
  tasksListData = [],
  updateTasksList = () => {},
  visitId = '',
}) {
  const { setAlert } = useContext(AlertContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [taskData, setTaskData] = useState(taskInitialState);

  const { data: filtersData, error } = useGetFiltersDataQuery({
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);

  const addNewTask = useCallback(
    (visit) => {
      let taskPredefinedData = {
        ...taskInitialState,
        timesOfDay: filtersData?.timesOfDay?.map((time) => time.name),
        days: filtersData?.weekdays
          ?.slice(0, WORKING_WEEK_LENGTH)
          ?.map((day) => day.name),
        visitTypes: filtersData?.visitTypes?.map((time) => time.name),
      };
      if (visit) {
        const day = visit.date
          ? moment(visit.date, ISO_DATE_ONLY_FORMAT).format('dddd')
          : visit.day;
        const timeOfDay =
          visit.timeOfDay ||
          filtersData.timesOfDay.find(({ from, name, to }) =>
            moment(
              visit.exactTimeSlot ||
                visit.exactTime ||
                visit.arrivalTimeStart ||
                visit.arrivalStart,
              H12_TIME_FORMAT,
            ).isBetween(
              moment(from, H12_TIME_FORMAT),
              moment(to, H12_TIME_FORMAT),
              'minutes',
              timesOfDayInclusivity[name?.toLowerCase()],
            ),
          )?.name;
        taskPredefinedData = {
          ...taskPredefinedData,
          days: [day],
          timesOfDay: timeOfDay ? [timeOfDay] : [],
          visitTypes: [visit.visitType],
        };
      }
      setOpenDialog(true);
      setTaskData(taskPredefinedData);
    },
    [filtersData],
  );

  const applyTaskData = () => {
    const parsedTaskData = {
      ...taskData,
      id: uuidv4(),
      status: 'To Do',
      visitId,
    };
    const newTasksList = [...tasksListData, parsedTaskData];

    updateTasksList({ tasks: newTasksList });
    setOpenDialog(false);
  };

  const isDisabledSave = (data) => {
    if (taskData?.id) {
      const editableTask = tasksListData.find(
        (task) => task.id === taskData.id,
      );
      return JSON.stringify(editableTask) === JSON.stringify(taskData);
    }
    return Object.values(data).some((item) => !item?.toString().length);
  };
  const closeDialog = () => {
    setOpenDialog(false);
  };
  const memoizedProviderValue = useMemo(
    () => ({
      addNewTask,
    }),
    [addNewTask],
  );

  return (
    <VisitInfoAddTaskContext.Provider value={memoizedProviderValue}>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={closeDialog}
        disableOnCloseByClickOutside
        disabledSubmit={isDisabledSave(taskData)}
        openDialog={openDialog}
        submitButtonName="ADD"
        submitCallback={applyTaskData}
        title={taskData.id ? 'Edit Task' : 'Add Task'}
      >
        <Content
          filtersData={filtersData}
          hasPredefinedData={hasPredefinedData}
          setTaskData={setTaskData}
          taskData={taskData}
        />
      </CustomDialog>
      {children}
    </VisitInfoAddTaskContext.Provider>
  );
}

AddTaskProvider.propTypes = {
  children: oneOfType([instanceOf(Array), instanceOf(Object)]),
  hasPredefinedData: bool,
  tasksListData: instanceOf(Array),
  updateTasksList: func,
  visitId: string,
};

export default React.memo(AddTaskProvider);
