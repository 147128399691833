import { Box, Typography } from '@mui/material';
import { instanceOf, string } from 'prop-types';
import React from 'react';
import { COLORS } from '../../../../../../../shared/styles/Theme';
import { getIconComponent } from '../../../../../../../shared/utils/common';

export default function VisitType({ row = {}, value = '' }) {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            ml: row?.shiftedBy ? '40px' : 0,
            mb: '6px',
          }}
        >
          {getIconComponent(value)}
          <Typography
            variant={row?.shiftedBy ? 'body1' : 'h5'}
            sx={{ ml: '8px', color: COLORS.blue[700] }}
          >
            {value}
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ fontSize: '13px' }}>
          {row?.team?.name}
        </Typography>
      </Box>
    </Box>
  );
}

VisitType.propTypes = {
  row: instanceOf(Object),
  value: string,
};
