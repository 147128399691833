import { TabList } from '@mui/lab';
import { Box, Tab, Typography } from '@mui/material';
import { ADMINISTRATION_DETAILS_TABS } from '../../../../pages/Administration/constants';
import { bool, func } from 'prop-types';
import React from 'react';

export default function AdministrationTitle({
  handleChange = () => {},
  disableTabs = false,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        mb: '32px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Typography variant="h1">Administration</Typography>
      </Box>
      <TabList
        onChange={handleChange}
        sx={{
          display: 'flex',
          '& .MuiTabs-flexContainer': { gap: '24px' },
        }}
      >
        {ADMINISTRATION_DETAILS_TABS.map(({ key, title }) => (
          <Tab
            disabled={disableTabs}
            disableRipple
            key={key}
            label={title}
            value={key}
          />
        ))}
      </TabList>
    </Box>
  );
}

AdministrationTitle.propTypes = {
  handleChange: func,
  disableTabs: bool,
};
