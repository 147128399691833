export const parseCheckCallOutPossibilityData = (response) => {
  const dates = Object.keys(response);
  return dates.reduce((obj, item) => {
    const visitsData = response[item];
    return Object.assign(obj, {
      [item]: visitsData?.map(({ newEvent, oldEvent }) => ({
        newCaregiver:
          newEvent?.caregiver?.id === oldEvent?.caregiver?.id
            ? null
            : newEvent?.caregiver,
        newClient:
          newEvent?.client?.id === oldEvent?.client?.id
            ? null
            : newEvent?.client,
        newEventId: newEvent.id,
        newScheduledTime:
          newEvent?.arrivalTime === oldEvent?.arrivalTime
            ? null
            : newEvent?.arrivalTime,
        newVisitType:
          newEvent?.visitType === oldEvent?.visitType
            ? null
            : newEvent?.visitType,
        oldCaregiver: oldEvent?.caregiver,
        oldClient: oldEvent?.client,
        oldEventId: oldEvent?.id,
        oldScheduledTime: oldEvent?.arrivalTime,
        oldVisitType: oldEvent?.visitType,
        visitNotes: oldEvent?.visitNotes,
      })),
    });
  }, {});
};

export const filterCaregiversByTeams = (caregivers, teams) =>
  caregivers?.filter(({ primaryTeams, secondaryTeams }) => {
    const primaryMatch = primaryTeams?.some((team) =>
      teams?.teams?.map((t) => t.name).includes(team?.name),
    );
    const secondaryMatch = secondaryTeams?.some((team) =>
      teams?.teams?.map((t) => t.name).includes(team?.name),
    );
    return primaryMatch || secondaryMatch;
  });
