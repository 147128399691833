import { Box } from '@mui/material';
import hardConstraintsWarning from '../../../../../img/icons/error-icon.svg';
import softConstraintsWarning from '../../../../../img/icons/warning-icon.svg';
import { instanceOf } from 'prop-types';
import React from 'react';
import { violationTooltipComponent } from '../../../../../shared/utils/common';
import { visitConstraintsIconsWrapper } from './styles';

export default function VisitIcons({ visitData = {} }) {
  return (
    <Box sx={visitConstraintsIconsWrapper}>
      {visitData?.violatedSoftConstraints?.length > 0 &&
        violationTooltipComponent({
          violations: visitData.violatedSoftConstraints,
          icon: softConstraintsWarning,
        })}
      {visitData?.violatedHardConstraints?.length > 0 &&
        violationTooltipComponent({
          violations: visitData.violatedHardConstraints,
          icon: hardConstraintsWarning,
        })}
    </Box>
  );
}

VisitIcons.propTypes = {
  visitData: instanceOf(Object),
};
