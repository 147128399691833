import { Box, FormControlLabel, Switch } from '@mui/material';
import { bool, func, instanceOf } from 'prop-types';
import React from 'react';
import { switches } from '../../constants';
import { customSwitcher } from './styles';

function WeekdaysForScheduling({
  disabled = false,
  onChange = () => {},
  switchData = {},
}) {
  const handleSwitchChange = (event) => {
    const { id, checked } = event.target;
    onChange({ ...switchData, [id]: checked });
  };
  return (
    <Box sx={{ mt: '24px' }}>
      {switches.map(({ id, label }) => (
        <FormControlLabel
          key={id}
          control={
            <Switch
              id={id}
              sx={customSwitcher}
              disabled={disabled}
              checked={switchData[id] || false}
              color="secondary"
              onChange={handleSwitchChange}
            />
          }
          label={label}
        />
      ))}
    </Box>
  );
}

export default WeekdaysForScheduling;

WeekdaysForScheduling.propTypes = {
  disabled: bool,
  onChange: func,
  switchData: instanceOf(Object),
};
