import { Box, Typography } from '@mui/material';
import React from 'react';
import HistoryBulletList from '../../../../components/HistoryBulletList';

export const switches = [
  { id: 'saturdaySwitchedOn', label: 'Saturday' },
  { id: 'sundaySwitchedOn', label: 'Sunday' },
];

export const historyColumns = [
  {
    field: 'dateTime',
    headerName: 'Date & Time',
    sortable: false,
    minWidth: 280,
    renderCell: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{row.dateCreated}</Typography>
      </Box>
    ),
  },
  {
    field: 'changes',
    headerName: 'Changes',
    minWidth: 740,
    sortable: false,
    renderCell: ({ value }) => <HistoryBulletList changes={value} />,
  },
  {
    field: 'user',
    headerName: 'User',
    sortable: false,
    minWidth: 330,
    renderCell: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{row.author}</Typography>
      </Box>
    ),
  },
];
