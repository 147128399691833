export const activityOptions = [
  'Profile Updates',
  'Availability',
  'PTO&CallOuts',
];
export const activityName = (propertyName) => {
  if (propertyName === 'availabilities') {
    return 'availabilities';
  }
  if (propertyName === 'PTO') {
    return 'PTO&CallOuts';
  }
  return 'Profile Updates';
};
