import { useGridApiRef } from '@mui/x-data-grid';
import { bool, instanceOf } from 'prop-types';
import React, { useCallback } from 'react';
import { getNoData } from '../../../shared/utils/common';
import { v4 as uuidv4 } from 'uuid';
import CustomTable from '../../../components/Table';
import { commonColumns, shadowVisitsColumns } from './constants';
import { customUpdateProgramTableStyles } from './styles';

function PossibilityVisitsTable({ dateVisits = [], isShadowVisits = false }) {
  const apiRef = useGridApiRef();
  const getRowId = useCallback((row) => `${row.visitType} - ${uuidv4()}`, []);

  return (
    <CustomTable
      apiRef={apiRef}
      columns={isShadowVisits ? shadowVisitsColumns : commonColumns}
      customStyles={customUpdateProgramTableStyles}
      customRowClassName="super-app-theme"
      getRowClassName={(params) =>
        params.row.shiftedBy ? 'shifted-visit' : ''
      }
      getRowId={getRowId}
      headerHeight={44}
      noRowsOverlay={() =>
        getNoData({
          title: isShadowVisits
            ? 'Have no deleted shadow visits'
            : 'Have no re-scheduled visits on this day',
        })
      }
      rowHeight={107}
      rows={dateVisits}
      withoutPagination
    />
  );
}

PossibilityVisitsTable.propTypes = {
  dateVisits: instanceOf(Array),
  isShadowVisits: bool,
};

export default React.memo(PossibilityVisitsTable);
