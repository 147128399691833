import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, MenuItem, Select } from '@mui/material';
import { bool, func, instanceOf, string } from 'prop-types';
import React from 'react';
import SelectRenderValue from './SelectRenderValue';
import {
  customSelectItem,
  customSelectPaperProps,
  customSelectStyles,
} from './styles';

export default function CustomTeamSelect({
  additionalSelectStyles = {},
  changeValue = () => {},
  disabled = false,
  isLoading = false,
  options = [],
  placeHolder = '',
  selectedValue = '',
  teamBgColor = '',
}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    changeValue(value);
  };

  return (
    <Select
      IconComponent={ExpandMoreIcon}
      className={selectedValue?.length ? 'has-selected-items' : ''}
      disabled={isLoading || !options.length || disabled}
      displayEmpty
      onChange={handleChange}
      sx={{ ...customSelectStyles, ...additionalSelectStyles }}
      MenuProps={{
        PaperProps: {
          sx: customSelectPaperProps,
        },
      }}
      renderValue={() => (
        <SelectRenderValue
          placeHolder={placeHolder}
          selectedValue={selectedValue}
          teamBgColor={teamBgColor}
        />
      )}
      value={selectedValue}
    >
      {options?.map(({ backgroundColor, name }) => (
        <MenuItem
          disabled={isLoading}
          disableRipple
          key={name}
          sx={customSelectItem}
          value={name}
        >
          {name}
          <Box
            sx={{
              width: '14px',
              height: '14px',
              backgroundColor,
              borderRadius: '50%',
            }}
          />
        </MenuItem>
      ))}
    </Select>
  );
}

CustomTeamSelect.propTypes = {
  additionalSelectStyles: instanceOf(Object),
  changeValue: func,
  disabled: bool,
  isLoading: bool,
  options: instanceOf(Array),
  placeHolder: string,
  selectedValue: string,
  teamBgColor: string,
};
