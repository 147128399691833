import { Alert, AlertTitle, Box, Typography } from '@mui/material';
import errorIconTriangle from '../../../../img/icons/error-icon.svg';
import errorIcon from '../../../../img/icons/error-outlined.svg';
import successIcon from '../../../../img/icons/success-outlined.svg';
import warningIconTriangle from '../../../../img/icons/warning-icon.svg';
import warningIcon from '../../../../img/icons/warning-outlined-icon.svg';
import { bool, instanceOf, string } from 'prop-types';
import React, { useMemo } from 'react';
import { reasonsStyle } from '../../styles';
import { getAlertStyles } from '../VisitDetails/styles';
import { getListMarkerStyles, listItemsStyles } from './styles';

export default function ConstraintsAlert({
  alertData = {},
  iconType = '',
  outlined = false,
}) {
  const icons = {
    circle: {
      error: <img src={errorIcon} alt="error icon" />,
      partiallySuccessfulError: <img src={successIcon} alt="success icon" />,
      partiallySuccessfulWarning: <img src={successIcon} alt="success icon" />,
      success: <img src={successIcon} alt="success icon" />,
      warning: <img src={warningIcon} alt="warning icon" />,
    },
    triangle: {
      error: <img src={errorIconTriangle} alt="error icon" />,
      warning: <img src={warningIconTriangle} alt="error icon" />,
    },
  };

  const { reasons, title, type } = alertData;
  const alertWrapperStyles = useMemo(
    () => getAlertStyles(outlined, type),
    [outlined, type],
  );
  const listMarkerStyles = useMemo(() => getListMarkerStyles(type), [type]);
  return (
    <Alert icon={icons[iconType][type]} severity={type} sx={alertWrapperStyles}>
      <AlertTitle sx={{ mt: '4px', mb: 0, fontWeight: 700 }}>
        {title}
      </AlertTitle>
      {reasons &&
        reasons?.map((reason) => (
          <Box key={reason} sx={listItemsStyles}>
            <Box sx={listMarkerStyles} />
            <Typography sx={reasonsStyle}>{reason}</Typography>
          </Box>
        ))}
    </Alert>
  );
}

ConstraintsAlert.propTypes = {
  alertData: instanceOf(Object),
  iconType: string,
  outlined: bool,
};
