import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import hardConstraintsWarning from '../../../../../../img/icons/error-icon.svg';
import { bool, func, instanceOf } from 'prop-types';
import React from 'react';
import { COLORS } from '../../../../../../shared/styles/Theme';
import CustomDialog from '../../../../../../components/Dialog';
import {
  caregiverListStyles,
  caregiverNameStyles,
  errorModalBoxStyles,
  listBoxStyles,
} from './styles';

function ErrorTerritoryTerritory({
  errorData = [],
  openDeleteDialog = false,
  setOpenDeleteDialog = () => {},
}) {
  return (
    <CustomDialog
      cancelCallback={() => setOpenDeleteDialog(false)}
      submitButtonName="OK"
      submitCallback={() => setOpenDeleteDialog(false)}
      title="Error"
      openDialog={openDeleteDialog}
      withBorder
    >
      <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
        <Box sx={errorModalBoxStyles}>
          <img src={hardConstraintsWarning} alt="arrivalIcon" />
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            Please, delete the following associated Teams first:
          </Typography>
        </Box>
        <Box sx={listBoxStyles}>
          <Box sx={{ pl: '20px' }}>
            <Typography sx={{ mb: '16px' }} variant="h5">
              Teams: {errorData?.length}
            </Typography>
            {errorData?.map((team) => (
              <List>
                <ListItem sx={{ padding: '6px', fontSize: '14px' }}>
                  <ListItemIcon sx={{ minWidth: '16px' }}>
                    <FiberManualRecordIcon sx={caregiverListStyles} />
                  </ListItemIcon>

                  <Typography variant="body1" sx={caregiverNameStyles}>
                    {team?.teamName}
                  </Typography>
                </ListItem>
              </List>
            ))}
          </Box>
        </Box>
      </Box>
    </CustomDialog>
  );
}

ErrorTerritoryTerritory.propTypes = {
  errorData: instanceOf(Array),
  openDeleteDialog: bool,
  setOpenDeleteDialog: func,
};
export default ErrorTerritoryTerritory;
