import { COLORS } from '../../../../shared/styles/Theme';

const customSelectItem = {
  display: 'flex',
  fontSize: '15px',
  margin: '0 8px',
  color: `${COLORS.blue[900]}`,
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  '&.select-all-item': {
    paddingBottom: 0,
    '&.MuiMenuItem-root:hover': {
      backgroundColor: '#ffffff',
    },
  },
  '&:focus ': {
    backgroundColor: '#ffffff',
  },
  '&:hover': {
    backgroundColor: `${COLORS.blue[50]}`,
  },
  '&.Mui-selected, &.Mui-selected:active,  &.Mui-selected:focus': {
    backgroundColor: '#ffffff',
  },
  '&.Mui-selected:hover': {
    backgroundColor: `${COLORS.blue[50]}`,
    cursor: 'default',
  },
};

const customPaperStyles = {
  padding: '8px',
  boxShadow: '0px 4px 12px 2px rgba(180, 180, 180, 0.2)',
  borderRadius: '10px',
  maxHeight: '300px',
  marginTop: '4px',
  marginLeft: '24px',
};

export { customPaperStyles, customSelectItem };
