import { COLORS } from '../../../../../../shared/styles/Theme';

export const customSwitcher = {
  '& .MuiSwitch-thumb': {
    background: '#fff',
  },
  '& .MuiSwitch-track': {
    background: COLORS.blue[20],
  },
  '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    background: COLORS.green[500],
    opacity: 1,
  },
};
export const tableStyles = [
  {
    '& .MuiAccordionDetails-root': {
      padding: '10px',
    },
    '& .MuiDataGrid-cellCheckbox:has(.Mui-disabled)': {
      display: 'none',
    },
    '& .MuiDataGrid-row.Mui-selected': {
      background: 'transparent',
    },
    '& .MuiDataGrid-row.Mui-selected:hover': {
      background: 'transparent',
    },
    '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
      background: 'transparent',
    },
    '& .MuiDataGrid-columnHeader': {
      padding: '0 10px',
    },
    '& .MuiDataGrid-selectedRowCount': {
      display: 'none',
    },
    '& .MuiDataGrid-row:nth-of-type(odd)': {
      maxHeight: '80px !important',
      minHeight: '80px !important',
    },

    '& .MuiDataGrid-cell': {
      borderTop: 'none',
    },
    '& .MuiDataGrid-row:nth-of-type(even)': {
      borderTop: 'none',
    },
    '& .MuiDataGrid-columnHeader--moving': {
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-columnHeader:first-of-type': {
      width: '50px !important',
      minWidth: '30px !important',
    },
    '& .MuiDataGrid-cell:first-of-type': {
      padding: '0 0 0 6px',
    },
  },
];

export const filtersWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px 20px',
};

export const selectAllButtonStyles = {
  padding: '0px 16px 0px 16px',
  borderRadius: '10px',
  border: `1px solid ${COLORS.blue[200]}`,
  color: COLORS.blue[500],
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

export const showTasksLinkStyles = {
  marginLeft: '20px',
  maxWidth: '200px',
  display: 'flex',
  alignItems: 'center',
  gap: 1,
};
