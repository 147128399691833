import { Box, Typography } from '@mui/material';
import { bool, func, number } from 'prop-types';
import React from 'react';
import CustomDialog from '../../../../../../components/Dialog';

function DeleteVisitModal({
  confirmVisitDeleting = () => {},
  setOpenDeleteVisitDialog = () => {},
  openDeleteVisitDialog = false,
  visitLength = 0,
}) {
  return (
    <CustomDialog
      cancelButtonName="Cancel"
      cancelCallback={() => setOpenDeleteVisitDialog(false)}
      submitButtonName="Confirm"
      submitCallback={confirmVisitDeleting}
      title="Holiday"
      openDialog={openDeleteVisitDialog}
    >
      <Box
        sx={{
          padding: '10px 30px',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Typography variant="body1">
          There are {visitLength} allocated visits on this date.
        </Typography>
        <Typography variant="h3" sx={{ fontWeight: 600 }}>
          If you confirm all these visits will be permanently deleted. This
          action cannot be undone.
        </Typography>
        <Typography> Do you want to continue?</Typography>
      </Box>
    </CustomDialog>
  );
}
DeleteVisitModal.propTypes = {
  confirmVisitDeleting: func,
  setOpenDeleteVisitDialog: func,
  openDeleteVisitDialog: bool,
  visitLength: number,
};

export default DeleteVisitModal;
