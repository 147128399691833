import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import {
  useGetClientTasksListQuery,
  useUpdateCarePlanTasksMutation,
} from '../../../../api/CarePlan/api';
import AddTaskProvider from '../../../../pages/ClientDetails/components/CreateTask';
import { CarePlanContext } from '../../../../pages/ClientDetails/context';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router';
import { DEFAULT_SORTING_MODEL } from '../../../../shared/constants';
import AlertContext from '../../../../components/Alert';
import Loader from '../../../../components/Loader';
import CustomTable from '../../../../components/Table';
import CarePlanHistory from './components/CarePlanHistory';
import FiltersWrapper from './components/Filters';
import { Header } from './components/Header';
import { INITIAL_CARE_PLAN_FILTERS, columns } from './constants';
import { CarePlanTableStyles } from './styles';

function CarePlan() {
  const { id, tabSubView } = useParams();
  const { setAlert } = useContext(AlertContext);
  const [sortOption, setSortOption] = useState(DEFAULT_SORTING_MODEL);
  const [carePlanFilters, setCarePlanFilters] = useState(
    INITIAL_CARE_PLAN_FILTERS,
  );
  const {
    data: carePlanTasks,
    error,
    isLoading,
  } = useGetClientTasksListQuery(
    { clientId: id, carePlanFilters, sortOption },
    { refetchOnMountOrArgChange: true },
  );
  const [updateCarePlanTasks, { isLoading: isUpdateTasksLoading }] =
    useUpdateCarePlanTasksMutation();
  const apiRef = useGridApiRef();

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);

  const getRowId = useCallback(
    (row) => `${row.name} ${row.levelOfAssistance}`,
    [],
  );

  const memoizedProviderValue = useMemo(
    () => ({
      apiRef,
      carePlanTasks,
      isLoading,
      setSortOption,
      sortOption,
      updateCarePlanTasks,
    }),
    [apiRef, carePlanTasks, isLoading, sortOption, updateCarePlanTasks],
  );

  const updateSorting = useCallback((data) => setSortOption(data[0]), []);

  return (
    <CarePlanContext.Provider value={memoizedProviderValue}>
      <AddTaskProvider
        sortOption={sortOption}
        carePlanFilters={carePlanFilters}
        tasksListData={carePlanTasks}
        updateTasksList={updateCarePlanTasks}
      >
        {isLoading || (isUpdateTasksLoading && <Loader />)}
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Header />
          {tabSubView === 'current' ? (
            <FiltersWrapper
              carePlanFilters={carePlanFilters}
              isLoading={isLoading}
              setCarePlanFilters={setCarePlanFilters}
            />
          ) : null}
          {tabSubView === 'current' ? (
            <CustomTable
              apiRef={apiRef}
              columns={columns}
              customStyles={CarePlanTableStyles}
              headerHeight={44}
              getRowId={getRowId}
              isLoading={isLoading}
              onRowClick={() => {}}
              rows={carePlanTasks?.tasks}
              setSortModelState={updateSorting}
              sortModel={[sortOption]}
              withoutPagination
            />
          ) : (
            <CarePlanHistory />
          )}
        </Box>
      </AddTaskProvider>
    </CarePlanContext.Provider>
  );
}

export default React.memo(CarePlan);
