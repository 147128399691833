import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Avatar, MenuItem, Select, Typography } from '@mui/material';
import { func, instanceOf, string } from 'prop-types';
import React from 'react';
import { COLORS } from '../../../../../../../../../shared/styles/Theme';
import stringAvatar from '../../../../../../../../../shared/utils/stringAvatar';
import SelectRenderValue from '../../../../../../../../../components/Select/SelectRenderValue';
import {
  customPaperStyles,
  customSelectItem,
  customSelectStyles,
} from '../../../../../../../../../components/Select/style';

export default function CaregiverSelect({
  options = [],
  placeHolder = '',
  selectedValue = {},
  setValue = () => {},
}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const item = options.find((option) => option.id === value);

    setValue(item);
    return '';
  };

  return (
    <Select
      IconComponent={ExpandMoreIcon}
      className={selectedValue?.id ? 'has-selected-items' : ''}
      displayEmpty
      onChange={handleChange}
      sx={{
        ...customSelectStyles,
        '& .MuiSelect-select': {
          height: '40px !important',
        },
        maxWidth: '280px',
      }}
      MenuProps={{
        PaperProps: {
          sx: customPaperStyles,
        },
      }}
      renderValue={() => (
        <SelectRenderValue
          options={options?.map(
            ({ firstName, lastName }) => `${firstName} ${lastName}`,
          )}
          placeHolder={placeHolder}
          selectedValue={
            selectedValue
              ? `${selectedValue?.firstName} ${selectedValue?.lastName}`
              : ''
          }
        />
      )}
      value={selectedValue ? selectedValue?.id : ''}
    >
      {options?.map((option) => (
        <MenuItem
          key={option.id}
          disableRipple
          sx={{
            ...customSelectItem,
            padding: '4px 12px',
          }}
          value={option.id}
        >
          <Avatar
            alt="user avatar"
            src={option.avatar}
            {...stringAvatar(`${option.firstName} ${option.lastName}`)}
          />
          <Typography
            sx={{ ml: '8px', color: COLORS.blue[900] }}
            variant="body1"
          >
            {`${option.firstName} ${option.lastName}`}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
}

CaregiverSelect.propTypes = {
  options: instanceOf(Array),
  placeHolder: string,
  selectedValue: instanceOf(Object),
  setValue: func,
};
