import { COLORS } from '../../../../../../shared/styles/Theme';

export const autocompletePaperStyles = {
  padding: '18px 0',
  boxShadow: '0px 4px 12px 2px rgba(180, 180, 180, 0.2)',
  borderRadius: '10px',
  '& .MuiAutocomplete-listbox': {
    maxHeight: '300px',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    "& .MuiAutocomplete-option[aria-selected='true']": {
      backgroundColor: COLORS.blue[50],
    },
  },
};
