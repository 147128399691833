import { Box, Chip, Typography } from '@mui/material';
import moment from 'moment/moment';
import { instanceOf, number } from 'prop-types';
import React, { useMemo } from 'react';
import { COLORS } from '../../../../../../shared/styles/Theme';
import {
  chipArrivalStartStyle,
  chipArrivalTimeEndStyle,
  chipArrivalWindowStyle,
  chipEndStyle,
  chipStartStyle,
  durationStyle,
  getArrivalWindowStyles,
} from '../styles';

export default function ArrivalWindow({
  arrivalTimeStart = {},
  arrivalWindowHeight = 0,
  event = {},
  start = {},
}) {
  const arrivalWindowStyles = useMemo(
    () => getArrivalWindowStyles(arrivalWindowHeight),
    [arrivalWindowHeight],
  );
  return (
    <Box>
      <Chip label={moment(event.start).format('h:mm A')} sx={chipStartStyle} />
      <Chip label={moment(event.end).format('h:mm A')} sx={chipEndStyle} />
      {event.duration > 30 && (
        <Box sx={durationStyle}>
          <Typography
            sx={{ color: '#2C2D2E', fontSize: '12px', lineHeight: '16px' }}
          >
            Duration {event.duration} min
          </Typography>
        </Box>
      )}
      <Chip
        label={moment(arrivalTimeStart).format('h:mm A')}
        sx={{
          ...chipArrivalStartStyle,
          top: `-${arrivalWindowHeight + 10}px`,
        }}
      />
      <Chip
        label={moment(start).format('h:mm A')}
        sx={{
          ...chipArrivalTimeEndStyle,
        }}
      />
      <Box
        sx={{
          ...chipArrivalWindowStyle,
          top: `-${arrivalWindowHeight / 2 + 10}px`,
        }}
      >
        <Typography
          sx={{
            color: COLORS.black[700],
            fontSize: '12px',
            lineHeight: '16px',
          }}
        >
          Arrival window
        </Typography>
      </Box>
      <Box sx={arrivalWindowStyles} />
    </Box>
  );
}

ArrivalWindow.propTypes = {
  arrivalTimeStart: instanceOf(Object),
  arrivalWindowHeight: number,
  event: instanceOf(Object),
  start: instanceOf(Object),
};
