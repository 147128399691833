import { Box, Typography } from '@mui/material';
import { string } from 'prop-types';
import React, { useMemo } from 'react';
import {
  customLabelStyles,
  customSelectedValueStyles,
  customSelectedValueWrapper,
  getTeamColorStyles,
} from './styles';

export default function SelectRenderValue({
  placeHolder = '',
  selectedValue = '',
  teamBgColor = '',
}) {
  const teamColorStyles = useMemo(
    () => getTeamColorStyles(teamBgColor),
    [teamBgColor],
  );
  return (
    <Box sx={customSelectedValueWrapper}>
      <Box
        className={`animated-placeholder ${
          selectedValue?.length ? 'has-selected-items' : ''
        }`}
        sx={customLabelStyles}
      >
        {placeHolder}
      </Box>
      <Box sx={customSelectedValueStyles}>
        <Typography noWrap>{selectedValue}</Typography>
      </Box>
      <Box sx={teamColorStyles} />
    </Box>
  );
}

SelectRenderValue.propTypes = {
  placeHolder: string,
  selectedValue: string,
  teamBgColor: string,
};
