import { COLORS } from '../../../../shared/styles/Theme';

const customSelectStyles = {
  width: '200px',
  fontSize: '15px',
  minHeight: '40px',
  color: `${COLORS.blue[500]}`,
  borderRadius: '8px',
  '&.MuiSvgIcon-root': {
    fill: `${COLORS.blue[300]}`,
    width: '20px',
    height: '20px',
    top: 11,
  },
  '&.MuiOutlinedInput-root': {
    border: 'none',
    backgroundColor: COLORS.blue[50],
    padding: 0,
    '.MuiOutlinedInput-input': {
      height: '100%',
      padding: '0 16px',
    },
    fieldset: {
      border: 'none',
    },
  },
  '&.MuiOutlinedInput-root.has-selected-items': {
    color: COLORS.blue[900],
  },
  '& [aria-expanded=true] .animated-placeholder, & .animated-placeholder.has-selected-items':
    {
      top: 3,
      fontSize: '11px',
      transition: 'top 0.3s ease, fontSize 0.7s ease-out',
    },
};

const customSelectPaperProps = {
  padding: '8px',
  boxShadow: '0px 4px 12px 2px rgba(180, 180, 180, 0.2)',
  borderRadius: '10px',
};

const customSelectItem = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '15px',
  color: `${COLORS.blue[900]}`,
  borderRadius: '8px',
  backgroundColor: '#ffffff',
  '&.select-all-item': {
    borderBottom: `1px solid ${COLORS.blue[200]}`,
    paddingBottom: '8px',
  },
  '&:hover, &:active, &:focus, ': {
    backgroundColor: '#ffffff',
  },
  '&:hover': {
    backgroundColor: `${COLORS.blue[50]}`,
  },
  '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:active,  &.Mui-selected:focus':
    {
      backgroundColor: '#ffffff',
    },
};

const customSelectedValueWrapper = {
  display: 'flex',
  position: 'relative',
  height: '100%',
  pb: '3px',
};

const customSelectedValueStyles = {
  display: 'flex',
  maxWidth: '88%',
  overflow: 'hidden',
  alignItems: 'end',
  color: COLORS.blue[900],
};

const customLabelStyles = {
  display: 'flex',
  position: 'absolute',
  top: 10,
  left: '-1px',
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '19px',
  letterSpacing: '0em',
  color: COLORS.blue[500],
  transition: 'top 0.3s ease, fontSize 1s ease',
};

const getTeamColorStyles = (teamBgColor) => ({
  position: 'absolute',
  width: '14px',
  height: '14px',
  backgroundColor: teamBgColor,
  borderRadius: '50%',
  top: '12px',
  right: '12px',
});

export {
  customLabelStyles,
  customSelectItem,
  customSelectPaperProps,
  customSelectStyles,
  customSelectedValueStyles,
  customSelectedValueWrapper,
  getTeamColorStyles,
};
