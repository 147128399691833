import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { getAvailabilityColumns } from '../../../../../../pages/CaregiverDetails/view/Availability/constants';
import { bool, func, instanceOf, string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import CustomTable from '../../../../../../components/Table';

function AvailabilityTable({
  availabilityData = {},
  isLoading = true,
  noRowsOverlay = () => {},
  availabilityScheduleView = '1',
}) {
  const apiRef = useGridApiRef();
  const [checkedRows, setCheckedRows] = useState([]);
  useEffect(() => {
    if (availabilityData[availabilityScheduleView]?.days) {
      setCheckedRows(
        availabilityData[availabilityScheduleView]?.days
          ?.filter((row) => row.startTime !== null)
          ?.map((row) => row.id),
      );
    }
  }, [availabilityData, availabilityScheduleView]);
  const caregiverWithoutCurrentAvailability =
    availabilityData.length === 1 &&
    availabilityData?.[0].days?.every((t) => t.startTime === null);

  const columns = useMemo(
    () =>
      getAvailabilityColumns({
        caregiverWithoutCurrentAvailability,
        checkedRows,
        startDate: availabilityData[availabilityScheduleView]?.effectiveDate,
      }),
    [
      availabilityData,
      availabilityScheduleView,
      caregiverWithoutCurrentAvailability,
      checkedRows,
    ],
  );

  return (
    <Box>
      <CustomTable
        apiRef={apiRef}
        columns={columns}
        headerHeight={44}
        noRowsOverlay={noRowsOverlay}
        isLoading={isLoading}
        rowHeight={80}
        rows={availabilityData[availabilityScheduleView]?.days}
        withoutPagination
      />
    </Box>
  );
}

AvailabilityTable.propTypes = {
  availabilityData: instanceOf(Object),
  isLoading: bool,
  noRowsOverlay: func,
  availabilityScheduleView: string,
};
export default AvailabilityTable;
