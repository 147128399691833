import moment from 'moment/moment';
import { COLORS } from '../../../../shared/styles/Theme';

export const eventCardInfoWrapper = {
  display: 'flex',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
};

export const arrivalWindowBoxStyles = ({
  arrivalStart,
  arrivalWindowHeight,
  arrivalWindowStartPosition,
  eventStart,
}) => ({
  height: `${arrivalWindowHeight}px`,
  border: `2px dashed ${COLORS.blue[700]}`,
  borderBottom: 'none',
  position: 'absolute',
  top: moment(eventStart).isAfter(arrivalStart)
    ? `-${arrivalWindowStartPosition}px`
    : `${-arrivalWindowStartPosition}px`,
  width: '100%',
  zIndex: 1,
});

export const showTeamStyles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: '600',
  overflow: 'hidden',
  transition: 'height 0.3s ease',
  position: 'absolute',
  zIndex: 1,
};
