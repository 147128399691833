import { Box, Typography } from '@mui/material';
import {
  useDeleteTerritoryIdMutation,
  useUpdateTerritoryByIdMutation,
} from '../../../../../../api/Administration/api';
import EditIcon from '../../../../../../img/icons/edit-icon.svg';
import DeleteIcon from '../../../../../../img/icons/iconButtons/delete-icon.svg';
import { instanceOf } from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { COLORS } from '../../../../../../shared/styles/Theme';
import AlertContext from '../../../../../../components/Alert';
import CustomDialog from '../../../../../../components/Dialog';
import TableRowActionsWithIcons from '../../../../../../components/TableRowActionsWithIcons';
import AddTerritoryModal from '../AddTerritoryModal';
import ErrorTerritoryTerritory from './ErrorTerritoryTerritory';
import { teamColorBoxStyles } from './styles';

function TerritoryTableActions({ row = {}, locations = [] }) {
  const { setAlert } = useContext(AlertContext);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [territoryName, setTerritoryName] = useState(row?.name);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [order, setOrder] = useState(row?.order);
  const [updateTerritoryById, { error: updateTerritoryByIdError }] =
    useUpdateTerritoryByIdMutation();
  const [deleteTerritory, { error: deleteTerritoryByIdError }] =
    useDeleteTerritoryIdMutation();
  useEffect(() => {
    if (updateTerritoryByIdError) {
      const errorData = updateTerritoryByIdError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [setAlert, updateTerritoryByIdError]);
  useEffect(() => {
    if (deleteTerritoryByIdError?.status === 405) {
      setOpenErrorModal(true);
    }
  }, [deleteTerritoryByIdError?.status]);
  const updateUser = () => {
    updateTerritoryById({
      id: row?.id,
      payload: {
        name: territoryName,
        order,
      },
    });
    setOpenEditDialog(false);
  };
  const deleteHolidayById = () => {
    deleteTerritory(row?.id);
    setOpenDeleteDialog(false);
  };
  const menuItems = useMemo(
    () => [
      {
        icon: EditIcon,
        key: 'Edit',
        name: 'Edit',
        callback: () => {
          setOpenEditDialog(true);
          setOrder(row?.order);
        },
      },
      {
        icon: DeleteIcon,
        key: 'Remove',
        name: 'Remove',
        callback: () => {
          setOpenDeleteDialog(true);
        },
      },
    ],
    [row.order],
  );
  const cancelEditTerritory = () => {
    setOpenEditDialog(false);
    setOrder(row?.order);
    setTerritoryName(row.name);
  };
  return (
    <Box sx={teamColorBoxStyles}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography>{row?.name}</Typography>
      </Box>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={cancelEditTerritory}
        submitButtonName="Save"
        disabledSubmit={territoryName?.length === 0}
        submitCallback={updateUser}
        title="Edit Territory"
        openDialog={openEditDialog}
      >
        <Box sx={{ width: '100%', padding: '0 28px 20px 13px' }}>
          <AddTerritoryModal
            territoryName={territoryName}
            setTerritoryName={setTerritoryName}
            data={locations}
            order={order}
            setOrder={setOrder}
          />
        </Box>
      </CustomDialog>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setOpenDeleteDialog(false)}
        submitButtonName="confirm"
        submitCallback={deleteHolidayById}
        title="Confirmation"
        openDialog={openDeleteDialog}
      >
        <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            The Territory
            <span style={{ fontWeight: 'bold', margin: '0 4px' }}>
              {row.name}
            </span>
            will be removed. Do you want to proceed?
          </Typography>
        </Box>
      </CustomDialog>
      <ErrorTerritoryTerritory
        openDeleteDialog={openErrorModal}
        setOpenDeleteDialog={setOpenErrorModal}
        errorData={deleteTerritoryByIdError?.data?.payload}
      />
      <TableRowActionsWithIcons menuItems={menuItems} />
    </Box>
  );
}

TerritoryTableActions.propTypes = {
  locations: instanceOf(Array),
  row: instanceOf(Object),
};

export default React.memo(TerritoryTableActions);
