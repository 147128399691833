import { COLORS } from '../../../../../../../../../../shared/styles/Theme';

export const customAccordionStyles = {
  borderBottom: '1px solid #ffffff',
  backgroundColor: COLORS.black[50],
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 0,
    borderBottom: 'none',
  },
  '&:last-of-type, &.Mui-expanded:last-of-type': {
    borderRadius: 0,
  },
  borderRadius: 0,
};

export const customAccordionSummaryStyles = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row-reverse',
  padding: 0,
  cursor: 'default',
  '&.MuiAccordionSummary-root, &.MuiAccordionSummary-root.Mui-expanded': {
    minHeight: '28px',
    pl: '15px',
  },
  '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded':
    {
      justifyContent: 'space-between',
      margin: 0,
      paddingRight: '5px',
      cursor: 'default',
    },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
};

export const caregiverNameStyles = {
  display: 'flex',
  alignItems: 'center',
  '& span': {
    color: COLORS.blue[500],
    mr: '12px',
  },
};

export const visitAccordionSummaryTitle = {
  display: 'flex',
  alignItems: 'center',
  height: '28px',
  cursor: 'pointer',
  pl: '7px',
  '& .MuiTypography-root': {
    fontSize: '13px',
    textTransform: 'uppercase',
    lineHeight: '13px',
    cursor: 'pointer',
  },
};
