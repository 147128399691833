import { Stack, Typography } from '@mui/material';
import noDataIcon from '../../../../img/icons/no-data.svg';
import { func } from 'prop-types';
import React, { useState } from 'react';
import CustomDialog from '../../../../components/Dialog';
import UnsavedChangesContent from '../../../../components/Dialog/UnsavedChangesContent';
import { noDataTableHintStyles } from '../../../../components/Table/styles';

export default function NoRowsComponent({ goToEditTasks = () => {} }) {
  const [showDialog, setShowDialog] = useState(false);
  return (
    <>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setShowDialog(false)}
        submitButtonName="confirm"
        submitCallback={goToEditTasks}
        title="Confirmation"
        openDialog={showDialog}
      >
        <UnsavedChangesContent />
      </CustomDialog>
      <Stack height="100%" alignItems="center" justifyContent="center">
        <img src={noDataIcon} alt="" />
        <Typography sx={noDataTableHintStyles}>
          No tasks yet.{' '}
          <Typography
            component="span"
            className="link"
            onClick={() => setShowDialog(true)}
          >
            Edit tasks in Care Plan
          </Typography>
        </Typography>
      </Stack>
    </>
  );
}

NoRowsComponent.propTypes = {
  goToEditTasks: func,
};
