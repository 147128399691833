import { Box, Typography } from '@mui/material';
import hardConstraintsWarning from '../../../../../../img/icons/error-icon.svg';
import WarningIcon from '../../../../../../img/icons/warning-icon.svg';
import { instanceOf, string } from 'prop-types';
import React from 'react';
import { COLORS } from '../../../../../../shared/styles/Theme';
import {
  getIconComponent,
  violationTooltipComponent,
} from '../../../../../../shared/utils/common';

export default function VisitType({ row = {}, value = '' }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ mr: '6px' }}>
        {row?.violatedSoftConstraints?.length > 0 &&
          violationTooltipComponent({
            violations: row.violatedSoftConstraints,
            icon: WarningIcon,
          })}
        {row?.violatedHardConstraints?.length > 0 &&
          violationTooltipComponent({
            violations: row.violatedHardConstraints,
            icon: hardConstraintsWarning,
          })}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            ml: row?.shiftedBy ? '40px' : 0,
            mb: '6px',
          }}
        >
          {getIconComponent(value)}
          <Typography
            variant={row?.shiftedBy ? 'body1' : 'h5'}
            sx={{
              ml: '8px',
              fontSize: '14px',
              color: COLORS.blue[700],
            }}
          >
            {value}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

VisitType.propTypes = {
  row: instanceOf(Object),
  value: string,
};
