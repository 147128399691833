import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, IconButton } from '@mui/material';
import { CreateVisitContext } from '../../../../../../../../../../pages/ClientDetails/context';
import { instanceOf, string } from 'prop-types';
import React, { useCallback, useContext, useMemo } from 'react';
import { COLORS } from '../../../../../../../../../../shared/styles/Theme';
import CustomTooltip from '../../../../../../../../../../components/CustomTooltip';
import { getTaskActionsStyles } from './styles';

export default function TaskActions({ day = '', row = {} }) {
  const { availableVisitTasks, setAvailableVisitTasks } =
    useContext(CreateVisitContext);
  const dayIndex = useMemo(
    () => availableVisitTasks.findIndex((item) => item.day === day),
    [availableVisitTasks, day],
  );
  const rowTasks = [...availableVisitTasks[dayIndex].tasks];

  const swapTasks = (target, currentItem) => {
    if (!rowTasks?.length) {
      return true;
    }
    const copiedTasks = [...rowTasks];
    const currentIndex = rowTasks.findIndex(
      ({ carePlanTaskId }) => carePlanTaskId === currentItem,
    );
    if (currentIndex === -1) {
      return true;
    }
    const targetIndex = currentIndex + target;
    [copiedTasks[targetIndex], copiedTasks[currentIndex]] = [
      copiedTasks[currentIndex],
      copiedTasks[targetIndex],
    ];
    const newAvailableVisitTasks = [...availableVisitTasks];
    newAvailableVisitTasks[dayIndex] = {
      ...availableVisitTasks[dayIndex],
      tasks: copiedTasks,
    };
    setAvailableVisitTasks(newAvailableVisitTasks);
    return true;
  };

  // const deleteTask = () => {
  //   setAvailableVisitTasks({
  //     ...availableVisitTasks,
  //     tasks: availableVisitTasks.tasks.filter(({ id }) => id !== row.id),
  //   });
  // };

  const itemIndex = rowTasks.findIndex(
    ({ carePlanTaskId }) => carePlanTaskId === row?.carePlanTaskId,
  );

  const actions = [
    {
      callback: () => swapTasks(1, row?.carePlanTaskId),
      disabled: itemIndex === rowTasks.length - 1,
      icon: (
        <ArrowDownwardIcon sx={{ fill: COLORS.blue[300], fontSize: '20px' }} />
      ),
      label: 'Move down',
    },
    {
      callback: () => swapTasks(-1, row?.carePlanTaskId),
      disabled: itemIndex < 1,
      icon: (
        <ArrowUpwardIcon sx={{ fill: COLORS.blue[300], fontSize: '20px' }} />
      ),
      label: 'Move up',
    },
    // {
    //   callback: () => setOpenDeleteDialog(true),
    //   disabled: availableVisitTasks.length < 2,
    //   icon: <img src={DeleteIcon} alt="Delete" />,
    //   label: 'Delete',
    // },
  ];

  const taskActionStyles = useCallback(
    (action) => getTaskActionsStyles(action),
    [],
  );

  return (
    <Box sx={{ display: 'flex', gap: '16px' }}>
      {actions?.map((action) => (
        <CustomTooltip title={action.label} key={action.label}>
          <IconButton
            aria-label={action.label}
            disableRipple
            onClick={() => (action.disabled ? {} : action.callback())}
            sx={taskActionStyles(action)}
          >
            {action.icon}
          </IconButton>
        </CustomTooltip>
      ))}
    </Box>
  );
}

TaskActions.propTypes = {
  day: string,
  row: instanceOf(Object),
};
