import { COLORS } from '../../../../../../../../shared/styles/Theme';

export const customVisitsTableStyles = [
  {
    '& .MuiDataGrid-virtualScroller': {
      maxHeight: '100%',
    },
    '& .MuiDataGrid-row.shifted-visit': {
      borderTop: 'none',
    },
    '& .MuiDataGrid-row.shifted-visit .MuiDataGrid-cell:not(:first-of-type)': {
      borderTop: `1px solid ${COLORS.blue[200]}`,
    },
    '& .MuiDataGrid-cell:first-of-type': {
      padding: '0 16px 0 20px',
    },
    '& .MuiDataGrid-cell:last-of-type': {
      padding: '0 28px 0 16px',
    },
    '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-columnHeader--moving': {
      backgroundColor: 'transparent',
    },
  },
];

export const customCellWrapper = {
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
};
export const notAllocatedTitleStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: 1,
  mb: '16px',
};

export const visitCaregiverInfo = {
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  '& img': { width: '14px', height: '14px' },
};

export const caregiverNameStyles = {
  color: COLORS.blue[700],
  fontWeight: '600',
  fontSize: '14px',
  cursor: 'pointer',
};
