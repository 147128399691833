import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { bool, func, string } from 'prop-types';
import React from 'react';
import { visitArrivalTimeTypes } from '../../shared/constants';
import { formControlStyles, radioButtonStyles } from './styles';

export default function ArrivalTypeSelector({
  arrivalTimeType = '',
  changeArrivalTimeType = () => {},
  disabled = false,
}) {
  return (
    <RadioGroup
      defaultValue="exactTime"
      name="radio-buttons-group"
      onChange={changeArrivalTimeType}
      row
      value={arrivalTimeType}
    >
      {visitArrivalTimeTypes.map(({ label, value }) => (
        <FormControlLabel
          control={<Radio sx={radioButtonStyles} />}
          disabled={disabled}
          key={value}
          label={label}
          sx={formControlStyles}
          value={value}
        />
      ))}
    </RadioGroup>
  );
}

ArrivalTypeSelector.propTypes = {
  arrivalTimeType: string,
  changeArrivalTimeType: func,
  disabled: bool,
};
