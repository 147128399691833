import { Box, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { func, instanceOf } from 'prop-types';
import React, { useEffect, useState } from 'react';
import AvatarGroups from '../../../../../../../../../components/AvatarGroup';
import { avatarGroupStyles } from '../../styles';
import CaregiverSelect from './CaregiverSelect';

export default function AvailableCaregiver({
  availableCaregivers = [],
  preferredCaregiver = {},
  setPreferredCaregiver = () => {},
}) {
  const [openChoosingCaregiver, setOpenChoosingCaregiver] = useState(false);

  useEffect(() => {
    if (!!preferredCaregiver && !openChoosingCaregiver) {
      setOpenChoosingCaregiver(true);
    }
  }, [openChoosingCaregiver, preferredCaregiver]);
  const handleCheckboxChange = () => {
    if (openChoosingCaregiver) {
      setOpenChoosingCaregiver(false);
      setPreferredCaregiver(null);
    } else {
      setOpenChoosingCaregiver(true);
    }
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="body1" sx={{ mb: '12px' }}>
        Available caregivers:
      </Typography>
      <AvatarGroups
        extendingStyles={avatarGroupStyles}
        maxLength={10}
        showTooltip
        users={availableCaregivers?.map(
          ({ firstName, lastName }) => `${firstName} ${lastName}`,
        )}
      />
      <FormControlLabel
        sx={{ maxWidth: '200px' }}
        control={<Checkbox checked={openChoosingCaregiver} />}
        onChange={handleCheckboxChange}
        label="Set preferred caregiver"
      />
      {openChoosingCaregiver && (
        <CaregiverSelect
          options={availableCaregivers}
          placeHolder="Caregiver"
          selectedValue={preferredCaregiver}
          setValue={setPreferredCaregiver}
        />
      )}
    </Box>
  );
}

AvailableCaregiver.propTypes = {
  availableCaregivers: instanceOf(Array),
  preferredCaregiver: instanceOf(Object),
  setPreferredCaregiver: func,
};
