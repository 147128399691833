import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { bool, func, instanceOf } from 'prop-types';
import React, { useMemo } from 'react';
import { getNoData } from '../../../../shared/utils/common';
import CustomDialog from '../../../../components/Dialog';
import CustomTable from '../../../../components/Table';
import { tasksColumns } from './constants';
import {
  tasksManagementTableStyles,
  visitTasksManagementWrapper,
} from './styles';

function VisitTaskTable({
  tasks = [],
  openTaskDialog = false,
  setOpenTaskDialog = () => {},
  updateTasks = () => {},
}) {
  const apiRef = useGridApiRef();
  const tableStyles = useMemo(
    () => tasksManagementTableStyles(!tasks.length),
    [tasks],
  );
  return (
    <CustomDialog
      cancelButtonName="Cancel"
      cancelCallback={() => setOpenTaskDialog(false)}
      submitButtonName="confirm"
      submitCallback={updateTasks}
      title="Tasks"
      openDialog={openTaskDialog}
      customStyles={{
        maxWidth: '1000px',
        padding: '0 20px',
        '& .MuiDialogTitle-root': {
          padding: '36px 4px 24px 4px',
        },
      }}
    >
      <Box sx={visitTasksManagementWrapper}>
        <CustomTable
          apiRef={apiRef}
          columns={tasksColumns}
          customStyles={tableStyles}
          headerHeight={34}
          noRowsOverlay={() => getNoData({ title: 'No tasks yet' })}
          onRowClick={() => {}}
          rows={tasks || []}
          withoutPagination
        />
      </Box>
    </CustomDialog>
  );
}

VisitTaskTable.propTypes = {
  tasks: instanceOf(Array),
  openTaskDialog: bool,
  setOpenTaskDialog: func,
  updateTasks: func,
};

export default React.memo(VisitTaskTable);
