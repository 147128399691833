import { TextField } from '@mui/material';
import { func, string } from 'prop-types';
import React from 'react';
import { customInputStyles } from './styles';

export default function CustomInput({
  label = '',
  id = '',
  changeTaskName = () => {},
  value = '',
  onBlur = () => {},
}) {
  return (
    <TextField
      autoComplete="off"
      label={label}
      id={id}
      variant="filled"
      onChange={changeTaskName}
      sx={{ ...customInputStyles, minHeight: '40px', mb: '4px' }}
      value={value}
      onBlur={onBlur}
    />
  );
}

CustomInput.propTypes = {
  label: string,
  id: string,
  changeTaskName: func,
  value: string,
  onBlur: func,
};
