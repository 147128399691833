import { Box, Typography } from '@mui/material';
import { instanceOf } from 'prop-types';
import React, { useCallback } from 'react';
import { COLORS } from '../../../../../shared/styles/Theme';
import { visitsSummaryData } from '../constants';

function VisitsSummary({ data = {} }) {
  const getVisitDetails = useCallback(
    (key) => {
      if (key === 'weeklyVisits') {
        return data?.plan?.weeklyVisits || 0;
      }
      if (key === 'weeklyHours') {
        return data?.plan?.weeklyHours?.toFixed(2) || 0;
      }
      if (key === 'WeeklyCost') {
        return data?.plan?.weeklyCost
          ? `$ ${data?.plan?.weeklyCost?.toFixed()}`
          : 'N/A';
      }
      if (key === 'effectiveHourlyRate') {
        return data?.plan?.effectiveHourlyRate
          ? `$ ${data?.plan?.effectiveHourlyRate?.toFixed(2)}`
          : 'N/A';
      }
      return false;
    },
    [data],
  );
  return (
    <Box sx={{ display: 'flex', mr: '32px', gap: '28px' }}>
      {visitsSummaryData?.map((visit) => (
        <Box
          key={visit.name}
          sx={{
            borderLeft: `1px solid ${COLORS.blue[200]}`,
            paddingLeft: '16px',
            height: '40px',
            '&:first-of-type': {
              borderLeft: 'none',
            },
          }}
        >
          <Typography sx={{ mb: '6px' }} variant="body2">
            {visit.name}
          </Typography>
          <Typography variant="h5">{getVisitDetails(visit.key)}</Typography>
        </Box>
      ))}
    </Box>
  );
}

VisitsSummary.propTypes = {
  data: instanceOf(Object),
};

export default React.memo(VisitsSummary);
