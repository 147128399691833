import { Box, Typography } from '@mui/material';
import { useGetFiltersDataQuery } from '../../../../../../api/commonApi/api';
import { dayBubbleWrapper } from '../../../../../../pages/ClientDetails/views/CarePlan/components/TableTaskCell/styles';
import { instanceOf } from 'prop-types';
import React from 'react';
import { visitTypeIcons } from '../../../../../../shared/constants';
import IconsCell from '../../../../../../components/IconsCell';

export default function RecurrencePattern({ row }) {
  const { data: filtersData } = useGetFiltersDataQuery({
    refetchOnMountOrArgChange: true,
  });

  const allDaysSelected = row?.days.length === filtersData?.weekdays.length;
  const allVisitTypes =
    row?.visitTypes.length === filtersData?.visitTypes.length;
  const allTimesOfDay =
    row?.timesOfDay.length === filtersData?.timesOfDay.length;
  const reversedArray = [];
  row?.visitTypes.forEach((element) => {
    reversedArray.unshift(element);
  });
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        width: '340px',
      }}
    >
      {allDaysSelected ? (
        <Typography variant="body1">—</Typography>
      ) : (
        <Box sx={{ display: 'flex', gap: '8px' }}>
          {row?.days?.map((day) => (
            <Box key={day} sx={dayBubbleWrapper}>
              {day.slice(0, 2).toUpperCase()}
            </Box>
          ))}
        </Box>
      )}
      {allTimesOfDay ? (
        <Typography variant="body1">—</Typography>
      ) : (
        row?.timesOfDay.map((time) => (
          <Typography key={time} variant="body1">
            {time}
          </Typography>
        ))
      )}
      {allVisitTypes ? (
        <Typography variant="body1">—</Typography>
      ) : (
        <IconsCell
          iconsMap={visitTypeIcons}
          items={reversedArray}
          maxWidth={300}
        />
      )}
    </Box>
  );
}

RecurrencePattern.propTypes = {
  row: instanceOf(Object),
};
