import { Box, IconButton, Menu, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import burgerMenu from '../../../../img/icons/burgerMenu.svg';
import burgerMenuActive from '../../../../img/icons/burgerMenuActive.svg';
import callOutIcon from '../../../../img/icons/callout.png';
import moment from 'moment';
import { ScheduleCaregiverManagementContext } from '../../../../pages/Scheduling/context';
import { func, instanceOf } from 'prop-types';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { customPaperStyles, customSelectItem } from './style';

export default function ManagementActions({
  addOneTimeAvailability = () => {},
  caregiver = {},
  isDateHoliday = {},
  selectedDate = {},
}) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorElTask] = useState(null);
  const navigate = useNavigate();
  const handleOpenUserMenu = useCallback((event) => {
    setAnchorElTask(event.currentTarget);
    setOpen(true);
  }, []);
  const handleCloseMenu = useCallback(() => {
    setAnchorElTask(null);
    setOpen(false);
  }, []);
  const { addCallOut, setChosenCaregiver } = React.useContext(
    ScheduleCaregiverManagementContext,
  );

  const isPrevDateSelected = moment(selectedDate).isBefore(moment(), 'days');
  const menuItems = useMemo(
    () => [
      {
        name: (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              ml: '-18px',
            }}
          >
            <img src={callOutIcon} alt="callOut" />
            <Typography> Callout </Typography>
          </Box>
        ),
        disabled: isPrevDateSelected,
        callback: () => {
          addCallOut();
          setChosenCaregiver(caregiver.caregiver);
          handleCloseMenu();
        },
      },
      {
        name: 'Profile',
        callback: () => {
          navigate(`/caregivers/${caregiver.caregiver.id}`);
          handleCloseMenu();
        },
      },
      {
        name: 'Update Availability',
        callback: () => {
          navigate(
            `/caregivers/${caregiver.caregiver.id}/availability/current`,
          );
          handleCloseMenu();
        },
      },
      {
        name: 'Time-Off Request',
        callback: () => {
          navigate(
            `/caregivers/${caregiver.caregiver.id}/pto-callouts/current`,
          );
          handleCloseMenu();
        },
      },
      {
        name: 'One Time Availability',
        disabled: Object?.keys(isDateHoliday).length > 0,
        callback: () => {
          addOneTimeAvailability();
          handleCloseMenu();
        },
      },
    ],
    [
      isPrevDateSelected,
      isDateHoliday,
      addCallOut,
      setChosenCaregiver,
      caregiver.caregiver,
      handleCloseMenu,
      navigate,
      addOneTimeAvailability,
    ],
  );

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <IconButton
          disableRipple
          onClick={handleOpenUserMenu}
          sx={{ padding: 0 }}
        >
          {open ? (
            <img src={burgerMenuActive} alt="burger" />
          ) : (
            <img src={burgerMenu} alt="burger" />
          )}
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: -20,
          }}
          id="table-row-appbar"
          slotProps={{
            paper: {
              sx: customPaperStyles,
            },
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleCloseMenu}
        >
          {menuItems?.map(({ callback, name, disabled }) => (
            <MenuItem
              key={name}
              onClick={callback}
              disabled={disabled}
              sx={customSelectItem}
            >
              {name}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </Stack>
  );
}

ManagementActions.propTypes = {
  addOneTimeAvailability: func,
  caregiver: instanceOf(Object),
  isDateHoliday: instanceOf(Object),
  selectedDate: instanceOf(Object),
};
