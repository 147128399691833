import { Box } from '@mui/material';
import { bool, string } from 'prop-types';
import React from 'react';
import { COLORS } from '../../shared/styles/Theme';

export default function CounterBadge({ label = '', hideIfNothing = false }) {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        borderRadius: '100px',
        backgroundColor:
          label === '0' && hideIfNothing ? COLORS.blue[50] : COLORS.green[500],
        color:
          label === '0' && hideIfNothing
            ? COLORS.blue[500]
            : '#ffffff !important',
        fontWeight: 600,
        fontSize: '12px',
        justifyContent: 'center',
        height: '20px',
        lineHeight: '14px',
        letterSpacing: '0.06em',
        minWidth: '20px',
        padding: '6px',
        cursor: 'default',
      }}
    >
      {label}
    </Box>
  );
}

CounterBadge.propTypes = {
  label: string,
  hideIfNothing: bool,
};
