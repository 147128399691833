import { Box } from '@mui/material';
import { CaregiverProfileContext } from '../../../../../pages/CaregiverDetails/context';
import React, { useContext } from 'react';
import CustomSelect from '../../../../../components/Select';
import TeamEffectiveDateModal from '../TeamEffectiveDateModal';
import { additionalSelectStyles } from '../styles';

export default function PrimaryRegionsSelector() {
  const {
    caregiverProfile,
    editingProfileDataLibrary,
    id,
    profileData,
    setCaregiverProfile,
    setTeamDate,
    setTeamEffectiveDateOpen,
    teamDate,
    teamEffectiveDateOpen,
  } = useContext(CaregiverProfileContext);
  const onSelectChange = (params) => {
    const selectedTeams = editingProfileDataLibrary?.teams?.filter((region) =>
      params.value.includes(region?.name),
    );
    setTeamEffectiveDateOpen({ isPrimary: true, isOpen: true });
    setCaregiverProfile({
      ...caregiverProfile,
      [params.field]: selectedTeams,
    });
  };
  return (
    <Box sx={{ maxWidth: '250px', minWidth: '250px' }}>
      <CustomSelect
        addStyles={additionalSelectStyles}
        id="primaryTeams"
        placeHolder="Primary teams"
        options={editingProfileDataLibrary?.teams?.map(
          (option) => option?.name,
        )}
        isLoading={false}
        selectedValue={caregiverProfile?.primaryTeams?.[0]?.name || ''}
        setValue={onSelectChange}
      />
      <TeamEffectiveDateModal
        closeEffectiveDateDialog={() =>
          setTeamEffectiveDateOpen({
            isPrimary: true,
            isOpen: false,
          })
        }
        openEffectiveDateDialog={
          teamEffectiveDateOpen.isOpen && profileData?.hasAvailability
        }
        submitEffectiveDateDialog={() => {}}
        isPrimary={teamEffectiveDateOpen.isPrimary}
        date={teamDate}
        selectDate={setTeamDate}
        primaryTeams={caregiverProfile.primaryTeams}
        caregiverId={id}
      />
    </Box>
  );
}
