import { COLORS } from '../../../../../../shared/styles/Theme';

const customSelectedValueStyles = {
  display: 'flex',
  maxWidth: '88%',
  overflow: 'hidden',
  alignItems: 'end',
  color: COLORS.blue[900],
};

const customLabelStyles = {
  display: 'flex',
  position: 'absolute',
  top: 10,
  left: '-1px',
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '19px',
  letterSpacing: '0em',
  color: COLORS.blue[500],
  transition: 'top 0.3s ease, fontSize 1s ease',
};

const customSelectStyles = {
  minWidth: '100px',
  width: '100%',
  flex: '1 0 0',
  fontSize: '15px',
  minHeight: '40px',
  color: `${COLORS.blue[500]}`,
  borderRadius: '8px',
  '&.MuiSvgIcon-root': {
    fill: `${COLORS.blue[300]}`,
    width: '20px',
    height: '20px',
    top: 11,
  },
  '&.MuiOutlinedInput-root': {
    border: 'none',
    backgroundColor: COLORS.blue[50],
    '.MuiOutlinedInput-input': {
      height: '100%',
      padding: '0 16px',
    },
    fieldset: {
      border: 'none',
    },
  },
  '&.MuiOutlinedInput-root.has-selected-items': {
    color: COLORS.blue[900],
  },
  '& [aria-expanded=true] .animated-placeholder, & .animated-placeholder.has-selected-items':
    {
      top: 2,
      fontSize: '11px',
      transition: 'top 0.3s ease, fontSize 1s ease',
    },
};

const customSelectItem = {
  display: 'flex',
  fontSize: '15px',
  color: `${COLORS.blue[900]}`,
  backgroundColor: '#ffffff',
  '&.select-all-item': {
    borderBottom: `1px solid ${COLORS.blue[200]}`,
    paddingBottom: '8px',
  },
  '&:hover, &:active, &:focus ': {
    backgroundColor: '#ffffff',
  },
  '&:hover': {
    backgroundColor: `${COLORS.blue[50]}`,
  },
  '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:active,  &.Mui-selected:focus':
    {
      backgroundColor: '#ffffff',
    },
};

export {
  customSelectStyles,
  customSelectItem,
  customLabelStyles,
  customSelectedValueStyles,
};
