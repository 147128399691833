import { Box, Stack, Typography } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import noDataIcon from '../../../../../../img/icons/no-data.svg';
import { bool, func, instanceOf } from 'prop-types';
import React, { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  AffectedVisitsAccordion,
  DeletedShadowVisitsAccordion,
} from '../../../../../../components/AffectedVisitsAccordion';
import FullWidthDialog from '../../../../../../components/FullWidthDialog';
import CustomTable from '../../../../../../components/Table';
import { renderRescheduleAlert, reschedulingVisitColumns } from './constants';

function SchedulingUpdatesTable({
  actualVisit = {},
  open = false,
  cancelCallback = () => {},
  submitAvailability = () => {},
}) {
  const apiRef = useGridApiRef();
  const getRowId = useCallback((row) => `${row.visitType} - ${uuidv4()}`, []);
  if (!actualVisit) {
    return false;
  }
  const getNoData = (isShadow) => (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <img src={noDataIcon} alt="" />
      {isShadow ? (
        <Typography>Have no deleted shadow visits</Typography>
      ) : (
        <Typography>Have no re-scheduled visits on this day</Typography>
      )}
    </Stack>
  );
  const dates =
    actualVisit && actualVisit.rescheduledEvents
      ? Object.keys(actualVisit.rescheduledEvents)
      : [];
  return (
    <Box>
      <FullWidthDialog
        cancelButtonName="cancel"
        cancelCallback={cancelCallback}
        backButtonCallback={cancelCallback}
        submitButtonName="confirm Updates"
        submitCallback={submitAvailability}
        title="Scheduling Updates"
        openDialog={open}
      >
        <Box sx={{ padding: '30px' }}>
          {renderRescheduleAlert(
            Object.values(actualVisit.rescheduledEvents).flat(),
          )}
          <Typography sx={{ mb: '20px' }} variant="h4">
            {Object.keys(actualVisit.rescheduledEvents).length === 0
              ? ''
              : 'Affected Visits'}
          </Typography>
          {dates?.map((date) => (
            <AffectedVisitsAccordion
              initialExpanded={!!actualVisit?.rescheduledEvents[date].length}
              key={actualVisit.rescheduledEvents[date]?.id}
              hasWarning={actualVisit.rescheduledEvents[date].some(
                (visit) => visit?.status?.status === 'warning',
              )}
              hasError={actualVisit.rescheduledEvents[date].some(
                (visit) => visit?.status?.status === 'error',
              )}
              tasksCount={actualVisit.rescheduledEvents[date].length}
              title={date}
            >
              <CustomTable
                apiRef={apiRef}
                columns={reschedulingVisitColumns}
                getRowId={getRowId}
                headerHeight={44}
                noRowsOverlay={getNoData}
                rowHeight={107}
                rows={actualVisit?.rescheduledEvents[date]}
                withoutPagination
              />
            </AffectedVisitsAccordion>
          ))}
          <DeletedShadowVisitsAccordion
            itemsCount={actualVisit?.nonShadowVisitsAnyMore?.length}
            title="Shadow Visits to Be Deleted"
          >
            <CustomTable
              apiRef={apiRef}
              columns={reschedulingVisitColumns}
              getRowId={getRowId}
              headerHeight={44}
              noRowsOverlay={() => getNoData(true)}
              rowHeight={107}
              rows={actualVisit?.nonShadowVisitsAnyMore}
              withoutPagination
            />
          </DeletedShadowVisitsAccordion>
        </Box>
      </FullWidthDialog>
    </Box>
  );
}

SchedulingUpdatesTable.propTypes = {
  actualVisit: instanceOf(Object),
  open: bool,
  cancelCallback: func,
  submitAvailability: func,
};

export default React.memo(SchedulingUpdatesTable);
