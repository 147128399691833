import { Box, List, ListItem, Typography } from '@mui/material';
import StarIcon from '../../../../../img/icons/star-icon.svg';
import { instanceOf } from 'prop-types';
import React, { useMemo } from 'react';
import CounterBadge from '../../../../../components/CounterBadge';
import CustomTooltip from '../../../../../components/CustomTooltip';

export default function CaregiverTeams({
  primaryTeams = [],
  secondaryTeams = [],
}) {
  const teamsList = useMemo(() => {
    const primaryList = primaryTeams?.map((data) => ({
      ...data,
      primary: true,
    }));
    if (primaryList.length) {
      return {
        mainTeam: primaryList[0],
        hiddenTeams: [...primaryList.slice(1), ...secondaryTeams],
      };
    }
    if (!primaryList.length && secondaryTeams.length) {
      return {
        mainTeam: secondaryTeams[0],
        hiddenTeams: [...secondaryTeams.slice(1)],
      };
    }
    return null;
  }, [primaryTeams, secondaryTeams]);

  return teamsList ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '6px',
      }}
    >
      <Box sx={{ display: 'flex', flexWrap: 'no-wrap' }}>
        <Typography noWrap sx={{ mr: '4px' }}>
          {teamsList?.mainTeam?.name}
        </Typography>
        <img src={StarIcon} alt="" />
      </Box>
      {teamsList.hiddenTeams.length ? (
        <Box sx={{ width: 'fit-content' }}>
          <CustomTooltip
            placement="right-start"
            title={
              <Box sx={{ padding: '6px' }}>
                <List>
                  {teamsList.hiddenTeams.map(({ id, name, primary }) => (
                    <ListItem key={id} sx={{ padding: 0 }}>
                      <Typography sx={{ mr: '4px', fontSize: '12px' }} noWrap>
                        {name}
                      </Typography>
                      {primary && <img src={StarIcon} alt="" />}
                    </ListItem>
                  ))}
                </List>
              </Box>
            }
          >
            <div>
              <CounterBadge
                label={`+${teamsList.hiddenTeams.length.toString()}`}
              />
            </div>
          </CustomTooltip>
        </Box>
      ) : (
        ''
      )}
    </Box>
  ) : (
    '-'
  );
}

CaregiverTeams.propTypes = {
  primaryTeams: instanceOf(Array),
  secondaryTeams: instanceOf(Array),
};
