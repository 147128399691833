import { Box, Stack } from '@mui/material';
import moment from 'moment';
import { func, instanceOf } from 'prop-types';
import React from 'react';
import {
  FULL_DATE_H24_STANDARD_FORMAT,
  H12_TIME_FORMAT,
} from '../../../../../../../../shared/constants';
import { CustomDatePicker } from '../../../../../../../../components/CustomDateAndTimePickers';
import CustomTimeSelect from '../../../../../../../../components/CustomDateAndTimePickers/CustomTimeSelect';

export default function ChooseDatesRows({
  changeRequestDate = () => {},
  changeRequestTime = () => {},
  requestData = {},
}) {
  return (
    <Stack>
      <Box sx={{ display: 'flex', gap: '20px', mt: '-8px', mb: '24px' }}>
        <CustomDatePicker
          changeDate={(newValue) =>
            changeRequestDate({
              requestDateStart: newValue,
              requestDateEnd: requestData?.requestDateEnd,
            })
          }
          date={requestData?.requestDateStart}
          isDisablePast
          label="Time-Off Date Start"
        />
        <CustomTimeSelect
          disabled={requestData?.allDay}
          onSelectTimeValue={(value) =>
            changeRequestTime({
              requestTimeStart: moment(value, H12_TIME_FORMAT),
              requestTimeEnd: requestData.requestTimeEnd,
            })
          }
          placeHolder="Time-Off Time Start"
          selectedTime={
            requestData?.requestTimeStart?.isValid()
              ? requestData?.requestTimeStart.format(H12_TIME_FORMAT)
              : ''
          }
        />
      </Box>
      <Box sx={{ display: 'flex', gap: '20px' }}>
        <CustomDatePicker
          changeDate={(newValue) =>
            changeRequestDate({
              requestDateStart: requestData?.requestDateStart,
              requestDateEnd: newValue,
            })
          }
          date={
            requestData?.requestDateEnd
              ? moment(
                  requestData?.requestDateEnd,
                  FULL_DATE_H24_STANDARD_FORMAT,
                )
              : null
          }
          isDisablePast
          label="Time-Off Date End"
        />
        <CustomTimeSelect
          disabled={requestData?.allDay}
          onSelectTimeValue={(value) =>
            changeRequestTime({
              requestTimeStart: requestData.requestTimeStart,
              requestTimeEnd: moment(value, H12_TIME_FORMAT),
            })
          }
          placeHolder="Time-Off Time End"
          selectedTime={
            requestData?.requestTimeEnd?.isValid()
              ? requestData?.requestTimeEnd.format(H12_TIME_FORMAT)
              : ''
          }
        />
      </Box>
    </Stack>
  );
}

ChooseDatesRows.propTypes = {
  changeRequestDate: func,
  changeRequestTime: func,
  requestData: instanceOf(Object),
};
