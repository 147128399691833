import { Box } from '@mui/material';
import {
  useGetHolidaysQuery,
  useGetWeekendsSchedulingDaysQuery,
} from '../../../../../../../../api/Administration/api';
import moment from 'moment';
import { getBlockedReason } from '../../../../../../../../pages/ClientDetails/views/Calendar/components/CreateVisitProvider/CreateOneTimeVisitMultistep/constants';
import { func, instanceOf } from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import {
  CALIFORNIA_TIME_ZONE,
  DAYS_MAP,
  ISO_DATE_ONLY_FORMAT,
  MAX_SELECTED_DATES,
} from '../../../../../../../../shared/constants';
import AlertContext from '../../../../../../../../components/Alert';
import CustomTooltip from '../../../../../../../../components/CustomTooltip';
import { calendarElementStyles, datesWrapper, daysWrapper } from './styles';
import { getClassNames, getTooltipTitle } from './utils';

export default function DatesCalendar({
  handleSelectDate = () => {},
  selectedDates = [],
}) {
  const { setAlert } = useContext(AlertContext);
  const { data: holidays, error: getHolidaysError } = useGetHolidaysQuery();
  const { data: disabledWeekDays, error: getWeekdaysError } =
    useGetWeekendsSchedulingDaysQuery();
  useEffect(() => {
    if (getHolidaysError || getWeekdaysError) {
      const error = getWeekdaysError || getHolidaysError;
      setAlert({
        message: error?.data?.message || 'Something went wrong',
        stacktrace: error?.data?.stacktrace || 'Something went wrong',
        type: 'error',
      });
    }
  }, [getHolidaysError, getWeekdaysError, setAlert]);
  const listOfDates = useMemo(() => {
    const startOfTheWeek = moment().startOf('isoWeek');
    const maxDate = startOfTheWeek.clone().add(13, 'days');
    const holidaysList = holidays?.map((holiday) => holiday?.holidayDate);

    const dates = [];
    for (
      let startDate = startOfTheWeek.clone();
      startDate.isSameOrBefore(maxDate, 'days');
      startDate.add(1, 'days')
    ) {
      const isSunday = startDate.day() === 0;
      const isSaturday = startDate.day() === 6;
      dates.push({
        date: startDate.clone(),
        disabled:
          startDate.isBefore(moment().tz(CALIFORNIA_TIME_ZONE), 'days') ||
          holidaysList?.includes(startDate.format(ISO_DATE_ONLY_FORMAT)) ||
          (isSaturday && !disabledWeekDays?.schema?.saturdaySwitchedOn) ||
          (isSunday && !disabledWeekDays?.schema?.sundaySwitchedOn),
        isToday: startDate.isSame(moment(), 'days'),
        name: startDate.format('MMM D'),
        reason: getBlockedReason({
          pastDate: startDate.isBefore(
            moment().tz(CALIFORNIA_TIME_ZONE),
            'days',
          ),
          holiday: holidaysList?.includes(
            startDate.format(ISO_DATE_ONLY_FORMAT),
          ),
          turnOfDay:
            (isSaturday && disabledWeekDays?.schema?.saturdaySwitchedOn) ||
            (isSunday && disabledWeekDays?.schema?.sundaySwitchedOn),
        }),
      });
    }
    return dates;
  }, [disabledWeekDays, holidays]);

  const onSelectDate = (date) => {
    if (
      selectedDates.some((selectedDate) => date.isSame(selectedDate, 'day'))
    ) {
      handleSelectDate(
        selectedDates.filter(
          (selectedDate) => !date.isSame(selectedDate, 'day'),
        ),
      );
      return '';
    }
    if (selectedDates.length < MAX_SELECTED_DATES) {
      handleSelectDate([...selectedDates, date]);
      return '';
    }
    return '';
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={daysWrapper}>
        {DAYS_MAP.map(({ id, isWeekend, shortName }) => (
          <Box
            key={id}
            sx={calendarElementStyles.days}
            className={isWeekend ? 'weekend-day' : ''}
          >
            {shortName}
          </Box>
        ))}
      </Box>
      <Box sx={datesWrapper}>
        {listOfDates.map(({ date, disabled, isToday, name, reason }) => (
          <CustomTooltip
            key={date}
            title={disabled ? reason : getTooltipTitle({ disabled, isToday })}
            placement="bottom-end"
          >
            <Box
              key={name}
              sx={calendarElementStyles.dates}
              className={getClassNames({
                disabled,
                isToday,
                selected: selectedDates.some(
                  (selectedDate) => date === selectedDate,
                ),
              })}
              onClick={() => (disabled ? {} : onSelectDate(date))}
            >
              {name}
            </Box>
          </CustomTooltip>
        ))}
      </Box>
    </Box>
  );
}

DatesCalendar.propTypes = {
  handleSelectDate: func,
  selectedDates: instanceOf(Array),
};
