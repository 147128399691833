import { COLORS } from '../../shared/styles/Theme';

const customDateTimePickerStyles = [
  {
    width: '100%',
    flex: '1 0 0',
    height: '40px',
    '&.disabled-time-picker': {
      '& .MuiInputLabel-root': {
        color: COLORS.black[200],
      },
      '& .MuiFilledInput-root': {
        color: COLORS.black[200],
        backgroundColor: COLORS.blue[50],
        '&:hover, &.Mui-focused': {
          backgroundColor: COLORS.blue[50],
        },
        '& svg': {
          fill: COLORS.black[200],
        },
      },
    },
    '&.invalid-time-value': {
      '& .MuiFilledInput-root': {
        color: COLORS.black[200],
      },
    },
    '& .MuiInputLabel-root': {
      top: '-5px',
      left: '3px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '19px',
      letterSpacing: '0em',
      color: COLORS.blue[500],
      '&.Mui-error': {
        color: COLORS.blue[500],
      },
      '&.Mui-focused': {
        color: COLORS.blue[500],
      },
      '&[data-shrink=true]': {
        top: '-3px',
      },
    },
    '& .MuiFilledInput-root': {
      border: 'none',
      minWidth: '100px',
      height: '100%',
      borderRadius: '10px',
      fontSize: '14px',
      color: COLORS.blue[900],
      backgroundColor: COLORS.blue[50],
      alignItems: 'center',
      padding: '0 16px',
      '&.Mui-disabled': {
        backgroundColor: COLORS.blue[50],
      },
      '&:before, &:after': {
        display: 'none',
      },
      '&:hover, &.Mui-focused': {
        backgroundColor: COLORS.blue[50],
      },
      '& fieldset': {
        border: 'none',
      },
      '& svg': {
        fill: COLORS.blue[300],
        fontSize: '17px',
      },
      '& .MuiFilledInput-input': {
        transition: 'opacity 0.3s linear 0.06s',
        padding: 0,
        opacity: 1,
        mt: '14px',
      },
    },
    '&.empty-input .MuiFilledInput-root .MuiFilledInput-input': {
      opacity: 0,
    },
    '&.disabled-picker': {
      '& .MuiInputLabel-root': {
        color: COLORS.black[200],
      },
    },
  },
];

export { customDateTimePickerStyles };
