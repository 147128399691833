import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { bool, instanceOf, string } from 'prop-types';
import React from 'react';
import { SHORT_DATE_FORMAT } from '../../../../../../../shared/constants';
import styles from './style.module.css';

function CarePlanHistoryCommon({
  availabilityUpdated = false,
  availabilityEffectiveDate = '',
  sortedGroupedDays = {},
}) {
  const effectiveDate = moment(availabilityEffectiveDate).format(
    SHORT_DATE_FORMAT,
  );
  return (
    <Box sx={{ margin: '10px 0' }}>
      {!availabilityUpdated ? (
        <>
          <Typography className={styles.addedType}>
            Availability Added with Effective Date: {effectiveDate}
          </Typography>
          <ul className={styles.bulletList}>
            {Object.entries(sortedGroupedDays).map(([day, times]) => (
              <li key={day} className={styles.bulletListItem}>
                <span className={styles.boldWeight}>{day}:</span>
                <span>
                  from
                  <span className={styles.newTime}>
                    {times?.newStartTime?.to}
                  </span>
                  to
                  <span className={styles.newTime}>
                    {times?.newEndTime?.to}
                  </span>
                </span>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <>
          <Typography className={styles.updatedType}>
            Availability Changed with Effective Date: {effectiveDate}
          </Typography>
          <ul className={styles.bulletList}>
            {Object.entries(sortedGroupedDays).map(([day, times]) => (
              <Box>
                {times.oldStartTime ? (
                  <Box>
                    {times?.oldStartTime?.from === times.newStartTime.to &&
                    times?.oldEndTime?.from === times.newEndTime.to ? (
                      <li key={day} className={styles.bulletListItem}>
                        <span className={styles.boldWeight}>{day}:</span>
                        <span key={day}>N/A</span>
                      </li>
                    ) : (
                      <li key={day} className={styles.bulletListItem}>
                        <span className={styles.boldWeight}>{day}:</span>
                        <span>
                          was from
                          <span className={styles.oldTime}>
                            {times.oldStartTime?.from}
                          </span>
                          to
                          <span className={styles.oldTime}>
                            {times.oldEndTime?.from}
                          </span>
                          , will be from
                          <span className={styles.newTime}>
                            {times.newStartTime?.to}
                          </span>
                          to
                          <span className={styles.newTime}>
                            {times.newEndTime?.to}
                          </span>
                        </span>
                      </li>
                    )}
                  </Box>
                ) : (
                  <li key={day} className={styles.bulletListItem}>
                    <span className={styles.boldWeight}>{day}:</span>
                    <span>
                      from
                      <span className={styles.newTime}>
                        {times?.newStartTime?.to}
                      </span>
                      to
                      <span className={styles.newTime}>
                        {times?.newEndTime?.to}
                      </span>
                    </span>
                  </li>
                )}
              </Box>
            ))}
          </ul>
        </>
      )}
    </Box>
  );
}

CarePlanHistoryCommon.propTypes = {
  availabilityUpdated: bool,
  availabilityEffectiveDate: string,
  sortedGroupedDays: instanceOf(Object),
};

export default CarePlanHistoryCommon;
