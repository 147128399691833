import {
  useGetSchedulerQuery,
  useReloadSchedulerDataMutation,
} from '../../api/Scheduler/api';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  DATE_ONLY_STANDARD_FORMAT,
  ISO_DATE_ONLY_FORMAT,
} from '../../shared/constants';
import AlertContext from '../../components/Alert';
import Loader from '../../components/Loader';
import VisitManagementProvider from '../../components/VisitInfoTypeProvider';
import Scheduling from './Scheduling';
import ScheduleCaregiverManagementProvider from './components/ScheduleCaregiverManagement';
import { LOCAL_STORAGE_KEYS } from './constants';

export default function SchedulingPage() {
  const { setAlert } = useContext(AlertContext);
  const today = moment();
  const todayDate = today.format(ISO_DATE_ONLY_FORMAT);
  const storedDate = localStorage.getItem(LOCAL_STORAGE_KEYS.currentDate)
    ? new Date(localStorage.getItem(LOCAL_STORAGE_KEYS.currentDate))
    : null;
  const [currentDate, setCurrentDate] = useState(storedDate);
  const date = useMemo(
    () =>
      currentDate
        ? moment(currentDate).format(ISO_DATE_ONLY_FORMAT)
        : todayDate,
    [currentDate, todayDate],
  );
  const {
    data: schedulerEvents,
    isLoading: schedulerEventsLoading,
    isFetching,
    refetch: reFetchCalendarEvents,
    error,
  } = useGetSchedulerQuery(date, {
    refetchOnMountOrArgChange: true,
    enabled: !!currentDate,
  });
  const [
    reloadSchedulerData,
    { isLoading: refreshSchedulingPending, error: refreshSchedulingError },
  ] = useReloadSchedulerDataMutation();

  const reloadSchedulingForDate = () => {
    const data = {
      fromDate: moment(date).format(ISO_DATE_ONLY_FORMAT),
      toDate: moment(date).format(ISO_DATE_ONLY_FORMAT),
    };
    reloadSchedulerData(data)
      .unwrap()
      .then(() => window.location.reload());
  };

  useEffect(() => {
    const errorData = error || refreshSchedulingError;
    if (errorData) {
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [error, refreshSchedulingError, setAlert]);

  const eventsLoading = useMemo(
    () => schedulerEventsLoading || isFetching || refreshSchedulingPending,
    [refreshSchedulingPending, schedulerEventsLoading, isFetching],
  );

  const changeCurrentDate = useCallback((newDate) => {
    setCurrentDate(newDate);
    localStorage.setItem(LOCAL_STORAGE_KEYS.currentDate, newDate);
  }, []);
  return (
    <VisitManagementProvider reFetchCalendarEvents={reFetchCalendarEvents}>
      <ScheduleCaregiverManagementProvider
        calloutDate={moment(date).format(DATE_ONLY_STANDARD_FORMAT)}
      >
        {eventsLoading && <Loader />}
        {schedulerEvents && (
          <Scheduling
            currentDate={currentDate}
            eventsLoading={eventsLoading}
            reloadSchedulingForDate={reloadSchedulingForDate}
            schedulerEvents={schedulerEvents}
            setCurrentDate={changeCurrentDate}
            today={today}
            todayDate={todayDate}
            reFetchCalendarEvents={reFetchCalendarEvents}
          />
        )}
      </ScheduleCaregiverManagementProvider>
    </VisitManagementProvider>
  );
}
