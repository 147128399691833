import { COLORS } from '../../../../../shared/styles/Theme';

export const customFilterStyles = {
  width: '120px',
};
export const filterStyles = {
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  mr: '18px',
  width: '70%',
  padding: '0 10px',
};

export const visitConstraintsIconsWrapper = {
  width: 'fit-content',
  display: 'flex',
  gap: '4px',
};

export const visitCaregiversWrapper = {
  display: 'flex',
  flexDirection: 'column',
};

export const visitCaregiverInfo = {
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  '& img': { width: '14px', height: '14px' },
};

export const caregiverNameStyles = {
  color: COLORS.blue[700],
  fontWeight: '600',
  fontSize: '14px',
};
