import { EventType, PublicClientApplication } from '@azure/msal-browser';

export const config = {
  msal: {
    auth: {
      clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
      authority:
        'https://login.microsoftonline.com/c700a9d7-79ee-4c8e-82f5-3f73a86e5372',
      redirectUri: `${process.env.REACT_APP_BASE_URL}auth`,
    },
    cache: {
      cacheLocation: 'localStorage',
    },
  },
};

export const pcaInstance = new PublicClientApplication(config.msal);

pcaInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    localStorage.setItem('accessToken', event.payload.accessToken);
  }
});
