import { COLORS } from '../../shared/styles/Theme';

const customSelectedValueStyles = {
  display: 'flex',
  maxWidth: '88%',
  overflow: 'hidden',
  alignItems: 'end',
  color: COLORS.blue[900],
};

const customLabelStyles = {
  display: 'flex',
  position: 'absolute',
  top: 10,
  left: '-1px',
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '19px',
  letterSpacing: '0em',
  color: COLORS.blue[500],
  transition: 'top 0.3s ease, fontSize 1s ease',
};

const customSelectStyles = {
  minWidth: '100px',
  width: '100%',
  flex: '1 0 0',
  fontSize: '15px',
  minHeight: '40px',
  color: `${COLORS.blue[500]}`,
  borderRadius: '8px',
  '&.MuiSvgIcon-root': {
    fill: `${COLORS.blue[300]}`,
    width: '20px',
    height: '20px',
    top: 11,
  },
  '&.MuiOutlinedInput-root': {
    border: 'none',
    backgroundColor: COLORS.blue[50],
    padding: 0,
    '.MuiOutlinedInput-input': {
      height: '100%',
      padding: '0 16px',
    },
    fieldset: {
      border: 'none',
    },
  },
  '&.MuiOutlinedInput-root.has-selected-items': {
    color: COLORS.blue[900],
  },
  '& [aria-expanded=true] .animated-placeholder, & .animated-placeholder.has-selected-items':
    {
      top: 3,
      fontSize: '11px',
      transition: 'top 0.3s ease, fontSize 0.7s ease-out',
    },
};

const customSelectItem = {
  display: 'flex',
  fontSize: '15px',
  margin: '0 8px',
  color: `${COLORS.blue[900]}`,
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  '&.select-all-item': {
    paddingBottom: 0,
    '&.MuiMenuItem-root:hover': {
      backgroundColor: '#ffffff',
    },
  },
  '&:focus ': {
    backgroundColor: '#ffffff',
  },
  '&:hover': {
    backgroundColor: `${COLORS.blue[50]}`,
  },
  '&.Mui-selected, &.Mui-selected:active,  &.Mui-selected:focus': {
    backgroundColor: '#ffffff',
  },
  '&.Mui-selected:hover': {
    backgroundColor: `${COLORS.blue[50]}`,
    cursor: 'default',
  },
};

const customPaperStyles = {
  mt: '1px',
  padding: '8px 0',
  boxShadow: '0px 4px 12px 2px rgba(180, 180, 180, 0.2)',
  borderRadius: '10px',
  maxHeight: '300px',
};

const requiredLabelAsterisk = {
  '& .animated-placeholder:after': {
    content: '"*"',
    color: COLORS.red[600],
    fontSize: '11px',
    position: 'absolute',
    top: '-3px',
    right: '-6px',
  },
  '& [aria-expanded=true] .animated-placeholder:after, animated-placeholder.has-selected-items:after':
    {
      top: '-3px',
      right: '-6px',
    },
};

export {
  customPaperStyles,
  customSelectStyles,
  customSelectItem,
  customLabelStyles,
  customSelectedValueStyles,
  requiredLabelAsterisk,
};
