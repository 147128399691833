import { Box, ListItem, Typography } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import WarningIcon from '../../../../../../../img/icons/warning-icon.svg';
import { bool, func, instanceOf } from 'prop-types';
import React from 'react';
import { SHORT_DATE_FORMAT } from '../../../../../../../shared/constants';
import CustomDialog from '../../../../../../../components/Dialog';
import { listItemsStyle } from './style';

function DeactivationConfirmationDialog({
  openDeactivationModal = false,
  closeDeactivationModal = () => {},
  confirmDeactivation = () => {},
  date = {},
}) {
  const INFO_ITEMS = [
    {
      id: 'Time-off Requests',
      text: (
        <Box sx={listItemsStyle}>
          <img src={WarningIcon} alt="warning" />
          <span>
            All future <b>PTOs & Callouts</b> will be deleted
          </span>
        </Box>
      ),
    },
    {
      id: 'Availability',
      text: (
        <Box sx={listItemsStyle}>
          <img src={WarningIcon} alt="warning" />
          <span>
            The <b>Availability</b> will be set to empty availability starting
            the same effective date
          </span>
        </Box>
      ),
    },
    {
      id: 'action',
      text: (
        <Box sx={listItemsStyle}>
          <img src={WarningIcon} alt="warning" />
          <span>This action cannot be undone</span>
        </Box>
      ),
    },
  ];
  return (
    <CustomDialog
      cancelButtonName="Cancel"
      cancelCallback={closeDeactivationModal}
      submitButtonName="yes,I understand"
      submitCallback={confirmDeactivation}
      title="Confirmation"
      openDialog={openDeactivationModal}
    >
      <Box sx={{ padding: '0 28px 20px 28px', maxWidth: '100%' }}>
        <Typography sx={{ mb: '16px' }}>
          You are about to deactivate the client starting{' '}
          {date?.format(SHORT_DATE_FORMAT)}:
        </Typography>
        {INFO_ITEMS?.map((item) => (
          <ListItem key={item.id} sx={{ padding: 0 }}>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
        <Typography sx={{ mt: '10px' }}>
          Do you want to confirm the action?
        </Typography>
      </Box>
    </CustomDialog>
  );
}
DeactivationConfirmationDialog.propTypes = {
  openDeactivationModal: bool,
  closeDeactivationModal: func,
  confirmDeactivation: func,
  date: instanceOf(Object),
};

export default DeactivationConfirmationDialog;
