import { Box } from '@mui/material';
import { CaregiverProfileContext } from '../../../../../pages/CaregiverDetails/context';
import React, { useContext } from 'react';
import CustomSelect from '../../../../../components/Select';

export default function SkillsSelector() {
  const { caregiverProfile, editingProfileDataLibrary, setCaregiverProfile } =
    useContext(CaregiverProfileContext);

  const onSelectChange = (params) => {
    setCaregiverProfile({
      ...caregiverProfile,
      [params.field]: params.value,
    });
  };
  const skills = editingProfileDataLibrary?.skills?.skills?.map(
    (option) => option.name,
  );
  return (
    <Box sx={{ maxWidth: '250px', minWidth: '250px' }}>
      <CustomSelect
        id="skills"
        skillLang
        options={skills}
        isLoading={false}
        isMultiselect
        selectedValue={caregiverProfile.skills}
        setValue={onSelectChange}
      />
    </Box>
  );
}
