import { COLORS } from '../../../../shared/styles/Theme';

export const visitInfoManagementWrapper = {
  display: 'flex',
  gap: '24px',
  mt: '24px',
};

export const visitInfoManagementColumnStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
};

export const customInputsStyles = {
  '&.MuiTextField-root': {
    width: '312px',
    maxWidth: '312px',
    height: '40px',
    flex: 'inherit',
  },
};

export const visitDetailsCustomSelectStyles = {
  width: '312px',
  maxWidth: '312px',
  '&.MuiOutlinedInput-root': {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: COLORS.blue[50],
    flex: 0,
    '&.Mui-focused, &:hover': {
      backgroundColor: COLORS.blue[50],
    },
    padding: 0,
    '.MuiOutlinedInput-input': {
      height: '100%',
      padding: '0 16px',
    },
    fieldset: {
      border: 'none',
    },
  },
  '&.MuiOutlinedInput-root.has-selected-items': {
    color: COLORS.blue[900],
  },
  '& [aria-expanded=true] .animated-placeholder, & .animated-placeholder.has-selected-items':
    {
      top: 3,
      fontSize: '11px',
      transition: 'top 0.3s ease, fontSize 0.7s ease-out',
    },
};

export const columnTitle = {
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '16px',
  letterSpacing: '0em',
  mb: '-8px',
};
