import { autocompleteClasses } from '@mui/material/Autocomplete';
import { COLORS } from '../../../../shared/styles/Theme';

export const autocompleteStyles = {
  width: '100%',
  padding: 0,
  [`& .${autocompleteClasses.popupIndicator}`]: {
    transform: 'none',
  },
  '& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected="true"].Mui-focused':
    {
      backgroundColor: COLORS.blue[50],
    },
  '& .MuiAutocomplete-endAdornment': {
    top: 'calc(50% - 12px)',
  },
};

export const autocompletePaperStyles = {
  padding: '18px 0',
  boxShadow: '0px 4px 12px 2px rgba(180, 180, 180, 0.2)',
  borderRadius: '10px',
  '& .MuiAutocomplete-listbox': {
    maxHeight: '260px',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    "& .MuiAutocomplete-option[aria-selected='true']": {
      backgroundColor: COLORS.blue[50],
    },
  },
};

export const customSelectItem = {
  display: 'flex',
  fontSize: '15px',
  color: `${COLORS.blue[900]}`,
  backgroundColor: '#ffffff',
  '&.select-all-item': {
    borderBottom: `1px solid ${COLORS.blue[200]}`,
    paddingBottom: '8px',
  },
  '&:hover, &:active, &:focus ': {
    backgroundColor: '#ffffff',
  },
  '&:hover': {
    backgroundColor: `${COLORS.blue[50]}`,
  },
  '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:active,  &.Mui-selected:focus':
    {
      backgroundColor: '#ffffff',
    },
};
