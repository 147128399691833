import React from 'react';
import Availability from './view/Availability';
import CaregiversCalendar from './view/Calendar';
import CaregiverActivity from './view/CaregiverActivity';
import CaregiverProfileInfo from './view/Profile';
import PtoAndCallouts from './view/PtoAndCallouts';

export const CAREGIVER_DETAILS_TABS = [
  { title: 'Profile', key: 'profile', child: <CaregiverProfileInfo /> },
  {
    title: 'Availability',
    key: 'availability',
    child: <Availability />,
  },
  {
    title: 'PTO & Callouts',
    key: 'pto-callouts',
    child: <PtoAndCallouts />,
  },
  {
    title: 'Calendar',
    key: 'calendar',
    child: <CaregiversCalendar />,
  },
  {
    title: 'Activity',
    key: 'activity',
    child: <CaregiverActivity />,
  },
];

export const CAREGIVERS_TABS_SUBVIEW_NAMES_MAP = {
  profile: 'current',
  availability: 'current',
  'pto-callouts': 'current',
  calendar: null,
  activity: null,
};
