import errorIcon from '../../img/icons/error-outlined.svg';
import successIcon from '../../img/icons/success-outlined.svg';
import React from 'react';
import { COLORS } from '../../shared/styles/Theme';

export const timeOffRequestPossibilityAlert = {
  success: {
    bgColor: COLORS.green[200],
    color: COLORS.green[600],
    icon: <img src={successIcon} alt="success icon" />,
    getTitle() {
      return 'Reassignment is possible for all days';
    },
    type: 'success',
  },
  error: {
    bgColor: COLORS.red[200],
    color: COLORS.red[600],
    icon: <img src={errorIcon} alt="error icon" />,
    getTitle(number) {
      return `Reassignment impossible for ${number}`;
    },
    type: 'error',
  },
};
