import { Alert } from '@mui/material';
import React from 'react';
import { COLORS } from '../../../../../shared/styles/Theme';

export default function AddTaskAlert() {
  return (
    <Alert
      icon={false}
      severity="warning"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLORS.yellow[200],
        borderRadius: '10px',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '16px',
        color: COLORS.yellow[700],
      }}
    >
      The task already exists. Please check and try once again.
    </Alert>
  );
}
