import { Box } from '@mui/material';
import { instanceOf, string } from 'prop-types';
import React from 'react';
import { H12_TIME_FORMAT } from '../../../../../../../shared/constants';
import CustomTimeSelect from '../../../../../../../components/CustomDateAndTimePickers/CustomTimeSelect';

function TimeRange({ endTime = null, startTime = null, title = '' }) {
  return (
    <Box
      sx={{ display: 'flex', gap: '12px', alignItems: 'center', pr: '32px' }}
    >
      <Box sx={{ display: 'flex', gap: '20px', mb: '8px', width: '100%' }}>
        <CustomTimeSelect
          disabled
          onSelectTimeValue={() => {}}
          placeHolder={`${title} Start`}
          selectedTime={
            startTime?.isValid() ? startTime.format(H12_TIME_FORMAT) : ''
          }
        />
        <CustomTimeSelect
          disabled
          onSelectTimeValue={() => {}}
          placeHolder={`${title} End`}
          selectedTime={
            endTime?.isValid() ? endTime.format(H12_TIME_FORMAT) : ''
          }
        />
      </Box>
    </Box>
  );
}

TimeRange.propTypes = {
  endTime: instanceOf(Object),
  startTime: instanceOf(Object),
  title: string,
};

export default React.memo(TimeRange);
