export const visitsTableStyles = [
  {
    '& .MuiDataGrid-cell': {
      padding: '10px 16px',
    },
    '& .MuiDataGrid-columnHeader:first-of-type .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-virtualScroller': {
      boxSizing: 'border-box',
      maxHeight: 'calc(100vh - 420px)',
      overflowX: 'hidden',
      overflowY: 'auto !important',
      padding: '1px',
      scrollbarColor: '#888 #f1f1f1',
      scrollbarWidth: 'thin',
    },
  },
];
