import { Box, Button } from '@mui/material';
import { bool, func } from 'prop-types';
import React from 'react';
import { continueButtonStyles, rejectButtonStyles } from './styles';

export default function ButtonsGroup({
  checkApprovingPendingRequest = () => {},
  openRejectingDialog = () => {},
  showContinueButton = false,
  showRejectButton = false,
}) {
  return (
    <Box sx={{ display: 'flex', gap: '16px' }}>
      {showRejectButton && (
        <Button
          color="secondary"
          disableElevation
          onClick={openRejectingDialog}
          sx={rejectButtonStyles}
          variant="outlined"
        >
          Cancel
        </Button>
      )}
      {showContinueButton && (
        <Button
          color="secondary"
          disableElevation
          onClick={checkApprovingPendingRequest}
          sx={continueButtonStyles}
          variant="outlined"
        >
          Continue
        </Button>
      )}
    </Box>
  );
}

ButtonsGroup.propTypes = {
  checkApprovingPendingRequest: func,
  openRejectingDialog: func,
  showContinueButton: bool,
  showRejectButton: bool,
};
