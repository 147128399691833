import { Stack, Typography } from '@mui/material';
import noDataIcon from '../../../../../img/icons/no-data.svg';
import React from 'react';

function AvailabilityNoRowsComponent() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <img src={noDataIcon} alt="" />
      <Typography>No availability settings yet</Typography>
    </Stack>
  );
}

export default AvailabilityNoRowsComponent;
