import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
// need to be changed
import { useGetCaregiverHistoryQuery } from '../../../../../../api/Caregivers/api';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { HISTORY_UPDATE_FORMAT } from '../../../../../../shared/constants';
import AlertContext from '../../../../../../components/Alert';
import CustomTable from '../../../../../../components/Table';
import { PAGE_SIZE, PAGE_SIZE_OPTIONS, historyColumns } from '../../constants';

export default function CaregiverAvailabilityHistory() {
  const apiRef = useGridApiRef();
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const { data, isLoading, error } = useGetCaregiverHistoryQuery(id);
  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);

  const updatedEvents = data
    ?.map((event, index) => {
      const changes = JSON.parse(event?.changes);
      const changesArray = [];
      const onlyAvailability = changes
        ?.filter((type) => type.propertyName === 'availabilities')
        ?.map((change) => change.changes)
        ?.flat()
        ?.map((change) => change.changes)
        ?.flat();
      const onlyPrimitives = onlyAvailability?.filter(
        (type) => type.type === 'primitive',
      );
      if (onlyPrimitives) {
        changesArray.push(...onlyPrimitives);
      }

      const onlyTime = onlyAvailability
        ?.map((change) => change.changes)
        ?.flat()
        ?.filter((type) => type?.type === 'object');
      const modifiedData = onlyTime?.map((item) => {
        const changesWithSecondName = item?.changes?.map((change) => ({
          ...change,
          secondName: item.propertyName,
        }));

        return {
          ...item,
          changes: changesWithSecondName,
        };
      });
      const onlyPrimitiveTime = modifiedData
        ?.map((change) => change.changes)
        ?.flat();
      if (onlyPrimitiveTime) {
        changesArray.push(...onlyPrimitiveTime);
      }

      if (changesArray.length === 0) {
        return false;
      }
      const hasNonNoChange = changesArray.some(
        (change) => change.operation !== 'noChange',
      );
      if (!hasNonNoChange) {
        return false;
      }
      return {
        ...event,
        changes: changesArray,
        dateCreated: moment(event?.dateCreated).format(HISTORY_UPDATE_FORMAT),
        id: index + 1,
      };
    })
    .filter(Boolean);
  const sortedUpdatedEvents = updatedEvents?.sort((a, b) => {
    const dateA = new Date(a.dateCreated);
    const dateB = new Date(b.dateCreated);
    return dateB - dateA;
  });
  return (
    <Box>
      <CustomTable
        apiRef={apiRef}
        columns={historyColumns}
        headerHeight={44}
        isLoading={isLoading}
        rows={sortedUpdatedEvents}
        pageSize={PAGE_SIZE}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
      />
    </Box>
  );
}
