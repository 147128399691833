import { Box, Typography } from '@mui/material';
import WarningIconYellow from '../../../../img/icons/warning-icon.svg';
import { bool, instanceOf } from 'prop-types';
import React, { useContext } from 'react';
import CounterBadge from '../../../../components/CounterBadge';
import { ScheduleCaregiverManagementContext } from '../../context';
import ManagementActions from '../Menu';

export default function AdditionalInfoAndStatuses({
  caregiver = {},
  editMode = false,
  isDateHoliday = {},
  selectedDate = {},
}) {
  const { addOneTimeAvailability } = useContext(
    ScheduleCaregiverManagementContext,
  );
  return (
    <Box sx={{ position: 'absolute', right: '10px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {caregiver?.violatedSoftConstraints?.length > 0 && (
          <Box sx={{ display: 'flex', mr: '4px' }}>
            <img src={WarningIconYellow} alt="warningIcon" />
            <Typography>
              {caregiver?.violatedSoftConstraints?.length}
            </Typography>
          </Box>
        )}

        <CounterBadge label={caregiver?.visitCount?.toString()} />
        {!editMode && (
          <ManagementActions
            addOneTimeAvailability={() => addOneTimeAvailability(caregiver)}
            caregiver={caregiver}
            isDateHoliday={isDateHoliday}
            selectedDate={selectedDate}
          />
        )}
      </Box>
    </Box>
  );
}

AdditionalInfoAndStatuses.propTypes = {
  caregiver: instanceOf(Object),
  isDateHoliday: instanceOf(Object),
  selectedDate: instanceOf(Object),
  editMode: bool,
};
