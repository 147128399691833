import { COLORS } from '../../../../../../../../../../shared/styles/Theme';

export const crossedOutCellWrapperStyles = {
  position: 'relative',
  display: 'flex',
  padding: '0 6px',
};

export const crossedOutLineStyles = {
  width: '100%',
  position: 'absolute',
  top: '50%',
  bottom: '50%',
  left: 0,
  color: COLORS.red[500],
  flex: 1,
  borderWidth: '1px',
  borderColor: COLORS.red[500],
  zIndex: 1,
};
