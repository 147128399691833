import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { bool, func, instanceOf, string } from 'prop-types';
import React, { useMemo } from 'react';
import { H12_TIME_FORMAT } from '../../shared/constants';
import { COLORS } from '../../shared/styles/Theme';
import { DeletedShadowVisitsAccordion } from '../../components/AffectedVisitsAccordion';
import Loader from '../../components/Loader';
import CustomBreadcrumbs from './Breadcrumbs';
import ColumnAlert from './ColumnAlert';
import DatesPossibilityAccordion from './DatesPossibilityAccordion';
import PossibilityVisitsTable from './PossibilityVisitsTable';
import { timeOffRequestPossibilityAlert } from './constants';
import { checkPossibilityViewWrapper } from './styles';

function CheckPossibilityView({
  caregiverData = {},
  closeDialog = () => {},
  error = '',
  isLoading = false,
  possibilityData = {},
  requestData = {},
  shadowVisits = [],
}) {
  const requestPeriod = useMemo(() => {
    if (!requestData) {
      return null;
    }
    const requestStart = requestData?.allDay
      ? moment(requestData?.requestDateStart).format('MMM D')
      : moment(
          `${moment(requestData?.requestDateStart).format('MMM D')} ${moment(
            requestData?.requestTimeStart,
          ).format(H12_TIME_FORMAT)}`,
        ).format('MMM D HH:mm');
    const requestEnd = requestData?.allDay
      ? moment(requestData?.requestDateEnd).format('MMM D')
      : moment(
          `${moment(requestData?.requestDateEnd).format('MMM D')} ${moment(
            requestData?.requestTimeEnd,
          ).format(H12_TIME_FORMAT)}`,
        ).format('MMM D HH:mm');
    const year = moment(requestData?.requestDateEnd).format('YYYY');
    return `${requestStart} - ${requestEnd}, ${year}`;
  }, [requestData]);
  const listOfVisits = useMemo(() => {
    if (!possibilityData) {
      return '';
    }
    const dates = Object.keys(possibilityData);
    return dates?.map((date) => ({ visits: possibilityData[date], date }));
  }, [possibilityData]);

  const notReassignedVisits = listOfVisits.filter(({ visits }) =>
    visits.some(({ status }) => status?.status === 'error'),
  ).length;

  if (isLoading) return <Loader />;
  return (
    <Box sx={{ minWidth: '1440px', padding: '20px 32px' }}>
      <Box sx={checkPossibilityViewWrapper}>
        <CustomBreadcrumbs
          closeDialog={closeDialog}
          caregiverData={caregiverData}
        />
        {requestPeriod && (
          <Typography
            variant="body1"
            sx={{ fontWeight: 400, color: COLORS.blue[900] }}
          >{`Dates: ${requestPeriod}`}</Typography>
        )}
      </Box>
      <Box sx={{ padding: '0 28px' }}>
        <ColumnAlert
          alertData={
            timeOffRequestPossibilityAlert[
              notReassignedVisits || error ? 'error' : 'success'
            ]
          }
          errorDays={notReassignedVisits}
          errorMessage={error}
        />
        {listOfVisits?.map(({ date, visits }) => (
          <DatesPossibilityAccordion
            key={date}
            hasWarning={visits.some(
              (visit) => visit?.status?.status === 'warning',
            )}
            hasError={visits.some((visit) => visit?.status?.status === 'error')}
            tasksCount={visits.length}
            title={date}
          >
            <PossibilityVisitsTable dateVisits={visits} />
          </DatesPossibilityAccordion>
        ))}
        <DeletedShadowVisitsAccordion
          itemsCount={shadowVisits.length}
          title="Shadow Visits to Be Deleted"
        >
          <PossibilityVisitsTable dateVisits={shadowVisits} isShadowVisits />
        </DeletedShadowVisitsAccordion>
      </Box>
    </Box>
  );
}

CheckPossibilityView.propTypes = {
  caregiverData: instanceOf(Object),
  closeDialog: func,
  error: string,
  isLoading: bool,
  possibilityData: instanceOf(Object),
  requestData: instanceOf(Object),
  shadowVisits: instanceOf(Object),
};

export default React.memo(CheckPossibilityView);
