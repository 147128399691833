import { Box, Collapse, Typography } from '@mui/material';
import { useGetUserProfileDataQuery } from '../../../api/UserData/api';
import {
  useCheckRestoreCancelledVisitQuery,
  useConfirmRestoreCancelledVisitMutation,
  useRestoreCancelledVisitNonOptimizedPeriodMutation,
} from '../../../api/commonApi/api';
import { bool, func, string } from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import AlertContext from '../../../components/Alert';
import Loader from '../../../components/Loader';
import {
  canRestoreCanceledVisitStatuses,
  onlyCancelAllowsVisitStatuses,
  unVerifyVisitStatuses,
  visitStatuses,
} from '../constants';
import { infoKeyField, infoRowWrapperStyles } from '../styles';
import { getBgColor } from '../utils';
import CheckReschedulingTable from './CheckReschedulingTable';
import StatusChangingConfirmation from './StatusChangingConfirmation';
import VisitStatusSelect from './StatusSelect';

export default function StatusSelectorField({
  cancelNewStatus = () => {},
  changeStatus = () => {},
  closeNewStatusConfirmation = () => {},
  confirmNewStatus = () => {},
  date = '',
  disabledSelect = false,
  initialStatus = '',
  isFutureVisit = false,
  isNotAllocatedVisit = false,
  isUpdatingVisitStatus = false,
  needToConfirmStatus = false,
  reFetchCalendarEvents = () => {},
  scheduledVisit = false,
  temporaryStatus = '',
  visitId = '',
}) {
  const { setAlert } = useContext(AlertContext);
  const [openCheckReschedulingDialog, setOpenCheckReschedulingDialog] =
    useState(false);
  const { data: userProfile, error: userProfileError } =
    useGetUserProfileDataQuery();

  const {
    data: reschedulingData,
    isLoading: isLoadingReschedulingData,
    isFetching: isFetchingReschedulingData,
    error: checkVisitReschedulingError,
  } = useCheckRestoreCancelledVisitQuery(visitId, {
    refetchOnMountOrArgChange: true,
    skip: !openCheckReschedulingDialog,
  });
  const [
    confirmRestoreCancelledVisit,
    { error: confirmVisitReschedulingError },
  ] = useConfirmRestoreCancelledVisitMutation();
  const [
    restoreCancelledVisitNonOptimizedPeriod,
    { error: restoreCancelledVisitNonOptimizedPeriodError },
  ] = useRestoreCancelledVisitNonOptimizedPeriodMutation();

  useEffect(() => {
    const errorData =
      userProfileError ||
      checkVisitReschedulingError ||
      confirmVisitReschedulingError ||
      restoreCancelledVisitNonOptimizedPeriodError;
    if (errorData) {
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    checkVisitReschedulingError,
    confirmVisitReschedulingError,
    restoreCancelledVisitNonOptimizedPeriodError,
    setAlert,
    userProfileError,
  ]);

  const availableVisitsOptions = useMemo(() => {
    if (
      initialStatus?.toLowerCase() === 'canceled' &&
      userProfile?.roles?.includes('ADMIN') &&
      isFutureVisit
    ) {
      return canRestoreCanceledVisitStatuses;
    }
    if (
      initialStatus?.toLowerCase() === 'verified' &&
      userProfile?.roles?.includes('ADMIN')
    ) {
      return unVerifyVisitStatuses;
    }
    if (
      initialStatus?.toLowerCase() !== 'verified' &&
      (isNotAllocatedVisit || isFutureVisit)
    ) {
      return onlyCancelAllowsVisitStatuses;
    }
    return visitStatuses;
  }, [initialStatus, isNotAllocatedVisit, isFutureVisit, userProfile]);

  const closeRescheduling = useCallback(() => {
    setOpenCheckReschedulingDialog(false);
    cancelNewStatus();
  }, [cancelNewStatus]);

  const confirmReschedulingVisit = useCallback(() => {
    if (scheduledVisit) {
      confirmRestoreCancelledVisit(reschedulingData?.requestId)
        .unwrap()
        .then(() => {
          setOpenCheckReschedulingDialog(false);
          reFetchCalendarEvents();
        });
    } else {
      restoreCancelledVisitNonOptimizedPeriod({ date, visitId })
        .unwrap()
        .then(() => {
          setOpenCheckReschedulingDialog(false);
          reFetchCalendarEvents();
        });
    }
    closeNewStatusConfirmation();
  }, [
    closeNewStatusConfirmation,
    confirmRestoreCancelledVisit,
    date,
    reFetchCalendarEvents,
    reschedulingData,
    restoreCancelledVisitNonOptimizedPeriod,
    scheduledVisit,
    visitId,
  ]);

  const onConfirmNewStatus = () => {
    if (
      initialStatus?.toLowerCase() === 'canceled' &&
      temporaryStatus?.toLowerCase() === 'scheduled' &&
      scheduledVisit
    ) {
      setOpenCheckReschedulingDialog(true);
      return '';
    }
    if (
      initialStatus.toLowerCase() === 'canceled' &&
      temporaryStatus.toLowerCase() === 'scheduled' &&
      !scheduledVisit
    ) {
      confirmReschedulingVisit();
      return '';
    }
    confirmNewStatus();
    return '';
  };

  const isReschedulingDataLoading = useMemo(
    () => isLoadingReschedulingData || isFetchingReschedulingData,
    [isLoadingReschedulingData, isFetchingReschedulingData],
  );

  if (isUpdatingVisitStatus) {
    return <Loader />;
  }

  return (
    <Box>
      <Box sx={infoRowWrapperStyles}>
        <Typography sx={infoKeyField}>Status:</Typography>
        <Box sx={{ maxWidth: '250px', minWidth: '250px' }}>
          <VisitStatusSelect
            bgColor={getBgColor(temporaryStatus)}
            disabled={disabledSelect}
            initialVisitStatus={initialStatus}
            isLoading={false}
            options={availableVisitsOptions}
            selectedValue={temporaryStatus || ''}
            setValue={changeStatus}
          />
        </Box>
      </Box>
      <Collapse in={needToConfirmStatus} timeout={300}>
        <StatusChangingConfirmation
          cancelNewStatus={cancelNewStatus}
          confirmNewStatus={onConfirmNewStatus}
          newStatus={temporaryStatus}
        />
      </Collapse>
      {openCheckReschedulingDialog && (
        <CheckReschedulingTable
          closeRescheduling={closeRescheduling}
          confirmReschedulingVisit={confirmReschedulingVisit}
          isReschedulingDataLoading={isReschedulingDataLoading}
          openCheckReschedulingDialog={openCheckReschedulingDialog}
          reschedulingData={reschedulingData}
        />
      )}
    </Box>
  );
}

StatusSelectorField.propTypes = {
  cancelNewStatus: func,
  changeStatus: func,
  closeNewStatusConfirmation: func,
  confirmNewStatus: func,
  date: string,
  disabledSelect: bool,
  initialStatus: string,
  isFutureVisit: bool,
  isNotAllocatedVisit: bool,
  isUpdatingVisitStatus: bool,
  needToConfirmStatus: bool,
  reFetchCalendarEvents: func,
  scheduledVisit: bool,
  temporaryStatus: string,
  visitId: string,
};
