import { Alert } from '@mui/material';
import { instanceOf, number, string } from 'prop-types';
import React from 'react';
import { pluralize } from '../../shared/utils/pluralize';

export default function ColumnAlert({
  alertData = {},
  errorDays = 0,
  errorMessage = '',
}) {
  const { bgColor, color, icon, type, getTitle } = alertData;
  return (
    <Alert
      icon={icon}
      severity={type}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: bgColor,
        borderRadius: '10px',
        mb: '16px',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '16px',
        color,
      }}
    >
      {errorMessage || getTitle(pluralize(errorDays, 'day'))}
    </Alert>
  );
}

ColumnAlert.propTypes = {
  alertData: instanceOf(Object),
  errorDays: number,
  errorMessage: string,
};
