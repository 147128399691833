import moment from 'moment';
import {
  CALIFORNIA_TIME_ZONE,
  DATE_ONLY_STANDARD_FORMAT,
  FULL_DATE_H12_STANDARD_FORMAT,
  H12_TIME_FORMAT,
  ISO_DATE_ONLY_FORMAT,
  SHORT_DATE_FORMAT,
} from '../../../../../../../../shared/constants';
import {
  convertDateToUTC,
  convertUtcToTeamTimeZoneMomentObject,
} from '../../../../../../../../shared/utils/common';

export const getVisitAccordionTitle = (visit) => {
  const arrivalStart = convertUtcToTeamTimeZoneMomentObject(
    visit?.arrivalStart,
  ).format(H12_TIME_FORMAT);
  const arrivalEnd = convertUtcToTeamTimeZoneMomentObject(
    visit?.arrivalEnd,
  ).format(H12_TIME_FORMAT);
  const visitTimeSlot = visit.exactTimeSlot
    ? visit.exactTimeSlot
    : `${arrivalStart} - ${arrivalEnd}`;

  return `${moment(visit.date, ISO_DATE_ONLY_FORMAT).format(
    SHORT_DATE_FORMAT,
  )}, ${visitTimeSlot}`;
};

const getFormattedDate = ({ date, time }) =>
  `${moment(date)?.format(DATE_ONLY_STANDARD_FORMAT)} ${time}`;

export const parseDates = (availabilityScheduling) =>
  availabilityScheduling?.availableSchedule?.map(
    ({ arrivalEnd, arrivalStart, date, exactTimeSlot, preferredCaregiver }) => {
      const formatArrivalStart = getFormattedDate({ date, time: arrivalStart });
      const formatArrivalEnd = getFormattedDate({ date, time: arrivalEnd });
      const utcArrivalStart = convertDateToUTC({
        date: formatArrivalStart,
        dateFormat: FULL_DATE_H12_STANDARD_FORMAT,
        dateTimeZone: CALIFORNIA_TIME_ZONE,
      });
      const utcArrivalEnd = convertDateToUTC({
        date: formatArrivalEnd,
        dateFormat: FULL_DATE_H12_STANDARD_FORMAT,
        dateTimeZone: CALIFORNIA_TIME_ZONE,
      });
      return {
        arrivalEnd: utcArrivalEnd,
        arrivalStart: utcArrivalStart,
        date,
        exactTimeSlot,
        preferredCaregiver,
      };
    },
  );
