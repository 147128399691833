import { Box, Typography } from '@mui/material';
import { bool, instanceOf } from 'prop-types';
import React from 'react';
import styles from './style.module.css';

function PtoHistoryCommon({ updatePtoHistory = false, calloutChanges = {} }) {
  return (
    <Box sx={{ p: '10px 0' }}>
      {updatePtoHistory ? (
        <Box>
          <Typography sx={{ float: 'left', mb: '12px' }}>
            <span className={styles.boldWeight}>Time-Off Request Updated:</span>
          </Typography>

          <ul className={styles.bulletList}>
            <li className={styles.bulletListItem}>
              <Typography>
                <span className={styles.boldWeight}> Status Changed:</span>
                <span>
                  from
                  <span className={styles.oldTime}>
                    {calloutChanges.statusBefore}
                  </span>
                  to
                  <span className={styles.newTime}>
                    {calloutChanges.status}
                  </span>
                </span>
              </Typography>
            </li>
            <li className={styles.bulletListItem}>
              <Typography>
                <span className={styles.boldWeight}> Details Changed:</span>
                {calloutChanges.detailsBefore === calloutChanges.details ? (
                  <span>N/A</span>
                ) : (
                  <span>
                    from
                    <span className={styles.oldTime}>
                      {calloutChanges.detailsBefore}
                    </span>
                    to
                    <span className={styles.newTime}>
                      {calloutChanges.details}
                    </span>
                  </span>
                )}
              </Typography>
            </li>
            <li className={styles.bulletListItem}>
              <Typography>
                <span className={styles.boldWeight}>
                  Start Date & Time Changed:
                </span>
                {calloutChanges.fromDate === calloutChanges.fromDateBefore ? (
                  <span>N/A</span>
                ) : (
                  <span>
                    from
                    <span className={styles.oldTime}>
                      {calloutChanges.fromDateBefore}
                      {calloutChanges.fromTimeBefore}
                    </span>
                    to
                    <span className={styles.newTime}>
                      {calloutChanges.fromDate} {calloutChanges.fromTime}
                    </span>
                  </span>
                )}
              </Typography>
            </li>
            <li className={styles.bulletListItem}>
              <Typography>
                <span className={styles.boldWeight}>
                  Start Date & Time Changed:
                </span>
                {calloutChanges.toDateBefore === calloutChanges.toDate ? (
                  <span>N/A</span>
                ) : (
                  <span>
                    from
                    <span className={styles.oldTime}>
                      {calloutChanges.toDateBefore}
                      {calloutChanges.toTimeBefore}
                    </span>
                    to
                    <span className={styles.newTime}>
                      {calloutChanges.toDate} {calloutChanges.toTime}
                    </span>
                  </span>
                )}
              </Typography>
            </li>
          </ul>
        </Box>
      ) : (
        <Box>
          <Typography sx={{ float: 'left', mb: '12px' }}>
            <span className={styles.boldWeight}>
              {calloutChanges.type === 'Callout'
                ? 'Call-Out Submitted:'
                : 'Time-Off Request Submitted:'}
            </span>
          </Typography>

          <ul className={styles.bulletList}>
            {calloutChanges.type !== 'Callout' && (
              <li className={`${styles.bulletListItem} ${styles.addedType}`}>
                <Typography>
                  <span className={styles.boldWeight}> Request Type:</span>{' '}
                  {calloutChanges.type || 'N/A'}
                </Typography>
              </li>
            )}
            <li className={`${styles.bulletListItem} ${styles.addedType}`}>
              <Typography>
                <span className={styles.boldWeight}>Details:</span>{' '}
                {calloutChanges.details || 'N/A'}
              </Typography>
            </li>
            <li className={`${styles.bulletListItem} ${styles.addedType}`}>
              <Typography>
                <span className={styles.boldWeight}>Start Date & Time:</span>
                {calloutChanges.fromDate} {calloutChanges.fromTime}
              </Typography>
            </li>
            <li className={`${styles.bulletListItem} ${styles.addedType}`}>
              <Box>
                <span className={styles.boldWeight}> End Date & Time: </span>
                {calloutChanges.toDate} {calloutChanges.toTime}
              </Box>
            </li>
            <li className={`${styles.bulletListItem} ${styles.addedType}`}>
              <Box>
                <span className={styles.boldWeight}> Submitted On: </span>
                {calloutChanges.modifiedDate}
              </Box>
            </li>
            <li className={`${styles.bulletListItem} ${styles.addedType}`}>
              <Box>
                <span className={styles.boldWeight}> Submitted By: </span>
                {calloutChanges.author}
              </Box>
            </li>
          </ul>
        </Box>
      )}
    </Box>
  );
}

export default PtoHistoryCommon;

PtoHistoryCommon.propTypes = {
  updatePtoHistory: bool,
  calloutChanges: instanceOf(Object),
};
