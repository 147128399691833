import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryRealDB } from '../../api/baseQueryBE';

// change name
export const CARE_PROGRAM_REAL_API_KEY = 'careProgramRealApi';
const careProgramRealApi = createApi({
  reducerPath: CARE_PROGRAM_REAL_API_KEY,
  baseQuery: baseQueryRealDB,
  endpoints: (builder) => ({
    getCareProgramTable: builder.query({
      query: (clientId) => ({
        url: `/careprogram/${clientId}`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'careProgram', id: 'ALL' }],
    }),
    updateCareProgram: builder.mutation({
      query: ({ clientId, payload }) => ({
        url: `/careprogram/${clientId}`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'careProgram', id: 'ALL' }],
    }),
    getCareProgramHistory: builder.query({
      query: (clientId) => ({
        url: `/careprogram/${clientId}/history`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'careProgram', id: 'ALL' }],
    }),
    checkCareProgramArrivalWindows: builder.query({
      query: ({ clientId, payload }) => ({
        url: `/careprogram/${clientId}/alternative-availability`,
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response) => {
        const unAvailable = [];
        const available = response?.filter((item) => {
          if (!item.available) {
            unAvailable.push(item);
          }
          return item.available;
        });
        return {
          availableSchedule: available,
          unAvailableSchedule: unAvailable,
        };
      },
      providesTags: () => [{ type: 'careProgram', id: 'ALL' }],
    }),
  }),
});

export const {
  useGetCareProgramTableQuery,
  useGetCareProgramHistoryQuery,
  useCheckCareProgramArrivalWindowsQuery,
  useUpdateCareProgramMutation,
} = careProgramRealApi;

export default careProgramRealApi;
