import { Breadcrumbs, Typography } from '@mui/material';
import { bool, func, string } from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router';
import { COLORS } from '../../../../../../../shared/styles/Theme';
import BreadcrumbsButton from '../../../../../../../components/BreadcrumbsButton';

export default function CustomBreadcrumbs({
  closeDialog = () => {},
  disabled = false,
  title = '',
  userId = '',
  userName = '',
}) {
  const navigate = useNavigate();
  const onLinkClick = ({ e, url }) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    } else {
      navigate(url);
      closeDialog();
    }
  };
  return (
    <Breadcrumbs aria-label="breadcrumbs">
      <BreadcrumbsButton
        additionalStyles={{
          pointerEvents: disabled ? 'none' : 'auto',
          color: COLORS.blue[disabled ? 500 : 700],
        }}
        name="Caregivers"
        onLinkClick={(e) => onLinkClick({ e, url: '/caregivers' })}
      />
      <BreadcrumbsButton
        additionalStyles={{
          pointerEvents: disabled ? 'none' : 'auto',
          color: COLORS.blue[disabled ? 500 : 700],
        }}
        name={userName}
        onLinkClick={(e) => onLinkClick({ e, url: `/caregivers/${userId}` })}
      />
      <Typography sx={{ color: COLORS.blue[900] }} variant="body1">
        {title}
      </Typography>
    </Breadcrumbs>
  );
}

CustomBreadcrumbs.propTypes = {
  closeDialog: func,
  disabled: bool,
  title: string,
  userId: string,
  userName: string,
};
