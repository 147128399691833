import moment from 'moment';
import {
  MAX_SCHEDULER_WORKING_HOUR,
  MIN_SCHEDULER_WORKING_HOUR,
} from '../../../../shared/constants';

export const DEFAULT_DURATION_OF_VISIT = 50;

export const findMinimumStartAvailability = (availabilities, events) => {
  if (availabilities?.length === 0 && events?.length !== 0) {
    return events?.reduce((min, item) => {
      if (item?.type === 'lunch break') return min;
      const arrivalStart = item?.arrivalTimeStart?.toString();
      const timeParts = arrivalStart?.split(' ');
      const hours = parseInt(timeParts[4], 10);
      const isPM = timeParts[1].includes('PM');
      const hours24 = isPM ? hours + 12 : hours;
      return Math.min(min, hours24);
    }, 24);
  }
  if (availabilities?.length === 0 && events?.length === 0) {
    return MIN_SCHEDULER_WORKING_HOUR;
  }
  const minMoment = availabilities
    .flat()
    .map((availability) => moment(availability.startTime, 'h:mm A'));

  const minHour = minMoment.reduce((min, current) =>
    current.hour() < min.hour() ? current : min,
  );

  return minHour.hour() === 0 ? 1 : minHour.hour();
};
export const findMaximumEndAvailability = (availabilities, events) => {
  if (availabilities?.length === 0 && events?.length !== 0) {
    return events?.reduce((max, item) => {
      if (item?.type === 'lunch break') return max;
      const arrivalEnd = item?.arrivalTimeEnds?.toString();
      const timeParts = arrivalEnd?.split(' ');
      const hours = parseInt(timeParts[4], 10);
      const isPM = timeParts[1].includes('PM');
      const hours24 = isPM ? hours + 12 : hours;
      const duration = parseInt(item.duration, 10);
      const maxWithDuration = hours24 + Math.floor(duration / 60);
      return Math.max(max, maxWithDuration);
    }, 0);
  }

  if (availabilities?.length === 0 && events?.length === 0) {
    return MAX_SCHEDULER_WORKING_HOUR;
  }

  const maxMoment = availabilities
    .flat()
    .map((availability) => moment(availability.endTime, 'h:mm A'));

  const maxHour = maxMoment.reduce((max, current) =>
    current.hour() > max.hour() ? current : max,
  );

  return maxHour.hour();
};
