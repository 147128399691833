import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetCareProgramHistoryQuery } from '../../../../../../api/CareProgram/api';
import {
  useGetFiltersDataQuery,
  useGetSkillsLibraryQuery,
} from '../../../../../../api/commonApi/api';
// import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
// import { HISTORY_UPDATE_FORMAT } from '../../../../../../shared/constants';
import {
  // addPropertyName,
  processCareProgramChanges,
} from '../../../../../../shared/utils/common';
import AlertContext from '../../../../../../components/Alert';
import CustomTable from '../../../../../../components/Table';
import { PAGE_SIZE, PAGE_SIZE_OPTIONS, historyColumns } from '../../constants';

export default function CareProgramHistory() {
  const apiRef = useGridApiRef();
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const {
    data,
    isLoading,
    error: getCareProgramHistoryError,
  } = useGetCareProgramHistoryQuery(id);
  const {
    data: skillsData,
    isLoading: isLoadingSkills,
    error: getSkillsLibraryError,
  } = useGetSkillsLibraryQuery({
    refetchOnMountOrArgChange: true,
  });
  const {
    data: filtersData,
    isLoading: isFiltersDataLoading,
    error: getFiltersDataError,
  } = useGetFiltersDataQuery({
    refetchOnMountOrArgChange: true,
  });
  useEffect(() => {
    if (
      getSkillsLibraryError ||
      getFiltersDataError ||
      getCareProgramHistoryError
    ) {
      const errorData =
        getSkillsLibraryError ||
        getFiltersDataError ||
        getCareProgramHistoryError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    getSkillsLibraryError,
    getFiltersDataError,
    getCareProgramHistoryError,
    setAlert,
  ]);
  const updatedEvents = data
    ?.map((event, index) =>
      processCareProgramChanges(event, skillsData, filtersData, index),
    )
    .filter(Boolean);
  const sortedUpdatedEvents = updatedEvents?.sort((a, b) => {
    const dateA = new Date(a.dateCreated);
    const dateB = new Date(b.dateCreated);
    return dateB - dateA;
  });
  return (
    <Box>
      <CustomTable
        apiRef={apiRef}
        columns={historyColumns}
        headerHeight={44}
        isLoading={isLoading || isLoadingSkills || isFiltersDataLoading}
        rows={sortedUpdatedEvents}
        pageSize={PAGE_SIZE}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
      />
    </Box>
  );
}
