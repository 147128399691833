import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetClientActivityQuery } from '../../../../api/Clients/api';
import {
  useGetFiltersDataQuery,
  useGetSkillsLibraryQuery,
} from '../../../../api/commonApi/api';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  HISTORY_UPDATE_FORMAT,
  SEARCH_INPUT_DEBOUNCE_DELAY,
} from '../../../../shared/constants';
import { useDebouncedCallback } from '../../../../shared/hooks/useDebounce';
import {
  mergeArraysWithActivity,
  processCarePlanChangesChanges,
  processCareProgramChanges,
} from '../../../../shared/utils/common';
import Activity from '../../../../components/Activity';
import { INITIAL_ACTIVITY_FILTERS } from '../../../../components/Activity/constants';
import AlertContext from '../../../../components/Alert';
import { activityOptions } from './constants';

function ClientActivity() {
  const { id } = useParams();
  const apiRef = useGridApiRef();
  const {
    data,
    isError: clientActivityError,
    isLoading: isActivityLoading,
  } = useGetClientActivityQuery(
    {
      clientId: id,
    },
    { refetchOnMountOrArgChange: true },
  );
  const {
    data: skillsData,
    isLoading: isLoadingSkills,
    error: getSkillsLibraryError,
  } = useGetSkillsLibraryQuery({
    refetchOnMountOrArgChange: true,
  });
  const {
    data: filtersData,
    isLoading: isFiltersDataLoading,
    error: getFiltersDataError,
  } = useGetFiltersDataQuery({
    refetchOnMountOrArgChange: true,
  });
  const [visitsFilters, setVisitsFilters] = useState(INITIAL_ACTIVITY_FILTERS);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const { setAlert } = useContext(AlertContext);

  useEffect(() => {
    if (getSkillsLibraryError || getFiltersDataError || clientActivityError) {
      const errorData =
        getSkillsLibraryError || getFiltersDataError || clientActivityError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    getSkillsLibraryError,
    getFiltersDataError,
    setAlert,
    clientActivityError,
  ]);
  const mergedList = [
    ...mergeArraysWithActivity(
      data?.activitiesByEntity?.Client,
      'Profile Updates',
    ),
    ...mergeArraysWithActivity(
      data?.activitiesByEntity?.ScheduledEvent,
      'Visits',
    ),
    ...mergeArraysWithActivity(
      data?.activitiesByEntity?.CareProgram,
      'Care Program',
    ),
    ...mergeArraysWithActivity(data?.activitiesByEntity?.CarePlan, 'Care Plan'),
  ];

  const filteredData = mergedList?.filter(Boolean);

  const updatedEvents = filteredData
    ?.map((event, index) => {
      if (event.activity === 'Care Program') {
        return processCareProgramChanges(event, skillsData, filtersData, index);
      }
      if (event.activity === 'Care Plan') {
        return processCarePlanChangesChanges(
          event,
          skillsData,
          filtersData,
          index,
        );
      }
      const changes = JSON.parse(event?.changes);
      const changesArray = [];
      const onlyPrimitives = changes?.filter(
        (change) => change.type === 'primitive',
      );
      changesArray.push(...onlyPrimitives);
      const onlyObjects = changes
        ?.filter((change) => change.type === 'object')
        .map((change) => change.changes)
        .flat();
      changesArray.push(...onlyObjects);

      const onlyBlockedCaregivers = changes
        ?.filter((change) => change.propertyName === 'blockedCaregivers')
        ?.filter((change) => change.type === 'collection')
        ?.map((change) => change.changes)
        .flat()
        ?.filter((change) => change.type === 'object')
        ?.map((change) => change.changes)
        .flat();
      const onlyBlockedCaregiversName = onlyBlockedCaregivers?.map(
        (change) => ({
          ...change,
          propertyName: 'blockedCaregivers',
        }),
      );
      changesArray.push(...onlyBlockedCaregiversName);

      return {
        ...event,
        activity: event.activity,
        changes: changesArray,
        dateCreated: moment(event?.dateCreated).format(HISTORY_UPDATE_FORMAT),
        id: index + 1,
      };
    })
    .filter(Boolean);

  const sortedUpdatedEvents = updatedEvents?.sort((a, b) => {
    const dateA = new Date(a.dateCreated);
    const dateB = new Date(b.dateCreated);
    return dateB - dateA;
  });
  useEffect(() => {
    setFilteredEvents(sortedUpdatedEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setVisitsFilters((prevValue) => ({
      ...prevValue,
      activity: activityOptions.map((name) => name),
    }));
  }, [setVisitsFilters]);

  const debouncedSearch = useDebouncedCallback((e) => {
    setVisitsFilters({ ...visitsFilters, [e.target.name]: e.target.value });
  }, SEARCH_INPUT_DEBOUNCE_DELAY);

  const filterEvents = useCallback(() => {
    if (!updatedEvents) return [];
    return updatedEvents.filter((event) => {
      const { changes } = event;
      if (
        visitsFilters.activity &&
        !visitsFilters.activity?.includes(event?.activity)
      ) {
        return false;
      }

      return changes.some((change) =>
        Object.values(change || {}).some(
          (value) =>
            typeof value === 'string' &&
            value.toLowerCase().includes(visitsFilters.search),
        ),
      );
    });
  }, [updatedEvents, visitsFilters]);

  useEffect(() => {
    const filtered = filterEvents();
    setFilteredEvents(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitsFilters]);

  return (
    <Box sx={{ mt: '10px' }}>
      <Activity
        filterData={visitsFilters}
        setFilterData={setVisitsFilters}
        debouncedSearch={debouncedSearch}
        data={filteredEvents}
        activityOptions={activityOptions.map((name) => name)}
        apiRef={apiRef}
        client
        isLoading={isActivityLoading || isFiltersDataLoading || isLoadingSkills}
      />
    </Box>
  );
}

export default ClientActivity;
