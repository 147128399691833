import { Box } from '@mui/material';
import { bool, func, instanceOf } from 'prop-types';
import React from 'react';
import { PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../shared/constants';
import { getNoData } from '../../shared/utils/common';
import CustomTable from '../../components/Table';
import ActivityFilters from './components/ActivityFilters';
import { activityColumns } from './constants';

function Activity({
  activityOptions = [],
  data = [],
  debouncedSearch = () => {},
  filterData = {},
  isLoading = false,
  setFilterData = () => {},
  userOptions = [],
  apiRef = {},
  client = false,
}) {
  return (
    <Box sx={{ mt: '10px' }}>
      <ActivityFilters
        activityOptions={activityOptions}
        debouncedSearch={debouncedSearch}
        filterData={filterData}
        setFilterData={setFilterData}
        userOptions={userOptions}
        client={client}
      />
      <CustomTable
        apiRef={apiRef}
        columns={activityColumns(client)}
        headerHeight={44}
        isLoading={isLoading}
        rows={data}
        noRowsOverlay={() => getNoData({ title: `No Activity  yet` })}
        pageSize={PAGE_SIZE}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
      />
    </Box>
  );
}

Activity.propTypes = {
  apiRef: instanceOf(Object),
  activityOptions: instanceOf(Array),
  data: instanceOf(Array),
  debouncedSearch: func,
  filterData: instanceOf(Object),
  isLoading: bool,
  setFilterData: func,
  client: bool,
  userOptions: instanceOf(Array),
};

export default Activity;
