/* eslint-disable react/prop-types */
import { Alert, AlertTitle, Box, Grow } from '@mui/material';
import {
  useCheckCarePlanChangesMutation,
  useCheckDragAndDropPossibilityMutation,
  useUpdateSchedulerMutation,
  useVerifyVisitMutation,
} from '../../../../api/Scheduler/api';
import moment from 'moment-timezone';
import Header from '../../../../pages/Scheduling/components/EditCalendar/Header';
import {
  dragAndDropSuccessAlertStyles,
  editContentWrapper,
} from '../../../../pages/Scheduling/components/styles';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { H12_TIME_FORMAT, ISO_DATE_ONLY_FORMAT } from '../../../../shared/constants';
import AlertContext from '../../../../components/Alert';
import DnDCalendars from '../../../../components/DndCalendar/DndCalendar';
import FullWidthDialog from '../../../../components/FullWidthDialog';
import Loader from '../../../../components/Loader';
import { LOCAL_STORAGE_KEYS, addArrivalEvents } from '../../constants';
import { DragAnnDropContext } from '../../context';
import '../../index.css';
import {
  calculateCalendarVerticalScrollPosition,
  getDndEventBoxClass,
} from '../../utils';
import CustomNavigation from '../CustomNavigation';
import DragAndDropEventComponent from '../DragAndDropEventComponent';
import RescheduleModalBackend from '../RescheduleModalWindows/RescheduleModalBackend';
import TaskChangesModal from '../TaskChangesModalWindows/TaskChangesModals';

const MemoizedDnDCalendars = React.memo(DnDCalendars);
function EditScheduling({
  currentDate,
  dayPropGetter,
  eventsList,
  filteredCaregivers,
  firstVisit,
  isDateHoliday,
  lastVisit,
  notAllocatedEvents,
  onNavigate,
  resourceMap,
  setEditMode,
  setTeamsFiler,
  showDetails,
  slotPropGetter,
  teamsFilter,
  todayDate,
}) {
  const { setAlert } = useContext(AlertContext);
  const eventWrapperRef = useRef();
  const savedScrollPosition = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS.scrollPosition),
  );
  const [draggedEvent, setDraggedEvent] = useState({});
  const [draggedVisit, setDraggedVisit] = useState({});
  const [open, setOpen] = useState(false);
  const [updateContainer, setUpdateContainer] = useState(0);
  const [carePlanChanged, setIsCarePlanChanged] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [editModeEvents, setEditModeEvents] = useState([]);

  const [
    checkDragAndDropPossibility,
    {
      data: checkPossibilityData,
      isLoading,
      error: checkDragAndDropPossibilityError,
    },
  ] = useCheckDragAndDropPossibilityMutation();
  const [
    updateCalendarData,
    { error: updateSchedulerError, isLoading: isUpdateCalendarLoading },
  ] = useUpdateSchedulerMutation();
  const [
    checkCarePlanChanges,
    { data: carePlanChangesData, error: checkCarePlanError },
  ] = useCheckCarePlanChangesMutation();
  const [updateVisitData, { error: updateVisitDataError }] =
    useVerifyVisitMutation();

  useEffect(() => {
    if (
      checkDragAndDropPossibilityError ||
      updateSchedulerError ||
      checkCarePlanError ||
      updateVisitDataError
    ) {
      const errorData =
        checkDragAndDropPossibilityError ||
        updateSchedulerError ||
        checkCarePlanError ||
        updateVisitDataError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    checkDragAndDropPossibilityError,
    updateSchedulerError,
    checkCarePlanError,
    updateVisitDataError,
    setAlert,
  ]);

  const updateStorageScrollPosition = useCallback((timeContent) => {
    const scrollPosition = {
      top: timeContent.scrollTop.toFixed(2),
      left: timeContent.scrollLeft.toFixed(2),
    };

    localStorage.setItem(
      LOCAL_STORAGE_KEYS.scrollPosition,
      JSON.stringify(scrollPosition),
    );
  }, []);

  useEffect(() => {
    const timeContentEdit = document?.querySelector(
      '.edit-scheduling-calendar  .rbc-time-content',
    );

    if (timeContentEdit && savedScrollPosition) {
      timeContentEdit.scrollLeft = savedScrollPosition.left;
    }

    timeContentEdit?.addEventListener('scroll', () =>
      updateStorageScrollPosition(timeContentEdit),
    );
    return () => {
      timeContentEdit?.removeEventListener('scroll', () =>
        updateStorageScrollPosition(timeContentEdit),
      );
    };
  }, [updateStorageScrollPosition, savedScrollPosition]);

  const getEditModeEvents = useCallback(() => {
    if (!eventsList) return [];
    const eventsWithoutArrival = eventsList?.filter(
      (event) => event?.title !== 'arrival',
    );

    return addArrivalEvents({
      eventsList: [...eventsWithoutArrival],
      filteredCaregivers,
      currentDate,
    });
  }, [eventsList, filteredCaregivers, currentDate]);

  useEffect(() => {
    setTimeout(() => {
      const nonDraggableEvents = document?.querySelectorAll('.non-draggable');
      if (nonDraggableEvents.length > 0) {
        nonDraggableEvents.forEach((element) => {
          element.style.setProperty('opacity', '0.9', 'important');
        });
      }
    });
    setEditModeEvents(getEditModeEvents());
  }, [getEditModeEvents]);

  useEffect(() => {
    setTimeout(() => {
      const selector = `.rbc-event.dnd-event.arrival${draggedEvent.event?.eventId}`;
      const selectorArrivalTime = `.rbc-event.dnd-event.arrivalTime${draggedEvent?.event?.eventId}`;
      const allArrivalTime = document?.querySelectorAll(
        `.rbc-event.dnd-event.arrivalTime`,
      );
      const teamClassElements = document?.querySelectorAll(selector);
      const allArrivalElements = document?.querySelectorAll('.arrival');
      const arrivalTimeElement = document?.querySelector(selectorArrivalTime);
      const draggingEvent = document.querySelector(
        '.rbc-addons-dnd-dragged-event',
      );
      draggingEvent?.classList?.toggle('is-dragging', draggedEvent?.event);
      if (Object.keys(draggedEvent).length > 0) {
        allArrivalElements?.forEach((element) => {
          element.style.setProperty('display', 'none', 'important');
        });
        arrivalTimeElement?.style?.setProperty('display', 'none', 'important');

        teamClassElements?.forEach((element) => {
          element.style.setProperty('display', 'block', 'important');
          element.style.setProperty('left', '0%', 'important');
          element.style.setProperty(
            'background',
            `linear-gradient(
            0deg,
            rgba(43, 119, 255, 0.2),
            rgba(43, 119, 255, 0.2)
          )`,
            'important',
          );
        });
        arrivalTimeElement?.style.setProperty('display', 'block', 'important');
        arrivalTimeElement?.style.setProperty('width', '95%', 'important');
      }
      if (Object.keys(draggedEvent).length === 0) {
        allArrivalElements?.forEach((element) => {
          element.style.setProperty('display', 'none', 'important');
        });
        allArrivalTime?.forEach((element) => {
          element.style.setProperty('display', 'none', 'important');
        });
      }
    });
  }, [draggedEvent, updateContainer]);

  useEffect(() => {
    if (!draggedEvent?.event) {
      setTimeout(() => {
        const targetNodes = document.querySelectorAll('.rbc-events-container');

        const observers = Array.from(targetNodes)?.map((node) => {
          const observer = new MutationObserver(() => {
            setUpdateContainer((prevCounter) => prevCounter + 1);
          });

          const config = { attributes: true, childList: true, subtree: true };

          observer.observe(node, config);

          return observer;
        });

        return () => {
          observers.forEach((observer) => observer.disconnect());
        };
      });
    }
  }, [draggedEvent]);

  const handleMouseOver = useCallback(
    (event) => {
      const eventWrapper = eventWrapperRef.current;
      const timeContentEdit = document?.querySelector('.rbc-time-content');
      if (!eventWrapper || !timeContentEdit || !draggedEvent?.event) return;
      const mouseY = event.clientY;
      const mouseX = event.clientX;
      const scrollTopThreshold = 300;
      const scrollBottomThreshold = timeContentEdit.scrollHeight - 1150;
      const scrollLeftThreshold = 200;
      const scrollRightThreshold = timeContentEdit.clientWidth - 100;

      if (mouseX < scrollLeftThreshold) {
        timeContentEdit.scrollLeft -= 30;
      }
      if (mouseX > scrollRightThreshold) {
        timeContentEdit.scrollLeft += 30;
      }

      if (mouseY < scrollTopThreshold) {
        timeContentEdit.scrollTop -= 50;
      }
      if (mouseY > scrollBottomThreshold) {
        timeContentEdit.scrollTop += 50;
      }
    },
    [draggedEvent],
  );

  const onEventSelect = useCallback((event) => {
    setDraggedEvent(event);
    setDraggedVisit(event);
    const timeContentEdit = document?.querySelector('.rbc-time-content');
    const scrollPosition = {
      top: timeContentEdit.scrollTop.toFixed(2),
      left: timeContentEdit.scrollLeft.toFixed(2),
    };
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.temporaryScrollPosition,
      JSON.stringify(scrollPosition),
    );
  }, []);

  const submitCalendarData = () => {
    setOpen(false);
    updateCalendarData({
      requestId: checkPossibilityData?.requestId,
    }).then((fulfilled) => {
      if (fulfilled) {
        setShowSuccessAlert(true);
        setTimeout(() => {
          setShowSuccessAlert(false);
        }, 3000);
      }
    });
    setIsCarePlanChanged(carePlanChangesData?.carePlanChange);
  };

  const openMovingSubmitDialog = useCallback(
    (checkRequestData) => {
      checkDragAndDropPossibility(checkRequestData);
      checkCarePlanChanges(checkRequestData);
      setOpen(true);
      setDraggedEvent({});
    },
    [checkCarePlanChanges, checkDragAndDropPossibility],
  );

  const moveEvent = useCallback(
    ({ event, start, end, resourceId }) => {
      const { allDay } = event;
      const eventIndex = editModeEvents.findIndex(
        (ev) => ev.eventId === event.eventId,
      );
      if (moment(start).isBefore(moment())) {
        setEditModeEvents(getEditModeEvents());
        return;
      }
      const columnIndex = resourceMap.findIndex(
        (resource) => resource.resourceId === resourceId,
      );
      const columnIndexWithAllocated = notAllocatedEvents.length
        ? columnIndex - 1
        : columnIndex;
      const tenMinutesBefore = moment(start).subtract(4, 'minutes');
      const tenMinutesAfter = moment(start).add(4, 'minutes');
      if (notAllocatedEvents?.length && resourceId === 1) {
        return;
      }
      // return same place
      if (
        event.resourceId === resourceId &&
        moment(event.start).isBetween(tenMinutesBefore, tenMinutesAfter)
      ) {
        return;
      }
      const targetCaregiver = filteredCaregivers[columnIndexWithAllocated];

      const updatedEvent = {
        ...event,
        start,
        arrivalTimeStart: moment(start)
          .subtract(event.timeDifferenceInMinutes, 'minutes')
          .toDate(),
        arrivalTimeEnds: moment(start)
          .add(event.timeDifferenceBetweenStartAndArrivalEnd, 'minutes')
          .toDate(),
        end,
        allDay,
        caregiver: targetCaregiver?.caregiver,
        team: event?.team,
        resourceId: targetCaregiver?.resourceId,
        region: {
          id: targetCaregiver?.region?.id,
          name: targetCaregiver?.region?.name,
        },
      };
      setEditModeEvents((prevEvents) => {
        const updatedEvents = [...prevEvents];
        updatedEvents[eventIndex] = updatedEvent;
        return updatedEvents;
      });
      openMovingSubmitDialog({
        fromDate: currentDate
          ? moment(currentDate).format(ISO_DATE_ONLY_FORMAT)
          : todayDate,
        toDate: currentDate
          ? moment(currentDate).format(ISO_DATE_ONLY_FORMAT)
          : todayDate,
        teams: targetCaregiver?.location,
        team: event.region,
        toCaregiver: targetCaregiver?.caregiver?.id,
        allTeams: targetCaregiver?.allTeamsValue,
        initialTeam: event?.team,
        caregiver: targetCaregiver.caregiver,
        eventId: event?.id,
        toDuration: event?.duration,
        toTime: moment(start).format(H12_TIME_FORMAT),
        fromTime: moment(event?.start).format(H12_TIME_FORMAT),
        eventTeam: event.region,
        arrivalTimeStart: event.arrivalTimeStart,
        arrivalTimeEnd: event.arrivalTimeEnds,
      });
    },
    [
      currentDate,
      editModeEvents,
      filteredCaregivers,
      getEditModeEvents,
      notAllocatedEvents,
      openMovingSubmitDialog,
      resourceMap,
      todayDate,
    ],
  );

  const minStartTime = moment().subtract(30, 'minute').toDate();
  const minUTCStartTime = moment(minStartTime).utc().toDate();
  const customToolbarComponent = (props) => (
    <CustomNavigation isEdit {...props} />
  );
  const calendarComponent = useMemo(
    () => (
      <MemoizedDnDCalendars
        className="edit-scheduling-calendar"
        customToolbar={customToolbarComponent}
        date={currentDate}
        dayPropGetter={dayPropGetter}
        eventComponent={DragAndDropEventComponent}
        eventDrop={moveEvent}
        eventStyleGetter={getDndEventBoxClass}
        events={editModeEvents}
        firstVisit={firstVisit}
        isDateHoliday={isDateHoliday}
        lastVisit={lastVisit}
        onDragStart={onEventSelect}
        onNavigate={onNavigate}
        resources={resourceMap}
        scrollTime={
          currentDate === null || !savedScrollPosition
            ? minUTCStartTime
            : calculateCalendarVerticalScrollPosition({
                currentDate,
                firstVisit,
                top: savedScrollPosition.top,
              })
        }
        slotPropGetter={slotPropGetter}
        toolbar
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentDate,
      editModeEvents,
      dayPropGetter,
      onEventSelect,
      moveEvent,
      onNavigate,
    ],
  );

  const closeEventMovingConfirmation = useCallback(() => {
    setOpen(false);
    const temporaryScrollPosition = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.temporaryScrollPosition),
    );
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.scrollPosition,
      JSON.stringify(temporaryScrollPosition),
    );
    setEditModeEvents(getEditModeEvents());
  }, [getEditModeEvents]);

  const handleMouseUp = useCallback(() => {
    if (draggedEvent.event) {
      setDraggedEvent({});
    }
  }, [draggedEvent]);
  const closeEditMode = () => {
    setEditMode(false);
    setDraggedEvent({});
  };
  const memoizedProviderValue = useMemo(
    () => ({
      draggedEvent,
    }),
    [draggedEvent],
  );
  useEffect(() => {
    if (checkDragAndDropPossibilityError?.status === 500) {
      closeEventMovingConfirmation();
      setOpen(false);
    }
  }, [checkDragAndDropPossibilityError, closeEventMovingConfirmation]);

  return (
    <DragAnnDropContext.Provider value={memoizedProviderValue}>
      <FullWidthDialog
        cancelButtonName=""
        cancelCallback={() => {}}
        submitButtonName=""
        submitCallback={() => {}}
        title="Edit Mode"
        openDialog
        hideBackButton
        hideSubmitButtons
      >
        <Box sx={editContentWrapper}>
          {isUpdateCalendarLoading && <Loader />}
          <Grow in={showSuccessAlert}>
            <Alert sx={dragAndDropSuccessAlertStyles} severity="success">
              <AlertTitle>Success</AlertTitle>
              Successfully moved visit
            </Alert>
          </Grow>
          <Header
            closeEditMode={closeEditMode}
            isDateHoliday={!!isDateHoliday?.name}
            setTeamsFiler={setTeamsFiler}
            showDetails={showDetails}
            teamsFilter={teamsFilter}
          />
          <Box
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseOver}
            role="presentation"
            ref={eventWrapperRef}
            style={{ width: '100%', position: 'relative' }}
          >
            {calendarComponent}
          </Box>
          <RescheduleModalBackend
            checkPossibilityData={checkPossibilityData}
            closeDialog={closeEventMovingConfirmation}
            disabledSubmit={isLoading}
            isLoading={isLoading}
            openDialog={open}
            submitDialog={submitCalendarData}
          />
          <TaskChangesModal
            actualVisit={draggedVisit}
            carePlanChanges={carePlanChangesData}
            openDialog={carePlanChanged}
            setOpenDialog={setIsCarePlanChanged}
            updateVisitData={updateVisitData}
          />
        </Box>
      </FullWidthDialog>
    </DragAnnDropContext.Provider>
  );
}

export default EditScheduling;
