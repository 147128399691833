import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { bool, func, instanceOf, string } from 'prop-types';
import React from 'react';
import { MONTH_DAY_DATE_FORMAT } from '../../../../../../../../../shared/constants';
import { timeSlotItemStyles } from './styles';

export default function SelectAlternativeVisitSlots({
  availableSlots = [],
  isExactTime = false,
  selectedNewSlot = null,
  setAvailableCaregivers = () => {},
  setNewVisitSlot = () => {},
  title = '',
}) {
  const getName = (date, slot) =>
    date ? `${moment(date).format(MONTH_DAY_DATE_FORMAT)}, ${slot}` : slot;

  const slots = availableSlots?.map((slot) =>
    isExactTime
      ? {
          anotherDate: slot?.date,
          caregivers: slot?.availableCaregivers,
          name: getName(slot?.date, slot?.exactTimeSlot),
          value: slot,
        }
      : {
          anotherDate: slot?.date,
          caregivers: slot?.availableCaregivers,
          name: getName(
            slot?.date,
            `${slot?.arrivalStart} - ${slot?.arrivalEnd}`,
          ),
          value: slot,
        },
  );
  const selectedSlot = isExactTime
    ? getName(selectedNewSlot?.date, selectedNewSlot?.exactTimeSlot)
    : getName(
        selectedNewSlot?.date,
        `${selectedNewSlot?.arrivalStart} - ${selectedNewSlot?.arrivalEnd}`,
      );

  const getWidth = ({ anotherDate }) => {
    if (!anotherDate) {
      return '120px';
    }
    if (anotherDate) {
      return '240px';
    }
    return '160px';
  };

  const selectSlot = (caregivers, value) => {
    setAvailableCaregivers(caregivers);
    setNewVisitSlot(value);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', pt: '8px', flex: 1 }}>
      <Typography variant="body1" sx={{ mb: '12px' }}>
        {title}
      </Typography>
      <Box sx={{ display: 'flex', gap: '12px', flexWrap: 'wrap', mb: '16px' }}>
        {slots?.map(({ anotherDate, caregivers, name, value }) => (
          <Box
            className={selectedSlot === name ? 'selected' : ''}
            component="button"
            onClick={() => selectSlot(caregivers, value)}
            key={name}
            sx={{
              ...timeSlotItemStyles,
              minWidth: getWidth({ anotherDate }),
            }}
          >
            {name}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

SelectAlternativeVisitSlots.propTypes = {
  availableSlots: instanceOf(Array),
  isExactTime: bool,
  selectedNewSlot: instanceOf(Object),
  setAvailableCaregivers: func,
  setNewVisitSlot: func,
  title: string,
};
