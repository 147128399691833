import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { func, instanceOf, string } from 'prop-types';
import * as React from 'react';
import CounterBadge from '../../components/CounterBadge';
import {
  customSelectItem,
  customSelectStyles,
  customTableViewStyles,
} from './styles';

const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  disableAutoFocusItem: true,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SelectTeamFilter({
  id = '',
  options = [],
  placeHolder = '',
  selectedValue = [],
  setValue = () => {},
}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === 'all') {
      setValue({
        field: id,
        value: selectedValue?.length === options?.length ? [] : options,
      });
      return '';
    }
    let duplicateRemoved = [];
    value.forEach((item) => {
      if (duplicateRemoved.findIndex((option1) => option1 === item) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x === item);
      } else {
        duplicateRemoved.push(item);
      }
    });
    setValue({ field: id, value: duplicateRemoved });
    return '';
  };
  return (
    <div>
      <FormControl
        sx={{
          ...customTableViewStyles,
        }}
      >
        <Select
          IconComponent={ExpandMoreIcon}
          MenuProps={MenuProps}
          displayEmpty
          multiple
          onChange={handleChange}
          renderValue={() => (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {placeHolder}
              <CounterBadge
                label={
                  options?.length && selectedValue?.length === options?.length
                    ? 'ALL'
                    : selectedValue.length.toString()
                }
              />
            </Box>
          )}
          sx={{
            ...customSelectStyles,
            backgroundColor: selectedValue?.length > 0 && '#FFF6D4 !important',
          }}
          value={selectedValue}
        >
          <MenuItem
            name="all"
            value="all"
            disableRipple
            className="select-all-item"
            sx={{
              ...customSelectItem,
            }}
          >
            <Checkbox
              checked={
                options?.length > 0 && options?.length === selectedValue?.length
              }
            />
            All
          </MenuItem>
          {options?.map((name) => (
            <MenuItem
              key={name}
              value={name}
              disableRipple
              sx={{ ...customSelectItem }}
            >
              <Checkbox
                checked={
                  !selectedValue?.length
                    ? false
                    : selectedValue?.findIndex((item) => item === name) >= 0
                }
                sx={{
                  padding: '5px 9px',
                }}
              />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

SelectTeamFilter.propTypes = {
  id: string,
  options: instanceOf(Array),
  placeHolder: string,
  selectedValue: instanceOf(Array),
  setValue: func,
};
