import { COLORS } from '../../../../../../../shared/styles/Theme';

export const customAccordionSummaryStyles = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row-reverse',
  padding: 0,
  margin: '12px 0',
  cursor: 'default',
  '&.MuiAccordionSummary-root, &.MuiAccordionSummary-root.Mui-expanded': {
    minHeight: '32px',
  },
  '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded':
    {
      justifyContent: 'space-between',
      margin: 0,
      paddingRight: '5px',
      cursor: 'default',
    },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
};

export const customAccordionStyles = {
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: '0 0 20px 0',
  },
  '&:last-of-type, &.Mui-expanded:last-of-type': {
    borderRadius: 0,
  },
  borderRadius: 0,
};

export const caregiverNameStyles = {
  display: 'flex',
  alignItems: 'center',
  '& span': {
    color: COLORS.blue[500],
    mr: '12px',
  },
};

export const visitAccordionSummaryTitle = {
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  ml: '5px',
  cursor: 'pointer',
};
