import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { bool, func, instanceOf, string } from 'prop-types';
import React, { useState } from 'react';
import { SHORT_DATE_FORMAT } from '../../shared/constants';
import { customDateTimePickerStyles } from './styles';

export default function CustomDatePicker({
  changeDate = () => {},
  customFormat = '',
  customStyles = [],
  date = null,
  disabled = false,
  isDisablePast = false,
  isDisabledToday = false,
  label = '',
  maxDate = null,
  shouldDisableDate = () => {},
}) {
  const [open, setOpen] = useState(false);
  return (
    <DatePicker
      disabled={disabled}
      className={`${!date && 'empty-input'} ${disabled && 'disabled-picker'}`}
      defaultValue={null}
      disablePast={isDisablePast}
      minDate={isDisabledToday ? moment().add(1, 'days') : null}
      maxDate={maxDate}
      format={customFormat || SHORT_DATE_FORMAT}
      shouldDisableDate={shouldDisableDate}
      label={label}
      onChange={changeDate}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      value={date}
      slotProps={{
        textField: {
          InputLabelProps: { shrink: open || !!date },
          variant: 'filled',
          readOnly: true,
          onClick: (e) => {
            e.stopPropagation();
            setOpen(true);
          },
        },
      }}
      slots={{ openPickerIcon: DateRangeOutlinedIcon }}
      sx={[...customDateTimePickerStyles, ...(customStyles || [])]}
    />
  );
}

CustomDatePicker.propTypes = {
  changeDate: func,
  customFormat: string,
  customStyles: instanceOf(Array),
  date: instanceOf(Object),
  disabled: bool,
  isDisablePast: bool,
  isDisabledToday: bool,
  label: string,
  maxDate: instanceOf(Object),
  shouldDisableDate: func,
};
