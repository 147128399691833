import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetWeekendsSchedulingDaysHistoryQuery } from '../../../../../../api/Administration/api';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import {
  HISTORY_UPDATE_FORMAT,
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  historyColumns,
} from '../../../../../../shared/constants';
import AlertContext from '../../../../../../components/Alert';
import CustomTable from '../../../../../../components/Table';

export default function SchedulingEngineHistory() {
  const apiRef = useGridApiRef();
  const { setAlert } = useContext(AlertContext);
  const { data, isLoading, error } = useGetWeekendsSchedulingDaysHistoryQuery({
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);

  const updatedEvents = data
    ?.map((event, index) => {
      const changes = JSON.parse(event?.changes);
      const changesArray = [];
      const onlyPrimitives = changes
        ?.filter((type) => type.operation === 'update')
        ?.filter((type) => type.type === 'object')
        ?.map((change) => change.changes)
        .flat();

      if (onlyPrimitives) {
        const transformedPrimitives = onlyPrimitives.map((change) => {
          if (change.propertyName.endsWith('SwitchedOn')) {
            return {
              ...change,
              propertyName: change.propertyName.replace('SwitchedOn', ''),
            };
          }
          return change;
        });
        changesArray.push(...transformedPrimitives);
      }

      if (changesArray.length === 0) {
        return false;
      }
      return {
        ...event,
        changes: changesArray,
        dateCreated: moment(event?.dateCreated).format(HISTORY_UPDATE_FORMAT),
        id: index + 1,
      };
    })
    .filter(Boolean);
  const sortedUpdatedEvents = updatedEvents?.sort((a, b) => {
    const dateA = new Date(a.dateCreated);
    const dateB = new Date(b.dateCreated);
    return dateB - dateA;
  });
  return (
    <Box sx={{ mt: '24px' }}>
      <CustomTable
        apiRef={apiRef}
        columns={historyColumns}
        headerHeight={44}
        isLoading={isLoading}
        rows={sortedUpdatedEvents}
        pageSize={PAGE_SIZE}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
      />
    </Box>
  );
}
