import { COLORS } from '../../../../shared/styles/Theme';

export const customStepperStyles = {
  color: COLORS.blue[900],
  '& .MuiStepConnector-root': {
    width: '44px',
  },
  '& .MuiStepButton-root': {
    padding: '5px',
    margin: '-5px',
  },
  '& .MuiStepIcon-root': {
    width: '24px',
    height: '24px',
    color: COLORS.blue[50],
    '& .MuiStepIcon-text': {
      fontSize: '13px',
      fontWeight: '600',
      fill: COLORS.black[300],
    },
    '&.Mui-active': {
      color: COLORS.green[500],
      '& .MuiStepIcon-text': {
        fill: '#ffffff',
      },
    },
    '&.Mui-completed': {
      color: COLORS.blue[250],
      borderRadius: '24px',
      backgroundColor: COLORS.blue[700],
    },
  },
  '& .MuiStepLabel-label': {
    color: COLORS.black[400],
    '&.Mui-active': {
      color: COLORS.blue[900],
    },
    '&.Mui-completed': {
      color: COLORS.blue[700],
    },
  },
};
