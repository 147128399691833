import { Button, ButtonGroup, Typography } from '@mui/material';
import { bool, func, instanceOf, number, oneOfType, string } from 'prop-types';
import React from 'react';
import { customGroupButton } from './styles';

export default function ButtonsGroup({
  additionalStyles = {},
  buttons = [],
  changeSelected = () => {},
  disabled = false,
  isChangedView = false,
  selected = '',
}) {
  const changeView = (e) => e.target.title && changeSelected(e.target.title);
  return (
    <ButtonGroup
      disableElevation
      onClick={(e) => (!isChangedView ? changeView(e) : changeSelected(e))}
      variant="outlined"
      disabled={disabled}
    >
      {buttons.map(({ name, icon, infoType, subName }) => (
        <Button
          disabled={disabled || infoType === selected}
          className={subName ? 'sub-name' : ''}
          key={name}
          name={infoType}
          value={infoType}
          title={infoType}
          startIcon={icon ? <img src={icon} alt="icon" /> : null}
          sx={{ ...customGroupButton, ...additionalStyles }}
        >
          {name}
          {subName && (
            <Typography variant="string" title={infoType}>
              {subName}
            </Typography>
          )}
        </Button>
      ))}
    </ButtonGroup>
  );
}

ButtonsGroup.propTypes = {
  additionalStyles: instanceOf(Object),
  buttons: instanceOf(Array),
  changeSelected: func,
  disabled: bool,
  isChangedView: bool,
  selected: oneOfType([number, string]),
};
