import CircleIcon from '@mui/icons-material/Circle';
import {
  Box,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import ErrorIcon from '../../../../img/icons/error-icon.svg';
import WarningIcon from '../../../../img/icons/warning-icon.svg';
import moment from 'moment';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  H12_TIME_FORMAT,
  SHORT_DATE_FORMAT,
  backgroundColorSchemeMap,
} from '../../../../shared/constants';
import { COLORS } from '../../../../shared/styles/Theme';
import { getArrivalRange, getIconComponent } from '../../../../shared/utils/common';
import Badge from '../../../../components/Badge';
import CustomTooltip from '../../../../components/CustomTooltip';
import ShadowCaregiverCell from '../../../../components/DeletedSVShadowCaregiverCell';

export const checkingSchedulingColumns = {
  error: {
    title: 'Rescheduling impossible',
    type: 'error',
  },
  warning: {
    title:
      'Rescheduling of rescheduled visit is possible with soft constraints violation',
    type: 'warning',
  },
  partiallySuccessfulWarning: {
    title: (
      <Box sx={{ display: 'flex' }}>
        <img
          style={{ marginRight: '6px' }}
          src={WarningIcon}
          alt="warning icon"
        />
        <Typography
          variant="body1"
          component="span"
          sx={{
            fontWeight: 600,
          }}
        >
          Rescheduling is possible with soft constraints violation
        </Typography>
      </Box>
    ),
    type: 'success',
  },
  partiallySuccessfulError: {
    title: (
      <Box sx={{ display: 'flex' }}>
        <img
          style={{ marginRight: '6px' }}
          src={ErrorIcon}
          alt="warning icon"
        />
        <Typography
          variant="body1"
          component="span"
          sx={{
            fontWeight: 600,
          }}
        >
          Rescheduling is possible with hard constraints violation
        </Typography>
      </Box>
    ),
    type: 'success',
  },
  success: {
    title: 'Rescheduling is possible',
    type: 'success',
  },
};

const visitReassignmentStatusesColorsMap = {
  error: 'error',
  warning: 'partiallySuccessful',
  success: 'active',
};

const getScheduledTimeRange = (data, isMainVisit) => {
  const colorKey = visitReassignmentStatusesColorsMap[data?.status?.status];
  const oldTimeRange = `${moment(
    data?.oldScheduledTime?.start,
    H12_TIME_FORMAT,
  ).format(H12_TIME_FORMAT)} - ${moment(
    data?.oldScheduledTime?.end,
    H12_TIME_FORMAT,
  ).format(H12_TIME_FORMAT)}`;
  const newTimeRange = `${moment(
    data?.newScheduledTime?.start,
    H12_TIME_FORMAT,
  ).format(H12_TIME_FORMAT)} - ${moment(
    data?.newScheduledTime?.end,
    H12_TIME_FORMAT,
  ).format(H12_TIME_FORMAT)}`;
  return (
    <div>
      <Typography
        component="span"
        sx={{
          textDecoration: data?.newScheduledTime ? 'line-through' : 'none',
        }}
      >
        {oldTimeRange}
      </Typography>
      {data?.newScheduledTime && (
        <CustomTooltip
          title={
            colorKey === 'active'
              ? 'Time can be changed within arrival window'
              : ''
          }
        >
          <div>
            {isMainVisit ? (
              <Typography sx={{ fontWeight: 'bold' }} component="span">
                {newTimeRange}
              </Typography>
            ) : (
              <Badge
                colorScheme={backgroundColorSchemeMap[colorKey || 'active']}
                label={newTimeRange}
              />
            )}
          </div>
        </CustomTooltip>
      )}
    </div>
  );
};
const getCaregiverData = (row) => {
  const colorKey = visitReassignmentStatusesColorsMap[row?.status?.status];
  const initialCaregiver = row?.oldCaregiver
    ? `${row?.oldCaregiver?.firstName} ${row?.oldCaregiver?.lastName}`
    : null;
  const newCaregiver = row?.newCaregiver
    ? `${row?.newCaregiver?.firstName} ${row?.newCaregiver?.lastName}`
    : null;
  return (
    <div>
      <Typography
        component="span"
        sx={{ textDecoration: newCaregiver ? 'line-through' : 'none' }}
      >
        {initialCaregiver || ''}
      </Typography>
      {newCaregiver && (
        <CustomTooltip
          title={colorKey === 'active' ? 'Caregiver can be changed' : ''}
        >
          <div>
            <Badge
              colorScheme={backgroundColorSchemeMap[colorKey || 'active']}
              label={newCaregiver || ''}
            />
          </div>
        </CustomTooltip>
      )}
    </div>
  );
};

const getDateData = (row) => {
  const initialDate = row?.oldDate || null;
  const newDate = row?.newDate || null;

  if (newDate === initialDate) {
    return <Typography>{initialDate || ''}</Typography>;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography
        component="span"
        sx={{ textDecoration: newDate ? 'line-through' : 'none' }}
      >
        {initialDate || ''}
      </Typography>
      {newDate && (
        <Typography sx={{ fontWeight: 'bold' }} component="span">
          {newDate || ''}
        </Typography>
      )}
    </Box>
  );
};

export const reschedulingVisitColumns = ({ isMainVisit }) => {
  const commonColumns = [
    {
      field: 'visitType',
      headerName: 'Visit Type',
      flex: 1,
      maxWidth: 250,
      sortable: false,
      renderCell: ({ value, row }) => (
        <Box sx={{ display: 'flex', ml: '20px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box
              sx={{
                display: 'flex',
                ml: row?.shiftedBy ? '40px' : 0,
                mb: '6px',
              }}
            >
              {getIconComponent(value)}
              <Typography
                component="span"
                variant={row?.shiftedBy ? 'body1' : 'h5'}
                sx={{ ml: '8px', color: COLORS.blue[700] }}
              >
                {value}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              sx={{ fontSize: '13px' }}
              component="span"
            >
              {row?.team?.name || row?.region?.name}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: 'client',
      headerName: 'Client',
      minWidth: 160,
      sortable: false,
      renderCell: ({ row, value }) => (
        <Link
          component={RouterLink}
          to={`/clients/${row?.client?.id}`}
          underline="none"
        >
          <Typography
            variant="body1"
            component="span"
            sx={{ color: COLORS.blue[700], cursor: 'pointer' }}
          >{`${value.firstName} ${value.lastName}`}</Typography>
        </Link>
      ),
    },
    {
      field: 'duration',
      headerName: 'Duration',
      sortable: false,
      width: 100,
      renderCell: ({ value }) => (
        <Typography component="span">
          {value !== null ? `${value} min` : '0 min'}
        </Typography>
      ),
    },
    {
      field: 'arrivalWindow',
      headerName: 'Arrival Window',
      sortable: false,
      width: 180,
      renderCell: ({ row }) => getArrivalRange(row),
    },
    {
      field: 'date',
      headerName: 'Date',
      sortable: false,
      width: 180,
      renderCell: ({ row }) => getDateData(row),
    },
    {
      field: 'scheduledTime',
      headerName: 'Scheduled Time',
      sortable: false,
      width: 180,
      renderCell: ({ row }) =>
        row?.oldScheduledTime ? getScheduledTimeRange(row, isMainVisit) : '',
    },
    {
      field: 'caregiver',
      headerName: 'Caregiver',
      minWidth: 150,
      maxWidth: 300,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => getCaregiverData(row),
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      minWidth: 240,
      flex: 1,
      renderCell: ({ value }) => {
        const colorKey = visitReassignmentStatusesColorsMap[value?.status];
        return value?.status && !isMainVisit ? (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <Badge
              colorScheme={backgroundColorSchemeMap[colorKey]}
              label={value.message}
            />
            {value?.status === 'warning' && (
              <CustomTooltip
                title={
                  value?.reasons?.length ? (
                    <Box sx={{ padding: '12px' }}>
                      <List>
                        {value?.reasons?.map((reason) => (
                          <ListItem key={reason} sx={{ padding: 0 }}>
                            <ListItemIcon sx={{ minWidth: '16px' }}>
                              <CircleIcon
                                sx={{
                                  fontSize: '6px',
                                  color: COLORS.blue[700],
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText primary={reason} />
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  ) : (
                    ''
                  )
                }
              >
                <img src={WarningIcon} alt="Warning" />
              </CustomTooltip>
            )}
          </Box>
        ) : (
          ''
        );
      },
    },
  ];
  if (isMainVisit) {
    return commonColumns.filter((column) => column.field !== 'status');
  }
  if (!isMainVisit) {
    return commonColumns.filter((column) => column.field !== 'date');
  }
  return commonColumns;
};
export const shadowVisitColumns = [
  {
    field: 'visitType',
    headerName: 'Visit Type',
    flex: 1,
    maxWidth: 250,
    sortable: false,
    renderCell: ({ value, row }) => (
      <Box sx={{ display: 'flex', ml: '20px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              ml: row?.shiftedBy ? '40px' : 0,
              mb: '6px',
            }}
          >
            {getIconComponent(value)}
            <Typography
              component="span"
              variant={row?.shiftedBy ? 'body1' : 'h5'}
              sx={{ ml: '8px', color: COLORS.blue[700] }}
            >
              {value}
            </Typography>
          </Box>
          <Typography
            variant="body2"
            sx={{ fontSize: '13px' }}
            component="span"
          >
            {row?.team?.name || row?.region?.name}
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    field: 'client',
    headerName: 'Client',
    minWidth: 160,
    sortable: false,
    renderCell: ({ row, value }) => (
      <Link
        component={RouterLink}
        to={`/clients/${row?.client?.id}`}
        underline="none"
      >
        <Typography
          variant="body1"
          component="span"
          sx={{ color: COLORS.blue[700], cursor: 'pointer' }}
        >{`${value.firstName} ${value.lastName}`}</Typography>
      </Link>
    ),
  },
  {
    field: 'duration',
    headerName: 'Duration',
    sortable: false,
    width: 100,
    renderCell: ({ value }) => (
      <Typography component="span">
        {value !== null ? `${value} min` : '0 min'}
      </Typography>
    ),
  },
  {
    field: 'arrivalWindow',
    headerName: 'Arrival Window',
    sortable: false,
    width: 180,
    renderCell: ({ row }) => getArrivalRange(row),
  },
  {
    field: 'scheduledTime',
    headerName: 'Scheduled Time',
    sortable: false,
    width: 180,
    renderCell: ({ row }) =>
      row.oldScheduledTime?.start ? (
        <Typography>{`${row.oldScheduledTime?.start} - ${row.oldScheduledTime?.end}`}</Typography>
      ) : (
        <Typography>{row.oldScheduledTime}</Typography>
      ),
  },
  {
    field: 'caregiver',
    headerName: 'Caregiver',
    minWidth: 150,
    maxWidth: 300,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => <ShadowCaregiverCell row={row} />,
  },
  {
    field: 'date',
    headerName: 'Date',
    sortable: false,
    width: 150,
    renderCell: ({ row }) => (
      <Typography>{moment(row?.oldDate).format(SHORT_DATE_FORMAT)}</Typography>
    ),
  },
];
