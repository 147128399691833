import { Box, Link, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  SHORT_DATE_FORMAT,
  TIME_OFF_REQUEST_DATE_FORMAT,
  TIME_OFF_REQUEST_LAST_UPDATED_DATE_FORMAT,
  backgroundColorSchemeMap,
} from '../../../../shared/constants';
import { COLORS } from '../../../../shared/styles/Theme';
import { convertUtcToTeamTimeZoneMomentObject } from '../../../../shared/utils/common';
import Badge from '../../../../components/Badge';
import RequestsActions from './RequestsActions';
import CaregiverTeams from './components/CaregiverTeams';
import { lastUpdateActionsWrapper, lastUpdateCellWrapper } from './styles';

export const INITIAL_FILTERS = {
  datesRange: {},
  caregiver: '',
  teams: [],
  status: [],
};

export const TIME_OFF_REQUESTS_STATUSES = ['Approved', 'Pending', 'Rejected'];

const PAGE_SIZE = 20;

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];
export const DEFAULT_SORTING_MODEL = { field: 'requestStart', sort: 'asc' };
const getDateValue = (allDay, date) =>
  allDay
    ? convertUtcToTeamTimeZoneMomentObject(date)?.format(SHORT_DATE_FORMAT)
    : convertUtcToTeamTimeZoneMomentObject(date)?.format(
        TIME_OFF_REQUEST_DATE_FORMAT,
      );

export const getBadgeColorScheme = (status) => {
  const statusesKeys = {
    approved: 'active',
    pending: 'partiallySuccessful',
    rejected: 'error',
  };
  const statusKey = statusesKeys[status?.toLowerCase()];
  return backgroundColorSchemeMap[statusKey];
};
const columns = [
  {
    field: 'caregiver',
    headerName: 'Caregiver',
    minWidth: 150,
    maxWidth: 220,
    flex: 1,
    renderCell: ({ row }) => (
      <Link
        component={RouterLink}
        to={`/caregivers/${row?.caregiver?.id}/pto-callouts/current`}
        underline="none"
      >
        <Typography
          variant="body1"
          sx={{ color: COLORS.blue[700], cursor: 'pointer' }}
        >
          {row?.caregiver?.firstName} {row?.caregiver?.lastName}
        </Typography>
      </Link>
    ),
    sortable: false,
  },
  {
    field: 'team',
    headerName: 'Team',
    minWidth: 180,
    maxWidth: 200,
    renderCell: ({ row }) => (
      <CaregiverTeams
        primaryTeams={row?.caregiverTeams}
        secondaryTeams={row?.caregiverSecondaryTeams}
      />
    ),
    sortable: false,
  },
  {
    field: 'requestType',
    headerName: 'Request Type',
    maxWidth: 140,
    flex: 1,
    sortable: true,
  },
  {
    field: 'details',
    headerName: 'Details',
    minWidth: 150,
    maxWidth: 150,
    sortable: false,
    flex: 2,
  },
  {
    field: 'createdDate',
    headerName: 'Date Submitted',
    minWidth: 125,
    maxWidth: 140,
    flex: 2,
    renderCell: ({ value }) => (
      <Box
        sx={{
          ...lastUpdateCellWrapper,
          justifyContent: value ? 'start' : 'center',
        }}
      >
        {value
          ? convertUtcToTeamTimeZoneMomentObject(value).format(
              SHORT_DATE_FORMAT,
            )
          : '-'}
      </Box>
    ),
    sortable: false,
  },
  {
    field: 'requestStart',
    headerName: 'Start Date & Time',
    minWidth: 110,
    maxWidth: 190,
    flex: 2,
    renderCell: ({ row, value }) => (
      <Box sx={lastUpdateCellWrapper}>{getDateValue(row.allDay, value)}</Box>
    ),
    sortComparator: (v1, v2) => moment(v1).diff(moment(v2)),
  },
  {
    field: 'requestEnd',
    headerName: 'End Date & Time',
    minWidth: 110,
    maxWidth: 150,
    flex: 2,
    renderCell: ({ row, value }) => (
      <Box sx={lastUpdateCellWrapper}>{getDateValue(row.allDay, value)}</Box>
    ),
    sortable: false,
  },
  {
    field: 'lastUpdated',
    headerName: 'Last Updated',
    minWidth: 250,
    flex: 1,
    renderCell: ({ row }) => (
      <Box sx={lastUpdateCellWrapper}>
        {row?.lastUpdatedBy ? (
          <Stack sx={{ width: '160px' }}>
            <Typography
              sx={{ maxWidth: '160px' }}
            >{`${row?.lastUpdatedBy.firstName} ${row?.lastUpdatedBy.lastName}`}</Typography>
            <Typography sx={{ maxWidth: '100px' }}>
              {row?.modifiedDate
                ? convertUtcToTeamTimeZoneMomentObject(row.modifiedDate).format(
                    TIME_OFF_REQUEST_LAST_UPDATED_DATE_FORMAT,
                  )
                : ''}
            </Typography>
          </Stack>
        ) : (
          '-'
        )}

        <Box sx={lastUpdateActionsWrapper}>
          <Badge
            colorScheme={getBadgeColorScheme(row?.status)}
            label={row?.status}
          />
          <RequestsActions row={row} />
        </Box>
      </Box>
    ),
    sortable: false,
  },
];

export { columns, PAGE_SIZE, PAGE_SIZE_OPTIONS };
