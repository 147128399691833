import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useGetCareProgramTableQuery } from '../../../../../../api/CareProgram/api';
import moment from 'moment';
import { EditCareProgramContext } from '../../../../../../pages/ClientDetails/context';
import { func, string } from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { SHORT_DATE_FORMAT } from '../../../../../../shared/constants';
import { COLORS } from '../../../../../../shared/styles/Theme';
import AlertContext from '../../../../../../components/Alert';
import ButtonsGroup from '../../../../../../components/ButtonsGroup';
import VisitsSummary from '../../components/VisitsSummary';

function CareProgramTableViewHeader({
  view = '',
  careProgramVersion = '',
  setCareProgramVersion = () => {},
}) {
  const { setAlert } = useContext(AlertContext);
  const { openUpdateProgram } = useContext(EditCareProgramContext);
  const { id } = useParams();
  const { data, isLoading, error } = useGetCareProgramTableQuery(id);
  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);
  if (isLoading) {
    return false;
  }

  const maxEffectiveDateStart = data?.careProgram?.reduce(
    (maxDate, program) => {
      const effectiveDate = new Date(program.effectiveDateStart);
      return effectiveDate > maxDate ? effectiveDate : maxDate;
    },
    new Date(0),
  );

  const detailsButtons = [
    { name: 'Current', infoType: 'current' },
    {
      name: `${moment(maxEffectiveDateStart).format(SHORT_DATE_FORMAT)}`,
      infoType: 'future',
    },
  ];
  const ifMaxDateIsAfterToday = moment(maxEffectiveDateStart).isAfter(moment());
  return (
    <div style={{ display: 'flex' }}>
      <VisitsSummary data={data} />
      {view === 'calendar' && ifMaxDateIsAfterToday && (
        <ButtonsGroup
          buttons={detailsButtons}
          changeSelected={setCareProgramVersion}
          selected={careProgramVersion}
        />
      )}
      {data?.careProgram.length ? (
        <Button
          sx={{ boxShadow: 'none', ml: '16px' }}
          variant="contained"
          onClick={openUpdateProgram}
        >
          Edit mode
        </Button>
      ) : (
        <Button
          color="secondary"
          variant="outlined"
          onClick={openUpdateProgram}
          startIcon={<AddIcon />}
          disableElevation
          sx={{
            '&.MuiButton-root.Mui-disabled': {
              backgroundColor: 'transparent',
              color: COLORS.blue[500],
              opacity: 0.7,
            },
          }}
        >
          Add visit
        </Button>
      )}
    </div>
  );
}

CareProgramTableViewHeader.propTypes = {
  view: string,
  careProgramVersion: string,
  setCareProgramVersion: func,
};

export default React.memo(CareProgramTableViewHeader);
