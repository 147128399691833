import { Box, Typography } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ISO_DATE_ONLY_FORMAT } from '../../../../shared/constants';
import { VisitInfoTypeContext } from '../../../../shared/context';
import { infoKeyField, infoRowWrapperStyles } from '../../styles';
import { checkEnableToEditFields, parseShortVisitInfoData } from '../../utils';
import ActualTimeFields from '../ActualTimeFields';
import DateAndArrivalTimeFields from '../DateAndArrivalTimeFields';
import StatusSelectorField from '../StatusSelectorField';
import VisitPrimaryCaregiver from '../VisitPrimaryCaregiver';
import VisitShadowCaregiver from '../VisitShadowCaregiver';
import LinkItem from './LinkItem';

export default function VisitInfoFields() {
  const [unMarkingPreferredCaregiver, setUnMarkingPreferredCaregiver] =
    useState(false);
  const [unMarkingShadowCaregiver, setUnMarkingShadowCaregiver] =
    useState(false);
  const [editingPrimaryCaregiver, setEditingPrimaryCaregiver] = useState(false);
  const [editingShadowCaregiver, setEditingShadowCaregiver] = useState(false);
  const [editActualTime, setEditActualTime] = useState(false);
  const [needToConfirmStatus, setNeedToConfirmStatus] = useState(false);
  const {
    initialVisitData,
    onUpdateVisitDetails,
    reFetchCalendarEvents,
    temporaryVisitData,
    updateTemporaryVisitData,
    isUpdatingVisitStatus,
    editArrivalTime,
    setEditArrivalTime,
  } = useContext(VisitInfoTypeContext);
  const visitData = useMemo(
    () => parseShortVisitInfoData(temporaryVisitData),
    [temporaryVisitData],
  );

  const changeStatus = useCallback(
    (value) => {
      const newData = { status: value };
      updateTemporaryVisitData(newData);
      setNeedToConfirmStatus(true);
    },
    [updateTemporaryVisitData],
  );

  const cancelNewStatus = () => {
    setNeedToConfirmStatus(false);
    updateTemporaryVisitData({
      status: initialVisitData.status,
      actualTimeStart: initialVisitData.actualTimeStart,
      actualTimeEnd: initialVisitData.actualTimeEnd,
    });
  };

  const cancelPrimaryCaregiverEditing = () => {
    setEditingPrimaryCaregiver(false);
    updateTemporaryVisitData({
      caregiver: initialVisitData.caregiver,
    });
  };

  const cancelShadowCaregiverEditing = () => {
    setEditingShadowCaregiver(false);
    updateTemporaryVisitData({
      shadowCaregiver: initialVisitData.shadowCaregiver,
    });
  };

  const confirmNewStatus = useCallback(
    (reason) => {
      setNeedToConfirmStatus(false);
      onUpdateVisitDetails({ statusChanging: true, reason });
    },
    [onUpdateVisitDetails],
  );

  const closeNewStatusConfirmation = useCallback(
    () => setNeedToConfirmStatus(false),
    [],
  );

  const isNotAllocatedVisit = !initialVisitData?.caregiver;
  const enableFieldsToEditData = useMemo(
    () =>
      checkEnableToEditFields({
        editActualTime,
        editArrivalTime,
        editingPrimaryCaregiver,
        editingShadowCaregiver,
        isNotAllocatedVisit,
        needToConfirmStatus,
        unMarkingPreferredCaregiver,
        unMarkingShadowCaregiver,
        visitStatus: visitData.status,
      }),
    [
      editActualTime,
      editArrivalTime,
      editingPrimaryCaregiver,
      editingShadowCaregiver,
      isNotAllocatedVisit,
      needToConfirmStatus,
      unMarkingPreferredCaregiver,
      unMarkingShadowCaregiver,
      visitData.status,
    ],
  );
  const isFutureVisit = moment(
    initialVisitData?.date,
    ISO_DATE_ONLY_FORMAT,
  ).isAfter(moment(), 'day');

  const isOutOfOptimizedPeriodVisit = moment(
    initialVisitData?.date,
    ISO_DATE_ONLY_FORMAT,
  ).isAfter(moment().clone().startOf('week').add(13, 'days'));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={infoRowWrapperStyles}>
        <Typography sx={infoKeyField}>Client:</Typography>
        <LinkItem
          link={visitData?.client?.link || ''}
          name={visitData?.client?.name || ''}
        />
      </Box>
      <Box sx={infoRowWrapperStyles}>
        <Typography sx={infoKeyField}>Location:</Typography>
        <Typography variant="body1">{visitData?.location}</Typography>
      </Box>
      <VisitPrimaryCaregiver
        cancelPrimaryCaregiverEditing={cancelPrimaryCaregiverEditing}
        caregiverData={visitData?.caregiver}
        editPrimaryCaregiver={() => setEditingPrimaryCaregiver(true)}
        editingPrimaryCaregiver={editingPrimaryCaregiver}
        enableToEditPrimaryCaregiver={
          enableFieldsToEditData.isPrimaryCaregiverAvailableToEdit &&
          !isOutOfOptimizedPeriodVisit
        }
        onUnmarkPreferredCaregiver={setUnMarkingPreferredCaregiver}
        preferredCaregiverData={visitData?.preferredCaregiver}
        unMarkingPreferredCaregiver={unMarkingPreferredCaregiver}
      />
      <VisitShadowCaregiver
        cancelShadowCaregiverEditing={cancelShadowCaregiverEditing}
        caregiverData={visitData?.shadowCaregiver}
        editingShadowCaregiver={editingShadowCaregiver}
        enableToEditShadowCaregiver={
          enableFieldsToEditData.isShadowCaregiverAvailableToEdit &&
          !isOutOfOptimizedPeriodVisit
        }
        onEditShadowCaregiver={() => setEditingShadowCaregiver(true)}
        onUnmarkShadowCaregiver={setUnMarkingShadowCaregiver}
        unMarkingShadowCaregiver={unMarkingShadowCaregiver}
      />
      <Box sx={infoRowWrapperStyles}>
        <Typography sx={infoKeyField}>Team:</Typography>
        <Typography variant="body1">{visitData?.team?.name || ''}</Typography>
      </Box>
      <StatusSelectorField
        cancelNewStatus={cancelNewStatus}
        closeNewStatusConfirmation={closeNewStatusConfirmation}
        changeStatus={changeStatus}
        confirmNewStatus={confirmNewStatus}
        date={initialVisitData?.date}
        disabledSelect={
          (initialVisitData?.shadowCaregiver &&
            initialVisitData?.caregiver.id ===
              initialVisitData?.shadowCaregiver.id) ||
          enableFieldsToEditData.isStatusDisabledToEdit
        }
        isUpdatingVisitStatus={isUpdatingVisitStatus}
        isFutureVisit={isFutureVisit}
        isNotAllocatedVisit={isNotAllocatedVisit}
        initialStatus={
          initialVisitData?.shadowCaregiver &&
          initialVisitData?.caregiver.id ===
            initialVisitData?.shadowCaregiver.id
            ? initialVisitData?.shadowStatus
            : initialVisitData?.status
        }
        needToConfirmStatus={needToConfirmStatus}
        reFetchCalendarEvents={reFetchCalendarEvents}
        scheduledVisit={!!initialVisitData?.externalId}
        temporaryStatus={visitData?.status}
        visitId={initialVisitData?.id}
      />
      <DateAndArrivalTimeFields
        editArrivalTime={editArrivalTime}
        enableToEditScheduledTime={
          enableFieldsToEditData.isScheduledTimeAvailableToEdit &&
          !isOutOfOptimizedPeriodVisit
        }
        isExactTime={visitData?.isExactTime}
        setEditArrivalTime={setEditArrivalTime}
        scheduledValue={visitData?.scheduledArrival}
      />
      <ActualTimeFields
        actualTimeEnd={visitData?.actualTimeEnd || '-'}
        actualTimeStart={visitData?.actualTimeStart || '-'}
        editActualTime={editActualTime}
        enableToEditActualTime={
          enableFieldsToEditData.isActualTimeAvailableToEdit &&
          !isOutOfOptimizedPeriodVisit
        }
        temporaryVisitData={temporaryVisitData}
        setEditActualTime={setEditActualTime}
      />
      <Box sx={infoRowWrapperStyles}>
        <Typography sx={infoKeyField}>Duration:</Typography>
        <Typography variant="body1">
          {visitData?.actualDuration || visitData?.duration}
        </Typography>
      </Box>
    </Box>
  );
}
