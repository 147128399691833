import { Box, Typography } from '@mui/material';
// import WarningIconYellow from '../../../../img/icons/warning-icon.svg';
import { instanceOf } from 'prop-types';
import React from 'react';
// import CustomTooltip from '../../../../components/CustomTooltip';
import { formattedTime } from '../../constants';
import { detailsInfoKeysStyles, detailsWrapper } from '../styles';

export default function DetailsWrapper({ caregiver = {} }) {
  const calculateColor = () => {
    if (caregiver.utilizationRate < 50) {
      return '#FF3B30';
    }
    if (caregiver.utilizationRate < 60) {
      return '#FF9C28';
    }
    return '#09AF00';
  };
  return (
    <Box className="expandedHeader" sx={detailsWrapper}>
      <Box sx={{ display: 'flex', mb: '3px' }}>
        <Typography sx={{ ...detailsInfoKeysStyles, pl: '12px' }}>
          with Travel Time:
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            color: calculateColor(),
          }}
        >
          {caregiver.utilizationRateWithTravelTime}%
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', mb: '3px' }}>
        <Typography sx={{ ...detailsInfoKeysStyles, fontWeight: 700 }}>
          Utilization Hours:
        </Typography>
        <Typography sx={{ fontSize: '12px' }}>
          {formattedTime(caregiver?.utilizedMinutes)}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', mb: '3px' }}>
        <Typography sx={{ ...detailsInfoKeysStyles, pl: '12px' }}>
          with Travel Time:
        </Typography>
        <Typography sx={{ fontSize: '12px' }}>
          {formattedTime(caregiver?.utilizedMinutesWithTravelTime)}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', mb: '3px' }}>
        <Typography sx={detailsInfoKeysStyles}>
          Expected Travel Time:
        </Typography>
        <Typography sx={{ fontSize: '12px' }}>
          {formattedTime(caregiver?.expectedTravelTime)}
        </Typography>
      </Box>
    </Box>
  );
}

DetailsWrapper.propTypes = {
  caregiver: instanceOf(Object),
};
