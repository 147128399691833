import { Box, Typography } from '@mui/material';
import { useGetUserDataByIdQuery } from '../../../../../../../../api/Clients/api';
import CustomBreadcrumbs from '../../../../../../../../pages/ClientDetails/components/Breadcrumbs';
import {
  ClientDetailsContext,
  EditCareProgramContext,
} from '../../../../../../../../pages/ClientDetails/context';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { SHORT_DATE_FORMAT } from '../../../../../../../../shared/constants';
import AlertContext from '../../../../../../../../components/Alert';
import CareProgramTable from '../../../CareProgramTable';
import VisitsSummary from '../../../VisitsSummary';
import {
  updateCareProgramInfoWrapper,
  updateCareProgramTitleWrapper,
  updateCareProgramViewWrapper,
} from './style';

function CareProgramEditMode() {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const {
    closeDialog,
    effectiveDate,
    initialCareProgramData,
    updatedCareProgram,
  } = useContext(EditCareProgramContext);
  const { resetToInitialView } = useContext(ClientDetailsContext);
  const { data: clientData, error } = useGetUserDataByIdQuery(id);

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);

  return (
    <Box sx={updateCareProgramViewWrapper}>
      <Box sx={updateCareProgramTitleWrapper}>
        <CustomBreadcrumbs
          closeDialog={closeDialog}
          resetToInitialView={resetToInitialView}
          title="Update Care Program"
        />
        <Typography variant="body1">
          {`Effective Date: ${effectiveDate.format(SHORT_DATE_FORMAT)}`}
        </Typography>
      </Box>
      <Box sx={updateCareProgramInfoWrapper}>
        <Typography variant="h1">
          {`${clientData?.clientDetails?.firstName} ${clientData?.clientDetails?.lastName}`}
        </Typography>
        <VisitsSummary data={initialCareProgramData} />
      </Box>
      <CareProgramTable careProgramVisits={updatedCareProgram} isEditView />
    </Box>
  );
}

export default React.memo(CareProgramEditMode);
