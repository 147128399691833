import { isItemsContainSubstring } from '../../shared/utils/common';
import { CLIENTS_STATUSES_MAP } from './constants';

export const filterClient = (
  { clientName, teams, phoneNumber, status },
  clients,
) => {
  let data = [...clients];
  if (data?.length && clientName?.length) {
    data = data.filter((item) => {
      const fullName = `${item.firstname} ${item.lastname}`;
      const reverseFullName = `${item.lastname} ${item.firstname}`;
      return (
        isItemsContainSubstring(fullName, clientName) ||
        isItemsContainSubstring(reverseFullName, clientName)
      );
    });
  }
  if (data.length && phoneNumber?.length) {
    data = data.filter((item) => {
      const numericPhoneNumber = item?.phone?.replace(/\D/g, '');

      const formattedPhoneNumber = `(${numericPhoneNumber?.slice(
        0,
        3,
      )}) ${numericPhoneNumber?.slice(3, 6)}-${numericPhoneNumber?.slice(6)}`;
      return item.phone
        ? isItemsContainSubstring(formattedPhoneNumber, phoneNumber)
        : '';
    });
  }
  if (data.length && !status.length) {
    data = [];
  }
  if (data.length && status.length) {
    data = data.filter((item) =>
      status.includes(CLIENTS_STATUSES_MAP[item.status?.toLowerCase()]),
    );
  }

  if (teams.length === 0) {
    data = [];
  }

  if (data.length && teams.length) {
    let emptyTeamClients = [];
    let selectedRegionClient = [];
    const locationNames = teams?.map((location) => location.name);

    const allNames = [...locationNames];
    if (teams?.includes('Empty Team')) {
      emptyTeamClients = data?.filter(
        (item) => item?.team?.length === 0 || item.team === null,
      );
    }
    if (teams?.some((team) => team !== 'Empty Team')) {
      selectedRegionClient = data?.filter((item) =>
        allNames?.includes(item?.team?.name),
      );
    }

    data = Array.from(new Set([...selectedRegionClient, ...emptyTeamClients]));
  }
  return data;
};

export const getSelectedLocations = (selectedLocations, locationsList) => {
  if (!selectedLocations?.length || !locationsList?.length) return false;
  const regions = selectedLocations?.filter(
    (item) => locationsList?.map(({ name }) => name).indexOf(item) >= 0,
  ).length;
  const communities = selectedLocations.length - regions;
  return { regions, communities };
};
