import { Box, Typography } from '@mui/material';
import { useCheckCareProgramArrivalWindowsQuery } from '../../../../../../../../../../api/CareProgram/api';
import AvailabilityLoader from '../../../../../../../../../../pages/ClientDetails/components/AvailabilityLoader';
import { func, instanceOf } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { COLORS } from '../../../../../../../../../../shared/styles/Theme';
import AlertContext from '../../../../../../../../../../components/Alert';
import CustomDialog from '../../../../../../../../../../components/Dialog';
import AvailabilityColumns from './AvailabilityColumns';

function AlternativeArrivalWindows({
  alternativeArrivalWindows = {},
  completedSteps = [],
  selectedTimeSlots = {},
  setAlternativeArrivalWindows = () => {},
  setStepDataLoading = () => {},
  setVisitData = () => {},
  updatedCareProgram = {},
  visitData = {},
}) {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [deletedVisitData, setDeletedVisitData] = useState(null);
  const [expanded, setExpanded] = useState([]);
  const {
    data: careProgramArrivalWindows,
    isLoading,
    isFetching,
    error,
  } = useCheckCareProgramArrivalWindowsQuery(
    {
      clientId: id,
      payload: {
        careProgramAvailabilityRequest: {
          ...visitData,
          careProgramVisits: updatedCareProgram,
        },
        days: Object.values(selectedTimeSlots).map((timeSlot) => ({
          ...timeSlot,
          weekday: timeSlot.day,
        })),
      },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: completedSteps.includes(1),
    },
  );
  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);
  useEffect(() => {
    if (careProgramArrivalWindows) {
      setAlternativeArrivalWindows(careProgramArrivalWindows);
    }
  }, [careProgramArrivalWindows, setAlternativeArrivalWindows]);
  useEffect(() => {
    const isDataLoading = isFetching || isLoading;
    setStepDataLoading(isDataLoading);
  }, [isFetching, isLoading, setStepDataLoading]);

  const confirmDeleting = () => {
    const { dataKey, date } = deletedVisitData;
    const newSchedule = alternativeArrivalWindows[dataKey].map((item) =>
      item.date === date ? { ...item, cancelled: true } : item,
    );
    setExpanded(expanded.filter((item) => item !== date));
    setDeletedVisitData(null);
    setOpenConfirmationModal(false);
    setAlternativeArrivalWindows({
      ...alternativeArrivalWindows,
      [dataKey]: newSchedule,
    });
  };

  const deleteVisitFromSchedule = (date) => {
    setOpenConfirmationModal(true);
    setDeletedVisitData(date);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => {
          setDeletedVisitData(null);
          setOpenConfirmationModal(false);
        }}
        submitButtonName="confirm"
        submitCallback={confirmDeleting}
        title="Confirmation"
        openDialog={openConfirmationModal}
      >
        <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            The day of visit will be removed. Do you want to proceed?
          </Typography>
        </Box>
      </CustomDialog>
      {isLoading || isFetching ? (
        <AvailabilityLoader />
      ) : (
        <AvailabilityColumns
          alternativeArrivalWindows={alternativeArrivalWindows}
          deleteVisitFromSchedule={deleteVisitFromSchedule}
          expanded={expanded}
          setAlternativeArrivalWindows={setAlternativeArrivalWindows}
          setExpanded={setExpanded}
          setVisitData={setVisitData}
        />
      )}
    </Box>
  );
}

AlternativeArrivalWindows.propTypes = {
  alternativeArrivalWindows: instanceOf(Object),
  completedSteps: instanceOf(Array),
  selectedTimeSlots: instanceOf(Object),
  setAlternativeArrivalWindows: func,
  setStepDataLoading: func,
  setVisitData: func,
  updatedCareProgram: instanceOf(Array),
  visitData: instanceOf(Object),
};

export default React.memo(AlternativeArrivalWindows);
