import { COLORS } from '../../../../../../shared/styles/Theme';

export const customRadioStyles = {
  '& .MuiRadio-root.Mui-checked': {
    color: COLORS.blue[700],
  },
};
export const territorySelectStyles = {
  '& .animated-placeholder': {
    top: '0px',
  },
};
