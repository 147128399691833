import { Box, Typography } from '@mui/material';
import { CaregiverProfileContext } from '../../../../../pages/CaregiverDetails/context';
import { bool, instanceOf } from 'prop-types';
import React, { useContext } from 'react';
import { backgroundColorSchemeMap } from '../../../../../shared/constants';
import { checkEqualArrays } from '../../../../../shared/utils/common';
import Badge from '../../../../../components/Badge';

export default function PrimaryAndSecondaryRegionsInfo({
  isPrimary = false,
  value = [],
}) {
  const emptyValuePlaceholder = isPrimary ? 'No primary team' : '';
  const { profileData } = useContext(CaregiverProfileContext);
  const isEqualTeams = checkEqualArrays(value, profileData?.futurePrimaryTeams);

  return (
    <Box sx={{ display: 'flex', maxWidth: '600px', flexWrap: 'wrap', gap: 1 }}>
      <Box>
        {value?.length ? (
          value?.map((regions, index) => {
            const key = `${regions?.name}${regions?.id}-${index}`;
            return (
              <Box key={key}>
                {!profileData.primaryChanged ? (
                  <Typography
                    key={key}
                    sx={{ marginRight: '2px' }}
                    variant="body1"
                  >
                    {regions?.name &&
                      `${regions?.name}${
                        index !== value.length - 1 ? ',' : ''
                      }`}
                  </Typography>
                ) : (
                  <Box>
                    <Badge
                      colorScheme={backgroundColorSchemeMap.error}
                      label={regions?.name}
                    />
                  </Box>
                )}
              </Box>
            );
          })
        ) : (
          <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
            {emptyValuePlaceholder}
          </Typography>
        )}
      </Box>
      {!isEqualTeams && profileData?.futurePrimaryTeams?.length && (
        <Box sx={{ display: 'flex', gap: 1 }}>
          {profileData?.futurePrimaryTeams?.map((newRegion, counter) => (
            <Typography
              key={newRegion.id}
              sx={{ marginRight: '2px' }}
              variant="body1"
            >{`${newRegion.name}${
              counter !== value.length - 1 ? ',' : ''
            }`}</Typography>
          ))}
          <Typography>from {profileData?.effectiveDateStart}</Typography>
        </Box>
      )}
    </Box>
  );
}

PrimaryAndSecondaryRegionsInfo.propTypes = {
  isPrimary: bool,
  value: instanceOf(Array),
};
