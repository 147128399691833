import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import { Box, Pagination, PaginationItem } from '@mui/material';
import {
  gridPageCountSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import PaginationItemsPerPageSelect from '../PaginationItemsPerPageSelect';

export default function CustomPagination() {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const { pageSizeOptions, initialState } = useGridRootProps();
  const [itemsPerPage, setItemsPerPage] = useState(
    initialState.pagination.paginationModel.pageSize,
  );
  const [page, setPage] = useState(
    initialState.pagination.paginationModel.page,
  );
  useEffect(() => {
    apiRef.current.setPageSize(itemsPerPage);
  }, [apiRef, itemsPerPage]);

  const handlePageChange = (event, value) => {
    setPage(value - 1);
    apiRef.current.setPage(value - 1);
  };

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Pagination
        color="primary"
        count={pageCount}
        onChange={handlePageChange}
        page={page + 1}
        renderItem={(props2) => (
          <PaginationItem
            {...props2}
            disableRipple
            slots={{
              first: FastRewindIcon,
              previous: ArrowLeftIcon,
              next: ArrowRightIcon,
              last: FastForwardIcon,
            }}
          />
        )}
        shape="rounded"
        showFirstButton
        showLastButton
        variant="outlined"
      />
      <Box sx={{ flexGrow: 1 }} />
      <PaginationItemsPerPageSelect
        itemsPerPage={itemsPerPage}
        options={pageSizeOptions}
        setItemsPerPage={(e) => setItemsPerPage(e.target.value)}
      />
    </Box>
  );
}
