import { Box, Stack } from '@mui/material';
import {
  useGetWeekendsSchedulingDaysQuery,
  useUpdateWeekendsSchedulingDaysMutation,
} from '../../../../api/Administration/api';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router';
import { viewTypeButtons } from '../../../../shared/constants';
import { NavigationBlockingContext } from '../../../../shared/context';
import AlertContext from '../../../../components/Alert';
import ButtonsGroup from '../../../../components/ButtonsGroup';
import Loader from '../../../../components/Loader';
import SchedulingEngineActions from './components/SchedulingEngineActions';
import SchedulingEngineHistory from './components/SchedulingEngineHistory';
import WeekdaysForScheduling from './components/WeekdaysForScheduling';
import { SchedulingEngineContext } from './context';

export default function AdministrationSchedulingEngine() {
  const { tabSubView } = useParams();
  const navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);
  const { blocked, setBlocked } = useContext(NavigationBlockingContext);
  const [isEdit, setIsEdit] = useState(false);
  const { data, isLoading, error } = useGetWeekendsSchedulingDaysQuery();
  const [
    updateWeekendDays,
    { isLoading: isUpdateLoading, error: updateError },
  ] = useUpdateWeekendsSchedulingDaysMutation();

  useEffect(() => {
    const errorData = error || updateError;
    if (errorData) {
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [setAlert, error, updateError]);
  const [switchData, setSwitchData] = useState(data?.schema);
  const changeSubView = (value) => {
    navigate(`/administration/scheduling-engine/${value}`);
  };
  useEffect(() => {
    setSwitchData(data?.schema);
  }, [data]);

  useEffect(
    () => () => {
      if (blocked) setBlocked(false);
    },
    [blocked, setBlocked],
  );

  const onEdiSchedulingEngine = useCallback(() => {
    setIsEdit(true);
    setBlocked(true);
  }, [setBlocked]);

  const onCloseSchedulingEngine = useCallback(() => {
    setIsEdit(false);
    setBlocked(false);
    setSwitchData(data?.schema);
  }, [data, setBlocked]);

  const onSaveWeekendDays = useCallback(() => {
    updateWeekendDays({
      payload: {
        version: data?.version,
        schema: switchData,
      },
    });
    setIsEdit(false);
    setBlocked(false);
  }, [data?.version, setBlocked, switchData, updateWeekendDays]);

  const memoizedProviderValue = useMemo(
    () => ({
      isEdit,
      onCloseSchedulingEngine,
      onEdiSchedulingEngine,
      onSaveWeekendDays,
    }),
    [isEdit, onCloseSchedulingEngine, onEdiSchedulingEngine, onSaveWeekendDays],
  );

  const handleSwitchChange = (updatedSwitchData) => {
    setSwitchData(updatedSwitchData);
  };
  if (isLoading || isUpdateLoading) {
    return <Loader />;
  }

  return (
    <SchedulingEngineContext.Provider value={memoizedProviderValue}>
      <Stack>
        {tabSubView === 'current' ? (
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box>
                <ButtonsGroup
                  buttons={viewTypeButtons}
                  changeSelected={changeSubView}
                  selected={tabSubView}
                  disabled={isEdit}
                />
              </Box>
              <SchedulingEngineActions />
            </Box>
            <WeekdaysForScheduling
              disabled={!isEdit}
              onChange={handleSwitchChange}
              switchData={switchData}
            />
          </Box>
        ) : (
          <Box>
            <ButtonsGroup
              buttons={viewTypeButtons}
              changeSelected={changeSubView}
              selected={tabSubView}
            />
            <SchedulingEngineHistory />
          </Box>
        )}
      </Stack>
    </SchedulingEngineContext.Provider>
  );
}
