import { Typography } from '@mui/material';
import leftArrow from '../../img/icons/iconButtons/leftArrow.svg';
import rightArrow from '../../img/icons/iconButtons/rightArrow.svg';
import moment from 'moment';
import { func, instanceOf } from 'prop-types';
import React from 'react';
import { Navigate } from 'react-big-calendar';
import './index.css';

export default function SevenDaysCalendarCustomToolbar({
  date = null,
  onNavigate = () => {},
}) {
  const handlePrev = () => {
    const prevDate = moment(date).subtract(0, 'day');
    onNavigate(Navigate.PREVIOUS, prevDate);
  };
  const handleNext = () => {
    const nextDate =
      moment(date).day() === 0
        ? moment(date).subtract(1, 'day')
        : moment(date).add(0, 'day');
    onNavigate(Navigate.NEXT, nextDate);
  };

  return (
    <div className="rbc-toolbar">
      <Typography variant="h5" className="rbc-toolbar-label">
        {moment(date)?.format('MMMM YYYY')}
      </Typography>
      <div className="rbc-btn-group">
        <button className="calendar-buttons" type="button" onClick={handlePrev}>
          <img src={leftArrow} alt="" />
        </button>
        <button className="calendar-buttons" type="button" onClick={handleNext}>
          <img src={rightArrow} alt="" />
        </button>
      </div>
    </div>
  );
}

SevenDaysCalendarCustomToolbar.propTypes = {
  date: instanceOf(Date),
  onNavigate: func,
};
