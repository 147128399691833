import { Box, IconButton } from '@mui/material';
import EditIcon from '../../../../../img/icons/edit-icon.svg';
import CancelIcon from '../../../../../img/icons/iconButtons/cancel-icon.svg';
import DeleteIcon from '../../../../../img/icons/iconButtons/delete-icon.svg';
import { timeOfRequestActionsButtonStyles } from '../../../../../pages/CaregiverDetails/view/PtoAndCallouts/styles';
import { EditCareProgramContext } from '../../../../../pages/ClientDetails/context';
import { instanceOf } from 'prop-types';
import React, { useContext } from 'react';
import CustomTooltip from '../../../../../components/CustomTooltip';
import { careProgramVisitActionsWrapper } from './CareProgramManagementProvider/components/styles';

function VisitActions({ visit = {}, visitEditTypes = {} }) {
  const {
    initialCareProgramData,
    setEditedVisitData,
    setUpdatedCareProgram,
    showRemoveProgramVisit,
    updatedCareProgram,
  } = useContext(EditCareProgramContext);

  const cancelChanges = () => {
    if (visit.temporaryVisitId && !visit.changesType) {
      const newVisitsList = [...updatedCareProgram].filter(
        (item) => item.temporaryVisitId !== visit.temporaryVisitId,
      );
      setUpdatedCareProgram(newVisitsList);
      return '';
    }
    if (visit.visitId && visit.changesType === visitEditTypes.delete) {
      const updatedVisitIndex = updatedCareProgram.findIndex(
        ({ visitId }) => visitId === visit.visitId,
      );
      const initialVisitData = initialCareProgramData.careProgram.find(
        ({ visitId }) => visitId === visit.visitId,
      );
      const newVisitsList = [...updatedCareProgram];
      newVisitsList[updatedVisitIndex] = initialVisitData;
      setUpdatedCareProgram(newVisitsList);
    }
    if (
      visit.visitId &&
      visit.changesType === visitEditTypes.edit &&
      visit.childId
    ) {
      const editVisitIndex = updatedCareProgram.findIndex(
        ({ visitId }) => visitId === visit.visitId,
      );
      const initialVisitData = initialCareProgramData.careProgram.find(
        ({ visitId }) => visitId === visit.visitId,
      );
      const newVisitsList = [...updatedCareProgram].filter(
        ({ temporaryVisitId }) => temporaryVisitId !== visit.childId,
      );
      newVisitsList[editVisitIndex] = initialVisitData;
      setUpdatedCareProgram(newVisitsList);
    }
    return '';
  };

  const hasUnsavedChanges = visit.changesType || visit.temporaryVisitId;
  const canRevertChanges = hasUnsavedChanges && !visit.parentId;
  const canBeEdited = !hasUnsavedChanges || visit.parentId || !visit.visitId;
  const canBeDeleted = !hasUnsavedChanges && !visit.temporaryVisitId;

  return (
    <Box sx={careProgramVisitActionsWrapper}>
      {canBeEdited && (
        <CustomTooltip title="Edit visit">
          <span>
            <IconButton
              aria-label="Edit visit"
              disabled={!!visit.effectiveDateEnd}
              disableRipple
              onClick={() => setEditedVisitData(visit)}
              sx={timeOfRequestActionsButtonStyles}
            >
              <img src={EditIcon} alt="Edit" />
            </IconButton>
          </span>
        </CustomTooltip>
      )}
      {canRevertChanges && (
        <CustomTooltip title="Cancel Changes">
          <span>
            <IconButton
              aria-label="Cancel Changes"
              disableFocusRipple
              disableRipple
              onClick={cancelChanges}
              sx={timeOfRequestActionsButtonStyles}
            >
              <img src={CancelIcon} alt="Cancel" />
            </IconButton>
          </span>
        </CustomTooltip>
      )}
      {canBeDeleted && (
        <CustomTooltip title="Delete visit">
          <span>
            <IconButton
              aria-label="Delete visit"
              disabled={!!visit.effectiveDateEnd}
              disableRipple
              onClick={() => showRemoveProgramVisit(visit)}
              sx={timeOfRequestActionsButtonStyles}
            >
              <img src={DeleteIcon} alt="Delete" />
            </IconButton>
          </span>
        </CustomTooltip>
      )}
    </Box>
  );
}

VisitActions.propTypes = {
  visit: instanceOf(Object),
  visitEditTypes: instanceOf(Object),
};

export default React.memo(VisitActions);
