import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import { CreateOneTimeVisitContext } from '../../../../../pages/ClientDetails/context';
import { func, instanceOf } from 'prop-types';
import React, { useContext } from 'react';
import { COLORS } from '../../../../../shared/styles/Theme';
import SevenDaysCalendarCustomToolbar from '../../../../../components/SevenDaysCalendar/SevenDaysCustomToolbar';

export default function SevenDaysCalendarToolbar({
  date = null,
  onNavigate = () => {},
}) {
  const { addOneTimeVisit } = useContext(CreateOneTimeVisitContext);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        height: '40px',
        mb: '20px',
      }}
    >
      <SevenDaysCalendarCustomToolbar date={date} onNavigate={onNavigate} />
      <Button
        color="secondary"
        variant="outlined"
        onClick={addOneTimeVisit}
        startIcon={<AddIcon />}
        disableElevation
        sx={{
          '&.MuiButton-root.Mui-disabled': {
            backgroundColor: 'transparent',
            color: COLORS.blue[500],
            opacity: 0.7,
          },
        }}
      >
        Create visit
      </Button>
    </Box>
  );
}

SevenDaysCalendarToolbar.propTypes = {
  date: instanceOf(Date),
  onNavigate: func,
};
