import { Box, Typography } from '@mui/material';
import React from 'react';

function SuccessResult() {
  return (
    <Box>
      <Typography sx={{ ml: '6px' }} variant="body1">
        The visit can be rescheduled
      </Typography>
      <Typography sx={{ ml: '6px' }} variant="body1">
        Do you want to proceed?
      </Typography>
    </Box>
  );
}

export default SuccessResult;
