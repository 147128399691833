import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  createFilterOptions,
} from '@mui/material';
import PreferredIcon from '../../../../img/icons/preferred-icon.svg';
import { bool, func, instanceOf, string } from 'prop-types';
import React from 'react';
import { COLORS } from '../../../../shared/styles/Theme';
import { customInputStyles } from '../../../../components/Inputs/styles';
import {
  autocompletePaperStyles,
  autocompleteStyles,
  customSelectItem,
} from './styles';

function CustomPaper(props) {
  return <Paper elevation={0} {...props} sx={autocompletePaperStyles} />;
}

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option) => option.name,
});

export default function CustomAutocomplete({
  changeValue = () => {},
  disabled = false,
  id = '',
  label = '',
  optionsList = [],
  selectedValue = '',
  showAdditionalIcon = false,
}) {
  return (
    <Autocomplete
      disablePortal
      disabled={disabled}
      filterOptions={filterOptions}
      getOptionLabel={(option) => option?.id || option || ''}
      id={id}
      isOptionEqualToValue={(option, value) =>
        value === undefined || value === '' || value === option?.name
      }
      onChange={changeValue}
      options={optionsList}
      PaperComponent={CustomPaper}
      popupIcon={<SearchIcon fontSize="medium" />}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete="off"
          className={disabled ? 'disabled-multiselect' : ''}
          label={label}
          id="caregiver"
          variant="filled"
          sx={{
            ...customInputStyles,
            color: COLORS.blue[900],
            height: '40px',
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: showAdditionalIcon ? (
              <>
                <InputAdornment
                  position="end"
                  sx={{
                    position: 'absolute',
                    top: 20,
                    right: 34,
                    '& img': {
                      width: '16px',
                      height: '16px',
                    },
                  }}
                >
                  <img src={PreferredIcon} alt="preferred-icon" />
                </InputAdornment>
                {/* {params.InputProps.endAdornment} */}
              </>
            ) : null,
          }}
        />
      )}
      renderOption={(props, option) => (
        <MenuItem
          {...props}
          key={option?.name}
          disableRipple
          sx={{ ...customSelectItem }}
        >
          {option?.name}
        </MenuItem>
      )}
      sx={autocompleteStyles}
      value={selectedValue || null}
    />
  );
}

CustomAutocomplete.propTypes = {
  changeValue: func,
  disabled: bool,
  id: string,
  label: string,
  optionsList: instanceOf(Array),
  selectedValue: string,
  showAdditionalIcon: bool,
};
