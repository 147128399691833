export const tasksManagementTableStyles = (isEmpty) => [
  {
    '& .MuiDataGrid-virtualScroller': {
      maxHeight: '100%',
      padding: isEmpty ? '0' : '1px',
    },
    '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-cell': {
      padding: '39px 21px 36px 21px',
    },
    '& .MuiDataGrid-footerContainer': {
      margin: 0,
    },
  },
];

export const previewEffectiveDatesStatusStyles = {
  display: 'flex',
  '& .MuiChip-root': {
    width: '100%',
  },
};

export const customCellWrapper = {
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
};

export const durationAndEffectiveDates = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
};
