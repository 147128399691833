import { Box } from '@mui/material';
import { useGetCaregiversOrderQuery } from '../../../../api/Administration/api';
import moment from 'moment';
import { bool, func, instanceOf, string } from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import {
  ISO_DATE_ONLY_FORMAT,
  visitArrivalTimeTypesNames,
  visitStatusesMap,
} from '../../../../shared/constants';
import AlertContext from '../../../../components/Alert';
import ArrivalTypeSelector from '../../../../components/ArrivalTypeSelector';
import { CustomDatePicker } from '../../../../components/CustomDateAndTimePickers';
import CustomAutocomplete from '../AutocompleteSelect';
import CustomTeamSelect from '../TeamSelect';
import { visitCaregiverAndDateWrapper } from './styles';

function CaregiverAndDate({
  disabledAll = false,
  initialVisitData = {},
  isExactTime = false,
  primaryCaregiver = {},
  preferredCaregiver = {},
  shadowCaregiver = {},
  team = {},
  updateTemporaryVisitData = () => {},
  visitDate = '',
  visitStatus = '',
}) {
  const { setAlert } = useContext(AlertContext);
  const { data: caregivers, isLoading, error } = useGetCaregiversOrderQuery();
  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);
  const caregiversList = useMemo(
    () =>
      caregivers
        ?.map(({ participantDto }) => ({
          id: participantDto.id,
          name: `${participantDto.firstName} ${participantDto.lastName}`,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [caregivers],
  );

  const teamsList = useMemo(() => {
    const caregiverData = caregivers?.find(
      ({ caregiverId }) => caregiverId === primaryCaregiver?.id,
    );
    return caregiverData
      ? [
          ...(caregiverData?.primaryTeams || []),
          ...(caregiverData?.secondaryTeams || []),
        ]
      : [];
  }, [caregivers, primaryCaregiver?.id]);

  const selectedTeamColor = useMemo(() => {
    if (!teamsList.length || !team) {
      return 'transparent';
    }
    const foundTeam = teamsList.find(
      ({ name }) => name?.toLowerCase() === team?.name?.toLowerCase(),
    );
    return foundTeam ? foundTeam?.color?.color : 'transparent';
  }, [teamsList, team]);

  const selectCaregiver = (_, value) => {
    if (!value) {
      return;
    }
    const selectedCaregiver = caregivers.find(
      ({ participantDto }) => participantDto?.id === value?.id,
    );
    updateTemporaryVisitData({
      caregiver: selectedCaregiver?.participantDto,
      team: null,
    });
  };

  const changeVisitTeam = useCallback(
    (value) => {
      const teamObject = teamsList?.find((item) => item.name === value);
      updateTemporaryVisitData({
        team: teamObject,
      });
    },
    [teamsList, updateTemporaryVisitData],
  );

  const changeVisitDate = useCallback(
    (date) =>
      updateTemporaryVisitData({
        date: moment(date).startOf('day').format(ISO_DATE_ONLY_FORMAT),
      }),
    [updateTemporaryVisitData],
  );

  const changeArrivalTimeType = useCallback(
    (e) => {
      const isExactTimeSelected = e.target.value === 'exactTime';
      updateTemporaryVisitData({
        isExactTime: isExactTimeSelected,
        arrivalTimeStart: initialVisitData.arrivalTimeStart,
        arrivalTimeEnd: isExactTimeSelected
          ? initialVisitData.arrivalTimeStart
          : initialVisitData.arrivalTimeEnd,
      });
    },
    [initialVisitData, updateTemporaryVisitData],
  );

  const maxDatePickerDate = moment().startOf('week').add(13, 'days');

  const disabledToChange = useMemo(
    () =>
      ![
        visitStatusesMap.scheduled,
        visitStatusesMap.enRoute,
        visitStatusesMap.onSite,
      ].includes(visitStatus),
    [visitStatus],
  );
  return (
    <Box sx={visitCaregiverAndDateWrapper}>
      <CustomAutocomplete
        id="primaryCaregiver"
        changeValue={selectCaregiver}
        disabled={disabledAll || isLoading || disabledToChange}
        optionsList={caregiversList}
        label="Primary Caregiver"
        selectedValue={
          primaryCaregiver
            ? `${primaryCaregiver.firstName} ${primaryCaregiver.lastName}`
            : ''
        }
        showAdditionalIcon={!!preferredCaregiver?.id}
      />
      <CustomAutocomplete
        id="shadowCaregiver"
        changeValue={selectCaregiver}
        disabled={disabledAll || isLoading || disabledToChange}
        optionsList={caregiversList}
        label="Shadow Caregiver"
        selectedValue={
          shadowCaregiver
            ? `${shadowCaregiver.firstName} ${shadowCaregiver.lastName}`
            : ''
        }
      />
      {!!teamsList.length && (
        <CustomTeamSelect
          changeValue={changeVisitTeam}
          disabled={disabledAll || disabledToChange}
          isLoading={isLoading}
          options={teamsList}
          placeHolder="Team"
          selectedValue={team?.name || ''}
          teamBgColor={selectedTeamColor}
        />
      )}
      <CustomDatePicker
        changeDate={changeVisitDate}
        customStyles={[{ width: '200px', flex: 'inherit' }]}
        disabled={disabledAll || disabledToChange}
        date={moment(visitDate, ISO_DATE_ONLY_FORMAT)}
        isDisablePast
        label="Visit Date"
        maxDate={maxDatePickerDate}
      />
      <ArrivalTypeSelector
        arrivalTimeType={
          isExactTime
            ? visitArrivalTimeTypesNames.exactTime
            : visitArrivalTimeTypesNames.arrivalWindow
        }
        changeArrivalTimeType={changeArrivalTimeType}
        disabled={disabledAll || disabledToChange}
      />
    </Box>
  );
}

CaregiverAndDate.propTypes = {
  disabledAll: bool,
  initialVisitData: instanceOf(Object),
  isExactTime: bool,
  primaryCaregiver: instanceOf(Object),
  preferredCaregiver: instanceOf(Object),
  shadowCaregiver: instanceOf(Object),
  team: instanceOf(Object),
  updateTemporaryVisitData: func,
  visitDate: string,
  visitStatus: string,
};

export default React.memo(CaregiverAndDate);
