import { Box } from '@mui/material';
import moment from 'moment';
import { func, number, string } from 'prop-types';
import React, { useState } from 'react';
import { H12_TIME_FORMAT } from '../../../../shared/constants';
import CustomTimeSelect from '../../../../components/CustomDateAndTimePickers/CustomTimeSelect';
import ErrorAlert from '../../../../components/ErrorAlert';

export default function ArrivalWindow({
  arrivalEnd = '',
  arrivalStart = '',
  changeArrivalTimeRange = () => {},
  gap = 0,
}) {
  const [error, setError] = useState(null);

  const updateArrivalWindow = ({ startTime, endTime }) => {
    const arrivalRange = {
      arrivalStart: startTime || '',
      arrivalEnd: endTime || '',
    };
    changeArrivalTimeRange(arrivalRange);
  };

  const onStartChange = ({ newValue, endTimeValue }) => {
    const startTime = moment(newValue, H12_TIME_FORMAT);
    const endTime = moment(endTimeValue, H12_TIME_FORMAT);
    updateArrivalWindow({ startTime: newValue, endTime: endTimeValue });
    if (endTime.isSameOrBefore(startTime)) {
      setError('You have been inputted wrong arrival window time');
      return null;
    }
    setError(null);
    return null;
  };

  const onEndChange = ({ newValue, startTimeValue }) => {
    const startTime = moment(startTimeValue, H12_TIME_FORMAT);
    const endTime = moment(newValue, H12_TIME_FORMAT);
    updateArrivalWindow({ startTime: startTimeValue, endTime: newValue });
    if (endTime.isSameOrBefore(startTime)) {
      setError('You have been inputted wrong arrival window time');
      return false;
    }
    setError(null);
    return null;
  };
  return (
    <div>
      {error && <ErrorAlert error={error} />}
      <Box sx={{ display: 'flex', mb: '10px', gap: gap ? `${gap}px` : '20px' }}>
        <CustomTimeSelect
          onSelectTimeValue={(value) =>
            onStartChange({ newValue: value, endTimeValue: arrivalEnd })
          }
          placeHolder="Scheduled Start Time"
          selectedTime={arrivalStart}
        />
        <CustomTimeSelect
          onSelectTimeValue={(value) =>
            onEndChange({ newValue: value, startTimeValue: arrivalStart })
          }
          placeHolder="Scheduled End Time"
          selectedTime={arrivalEnd}
        />
      </Box>
    </div>
  );
}

ArrivalWindow.propTypes = {
  arrivalEnd: string,
  arrivalStart: string,
  changeArrivalTimeRange: func,
  gap: number,
};
