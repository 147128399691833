import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, MenuItem, Paper, TextField } from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { func, instanceOf, string } from 'prop-types';
import React from 'react';
import { COLORS } from '../../../../../../shared/styles/Theme';
import { customInputStyles } from '../../../../../../components/Inputs/styles';
import { customSelectItem } from '../Select/styles';
import { autocompletePaperStyles } from './styles';

function CustomPaper(props) {
  return <Paper elevation={0} {...props} sx={autocompletePaperStyles} />;
}

export default function CustomAutocomplete({
  changeValue = () => {},
  id = '',
  options = [],
  selectedValue = '',
}) {
  return (
    <Autocomplete
      disablePortal
      disabled={!options.length}
      id={id}
      sx={{
        width: '100%',
        padding: 0,
        [`& .${autocompleteClasses.popupIndicator}`]: {
          transform: 'none',
        },
        '& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected="true"].Mui-focused':
          {
            backgroundColor: COLORS.blue[50],
          },
        '& .MuiAutocomplete-endAdornment': {
          top: '50%',
        },
      }}
      options={options}
      getOptionLabel={(option) => option?.name || option || ''}
      isOptionEqualToValue={(option, value) =>
        value === undefined || value === '' || option?.name === value || false
      }
      PaperComponent={CustomPaper}
      onChange={changeValue}
      popupIcon={<SearchIcon fontSize="medium" />}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete="off"
          label="Task"
          id="task"
          variant="filled"
          sx={{
            ...customInputStyles,
            color: COLORS.blue[900],
            height: '40px',
          }}
        />
      )}
      renderOption={(props, option) => (
        <MenuItem
          {...props}
          key={`${option?.name} - ${option?.details}`}
          disableRipple
          sx={{ ...customSelectItem }}
        >
          {option?.name}
        </MenuItem>
      )}
      value={selectedValue || null}
    />
  );
}

CustomAutocomplete.propTypes = {
  changeValue: func,
  id: string,
  options: instanceOf(Array),
  selectedValue: string,
};
