import { Box, Typography } from '@mui/material';
import { number } from 'prop-types';
import React from 'react';

export default function VisitCost({ cost = 0 }) {
  return (
    <Box>
      <Typography variant="body2" noWrap>
        Visit Cost
      </Typography>
      {cost ? (
        <Box sx={{ display: 'flex', alignItems: 'start' }}>
          <Typography variant="body2" noWrap sx={{ mr: '4px' }}>
            $
          </Typography>
          <Typography variant="body1" noWrap>
            {cost.toFixed(2)}
          </Typography>
        </Box>
      ) : (
        <Typography variant="body1">N /A</Typography>
      )}
    </Box>
  );
}

VisitCost.propTypes = {
  cost: number,
};
