import { Box } from '@mui/material';
import { useGetCaregiversListQuery } from '../../../../../api/Caregivers/api';
import errorIcon from '../../../../../img/icons/error-outlined.svg';
import successIcon from '../../../../../img/icons/success-outlined.svg';
import { func, instanceOf } from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { VisitInfoTypeContext } from '../../../../../shared/context';
import { COLORS } from '../../../../../shared/styles/Theme';
import AlertContext from '../../../../../components/Alert';
import Loader from '../../../../../components/Loader';
import CustomAutocomplete from '../../AutocompleteSelect';
import ColumnAlert from './ColumnAlert';
import SetupActions from './SetupActions';
import { editPreferredCaregiverWrapperStyles } from './styles';

export default function VisitShadowCaregiverSetup({
  applyShadowCaregiver = () => {},
  cancelEditing = () => {},
  checkShadowCaregiverAvailability = () => {},
  checkShadowCaregiverAvailabilityData = null,
  clearShadowCaregiverAvailabilityData = () => {},
}) {
  const { setAlert } = useContext(AlertContext);
  const {
    initialVisitData,
    reFetchCalendarEvents,
    temporaryVisitData,
    updateTemporaryVisitData,
  } = useContext(VisitInfoTypeContext);
  const [availableToRecheck, setAvailableToRecheck] = useState(false);
  const [caregiverHasBeenRechecked, setCaregiverHasBeenRechecked] =
    useState(false);

  const {
    data: caregivers,
    error: caregiversListError,
    isLoading: isCaregiversListLoading,
  } = useGetCaregiversListQuery(
    {
      team: temporaryVisitData?.team?.id,
      active: true,
      date: temporaryVisitData?.date,
    },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    const errorData = caregiversListError;
    if (errorData) {
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [caregiversListError, setAlert]);

  const onApplyShadowCaregiver = () => {
    cancelEditing();
    applyShadowCaregiver({
      caregiverId: temporaryVisitData?.shadowCaregiver?.id,
      visitId: temporaryVisitData?.id,
    })
      .unwrap()
      .then(() => reFetchCalendarEvents());
  };

  const caregiversList = useMemo(
    () =>
      caregivers
        ?.map(({ firstName, id, lastName }) => ({
          id,
          name: `${firstName} ${lastName}`,
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter(
          (caregiver) =>
            caregiver.id !== temporaryVisitData?.caregiver?.id &&
            caregiver.id !== temporaryVisitData?.preferredCaregiver?.id,
        ),
    [
      caregivers,
      temporaryVisitData?.caregiver?.id,
      temporaryVisitData?.preferredCaregiver?.id,
    ],
  );

  const selectCaregiver = (_, value) => {
    if (!value) {
      return;
    }
    const selectedCaregiver = caregivers.find(({ id }) => id === value?.id);
    updateTemporaryVisitData({
      shadowCaregiver: selectedCaregiver,
    });
    if (value?.id === temporaryVisitData?.caregiver?.id) {
      clearShadowCaregiverAvailabilityData();
    }
    if (caregiverHasBeenRechecked) {
      setAvailableToRecheck(true);
    }
  };

  const checkAvailability = () => {
    checkShadowCaregiverAvailability({
      caregiverId: temporaryVisitData?.shadowCaregiver?.id,
      constraints: {
        availabilityConstraint:
          temporaryVisitData?.shadowCaregiver?.hasAnyAvailability,
        blockedCaregiverConstraint: true,
        includeOneTimeAvailability:
          temporaryVisitData?.shadowCaregiver?.hasAnyAvailability,
        teamConstraint: true,
      },
      visitId: temporaryVisitData?.id,
    });
    if (!caregiverHasBeenRechecked) {
      setCaregiverHasBeenRechecked(true);
    }
    if (availableToRecheck) {
      setAvailableToRecheck(false);
    }
  };

  const cancelPrimaryCaregiverEditing = () => {
    setCaregiverHasBeenRechecked(false);
    cancelEditing();
    clearShadowCaregiverAvailabilityData();
    updateTemporaryVisitData({
      shadowCaregiver: initialVisitData?.shadowCaregiver,
    });
  };

  const caregiverHasBeenChanged = useMemo(
    () =>
      initialVisitData?.shadowCaregiver?.id !==
      temporaryVisitData?.shadowCaregiver?.id,
    [initialVisitData, temporaryVisitData],
  );

  const caregiverName = temporaryVisitData?.shadowCaregiver
    ? `${temporaryVisitData?.shadowCaregiver.firstName} ${temporaryVisitData?.shadowCaregiver.lastName}`
    : '';

  const shadowCaregiverAvailabilityAlert = {
    success: {
      bgColor: COLORS.green[200],
      color: COLORS.green[600],
      icon: <img src={successIcon} alt="success icon" />,
      title: `Visit can be rescheduled. New shadow caregiver: ${caregiverName}`,
      type: 'success',
    },
    error: {
      bgColor: COLORS.red[200],
      color: COLORS.red[600],
      icon: <img src={errorIcon} alt="error icon" />,
      title: `Visit cannot be rescheduled. New shadow caregiver ${caregiverName} cannot be assigned.`,
      type: 'error',
    },
  };
  return (
    <Box sx={editPreferredCaregiverWrapperStyles}>
      {isCaregiversListLoading && <Loader />}
      {checkShadowCaregiverAvailabilityData && (
        <ColumnAlert
          alertData={
            shadowCaregiverAvailabilityAlert[
              checkShadowCaregiverAvailabilityData?.visitAvailable
                ? 'success'
                : 'error'
            ]
          }
        />
      )}
      <Box sx={{ display: 'flex', width: '100%', gap: '12px' }}>
        <CustomAutocomplete
          id="caregiver"
          changeValue={selectCaregiver}
          optionsList={caregiversList}
          label="Shadow Caregiver"
          selectedValue={caregiverName}
        />
        <SetupActions
          applyShadowCaregiver={onApplyShadowCaregiver}
          availableToRecheck={availableToRecheck}
          caregiverHasBeenChanged={caregiverHasBeenChanged}
          caregiverHasBeenRechecked={caregiverHasBeenRechecked}
          checkAvailability={checkAvailability}
          disableToApply={!checkShadowCaregiverAvailabilityData?.visitAvailable}
          onCancel={cancelPrimaryCaregiverEditing}
        />
      </Box>
    </Box>
  );
}

VisitShadowCaregiverSetup.propTypes = {
  applyShadowCaregiver: func,
  cancelEditing: func,
  checkShadowCaregiverAvailability: func,
  checkShadowCaregiverAvailabilityData: instanceOf(Object),
  clearShadowCaregiverAvailabilityData: func,
};
