import { useGridApiRef } from '@mui/x-data-grid';
import { instanceOf } from 'prop-types';
import React from 'react';
import { getNoData } from '../../../../../shared/utils/common';
import { DeletedShadowVisitsAccordion } from '../../../../../components/AffectedVisitsAccordion';
import CustomTable from '../../../../../components/Table';
import { reschedulingVisitColumns } from '../constants';
import { CustomTableStyles } from '../styles';

export default function ShadowVisitsAccordionWrapper({ shadowVisits = [] }) {
  const apiRef = useGridApiRef();
  return (
    <DeletedShadowVisitsAccordion
      title="Shadow Visits to Be Deleted"
      itemsCount={shadowVisits?.length}
    >
      <CustomTable
        apiRef={apiRef}
        columns={reschedulingVisitColumns({ isMainVisit: false })}
        customStyles={CustomTableStyles}
        headerHeight={44}
        noRowsOverlay={() =>
          getNoData({ title: 'Have no deleted shadow visits' })
        }
        rowHeight={107}
        rows={shadowVisits}
        withoutPagination
      />
    </DeletedShadowVisitsAccordion>
  );
}

ShadowVisitsAccordionWrapper.propTypes = {
  shadowVisits: instanceOf(Array),
};
