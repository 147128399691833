import { Box, Typography } from '@mui/material';
import { ClientProfilesContext } from '../../../../../pages/ClientDetails/context';
import React, { useContext } from 'react';
import { COLORS } from '../../../../../shared/styles/Theme';
import CustomSelect from '../../../../../components/Select';
import { additionalSelectStyles } from '../styles';

export default function BlockedCaregiversSelector() {
  const { clientProfile, editingProfileDataLibrary, onSelectChange } =
    useContext(ClientProfilesContext);
  return (
    <Box sx={{ maxWidth: '250px', minWidth: '250px' }}>
      {editingProfileDataLibrary.caregiversAvailableForBlackList.length ? (
        <CustomSelect
          addStyles={additionalSelectStyles}
          id="blockedCaregivers"
          placeHolder="Blocked caregivers"
          options={editingProfileDataLibrary?.caregiversAvailableForBlackList?.map(
            (option) => `${option.firstName} ${option.lastName}`,
          )}
          isLoading={false}
          isMultiselect
          selectedValue={
            Array.isArray(clientProfile?.blockedCaregivers)
              ? clientProfile?.blockedCaregivers?.map(
                  (name) => `${name.firstName} ${name.lastName}`,
                )
              : []
          }
          setValue={onSelectChange}
        />
      ) : (
        <Typography
          sx={{ color: COLORS.blue[500], fontStyle: 'italic' }}
        >{`No caregivers with a client team ${clientProfile?.team?.name}`}</Typography>
      )}
    </Box>
  );
}
