import { COLORS } from '../../../../../../../../shared/styles/Theme';

export const stepContentWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '1420px',
  width: '100%',
  marginBottom: '68px',
};

export const tasksManagementTableStyles = (isEmpty) => [
  {
    '& .MuiDataGrid-virtualScroller': {
      maxHeight: 'calc(100vh - 516px)',
      padding: isEmpty ? '32px 0' : '1px',
    },
    '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-cell': {
      padding: '26px 21px 24px 21px',
    },
    '& .MuiDataGrid-footerContainer': {
      margin: 0,
    },
  },
];

export const getTaskActionsStyles = (action) => ({
  width: '40px',
  height: '40px',
  border: `1px solid ${COLORS.blue[200]}`,
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: action.disabled ? '#ffffff' : COLORS.blue[50],
  },
  opacity: action.disabled ? 0.7 : 1,
  cursor: action.disabled ? 'default' : 'pointer',
});
