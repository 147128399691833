import { Box } from '@mui/material';
import { EditCareProgramContext } from '../../../../../../../../../../pages/ClientDetails/context';
import CrossedOutCell from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/CrossedOutCell';
import CareProgramTableDaysSchedule from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramTableDaysSchedule';
import { instanceOf } from 'prop-types';
import React, { useContext } from 'react';

export default function VisitDaySchedule({ row = {} }) {
  const { updatedCareProgram } = useContext(EditCareProgramContext);
  const parentVisit = updatedCareProgram.find(
    ({ visitId }) => row?.parentId && visitId === row?.parentId,
  );
  const scheduleHasBeenChanged =
    parentVisit && parentVisit?.days.toString() !== row?.days.toString();
  return row.parentId && scheduleHasBeenChanged ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        alignItems: 'center',
      }}
    >
      <CrossedOutCell>
        <CareProgramTableDaysSchedule
          selectedDays={parentVisit?.days}
          transparent
        />
      </CrossedOutCell>
      <CareProgramTableDaysSchedule selectedDays={row?.days} />
    </Box>
  ) : (
    <CareProgramTableDaysSchedule selectedDays={row?.days} />
  );
}

VisitDaySchedule.propTypes = {
  row: instanceOf(Object),
};
