import { Box, Typography } from '@mui/material';
import HolidayIcon from '../../../../../../../img/icons/holiday.svg';
import { string } from 'prop-types';
import React from 'react';
import { COLORS } from '../../../../../../../shared/styles/Theme';

export default function Holiday({ name = '' }) {
  return (
    <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
      <img className="info-icon" src={HolidayIcon} alt="" />
      <Typography
        sx={{
          color: COLORS.blue[900],
          display: 'table-cell',
          verticalAlign: 'middle',
        }}
      >
        {name}
      </Typography>
    </Box>
  );
}

Holiday.propTypes = {
  name: string,
};
