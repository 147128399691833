import { Box } from '@mui/material';
import moment from 'moment/moment';
import { func } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { H12_TIME_FORMAT } from '../../../../shared/constants';
import { VisitInfoTypeContext } from '../../../../shared/context';
import ErrorAlert from '../../../../components/ErrorAlert';
import { customDatePickerStyles } from '../../styles';
import ActualTimes from './ActualTimes';
import SetupActions from './SetupActions';
import { visitActualTimeWrapperStyles } from './styles';

export default function VisitActualTimesSetup({ cancelEditing = () => {} }) {
  const {
    initialVisitData,
    isRecheckAvailable,
    temporaryVisitData,
    updateTemporaryVisitData,
    onUpdateVisitDetails,
  } = useContext(VisitInfoTypeContext);
  const [error, setError] = useState(null);

  const verifyActualTime = useCallback(() => {
    if (
      !temporaryVisitData?.actualTimeEnd ||
      !temporaryVisitData?.actualTimeStart
    ) {
      return true;
    }
    const startTime = moment(
      temporaryVisitData?.actualTimeStart,
      H12_TIME_FORMAT,
    );
    const endTimeFormatted = moment(
      temporaryVisitData?.actualTimeEnd,
      H12_TIME_FORMAT,
    );
    if (startTime.isSameOrAfter(endTimeFormatted)) {
      setError('Actual time has wrong range. Please, input a right values.');
      return true;
    }
    return true;
  }, [temporaryVisitData?.actualTimeEnd, temporaryVisitData?.actualTimeStart]);

  useEffect(() => {
    verifyActualTime();
  }, [verifyActualTime]);

  const changeActualTimesRange = (data) => {
    updateTemporaryVisitData({
      actualTimeEnd: data.newEndTime,
      actualTimeStart: data.newStartTime,
      actualDuration:
        data.newStartTime && data.newEndTime
          ? moment(data.newEndTime, H12_TIME_FORMAT).diff(
              moment(data.newStartTime, H12_TIME_FORMAT),
              'minutes',
            )
          : null,
    });
  };

  const closeDateEditing = () => {
    cancelEditing();
    updateTemporaryVisitData({
      actualTimeEnd: initialVisitData.actualTimeEnd,
      actualTimeStart: initialVisitData.actualTimeStart,
      actualDuration: initialVisitData.actualDuration,
    });
  };

  const updateActualTime = () => {
    cancelEditing();
    onUpdateVisitDetails();
  };

  return (
    <Box sx={visitActualTimeWrapperStyles}>
      <Box sx={{ display: 'flex', width: '100%', mt: '12px', gap: '16px' }}>
        <ActualTimes
          changeTimeRange={changeActualTimesRange}
          customStyles={customDatePickerStyles}
          disabledEndTime={!temporaryVisitData?.actualTimeEnd}
          endTimeLabel="Actual End Time"
          endTimeValue={temporaryVisitData?.actualTimeEnd}
          gap={16}
          setError={setError}
          startTimeLabel="Actual Start Time"
          startTimeValue={temporaryVisitData?.actualTimeStart}
        />
      </Box>
      {error && <ErrorAlert error={error} />}
      <SetupActions
        disableApply={!isRecheckAvailable || !!error}
        onCancel={closeDateEditing}
        updateVisit={updateActualTime}
      />
    </Box>
  );
}

VisitActualTimesSetup.propTypes = {
  cancelEditing: func,
};
