import { Box, Typography } from '@mui/material';
import { instanceOf } from 'prop-types';
import React from 'react';
import { COLORS } from '../../../../../../../../shared/styles/Theme';
import { createVisitSummaryFields } from '../constants';

export default function VisitSummary({ data = {} }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '28px',
      }}
    >
      {Object.values(createVisitSummaryFields)?.map((fieldsMap) => (
        <Box
          key={fieldsMap?.map((field) => field.name).toString()}
          sx={{
            display: 'flex',
            paddingRight: '70px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mr: '12px',
            }}
          >
            {fieldsMap?.map(({ name, key }) =>
              data[key] ? (
                <Typography
                  variant="body1"
                  key={name}
                  sx={{
                    color: COLORS.blue[500],
                    mb: '16px',
                    lineHeight: '20px',
                  }}
                >
                  {name}:
                </Typography>
              ) : null,
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {fieldsMap?.map(({ name, key }) =>
              data[key] ? (
                <Typography
                  key={name}
                  variant="body1"
                  sx={{
                    color: COLORS.blue[900],
                    mb: '16px',
                    lineHeight: '20px',
                  }}
                >
                  {data[key] || ''}
                </Typography>
              ) : null,
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

VisitSummary.propTypes = {
  data: instanceOf(Object),
};
