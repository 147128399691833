import { Box, Typography } from '@mui/material';
import { ScheduleCaregiverManagementContext } from '../../../../../../../../pages/Scheduling/context';
import { instanceOf } from 'prop-types';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { COLORS } from '../../../../../../../../shared/styles/Theme';
import CustomDialog from '../../../../../../../../components/Dialog';
import UnsavedChangesContent from '../../../../../../../../components/Dialog/UnsavedChangesContent';
import { customCellWrapper } from './styles';

export default function CaregiversCell({ row = {} }) {
  const navigate = useNavigate();
  const { closeCallOutCreation } = useContext(
    ScheduleCaregiverManagementContext,
  );
  const [showConformation, setShowConformation] = useState(false);
  const [navigationUrl, setNavigationUrl] = useState('');

  const initialCaregiver = row?.oldCaregiver
    ? `${row?.oldCaregiver?.firstName} ${row?.oldCaregiver?.lastName}`
    : null;
  const newCaregiver = row?.newCaregiver
    ? `${row?.newCaregiver?.firstName} ${row?.newCaregiver?.lastName}`
    : null;
  const onLinkClick = (url) => {
    setNavigationUrl(url);
    setShowConformation(true);
  };
  const closeCreation = () => {
    closeCallOutCreation();
    navigate(navigationUrl);
    setShowConformation(null);
    setNavigationUrl('');
  };
  return (
    <div>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setShowConformation(false)}
        submitButtonName="confirm"
        submitCallback={closeCreation}
        title="Confirmation"
        openDialog={showConformation}
      >
        <UnsavedChangesContent text="Callout creation will be lost. Do you want to proceed?" />
      </CustomDialog>
      <Box sx={customCellWrapper}>
        <Typography
          component="span"
          onClick={() => onLinkClick(`/caregivers/${row?.oldCaregiver?.id}`)}
          sx={{
            textDecoration: newCaregiver ? 'line-through' : 'none',
            color: COLORS.blue[700],
            cursor: 'pointer',
          }}
        >
          {initialCaregiver || ''}
        </Typography>
        {newCaregiver && (
          <Typography
            component="span"
            onClick={() => onLinkClick(`/caregivers/${row?.newCaregiver?.id}`)}
            sx={{
              fontWeight: 600,
              color: COLORS.blue[700],
              cursor: 'pointer',
            }}
          >
            {newCaregiver || ''}
          </Typography>
        )}
      </Box>
    </div>
  );
}

CaregiversCell.propTypes = {
  row: instanceOf(Object),
};
