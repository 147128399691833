import { COLORS } from '../../../../../../../shared/styles/Theme';

export const createOneTimeVisitMultistepWrapper = {
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 60px',
  minWidth: '1420px',
};

export const createOneTimeVisitMultistepHeader = {
  display: 'flex',
  justifyContent: 'space-between',
  mb: '50px',
};

export const visitParametersWrapper = {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '520px',
  width: '100%',
  margin: 'auto',
};

export const timeInputStyles = [
  {
    '& .MuiFilledInput-root': {
      padding: '12px',
      paddingLeft: '16px',
      display: 'flex',
      alignItems: 'center',
    },
  },
];

export const selectVisitTypeStyles = {
  maxWidth: '250px',
  marginRight: '20px',
  '& .MuiSelect-select': {
    height: '40px !important',
  },
};

export const customAccordionStyles = {
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 0,
  },
  '&:last-of-type, &.Mui-expanded:last-of-type': {
    borderRadius: 0,
  },
  borderBottom: `1px solid ${COLORS.blue[200]}`,
  borderRadius: 0,
};

export const customAccordionSummaryStyles = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row-reverse',
  padding: 0,
  cursor: 'default',
  margin: '16px 0',
  '&.MuiAccordionSummary-root, &.MuiAccordionSummary-root.Mui-expanded': {
    minHeight: '48px',
  },
  '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded':
    {
      justifyContent: 'space-between',
      margin: 0,
      paddingRight: '20px',
      cursor: 'default',
    },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
};

export const visitAccordionSummaryTitle = {
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  ml: '5px',
};

export const checkAvailabilityColumnsWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '40px',
  maxWidth: '1080px',
  width: '100%',
  margin: 'auto',
};

export const checkAvailabilityCustomAlert = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '10px',
  mb: '16px',
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '16px',
};

export const avatarGroupStyles = {
  justifyContent: 'flex-end',
  '& .MuiAvatar-root': {
    width: '40px',
    height: '40px',
    fontSize: '13px',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '0.06em',
    '&:last-of-type': {
      marginLeft: 0,
    },
  },
};

export const visitAccordionIconButtonStyles = {
  border: `1px solid ${COLORS.blue[200]}`,
  borderRadius: '10px',
  '&.MuiIconButton-root.Mui-disabled': {
    cursor: 'default',
    '& img': {
      opacity: 0.5,
    },
  },
  '&:hover': { backgroundColor: COLORS.blue[50] },
};
