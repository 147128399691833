import moment from 'moment/moment';
import {
  H12_TIME_FORMAT,
  ISO_DATE_ONLY_FORMAT,
  SHORT_DATE_FORMAT,
} from '../../../../shared/constants';

const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const getBadgeLabel = (dateStart) => {
  if (dateStart) {
    return `${
      moment(dateStart, ISO_DATE_ONLY_FORMAT).isSameOrBefore(moment())
        ? 'Started'
        : 'Starting'
    } ${moment(dateStart, ISO_DATE_ONLY_FORMAT).format(SHORT_DATE_FORMAT)}`;
  }
  return `${'Starting'} ${moment().format(SHORT_DATE_FORMAT)}`;
};

// to send data to backend
export const transformAvailabilityData = (data) => {
  const transformedData = {};

  data?.forEach((row) => {
    const { workingDays, startTime, endTime } = row;
    const formattedStartTime = startTime
      ? moment(startTime, 'h:mmA').format(H12_TIME_FORMAT)
      : null;
    const formattedEndTime = endTime
      ? moment(endTime, 'h:mmA').format(H12_TIME_FORMAT)
      : null;

    if (formattedStartTime !== null || formattedEndTime !== null) {
      transformedData[workingDays] = {
        startTime: formattedStartTime,
        endTime: formattedEndTime,
      };
    }
  });

  return transformedData;
};
export const transformBackendAvailabilityData = (data) => {
  const transformedData = [];
  if (
    !data ||
    (data.length === 2 && data[0].effectiveDateEnd && data[1].effectiveDateEnd)
  ) {
    return [
      {
        days: daysOfWeek?.map((day, ind) => ({
          id: ind + 1,
          workingDays: day,
          startTime: null,
          endTime: null,
        })),
      },
    ];
  }
  if (data.length === 1 && data[0].effectiveDateEnd) {
    return [
      {
        days: daysOfWeek?.map((day, ind) => ({
          id: ind + 1,
          workingDays: day,
          startTime: null,
          endTime: null,
        })),
      },
    ];
  }
  data?.forEach((availabilityData) => {
    const workingDays = availabilityData?.workingDays || {};
    const effectiveDateStart = availabilityData?.effectiveDateStart || null;
    const effectiveDateEnd = availabilityData?.effectiveDateEnd || null;
    const daysData = [];
    daysOfWeek.forEach((day, ind) => {
      daysData.push({
        id: ind + 1,
        workingDays: day,
        startTime: workingDays[day]?.startTime || null,
        endTime: workingDays[day]?.endTime || null,
      });
    });

    transformedData.push({
      effectiveDate: effectiveDateStart,
      effectiveDateEnd,
      days: daysData,
    });
  });

  return transformedData?.sort((a, b) => {
    const dateA = new Date(a.effectiveDate);
    const dateB = new Date(b.effectiveDate);
    return dateA - dateB;
  });
};

// for show data from backend
export const transformAvailabilityRows = (data) =>
  daysOfWeek?.map((day, index) => {
    const workingDayData = data?.workingDays[day] || {};
    const startTime = workingDayData?.startTime || null;
    const endTime = workingDayData?.endTime || null;

    return {
      id: index + 1,
      workingDays: day,
      startTime,
      endTime,
    };
  });
