import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import { bool, func, instanceOf, number } from 'prop-types';
import React, { useState } from 'react';
import { COLORS } from '../../../shared/styles/Theme';
import LocationsTree from './LocationsTree';
import { dialogContentStyles } from './styles';

export default function LocationsModal({
  allTeamCount = 0,
  closeDialog = () => {},
  filters = {},
  isCaregiverOrClient = false,
  locationsList = [],
  openFilter = false,
  setFilters = () => {},
  setFiltersValue = () => {},
}) {
  const [selectedLocations, setSelectedLocations] = useState(filters.teams);
  const onCloseDialog = () => {
    setSelectedLocations(filters.teams);
    closeDialog();
  };
  const applyLocations = () => {
    setFiltersValue(selectedLocations);
    setFilters({ ...filters, teams: selectedLocations });
    closeDialog();
  };
  const onlySelectedTeams = selectedLocations?.filter((team) => team.territory);
  return (
    <Dialog open={openFilter} onClose={onCloseDialog}>
      <DialogContent sx={dialogContentStyles}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '24px',
          }}
        >
          <Typography variant="h4">Filtering by Location</Typography>
          <IconButton
            onClick={onCloseDialog}
            sx={{ marginRight: '-8px', marginTop: '-8px' }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <LocationsTree
          actualSelected={filters.teams}
          allTeamCount={allTeamCount}
          isCaregiverOrClient={isCaregiverOrClient}
          locationsList={locationsList}
          selectedLocations={selectedLocations}
          setSelectedLocations={setSelectedLocations}
        />
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-between',
          padding: '12px 20px',
          borderTop: `1px solid ${COLORS.blue[200]}`,
        }}
      >
        {selectedLocations?.length ? (
          <Typography variant="body2">
            {`${onlySelectedTeams?.length} / ${allTeamCount} Teams`}
          </Typography>
        ) : (
          <div />
        )}
        <Box sx={{ display: 'flex', gap: '12px' }}>
          <Button
            color="secondary"
            variant="outlined"
            onClick={onCloseDialog}
            disableElevation
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={applyLocations}
            disableElevation
          >
            Apply
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

LocationsModal.propTypes = {
  allTeamCount: number,
  closeDialog: func,
  filters: instanceOf(Object),
  isCaregiverOrClient: bool,
  locationsList: instanceOf(Array),
  openFilter: bool,
  setFilters: func,
  setFiltersValue: func,
};
