import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import moment from 'moment';
import { EditCareProgramContext } from '../../../../../../../../pages/ClientDetails/context';
import { UPDATE_PROGRAM_POPUPS } from '../../../../../../../../pages/ClientDetails/views/CareProgram/constants';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ISO_DATE_ONLY_FORMAT } from '../../../../../../../../shared/constants';
import CustomDialog from '../../../../../../../../components/Dialog';
import UnsavedChangesContent from '../../../../../../../../components/Dialog/UnsavedChangesContent';
import FullWidthDialog from '../../../../../../../../components/FullWidthDialog';
import { checkIsDataHasBeenChanged } from '../../utils';
import CareProgramSummary from '../CreateCareProgramVisit/CreateVisitMultistep/CareProgramSummary';
import CareProgramEditMode from './CareProgramEditMode';
import { customDialogButtonStyles } from './style';

export default function UpdateCareProgramDialog() {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const {
    closeDialog,
    error,
    initialCareProgramData,
    innerDialogType,
    saveCareProgramData,
    showAddNewProgramVisit,
    showProgramVisitsSummary,
    updatedCareProgram,
  } = useContext(EditCareProgramContext);

  const hasNoUnsavedChanges = useCallback(
    () =>
      !checkIsDataHasBeenChanged({
        initialData: initialCareProgramData?.careProgram,
        updatedData: updatedCareProgram,
        error,
      }),
    [error, initialCareProgramData?.careProgram, updatedCareProgram],
  );

  const hasFeaturedVisits = useMemo(
    () =>
      updatedCareProgram.some(
        ({ effectiveDateStart, effectiveDateEnd }) =>
          moment(effectiveDateStart, ISO_DATE_ONLY_FORMAT).isAfter(moment()) ||
          moment(effectiveDateEnd, ISO_DATE_ONLY_FORMAT).isAfter(moment()),
      ),
    [updatedCareProgram],
  );

  const closeEditing = () => {
    if (hasNoUnsavedChanges() && !!Object.keys(error).length) {
      closeDialog();
    } else {
      setOpenConfirmationModal(true);
    }
  };

  const getCustomButton = useMemo(
    () => (
      <Button
        color="secondary"
        variant="outlined"
        onClick={showAddNewProgramVisit}
        startIcon={<AddIcon />}
        disableElevation
        sx={customDialogButtonStyles}
      >
        Add visit
      </Button>
    ),
    [showAddNewProgramVisit],
  );
  return (
    <>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setOpenConfirmationModal(false)}
        submitButtonName="confirm"
        submitCallback={closeDialog}
        title="Confirmation"
        openDialog={openConfirmationModal}
      >
        <UnsavedChangesContent />
      </CustomDialog>
      <FullWidthDialog
        cancelButtonName="cancel"
        cancelCallback={closeEditing}
        customTitleButton={getCustomButton}
        disabledSubmit={
          !hasFeaturedVisits ||
          hasNoUnsavedChanges() ||
          !!Object.keys(error).length
        }
        hideBackButton
        submitButtonName="Review changes"
        submitCallback={showProgramVisitsSummary}
        title="Edit Mode"
        openDialog={innerDialogType === UPDATE_PROGRAM_POPUPS.programView}
      >
        <CareProgramEditMode />
      </FullWidthDialog>
      <FullWidthDialog
        cancelButtonName="cancel"
        cancelCallback={closeEditing}
        hideBackButton
        submitButtonName="Save changes"
        submitCallback={saveCareProgramData}
        title="Review & Confirm Changes"
        openDialog={
          innerDialogType === UPDATE_PROGRAM_POPUPS.careProgramSummary
        }
      >
        <CareProgramSummary />
      </FullWidthDialog>
    </>
  );
}
