/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumbs, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { useGetCaregiverInfoQuery } from '../../../../../../api/Caregivers/api';
import { bool, func } from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { COLORS } from '../../../../../../shared/styles/Theme';
import AlertContext from '../../../../../../components/Alert';

export default function AvailabilityBreadcrumbs({
  closeDialog = () => {},
  isLoading = false,
}) {
  const { setAlert } = useContext(AlertContext);
  const { id } = useParams();
  const { data: userData, error } = useGetCaregiverInfoQuery(id);
  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);
  const userName = () => `${userData?.firstName} ${userData?.lastName}`;

  const moveToProfile = () => {
    if (!isLoading) {
      closeDialog();
    }
  };

  return (
    <Breadcrumbs aria-label="breadcrumbs">
      <Link
        component={RouterLink}
        to={isLoading ? '' : '/caregivers'}
        underline="none"
      >
        <Typography sx={{ color: COLORS.blue[700] }} variant="body1">
          Caregivers
        </Typography>
      </Link>
      <Link
        component={RouterLink}
        to={isLoading ? '' : `/caregivers/${id}`}
        underline="none"
        onClick={moveToProfile}
      >
        <Typography sx={{ color: COLORS.blue[700] }} variant="body1">
          {userName()}
        </Typography>
      </Link>
      <Typography sx={{ color: COLORS.blue[900] }} variant="body1">
        Change Caregiver’s Team
      </Typography>
    </Breadcrumbs>
  );
}

AvailabilityBreadcrumbs.propTypes = {
  closeDialog: func,
  isLoading: bool,
};
