import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { TabList } from '@mui/lab';
import { Box, IconButton, Tab, Typography } from '@mui/material';
import { useGetCaregiverInfoQuery } from '../../../../api/Caregivers/api';
import { bool } from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import AlertContext from '../../../../components/Alert';
import {
  CAREGIVERS_TABS_SUBVIEW_NAMES_MAP,
  CAREGIVER_DETAILS_TABS,
} from '../../constants';
import { TitleBackButtonStyles, TitleWrapper } from './styles';

export default function Title({ disableTabs = false }) {
  const { setAlert } = useContext(AlertContext);
  const { id } = useParams();
  const { data: profileData, isLoading, error } = useGetCaregiverInfoQuery(id);
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);

  const changeTab = (_, newValue) => {
    const tabSubViewName = CAREGIVERS_TABS_SUBVIEW_NAMES_MAP[newValue];
    navigate(
      tabSubViewName
        ? `/caregivers/${id}/${newValue}/${tabSubViewName}`
        : `/caregivers/${id}/${newValue}`,
    );
  };

  return (
    <Box sx={TitleWrapper}>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <IconButton
          aria-label="back"
          disabled={disableTabs || isLoading}
          onClick={() => navigate(-1)}
          sx={TitleBackButtonStyles}
        >
          <NavigateBeforeIcon sx={{ fontSize: '20px' }} />
        </IconButton>
        {!isLoading && (
          <Typography variant="h1">
            {profileData
              ? `${profileData?.firstName} ${profileData?.lastName}`
              : `Caregiver hasn't been found`}
          </Typography>
        )}
      </Box>
      <TabList
        onChange={changeTab}
        sx={{
          display: 'flex',
          '& .MuiTabs-flexContainer': { gap: '24px' },
        }}
      >
        {CAREGIVER_DETAILS_TABS.map(({ key, title }) => (
          <Tab
            disabled={disableTabs || !profileData || isLoading}
            disableRipple
            key={`${title}-${key}`}
            label={title}
            value={key}
          />
        ))}
      </TabList>
    </Box>
  );
}

Title.propTypes = {
  disableTabs: bool,
};
