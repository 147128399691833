export const minHour = (events) =>
  events?.reduce((min, item) => {
    const arrivalStart = item?.arrivalTimeStart?.toString();
    const timeParts = arrivalStart?.split(' ');
    const hours = parseInt(timeParts[4], 10);
    const isPM = timeParts[1].includes('PM');
    const hours24 = isPM ? hours + 12 : hours;
    return Math.min(min, hours24);
  }, 24);

export const maxHour = (events) =>
  events?.reduce((max, item) => {
    const arrivalEnd = item?.end?.toString();
    const timeParts = arrivalEnd?.split(' ');
    const hours = parseInt(timeParts[4], 10);
    const isPM = timeParts[1].includes('PM');
    const hours24 = isPM ? hours + 12 : hours;
    const duration = parseInt(item.duration, 10);
    const maxWithDuration = hours24 + Math.floor(duration / 60);
    return Math.max(max, maxWithDuration);
  }, 0);

export const checkIsCreateVisitStepConfirmButtonDisabled = (data) => {
  const {
    availabilityScheduling,
    isSelectedTimeOutOfRange,
    pastTimeError,
    step,
    stepDataLoading,
    visitData,
  } = data;
  if (!visitData) {
    return false;
  }
  if (step === 0) {
    const {
      arrivalEnd,
      arrivalStart,
      dates,
      duration,
      exactTimeSlot,
      visitType,
    } = visitData || {};
    return (
      !(
        !!(arrivalStart?.length && arrivalEnd?.length) ||
        (!!exactTimeSlot?.length && exactTimeSlot !== 'Invalid date')
      ) ||
      !dates.length ||
      !duration ||
      !visitType ||
      isSelectedTimeOutOfRange ||
      !!pastTimeError
    );
  }
  if (step === 1) {
    return (
      !availabilityScheduling?.availableSchedule.length ||
      !!availabilityScheduling?.unAvailableSchedule?.length ||
      stepDataLoading
    );
  }
  return false;
};
