import { Box } from '@mui/material';
import { instanceOf } from 'prop-types';
import React from 'react';
import CarePlanHistoryCommon from './CarePlanHistoryCommon';
import {
  findNewestEffectiveDate,
  formattedSortedGroupedDays,
  groupByDay,
  identifyUIRenderType,
  sortDaysByWeekOrder,
} from './constants';

const getHistoryList = (arr) => {
  const groupedDays = groupByDay(arr);
  const sortedGroupedDays = sortDaysByWeekOrder(groupedDays);
  const formattedGroupedDays = formattedSortedGroupedDays(sortedGroupedDays);

  const availabilityEffectiveDate = findNewestEffectiveDate(arr);
  const availabilityUpdated = identifyUIRenderType(arr);
  return (
    <CarePlanHistoryCommon
      availabilityEffectiveDate={availabilityEffectiveDate}
      availabilityUpdated={availabilityUpdated}
      sortedGroupedDays={formattedGroupedDays}
    />
  );
};

export default function HistoryBulletList({ changes = [] }) {
  return <Box sx={{ paddingLeft: '20px' }}>{getHistoryList(changes)}</Box>;
}

HistoryBulletList.propTypes = {
  changes: instanceOf(Array),
};
