import { Box, Typography } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import hardConstraintsWarning from '../../../../../../../../img/icons/error-icon.svg';
import { instanceOf } from 'prop-types';
import React, { useCallback } from 'react';
import { getNoData } from '../../../../../../../../shared/utils/common';
import { v4 as uuidv4 } from 'uuid';
import CustomTable from '../../../../../../../../components/Table';
import { calloutPossibilityColumns } from './constants';
import { customVisitsTableStyles, notAllocatedTitleStyles } from './styles';

function PossibilityVisitsTable({ dateVisits = [], notAllocatedData = [] }) {
  const apiRef = useGridApiRef();
  const getRowId = useCallback((row) => `${row.visitType} - ${uuidv4()}`, []);
  const notAllocatedVisits = notAllocatedData.filter(
    (t) => t?.status?.status === 'error',
  );
  return (
    <Box>
      <Typography variant="h6" sx={{ mb: '16px' }}>
        First Visit Changes
      </Typography>
      <CustomTable
        apiRef={apiRef}
        columns={calloutPossibilityColumns(false)}
        customStyles={customVisitsTableStyles}
        customRowClassName="super-app-theme"
        getRowClassName={(params) =>
          params.row.shiftedBy ? 'shifted-visit' : ''
        }
        noRowsOverlay={() =>
          getNoData({
            title:
              'There are no caregivers whose first visits changed this day',
          })
        }
        getRowId={getRowId}
        headerHeight={44}
        rowHeight={107}
        rows={dateVisits}
        withoutPagination
      />
      <Box>
        <Box sx={notAllocatedTitleStyles}>
          <img src={hardConstraintsWarning} alt="hard constraints" />
          <Typography variant="h6">Not Allocated Visits</Typography>
        </Box>
        <CustomTable
          apiRef={apiRef}
          columns={calloutPossibilityColumns(true)}
          customStyles={customVisitsTableStyles}
          customRowClassName="super-app-theme"
          getRowClassName={(params) =>
            params.row.shiftedBy ? 'shifted-visit' : ''
          }
          noRowsOverlay={() =>
            getNoData({
              title: 'There are no Not Allocated  visits for this day ',
            })
          }
          getRowId={getRowId}
          headerHeight={44}
          rowHeight={107}
          rows={notAllocatedVisits}
          withoutPagination
        />
      </Box>
    </Box>
  );
}

PossibilityVisitsTable.propTypes = {
  dateVisits: instanceOf(Array),
  notAllocatedData: instanceOf(Array),
};

export default React.memo(PossibilityVisitsTable);
