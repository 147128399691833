import { Box, Typography } from '@mui/material';
import carePlanIcon from '../../../../../../../../../../img/icons/visitTypes/repeatedVisit.svg';
import React from 'react';
import { tasksTypeIcons } from '../../../../../../../../../../shared/constants';
import CustomTooltip from '../../../../../../../../../../components/CustomTooltip';
import TaskActions from './TaskActions';

export const getEditColumns = (day) => [
  {
    field: 'name',
    headerName: 'Task',
    minWidth: 220,
    flex: 1,
    renderCell: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '8px' }}>
        <CustomTooltip title="Care Plan visit">
          <img src={carePlanIcon} alt="" />
        </CustomTooltip>
        <CustomTooltip title={row?.name}>
          <Typography
            sx={{
              fontSize: '20px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              wordBreak: 'break-word',
            }}
            variant="h4"
          >
            {row.name}
          </Typography>
        </CustomTooltip>
      </Box>
    ),
    sortable: false,
  },
  {
    field: 'category',
    headerName: 'Category',
    minWidth: 220,
    sortable: false,
  },
  {
    field: 'details',
    headerName: 'Description',
    minWidth: 520,
    flex: 1,
    sortable: false,
  },
  {
    field: 'isCustom',
    headerName: 'Task Type',
    minWidth: 390,
    renderCell: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
        }}
      >
        <CustomTooltip
          title={row?.isCustom ? 'Custom task' : 'Care Program task'}
        >
          <Box
            component="img"
            src={tasksTypeIcons[row?.isCustom ? 'custom' : 'common']}
            sx={{ height: '20px', mt: '10px', mr: '170px' }}
            alt="img"
          />
        </CustomTooltip>
        <TaskActions day={day} row={row} />
      </Box>
    ),
    sortable: false,
  },
];
