import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { func, string } from 'prop-types';
import React, { useState } from 'react';
import { isValidEmail } from '../../../../../../shared/utils/common';
import ErrorAlert from '../../../../../../components/ErrorAlert';
import CustomInput from '../../../../../../components/Inputs';
import { customRadioStyles } from './style';

export default function AddUserModal({
  radioValue = '',
  setRadioValue = () => {},
  setUserEmail = () => {},
  userEmail = '',
}) {
  const handleChange = (event) => {
    setRadioValue(event.target.value);
  };

  const [error, setError] = useState(null);

  const handleChangeEmail = (event) => {
    setUserEmail(event.target.value);
    setError(null);
  };
  const handleBlurEmail = () => {
    if (!isValidEmail(userEmail)) {
      setError('Email is invalid');
    }
    if (userEmail?.length === 0) {
      setError(null);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        padding: '0 28px 28px 16px',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <CustomInput
        changeTaskName={handleChangeEmail}
        id="user"
        label="User"
        value={userEmail.trim()}
        onBlur={handleBlurEmail}
      />
      {error && <ErrorAlert error={error} />}
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={radioValue}
          onChange={handleChange}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            ...customRadioStyles,
          }}
        >
          <FormControlLabel value="ADMIN" control={<Radio />} label="Admin" />
          <FormControlLabel value="USER" control={<Radio />} label="User" />
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

AddUserModal.propTypes = {
  radioValue: string,
  setRadioValue: func,
  setUserEmail: func,
  userEmail: string,
};
