import { TabList } from '@mui/lab';
import { Box, Tab, Typography } from '@mui/material';
import { func } from 'prop-types';
import React from 'react';
import { REPORTING_TABS } from '../../constants';

export default function ReportingTitle({ handleChange = () => {} }) {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        mb: '32px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Typography variant="h1">Reporting</Typography>
      </Box>
      <TabList
        onChange={handleChange}
        sx={{
          display: 'flex',
          '& .MuiTabs-flexContainer': { gap: '24px' },
        }}
      >
        {REPORTING_TABS.map(({ key, title }) => (
          <Tab
            disableRipple
            key={`${title}-${key}`}
            label={title}
            value={key}
          />
        ))}
      </TabList>
    </Box>
  );
}

ReportingTitle.propTypes = {
  handleChange: func,
};
