/* eslint-disable react/prop-types */
import Button from '@mui/material/Button';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import * as React from 'react';
import { COLORS } from '../../../shared/styles/Theme';

function ButtonField(props) {
  const {
    setOpen,
    label,
    id,
    isDateHoliday,
    disabled,
    InputProps: { ref } = {},
    inputProps: { 'aria-label': ariaLabel } = {},
  } = props;
  return (
    <Button
      variant="outlined"
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      sx={{
        border: 'none',
        color: COLORS.blue[700],
        fontSize: isDateHoliday.id ? '14px' : '16px',
        fontWeight: 700,
        paddingRight: '0px !important',
        paddingLeft: '0px !important',
        borderRadius: 0,
        borderBottom: `1px solid  ${COLORS.blue[700]} !important`,
        borderBottomStyle: 'dashed !important',
        maxHeight: '20px',
        alignSelf: 'center',
        '&:hover': {
          border: 'none',
          backgroundColor: 'transparent',
        },
      }}
      onClick={() => setOpen?.((prev) => !prev)}
    >
      {label ?? 'Pick a date'}
    </Button>
  );
}

function ButtonDatePicker({ label, value, onChange, slots, isDateHoliday }) {
  const [open, setOpen] = React.useState(false);
  const maxAvailableDate = moment().startOf('week').add(13, 'days');
  const buttonFieldComponent = (props) => (
    <ButtonField
      label={label}
      value={value}
      isDateHoliday={isDateHoliday}
      {...props}
    />
  );
  return (
    <DatePicker
      // eslint-disable-next-line react/destructuring-assignment
      slots={{
        field: (props) => buttonFieldComponent(props),
        ...slots,
      }}
      label={label}
      value={value}
      slotProps={{ field: { setOpen } }}
      onChange={onChange}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      // disablePast
      maxDate={maxAvailableDate}
    />
  );
}

export default function PickerWithButtonField({
  isDateHoliday,
  label,
  onDateChange,
  setValue,
  value,
}) {
  const handleChange = (newValue) => {
    setValue(newValue);
    onDateChange(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ButtonDatePicker
        label={label}
        value={value}
        onChange={handleChange}
        isDateHoliday={isDateHoliday}
      />
    </LocalizationProvider>
  );
}
