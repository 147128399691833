import { Box, Typography } from '@mui/material';
import WarningIcon from '../../../../../../img/icons/warning-icon.svg';
import { func, instanceOf } from 'prop-types';
import React from 'react';
import { SHORT_DATE_FORMAT } from '../../../../../../shared/constants';
import { CustomDatePicker } from '../../../../../../components/CustomDateAndTimePickers';

export default function SelectEffectiveDate({
  date = {},
  selectDate = () => {},
}) {
  return (
    <Box sx={{ width: '100%', padding: '0 20px 28px 28px' }}>
      <Box sx={{ display: 'flex', gap: 1, mb: '20px' }}>
        <img src={WarningIcon} alt="Warning" />
        <Typography>
          All visits after {date.format(SHORT_DATE_FORMAT)} will be reallocated.
          This cannot be undone.
        </Typography>
      </Box>
      <CustomDatePicker
        customStyles={[{ maxWidth: '200px', height: '40px' }]}
        changeDate={selectDate}
        date={date}
        isDisablePast
        isDisabledToday
        label="Deactivation Effective Date"
      />
    </Box>
  );
}

SelectEffectiveDate.propTypes = {
  date: instanceOf(Object),
  selectDate: func,
};
