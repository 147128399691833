import { Tooltip } from '@mui/material';
import { instanceOf, node, oneOfType, string } from 'prop-types';
import React from 'react';
import { COLORS } from '../../shared/styles/Theme';

export default function CustomTooltip({
  children = {},
  customStyle = {},
  title = '',
  placement = 'bottom-end',
}) {
  return (
    <Tooltip
      key={title}
      componentsProps={{
        tooltip: {
          sx: {
            '&.MuiTooltip-tooltipPlacementBottom': {
              mt: '5px !important',
              mr: '25px !important',
            },
            padding: '2px 6px',
            backgroundColor: '#ffffff',
            boxShadow: '0px 0px 12px rgba(44, 45, 46, 0.16)',
            color: COLORS.black[700],
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16px',
            ...(customStyle || {}),
          },
        },
      }}
      placement={placement}
      title={title}
    >
      {children}
    </Tooltip>
  );
}

CustomTooltip.propTypes = {
  children: node,
  placement: string,
  customStyle: instanceOf(Object),
  title: oneOfType([string, instanceOf(Object)]),
};
