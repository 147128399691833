import { Dialog, DialogContent } from '@mui/material';
import { bool, element, func } from 'prop-types';
import React, { createElement } from 'react';
import { TransitionSlide } from '../../../../shared/constants';
import { visitInfoWrapper } from '../../styles';
import Actions from './Actions';
import Title from './Title';

export default function VisitInfoTypeDialog({
  cancelCallback = () => {},
  children = createElement('div'),
  isLoading = false,
  openDialog = false,
  openVisitDetailsDialog = () => {},
}) {
  return (
    <Dialog
      disableEscapeKeyDown
      keepMounted
      open={!!openDialog}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '680px',
        },
      }}
      TransitionComponent={TransitionSlide}
    >
      <Title closeDialog={cancelCallback} isLoading={isLoading} />
      <DialogContent sx={visitInfoWrapper}>{children}</DialogContent>
      <Actions openVisitDetailsDialog={openVisitDetailsDialog} />
    </Dialog>
  );
}

VisitInfoTypeDialog.propTypes = {
  cancelCallback: func,
  children: element,
  isLoading: bool,
  openDialog: bool,
  openVisitDetailsDialog: func,
};
