import { Alert } from '@mui/material';
import { instanceOf } from 'prop-types';
import React from 'react';

export default function ColumnAlert({ alertData = {} }) {
  const { bgColor, color, icon, title, type } = alertData;
  return (
    <Alert
      icon={icon}
      severity={type}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: bgColor,
        borderRadius: '10px',
        mb: '16px',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '16px',
        color,
      }}
    >
      {title}
    </Alert>
  );
}

ColumnAlert.propTypes = {
  alertData: instanceOf(Object),
};
