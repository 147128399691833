import { useGetWeekendsSchedulingDaysQuery } from '../../../../api/Administration/api';
import { useGetFiltersDataQuery } from '../../../../api/commonApi/api';
import moment from 'moment';
import { bool, func, instanceOf, oneOfType } from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router';
import { H12_TIME_FORMAT } from '../../../../shared/constants';
import AlertContext from '../../../../components/Alert';
import CustomDialog from '../../../../components/Dialog';
import { AddTaskContext } from '../../context';
import Content from './components/Content';
import { noEmptyFields, taskInitialState } from './constants';

export default function AddTaskProvider({
  children = [],
  hasPredefinedData = false,
  tasksListData = {},
  updateTasksList = () => {},
}) {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [hasTheSameTask, setHasTheSameTask] = useState(false);
  const [taskData, setTaskData] = useState(taskInitialState);
  const { data: weekDaysSwitch, error: schemaError } =
    useGetWeekendsSchedulingDaysQuery();
  const { data: filtersData, error } = useGetFiltersDataQuery({
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    const errorData = error || schemaError;
    if (errorData) {
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [error, schemaError, setAlert]);

  const addNewTask = useCallback(
    (visit) => {
      let taskPredefinedData = {
        ...taskInitialState,
      };
      if (visit) {
        const day = visit.date ? moment(visit.date).format('dddd') : visit.day;
        const timeOfDay =
          visit.timeOfDay ||
          filtersData.timesOfDay.find(({ from, to }) =>
            moment(
              visit.exactTimeSlot || visit.arrivalStart,
              H12_TIME_FORMAT,
            ).isBetween(
              moment(from, H12_TIME_FORMAT),
              moment(to, H12_TIME_FORMAT),
              'minutes',
              '[]',
            ),
          )?.name;
        taskPredefinedData = {
          ...taskInitialState,
          days: [day],
          timesOfDay: timeOfDay ? [timeOfDay] : [],
          visitTypes: [visit.visitType],
        };
      }
      setOpenDialog(true);
      setTaskData(taskPredefinedData);
    },
    [filtersData],
  );

  const editTask = useCallback(
    (editedTaskId) => {
      const editableTask = tasksListData.tasks.find(
        (task) => task.id === editedTaskId,
      );
      setOpenDialog(true);
      setTaskData(editableTask);
    },
    [tasksListData?.tasks],
  );
  const filterDaysBySaAndSu = useMemo(() => {
    if (!filtersData?.weekdays) return [];
    return filtersData?.weekdays.filter(
      ({ name }) =>
        !(
          (name === 'Saturday' &&
            !weekDaysSwitch?.schema?.saturdaySwitchedOn) ||
          (name === 'Sunday' && !weekDaysSwitch?.schema?.sundaySwitchedOn)
        ),
    );
  }, [filtersData?.weekdays, weekDaysSwitch]);

  const applyTaskData = () => {
    const toSaveTaskData = {
      ...taskData,
      days: taskData.days.length
        ? taskData.days
        : filterDaysBySaAndSu.map(({ name }) => name),
      timesOfDay: taskData.timesOfDay.length
        ? taskData.timesOfDay
        : filtersData.timesOfDay.map(({ name }) => name),
      visitTypes: taskData.visitTypes.length
        ? taskData.visitTypes
        : filtersData.visitTypes.map(({ name }) => name),
    };
    const updatedTaskDataName = {
      ...toSaveTaskData,
      name: toSaveTaskData?.name?.trim(),
    };
    const newTasksList = updatedTaskDataName.id
      ? tasksListData?.tasks?.map((item) =>
          item.id === updatedTaskDataName.id ? updatedTaskDataName : item,
        )
      : [...tasksListData.tasks, updatedTaskDataName];

    updateTasksList({
      clientId: id,
      payload: {
        version: tasksListData?.version,
        tasks: newTasksList,
      },
    });
    setOpenDialog(false);
  };

  const changeTaskData = (data) => {
    if (hasTheSameTask) setHasTheSameTask(false);
    setTaskData(data);
  };

  const isDisabledSave = useMemo(() => {
    const hasEmptyFields = noEmptyFields.some(
      (fieldKey) => !taskData[fieldKey].toString().trim().length,
    );
    if (hasEmptyFields) return true;
    if (!hasEmptyFields && tasksListData?.tasks?.length) {
      const createdTasks = taskData?.id
        ? tasksListData?.tasks.filter(
            (taskObject) => taskObject.id !== taskData?.id,
          )
        : tasksListData?.tasks;
      const currentTaskData = { ...taskData };
      delete currentTaskData.id;
      const stringifiedTasksObjects = createdTasks.map((taskObject) => {
        const createdTaskObject = { ...taskObject };
        delete createdTaskObject.id;
        return JSON.stringify(
          createdTaskObject,
          Object.keys(createdTaskObject).sort(),
        );
      });
      const theSameTaskAlreadyCreated = stringifiedTasksObjects.includes(
        JSON.stringify(currentTaskData, Object.keys(currentTaskData).sort()),
      );
      setHasTheSameTask(theSameTaskAlreadyCreated);
      return theSameTaskAlreadyCreated;
    }
    if (taskData?.id) {
      const editableTask = tasksListData.tasks.find(
        (task) => task.id === taskData.id,
      );
      return JSON.stringify(editableTask) === JSON.stringify(taskData);
    }
    return false;
  }, [taskData, tasksListData]);

  const closeDialog = () => setOpenDialog(false);

  const memoizedProviderValue = useMemo(
    () => ({
      addNewTask,
      editTask,
    }),
    [addNewTask, editTask],
  );

  return (
    <AddTaskContext.Provider value={memoizedProviderValue}>
      {openDialog && (
        <CustomDialog
          cancelButtonName="Cancel"
          cancelCallback={closeDialog}
          disableOnCloseByClickOutside
          disabledSubmit={isDisabledSave}
          openDialog={openDialog}
          submitButtonName="Save"
          submitCallback={applyTaskData}
          title={taskData.id ? 'Edit Task' : 'Add Task'}
        >
          <Content
            disabledDays={weekDaysSwitch?.schema}
            filtersData={filtersData}
            hasPredefinedData={hasPredefinedData}
            hasTheSameTask={hasTheSameTask}
            setTaskData={changeTaskData}
            taskData={taskData}
          />
        </CustomDialog>
      )}
      {children}
    </AddTaskContext.Provider>
  );
}

AddTaskProvider.propTypes = {
  children: oneOfType([instanceOf(Array), instanceOf(Object)]),
  hasPredefinedData: bool,
  tasksListData: instanceOf(Object),
  updateTasksList: func,
};
