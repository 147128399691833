import { Box } from '@mui/material';
import { instanceOf } from 'prop-types';
import React from 'react';
import styles from './style.module.css';

const getHistoryList = (arr) => (
  <ul className={styles.bulletList}>
    {arr?.changes?.map((item, index) => {
      const key = `${item.operation}-${item.propertyName}-${item.id}${index}`;
      const objectDescription = JSON?.parse(arr?.objectDescription);
      if (item.operation === 'update') {
        return (
          <li
            key={key}
            className={
              item.to === ''
                ? `${styles.bulletListItem} ${styles.removedType}`
                : styles.bulletListItem
            }
          >
            {item.propertyName === 'role' ? 'User Role ' : 'User Name '}
            <span className={styles.boldWeight}>
              {objectDescription?.email}
            </span>
            {item.to !== '' ? (
              <span>
                is
                <span className={styles.boldWeight}>{item.visitType}</span>
                changed from
                <span className={styles.oldTime}>{item.from}</span> to
                <span className={styles.newTime}> {item.to}</span>
              </span>
            ) : (
              <span> was removed</span>
            )}
          </li>
        );
      }
      if (item.operation === 'remove') {
        return (
          <li
            key={key}
            className={`${styles.bulletListItem} ${styles.removedType}`}
          >
            User Removed:
            <span className={styles.boldWeight}>{item.nameFrom}</span>(
            {item.from})
          </li>
        );
      }
      if (item.operation === 'insert') {
        return (
          <li
            key={key}
            className={`${styles.bulletListItem} ${styles.addedType}`}
          >
            User Added:
            <span className={styles.boldWeight}>{item.nameTo}</span>({item.to})
          </li>
        );
      }
      return false;
    })}
  </ul>
);

export default function UsersHistoryBullet({ changes = [] }) {
  return <Box sx={{ paddingLeft: '20px' }}>{getHistoryList(changes)}</Box>;
}

UsersHistoryBullet.propTypes = {
  changes: instanceOf(Object),
};
