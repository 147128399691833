/* eslint-disable react/prop-types */
import moment from 'moment';
import 'moment-timezone';
import { bool, instanceOf, number } from 'prop-types';
import React from 'react';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { CALIFORNIA_TIME_ZONE } from '../../shared/constants';
// import { CALIFORNIA_TIME_ZONE } from '../../shared/constants';
import './index.css';

const localizer = momentLocalizer(moment);
const now = new Date();
function DnDCalendars({
  events = [],
  toolbar = false,
  firstVisit = 0,
  lastVisit = 0,
  // need to be added props
  eventComponent,
  className,
  customToolbar,
  date,
  dateHeaderWrapper,
  dayPropGetter,
  eventDrop,
  eventStyleGetter,
  isDateHoliday,
  onDragOver,
  onDragStart,
  onNavigate,
  onSelectEvent,
  resourceAccessor,
  resources,
  scrollTime,
  slotGroupPropGetter,
  slotPropGetter,
}) {
  const DnDCalendar = withDragAndDrop(Calendar);
  const minTime = new Date();
  minTime.setHours(firstVisit, 0, 0);

  const maxTime = new Date();
  maxTime.setHours(lastVisit, 59, 59);
  const timeFormats = {
    timeGutterFormat: 'h A',
    eventTimeRangeFormat: ({ start, end }) =>
      `${moment(start).format('h A')} - ${moment(end).format('h A')}`,
  };
  return (
    <DnDCalendar
      getNow={() =>
        moment(
          moment().tz(CALIFORNIA_TIME_ZONE).format('YYYY-MM-DDTHH:mm:ss'),
        ).toDate()
      }
      className={`Scheduling ${className}`}
      date={date}
      dayLayoutAlgorithm="no-overlap"
      dayPropGetter={dayPropGetter}
      defaultView={Views.DAY}
      draggableAccessor="isDraggable"
      eventPropGetter={eventStyleGetter}
      events={events}
      formats={timeFormats}
      isDateHoliday={isDateHoliday}
      localizer={localizer}
      max={maxTime}
      min={minTime}
      now={now}
      onDragOver={onDragOver}
      onDragStart={onDragStart}
      onEventDrop={eventDrop}
      onNavigate={onNavigate}
      onSelectEvent={onSelectEvent}
      resourceAccessor={resourceAccessor}
      resourceIdAccessor="resourceId"
      resourceTitleAccessor="resourceTitle"
      resources={resources}
      scrollToTime={scrollTime}
      slotGroupPropGetter={slotGroupPropGetter}
      slotPropGetter={slotPropGetter}
      step={5}
      timeslots={12}
      toolbar={toolbar}
      tooltipAccessor={() => ''}
      components={{
        event: eventComponent,
        toolbar: customToolbar,
        day: {
          header: dateHeaderWrapper,
        },
      }}
    />
  );
}

DnDCalendars.propTypes = {
  events: instanceOf(Array),
  toolbar: bool,
  firstVisit: number,
  lastVisit: number,
};
export default React.memo(DnDCalendars);
