import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryRealDB } from '../../api/baseQueryBE';
import { parseClientBlockedCaregiversResponse } from '../../pages/ClientDetails/utils';
import { parseVisitTime } from '../../shared/utils/common';

export const CLIENTS_API_KEY = 'clientsApi';

const clientsApi = createApi({
  reducerPath: CLIENTS_API_KEY,
  baseQuery: baseQueryRealDB,
  endpoints: (builder) => ({
    getClientsList: builder.query({
      query: () => ({
        url: '/clients/',
        method: 'GET',
      }),
    }),
    getAllCaregivers: builder.query({
      query: (params) => ({
        url: '/caregiver/list',
        method: 'GET',
        params: params || null,
      }),
    }),
    getClientActivity: builder.query({
      query: ({ clientId }) => ({
        url: `activity/log/client/${clientId}`,
        method: 'GET',
      }),
    }),
    getUserDataById: builder.query({
      query: (id) => ({
        url: `/clients/${id}`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'clientsInfo', id: 'ALL' }],
    }),
    updateClientsInfo: builder.mutation({
      query: ({ clientId, payload }) => ({
        url: `/clients/${clientId}`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'clientsInfo', id: 'ALL' }],
    }),
    getClientsCalendar: builder.query({
      query: (params) => ({
        url: `/clients/${params.clientId}/calendar`,
        method: 'POST',
        body: { date: params.date },
      }),
      transformResponse: (response) => parseVisitTime(response),
    }),
    getClientsProfileHistory: builder.query({
      query: (id) => ({
        url: `/clients/${id}/history`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'clientsInfo', id: 'ALL' }],
    }),
    checkClientSuspend: builder.query({
      query: ({ clientId, payload }) => ({
        url: `/clients/${clientId}/suspend/check`,
        method: 'POST',
        body: payload,
      }),
    }),
    checkClientResume: builder.query({
      query: (clientId) => ({
        url: `/clients/${clientId}/resume/check`,
        method: 'POST',
      }),
    }),
    checkClientDeactivation: builder.query({
      query: ({ clientId, date }) => ({
        url: `/clients/${clientId}/deactivate/check?date=${date}`,
        method: 'POST',
      }),
    }),
    checkClientActivation: builder.query({
      query: (clientId) => ({
        url: `/clients/${clientId}/activate/check`,
        method: 'POST',
      }),
    }),
    checkClientBlockedCaregiversChanges: builder.query({
      query: ({ clientId, payload }) => ({
        url: `/clients/${clientId}/blockCaregiver/check`,
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response) =>
        parseClientBlockedCaregiversResponse(response),
    }),
    confirmClientSuspend: builder.mutation({
      query: ({ clientId, requestId }) => ({
        url: `/clients/${clientId}/suspend/confirm/${requestId}`,
        method: 'POST',
      }),
      invalidatesTags: () => [{ type: 'clientsInfo', id: 'ALL' }],
    }),
    confirmClientResume: builder.mutation({
      query: ({ clientId, requestId }) => ({
        url: `/clients/${clientId}/resume/confirm/${requestId}`,
        method: 'POST',
      }),
      invalidatesTags: () => [{ type: 'clientsInfo', id: 'ALL' }],
    }),
    confirmClientDeactivation: builder.mutation({
      query: ({ clientId, requestId }) => ({
        url: `/clients/${clientId}/deactivate/confirm/${requestId}`,
        method: 'POST',
      }),
      invalidatesTags: () => [{ type: 'clientsInfo', id: 'ALL' }],
    }),
    confirmClientActivation: builder.mutation({
      query: ({ clientId }) => ({
        url: `/clients/${clientId}/activate`,
        method: 'POST',
      }),
      invalidatesTags: () => [{ type: 'clientsInfo', id: 'ALL' }],
    }),
    confirmBlockedCaregivers: builder.mutation({
      query: ({ clientId, requestId }) => ({
        url: `/clients/${clientId}/blockCaregiver/${requestId}/approve`,
        method: 'POST',
      }),
      invalidatesTags: () => [{ type: 'clientsInfo', id: 'ALL' }],
    }),
  }),
});
export const {
  useGetClientsListQuery,
  useGetAllCaregiversQuery,
  useGetUserDataByIdQuery,
  useUpdateClientsInfoMutation,
  useGetClientsProfileHistoryQuery,
  useGetClientsCalendarQuery,
  useCheckClientSuspendQuery,
  useCheckClientResumeQuery,
  useCheckClientDeactivationQuery,
  useCheckClientActivationQuery,
  useCheckClientBlockedCaregiversChangesQuery,
  useConfirmClientSuspendMutation,
  useConfirmBlockedCaregiversMutation,
  useConfirmClientResumeMutation,
  useConfirmClientDeactivationMutation,
  useConfirmClientActivationMutation,
  useGetClientActivityQuery,
} = clientsApi;

export default clientsApi;
