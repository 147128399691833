import React from 'react';
import styles from '../../../../../../components/VisitInfoTypeProvider/components/VisitDetails/VisitHistory/HistoryBulletList/style.module.css';

const convertTitles = (str) =>
  str
    ?.split(/(?=[A-Z])/)
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(' ');

export const getUpdateOperationComponent = (item, key, propertyName) => {
  switch (propertyName) {
    case 'task':
      return (
        <li
          key={key}
          className={
            item.to === ''
              ? `${styles.bulletListItem} ${styles.removedType}`
              : styles.bulletListItem
          }
        >
          <span className={styles.boldWeight}>
            {convertTitles(item?.propertyName)}:
          </span>
          <span>{`${
            item?.additionalInfo?.taskName
          } ${item?.additionalInfo?.changedProperty.toLowerCase()} was changed from`}</span>
          <span className={styles.oldTime}>{item.from}</span> to
          <span className={styles.newTime}> {item.to}</span>
        </li>
      );

    case 'visitType':
      return (
        <li
          key={key}
          className={
            item.to === ''
              ? `${styles.bulletListItem} ${styles.removedType}`
              : styles.bulletListItem
          }
        >
          <span className={styles.boldWeight}>
            {convertTitles(item?.propertyName)}
          </span>
          {item.to !== '' ? (
            <span>
              <span>{` was changed from `}</span>
              <span className={styles.oldTime}>
                {`${item.to ? 'Arrival Window' : 'Exact Time'} `}
              </span>
              to
              <span className={styles.newTime}>
                {` ${item.to ? 'Exact Time' : 'Arrival Window'}`}
              </span>
            </span>
          ) : (
            <span> was removed</span>
          )}
        </li>
      );

    default:
      return (
        <li
          key={key}
          className={
            item.to === ''
              ? `${styles.bulletListItem} ${styles.removedType}`
              : styles.bulletListItem
          }
        >
          <span className={styles.boldWeight}>
            {convertTitles(item?.propertyName)}
          </span>
          {item.to !== '' ? (
            <span>
              <span>{` was changed from `}</span>
              <span className={styles.oldTime}>{item.from || '—'}</span> to
              <span className={styles.newTime}> {item.to || '—'}</span>
            </span>
          ) : (
            <span> was removed</span>
          )}
        </li>
      );
  }
};

export const getRemoveOperationComponent = (item, key, propertyName) => {
  switch (propertyName) {
    case 'task':
      return (
        <li
          key={key}
          className={`${styles.bulletListItem} ${styles.removedType}`}
        >
          <span className={styles.boldWeight}>
            {convertTitles(item?.propertyName)}:
          </span>
          <span>{`${
            item?.additionalInfo.taskName
          }, ${item?.additionalInfo.status.toLowerCase()} was removed`}</span>
        </li>
      );

    default:
      return (
        <li
          key={key}
          className={`${styles.bulletListItem} ${styles.removedType}`}
        >
          <span className={styles.boldWeight}>
            {convertTitles(item?.propertyName)}
          </span>
          {` ${item.from} was removed`}
        </li>
      );
  }
};

export const getInsertOperationComponent = (item, key, propertyName) => {
  switch (propertyName) {
    case 'task':
      return (
        <li
          key={key}
          className={`${styles.bulletListItem} ${styles.addedType}`}
        >
          <span className={styles.boldWeight}>
            {convertTitles(item?.propertyName)}:
          </span>
          <span>{`${item?.additionalInfo.taskName}, to do was added`}</span>
        </li>
      );

    default:
      return (
        <li
          key={key}
          className={`${styles.bulletListItem} ${styles.addedType}`}
        >
          <span className={styles.boldWeight}>
            {convertTitles(item?.propertyName)}
          </span>
          {` was added: ${item.to}`}
        </li>
      );
  }
};
