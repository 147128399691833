import { COLORS } from '../../../shared/styles/Theme';

const customButtonFilter = {
  display: 'flex',
  minWidth: '315px',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '11px 16px',
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '19px',
  color: COLORS.blue[500],
  backgroundColor: COLORS.blue[50],
  cursor: 'pointer',
  borderRadius: '10px',
  '&.has-selected-items': {
    color: COLORS.blue[900],
    backgroundColor: COLORS.yellow[200],
  },
  '& svg': {
    fill: COLORS.blue[300],
    transform: 'scale(1.35)',
    marginLeft: '6px',
  },
};

const dialogContentStyles = {
  width: '100%',
  minWidth: '520px',
  maxWidth: '700px',
  padding: '36px 36px 36px 32px',
  '&::-webkit-scrollbar': {
    width: '0.4em',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
};

export { customButtonFilter, dialogContentStyles };
