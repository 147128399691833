import {
  useAddOneTimeAvailabilityMutation,
  useGetAllCaregiverAvailabilitiesQuery,
} from '../../../../../api/Scheduler/api';
import moment from 'moment';
import { ScheduleCaregiverManagementContext } from '../../../../../pages/Scheduling/context';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ISO_DATE_ONLY_FORMAT } from '../../../../../shared/constants';
import AlertContext from '../../../../../components/Alert';
import CustomDialog from '../../../../../components/Dialog';
import SettingsView from './components/SettingsView';

export default function AddOneTimeAvailability() {
  const { setAlert } = useContext(AlertContext);
  const [hasErrors, setHasErrors] = useState(false);
  const [oneTimeAvailabilityData, setOneTimeAvailabilityData] = useState({
    availability: null,
    oneTimeAvailability: [],
  });
  const { calloutDate, closeOneTimeAvailability, showOneTimeAvailabilityData } =
    useContext(ScheduleCaregiverManagementContext);

  const { data: availabilitiesData, error: availabilitiesDataError } =
    useGetAllCaregiverAvailabilitiesQuery(
      {
        caregiver: showOneTimeAvailabilityData?.caregiver,
        date: showOneTimeAvailabilityData?.date?.format(ISO_DATE_ONLY_FORMAT),
      },
      {
        refetchOnMountOrArgChange: true,
      },
    );

  const [addOneTimeAvailability, { error: addOneTimeAvailabilityError }] =
    useAddOneTimeAvailabilityMutation();

  useEffect(() => {
    const errorData = availabilitiesDataError || addOneTimeAvailabilityError;
    if (errorData) {
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [setAlert, availabilitiesDataError, addOneTimeAvailabilityError]);
  useEffect(() => {
    if (availabilitiesData) {
      setOneTimeAvailabilityData(availabilitiesData);
    }
  }, [availabilitiesData, setOneTimeAvailabilityData]);

  const isRequiredFieldsNotFilled = useMemo(
    () =>
      !oneTimeAvailabilityData?.oneTimeAvailability?.length ||
      oneTimeAvailabilityData?.oneTimeAvailability?.some(
        (period) => !period.startTime || !period.endTime,
      ),
    [oneTimeAvailabilityData],
  );

  const closeDialog = useCallback(() => {
    closeOneTimeAvailability();
  }, [closeOneTimeAvailability]);

  const submitOneTimeAvailability = () => {
    const parsedOneTimeAvailability =
      oneTimeAvailabilityData?.oneTimeAvailability.map(
        ({ endTime, startTime }) => ({
          endTime,
          startTime,
        }),
      );

    const requestData = {
      id: showOneTimeAvailabilityData.caregiver.id,
      date: moment(showOneTimeAvailabilityData.date).format(
        ISO_DATE_ONLY_FORMAT,
      ),
      oneTimeAvailability: parsedOneTimeAvailability,
      availabilities: [oneTimeAvailabilityData?.availability] || [],
    };
    addOneTimeAvailability(requestData);
    closeOneTimeAvailability();
  };
  return (
    <CustomDialog
      cancelButtonName="Cancel"
      cancelCallback={closeDialog}
      customStyles={{ maxWidth: '600px' }}
      disableOnCloseByClickOutside
      disabledSubmit={isRequiredFieldsNotFilled || hasErrors}
      openDialog={!!showOneTimeAvailabilityData}
      submitButtonName="Confirm"
      submitCallback={submitOneTimeAvailability}
      title="One Time Availability"
    >
      <SettingsView
        date={calloutDate}
        oneTimeAvailabilityData={oneTimeAvailabilityData}
        setHasErrors={setHasErrors}
        setOneTimeAvailabilityData={setOneTimeAvailabilityData}
      />
    </CustomDialog>
  );
}
