import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
// import Chevron from '../../../../../../../../../../img/icons/chevron-right.svg?react';
import { node, string } from 'prop-types';
import React, { useState } from 'react';
import {
  customAccordionStyles,
  customAccordionSummaryStyles,
  visitAccordionSummaryTitle,
} from './styles';

function AffectedVisitsAccordion({ children = <div />, title = '' }) {
  const [expanded, setExpanded] = useState(true);

  return (
    <Accordion elevation={0} expanded={expanded} sx={customAccordionStyles}>
      <AccordionSummary
        // expandIcon={<Chevron onClick={() => setExpanded(!expanded)} />}
        aria-controls="panel1bh-content"
        sx={customAccordionSummaryStyles}
      >
        <Box
          sx={visitAccordionSummaryTitle}
          onClick={() => setExpanded(!expanded)}
        >
          <Typography variant="body2">{title}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>{children}</AccordionDetails>
    </Accordion>
  );
}

AffectedVisitsAccordion.propTypes = {
  children: node,
  title: string,
};

export default React.memo(AffectedVisitsAccordion);
