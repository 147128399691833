import { Avatar, AvatarGroup } from '@mui/material';
import { bool, instanceOf, number } from 'prop-types';
import React from 'react';
import stringAvatar from '../../shared/utils/stringAvatar';
import CustomTooltip from '../CustomTooltip';
import { customAvatarGroup } from './styles';

export default function AvatarGroups({
  extendingStyles = {},
  maxLength = 1,
  showTooltip = false,
  users = [],
}) {
  return (
    <AvatarGroup
      sx={{ ...customAvatarGroup, ...(extendingStyles || {}) }}
      max={maxLength}
    >
      {users?.map((item) => (
        <CustomTooltip key={item} title={showTooltip ? item : null}>
          <Avatar alt="user avatar" src={item} {...stringAvatar(item, false)} />
        </CustomTooltip>
      ))}
    </AvatarGroup>
  );
}

AvatarGroups.propTypes = {
  extendingStyles: instanceOf(Object),
  maxLength: number,
  showTooltip: bool,
  users: instanceOf(Array),
};
