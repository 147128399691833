import { Alert, Box, Typography } from '@mui/material';
import { bool, func, instanceOf } from 'prop-types';
import React, { useMemo } from 'react';
import { H12_TIME_FORMAT, SHORT_DATE_FORMAT } from '../../../../../../../shared/constants';
import { DeletedShadowVisitsAccordion } from '../../../../../../../components/AffectedVisitsAccordion';
import Loader from '../../../../../../../components/Loader';
import CustomBreadcrumbs from './Breadcrumbs';
import CheckPossibilityAccordion from './CheckPossibilityAccordion';
import {
  PossibilityVisitsTable,
  ShadowVisitsTable,
} from './PossibilityVisitsTable';
import {
  calloutTimeRangeLabelStyles,
  checkPossibilityViewWrapper,
  infoAlertStyles,
} from './styles';

function CheckPossibilityView({
  callOutData = {},
  closeDialog = () => {},
  isLoading = false,
  possibilityData = {},
}) {
  const callOutDates = useMemo(
    () => ({
      start: `${callOutData?.callOutStartDate?.format(
        SHORT_DATE_FORMAT,
      )} from ${callOutData?.callOutStartTime?.format(H12_TIME_FORMAT)}`,
      end: `${callOutData?.callOutEndDate?.format(
        SHORT_DATE_FORMAT,
      )} from ${callOutData?.callOutEndTime?.format(H12_TIME_FORMAT)}`,
    }),
    [callOutData],
  );
  if (isLoading) return <Loader />;
  return (
    <Box sx={{ minWidth: '1440px', padding: '20px 32px' }}>
      <Box sx={checkPossibilityViewWrapper}>
        <CustomBreadcrumbs
          caregiver={callOutData?.caregiver}
          closeDialog={closeDialog}
        />
        <Box sx={{ display: 'flex', gap: '20px' }}>
          <Typography variant="body1" sx={calloutTimeRangeLabelStyles}>
            <span>Start:</span>
            {callOutDates?.start}
          </Typography>
          <Typography variant="body1" sx={calloutTimeRangeLabelStyles}>
            <span>End:</span>
            {callOutDates?.end}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ padding: '0 28px' }}>
        <Alert variant="outlined" severity="info" sx={infoAlertStyles}>
          The first visit of the day for the caregivers listed below has been
          updated.
        </Alert>
        {possibilityData?.dates?.map((date) => (
          <CheckPossibilityAccordion
            key={date}
            tasksCount={possibilityData?.response[date].length}
            title={date}
          >
            <PossibilityVisitsTable
              dateVisits={possibilityData?.response[date]}
              notAllocatedData={possibilityData.rescheduledEvents[date]}
            />
          </CheckPossibilityAccordion>
        ))}
        {!!possibilityData?.deletedShadowVisits?.length && (
          <DeletedShadowVisitsAccordion
            itemsCount={possibilityData?.deletedShadowVisits?.length}
            title="Shadow Visits to Be Deleted"
          >
            <ShadowVisitsTable rows={possibilityData?.deletedShadowVisits} />
          </DeletedShadowVisitsAccordion>
        )}
      </Box>
    </Box>
  );
}

CheckPossibilityView.propTypes = {
  callOutData: instanceOf(Object),
  closeDialog: func,
  isLoading: bool,
  possibilityData: instanceOf(Object),
};

export default React.memo(CheckPossibilityView);
