import { Box, Button } from '@mui/material';
import { bool, func } from 'prop-types';
import React from 'react';
import { outlinedButtonStyles, setupActionsWrapperStyles } from './styles';

export default function SetupActions({
  disableApply = false,
  onCancel = () => {},
  updateVisit = () => {},
}) {
  return (
    <Box sx={setupActionsWrapperStyles}>
      <Button
        color="secondary"
        variant="outlined"
        onClick={onCancel}
        disableElevation
        sx={outlinedButtonStyles}
      >
        Cancel
      </Button>
      <Button
        color="primary"
        disabled={disableApply}
        variant="contained"
        onClick={updateVisit}
        disableElevation
      >
        Apply
      </Button>
    </Box>
  );
}

SetupActions.propTypes = {
  disableApply: bool,
  onCancel: func,
  updateVisit: func,
};
