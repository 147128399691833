import { COLORS } from '../../shared/styles/Theme';

export const emptyPageWrapper = {
  display: 'flex',
  height: '100%',
};

export const infoKeyField = {
  display: 'flex',
  maxWidth: '220px',
  width: '100%',
  color: COLORS.blue[500],
  alignItems: 'center',
  lineHeight: '20px',
};

export const chipComponentStyle = {
  cursor: 'inherit',
  padding: '2px 10px',
  fontSize: '14px',
  fontWeight: 400,
  borderRadius: '30px',
  backgroundColor: '#EFF2F9',
  color: COLORS.blue[900],
};

export const visitNotesStyles = {
  fontSize: '14px',
  lineHeight: '20px',
  color: COLORS.blue[900],
};

export const timeInputStyles = {
  minWidth: '170px',
  '&.MuiOutlinedInput-root': {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    '&.Mui-focused, &:hover': {
      backgroundColor: '#ffffff',
    },
    padding: 0,
    '.MuiOutlinedInput-input': {
      height: '100%',
      padding: '0 16px',
    },
    fieldset: {
      border: 'none',
    },
  },
  '&.MuiOutlinedInput-root.has-selected-items': {
    color: COLORS.blue[900],
  },
  '& [aria-expanded=true] .animated-placeholder, & .animated-placeholder.has-selected-items':
    {
      top: 3,
      fontSize: '11px',
      transition: 'top 0.3s ease, fontSize 0.7s ease-out',
    },
};

export const customDatePickerStyles = [
  {
    minWidth: '182px',
    maxWidth: '50%',
    height: '40px',
    '& .MuiFilledInput-root': {
      padding: '12px',
      paddingLeft: '16px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#ffffff',
      '&.Mui-focused, &:hover': {
        backgroundColor: '#ffffff',
      },
    },
  },
];

export const visitInfoWrapper = {
  '&.MuiDialogContent-root': {
    padding: '0 28px 28px 28px',
  },
  '&::-webkit-scrollbar': {
    width: '0.4em',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
};

export const customOutlinedButton = {
  height: '32px',
  padding: '8px 12px',
  fontSize: '11px',
  width: 'fit-content',
  borderRadius: '6px',
  '& .MuiButton-startIcon': {
    mr: '6px',
  },
};

export const infoFieldWrapper = {
  display: 'flex',
  justifyContent: 'start',
  flex: 1,
  flexDirection: 'column',
  gap: '8px',
};

export const infoRowWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  height: 'auto',
  mb: '16px',
  transition: 'height 500ms',
};

export const infoRowEditableInfoWrapper = {
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const reasonsStyle = {
  fontSize: '15px',
  lineHeight: '23px',
  ml: '8px',
  color: COLORS.blue[900],
};
