import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import { func, instanceOf } from 'prop-types';
import React, { useMemo } from 'react';
import { SHORT_DATE_FORMAT } from '../../../../../shared/constants';
import { arrowButtonStyles, datesRangeStyles } from './styles';

export default function ReportingWeekDates({
  date = {},
  setNewDate = () => {},
}) {
  const handlePrev = () => {
    const prevDate = moment(date).subtract(7, 'day');
    setNewDate(prevDate);
  };
  const handleNext = () => {
    const nextDate = moment(date).add(7, 'day');
    setNewDate(nextDate);
  };
  const disabledPrev = useMemo(
    () => moment().clone().startOf('isoweek').isSame(date),
    [date],
  );
  const disabledNext = useMemo(
    () => moment().clone().startOf('isoweek').add(7, 'day').isSame(date),
    [date],
  );
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
      <Typography variant="body1" sx={datesRangeStyles}>
        {`${date?.format(SHORT_DATE_FORMAT)} - ${date
          ?.clone()
          .add(6, 'day')
          .format(SHORT_DATE_FORMAT)}`}
      </Typography>
      <Box sx={{ display: 'flex', gap: '4px' }}>
        <IconButton
          aria-label="back"
          disabled={disabledPrev}
          onClick={handlePrev}
          sx={arrowButtonStyles}
        >
          <NavigateBeforeIcon />
        </IconButton>
        <IconButton
          aria-label="back"
          disabled={disabledNext}
          onClick={handleNext}
          sx={arrowButtonStyles}
        >
          <NavigateNextIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

ReportingWeekDates.propTypes = {
  date: instanceOf(Object),
  setNewDate: func,
};
