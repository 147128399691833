import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { bool, func, instanceOf, string } from 'prop-types';
import React, { useMemo } from 'react';
import { tasksTypeButtons } from '../../../../../../../shared/constants';
import { getNoData } from '../../../../../../../shared/utils/common';
import ButtonsGroup from '../../../../../../../components/ButtonsGroup';
import CustomDialog from '../../../../../../../components/Dialog';
import CustomTable from '../../../../../../../components/Table';
import { getTasksColumns } from './constants';
import {
  customDialogStyles,
  tasksManagementTableStyles,
  visitTasksManagementWrapper,
} from './styles';

function VisitTasksManagement({
  closeTasksDialog = () => {},
  openTaskDialog = false,
  setShowedTasksType = () => {},
  showedTasksType = '',
  tasks = [],
  updateTasks = () => {},
}) {
  const apiRef = useGridApiRef();
  const tableStyles = useMemo(
    () => tasksManagementTableStyles(!tasks.length),
    [tasks],
  );

  const tasksColumns = useMemo(
    () => getTasksColumns(showedTasksType),
    [showedTasksType],
  );

  return (
    <CustomDialog
      cancelButtonName="Cancel"
      cancelCallback={closeTasksDialog}
      submitButtonName="confirm"
      submitCallback={updateTasks}
      title="Tasks"
      openDialog={openTaskDialog}
      customStyles={customDialogStyles}
    >
      <Box sx={visitTasksManagementWrapper}>
        <ButtonsGroup
          buttons={tasksTypeButtons}
          changeSelected={(value) => setShowedTasksType(value)}
          selected={showedTasksType}
        />
        <CustomTable
          apiRef={apiRef}
          columns={tasksColumns}
          customStyles={tableStyles}
          headerHeight={34}
          noRowsOverlay={() => getNoData({ title: 'No tasks yet' })}
          onRowClick={() => {}}
          rows={tasks || []}
          withoutPagination
        />
      </Box>
    </CustomDialog>
  );
}

VisitTasksManagement.propTypes = {
  closeTasksDialog: func,
  openTaskDialog: bool,
  setShowedTasksType: func,
  showedTasksType: string,
  tasks: instanceOf(Array),
  updateTasks: func,
};

export default React.memo(VisitTasksManagement);
