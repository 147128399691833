import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { bool, func, instanceOf, number, oneOfType, string } from 'prop-types';
import React from 'react';
import { COLORS } from '../../shared/styles/Theme';
import { customInputStyles } from './styles';

export default function NumericInput({
  additionalStyles = {},
  changeValue = () => {},
  disabled = false,
  isRequired = false,
  label = '',
  oneTimeVisit = false,
  step = 0,
  value = 0,
}) {
  const increaseValue = () => {
    changeValue(+value + step);
  };
  const decreaseValue = () => {
    if (value - step > 0) {
      changeValue(value - step);
    }
  };
  const buttons = [
    {
      callback: increaseValue,
      icon: <ArrowDropUpIcon viewBox="-2 -1 28 28" />,
      key: 'increase',
    },
    {
      callback: decreaseValue,
      icon: <ArrowDropDownIcon viewBox="-2 -2 28 28" />,
      key: 'decrease',
    },
  ];
  return (
    <TextField
      label={label}
      id="duration"
      variant="filled"
      disabled={disabled}
      required={isRequired}
      InputProps={{
        inputProps: {
          type: 'text',
          inputMode: 'numeric',
          pattern: '[0-9]*',
          min: 0,
          max: 240,
        },
        endAdornment: (
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              alignItems: 'center',
            }}
          >
            {!oneTimeVisit && (
              <Typography
                variant="body2"
                sx={{
                  mr: '10px',
                  color: disabled ? COLORS.black[200] : COLORS.blue[500],
                }}
              >
                min
              </Typography>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
              }}
            >
              {buttons.map(({ callback, icon, key }) => (
                <IconButton
                  key={key}
                  disabled={
                    disabled || (key === 'decrease' && value - step <= 0)
                  }
                  onClick={callback}
                  sx={{
                    padding: 0,
                    width: '10px',
                    height: '10px',
                    '&.MuiIconButton-root.Mui-disabled svg': {
                      fill: COLORS.black[200],
                    },
                  }}
                >
                  {icon}
                </IconButton>
              ))}
            </Box>
          </Box>
        ),
      }}
      onChange={(e) =>
        e.target.validity.valid ? changeValue(e.target.value) : {}
      }
      sx={{
        ...customInputStyles,
        ...additionalStyles,
        maxWidth: oneTimeVisit ? '250px' : '',
      }}
      value={value}
    />
  );
}

NumericInput.propTypes = {
  additionalStyles: instanceOf(Object),
  changeValue: func,
  disabled: bool,
  isRequired: bool,
  label: string,
  oneTimeVisit: bool,
  step: number,
  value: oneOfType([number, string]),
};
