import CircleIcon from '@mui/icons-material/Circle';
import { Box, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { useGetCaregiverInfoQuery } from '../../../../../../../api/Caregivers/api';
import { bool, func, instanceOf, string } from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { COLORS } from '../../../../../../../shared/styles/Theme';
import { DeletedShadowVisitsAccordion } from '../../../../../../../components/AffectedVisitsAccordion';
import AlertContext from '../../../../../../../components/Alert';
import CustomBreadcrumbs from './Breadcrumbs';
import TableTitle from './TableTitle';
import VisitsByTypesChangesTable from './VisitsByTypesChangesTable';
import {
  caregiverStatusChangingColumns,
  getDeletedShadowVisitsColumns,
} from './VisitsByTypesChangesTable/constants';
import { checkPossibilityViewWrapper } from './style';

const INFO_ITEMS = [
  {
    id: 'Time-off Requests',
    text: (
      <span>
        All future <b>PTOs & Callouts</b> will be deleted
      </span>
    ),
  },
  {
    id: 'Availability',
    text: (
      <span>
        The <b>Availability</b> will be set to empty availability starting the
        same effective date
      </span>
    ),
  },
];

function ScheduleChangesView({
  allDataLoaded = false,
  actionType = '',
  closeDialog = () => {},
  isLoading = false,
  shadowVisits = [],
  visits = {},
}) {
  const { setAlert } = useContext(AlertContext);
  const { id } = useParams();
  const { data: userData, error } = useGetCaregiverInfoQuery(id);
  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);
  const userName = useMemo(
    () => `${userData?.firstName} ${userData?.lastName}`,
    [userData],
  );
  const actionBreadcrumbsTitles = {
    deactivate: 'Deactivate Caregiver',
    reActivate: 'Reactivate Caregiver',
  };
  const shadowVisitsColumns = useMemo(
    () => getDeletedShadowVisitsColumns(closeDialog),
    [closeDialog],
  );
  return (
    <Box sx={{ minWidth: '1440px', padding: '20px 32px' }}>
      <Box sx={checkPossibilityViewWrapper}>
        <CustomBreadcrumbs
          closeDialog={closeDialog}
          disabled={!allDataLoaded}
          title={actionBreadcrumbsTitles[actionType]}
          userId={id}
          userName={userName}
        />
      </Box>
      <Typography variant="h1" sx={{ pl: '28px', mb: '20px' }}>
        Schedule Changes
      </Typography>
      <Box sx={{ padding: '0 28px' }}>
        <TableTitle title="Not Allocated Visits" visitsCount={visits?.length} />
        <VisitsByTypesChangesTable
          columns={caregiverStatusChangingColumns}
          isLoading={isLoading}
          noDataTitle="Have no Not Allocated Visits"
          visitsList={visits}
        />
        {actionType === 'deactivate' && (
          <DeletedShadowVisitsAccordion
            itemsCount={shadowVisits.length}
            title="Shadow Visits to Be Deleted"
          >
            <VisitsByTypesChangesTable
              columns={shadowVisitsColumns}
              isLoading={isLoading}
              noDataTitle="Have no deleted shadow visits"
              visitsList={shadowVisits}
            />
          </DeletedShadowVisitsAccordion>
        )}
        {actionType === 'deactivate' && (
          <>
            <Typography variant="h5" sx={{ lineHeight: '32px' }}>
              Availability & Absence
            </Typography>
            <List>
              {INFO_ITEMS?.map((item) => (
                <ListItem key={item.id} sx={{ padding: 0 }}>
                  <ListItemIcon sx={{ minWidth: '16px' }}>
                    <CircleIcon
                      sx={{ fontSize: '6px', color: COLORS.blue[700] }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Box>
    </Box>
  );
}

ScheduleChangesView.propTypes = {
  allDataLoaded: bool,
  actionType: string,
  closeDialog: func,
  isLoading: bool,
  shadowVisits: instanceOf(Array),
  visits: instanceOf(Object),
};

export default React.memo(ScheduleChangesView);
