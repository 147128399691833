import { Alert, Box, Typography } from '@mui/material';
import WarningIcon from '../../../../../../../../img/icons/warning-icon.svg';
import { func, instanceOf, string } from 'prop-types';
import React from 'react';
import { COLORS } from '../../../../../../../../shared/styles/Theme';
import { CustomDatePicker } from '../../../../../../../../components/CustomDateAndTimePickers';
import { effectiveDateSelectCustomAlert } from './styles';

export default function SelectDate({
  date = {},
  selectDate = () => {},
  warningTitle = '',
}) {
  return (
    <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
      {warningTitle && (
        <Alert
          icon={<img src={WarningIcon} alt="" />}
          severity="warning"
          sx={effectiveDateSelectCustomAlert}
        >
          {warningTitle}
        </Alert>
      )}
      <Typography
        variant="body1"
        sx={[{ color: COLORS.blue[900], mb: '20px' }]}
      >
        Select the effective date for the changes
      </Typography>
      <CustomDatePicker
        customStyles={[{ maxWidth: '160px', height: '40px' }]}
        changeDate={(newValue) => selectDate(newValue)}
        date={date}
        isDisablePast
        isDisabledToday
        label="Effective Date"
      />
    </Box>
  );
}

SelectDate.propTypes = {
  date: instanceOf(Object),
  selectDate: func,
  warningTitle: string,
};
