import { Box, Button } from '@mui/material';
import { func, instanceOf, string } from 'prop-types';
import React from 'react';
import ButtonsGroup from '../../../../../components/ButtonsGroup';

export default function AvailabilityControls({
  availabilityData = {},
  availabilityScheduleView = '0',
  initialData = [],
  setAvailabilityScheduleView = () => {},
  setOpenDialog = () => {},
  viewEffectiveDate = [],
}) {
  return (
    <Box>
      {initialData?.length === 2 && (
        <ButtonsGroup
          buttons={viewEffectiveDate}
          changeSelected={({ target }) =>
            target.name ? setAvailabilityScheduleView(target.name) : {}
          }
          selected={availabilityScheduleView}
          isChangedView
        />
      )}
      <Button
        color="primary"
        variant="contained"
        onClick={() => setOpenDialog(true)}
        sx={{ ml: '28px' }}
        disableElevation
      >
        {availabilityData?.length ? 'EDIT MODE' : 'ADD AVAILABILITY'}
      </Button>
    </Box>
  );
}

AvailabilityControls.propTypes = {
  availabilityData: instanceOf(Object),
  availabilityScheduleView: string,
  initialData: instanceOf(Array),
  setAvailabilityScheduleView: func,
  setOpenDialog: func,
  viewEffectiveDate: instanceOf(Array),
};
