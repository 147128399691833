import { Alert, Collapse } from '@mui/material';
import { bool, instanceOf, string } from 'prop-types';
import React from 'react';
import { backgroundColorSchemeMap } from '../../shared/constants';
import { checkAvailabilityCustomAlert } from './styles';

export default function CustomDialogAlert({
  customStyles = {},
  icon = '',
  showAlert = false,
  title = '',
  type = '',
}) {
  return (
    <Collapse
      orientation="vertical"
      in={showAlert}
      sx={{ width: '100%', ...customStyles }}
    >
      <Alert
        icon={<img src={icon} alt="icon" />}
        severity="warning"
        sx={{
          ...checkAvailabilityCustomAlert,
          backgroundColor: backgroundColorSchemeMap[type]?.backgroundColor,
          color: backgroundColorSchemeMap[type]?.color,
        }}
      >
        {title}
      </Alert>
    </Collapse>
  );
}

CustomDialogAlert.propTypes = {
  customStyles: instanceOf(Object),
  icon: string,
  showAlert: bool,
  title: string,
  type: string,
};
