/* eslint-disable no-param-reassign */
import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetCaregiverTimeOffRequestsHistoryQuery } from '../../../../../../api/TimeOffRequest/api';
import moment from 'moment';
import React, { useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import {
  HISTORY_UPDATE_FORMAT,
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
} from '../../../../../../shared/constants';
import { getNoData } from '../../../../../../shared/utils/common';
import AlertContext from '../../../../../../components/Alert';
import CustomTable from '../../../../../../components/Table';
import { addDateTimeObjects, ptoHistoryColumns } from './constants';

export default function PtoHistory() {
  const apiRef = useGridApiRef();
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const {
    data,
    isLoading,
    error: getPTOHistoryError,
  } = useGetCaregiverTimeOffRequestsHistoryQuery(id);

  useEffect(() => {
    if (getPTOHistoryError) {
      setAlert({
        errorData: getPTOHistoryError,
        type: 'error',
      });
    }
  }, [getPTOHistoryError, setAlert]);
  const updatedEvents = useMemo(
    () =>
      data
        ?.map((event, index) => {
          const changes = event?.changes ? JSON.parse(event.changes) : null;
          const changesArray = [];
          const onlyPrimitives = changes
            ?.filter((type) => type.type === 'primitive')
            ?.map((change) => change);
          if (onlyPrimitives) {
            changesArray.push(...onlyPrimitives);
          }

          const updatedChangesArray = addDateTimeObjects(changesArray);
          if (updatedChangesArray.length === 0) {
            return false;
          }
          return {
            ...event,
            changes: updatedChangesArray,
            dateCreated: moment(event.dateCreated).format(
              HISTORY_UPDATE_FORMAT,
            ),
            id: index + 1,
          };
        })
        .filter(Boolean),
    [data],
  );

  const sortedUpdatedEvents = updatedEvents?.sort((a, b) => {
    const dateA = new Date(a.dateCreated);
    const dateB = new Date(b.dateCreated);
    return dateB - dateA;
  });
  return (
    <Box sx={{ mt: '10px' }}>
      <CustomTable
        apiRef={apiRef}
        columns={ptoHistoryColumns}
        headerHeight={44}
        isLoading={isLoading}
        rows={sortedUpdatedEvents}
        noRowsOverlay={() =>
          getNoData({ title: 'No PTO & Call Out History yet' })
        }
        pageSize={PAGE_SIZE}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
      />
    </Box>
  );
}
