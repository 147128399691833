import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, IconButton, Typography } from '@mui/material';
import DeleteIcon from '../../../../img/icons/iconButtons/delete-icon.svg';
import { instanceOf, string } from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { visitStatusesMap } from '../../../../shared/constants';
import { VisitInfoTypeContext } from '../../../../shared/context';
import { COLORS } from '../../../../shared/styles/Theme';
import CustomTooltip from '../../../../components/CustomTooltip';
import CustomDialog from '../../../../components/Dialog';
import {
  getTaskActionsStyles,
  tableActionsWrapper,
} from '../VisitDetails/styles';

export default function TaskActions({ row = {}, tasksType = '' }) {
  const { updateTemporaryVisitData, temporaryVisitData } =
    useContext(VisitInfoTypeContext);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const deleteTask = () => {
    const newTasks = temporaryVisitData[tasksType].filter(
      (task) => task.id !== row.id,
    );
    updateTemporaryVisitData({ [tasksType]: newTasks });
  };

  const rowTasks = temporaryVisitData[tasksType] || [];
  const itemIndex = rowTasks.findIndex(({ id }) => id === row?.id);

  const swapTasks = (target, currentItem) => {
    if (!rowTasks?.length) {
      return true;
    }
    const copiedTasks = [...rowTasks];
    const currentIndex = rowTasks.findIndex(({ id }) => id === currentItem);
    if (currentIndex === -1) {
      return true;
    }
    const targetIndex = currentIndex + target;
    [copiedTasks[targetIndex], copiedTasks[currentIndex]] = [
      copiedTasks[currentIndex],
      copiedTasks[targetIndex],
    ];
    updateTemporaryVisitData({ [tasksType]: copiedTasks });
    return true;
  };
  const actions = [
    {
      callback: () => setOpenDeleteDialog(true),
      icon: <img src={DeleteIcon} alt="Delete" />,
      label: 'Delete',
    },
    {
      callback: () => swapTasks(1, row?.id),
      disabled: itemIndex === rowTasks.length - 1,
      icon: (
        <ArrowDownwardIcon sx={{ fill: COLORS.blue[300], fontSize: '20px' }} />
      ),
      label: 'Move down',
    },
    {
      callback: () => swapTasks(-1, row?.id),
      disabled: itemIndex < 1,
      icon: (
        <ArrowUpwardIcon sx={{ fill: COLORS.blue[300], fontSize: '20px' }} />
      ),
      label: 'Move up',
    },
  ];

  const taskActionStyles = useCallback(
    (action) => getTaskActionsStyles(action),
    [],
  );
  const taskName = useMemo(
    () =>
      temporaryVisitData[tasksType]?.find((task) => task.id === row?.id)
        ?.name || '',
    [tasksType, temporaryVisitData, row?.id],
  );

  const disableToChange = useMemo(
    () =>
      ![
        visitStatusesMap.scheduled,
        visitStatusesMap.enRoute,
        visitStatusesMap.onSite,
      ].includes(temporaryVisitData?.status),
    [temporaryVisitData?.status],
  );

  const onActionClick = (e, callback) => {
    e.preventDefault();
    e.stopPropagation();
    callback();
  };

  return (
    <Box sx={tableActionsWrapper}>
      {actions?.map((action) => (
        <CustomTooltip title={action.label} key={action.label}>
          <span>
            <IconButton
              aria-label={action.label}
              disabled={disableToChange || action.disabled}
              disableRipple
              onClick={(e) => onActionClick(e, action.callback)}
              sx={taskActionStyles}
            >
              {action.icon}
            </IconButton>
          </span>
        </CustomTooltip>
      ))}
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setOpenDeleteDialog(false)}
        submitButtonName="confirm"
        submitCallback={deleteTask}
        title="Confirmation"
        openDialog={openDeleteDialog}
      >
        <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            {`The task "${taskName}" will be removed. Do you want to proceed?`}
          </Typography>
        </Box>
      </CustomDialog>
    </Box>
  );
}

TaskActions.propTypes = {
  row: instanceOf(Object),
  tasksType: string,
};
