import { Box, Tooltip, Typography } from '@mui/material';
import { instanceOf } from 'prop-types';
import React from 'react';
import { COLORS } from '../../../../../../shared/styles/Theme';

export function SecondaryTeamTooltip({ teams = [] }) {
  const maxVisibleSkills = 2;
  const visibleSkills = teams.slice(0, maxVisibleSkills);
  const hiddenSkillsCount = teams.length - maxVisibleSkills;
  const hiddenSkills = teams?.slice(maxVisibleSkills);
  const tooltipContent = (
    <div>
      {hiddenSkills?.map((t, index) => (
        <div
          key={t.id}
          style={{
            marginBottom: index === hiddenSkills.length - 1 ? '0' : '12px',
          }}
        >
          <Typography variant="body1" style={{ marginLeft: '10px' }}>
            {t.name}
          </Typography>
        </div>
      ))}
    </div>
  );

  return (
    <Box
      sx={{
        maxWidth: '350px',
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'space-between',
        height: '50px',
      }}
    >
      {visibleSkills?.map((team) => (
        <Typography key={team.id}>{team?.name},</Typography>
      ))}
      {hiddenSkillsCount > 0 && (
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                '&.MuiTooltip-tooltipPlacementRight': {
                  mt: '5px !important',
                  mr: '25px !important',
                },
                padding: '10px',
                paddingLeft: '6px',
                backgroundColor: '#ffffff',
                boxShadow: '0px 0px 12px rgba(44, 45, 46, 0.16)',
                color: COLORS.blue[900],
                fontWeight: 400,
                fontSize: '15px',
                lineHeight: '20px',
                borderRadius: '10px',
              },
            },
          }}
          title={tooltipContent}
          placement="right-start"
        >
          <Typography>+{hiddenSkillsCount}</Typography>
        </Tooltip>
      )}
    </Box>
  );
}
SecondaryTeamTooltip.propTypes = {
  teams: instanceOf(Array),
};
