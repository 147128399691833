import { Box } from '@mui/material';
import { CaregiverProfileContext } from '../../../../../pages/CaregiverDetails/context';
import React, { useContext } from 'react';
import CustomSelect from '../../../../../components/Select';

export default function LanguagesSelector() {
  const { caregiverProfile, editingProfileDataLibrary, setCaregiverProfile } =
    useContext(CaregiverProfileContext);

  const onSelectChange = (params) =>
    setCaregiverProfile({
      ...caregiverProfile,
      [params.field]: params.value,
    });

  return (
    <Box sx={{ maxWidth: '250px', minWidth: '250px' }}>
      <CustomSelect
        id="languages"
        options={editingProfileDataLibrary?.skills?.language?.map(
          (option) => option.name,
        )}
        isLoading={false}
        isMultiselect
        selectedValue={caregiverProfile?.languages}
        setValue={onSelectChange}
      />
    </Box>
  );
}
