import { DialogContent } from '@mui/material';
import { node } from 'prop-types';
import React from 'react';

export default function ContentWrapper({ children = <div /> }) {
  return (
    <DialogContent
      sx={{
        width: '520px',
        padding: '0 28px 28px 28px',
        '&::-webkit-scrollbar': {
          width: '0.4em',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
      }}
    >
      {children}
    </DialogContent>
  );
}

ContentWrapper.propTypes = {
  children: node,
};
