import { Box, Typography } from '@mui/material';
import HolidayIcon from '../../../../../img/icons/holiday.svg';
import moment from 'moment';
import { instanceOf, string } from 'prop-types';
import React from 'react';
import CustomTooltip from '../../../../../components/CustomTooltip';

export function ClientsCalendarHeader({
  date = {},
  holiday = {},
  holidayName = '',
}) {
  const isToday = moment(date).isSame(moment(), 'day');
  const dayOfWeek = moment(date).format('ddd');
  const dateNumber = moment(date).format('DD');
  return (
    <div className="rbc-day-bg">
      <div className="rbc-day-bg-inner">
        <Typography sx={{ marginBottom: '4px' }}>{dayOfWeek}</Typography>
        <Box sx={{ display: 'flex' }}>
          <Typography
            sx={{ padding: '6px', paddingTop: '7px' }}
            variant="h5"
            className={isToday ? 'today' : ''}
          >
            {dateNumber}
          </Typography>
          {holiday === date && (
            <CustomTooltip title={holidayName} placement="right-end">
              <img style={{ marginLeft: '4px' }} src={HolidayIcon} alt="" />
            </CustomTooltip>
          )}
        </Box>
      </div>
    </div>
  );
}

ClientsCalendarHeader.propTypes = {
  date: instanceOf(Object),
  holiday: instanceOf(Object),
  holidayName: string,
};
