import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import { AddTaskContext } from '../../../../../../pages/ClientDetails/context';
import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router';
import ButtonsGroup from '../../../../../../components/ButtonsGroup';
import { viewTypeButtons } from '../../constants';

export default function Header() {
  const { id, tabSubView } = useParams();
  const navigate = useNavigate();
  const { addNewTask } = useContext(AddTaskContext);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        flexShrink: 0,
        marginBottom: '20px',
      }}
    >
      <ButtonsGroup
        buttons={viewTypeButtons}
        changeSelected={(value) =>
          navigate(`/clients/${id}/care-plan/${value}`)
        }
        selected={tabSubView}
      />
      {tabSubView === 'current' && (
        <Button
          color="primary"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => addNewTask()}
          sx={{ ml: '28px' }}
          disableElevation
        >
          Task
        </Button>
      )}
    </Box>
  );
}
