import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {
  Box,
  Button,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { bool, element, func, string } from 'prop-types';
import React from 'react';
import {
  backButtonStyles,
  dialogTitleNameBlock,
  dialogTitleStyles,
} from './styles';

export default function Title({
  backButtonCallback = () => {},
  cancelButtonName = '',
  cancelCallback = () => {},
  customTitleButton = null,
  customTitleComponent = null,
  disableAllActions = false,
  disabled = false,
  hideBackButton = false,
  hideSubmitButtons = false,
  submitButtonName = '',
  submitCallback = () => {},
  title = '',
}) {
  return (
    <DialogTitle sx={dialogTitleStyles}>
      <Box sx={dialogTitleNameBlock}>
        <IconButton
          aria-label="back"
          className={hideBackButton ? 'hidden' : ''}
          disabled={disableAllActions}
          onClick={backButtonCallback || cancelCallback}
          sx={backButtonStyles}
        >
          <NavigateBeforeIcon />
        </IconButton>
        <Typography variant="h4">{title}</Typography>
      </Box>
      <Box
        sx={{
          gap: '12px',
          display: hideSubmitButtons ? 'none' : 'flex',
        }}
      >
        <Box sx={{ display: customTitleComponent ? 'flex' : 'none' }}>
          {customTitleComponent}
        </Box>
        <Button
          color="secondary"
          variant="outlined"
          onClick={cancelCallback}
          disableElevation
          disabled={disableAllActions}
        >
          {cancelButtonName}
        </Button>
        <Box sx={{ display: customTitleButton ? 'flex' : 'none' }}>
          {customTitleButton}
        </Box>
        <Button
          color="primary"
          variant="contained"
          onClick={submitCallback}
          disableElevation
          disabled={disabled}
        >
          {submitButtonName}
        </Button>
      </Box>
    </DialogTitle>
  );
}

Title.propTypes = {
  backButtonCallback: func,
  cancelButtonName: string,
  cancelCallback: func,
  customTitleButton: element,
  customTitleComponent: element,
  disableAllActions: bool,
  disabled: bool,
  hideBackButton: bool,
  hideSubmitButtons: bool,
  submitButtonName: string,
  submitCallback: func,
  title: string,
};
