import { COLORS } from '../../../../shared/styles/Theme';

export const TitleWrapper = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  mb: '32px',
};
export const TitleBackButtonStyles = {
  width: '40px',
  height: '40px',
  padding: 0,
  backgroundColor: COLORS.blue[50],
  mr: '24px',
  '&.Mui-disabled': {
    backgroundColor: COLORS.blue[50],
  },
};
