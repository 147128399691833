import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, MenuItem, Select } from '@mui/material';
import { bool, func, instanceOf, string } from 'prop-types';
import React from 'react';
import SelectRenderValue from './SelectRenderValue';
import {
  customPaperStyles,
  customSelectItem,
  customSelectStyles,
  requiredLabelAsterisk,
} from './style';

export default function TimeSelect({
  addStyles = {},
  disabled = false,
  id = '',
  isLoading = false,
  isRequired = false,
  options = [],
  placeHolder = '',
  selectedValue = '',
  setValue = () => {},
}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setValue({ field: id, value });
  };
  return (
    <Select
      IconComponent={ExpandMoreIcon}
      className={selectedValue?.length ? 'has-selected-items' : ''}
      disabled={disabled || isLoading}
      displayEmpty
      onChange={handleChange}
      sx={{
        ...customSelectStyles,
        ...(isRequired ? requiredLabelAsterisk : {}),
        ...addStyles,
      }}
      MenuProps={{
        PaperProps: {
          sx: customPaperStyles,
        },
      }}
      renderValue={() => (
        <SelectRenderValue
          options={options}
          placeHolder={placeHolder}
          selectedValue={selectedValue}
        />
      )}
      value={selectedValue}
    >
      {options?.map((option) => (
        <MenuItem
          key={option}
          disabled={isLoading}
          disableRipple
          sx={{ ...customSelectItem }}
          value={option}
        >
          <Box>{option}</Box>
        </MenuItem>
      ))}
    </Select>
  );
}

TimeSelect.propTypes = {
  addStyles: instanceOf(Object),
  disabled: bool,
  id: string,
  isLoading: bool,
  isRequired: bool,
  options: instanceOf(Array),
  placeHolder: string,
  selectedValue: string,
  setValue: func,
};
