import { Box } from '@mui/material';
import { useGetCaregiversListQuery } from '../../../../../api/Caregivers/api';
import errorIcon from '../../../../../img/icons/error-outlined.svg';
import successIcon from '../../../../../img/icons/success-outlined.svg';
import { func, instanceOf } from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { VisitInfoTypeContext } from '../../../../../shared/context';
import { COLORS } from '../../../../../shared/styles/Theme';
import AlertContext from '../../../../../components/Alert';
import Loader from '../../../../../components/Loader';
import CustomAutocomplete from '../../AutocompleteSelect';
import ColumnAlert from './ColumnAlert';
import SetupActions from './SetupActions';
import { editPreferredCaregiverWrapperStyles } from './styles';

export default function VisitPreferredCaregiverSetup({
  applyPreferredCaregiver = () => {},
  cancelEditing = () => {},
  checkPreferredCaregiverAvailability = () => {},
  checkPreferredCaregiverAvailabilityData = null,
  clearPreferredCaregiverAvailabilityData = () => {},
}) {
  const [availableToRecheck, setAvailableToRecheck] = useState(false);
  const [caregiverAvailabilityChecked, setCaregiverAvailabilityChecked] =
    useState(false);

  const {
    initialVisitData,
    reFetchCalendarEvents,
    temporaryVisitData,
    updateTemporaryVisitData,
  } = useContext(VisitInfoTypeContext);

  const { setAlert } = useContext(AlertContext);
  const {
    data: caregivers,
    error,
    isLoading: isCaregiversListLoading,
  } = useGetCaregiversListQuery(
    {
      team: temporaryVisitData?.team?.id,
      active: true,
      date: temporaryVisitData?.date,
    },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);

  const caregiversList = useMemo(
    () =>
      caregivers
        ?.map(({ firstName, id, lastName }) => ({
          id,
          name: `${firstName} ${lastName}`,
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter(
          (caregiver) =>
            caregiver.id !== temporaryVisitData?.shadowCaregiver?.id,
        ),
    [caregivers, temporaryVisitData?.shadowCaregiver?.id],
  );

  const selectCaregiver = (_, value) => {
    if (!value) {
      return;
    }
    const selectedCaregiver = caregivers.find(({ id }) => id === value?.id);
    updateTemporaryVisitData({
      preferredCaregiver: selectedCaregiver,
    });
    if (value?.id === temporaryVisitData?.caregiver?.id) {
      clearPreferredCaregiverAvailabilityData();
    }
    if (caregiverAvailabilityChecked) {
      setAvailableToRecheck(true);
    }
  };

  const checkAvailability = () => {
    checkPreferredCaregiverAvailability({
      caregiverId: temporaryVisitData?.preferredCaregiver?.id,
      constraints: {
        availabilityConstraint: true,
        blockedCaregiverConstraint: true,
        includeOneTimeAvailability: true,
        teamConstraint: true,
      },
      visitId: temporaryVisitData?.id,
    });
    if (!caregiverAvailabilityChecked) {
      setCaregiverAvailabilityChecked(true);
    }
    if (availableToRecheck) {
      setAvailableToRecheck(false);
    }
  };

  const cancelPrimaryCaregiverEditing = () => {
    setCaregiverAvailabilityChecked(false);
    cancelEditing();
    clearPreferredCaregiverAvailabilityData();
    updateTemporaryVisitData({
      preferredCaregiver: initialVisitData?.preferredCaregiver,
    });
  };

  const onApplyPreferredCaregiver = () => {
    cancelPrimaryCaregiverEditing();
    applyPreferredCaregiver({
      caregiverId: temporaryVisitData?.preferredCaregiver?.id,
      visitId: temporaryVisitData?.id,
    })
      .unwrap()
      .then(() => reFetchCalendarEvents());
  };

  const caregiverHasBeenChanged = useMemo(
    () =>
      temporaryVisitData?.preferredCaregiver &&
      initialVisitData?.preferredCaregiver?.id !==
        temporaryVisitData?.preferredCaregiver?.id,
    [initialVisitData, temporaryVisitData],
  );

  const caregiverName = temporaryVisitData?.preferredCaregiver
    ? `${temporaryVisitData?.preferredCaregiver.firstName} ${temporaryVisitData?.preferredCaregiver.lastName}`
    : null;

  const preferredCaregiverAvailabilityAlert = {
    success: {
      bgColor: COLORS.green[200],
      color: COLORS.green[600],
      icon: <img src={successIcon} alt="success icon" />,
      title: `Visit can be rescheduled. New primary preferred caregiver: ${caregiverName}`,
      type: 'success',
    },
    error: {
      bgColor: COLORS.red[200],
      color: COLORS.red[600],
      icon: <img src={errorIcon} alt="error icon" />,
      title: `Visit cannot be rescheduled. New primary preferred caregiver ${caregiverName} cannot be assigned.`,
      type: 'error',
    },
  };

  const preferredCaregiverTheSameAsPrimary = useMemo(
    () =>
      temporaryVisitData?.preferredCaregiver?.id ===
      temporaryVisitData?.caregiver?.id,
    [
      temporaryVisitData?.caregiver?.id,
      temporaryVisitData?.preferredCaregiver?.id,
    ],
  );

  const preferredCaregiverName = temporaryVisitData?.preferredCaregiver
    ? `${temporaryVisitData?.preferredCaregiver.firstName} ${temporaryVisitData?.preferredCaregiver.lastName}`
    : '';

  return (
    <Box sx={editPreferredCaregiverWrapperStyles}>
      {isCaregiversListLoading && <Loader />}
      {checkPreferredCaregiverAvailabilityData && (
        <ColumnAlert
          alertData={
            preferredCaregiverAvailabilityAlert[
              checkPreferredCaregiverAvailabilityData?.visitAvailable
                ? 'success'
                : 'error'
            ]
          }
        />
      )}
      <Box sx={{ display: 'flex', width: '100%', gap: '12px' }}>
        <CustomAutocomplete
          id="caregiver"
          changeValue={selectCaregiver}
          optionsList={caregiversList}
          label="Primary Caregiver"
          selectedValue={preferredCaregiverName}
          showAdditionalIcon={temporaryVisitData?.isPreferredCaregiver}
        />
        <SetupActions
          applyPreferredCaregiver={onApplyPreferredCaregiver}
          availableToRecheck={availableToRecheck}
          caregiverAvailabilityChecked={caregiverAvailabilityChecked}
          caregiverHasBeenChanged={caregiverHasBeenChanged}
          checkAvailability={checkAvailability}
          notAvailableToSelect={
            checkPreferredCaregiverAvailabilityData &&
            !checkPreferredCaregiverAvailabilityData?.visitAvailable
          }
          onCancel={cancelPrimaryCaregiverEditing}
          preferredCaregiverTheSameAsPrimary={
            preferredCaregiverTheSameAsPrimary
          }
        />
      </Box>
    </Box>
  );
}

VisitPreferredCaregiverSetup.propTypes = {
  applyPreferredCaregiver: func,
  cancelEditing: func,
  checkPreferredCaregiverAvailability: func,
  checkPreferredCaregiverAvailabilityData: instanceOf(Object),
  clearPreferredCaregiverAvailabilityData: func,
};
