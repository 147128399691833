import { Box } from '@mui/material';
import { useGetWeekendsSchedulingDaysQuery } from '../../../../../../../../../../api/Administration/api';
import { useCheckVisitAvailabilityQuery } from '../../../../../../../../../../api/Visits/api';
import { useGetFiltersDataQuery } from '../../../../../../../../../../api/commonApi/api';
import AvailabilityLoader from '../../../../../../../../../../pages/ClientDetails/components/AvailabilityLoader';
import AvailabilityWindowsBody from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/RecurrentAvailability/AvailabilityWindowsBody';
import CheckAvailabilitySchedulePattern from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/RecurrentAvailability/CheckAvailabilitySchedulePattern';
import {
  getRecurrentAvailabilityContentWrapperStyles,
  recurrentAvailabilityWrapper,
} from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/RecurrentAvailability/styles';
import { func, instanceOf, number, string } from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { sortingDayNamesArray } from '../../../../../../../../../../shared/utils/common';
import AlertContext from '../../../../../../../../../../components/Alert';
import { checkAvailabilityWrapperStyles } from '../styles';
import ConstantTableItem from './ArrivalWindowsTableItmes/ConstantTableItem';

function RecurrentAvailability({
  availabilityScheduling,
  clearSelectedTimeSlots = () => {},
  errorData = '',
  setAvailabilityScheduling = () => {},
  setErrorData = () => {},
  setStepDataLoading = () => {},
  setTemporaryVisitData = () => {},
  setVisitData = () => {},
  temporaryVisitData = {},
  updatedCareProgram = [],
  visitData = {},
  visitSummaryHeight = 0,
}) {
  const { setAlert } = useContext(AlertContext);
  const { id } = useParams();
  const {
    data: availabilityData,
    isLoading,
    isFetching,
    error: checkVisitAvailabilityError,
  } = useCheckVisitAvailabilityQuery(
    {
      clientId: id,
      payload: { ...visitData, careProgramVisits: updatedCareProgram },
    },
    { refetchOnMountOrArgChange: true },
  );
  const {
    data: filtersData,
    isLoading: isFiltersLoading,
    error: getFiltersDataError,
  } = useGetFiltersDataQuery({
    refetchOnMountOrArgChange: true,
  });
  const { data: disabledWeekDays, error: getWeekdaysError } =
    useGetWeekendsSchedulingDaysQuery();

  const isSchedulePatternChanged = useMemo(
    () =>
      temporaryVisitData?.timesOfDay.length !== visitData?.timesOfDay.length ||
      temporaryVisitData?.days?.length !== visitData?.days.length ||
      JSON.stringify(
        temporaryVisitData?.timesOfDay.map((time) => time.name),
      ) !== JSON.stringify(visitData?.timesOfDay?.map((time) => time.name)) ||
      JSON.stringify(temporaryVisitData?.days) !==
        JSON.stringify(visitData?.days),
    [temporaryVisitData, visitData],
  );

  useEffect(() => {
    if (!isSchedulePatternChanged && !availabilityScheduling) {
      setAvailabilityScheduling(availabilityData);
    }
  }, [
    availabilityData,
    availabilityScheduling,
    isSchedulePatternChanged,
    setAvailabilityScheduling,
  ]);

  useEffect(() => {
    if (
      checkVisitAvailabilityError ||
      getFiltersDataError ||
      getWeekdaysError
    ) {
      const errorRequestData =
        checkVisitAvailabilityError || getFiltersDataError || getWeekdaysError;
      setAlert({
        errorData: errorRequestData,
        type: 'error',
      });
    }
  }, [
    checkVisitAvailabilityError,
    getFiltersDataError,
    getWeekdaysError,
    setAlert,
  ]);

  useEffect(() => {
    if (availabilityData) {
      setAvailabilityScheduling(availabilityData);
    }
  }, [setAvailabilityScheduling, availabilityData]);

  useEffect(() => {
    if (!availabilityScheduling) {
      setAvailabilityScheduling(availabilityData);
    }
  }, [availabilityData, availabilityScheduling, setAvailabilityScheduling]);

  useEffect(() => {
    setStepDataLoading(isLoading);
  }, [isLoading, setStepDataLoading]);

  const handleChangeDays = (e) => {
    if (errorData) {
      setErrorData(null);
    }
    const { name } = e.target;
    let targetItems = [...temporaryVisitData.days];
    if (targetItems.indexOf(name) > -1) {
      targetItems = targetItems.filter((item) => item !== name);
    } else {
      targetItems.push(name);
    }
    setTemporaryVisitData({
      ...temporaryVisitData,
      days: targetItems.sort(sortingDayNamesArray),
    });
    return false;
  };
  const handleChangeTimeOfDay = (e) => {
    if (errorData) {
      setErrorData(null);
    }
    const { name } = e.target;
    let targetItems = [...temporaryVisitData.timesOfDay]?.map(
      (item) => item.name,
    );
    if (targetItems.indexOf(name) > -1) {
      targetItems = targetItems.filter((item) => item !== name);
    } else {
      targetItems.push(name);
    }
    const timesOfDays = filtersData.timesOfDay.filter((time) =>
      targetItems.includes(time.name),
    );
    setTemporaryVisitData({
      ...temporaryVisitData,
      timesOfDay: timesOfDays,
    });
    return false;
  };
  const recheckAvailability = () => {
    setVisitData(temporaryVisitData);
    setAvailabilityScheduling(null);
    clearSelectedTimeSlots();
  };

  const tableHeaderItems = useMemo(
    () => ['Arrival Window', ...visitData.days],
    [visitData.days],
  );
  const tableContentStyles = useMemo(
    () => getRecurrentAvailabilityContentWrapperStyles(visitSummaryHeight),
    [visitSummaryHeight],
  );
  return (
    <Box sx={checkAvailabilityWrapperStyles}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <CheckAvailabilitySchedulePattern
          disabledRecheckAvailability={
            !isSchedulePatternChanged ||
            !temporaryVisitData?.days?.length ||
            !temporaryVisitData?.timesOfDay.length
          }
          filtersData={filtersData}
          disabledDays={disabledWeekDays?.schema}
          handleChangeDays={handleChangeDays}
          handleChangeTimeOfDay={handleChangeTimeOfDay}
          isFiltersLoading={isFiltersLoading}
          isLoading={isLoading || isFetching}
          recheckAvailability={recheckAvailability}
          visitData={temporaryVisitData}
        />
        {isLoading || isFetching ? (
          <AvailabilityLoader />
        ) : (
          <Box sx={recurrentAvailabilityWrapper}>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              {tableHeaderItems.map((item, index) => (
                <ConstantTableItem
                  key={item}
                  isArrivalWindowItem={!index}
                  isHeader
                  name={item}
                  showDivider={!!index && tableHeaderItems.length - 1 !== index}
                />
              ))}
            </Box>
            <Box sx={tableContentStyles}>
              {availabilityScheduling?.timesOfDay && (
                <AvailabilityWindowsBody />
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

RecurrentAvailability.propTypes = {
  availabilityScheduling: instanceOf(Object),
  clearSelectedTimeSlots: func,
  errorData: string,
  setAvailabilityScheduling: func,
  setErrorData: func,
  setStepDataLoading: func,
  setTemporaryVisitData: func,
  setVisitData: func,
  temporaryVisitData: instanceOf(Object),
  updatedCareProgram: instanceOf(Array),
  visitData: instanceOf(Object),
  visitSummaryHeight: number,
};

export default RecurrentAvailability;
