import { Box, Typography } from '@mui/material';
import { ScheduleCaregiverManagementContext } from '../../../../../../../../pages/Scheduling/context';
import { instanceOf } from 'prop-types';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { COLORS } from '../../../../../../../../shared/styles/Theme';
import CustomDialog from '../../../../../../../../components/Dialog';
import UnsavedChangesContent from '../../../../../../../../components/Dialog/UnsavedChangesContent';
import { customCellWrapper } from './styles';

export default function ShadowVisitClientsCell({ row = {} }) {
  const navigate = useNavigate();
  const { closeCallOutCreation } = useContext(
    ScheduleCaregiverManagementContext,
  );
  const [showConformation, setShowConformation] = useState(false);
  const [navigationUrl, setNavigationUrl] = useState('');

  const onLinkClick = (url) => {
    setNavigationUrl(url);
    setShowConformation(true);
  };
  const closeCreation = () => {
    closeCallOutCreation();
    navigate(navigationUrl);
    setShowConformation(null);
    setNavigationUrl('');
  };
  return (
    <div>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setShowConformation(false)}
        submitButtonName="confirm"
        submitCallback={closeCreation}
        title="Confirmation"
        openDialog={showConformation}
      >
        <UnsavedChangesContent text="Callout creation will be lost. Do you want to proceed?" />
      </CustomDialog>
      <Box sx={customCellWrapper}>
        <Typography
          component="span"
          onClick={() => onLinkClick(`/clients/${row?.client?.id}`)}
          sx={{
            color: COLORS.blue[700],
            cursor: 'pointer',
          }}
        >
          {`${row?.client?.firstName} ${row?.client?.lastName}`}
        </Typography>
      </Box>
    </div>
  );
}

ShadowVisitClientsCell.propTypes = {
  row: instanceOf(Object),
};
