import { Chip } from '@mui/material';
import { instanceOf, string } from 'prop-types';
import React from 'react';

export default function Badge({ colorScheme = {}, label = '' }) {
  return (
    <Chip
      label={label}
      sx={{
        backgroundColor: colorScheme.backgroundColor,
        color: colorScheme.color,
        cursor: 'inherit',
        padding: '4px 10px',
        borderRadius: '30px',
        '& .MuiChip-label': {
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '16px',
        },
      }}
    />
  );
}

Badge.propTypes = {
  colorScheme: instanceOf(Object),
  label: string,
};
