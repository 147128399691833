import moment from 'moment';
import { isItemsContainSubstring } from '../../../../shared/utils/common';

export const filterTimeOffRequests = (
  { datesRange, caregiver, teams, status },
  timeOffRequests,
  isAllTeams,
) => {
  let data = [...timeOffRequests];
  if (data.length && datesRange.startDate && datesRange.endDate) {
    data = data.filter((item) => {
      const periodStart = moment(item.requestStart);
      const periodEnd = moment(item.requestEnd);
      return (
        periodStart.isBetween(
          datesRange.startDate,
          datesRange.endDate,
          'day',
          '[]',
        ) ||
        periodEnd.isBetween(
          datesRange.startDate,
          datesRange.endDate,
          'day',
          '[]',
        )
      );
    });
  }
  if (data?.length && caregiver?.length) {
    data = data.filter(
      (item) =>
        isItemsContainSubstring(item?.caregiver?.firstName, caregiver) ||
        isItemsContainSubstring(item?.caregiver?.lastName, caregiver),
    );
  }
  if (data?.length && (!status?.length || !teams?.length)) {
    data = [];
  }
  if (data?.length && teams?.length && !isAllTeams) {
    data = data.filter((item) => {
      const caregiverTeams = [
        ...item.caregiverTeams,
        ...item.caregiverSecondaryTeams,
      ];
      return caregiverTeams.some(({ name }) => teams.includes(name));
    });
  }
  if (data?.length && status?.length) {
    data = data.filter((item) => status.includes(item.status));
  }
  return data;
};
