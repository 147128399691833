import { COLORS } from '../../../shared/styles/Theme';

const customSelectStyles = {
  minWidth: '100px',
  flex: '1 0 0',
  fontSize: '15px',
  height: '42px',
  color: `${COLORS.blue[500]}`,
  borderRadius: '8px',
  '&.MuiSvgIcon-root': {
    fill: `${COLORS.blue[300]}`,
    width: '20px',
    height: '20px',
    top: 11,
  },
  '&.MuiOutlinedInput-root': {
    border: 'none',
    backgroundColor: COLORS.blue[50],
    '.MuiOutlinedInput-input': {
      padding: '10px 12px',
      paddingRight: '28px',
    },
    fieldset: {
      border: 'none',
    },
  },
  '&.MuiOutlinedInput-root.has-selected-items': {
    backgroundColor: COLORS.yellow[200],
    color: COLORS.blue[900],
  },
};

const customSelectPaperProps = {
  mt: '5px',
  padding: '8px 0',
  boxShadow: '0px 4px 12px 2px rgba(180, 180, 180, 0.2)',
  borderRadius: '10px',
  maxHeight: '600px',
};

const customSelectItem = {
  display: 'flex',
  fontSize: '15px',
  margin: '0 8px',
  color: `${COLORS.blue[900]}`,
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  '&.select-all-item': {
    paddingBottom: 0,
    '&.MuiMenuItem-root:hover': {
      backgroundColor: '#ffffff',
    },
  },
  '&:focus ': {
    backgroundColor: '#ffffff',
  },
  '&:hover': {
    backgroundColor: `${COLORS.blue[50]}`,
  },
  '&.Mui-selected, &.Mui-selected:active,  &.Mui-selected:focus': {
    backgroundColor: '#ffffff',
  },
  '&.Mui-selected:hover': {
    backgroundColor: `${COLORS.blue[50]}`,
    cursor: 'default',
  },
};

export { customSelectPaperProps, customSelectStyles, customSelectItem };
