import { COLORS } from '../../../../../../shared/styles/Theme';

export const customRadioStyles = {
  '& .MuiRadio-root.Mui-checked': {
    color: COLORS.blue[700],
  },
  display: 'flex',
  flexDirection: 'row',
  gap: 2,
};
export const teamSelectStyles = {
  '& .animated-placeholder': {
    top: '0px',
  },
};
export const territorySelectStyles = {
  '& .animated-placeholder': {
    top: '-4px !important',
  },
  '& .animated-chosen-item': {
    marginTop: '8px',
  },
};
export const muiColorInputStyles = {
  paddingBottom: '4px',
  '&  .MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },
  '& .MuiInputAdornment-root': {
    maxHeight: '30px',
    height: '30px',
  },
  '& .MuiInputBase-input': {
    display: 'none',
  },
  '& .MuiColorInput-Button': {
    width: '90px',
    maxHeight: '30px',
    height: '100%',
  },
  '& .MuiPaper-root-MuiPopover-paper': {
    top: '200px',
  },
};
export const colorValueStyles = {
  position: 'absolute',
  pointerEvents: 'none',
  top: '6px',
  left: '22px',
  textTransform: 'uppercase',
  fontSize: '14px',
};
export const colorBoxStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  background: '#EFF2F9',
  padding: '6px 10px',
  borderRadius: '10px',
};
export const modalBoxStyles = {
  width: '100%',
  padding: '0 28px 28px 16px',
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};
