import { Box, Typography } from '@mui/material';
import React from 'react';
import { COLORS } from '../../../../shared/styles/Theme';
import { availabilityLoader, availabilityLoaderWrapper } from './styles';

export default function AvailabilityLoader() {
  return (
    <Box sx={availabilityLoaderWrapper}>
      <Box sx={availabilityLoader} />
      <Typography variant="body1" sx={{ color: COLORS.blue[500] }}>
        Checking availability…
      </Typography>
    </Box>
  );
}
