import { fetchBaseQuery } from '@reduxjs/toolkit/query';
export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem(
      `msal.token.keys.${process.env.REACT_APP_AZURE_CLIENT_ID}`,
    );
    const accessToken = JSON.parse(token).accessToken[0];
    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }
    return headers;
  },
});
