import { TabContext, TabPanel } from '@mui/lab';
import { Box } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import ReportingTitle from './components/Title';
import { REPORTING_TABS } from './constants';

export default function Reporting() {
  const { tab } = useParams();
  const navigate = useNavigate();
  const handleChange = (_, newValue) => {
    navigate(
      newValue === 'weekly-scheduling-report'
        ? `/reporting/${newValue}/not-allocated`
        : `/reporting/${newValue}`,
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        typography: 'body1',
        maxHeight: 'calc(100vh - 180px)',
        pr: '20px',
      }}
    >
      <TabContext value={tab}>
        <ReportingTitle handleChange={handleChange} />
        <Box>
          {REPORTING_TABS?.map(({ key, child }) => (
            <TabPanel key={key} sx={{ padding: 0 }} value={key}>
              {child}
            </TabPanel>
          ))}
        </Box>
      </TabContext>
    </Box>
  );
}
