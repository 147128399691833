import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Checkbox,
  Divider,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import {
  bool,
  func,
  instanceOf,
  node,
  number,
  oneOfType,
  string,
} from 'prop-types';
import React from 'react';
import SelectRenderValue from './SelectRenderValue';
import {
  customPaperStyles,
  customSelectItem,
  customSelectStyles,
  requiredLabelAsterisk,
} from './style';

export default function CustomSelect({
  addStyles = {},
  disabled = false,
  endIcon = <div />,
  hasSelectAllOption = false,
  icons = null,
  id = '',
  isLoading = false,
  isMultiselect = false,
  isRequired = false,
  options = [],
  placeHolder = '',
  selectedValue = '' || {} || 0,
  setValue = () => {},
  withIcon = false,
}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (!isMultiselect) {
      setValue({ field: id, value });
      return '';
    }
    if (isMultiselect && value[value.length - 1] === 'all') {
      setValue({
        field: id,
        value: selectedValue.length === options.length ? [] : options,
      });
      return '';
    }
    let duplicateRemoved = [];
    value.forEach((item) => {
      if (duplicateRemoved.findIndex((option) => option === item) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x === item);
      } else {
        duplicateRemoved.push(item);
      }
    });
    setValue({ field: id, value: duplicateRemoved });
    return '';
  };
  const selectInitialValue = isMultiselect ? [] : '';
  return (
    <Select
      endAdornment={withIcon ? endIcon : null}
      IconComponent={ExpandMoreIcon}
      className={selectedValue?.length ? 'has-selected-items' : ''}
      disabled={disabled || isLoading}
      displayEmpty
      multiple={isMultiselect}
      onChange={handleChange}
      sx={{
        ...customSelectStyles,
        ...(isRequired ? requiredLabelAsterisk : {}),
        ...addStyles,
      }}
      MenuProps={{
        PaperProps: {
          sx: customPaperStyles,
        },
      }}
      renderValue={() => (
        <SelectRenderValue
          isMultiselect={isMultiselect}
          options={options}
          placeHolder={placeHolder}
          selectedValue={selectedValue}
        />
      )}
      value={selectedValue || selectInitialValue}
    >
      {hasSelectAllOption && (
        <MenuItem
          disabled={isLoading}
          name="all"
          value="all"
          disableRipple
          className="select-all-item"
          sx={{
            ...customSelectItem,
          }}
        >
          <Checkbox
            checked={
              options.length > 0 && options.length === selectedValue?.length
            }
          />
          All
        </MenuItem>
      )}

      {/* {skillLang &&
        options?.map((option) => (
          <MenuItem
            key={option.name}
            disabled={isLoading}
            disableRipple
            sx={{ ...customSelectItem }}
            value={option.name}
          >
            {isMultiselect && (
              <Checkbox
                checked={
                  !selectedValue.length
                    ? false
                    : selectedValue?.findIndex(
                        (item) => item === option.name,
                      ) >= 0
                }
                sx={{
                  padding: '5px 9px',
                }}
              />
            )}
            {withIcon && icons ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  '& img': {
                    margin: 'auto',
                  },
                }}
              >
                <Typography sx={{ width: '90%' }}> {option.name}</Typography>
                {icons[option.name?.toLowerCase()] && (
                  <img
                    src={icons[option.name?.toLowerCase()]}
                    alt={option.name}
                  />
                )}
              </Box>
            ) : (
              <Box>{option.name}</Box>
            )}
          </MenuItem>
        ))} */}

      {hasSelectAllOption && <Divider sx={{ mt: 0 }} />}
      {options?.map((option) => (
        <MenuItem
          key={option}
          disabled={isLoading}
          disableRipple
          sx={{ ...customSelectItem }}
          value={option}
        >
          {isMultiselect && (
            <Checkbox
              checked={
                !selectedValue.length
                  ? false
                  : selectedValue?.findIndex((item) => item === option) >= 0
              }
              sx={{
                padding: '5px 9px',
              }}
            />
          )}
          {withIcon && icons ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                '& img': {
                  margin: 'auto',
                },
              }}
            >
              <Typography sx={{ width: '90%' }}> {option}</Typography>
              {icons[option?.toLowerCase()] && (
                <img src={icons[option?.toLowerCase()]} alt={option} />
              )}
            </Box>
          ) : (
            <Box>{option}</Box>
          )}
        </MenuItem>
      ))}
    </Select>
  );
}

CustomSelect.propTypes = {
  addStyles: instanceOf(Object),
  disabled: bool,
  endIcon: node,
  hasSelectAllOption: bool,
  icons: instanceOf(Object),
  id: string,
  isLoading: bool,
  isMultiselect: bool,
  isRequired: bool,
  options: instanceOf(Array),
  placeHolder: string,
  selectedValue: oneOfType([
    instanceOf(Array),
    string,
    instanceOf(Object),
    number,
  ]),
  setValue: func,
  withIcon: bool,
};
