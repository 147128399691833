import { Box } from '@mui/material';
import { func, instanceOf, number, string } from 'prop-types';
import React, { useState } from 'react';
import { isValidText } from '../../../../../../shared/utils/common';
import ErrorAlert from '../../../../../../components/ErrorAlert';
import CustomInput from '../../../../../../components/Inputs';
import CustomSelect from '../../../../../../components/Select';
import { territorySelectStyles } from './styles';

export default function AddTerritoryModal({
  data = [],
  order = 0,
  setOrder = () => {},
  setTerritoryName = () => {},
  territoryName = '',
}) {
  const options = Array.from({ length: data?.length }, (_, index) => index + 1);
  const onSelectChange = (params) => {
    setOrder(params.value);
  };
  const [error, setError] = useState(null);

  const handleChangeTerritoryName = (event) => {
    setTerritoryName(event.target.value);
    setError(null);
  };
  const handleBlurEmail = () => {
    if (!isValidText(territoryName)) {
      setError('Territory Name is invalid');
    }
    if (territoryName?.length === 0 && error) {
      setError(null);
    }
  };
  return (
    <Box
      sx={{
        width: '100%',
        padding: '0 28px 28px 16px',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <CustomInput
        changeTaskName={handleChangeTerritoryName}
        id="territory"
        label="Territory"
        value={territoryName}
        onBlur={handleBlurEmail}
      />
      {error && <ErrorAlert error={error} />}
      <CustomSelect
        addStyles={territorySelectStyles}
        id="order"
        isLoading={false}
        isMultiselect={false}
        options={options || ''}
        placeHolder="Order"
        selectedValue={order}
        setValue={onSelectChange}
      />
    </Box>
  );
}

AddTerritoryModal.propTypes = {
  data: instanceOf(Array),
  order: number,
  setOrder: func,
  setTerritoryName: func,
  territoryName: string,
};
