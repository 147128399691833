import { Box } from '@mui/material';
import { instanceOf } from 'prop-types';
import React from 'react';
import VisitViolations from './VisitViolations/VisitViolations';

export default function VisitViolationsRow({ row = {} }) {
  const text = row?.violations?.join(`\n`);
  return (
    <Box sx={{ display: 'flex', width: '100%', pb: '16px' }}>
      <VisitViolations
        disabled
        initialVisitNotes={text}
        label="Constraints Violation"
        maxLength={4000}
        textFieldId={row.id}
        textFieldValue={text}
      />
    </Box>
  );
}

VisitViolationsRow.propTypes = {
  row: instanceOf(Object),
};
