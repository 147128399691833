import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryRealDB } from '../../api/baseQueryBE';
import { parseTimeOffRequestsData } from '../../pages/CaregiverDetails/view/PtoAndCallouts/utils';
import { filterTimeOffRequests } from '../../pages/Scheduling/components/PtoAndCalloutsTable/utils';

export const TIME_OFF_REQUEST_API_KEY = 'timeOffRequestApi';

const timeOffRequestApi = createApi({
  reducerPath: TIME_OFF_REQUEST_API_KEY,
  baseQuery: baseQueryRealDB,
  endpoints: (builder) => ({
    getTimeOffRequestsTypes: builder.query({
      query: () => ({
        url: '/time-off-requests/schema/types',
        method: 'GET',
      }),
      transformResponse: (response) =>
        response?.schema?.filter(({ name }) =>
          ['Personal', 'Vacation'].includes(name),
        ),
    }),
    getAllTimeOffRequests: builder.query({
      query: () => ({
        url: 'time-off-requests',
        method: 'GET',
      }),
      transformResponse: (
        response,
        meta,
        { timeOffRequestsFilters, isAllTeams },
      ) => filterTimeOffRequests(timeOffRequestsFilters, response, isAllTeams),
      providesTags: () => [{ type: 'allTimeOffRequests', id: 'ALL' }],
    }),
    getCaregiverTimeOffRequests: builder.query({
      query: (caregiverId) => ({
        url: `time-off-requests/caregiver/${caregiverId}`,
        method: 'GET',
      }),
      transformResponse: (response) => parseTimeOffRequestsData(response),
      providesTags: () => [{ type: 'timeOffRequests', id: 'ALL' }],
    }),
    changeTimeOffRequestStatus: builder.mutation({
      query: ({ payload, id }) => ({
        url: `teams/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: () => [
        { type: 'timeOffRequests', id: 'ALL' },
        { type: 'allTimeOffRequests', id: 'ALL' },
      ],
    }),
    checkPossibilityAndCreateTimeOffRequestDraft: builder.mutation({
      query: ({ caregiverId, payload }) => ({
        url: `time-off-requests/caregiver/${caregiverId}`,
        method: 'POST',
        body: payload,
      }),
    }),
    createTimeOffRequest: builder.mutation({
      query: ({ requestId }) => ({
        url: `time-off-requests/${requestId}/confirm`,
        method: 'POST',
      }),
      invalidatesTags: () => [{ type: 'timeOffRequests', id: 'ALL' }],
    }),
    cancelCreatingTimeOffRequest: builder.mutation({
      query: (requestId) => ({
        url: `time-off-requests/${requestId}/cancel`,
        method: 'POST',
      }),
      invalidatesTags: () => [{ type: 'timeOffRequests', id: 'ALL' }],
    }),
    checkCancelingApprovedTimeOffRequest: builder.mutation({
      query: (requestId) => ({
        url: `time-off-requests/${requestId}/cancel/check`,
        method: 'POST',
      }),
    }),
    confirmCancelingApprovedTimeOffRequest: builder.mutation({
      query: (payload) => ({
        url: 'time-off-requests/cancel/confirm',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [
        { type: 'timeOffRequests', id: 'ALL' },
        { type: 'allTimeOffRequests', id: 'ALL' },
      ],
    }),
    cancelPendingTimeOffRequest: builder.mutation({
      query: (requestId) => ({
        url: `time-off-requests/${requestId}/reject`,
        method: 'POST',
      }),
      invalidatesTags: () => [
        { type: 'timeOffRequests', id: 'ALL' },
        { type: 'allTimeOffRequests', id: 'ALL' },
      ],
    }),
    checkApprovingPendingTimeOffRequest: builder.mutation({
      query: (requestId) => ({
        url: `time-off-requests/${requestId}/check`,
        method: 'POST',
      }),
    }),
    approvePendingTimeOffRequest: builder.mutation({
      query: (payload) => ({
        url: 'time-off-requests/approve',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [
        { type: 'timeOffRequests', id: 'ALL' },
        { type: 'allTimeOffRequests', id: 'ALL' },
      ],
    }),
    getCaregiverTimeOffRequestsHistory: builder.query({
      query: (caregiverId) => ({
        url: `time-off-requests/${caregiverId}/history`,
        method: 'GET',
      }),
      invalidatesTags: () => [
        { type: 'timeOffRequests', id: 'ALL' },
        { type: 'allTimeOffRequests', id: 'ALL' },
      ],
    }),
  }),
});
export const {
  useGetCaregiverTimeOffRequestsQuery,
  useGetTimeOffRequestsTypesQuery,
  useGetAllTimeOffRequestsQuery,
  useCheckPossibilityAndCreateTimeOffRequestDraftMutation,
  useCreateTimeOffRequestMutation,
  useCancelPendingTimeOffRequestMutation,
  useCancelCreatingTimeOffRequestMutation,
  useCheckCancelingApprovedTimeOffRequestMutation,
  useConfirmCancelingApprovedTimeOffRequestMutation,
  useCheckApprovingPendingTimeOffRequestMutation,
  useApprovePendingTimeOffRequestMutation,
  useGetCaregiverTimeOffRequestsHistoryQuery,
} = timeOffRequestApi;

export default timeOffRequestApi;
