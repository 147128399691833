import { COLORS } from '../../../shared/styles/Theme';

export const customInputFilter = {
  flex: '1 0 0',
  '& .MuiOutlinedInput-root': {
    border: 'none',
    minWidth: '100px',
    maxHeight: '42px',
    borderRadius: '10px',
    fontSize: '15px',
    color: COLORS.blue[500],
    backgroundColor: COLORS.blue[50],
    padding: '10px 12px',
    'input::placeholder': {
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '19px',
      color: COLORS.blue[500],
      opacity: 1,
    },
    '&.has-selected-items': {
      color: COLORS.blue[900],
      backgroundColor: COLORS.yellow[200],
    },
    '& fieldset': {
      border: 'none',
    },
    '& svg': {
      fill: COLORS.blue[300],
      fontSize: '17px',
    },
    '& .MuiOutlinedInput-input': {
      padding: 0,
    },
  },
};
