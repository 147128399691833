import CircleIcon from '@mui/icons-material/Circle';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { instanceOf, number } from 'prop-types';
import React from 'react';
import { COLORS } from '../../../../../../shared/styles/Theme';

function VisitsVerifyConfirmation({
  chosenVisits = [],
  additionalVisitDetails = 0,
  caregiverVisitNotes = 0,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0 28px 28px 28px',
      }}
    >
      {additionalVisitDetails === 0 && caregiverVisitNotes === 0 ? (
        <Box>
          <Typography>
            You are going to verify {chosenVisits?.length} visits at once.
          </Typography>
          <Typography>Would you like to continue?</Typography>
        </Box>
      ) : (
        <Box>
          <List>
            <ListItem sx={{ padding: 0 }}>
              <ListItemIcon sx={{ minWidth: '16px' }}>
                <CircleIcon
                  sx={{
                    fontSize: '6px',
                    color: COLORS.blue[900],
                  }}
                />
              </ListItemIcon>
              <ListItemText>
                {chosenVisits?.length} visits are going to be verified
              </ListItemText>
            </ListItem>
            {additionalVisitDetails > 0 && (
              <ListItem sx={{ padding: 0 }}>
                <ListItemIcon sx={{ minWidth: '16px' }}>
                  <CircleIcon
                    sx={{
                      fontSize: '6px',
                      color: COLORS.blue[900],
                    }}
                  />
                </ListItemIcon>
                <ListItemText>
                  Additional Visit Details of {additionalVisitDetails} visits
                  are going to be updated
                </ListItemText>
              </ListItem>
            )}
            {caregiverVisitNotes > 0 && (
              <ListItem sx={{ padding: 0 }}>
                <ListItemIcon sx={{ minWidth: '16px' }}>
                  <CircleIcon
                    sx={{
                      fontSize: '6px',
                      color: COLORS.blue[900],
                    }}
                  />
                </ListItemIcon>
                {caregiverVisitNotes > 0 && (
                  <ListItemText>
                    Caregiver Visit Notes of {caregiverVisitNotes} visits are
                    going to be updated
                  </ListItemText>
                )}
              </ListItem>
            )}
          </List>
        </Box>
      )}
    </Box>
  );
}

VisitsVerifyConfirmation.propTypes = {
  chosenVisits: instanceOf(Array),
  additionalVisitDetails: number,
  caregiverVisitNotes: number,
};

export default React.memo(VisitsVerifyConfirmation);
