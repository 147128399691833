import { Box, Typography } from '@mui/material';
import { number, string } from 'prop-types';
import React from 'react';
import CounterBadge from '../../../../../../../../components/CounterBadge';
import { visitAccordionSummaryTitle } from './styles';

function TableTitle({ title = '', visitsCount = 0 }) {
  return (
    <Box sx={visitAccordionSummaryTitle}>
      <Typography sx={{ lineHeight: '32px', ml: '8px' }} variant="h5">
        {title}
      </Typography>
      <CounterBadge label={visitsCount.toString()} hideIfNothing />
    </Box>
  );
}

TableTitle.propTypes = {
  title: string,
  visitsCount: number,
};

export default React.memo(TableTitle);
