export const detailsInfoTitle = {
  display: 'flex',
  maxWidth: '160px',
  width: '100%',
  marginRight: '45px',
};
export const profileReactivationButtonStyles = {
  width: 'fit-content',
  height: '32px',
  fontSize: '11px',
  lineHeight: '16px',
  mt: '8px',
};

export const reschedulerWaitingBlinkingText = {
  animation: 'animate 3s linear infinite',
  '@keyframes animate': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.6,
    },
    '100%': {
      opacity: 1,
    },
  },
};

export const additionalSelectStyles = {
  '& .animated-placeholder': {
    top: 1,
  },
  '& [aria-expanded=true] .animated-placeholder': {
    top: 1,
    fontSize: '15px',
    transition: 'opacity 0.3s ease, fontSize 0.7s ease-out',
  },
  '& .animated-placeholder.has-selected-items': {
    visibility: 'hidden',
  },
};
