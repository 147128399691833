import { COLORS } from '../../shared/styles/Theme';

export const customGroupButton = {
  height: '40px',
  display: 'flex',
  border: `1px solid ${COLORS.blue[200]}`,
  color: COLORS.blue[900],
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '20px',
  textTransform: 'none',
  '&:hover': {
    border: `1px solid ${COLORS.blue[200]}`,
    backgroundColor: COLORS.blue[50],
  },
  '&.MuiButton-root.Mui-disabled': {
    color: COLORS.blue[900],
    border: `1px solid ${COLORS.blue[200]}`,
    backgroundColor: COLORS.blue[50],
    opacity: 0.9,
  },
  '&.sub-name': {
    alignItems: 'start',
    justifyContent: 'start',
    padding: '5px 16px',
    lineHeight: '16px',
  },
  '&.sub-name span': {
    color: COLORS.blue[500],
    fontSize: '11px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0em',
  },
  '& .MuiButton-startIcon': {
    mr: '6px',
  },
};
