import { Box, Stack } from '@mui/material';
import { useGetTeamsQuery } from '../../../../api/Administration/api';
import {
  useConfirmCaregiverActivationMutation,
  useConfirmCaregiverDeactivationMutation,
  useConfirmCaregiverTeamChangeMutation,
  useGetCaregiverInfoQuery,
  useUpdateCaregiverInfoMutation,
} from '../../../../api/Caregivers/api';
import { useGetSkillsLibraryQuery } from '../../../../api/commonApi/api';
import moment from 'moment';
import {
  CaregiverDetailsContext,
  CaregiverProfileContext,
} from '../../../../pages/CaregiverDetails/context';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router';
import { ISO_DATE_ONLY_FORMAT } from '../../../../shared/constants';
import { checkEqualArrays, checkEqualStringArrays } from '../../../../shared/utils/common';
import AlertContext from '../../../../components/Alert';
import ButtonsGroup from '../../../../components/ButtonsGroup';
import Loader from '../../../../components/Loader';
import CaregiverHistoryTable from './CaregiverHistory';
import ProfileActions from './ProfileActions';
import ProfileDataRow from './ProfileDataRow';
import {
  detailsButtons,
  initialState,
  restructureTeams,
  userInfoFieldsMap,
} from './constants';

export default function CaregiverProfileInfo() {
  const { id, tabSubView } = useParams();
  const navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);
  const [caregiverProfile, setCaregiverProfile] = useState(initialState);
  const [caregiverProfileBeforeEdit, setCaregiverProfileBeforeEdit] =
    useState(initialState);
  const [teamEffectiveDateOpen, setTeamEffectiveDateOpen] = useState({
    isPrimary: false,
    isOpen: false,
  });
  const [teamDate, setTeamDate] = useState(moment().add(1, 'day'));
  const [hasChanges, setHasChanges] = useState(false);
  const {
    data: profile,
    isFetching: isDataReFetching,
    isLoading: isDataLoading,
    error: getCaregiverInfoError,
  } = useGetCaregiverInfoQuery(id);
  const profileData = restructureTeams(profile);
  const { data: skills, error: getSkillsLibraryError } =
    useGetSkillsLibraryQuery();
  const { data: teams, error: getLocationsListError } = useGetTeamsQuery();
  const [updateCaregiver, { error: updateCaregiverInfoError }] =
    useUpdateCaregiverInfoMutation();
  const [
    confirmCaregiverDeactivation,
    {
      isLoading: confirmationDeactivation,
      error: confirmCaregiverDeactivationError,
    },
  ] = useConfirmCaregiverDeactivationMutation();
  const [
    confirmCaregiverActivation,
    {
      isLoading: confirmationActivation,
      error: confirmCaregiverActivationError,
    },
  ] = useConfirmCaregiverActivationMutation();
  const [
    updateTeam,
    { isLoading: isConfirmLoading, error: checkCaregiverTeamConfirmError },
  ] = useConfirmCaregiverTeamChangeMutation();
  const { isEdit, onCloseProfileEditing, onEditProfile } = useContext(
    CaregiverDetailsContext,
  );
  useEffect(() => {
    if (
      getCaregiverInfoError ||
      getSkillsLibraryError ||
      getLocationsListError ||
      updateCaregiverInfoError ||
      confirmCaregiverDeactivationError ||
      confirmCaregiverActivationError ||
      checkCaregiverTeamConfirmError
    ) {
      const errorData =
        getCaregiverInfoError ||
        getSkillsLibraryError ||
        getLocationsListError ||
        updateCaregiverInfoError ||
        confirmCaregiverDeactivationError ||
        confirmCaregiverActivationError ||
        checkCaregiverTeamConfirmError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    getCaregiverInfoError,
    getSkillsLibraryError,
    getLocationsListError,
    updateCaregiverInfoError,
    confirmCaregiverDeactivationError,
    confirmCaregiverActivationError,
    setAlert,
    checkCaregiverTeamConfirmError,
  ]);

  const editingProfileDataLibrary = useMemo(
    () => ({
      skills,
      teams,
    }),
    [teams, skills],
  );
  useEffect(() => {
    const isEqual =
      checkEqualArrays(
        profileData?.primaryTeams,
        caregiverProfile?.primaryTeams,
      ) &&
      checkEqualArrays(
        profileData?.secondaryTeams,
        caregiverProfile?.secondaryTeams,
      ) &&
      checkEqualStringArrays(caregiverProfile?.skills, profileData?.skills) &&
      checkEqualStringArrays(
        caregiverProfile?.languages,
        profileData?.languages,
      ) &&
      caregiverProfile?.gender === profileData?.gender &&
      caregiverProfile.status === profileData.status;

    setHasChanges(!isEqual);
    if (
      caregiverProfile?.languages?.length === 0 &&
      caregiverProfile?.primaryTeams?.length === 0 &&
      caregiverProfile?.secondaryTeams?.length === 0 &&
      caregiverProfile?.skills?.length === 0 &&
      !caregiverProfile?.gender
    ) {
      setHasChanges(false);
    }
  }, [caregiverProfile, profileData]);

  const initializeCaregiverProfile = useCallback(() => {
    if (profileData) {
      setCaregiverProfile({
        primaryTeams: profileData?.primaryTeams,
        secondaryTeams: profileData?.secondaryTeams,
        skills: profileData?.skills || [],
        languages: profileData?.languages || [],
        gender: profileData?.gender || null,
        status: profileData?.status || '',
      });
      setCaregiverProfileBeforeEdit({
        primaryTeams: profileData?.primaryTeams,
        secondaryTeams: profileData?.secondaryTeams,
        skills: profileData?.skills || [],
        languages: profileData?.languages || [],
        gender: profileData?.gender || null,
        status: profileData?.status || '',
      });
    }
  }, [profileData]);

  const handleEditClick = useCallback(() => {
    initializeCaregiverProfile();
    onEditProfile();
    setHasChanges(false);
  }, [initializeCaregiverProfile, onEditProfile]);

  const gendersMapKeys = useMemo(
    () =>
      editingProfileDataLibrary?.skills?.genders.reduce(
        (obj, { description, name }) => ({ ...obj, [description]: name }),
        {},
      ),
    [editingProfileDataLibrary],
  );

  const updateCaregiverData = useCallback(() => {
    const { primaryTeams, secondaryTeams, gender, ...restProfile } =
      caregiverProfile;
    const currentTeams = { primaryTeams, secondaryTeams };
    updateCaregiver({
      caregiverId: id,
      payload: {
        ...restProfile,
        gender: gendersMapKeys[gender],
        currentTeams,
        version: profileData.version,
        activeFrom:
          caregiverProfile.status === 'Active' &&
          caregiverProfile.status !== profileData.status
            ? moment().format(ISO_DATE_ONLY_FORMAT)
            : null,
      },
    });
    onCloseProfileEditing();
    setHasChanges(false);
  }, [
    caregiverProfile,
    gendersMapKeys,
    id,
    onCloseProfileEditing,
    profileData,
    updateCaregiver,
  ]);

  const memoizedProviderValue = useMemo(
    () => ({
      caregiverProfile,
      confirmCaregiverDeactivation,
      confirmCaregiverActivation,
      editingProfileDataLibrary,
      handleEditClick,
      hasChanges,
      profileData,
      setCaregiverProfile,
      updateCaregiverData,
      teamEffectiveDateOpen,
      setTeamEffectiveDateOpen,
      teamDate,
      setTeamDate,
      caregiverProfileBeforeEdit,
      id,
      initializeCaregiverProfile,
      updateTeam,
    }),
    [
      caregiverProfile,
      confirmCaregiverDeactivation,
      confirmCaregiverActivation,
      editingProfileDataLibrary,
      handleEditClick,
      hasChanges,
      profileData,
      updateCaregiverData,
      teamEffectiveDateOpen,
      teamDate,
      caregiverProfileBeforeEdit,
      id,
      initializeCaregiverProfile,
      updateTeam,
    ],
  );

  const changeSubView = (value) =>
    navigate(`/caregivers/${id}/profile/${value}`);

  const isDataFetching =
    confirmationDeactivation ||
    confirmationActivation ||
    isDataReFetching ||
    isDataLoading ||
    isConfirmLoading;
  return (
    <CaregiverProfileContext.Provider value={memoizedProviderValue}>
      <Stack sx={{ maxWidth: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <ButtonsGroup
            buttons={detailsButtons}
            changeSelected={changeSubView}
            selected={tabSubView}
            disabled={isEdit || !profileData}
          />
          <ProfileActions />
        </Box>
        {isDataFetching ? (
          <Loader />
        ) : (
          <Box sx={{ maxWidth: '100%', mt: '32px' }}>
            {tabSubView === 'current' ? (
              userInfoFieldsMap?.map(({ name, type, key }) => (
                <ProfileDataRow
                  key={name}
                  isEdit={isEdit}
                  name={name}
                  profileData={profileData}
                  propKey={key}
                  type={type}
                />
              ))
            ) : (
              <CaregiverHistoryTable />
            )}
          </Box>
        )}
      </Stack>
    </CaregiverProfileContext.Provider>
  );
}
