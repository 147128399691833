import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { func, instanceOf, oneOfType, string } from 'prop-types';
import * as React from 'react';
import CounterBadge from '../../components/CounterBadge';
import {
  customSelectFormControlStyles,
  customSelectItem,
  customSelectPaperProps,
  customSelectStyles,
} from './styles';

export default function MultiselectWithBadge({
  additionalStyles = {},
  id = '',
  options = [],
  placeHolder = '',
  selectedValue = [] || '',
  setValue = () => {},
}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === 'all') {
      setValue({
        field: id,
        value: selectedValue?.length === options?.length ? [] : options,
      });
      return '';
    }
    let duplicateRemoved = [];
    value.forEach((item) => {
      if (duplicateRemoved.findIndex((option1) => option1 === item) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x === item);
      } else {
        duplicateRemoved.push(item);
      }
    });
    setValue({ field: id, value: duplicateRemoved });
    return '';
  };
  return (
    <FormControl
      sx={{
        ...customSelectFormControlStyles,
        ...additionalStyles,
      }}
    >
      <Select
        className={selectedValue?.length > 0 ? 'has-items' : ''}
        IconComponent={ExpandMoreIcon}
        displayEmpty
        multiple
        onChange={handleChange}
        renderValue={() => (
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {placeHolder}
            <CounterBadge
              label={
                selectedValue?.length === options?.length
                  ? 'ALL'
                  : selectedValue.length.toString()
              }
            />
          </Box>
        )}
        MenuProps={{
          disableAutoFocusItem: true,
          PaperProps: {
            sx: customSelectPaperProps,
          },
        }}
        sx={customSelectStyles}
        value={selectedValue}
      >
        <MenuItem
          name="all"
          value="all"
          disableRipple
          className="select-all-item"
          sx={{
            ...customSelectItem,
          }}
        >
          <Checkbox
            checked={
              options?.length > 0 && options?.length === selectedValue?.length
            }
          />
          All
        </MenuItem>
        {options?.map((name) => (
          <MenuItem
            key={name}
            value={name}
            disableRipple
            sx={{
              ...customSelectItem,
            }}
          >
            <Checkbox
              checked={
                !selectedValue?.length
                  ? false
                  : selectedValue?.findIndex((item) => item === name) >= 0
              }
              sx={{
                padding: '5px 9px',
              }}
            />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

MultiselectWithBadge.propTypes = {
  additionalStyles: instanceOf(Object),
  id: string,
  options: instanceOf(Array),
  placeHolder: string,
  selectedValue: oneOfType([instanceOf(Array), string]),
  setValue: func,
};
