import { Box, Typography } from '@mui/material';
import { string } from 'prop-types';
import React from 'react';
import { visitNotesStyles } from '../styles';

export default function CaregiverVisitNotes({ notes = '' }) {
  return (
    <Box sx={{ mt: '24px' }}>
      <Typography sx={{ ...visitNotesStyles, fontWeight: 600 }}>
        Caregiver Visit Notes
      </Typography>
      <Typography sx={visitNotesStyles}>{notes}</Typography>
    </Box>
  );
}

CaregiverVisitNotes.propTypes = {
  notes: string,
};
