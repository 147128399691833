import { Box, Button, FormControlLabel, Switch } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { VerificationModeVisitNotesContext } from '../../../../../../pages/Scheduling/context';
import React, { useCallback, useContext } from 'react';
import { getNoData } from '../../../../../../shared/utils/common';
import { v4 as uuidv4 } from 'uuid';
import CustomDialog from '../../../../../../components/Dialog';
import CustomTable from '../../../../../../components/Table';
import Filters from '../Filters';
import AffectedVisitsAccordion from './AffectedVisitsAccordion';
import VisitsVerifyConfirmation from './VisitsVerifyConfirmation';
import { reschedulingVisitColumns } from './constants';
import {
  customSwitcher,
  filtersWrapper,
  selectAllButtonStyles,
  tableStyles,
} from './styles';

function VisitVerificationCommon() {
  const {
    additionalVisitDetails,
    allLocations,
    caregiverVisitNotes,
    chosenVisits,
    completedVisits,
    date,
    debouncedSearch,
    filterData,
    filteredVisits,
    handleSelectAllTable,
    handleVerificationModeChange,
    openConfirmationDialog,
    setFilterData,
    setOpenConfirmationDialog,
    setTeamFilter,
    sortedTeams,
    statusOptions,
    submitDialog,
    teamsFilter,
    verificationMode,
    violationOptions,
  } = useContext(VerificationModeVisitNotesContext);
  const apiRef = useGridApiRef();
  const getRowId = useCallback((row) => `${row.visitType} - ${uuidv4()}`, []);
  const visitsColumns = useCallback(
    (locationId) => reschedulingVisitColumns(locationId),
    [],
  );
  return (
    <Box>
      <Box sx={filtersWrapper}>
        <Filters
          completedCounter={completedVisits?.length}
          date={date}
          debouncedSearch={debouncedSearch}
          filterData={filterData}
          isVerification
          locationsList={allLocations}
          setFilterData={setFilterData}
          setTeamFilter={setTeamFilter}
          statusOptions={statusOptions}
          teamsArray={allLocations?.map((t) => t?.name)}
          teamsFilter={teamsFilter}
          violationOptions={violationOptions}
        />
        <FormControlLabel
          control={
            <Switch
              sx={customSwitcher}
              checked={verificationMode}
              color="secondary"
              onChange={handleVerificationModeChange}
            />
          }
          label="Verification mode"
        />
        <Button sx={selectAllButtonStyles} onClick={handleSelectAllTable}>
          Select All
        </Button>
      </Box>
      <Box sx={{ padding: '40px' }}>
        {sortedTeams?.map((location) => (
          <AffectedVisitsAccordion
            key={location.id}
            region={location}
            visitsCount={filteredVisits[location.id]?.length}
          >
            <CustomTable
              apiRef={apiRef}
              columns={visitsColumns(location.id)}
              getRowId={getRowId}
              headerHeight={44}
              noRowsOverlay={() =>
                getNoData({
                  title:
                    'There are no Completed Visits for verification on this day',
                })
              }
              customStyles={tableStyles}
              isRowSelectable={(params) => params.row.type !== 'visitNote'}
              rows={filteredVisits[location.id]}
              withoutPagination
            />
          </AffectedVisitsAccordion>
        ))}
      </Box>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setOpenConfirmationDialog(false)}
        disableOnCloseByClickOutside
        openDialog={openConfirmationDialog}
        submitButtonName="Confirm"
        submitCallback={submitDialog}
        title="Confirmation"
      >
        <VisitsVerifyConfirmation
          chosenVisits={chosenVisits}
          additionalVisitDetails={additionalVisitDetails}
          caregiverVisitNotes={caregiverVisitNotes}
        />
      </CustomDialog>
    </Box>
  );
}
export default React.memo(VisitVerificationCommon);
