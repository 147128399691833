import { COLORS } from '../../../../../../shared/styles/Theme';

export const teamColorBoxStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  paddingRight: '20px',
};
export const caregiverNameStyles = {
  color: COLORS.blue[700],
  fontWeight: '600',
  fontSize: '14px',
};
export const caregiverListStyles = {
  color: COLORS.blue[700],
  width: '8px',
  height: '8px',
};
export const clientListStyles = {
  color: COLORS.blue[700],
  width: '8px',
  height: '8px',
};
export const clientNameStyles = {
  color: COLORS.blue[700],
  fontWeight: '600',
  fontSize: '14px',
  cursor: 'pointer',
};
export const errorModalBoxStyles = {
  display: 'flex',
  gap: 1,
  alignItems: 'center',
  m: '20px 0',
};
export const listBoxStyles = {
  display: 'flex',
  width: '100%',
  gap: 12,
};
