import { TabContext, TabPanel } from '@mui/lab';
import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router';
import { NavigationBlockingContext } from '../../shared/context';
import AdministrationTitle from './components/Title';
import { ADMINISTRATION_DETAILS_TABS } from './constants';

export default function Administration() {
  const { tab } = useParams();
  const navigate = useNavigate();
  const handleChange = (_, newValue) =>
    navigate(`/administration/${newValue}/current`);
  const { blocked } = useContext(NavigationBlockingContext);
  return (
    <Box
      sx={{
        width: '100%',
        typography: 'body1',
        maxHeight: 'calc(100vh - 180px)',
      }}
    >
      <TabContext value={tab}>
        <AdministrationTitle
          disableTabs={blocked}
          handleChange={handleChange}
        />
        <Box>
          {ADMINISTRATION_DETAILS_TABS?.map(({ key, child }) => (
            <TabPanel key={key} sx={{ padding: 0 }} value={key}>
              {child}
            </TabPanel>
          ))}
        </Box>
      </TabContext>
    </Box>
  );
}
