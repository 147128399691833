import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetClientHistoryQuery } from '../../../../../../api/CarePlan/api';
import {
  useGetFiltersDataQuery,
  useGetSkillsLibraryQuery,
} from '../../../../../../api/commonApi/api';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { getNoData, processCarePlanChangesChanges } from '../../../../../../shared/utils/common';
import AlertContext from '../../../../../../components/Alert';
import Loader from '../../../../../../components/Loader';
import CustomTable from '../../../../../../components/Table';
import { PAGE_SIZE, PAGE_SIZE_OPTIONS, historyColumns } from '../../constants';

export default function CarePlanHistory() {
  const apiRef = useGridApiRef();
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);

  const {
    data,
    isLoading,
    error: getClientHistoryError,
  } = useGetClientHistoryQuery(id);
  const {
    data: skillsData,
    isLoading: isLoadingSkills,
    error: getSkillsLibraryError,
  } = useGetSkillsLibraryQuery({
    refetchOnMountOrArgChange: true,
  });
  const {
    data: filtersData,
    isLoading: isFiltersDataLoading,
    error: getFiltersDataError,
  } = useGetFiltersDataQuery({
    refetchOnMountOrArgChange: true,
  });
  useEffect(() => {
    if (getClientHistoryError || getSkillsLibraryError || getFiltersDataError) {
      const errorData =
        getClientHistoryError || getSkillsLibraryError || getFiltersDataError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    getClientHistoryError,
    getFiltersDataError,
    getSkillsLibraryError,
    setAlert,
  ]);

  const updatedEvents = data
    ?.map((event, index) =>
      processCarePlanChangesChanges(event, skillsData, filtersData, index),
    )
    .filter(Boolean);

  const sortedUpdatedEvents = updatedEvents?.sort((a, b) => {
    const dateA = new Date(a.dateCreated);
    const dateB = new Date(b.dateCreated);
    return dateB - dateA;
  });
  if (isLoading) return <Loader />;

  return (
    <Box>
      <CustomTable
        apiRef={apiRef}
        columns={historyColumns}
        headerHeight={44}
        isLoading={isLoading || isLoadingSkills || isFiltersDataLoading}
        rows={sortedUpdatedEvents}
        noRowsOverlay={() =>
          getNoData({ title: 'Have No Care Plan History Yet' })
        }
        pageSize={PAGE_SIZE}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
      />
    </Box>
  );
}
