import { H12_TIME_FORMAT } from '../../../../../shared/constants';
import { convertUtcToTeamTimeZoneMomentObject } from '../../../../../shared/utils/common';

const parseTasksData = (changesData) => {
  if (changesData.taskType === 'CareProgramTask') {
    return changesData.changes.map((changes) => ({
      additionalInfo: {
        changedProperty: changes.propertyName,
        taskName: changesData.description.name,
      },
      from: changes.from,
      operation: changes.operation,
      propertyName: 'task',
      to: changes.to,
    }));
  }
  const statusChanges = changesData.changes.find(
    ({ propertyName }) => propertyName === 'status',
  );
  const isStatusUpdated = statusChanges && statusChanges.operation === 'update';
  if (isStatusUpdated) {
    return {
      additionalInfo: {
        changedProperty: 'status',
        taskName: changesData.description.name,
      },
      from: statusChanges.from,
      operation: statusChanges.operation,
      propertyName: 'task',
      to: statusChanges.to,
    };
  }
  const changedTask = changesData.changes.find(
    ({ propertyName }) => propertyName === 'name',
  );
  const isTaskAdded =
    changedTask &&
    changedTask.operation === 'insert' &&
    changedTask.from === null;
  if (isTaskAdded) {
    return {
      additionalInfo: {
        taskName: changesData.description.name,
      },
      from: null,
      operation: changedTask.operation,
      propertyName: 'task',
      to: changedTask.to,
    };
  }
  const isTaskRemoved =
    changedTask &&
    changedTask.operation === 'remove' &&
    changedTask.to === null;
  if (isTaskRemoved) {
    return {
      additionalInfo: {
        status: changesData.changes.find(
          ({ propertyName }) => propertyName === 'status',
        )?.from,
        taskName: changesData.description.name,
      },
      from: changedTask.from,
      operation: changedTask.operation,
      propertyName: 'task',
      to: null,
    };
  }
  return null;
};

const parseCaregiversInfo = (changesData) => {
  const firstNameData = changesData.changes.find(
    ({ propertyName }) => propertyName === 'firstName',
  );
  const lastNameData = changesData.changes.find(
    ({ propertyName }) => propertyName === 'lastName',
  );
  return {
    from: `${firstNameData?.from} ${lastNameData?.from} `,
    operation: changesData.operation,
    propertyName: changesData.propertyName,
    to: `${firstNameData?.to} ${lastNameData?.to} `,
  };
};

export const parseObjectTypeEvents = (changes) =>
  changes
    ?.filter((change) => ['collection', 'object'].includes(change?.type))
    .map((changesData) => {
      if (
        ['caregiver', 'shadowCaregiver', 'preferredCaregiver'].includes(
          changesData.propertyName,
        )
      ) {
        return parseCaregiversInfo(changesData);
      }
      if (changesData.propertyName === 'task') {
        return parseTasksData(changesData);
      }
      if (
        ['violatedHardConstraints', 'violatedSoftConstraints'].includes(
          changesData.propertyName,
        )
      ) {
        return changesData.changes.map((listOfChanges) => ({
          from: listOfChanges.from,
          operation: listOfChanges.operation,
          propertyName: changesData.propertyName,
          to: listOfChanges.to,
        }));
      }
      return false;
    })
    .flat()
    .filter(Boolean);

export const parsePrimitiveTypeEvents = (changes) => {
  const filteredPrimitives = changes
    ?.filter(({ type }) => type === 'primitive')
    ?.map((change) => change);

  return filteredPrimitives.map((change) => {
    const temporaryData = { ...change };
    if (
      ['arrivalTime', 'actualTimeEnd', 'actualTimeStart'].includes(
        temporaryData.propertyName,
      )
    ) {
      temporaryData.from = temporaryData?.from
        ? convertUtcToTeamTimeZoneMomentObject(temporaryData?.from)?.format(
            H12_TIME_FORMAT,
          )
        : null;
      temporaryData.to = convertUtcToTeamTimeZoneMomentObject(
        temporaryData?.to,
      )?.format(H12_TIME_FORMAT);
    }
    if (temporaryData.propertyName === 'visitNotes') {
      temporaryData.propertyName = 'caregiverVisitNotes';
    }
    if (temporaryData.propertyName === 'visitDetails') {
      temporaryData.propertyName = 'additionalVisitDetails';
    }
    if (temporaryData.propertyName === 'actualTimeStart') {
      temporaryData.propertyName = 'actualVisitStartTime';
    }
    if (temporaryData.propertyName === 'actualTimeEnd') {
      temporaryData.propertyName = 'actualVisitEndTime';
    }
    if (temporaryData.propertyName === 'arrivalTime') {
      temporaryData.propertyName = 'scheduledVisitStartTime';
    }
    if (temporaryData.propertyName === 'exactTime') {
      temporaryData.propertyName = 'visitType';
    }
    return temporaryData;
  });
};
