import { Box, Typography } from '@mui/material';
import { scheduledVisitsTypesMap } from '../../../../../../../pages/ClientDetails/views/Profile/constants';
import { bool, func, instanceOf, string } from 'prop-types';
import React, { useMemo } from 'react';
import { SHORT_DATE_FORMAT } from '../../../../../../../shared/constants';
import { COLORS } from '../../../../../../../shared/styles/Theme';
import Loader from '../../../../../../../components/Loader';
import CustomBreadcrumbs from './Breadcrumbs';
import VisitsByTypesAccordion from './VisitsByTypesAccordion';
import VisitsByTypesChangesTable from './VisitsByTypesChangesTable';
import { getColumns } from './VisitsByTypesChangesTable/constants';
import { checkPossibilityViewWrapper } from './style';

function ScheduleChangesView({
  actionType = '',
  closeDialog = () => {},
  isLoading = false,
  statusPeriodDates = {},
  visits = {},
}) {
  const requestPeriod = useMemo(
    () =>
      statusPeriodDates?.startDate && statusPeriodDates?.endDate
        ? `${statusPeriodDates?.startDate?.format(
            SHORT_DATE_FORMAT,
          )} – ${statusPeriodDates?.endDate?.format(SHORT_DATE_FORMAT)}`
        : `${statusPeriodDates?.startDate?.format(
            SHORT_DATE_FORMAT,
          )} – Without end Date`,
    [statusPeriodDates],
  );
  const actionBreadcrumbsTitles = {
    suspend: 'Suspend Client’s Services',
    deactivate: 'Deactivate Client',
    reActivate: 'Reactivate Client',
    resume: 'Resume Client’s Services',
  };
  const isSuspendActions = ['suspend', 'resume'].includes(actionType);
  const isReactivationAccount = ['reActivate', 'resume'].includes(actionType);

  return (
    <Box sx={{ minWidth: '1440px', padding: '20px 32px' }}>
      <Box sx={checkPossibilityViewWrapper}>
        <CustomBreadcrumbs
          closeDialog={closeDialog}
          title={actionBreadcrumbsTitles[actionType]}
        />
        {isSuspendActions && (
          <Typography
            variant="body1"
            sx={{ fontWeight: 400, color: COLORS.blue[900] }}
          >{`${
            isSuspendActions ? 'Resumption Date' : 'Suspension Dates'
          }: ${requestPeriod}`}</Typography>
        )}
      </Box>
      {isLoading ? (
        <Box sx={{ height: '300px' }}>
          <Loader />
        </Box>
      ) : (
        <Box sx={{ padding: '0 28px' }}>
          {scheduledVisitsTypesMap?.map(({ key, title }) => (
            <VisitsByTypesAccordion
              date={requestPeriod}
              key={key}
              tasksCount={visits[key]?.length}
              title={title}
            >
              <VisitsByTypesChangesTable
                columns={getColumns(isReactivationAccount)[key]}
                key={key}
                visitsList={visits[key]}
                visitsType={title?.toLowerCase()}
              />
            </VisitsByTypesAccordion>
          ))}
        </Box>
      )}
    </Box>
  );
}

ScheduleChangesView.propTypes = {
  actionType: string,
  closeDialog: func,
  isLoading: bool,
  statusPeriodDates: instanceOf(Object),
  visits: instanceOf(Object),
};

export default React.memo(ScheduleChangesView);
