import { COLORS } from '../../../../../../../../shared/styles/Theme';

export const daysWrapper = {
  display: 'flex',
  gap: '12px',
  borderBottom: `1px solid ${COLORS.blue[200]}`,
};

export const datesWrapper = {
  display: 'flex',
  gap: '12px',
  marginTop: '12px',
  flexWrap: 'wrap',
};

export const calendarElementStyles = {
  days: {
    display: 'flex',
    width: '64px',
    height: '40px',
    padding: '5px',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '11px',
    lineHeight: '19px',
    fontWeight: 400,
    textTransform: 'uppercase',
    color: COLORS.blue[500],
    '&.weekend-day': {
      color: COLORS.red[300],
    },
  },
  dates: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '13px',
    lineHeight: '16px',
    fontWeight: 600,
    padding: '5px',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    color: COLORS.blue[500],
    cursor: 'pointer',
    width: '64px',
    height: '40px',
    '&.today-date': {
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 5px, rgb(79, 67, 80) 0px 0px 0px 6px',
    },
    '&.disabled-date': {
      color: '#9ba1a7',
      backgroundColor: COLORS.black[50],
      cursor: 'default',
    },
    '&.selected-date': {
      color: '#ffffff',
      backgroundColor: COLORS.brown[600],
    },
  },
};
