import { COLORS } from '../../../../../shared/styles/Theme';

export const chipStartStyle = {
  position: 'absolute',
  top: '-7px',
  left: '-61px',
  zIndex: 1003,
  padding: ' 3px 6px 2px 6px',
  borderRadius: '30px',
  background: COLORS.green[500],
  color: '#fff',
  fontSize: '11px',
};
export const chipEndStyle = {
  position: 'absolute',
  top: '87%',
  left: '-61px',
  zIndex: 1003,
  padding: ' 3px 6px 2px 6px',
  borderRadius: '30px',
  background: COLORS.green[500],
  color: '#fff',
  fontSize: '11px',
};
export const durationStyle = {
  position: 'absolute',
  top: '30%',
  left: '-68px',
  width: '62px',
  zIndex: 1003,
  padding: '4px 6px',
  borderRadius: '4px',
  background: '#fff',
  boxShadow: '0px 0px 12px 0px #2C2D2E29',
};
export const chipArrivalStartStyle = {
  position: 'absolute',

  right: '-60px',
  zIndex: 1003,
  padding: ' 3px 6px 2px 6px',
  borderRadius: '30px',
  background: COLORS.brown[600],
  color: '#fff',
  fontSize: '11px',
};
export const chipArrivalTimeEndStyle = {
  position: 'absolute',
  right: '-60px',
  top: '-7px',
  zIndex: 1003,
  padding: ' 3px 6px 2px 6px',
  borderRadius: '30px',
  background: COLORS.brown[600],
  color: '#fff',
  fontSize: '11px',
};
export const chipArrivalWindowStyle = {
  position: 'absolute',
  right: '-98px',
  width: '92px',
  zIndex: 1003,
  padding: '4px 6px',
  borderRadius: '4px',
  background: '#fff',
  boxShadow: '0px 0px 12px 0px #2C2D2E29',
};

export const getTravelTimeStyles = (travelTime) => ({
  position: 'absolute',
  background:
    'repeating-linear-gradient(135deg,  #EFEBE9,  #EFEBE9 6px, #fff 5px, #fff 12px)',
  padding: '6px 10px',
  height: `${travelTime * 2}px`,
  display: 'flex',
  fontSize: '13px',
  color: COLORS.blue[900],
  fontWeight: 400,
  top: `-${travelTime * 2}px`,
  width: '100%',
});

export const getArrivalWindowStyles = (arrivalWindowHeight) => ({
  height: `${arrivalWindowHeight}px`,
  border: `2px dashed ${COLORS.blue[700]}`,
  borderBottom: 'none',
  position: 'absolute',
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  top: `-${arrivalWindowHeight}px`,
  width: '100%',
  zIndex: 1,
});
