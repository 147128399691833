import { Box, Typography } from '@mui/material';
import shadowCaregiverIcon from '../../img/icons/shadow-visit-black-icon.svg';
import { func, instanceOf } from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router';
import CustomTooltip from '../../components/CustomTooltip';
import { caregiverNameStyles, visitCaregiverInfo } from './styles';

export default function ShadowCaregiverCell({
  closeDialog = () => {},
  row = {},
}) {
  const navigate = useNavigate();

  const onLinkClick = (url) => {
    navigate(url);
    closeDialog();
  };

  return (
    <Box sx={visitCaregiverInfo}>
      {row?.oldShadowCaregiver && (
        <CustomTooltip title="Shadow visit">
          <img src={shadowCaregiverIcon} alt="arrivalIcon" />
        </CustomTooltip>
      )}
      {row?.oldShadowCaregiver ? (
        <Typography
          variant="body1"
          sx={caregiverNameStyles}
          component="span"
          onClick={() =>
            onLinkClick(`/caregivers/${row?.oldShadowCaregiver?.id}`)
          }
        >
          {`${row?.oldShadowCaregiver?.firstName} ${row?.oldShadowCaregiver?.lastName}`}
        </Typography>
      ) : (
        <Typography>-</Typography>
      )}
    </Box>
  );
}

ShadowCaregiverCell.propTypes = {
  closeDialog: func,
  row: instanceOf(Object),
};
