import moment from 'moment';
import { instanceOf } from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { isWeekend } from '../../../../../../shared/utils/common';
import DefaultFiveDaysCalendar from '../../../../../../components/Calendar';
import SevenDaysCalendar from '../../../../../../components/SevenDaysCalendar';
import CareProgramEvent from '../../views/CareProgramCalendarView/CareProgramEvent';
import { getMaxHour, getMinHour, parseVisits } from './utils';

function CareProgramCalendar({ careProgramVisits = [] }) {
  useEffect(() => {
    setTimeout(() => {
      const currentTimeIndicator = document.querySelector(
        '.rbc-current-time-indicator',
      );

      currentTimeIndicator?.classList.add('custom-time-indicator-care-program');
    }, 0);
  });

  const parsedEventsData = useMemo(
    () => parseVisits(careProgramVisits),
    [careProgramVisits],
  );

  const hasWeekendEvents = parsedEventsData?.some((event) =>
    isWeekend(event.start),
  );

  const eventPropGetter = () => ({
    className: 'care-program-calendar',
  });

  const dateCellWrapper = ({ date }) => (
    <div className="rbc-day-bg">
      <div className="rbc-day-bg-inner">{moment(date).format('ddd')}</div>
    </div>
  );

  const minHour = useMemo(
    () => getMinHour(parsedEventsData),
    [parsedEventsData],
  );
  const maxHour = useMemo(
    () => getMaxHour(parsedEventsData),
    [parsedEventsData],
  );
  return hasWeekendEvents ? (
    <SevenDaysCalendar
      className="client-seven-days-calendar"
      events={parsedEventsData}
      eventComponent={CareProgramEvent}
      dateHeaderWrapper={dateCellWrapper}
      eventStyleGetter={eventPropGetter}
      onSelectEvent={() => {}}
      firstVisit={parsedEventsData?.length > 0 ? minHour : 10}
      lastVisit={parsedEventsData?.length > 0 ? maxHour + 1 : 7}
    />
  ) : (
    <DefaultFiveDaysCalendar
      className="client-five-days-calendar"
      events={parsedEventsData}
      eventComponent={CareProgramEvent}
      dateHeaderWrapper={dateCellWrapper}
      onSelectEvent={() => {}}
      eventStyleGetter={eventPropGetter}
      firstVisit={parsedEventsData?.length > 0 ? minHour : 10}
      lastVisit={parsedEventsData?.length > 0 ? maxHour + 1 : 7}
    />
  );
}

CareProgramCalendar.propTypes = {
  careProgramVisits: instanceOf(Array),
};

export default React.memo(CareProgramCalendar);
