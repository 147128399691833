import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
// import Chevron from '../../../../../../../img/icons/chevron-right.svg?react';
import { instanceOf, node, number } from 'prop-types';
import React, { useState } from 'react';
import CounterBadge from '../../../../../../../components/CounterBadge';
import {
  customAccordionStyles,
  customAccordionSummaryStyles,
  visitAccordionSummaryTitle,
} from './styles';

function AffectedVisitsAccordion({
  children = <div />,
  visitsCount = 0,
  region = {},
}) {
  const [expanded, setExpanded] = useState(children?.props?.rows?.length > 0);
  return (
    <Accordion elevation={0} expanded={expanded} sx={customAccordionStyles}>
      <AccordionSummary
        // expandIcon={<Chevron onClick={() => setExpanded(!expanded)} />}
        aria-controls="panel1bh-content"
        sx={customAccordionSummaryStyles}
      >
        <Box
          sx={visitAccordionSummaryTitle}
          onClick={() => setExpanded(!expanded)}
        >
          <Box
            sx={{
              width: '16px',
              height: '16px',
              borderRadius: '3px',
              background: region?.color?.color,
            }}
          />
          <Typography
            sx={{ lineHeight: '32px', mr: '12px', ml: '8px' }}
            variant="h5"
          >
            {region.name}
          </Typography>
          <CounterBadge label={(visitsCount / 2).toString()} hideIfNothing />
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>{children}</AccordionDetails>
    </Accordion>
  );
}

AffectedVisitsAccordion.propTypes = {
  children: node,
  visitsCount: number,
  region: instanceOf(Object),
};

export default React.memo(AffectedVisitsAccordion);
