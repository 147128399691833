import { COLORS } from '../../shared/styles/Theme';

export const formControlStyles = {
  '&.MuiFormControlLabel-root': {
    marginLeft: '2px',
    marginRight: '20px',
  },
};

export const radioButtonStyles = {
  width: '18px',
  height: '18px',
  padding: '5px',
  mr: '8px',
  '& span': {
    backgroundImage:
      'radial-gradient(rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0) )',
  },
  '&.MuiRadio-root:hover': {
    backgroundColor: 'transparent',
  },
  color: COLORS.blue[700],
  '&.Mui-checked': {
    color: COLORS.blue[700],
  },
  '& .MuiSvgIcon-root': {
    fontSize: 18,
    zIndex: 1,
  },
};
