import { COLORS } from '../../../../../shared/styles/Theme';

export const arrowButtonStyles = {
  display: 'flex',
  width: '40px',
  height: '40px',
  padding: 0,
  backgroundColor: COLORS.blue[50],
  '&.hidden': {
    display: 'none',
  },
  '& .MuiSvgIcon-root': { color: COLORS.blue[900], fontSize: '20px' },
  '&.Mui-disabled': {
    backgroundColor: COLORS.blue[50],
    cursor: 'not-allowed',
    '& .MuiSvgIcon-root': { color: COLORS.blue[300] },
  },
};

export const datesRangeStyles = {
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '20px',
};
